import * as React from "react";
import {Controller} from "react-hook-form";
import {FormInputProps} from "./FormInputProps";
import {TextField} from "@mui/material";
import {ImageInfo, ImageUpload} from "../../../../components/ImageUpload";
import {createUploadService} from "../../../../UploadService";
import {UploadServiceType} from "../../../../components/quickform/QuickForm";

function toImageInfo(images: any, uploadService?: UploadServiceType): ImageInfo[] {
   if (Array.isArray(images)) {
      return (images as string[]).map(image => {
         return {
            id: image,
            url: uploadService?.url() + '/' + image
         }
      });
   } else if (images) {
      return [{
         id: images,
         url: uploadService?.url() + '/' + images
      }];
   } else {
      return []
   }
}


export const FormInputImage = ({name, control, label, errorMessage, container}: FormInputProps & {
   container: string
}) => {
   const inputRef = React.useRef<HTMLInputElement>(null);
   const uploadService = createUploadService(process.env.REACT_APP_FILESERVICEURL!, container);

   let ownPropertyDescriptor = Object.getOwnPropertyDescriptor(window!.HTMLInputElement!.prototype, "value");
   var nativeInputValueSetter = ownPropertyDescriptor!.set;

   return (
      <Controller
         name={name}
         control={control}
         render={({
                     field: {onChange, value},
                     fieldState: {error}
                  }) => (
            <div>
               <ImageUpload size={120} maxImages={1} id={'upload_' + name} images={toImageInfo(value, uploadService)}
                            uploadService={uploadService} addImages={(imageId: string | string[]) => {
                  nativeInputValueSetter!.call(inputRef.current!, imageId);
                  var ev2 = new Event('input', {bubbles: true});
                  inputRef.current!.dispatchEvent(ev2)
               }} delImage={() => {
                  nativeInputValueSetter!.call(inputRef.current!, '');
                  var ev2 = new Event('input', {bubbles: true});
                  inputRef.current!.dispatchEvent(ev2)
               }}/>
               <TextField
                  style={{display: 'none'}}
                  inputRef={inputRef}
                  hidden={true}
                  id={name}
                  size="small"
                  error={!!error}
                  onChange={onChange}
                  value={value || ''}
                  fullWidth
                  label={label}
                  variant="outlined"
                  margin={"normal"}
               />
            </div>
         )}
      />
   );
};
