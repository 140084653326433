import * as React from "react"
import Typography from "@mui/material/Typography";
import {QComponentProps} from "../Model";
import {SubTitle} from "../widgets/SubTitle";

type QHeadingProps = QComponentProps & {
   text: string
   subtext: string
   variant: 'h1'|'h2'|'h3'|'h4'|'body1'|undefined
   width?: string
}

export const QHeading: React.FC<QHeadingProps> = (props: any) => {
   const {subtext, text, variant} = props;
   return <div style={{width: props.width||'inherit'}}>
      <Typography style={{marginTop: 15}} variant={variant||'h1'}>{text}</Typography>
      {subtext && <SubTitle type={"subtitle1"}>
         <span dangerouslySetInnerHTML={{__html: subtext}}/>
      </SubTitle>}
   </div>
}