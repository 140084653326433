import * as React from "react";
import {useEffect} from "react";
import Page from "../../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import {Checkbox, FormControlLabel} from "@mui/material";
import PageContent from "../../../layout/PageContent";
import PageHeader from "../../../layout/PageHeader";
import {TradeInPagePath} from "./TradeInPage";
import {TradeInsList} from "../components/TradeInList";

export const TradeInsPagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/tradeins";
}

export const TradeInsPage = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const [state, setState] = React.useState<State>({
      registered: false,
      rejected: false,
      stocked: false
   });
   const navigate = useNavigate();

   useEffect(() => {
      let state = loadState();
      setState(state);
   }, [])

   const updateState = (state: State) => {
      saveState(state);
      setState(state);
   }

   const onSelectTradeIn = (row: any) => {
      navigate(TradeInPagePath(brandHouseId, shopId, row._id))
   }

   return <Page>
      <PageHeader title={"TradeIns"}>
         <FormControlLabel
            control={<Checkbox checked={state.registered}
                               onChange={() => updateState({...state, registered: !state.registered})}
                               name="Registered"/>}
            label="Only registered"
         />
      </PageHeader>
      <PageContent fullHeight>
         <TradeInsList shopId={shopId} registered={state.registered} rejected={state.rejected}
                           imageSize={70}
                           stocked={state.stocked} onSelectTradeIn={onSelectTradeIn}/>
      </PageContent>
   </Page>
}

type State = {
   registered: boolean
   rejected: boolean
   stocked: boolean
}

const loadState = (): State => {
   return {
      registered: (localStorage.getItem("tradeins.registered") === "true"),
      rejected: (localStorage.getItem("tradeins.rejected") === "true"),
      stocked: (localStorage.getItem("tradeins.stocked") === "true")
   }
}
const saveState = (state: State) => {
   localStorage.setItem("tradeins.registered", String(state.registered));
   localStorage.setItem("tradeins.rejected", String(state.rejected));
   localStorage.setItem("tradeins.stocked", String(state.stocked));
}