import Box from "@mui/material/Box";
import {BrandRule, EnumBrandRuleExpressionsOperation} from "../../../../Queries";
import {BodyText} from "../../../../layout/Typography";
import {Popover} from "@mui/material";
import {useState} from "react";

type ExpressionViewProps = {
   rule: BrandRule
}

export const ExpressionView = ({rule}: ExpressionViewProps) => {
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   if ((rule.expressions || []).length > 0) {
      return <Box>
         <BodyText type={"body1"}>Orderlines matching <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={handleClick}>{rule.expressions?.length} rules</span></BodyText>
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
            }}
         >
            <Box sx={{width: '400px', padding: '10px'}}>
               {rule.expressions!.map(expression => {
                  let isInc = expression?.operation === EnumBrandRuleExpressionsOperation.INCLUDE;
                  return <Box>{expression?.valueType + (isInc ? " one of " : " not one of ") + (expression?.value || []).join(',')}</Box>
               })}
            </Box>
         </Popover>
      </Box>
   } else {
      return <BodyText type={"body2"}>All orderlines</BodyText>
   }
}
