import {SxProps} from "@mui/system";
import {Chip, Theme} from "@mui/material";
import {useEffect, useState} from "react";
import {useBrandItemImageQuery} from "../../../Queries";

type ItemImageStatusProps = {
   imageId: string
   process: boolean
   sx?: SxProps<Theme>;
   onUpdateImageId: (imageId: string) => void
}

export const ItemImageStatus = ({imageId, process, sx, onUpdateImageId}: ItemImageStatusProps) => {
   const MAX_POLLS = 15;

   const [, setPollCount] = useState<number>(0);

   const {data, refetch} = useBrandItemImageQuery({
      variables: {
         imageId: imageId
      }
   });
   const [processing, setProcessing] = useState<'none' | 'processing' | string>('none');

   useEffect(() => {
      const pollInterval = 6000; // Poll every 6 seconds

      const interval = setInterval(() => {
         setPollCount(prevCount => {
            if (prevCount + 1 >= MAX_POLLS) {
               if (!data?.BrandItemImage?.imageId) {
                  setProcessing('Timed out')
               }
               clearInterval(interval);
               return prevCount + 1;
            }
            if (!data?.BrandItemImage?.imageId) {
               refetch();
            }
            return prevCount + 1;
         });
      }, pollInterval);

      return () => clearInterval(interval);
   }, [data?.BrandItemImage?.imageId, refetch]);

   useEffect(() => {
      if (data) {
         if (data.BrandItemImage?.processError) {
            setProcessing(data.BrandItemImage.processError)
         } else if (data.BrandItemImage?.imageId) {
            if (processing === 'processing') {
               if (data.BrandItemImage.imageId) {
                  onUpdateImageId(data.BrandItemImage.imageId);
               }
            }
         } else if (process) {
            if (data.BrandItemImage?.processStart) {
               const diff = new Date().getTime() - new Date(data.BrandItemImage.processStart).getTime();
               if (diff > 180000) {
                  setProcessing(data.BrandItemImage?.processError || 'Previously timed out');
               } else {
                  setProcessing('processing');
               }
            }
         }
      }
   }, [data, onUpdateImageId, process, processing])

   if (processing === 'none') {
      return null;
   }

   return <Chip variant={"filled"} color={processing === 'processing' ? "info" : "error"} sx={{
      ...sx,
      textAlign: 'center'
   }} label={processing === 'processing' ? "Processing" : processing}/>
}