import {FlexList} from "../../../components/flexlist/FlexList";
import {AddressInfo, ParcelFragment, SortFindManyParcelInput, useParcelsListLazyQuery} from "../../../Queries";
import {FlexListColumn} from "../../../components/flexlist/FlexTypes";
import {useCallback} from "react";
import dayjs from "dayjs";
import {Box} from "@mui/material";

type ParcelsListProps = {
   brandHouseId: string
   shopId: string
}

const PAGE_SIZE = 20;
export const ParcelsList = ({brandHouseId, shopId}: ParcelsListProps) => {
   const [fetchMore] = useParcelsListLazyQuery()

   const columns: FlexListColumn<ParcelFragment>[] = [{
      id: 'date',
      field: 'parcelDate',
      label: 'Parcel date',
      render: (date, row) => {
         return dayjs(date).format('DD. MMM YYYY  HH:MM')
      }
   }, {
      id: 'sender',
      field: 'sender',
      label: 'Sent by',
      render: (sender, row) => {
         return <AddressInfoView address={sender}/>
      }
   }, {
      id: 'receiver',
      field: 'receiver',
      label: 'To',
      render: (to, row) => {
         return <AddressInfoView address={to}/>
      }
   }];

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyParcelInput) || SortFindManyParcelInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: {
               brandHouseId,
               shopId,
               ...filter
            }
         }
      });
      return {
         data: data?.ParcelsPagination?.items || [],
         totalCount: data?.ParcelsPagination?.count || 0
      }
   }, [brandHouseId, fetchMore, shopId]);

   const onSelectRow = (row: ParcelFragment) => {

   }

   return <FlexList globalId={"parcelList"} onSelectRow={onSelectRow} columns={columns} itemsPerPage={20}
                    onRequestData={onRequestData}/>
}

const AddressInfoView = ({address}: { address: AddressInfo }) => {
   return <Box>
      <Box>
         {address?.name} ({address?.mail})
      </Box>
      <Box>
         {address?.attention}
      </Box>
      <Box>
         {address?.add1}
      </Box>
      <Box>
         {address?.add2}
      </Box>
      <Box>
         {address?.zipCode} {address?.city}
      </Box>
      <Box>
         {address.countryCode}
      </Box>
   </Box>
}
