const titleCase = (str:string) => {
   if(!str) return str;
   return str
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}


const stringToColor = (string: string) => {
   let hash = 0;
   let i;

   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }

   let color = '#';

   for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */

   return color;
}

const stringAvatar = (name: string) => {
   let strings = name.replaceAll('(','').replaceAll(')','').split(' ');
   const primeLetter = strings?.[0]?.[0] || ''
   const secondLetter = strings?.[1]?.[0] || ''
   return {
      sx: {
         bgcolor: stringToColor(name),
      },
      children: `${primeLetter}${secondLetter}`,
   };
}

const compare = (s1: string|undefined|null, s2: string|undefined|null) => {
   let eff1 = (s1 || '').toLowerCase();
   let eff2 = (s2 || '').toLowerCase();
   if(eff1 < eff2) {
      return -1;
   } else if(eff1 > eff2) {
      return 1;
   } else {
      return 0;
   }
}

export const StringHelper = {
   titleCase,
   stringAvatar,
   stringToColor,
   compare
}