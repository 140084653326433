import React, {useState} from "react";
import {
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton
} from "@mui/material";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {
   EnumOptionSetRefType,
   EnumOptionSetTarget,
   EnumOptionSetValueType,
   LocalizationEntryInput,
   OptionSetInput,
   OptionValueInput,
   useGetOptionSetQuery,
   useOptionsSynchronizeMutation,
} from "../../../../Queries";
import {ParamBrandHouse} from "../../../../Parameters";
import {FormInputSelect} from "../../../shop/wholesale/form/FormInputSelect";
import {FormInputCheckbox} from "../../../shop/wholesale/form/FormInputCheckbox";
import {FormInputText} from "../../../shop/wholesale/form/FormInputText";
import {AddCircle, DeleteForever} from "@mui/icons-material";
import SettingsHeader from "../../SettingsHeader";
import PageContent from "../../../../layout/PageContent";
import {SavingUI} from "../../../../SavingProvider";
import {OptionSetSettingsPagePath} from "./OptionSetSettingsPage";
import TextField from "@mui/material/TextField";

export const OptionSetEditPath = (brandId: string, optionSetId: string) => {
   return "/brands/" + brandId + "/settings/optionset/" + optionSetId + "/edit"
}
export const OptionSetCreatePath = (brandId: string) => {
   return "/brands/" + brandId + "/settings/optionset/create"
}

type FormData = {
   active: boolean;
   id?: string;
   externalId?: string;
   key?: string;
   refId?: string;
   target: EnumOptionSetTarget;
   title?: string;
   valueType: EnumOptionSetValueType;
   values?: FormDataValue[];
};

type FormDataValue = {
   customData?: any;
   id?: string;
   externalId?: string;
   label?: string;
   localizations?: LocalizationEntryInput[];
   sortorder?: number;
   value?: string;
};

export const OptionSetEditorPage: React.FC = () => {
   const {brandHouseId, optionSetId} = useParams<ParamBrandHouse & { optionSetId?: string }>();
   const navigate = useNavigate();
   const [updateOptions] = useOptionsSynchronizeMutation()

   // If optionSetId exists, render OptionSetEditorWithData
   if (optionSetId) {
      return <OptionSetEditorWithData optionSetId={optionSetId}/>;
   }

   // Create default values for a new OptionSet
   const defaultValues: FormData = {
      active: true,
      refId: brandHouseId,
      target: EnumOptionSetTarget.DROPOFF,
      title: "",
      valueType: EnumOptionSetValueType.SELECT,
      values: [],
      key: ""
   };

   const handleSave = async (formData: FormData) => {
      await SavingUI.process(async () => {
         await updateOptions({
            variables: {
               input: mapFormDataToOptionSetInput(formData),
            }
         })
         navigate(OptionSetSettingsPagePath(brandHouseId));
      }, 'Option Set saved', 'Please wait while saving')

   };

   return <OptionSetInnerEditor data={defaultValues} onSave={handleSave}/>;
};

type OptionSetEditorWithDataProps = {
   optionSetId: string;
};

export const OptionSetEditorWithData: React.FC<OptionSetEditorWithDataProps> = ({optionSetId}) => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data, loading} = useGetOptionSetQuery({
      variables: {
         id: optionSetId,
      },
   });
   const [updateOptions] = useOptionsSynchronizeMutation()

   const navigate = useNavigate();

   // Wait for the data to load
   if (loading) {
      return (
         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress/>
         </Box>
      );
   }

   if (!data?.Option) {
      return <div>No Option Set Found</div>;
   }

   // Populate FormData with the data from the query
   const formData: FormData = {
      active: data.Option.active || false,
      id: data.Option._id,
      externalId: data.Option.externalId || undefined,
      key: data.Option.key,
      refId: data.Option.refId,
      target: data.Option.target,
      title: data.Option.title,
      valueType: data.Option.valueType,
      values: (data.Option.values || []).map(val => ({
         customData: val?.customData,
         externalId: val?.externalId || undefined,
         id: val?._id || undefined,
         label: val?.label || undefined,
         localizations: (val?.localizations || []).map(loc => ({
            value: loc!.value,
            locale: loc!.locale,
         })),
         sortorder: val?.sortorder || 0,
         value: val?.value || undefined,
      })),
   };

   const handleSave = async (formData: FormData) => {
      await SavingUI.process(async () => {
         await updateOptions({
            variables: {
               input: mapFormDataToOptionSetInput(formData),
            }
         })
         navigate(OptionSetSettingsPagePath(brandHouseId));
      }, 'Option Set saved', 'Please wait while saving')
   };

   return <OptionSetInnerEditor data={formData} onSave={handleSave}/>;
};

type OptionSetInnerEditorProps = {
   data: FormData;
   onSave: (data: FormData) => Promise<void>;
};

export const OptionSetInnerEditor: React.FC<OptionSetInnerEditorProps> = ({data, onSave}) => {

   // Dialog state for importing values
   const [openDialog, setOpenDialog] = useState(false);
   const [importText, setImportText] = useState("");

   const formMethods = useForm<FormData>({
      defaultValues: data,
   });

   const {handleSubmit, control} = formMethods;

   const {fields, append, remove} = useFieldArray({
      control,
      name: "values",
   });

   const handleDialogOpen = () => {
      setOpenDialog(true);
   };

   const handleDialogClose = () => {
      setOpenDialog(false);
   };

   const handleImport = () => {
      // Split the input text by newlines and append to field array
      const newValues = importText.split("\n").filter(line => line.trim() !== "");
      let newSortOrder = fields.length;
      newValues.forEach(value => {
         append({
            label: value.trim(),
            value: value.trim(),
            externalId: value.trim(),
            sortorder: newSortOrder++
         });
      });
      setImportText(""); // Clear the input field
      handleDialogClose(); // Close the dialog
   };

   return (
      <Box>
         <SettingsHeader title="Option Sets" subtitle="Customizable data">
            <Button
               type="button"
               variant="outlined"
               onClick={handleDialogOpen}
               style={{ marginLeft: '10px' }}
            >
               Import values
            </Button>

            <Button
               type="button"
               variant="contained"
               onClick={async () => {
                  await onSave(formMethods.getValues());
               }}
            >
               Save
            </Button>
         </SettingsHeader>

         <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSave)}>
               <PageContent>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <FormInputText
                           name="title"
                           label="Title"
                           placeholder="Enter title"
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <FormInputText
                           name="key"
                           label="Key"
                           placeholder="Enter key"
                        />
                     </Grid>

                     <Grid item xs={12}>
                        <FormInputCheckbox
                           name="active"
                           label="Active"
                        />
                     </Grid>

                     <Grid item xs={12}>
                        <FormInputSelect
                           name="target"
                           label="Target"
                           options={Object.values(EnumOptionSetTarget).map((target) => ({
                              id: target,
                              name: target,
                           }))}
                           defaultValue={data.target}
                        />
                     </Grid>

                     <Grid item xs={12}>
                        <FormInputSelect
                           name="valueType"
                           label="Value Type"
                           options={Object.values(EnumOptionSetValueType).map((valueType) => ({
                              id: valueType,
                              name: valueType,
                           }))}
                           control={control}
                           defaultValue={data.valueType}
                        />
                     </Grid>
                  </Grid>
               </PageContent>
               <PageContent>
                  {fields.map((field, index) => (
                     <Grid container key={field.id} spacing={2}>
                        <Grid item xs={3}>
                           <FormInputText
                              name={`values.${index}.label`}
                              label="Label"
                              placeholder="Enter label"
                           />
                        </Grid>

                        <Grid item xs={3}>
                           <FormInputText
                              name={`values.${index}.value`}
                              label="Value"
                              placeholder="Enter value"
                           />
                        </Grid>
                        <Grid item xs={3}>
                           <FormInputText
                              name={`values.${index}.externalId`}
                              label="External ID"
                              placeholder="Enter external id"
                           />
                        </Grid>

                        <Grid item xs={3}>
                           <IconButton onClick={() => remove(index)}>
                              <DeleteForever/>
                           </IconButton>
                        </Grid>
                     </Grid>
                  ))}

                  {/* Add Button for Values */}
                  <Grid item xs={12}>
                     <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AddCircle/>}
                        onClick={() =>
                           append({
                              label: "",
                              value: "",
                              externalId: "",
                              sortorder: fields.length
                           })
                        }
                     >
                        Add Value
                     </Button>
                  </Grid>
               </PageContent>
            </form>
         </FormProvider>

         {/* Dialog for Importing Values */}
         <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Import Values</DialogTitle>
            <DialogContent>
               <TextField
                  variant="filled"
                  margin="none"
                  label="Enter values (one per line)"
                  multiline
                  rows={4}
                  fullWidth
                  value={importText}
                  onChange={(e) => setImportText(e.target.value)}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleDialogClose} color="primary">
                  Cancel
               </Button>
               <Button onClick={handleImport} color="primary">
                  Import
               </Button>
            </DialogActions>
         </Dialog>

      </Box>
   );
};

// Function to map FormDataValue to OptionValueInput
const mapFormDataValueToOptionValueInput = (value: FormDataValue): OptionValueInput => {
   return {
      label: value.label || "",
      value: value.value || "",
      externalId: value.externalId,
      sortorder: value.sortorder,
      customData: value.customData,
      localizations: value.localizations, // Assuming localizations is already correctly formatted
      deleted: false // Assuming you don't want these values deleted by default
   };
};

// Function to map FormData to OptionSetInput
export const mapFormDataToOptionSetInput = (formData: FormData): OptionSetInput => {
   return {
      active: formData.active,
      externalId: formData.externalId,
      id: {
         _id: formData.id,
         externalId: formData.externalId,
      },
      key: formData.key,
      refId: formData.refId,
      refType: EnumOptionSetRefType.BRANDHOUSE,
      target: formData.target,
      title: formData.title,
      valueType: formData.valueType,
      values: formData.values?.map(mapFormDataValueToOptionValueInput), // Map the values array to OptionValueInput
   };
};
