import * as React from "react";
import {TitleText} from "./Typography";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../atoms/CurrentUser";
import {isInsideIframe} from "../MainWindow";

type PageHeaderProps = {
   title: string | JSX.Element
   onBack?: boolean
}

const PageHeader: React.FC<PageHeaderProps> = ({title, onBack, children}) => {
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const containerRef = React.useRef<HTMLDivElement | null>(null);

   const classes = useStyles();
   const navigate = useNavigate();

   const onHandleBack = () => {
      navigate(-1)
   }

   const showTopBar = !isBrandHouse || !isInsideIframe();

   return <div ref={containerRef} className={classes.fixed} style={{
      top: showTopBar ? '50px' : '0'
   }}>
      <div className={classes.root}>
         <div className={classes.cell}>
            {onBack && window.history?.length > 0 &&
            <Button color={"secondary"} variant={"outlined"} size={"small"} onClick={onHandleBack}>
               <ArrowBackIcon/>
            </Button>}
            <TitleText type={"h1"}
                       style={{display: 'inline-block', marginLeft: '20px', position: 'relative', top: '4px'}}>
               {title}
            </TitleText>
         </div>
         <div>
            {children}
         </div>
      </div>
   </div>
}


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      fixed: {
         marginLeft: '0px',
         backgroundColor: '#f6f6f6',
         width: '100%',
         paddingTop: '10px',
         paddingBottom: '1px',
         position: 'sticky',
         zIndex: '1100',
         marginBottom: '2px'
      },
      root: {
         display: 'flex',
         justifyContent: 'space-between',
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
         marginBottom: theme.spacing(1),
      },
      cell: {
         paddingTop: '10px',
         display: 'flex',
         flexDirection: 'row',
         alignItems: 'center'
      }
   }),
);

export default PageHeader