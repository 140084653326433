import React, {FC, useEffect} from "react";
import { Rating } from '@mui/material';
import {RatingProps} from "@mui/lab/Rating/Rating";
import {BodyText} from "../../../layout/Typography";

export type ConditionOption = {
   condition: number
   name: string
   description: string
}

type ConditionProps = RatingProps & {
   conditions: ConditionOption[]
   current: number | null
   onConditionChange?: (condition: number | null) => void
   hideDetails?: boolean
}

export const Condition: FC<ConditionProps> = ({current, conditions, onConditionChange, hideDetails, ...props}) => {
   const [sorted, setSorted] = React.useState<ConditionOption[] | undefined>()
   const [value, setValue] = React.useState<number | null>(null)

   useEffect(() => {
      let conds = [...conditions];
      let conditionOptions = conds.sort((a, b) => {
         return a.condition - b.condition
      });
      setSorted(conditionOptions);
   }, [conditions])

   useEffect(() => {
      if (sorted) {
         for (let i = 1; i <= sorted.length; i++) {
            const condition = sorted[i - 1];
            if (condition.condition === current) {
               setValue(i)
            }
         }
         if (value === null) {
            setValue(0)
         }
      }
   }, [current, sorted, value]);

   const onRateChange = (e: any, value: number | null) => {
      if(onConditionChange) {
         if (value && sorted) {
            onConditionChange(sorted[value - 1].condition);
         } else {
            onConditionChange(null);
         }
      }
   }

   if (value === null || !sorted) return <></>

   return <div>
      <Rating
         size={"large"}
         value={value}
         max={conditions.length}
         onChange={onRateChange}
         readOnly={!onConditionChange}
         precision={1} {...props} />
      {!hideDetails && <BodyText type={"body2"}>{value > 0 && sorted[value - 1].name}</BodyText>}
   </div>
}