import {FC, useState} from "react";
import {DataColumn, DataTable} from "../../components/DataTable";
import {
   FilterFindManyTrackingInput,
   Tracking,
   useListTrackingBrandQuery,
   useListTrackingStockItemQuery,
   useListTrackingVariantByIdQuery,
   useListTrackingViewDataQuery
} from "../../Queries";
import {Chip, LinearProgress, Popover} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import {StockItemEditPagePath} from "../shop/stockitems/edit/StockItemEditPage";
import {ProductDetailPagePath} from "../shop/masterdata/products/ProductDetailPage";

type TrackingViewProps = {
   brandColumn?: boolean
   filter: FilterFindManyTrackingInput
};

export const TrackingViewList: FC<TrackingViewProps> = ({
                                                       brandColumn,
                                                       filter,
                                                    }) => {
   const {data} = useListTrackingViewDataQuery({
      variables: {
         filter: filter
      },
      pollInterval: 2000
   });

   let columns: DataColumn<Tracking>[] = [{
      field: 'ts',
      label: 'Time',
      render: (time: string, row: Tracking) => {
         let momentInTime = dayjs(time);
         const ageSeconds = Math.abs((new Date().getTime() - momentInTime.toDate().getTime()) / 1000);
         let color: string = "#F8F8F8";
         if (ageSeconds < 60) {
            color = "#00FF00";
         } else if (ageSeconds < 120) {
            color = "#40FF40";
         } else if (ageSeconds < 1200) {
            color = "#80FF80";
         } else if (ageSeconds < 3600) {
            color = "#C0FFC0";
         } else if (ageSeconds < 7200) {
            color = "#F0FFF0";
         }
         return <div style={{display: 'flex'}}>
            <div style={{
               marginRight: '16px',
               border: '1px solid #808080',
               width: '20px',
               backgroundColor: color,
               borderRadius: '50%'
            }}/>
            {momentInTime.format('HH:mm:ss, D. MMM')}
         </div>
      },
      width: '20%'
   }, {
      field: 'objectId',
      render: (objectId: any, row: Tracking) => {
         if (row.objectType === 'stockItem') {
            return <StockChip stockItemId={objectId}/>
         } else if (row.objectType === 'circularinfo') {
            return <CircularChip variantId={objectId}/>
         } else if (row.objectType === 'tradeInLogin') {
            return <div>TradeIn Login</div>
         } else if (row.objectType === 'tradeInFront') {
            return <div>TradeIn Frontpage</div>
         } else if (row.objectType === 'hummelPoint') {
            return <HummelPoint row={row}/>
         } else {
            return <div>{row.objectType}</div>
         }
      },
      label: 'Object',
      width: '40%'
   }, {
      field: 'ip',
      label: 'User',
      render: (ip, row: Tracking) => {
         return <div style={{display: 'flex', gap: '10px'}}>
            {row.browser && <UserChip userAgent={row.browser}/>}
            {row.ip?.split(":")[0]}
         </div>
      },
      width: '40%'
   }];

   if (brandColumn) {
      columns[0].width = '20%';
      columns[columns.length - 1].width = '20%';
      columns.splice(1, 0, {
         field: 'shopId',
         label: 'Shop',
         render: (bid, row) => {
            return <BrandChip shopId={row.shopId}/>
         },
         width: '20%'
      })
   }

   if (!data) {
      return <LinearProgress/>
   }

   return <DataTable idField={"_id"} rows={data?.TrackingMany} columns={columns}
                     order={"desc"} orderBy={0}/>
}

const StockChip: FC<{ stockItemId: string }> = ({stockItemId}) => {
   const {data} = useListTrackingStockItemQuery({
      variables: {
         stockItemId: stockItemId
      }
   });
   const classes = useStockItemStyles();
   const navigate = useNavigate();

   const onClick = () => {
      const shopId = data?.StockItemById?.shopId;
      const brandHouseId = data?.StockItemById?.shop?.brandHouseId;
      navigate(StockItemEditPagePath(brandHouseId, shopId, stockItemId))
   }

   return <div className={classes.stockItemContainer} onClick={onClick}>
      <img style={{maxWidth: '30px', maxHeight: '30px'}} src={data?.StockItemById?.imageUrls?.[0]} alt={"Packshot"}/>
      <div>{data?.StockItemById?.title}</div>
      {data?.StockItemById?.isSold && <Chip label={"Sold"}/>}
   </div>
}

const useStockItemStyles = makeStyles({
   stockItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center'
   },
});

const UserChip: FC<{ userAgent: string }> = ({userAgent}) => {
   const classes = useUserStyles();

   let type: 'android' | 'apple' | 'desktop'
   if (/android/i.test(userAgent)) {
      type = 'android'
   } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      type = 'apple'
   } else {
      type = 'desktop'
   }

   return <div className={classes.agentContainer}>
      {type === 'android' && <AndroidIcon/>}
      {type === 'apple' && <AppleIcon/>}
      {type === 'desktop' && <DesktopWindowsIcon/>}
   </div>
}

const useUserStyles = makeStyles({
   agentContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center'
   },
});


const BrandChip: FC<{ shopId: string }> = ({shopId}) => {
   const {data} = useListTrackingBrandQuery({
      variables: {
         shopId: shopId
      }
   });
   const classes = useBrandStyles();

   return <div className={classes.brandContainer}>
      <div>{data?.ShopById?.name}</div>
   </div>
}

const useBrandStyles = makeStyles({
   brandContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center'
   },
});


const CircularChip: FC<{ variantId: string }> = ({variantId}) => {
   const {data} = useListTrackingVariantByIdQuery({
      variables: {
         id: variantId
      }
   });
   const classes = useStockItemStyles();
   const navigate = useNavigate();

   const onClick = () => {
      const brandHouseId = data?.ProductVariant?.shop?.brandHouseId
      const shopId = data?.ProductVariant?.shopId
      const productId = data?.ProductVariant?.productId

      if(brandHouseId && shopId && productId) {
         navigate(ProductDetailPagePath(brandHouseId, shopId, productId))
      }
   }

   return <div className={classes.stockItemContainer} onClick={onClick}>
      <img style={{maxWidth: '30px', maxHeight: '30px'}} src={data?.ProductVariant?.imageUrls?.[0]} alt={"Packshot"}/>
      <div>{data?.ProductVariant?.product?.name}</div>
      <Chip label={"Circular Info"}/>
   </div>
}

const HummelPoint: FC<{ row: Tracking }> = ({row}) => {
   const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

   const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const data = row.objectPayload;

   if (typeof data === 'number' || typeof data === 'string') {
      return <div>Loyalty points {data}</div>
   } else {
      let id = 'points_' + row._id;
      return <>
         <div aria-describedby={id} onClick={handleClick}>Loyalty points {data.value}</div>
         <Popover open={Boolean(anchorEl)} onClose={handleClose} id={id} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
         }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
         </Popover>
      </>
   }
}
