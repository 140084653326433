import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {TreeView} from "@mui/lab";
import * as React from "react";
import {FC} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {StyledTreeItem} from "./layout/TreeViewItem";
import {atom, useRecoilValue, useSetRecoilState} from "recoil";
import {NavigationDataAtom, NavigationItem, NavigationLeaf} from "./navigation/NavigationController";
import {getNavigationItem, NavigationStateAtom} from "./navigation/NavigationStateController";
import {CurrentUserIsAdmin, UserSimplified} from "./atoms/CurrentUser";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import {IconButton} from "@mui/material";

export const PreviousPathAtom = atom<string>({
   key: 'SettingsPreviousPath',
   default: ''
})

export const Hierarchy = () => {
   const setPreviousPath = useSetRecoilState(PreviousPathAtom);

   const classes = useStyles();
   const navigate = useNavigate();
   const location = useLocation();

   const navigationData = useRecoilValue(NavigationDataAtom);
   const navigationState = useRecoilValue(NavigationStateAtom);

   const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
      const item = getNavigationItem(nodeId, navigationState, navigationData?.items || []);
      if (item) {
         setPreviousPath(location.pathname);
         navigate(item.path);
      }
   };

   return <TreeView className={classes.treeRoot}
                    defaultCollapseIcon={<ArrowDropDownIcon/>}
                    defaultExpandIcon={<ArrowRightIcon/>}
                    selected={navigationState?.selected?.id || undefined}
                    onNodeSelect={handleSelect}
                    expanded={navigationState?.selectedPath.map(path => path.id) || undefined}
   >
      {navigationData?.items.map(item => {
         return <HierarchyItem key={item.id} item={item}/>
      })}
   </TreeView>
}

const HierarchyItem: FC<{ item: NavigationItem }> = ({item}) => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const isSimplifiedUser = useRecoilValue(UserSimplified);
   if (item.hide) {
      if (!isAdministrator) {
         return null;
      } else if(isSimplifiedUser) {
         return null
      } else if (item.hideScope === 'all') {
         return null;
      }
   }

   return <StyledTreeItem
      key={item.id}
      nodeId={item.id}
      labelIcon={item.icon}
      sx={{opacity: item.disabledNotice ? 0.6 : undefined}}
      labelText={item.title + (item.disabledNotice ? ' ' + item.disabledNotice : '')}
      action={item.action ? <ActionItem item={item.action}/> : undefined}
   >
      {item.children && item.children.length > 0 && item.children.map(item => {
         return <HierarchyItem key={item.id} item={item}/>
      })}
   </StyledTreeItem>
}

const ActionItem = ({item}: { item: NavigationLeaf }) => {
   const setPreviousPath = useSetRecoilState(PreviousPathAtom);
   const navigate = useNavigate();
   const location = useLocation();

   const handleClick = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setPreviousPath(location.pathname);
      navigate(item.path);
   }

   const Icon = item.icon;

   return <IconButton onClick={handleClick} style={{padding: 2}} sx={{
      "&:hover": {
         color: '#808080'
      },
      color: '#c0c0c0'
   }}>
      <Icon fontSize={"small"}/>
   </IconButton>
}

const useStyles = makeStyles(theme => ({
   treeRoot: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400
   }
}));
