import React, {useRef, useState} from 'react';
import {Cropper, CropperRef} from 'react-advanced-cropper';
import {AppBar, Box, IconButton, Slider, ToggleButton, ToggleButtonGroup, Toolbar} from "@mui/material";
import Container from "@mui/material/Container";
import {Contrast, FormatPaint, HighlightAlt, LightMode, RotateLeft, RotateRight} from "@mui/icons-material";
import {AdjustableCropperBackground} from "./AdjustableCropperBackground";
import Button from "@mui/material/Button";
import {CropperState} from "advanced-cropper/types";
import {getZoomFactor} from 'advanced-cropper/extensions/absolute-zoom';

export interface ImageEditorProps {
   imageUrl: string;
   onSave: (image: Blob) => Promise<void>
}

export const ImageEditor = ({imageUrl, onSave}: ImageEditorProps) => {
   const cropperRef = useRef<CropperRef>(null)
   const [src] = useState(
      imageUrl
   );
   const [mode, setMode] = useState<'crop' | 'brightness' | 'hue' | 'saturation' | 'contrast' | 'erase'>('crop');
   const [adjustments, setAdjustments] = useState({
      brightness: 0,
      hue: 0,
      saturation: 0,
      contrast: 0,
      editable: false
   });

   const onChangeValue = (e: any, value: any) => {
      if (mode in adjustments) {
         setAdjustments((previousValue) => ({
            ...previousValue,
            editable: false,
            [mode]: value,
         }));
      }
   };

   const rotate = (angle: number) => {
      if (cropperRef.current) {
         const cropper = cropperRef.current;

         cropper.rotateImage(angle);

         cropper.zoomImage({
            factor: getZoomFactor(cropper.getState(), cropper.getSettings(), 0.0)
         })

         const image = cropper.getImage();
         cropper.setCoordinates({
            top: 0,
            left: 0,
            width: (image?.width || 1000) * 2,
            height: (image?.height || 1000) * 2
         })
      }
   };

   const onImageSave = async () => {
      const cropper = cropperRef.current;
      if (cropper) {
         const canvas = cropper.getCanvas();
         if (!canvas) {
            return;
         }
         canvas.toBlob(async (blob) => {
            if (blob) {
               await onSave(blob);
            }
         });
         cropper.zoomImage(0)
      }
   };

   const defaultSize = ({imageSize, visibleArea}: CropperState) => {
      return {
         width: (visibleArea || imageSize).width,
         height: (visibleArea || imageSize).height,
      };
   }

   const changeMode = (newMode: any) => {
      setMode(newMode);
      setAdjustments((previousValue) => ({
         ...previousValue,
         editable: newMode==='erase'
      }));
   }

   const cropperEnabled = mode === 'crop';

   return (
      <Box>
         <AppBar position={"static"} color={"default"} variant={"outlined"}>
            <Container maxWidth="xl">
               <Toolbar disableGutters variant={"dense"}>
                  <IconButton onClick={() => rotate(-90)} title={"Rotate left"}>
                     <RotateLeft/>
                  </IconButton>
                  <IconButton onClick={() => rotate(90)} title={"Rotate right"}>
                     <RotateRight/>
                  </IconButton>
                  <ToggleButtonGroup value={mode} size={"small"}>
                     <ToggleButton value={"crop"} onClick={() => changeMode('crop')} title={"Crop"}>
                        <HighlightAlt/>
                     </ToggleButton>
                     <ToggleButton value={"brightness"} onClick={() => changeMode('brightness')} title={"Brightness"}>
                        <LightMode/>
                     </ToggleButton>
                     <ToggleButton value={"contrast"} onClick={() => changeMode('contrast')} title={"Contrast"}>
                        <Contrast/>
                     </ToggleButton>
                     <ToggleButton value={"erase"} onClick={() => changeMode('erase')} title={"Erase"}>
                        <FormatPaint/>
                     </ToggleButton>
                  </ToggleButtonGroup>
                  <Box sx={{marginLeft: 0, width: '100%', textAlign: 'right'}}>
                     <Button variant={"contained"} onClick={onImageSave}>
                        Update
                     </Button>
                  </Box>
               </Toolbar>
            </Container>
         </AppBar>
         <Box sx={{position: 'relative'}}>
            <Cropper
               ref={cropperRef}
               style={{
                  objectFit: 'contain', maxWidth: '100%', height: '80vh',
                  cursor: mode === 'erase' ? 'crosshair' : undefined
               }}
               src={src}
               stencilProps={{
                  movable: cropperEnabled,
                  resizable: cropperEnabled,
                  lines: cropperEnabled,
                  handlers: cropperEnabled,
               }}
               backgroundWrapperProps={{
                  scaleImage: true,
                  moveImage: cropperEnabled,
               }}
               defaultSize={defaultSize}
               backgroundComponent={AdjustableCropperBackground}
               backgroundProps={adjustments}
            />
            {(mode !== 'crop' && mode !== 'erase') && (
               <Box sx={{
                  backgroundColor: 'black',
                  height: '30px',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  opacity: 0.5
               }}>
                  <Box sx={{position: 'absolute', top: 0, left: "30%", right: "30%"}}>
                     <Slider sx={{color: 'white'}} valueLabelDisplay={"on"} size={"small"} step={0.01} min={-1} max={1}
                             value={(adjustments as any)[mode]} onChange={onChangeValue}/>
                  </Box>
               </Box>
            )}
         </Box>
      </Box>
   );
};