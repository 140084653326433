import React, {FC, useState} from "react";
import {Thumbnail} from "./Thumbnail";
import {Box, Dialog, DialogContent, ImageList, ImageListItem, useMediaQuery} from "@mui/material";
import {MissingImageIcon} from "./Icons";

type ThumbnailsProps = {
   imageUrls: string[] | undefined
   size: number
   maxImages?: number
   legend?: string
   legendPosition?: 'left'|'right'
}

export const Thumbnails: FC<ThumbnailsProps> = ({imageUrls, size, legend, legendPosition, maxImages}) => {
   const [show, setShow] = useState<boolean>(false);
   const isDesktop = useMediaQuery('(min-width:800px)');

   const onClick = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setShow(true);
   }

   const onClose = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setShow(false)
   }

   const image = imageUrls?.[0]

   const legendPositionLeft = {
      textAlign: 'left', position: 'absolute', top: '-2px', left: 0, right: '0'
   }
   const legendPositionRight = {
      textAlign: 'right', position: 'absolute', top: '-2px', left: 0, right: '0'
   }

   const legendPositionStyle = legendPosition === 'left' ? legendPositionLeft : legendPositionRight

   return <div style={{position: 'relative', width: (size + 8) + 'px'}}>
      {image && <>
         {(legend || imageUrls!.length > 0) &&
            <div onClick={onClick} style={legendPositionStyle as any}>
               <Box onClick={onClick} sx={{
                  display: 'inline-block',
                  bottom: '0',
                  padding: '1px 6px 1px 6px',
                  color: '#000',
                  fontWeight: 'bold',
                  border: '1px solid #808080',
                  opacity: 0.7,
                  backgroundColor: '#fff',
                  borderRadius: '4px'
               }}>
                  {legend || imageUrls.length}
               </Box>
            </div>}
         <Thumbnail image={image} size={size} onClick={onClick}/>
         {show && <Dialog open={show} onClose={onClose} fullScreen={true} sx={{margin: '40px'}}>
            <DialogContent sx={{width: '100%', height: '100%'}} onClick={onClose}>
               <ImageList variant="masonry" cols={!isDesktop ? 1 : imageUrls.length < 3 ? imageUrls.length : 3} gap={8}>
                  {imageUrls.map((url) => {
                     return <ImageItem url={url}/>
                  })}
               </ImageList>
            </DialogContent>
         </Dialog>
         }
      </>}
      {!image && <Box sx={{
         display: 'flex',
         justifyContent: "center",
         border: '1px solid #e0e0e0',
         margin: '5px',
         alignItems: "baseline",
         cursor: 'pointer',
         position: 'relative'
      }}>
         {legend && <div style={{textAlign: 'left', position: 'absolute', top: '-2px', left: 0, right: '0'}}>
            <Box onClick={onClick} sx={{
               display: 'inline-block',
               bottom: '0',
               padding: '1px 6px 1px 6px',
               color: '#000',
               fontWeight: 'bold',
               border: '1px solid #808080',
               opacity: 0.7,
               backgroundColor: '#fff',
               borderRadius: '4px'
            }}>
               {legend}
            </Box>
         </div>}
         <MissingImageIcon size={size + 'px'}/>
      </Box>}
   </div>
}

const ImageItem = ({
                      url
                   }: {
   url: string
}) => {
   const [displayed, setDisplayed] = useState<boolean>(false);
   const onLoadImage = () => {
      setDisplayed(true);
   }
   let loadingPlaceholder = displayed ? undefined : {
      backgroundImage: 'url("https://storageaccountourimbea2.blob.core.windows.net/web/imgloading.gif")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
   };
   return <ImageListItem key={url}>
      <img
         onLoad={onLoadImage}
         style={loadingPlaceholder}
         src={`${url}?w=248&fit=crop&auto=format`}
         srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
         alt={url}
         loading="lazy"
      />
   </ImageListItem>
}
