import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import QRCode from "react-qr-code";
import {useRecoilState} from "recoil";
import {CurrentUserState, QrSessionInfo, UserInfo} from "../../../atoms/CurrentUser";
import PageHeader from "../../../layout/PageHeader";
import {useTradeInReceiveQrInitMutation} from "../../../Queries";

export const QrApp = () => {
   const [session, setSession] = React.useState<string|undefined>();
   const {logisticsPartnerId} = useParams<{ logisticsPartnerId: string }>();
   const [currentUser, setCurrentUser] = useRecoilState(CurrentUserState);
   const [sessionCreate] = useTradeInReceiveQrInitMutation()

   useEffect(() => {
      if(!currentUser?.qrSession) {
         (async function () {
            let newSession = await sessionCreate({
               variables: {
                  logisticsPartnerId: logisticsPartnerId
               }
            });
            setSession(newSession.data?.QRSessionCreate?._id);
         })();
      } else {
         setSession(currentUser?.qrSession.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if(!currentUser?.qrSession && session) {
         let updatedUser = {
            ...currentUser,
            qrSession: {
               id: session,
               state: 'receiver'
            } as QrSessionInfo
         } as UserInfo
         setCurrentUser(updatedUser)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [session])

   if (!session) {
      return <LinearProgress/>
   }

   const baseUrl = window.location.protocol + "//" + window.location.host;

   return <Page>
      <PageHeader title={"App"}/>
      <PageContent>
         <QRCode name={"test"} level={"L"} size={400} value={baseUrl + "/login/" + sessionStorage.getItem('accessToken') + "/" + session}/>
      </PageContent>
   </Page>
}