import {LinearProgress} from "@mui/material";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {useNavigate} from "react-router-dom";
import {ShippingCarrierEditPath} from "./ShippingCarrierEdit";
import {ShippingCarrierInput, useShippingCarriersLpQuery} from "../../../Queries";

export const ShippingCarriers = () => {
   const navigate = useNavigate();

   const {data} = useShippingCarriersLpQuery()

   if (!data) {
      return <LinearProgress/>
   }

   const shippingCarriers = data.ShippingCarriersLP||[]

   let columns: DataColumn<ShippingCarrierInput>[] = [{
      field: 'name',
      label: 'Name',
      width: '25%'
   }, {
      field: 'description',
      label: 'Description',
      width: '40%'
   }, {
      field: 'expression',
      label: 'Visibility expression'
   }]

   const onSelect = (row: any) => {
      navigate(ShippingCarrierEditPath(row._id))
   }

   return <Page>
      <PageHeader title={"Shipping carriers"}/>
      <PageContent>
         <DataTable stateId={"shipping"} idField={"_id"} rows={shippingCarriers} columns={columns}
                    onSingleSelect={onSelect}/>
      </PageContent>
   </Page>
}