import {gql, useQuery} from "@apollo/client";
import {BrandData} from "./BrandApiType";

const qlBrandApi = gql`
   query BrandApiGet($shopId: MongoID!) {
      BrandApiGet(filter: {shopId:$shopId}) {
         _id
         sendgrid {
            token
            from
            fromEmail
         }
         b2ceurope {
            userid
            password
         }
         burd {
            username
            password
         }
         externShop {
           serviceBus
           topic
         }
         autoretouch {
            workflowId
            autoProcess
            autoRotate
         }
         traede {
            enabled
            accessToken
            itemNumberPrefix
         }
         spy {
             enabled
             captureUrl
             refundUrl
         }
         shopify {
             enabled
             capture
             refund
             shopifyLocationId
             options {
                 color
                 colorName
                 size
                 sizeName
             }
         }
         autoPublish
         googleTrackingId
      }
      AutoRetouchWorkflows(shopId: $shopId) {
         id
         name
      }
   }
`;

const useBrandApi = (shopId: string): BrandData | null => {
   const {data} = useQuery(qlBrandApi, {
      variables: {
         shopId: shopId
      }
   });

   if (!data) {
      return null;
   }

   return data as BrandData
}

export default useBrandApi