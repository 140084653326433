import {Link} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import {FC} from "react";

type LinkToProps = {
   to: string
   newTab?: boolean
}

export const LinkTo: FC<LinkToProps> = ({to, newTab, children}) => {
   let props = {}
   if (newTab) {
      props = {
         target: "_blank",
         rel: "noreferrer"
      }
   }
   return <Link component={RouterLink} to={to} {...props}>
      {children}
   </Link>
}