import * as React from "react";
import {FC} from "react";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {StatOrderChart} from "../../analytics/StatOrdersChart";
import {getSplits} from "../../analytics/AnalyticsUtility";
import {BrandSelection, BrandSelector} from "../../analytics/BrandSelector";
import {StatTradeInChart} from "../../analytics/StatTradeInChart";
import PageGroup from "../../../layout/PageGroup";
import {StatOrderTable} from "../../analytics/StatOrderTable";
import {StatTradeInTable} from "../../analytics/StatTradeInTable";
import {StatDevelopmentTable} from "../../analytics/StatDevelopmentTable";
import {getDateRange, PeriodSelector} from "../../analytics/PeriodSelector";
import {StatWarehouseChart} from "../../analytics/StatWarehouseChart";
import {DateRange} from "@mui/x-date-pickers-pro";
import {StatTrackingChart} from "../../analytics/StatTrackingChart";
import {StatTrackingTable} from "../../analytics/StatTrackingTable";
import {StatInvoiceChart} from "../../analytics/StatInvoiceChart";
import {StatInvoiceTable} from "../../analytics/StatInvoiceTable";
import {Box} from "@mui/material";
import {SimpleSelect} from "../../../components/SimpleSelect";
import useSessionStorage from "../../../hooks/useSessionStorage";

export const LogAnalyticsPage: FC<any> = () => {
   const [brand, setBrand] = useSessionStorage<BrandSelection>('LogAnalyticsPage.brands', {
      currency: 'DKK',
      shopIds: [],
      brandHouseIds: []
   })
   const [value, setValue] = React.useState<DateRange<Date>>(getDateRange('1W'));

   const [start, end] = value;

   const splits = getSplits(start!, end!)

   const onBrandChange = async (brand: BrandSelection) => {
      setBrand(brand);
   }

   const onChange = (newValue: DateRange<Date>) => {
      setValue(newValue);
   }

   const onCurrencyChange = (e: any) => {
      setBrand({
         ...brand,
         currency: e.target.value
      })
   }

   return <Page>
      <PageHeader title={"Dashboard"}>
         <PeriodSelector onChange={onChange} defaultPeriod={'1W'}/>
      </PageHeader>
      <PageContent>
         <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
               <BrandSelector value={brand} onChange={onBrandChange}/>
            </Box>
            <Box>
               <SimpleSelect options={[{
                  id: 'DKK',
                  name: 'Danske Kroner (DKK)'
               }, {
                  id: 'EUR',
                  name: 'Euro (€)'
               }]} onSelectChange={onCurrencyChange} label={"Currency"} value={brand.currency}/>
            </Box>
         </Box>
      </PageContent>
      {start && end &&
         <PageGroup widths={['65%', '35%']}>
            <PageContent style={{height: '400px'}}>
               <StatOrderChart start={start!} end={end!} splits={splits} brand={brand}/>
            </PageContent>
            <PageContent style={{height: '400px'}}>
               <StatOrderTable start={start!} end={end!} brand={brand} splits={splits}/>
            </PageContent>
         </PageGroup>
      }
      {start && end &&
         <PageGroup widths={['65%', '35%']}>
            <PageContent style={{height: '400px'}}>
               <StatInvoiceChart start={start!} end={end!} splits={splits} brand={brand}/>
            </PageContent>
            <PageContent style={{height: '400px'}}>
               <StatInvoiceTable start={start!} end={end!} brand={brand} splits={splits}/>
            </PageContent>
         </PageGroup>
      }
      {start && end &&
         <PageGroup widths={['65%', '35%']}>
            <PageContent style={{height: '400px'}}>
               <StatTradeInChart start={start!} end={end!} brand={brand} splits={splits} onlyTotals={false}/>
            </PageContent>
            <PageContent style={{height: '400px'}}>
               <StatTradeInTable start={start!} end={end!} brand={brand} splits={splits}/>
            </PageContent>
         </PageGroup>
      }
      {start && end &&
         <PageGroup widths={['65%', '35%']}>
            <PageContent style={{minHeight: '400px'}}>
               <StatTrackingChart start={start!} end={end!} brand={brand} splits={splits}/>
            </PageContent>
            <PageContent style={{minHeight: '400px'}}>
               <StatTrackingTable start={start!} end={end!} brand={brand} splits={splits}/>
            </PageContent>
         </PageGroup>
      }
      {start && end &&
         <PageGroup widths={['65%', '35%']}>
            <PageContent style={{height: '400px'}}>
               <StatWarehouseChart start={start!} end={end!} brand={brand} splits={splits} onlyTotals={true}/>
            </PageContent>
            <PageContent style={{height: '400px'}}>
            </PageContent>
         </PageGroup>
      }
      {start && end &&
         <PageContent>
            <StatDevelopmentTable start={start!} end={end!} splits={splits} brand={brand}/>
         </PageContent>
      }
   </Page>
}

