import React, {FC, useEffect} from "react";
import {
   Box,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   Divider,
   LinearProgress,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem,
   Snackbar
} from "@mui/material";
import {ImageUpload} from "../../../components/ImageUpload";
import {createUploadService} from "../../../UploadService";
import {Dialogs} from "../../../DialogProvider";
import Button from "@mui/material/Button";
import {Chat, Error, RadioButtonChecked, RadioButtonUnchecked, Redo, Undo} from "@mui/icons-material";
import {Theme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
   StockItemImagesDocument,
   useStockItemImageAltTextQuery,
   useStockItemImageAltTextSaveMutation,
   useStockItemImageAutoRetouchMutation,
   useStockItemImageCreateMutation,
   useStockItemImageDeleteMutation,
   useStockItemImageQuery,
   useStockItemImageSetOrderMutation,
   useStockItemImagesQuery,
   useStockItemImagesRotateMutation
} from "../../../Queries";
import {ImportDialog} from "./ImportDialog";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputText} from "../wholesale/form/FormInputText";
import {SavingUI} from "../../../SavingProvider";

type StockItemImagesProps = {
   shopId: string
   stockItemId: string
}

export const StockItemImages: FC<StockItemImagesProps> = ({shopId, stockItemId}) => {
   const [altTextImage, setAltTextImage] = React.useState<string | undefined>();
   const [menuImage, setMenuImage] = React.useState<string | undefined>();
   const [importing, setImporting] = React.useState<boolean>(false);
   const [createImage] = useStockItemImageCreateMutation();
   const [deleteImage] = useStockItemImageDeleteMutation();
   const [updateImage, {loading}] = useStockItemImageSetOrderMutation();
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [rotating, setRotating] = React.useState<boolean>(false);
   const [rotateImage] = useStockItemImagesRotateMutation();

   const {data} = useStockItemImagesQuery({
      variables: {
         shopId: shopId,
         stockItemId: stockItemId
      }
   })

   const classes = useStyles();
   const open = Boolean(anchorEl);
   const images = data?.StockItemImages;

   if (!images) {
      return <LinearProgress/>
   }

   const handleClick = (imageId: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setMenuImage(imageId);
      setAnchorEl(event.currentTarget);
   };

   const onImport = () => {
      setImporting(true);
   }

   const onMove = (direction: 'up' | 'down') => async () => {
      if (!menuImage) {
         return;
      }
      const activeImage = images?.find(img => String(img?._id) === menuImage);
      if (!activeImage) {
         return;
      }
      const activeIndex = images?.indexOf(activeImage);
      let swapImage: any;
      if (direction === 'up') {
         if (activeIndex === 0) {
            return;
         }
         swapImage = (images?.[activeIndex - 1]) || undefined
      } else if (direction === 'down') {
         if (activeIndex === images?.length) {
            return;
         }
         swapImage = (images?.[activeIndex + 1]) || undefined
      }

      if (!swapImage) {
         return;
      }

      const swap = (arr: any[], p1: number, p2: number) => {
         let newArr = [...arr];
         newArr[p1] = newArr.splice(p2, 1, newArr[p1])[0];
         return newArr;
      }

      const imageIds = images?.map(image => String(image?._id));
      const swapIndex = images?.indexOf(swapImage);

      const newOrder = swap(imageIds!, activeIndex, swapIndex);

      await updateImage({
         variables: {
            shopId: shopId,
            stockItemId: stockItemId,
            imageIds: newOrder
         },
         refetchQueries: [
            StockItemImagesDocument,
            'StockItemImages'
         ]
      });

   }

   const onRotate = (rotate: number) => async () => {
      if (!menuImage) {
         return;
      }
      const activeImage = images?.find(img => String(img?._id) === menuImage);
      if (!activeImage) {
         return;
      }
      setRotating(true);
      try {
         await rotateImage({
            variables: {
               shopId: shopId,
               imageId: activeImage._id,
               rotate: rotate
            }
         });
      } catch (e) {
         Dialogs.info({
            title: 'Rotate failed',
            subtitle: 'Unable to rotate image, try again later'
         })
      }
      setRotating(false);
   }

   const addImages = async (imageIds: string[] | string) => {
      await createImage({
         variables: {
            shopId: shopId,
            stockItemId: stockItemId,
            uploadId: imageIds[0]
         },
         refetchQueries: [
            StockItemImagesDocument,
            'StockItemImages'
         ]
      });

      /*
            if (createdImage && createdImage.data) {
               Dialogs.confirm({
                  title: 'Retouch image?',
                  subtitle: 'This will remove background and ghost mannequins from the image.'
               }, async () => {
                  await retouchImage({
                     variables: {
                        shopId: shopId,
                        stockItemImageId: createdImage.data?.StockItemImageCreate?._id,
                        process: true
                     }
                  })
               });
            }
      */
   }

   const delImage = async (imageId: string) => {
      Dialogs.confirm({
         title: 'Delete image?',
         subtitle: 'This can not be undone.'
      }, async () => {
         await deleteImage({
            variables: {
               shopId: shopId,
               stockItemImageId: imageId
            },
            refetchQueries: [
               StockItemImagesDocument,
               'StockItemImages'
            ]
         });
      })
   }

   const uploadService = createUploadService(process.env.REACT_APP_FILESERVICEURL!, shopId)

   return <div className={classes.container}>
      {importing && <ImportDialog stockItemId={stockItemId} onHide={() => setImporting(false)}/>}
      {altTextImage && <ImageAltText imageId={altTextImage} onHide={() => setAltTextImage(undefined)}/>}
      <ImageUpload
         id={"stockImages"}
         uploadService={uploadService}
         onImport={onImport}
         images={images?.map(image => {
            if (image?.imageId) {
               return {
                  id: image._id,
                  url: image.imageUrl!,
                  otherUrl: image.sourceUrl
               }
            } else {
               return {
                  id: image!._id,
                  url: image!.sourceUrl
               }
            }
         })}
         onRender={(image) => {
            const img = images.find(img => img!._id === image.id);

            if (!img!.effectiveAltText) {
               return <></>
            }

            return <Box sx={{cursor: 'pointer', paddingTop: '10px', display: 'flex', justifyContent: 'center'}}
                        onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           setAltTextImage(String(img!._id))
                        }}
                        title={img!.effectiveAltText}
            >
               <div style={{
                  padding: '5px',
                  border: '1px solid #808080',
                  backgroundColor: 'white',
                  opacity: '0.7',
                  fontSize: '12px'
               }}>
                  {img!.effectiveAltText}
               </div>
            </Box>
         }}
         maxImages={10}
         addImages={addImages}
         delImage={delImage}
      >
         {(imageId) => {
            return <Button
               variant={"outlined"}
               aria-label="more"
               id={"long-button-" + imageId}
               aria-controls={open ? 'long-menu' : undefined}
               aria-expanded={open ? 'true' : undefined}
               aria-haspopup="true"
               onClick={handleClick(imageId)}
               startIcon={<MenuIcon/>}
               className={classes.menuButton}
               color={"secondary"}
            />
         }}
      </ImageUpload>
      {menuImage &&
         <Menu
            id={"long-menu"}
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={(e: any) => {
               e.preventDefault();
               e.stopPropagation();
               setAnchorEl(null)
               setMenuImage(undefined);
            }}
         >
            <StockImageButton imageId={menuImage} shopId={shopId}/>
            <Divider/>
            <MenuItem onClick={onRotate(-90)} disabled={rotating}>
               <ListItemIcon>
                  <Undo fontSize="small"/>
               </ListItemIcon>
               <ListItemText>
                  Rotate left
               </ListItemText>
            </MenuItem>
            <MenuItem onClick={onRotate(90)} disabled={rotating}>
               <ListItemIcon>
                  <Redo fontSize="small"/>
               </ListItemIcon>
               <ListItemText>
                  Rotate right
               </ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={onMove('up')} disabled={loading}>
               <ListItemIcon>
                  <MoveUpIcon fontSize="small"/>
               </ListItemIcon>
               <ListItemText>
                  Move up
               </ListItemText>
            </MenuItem>
            <MenuItem onClick={onMove('down')} disabled={loading}>
               <ListItemIcon>
                  <MoveDownIcon fontSize="small"/>
               </ListItemIcon>
               <ListItemText>
                  Move down
               </ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => setAltTextImage(menuImage)}>
               <ListItemIcon>
                  <Chat fontSize="small"/>
               </ListItemIcon>
               <ListItemText>
                  Alt text
               </ListItemText>
            </MenuItem>
         </Menu>
      }
   </div>
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         position: 'relative'
      },
      menuButton: {
         "& .MuiButton-startIcon": {
            margin: 0
         }
      },
      original: {
         position: 'relative',
         top: '25px',
         left: '10px',
         zIndex: 999,
         right: '10px',
         textAlign: 'center'
      }
   })
);

const StockImageButton: FC<{ shopId: string, imageId: string }> = ({shopId, imageId}) => {
   const [retouchSnack, setRetouchSnack] = React.useState<boolean>(false);

   const {data, startPolling, stopPolling, loading} = useStockItemImageQuery({
      variables: {
         shopId,
         stockItemImageId: imageId
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
   });

   const [retouchImage] = useStockItemImageAutoRetouchMutation();

   const doit = async (id: string) => {
      if (startPolling) {
         startPolling(2000);
      }
      setRetouchSnack(true);
      await retouchImage({
         variables: {
            shopId,
            stockItemImageId: id,
            process: true
         }
      });
   }

   const toggleProcess = (id: string) => async () => {
      if (data && data.StockItemImage?.imageId) {
         Dialogs.confirm({
            title: 'Restart autoretouch?',
            subtitle: 'Do not start multiple retouch sessions on the same product please.'
         }, async () => {
            await doit(id);
         });
      } else {
         await doit(id);
      }
   }

   useEffect(() => {
      if (data) {
         if (data.StockItemImage?.imageId && stopPolling) {
            stopPolling()
         } else if (data.StockItemImage?.process && !data.StockItemImage?.imageId && startPolling) {
            startPolling(2000);
         }
      }
   }, [data, startPolling, stopPolling])

   const onHideSnack = () => {
      setRetouchSnack(false);
   }

   let showProgress = (data?.StockItemImage?.process && !data?.StockItemImage?.imageId) || loading;

   return <><MenuItem onClick={toggleProcess(imageId)}>
      <ListItemIcon>
         {showProgress && <CircularProgress size={20}/>}
         {!showProgress && <>
            {data?.StockItemImage?.failed ? <Error/> : !data?.StockItemImage?.imageId ? <RadioButtonUnchecked/> :
               <RadioButtonChecked/>}
         </>}
      </ListItemIcon>
      <ListItemText>
         Retouch
      </ListItemText>
   </MenuItem>
      <Snackbar autoHideDuration={4000} open={retouchSnack} onClose={onHideSnack}
                message={(data?.StockItemImage?.process || data?.StockItemImage?.imageId) ? "AutoRetouch restarted" : "AutoRetouch started"}/>
   </>
}

type ImageAltTextProps = {
   imageId: string
   onHide: () => void
}

const ImageAltText = ({imageId, onHide}: ImageAltTextProps) => {
   const {data} = useStockItemImageAltTextQuery({
      variables: {
         imageId
      },
      fetchPolicy: "no-cache"
   });
   const [save] = useStockItemImageAltTextSaveMutation();

   type FormData = {
      altText: string
   }

   const methods = useForm<FormData>({
      defaultValues: {
         altText: ''
      }
   });
   const {reset, handleSubmit} = methods;

   useEffect(() => {
      if (data) {
         reset({
            altText: data.StockItemImageById?.altText || ''
         })
      }
   }, [data, reset])


   const onSave = async (data: FormData) => {
      await SavingUI.process(async () => {
         await save({
            variables: {
               imageId,
               input: {
                  altText: data.altText
               }
            },
            refetchQueries: ['StockItemImages']
         });
         onHide()
      }, 'Image alt text updated')
   }

   return <FormProvider {...methods}>
      <Dialog open={true} onClose={onHide}>
         {!data?.StockItemImageById && <LinearProgress/>}
         {!!data?.StockItemImageById && <>
            <DialogContent>
               <FormInputText name={"altText"} label={"Image alt text"}/>
            </DialogContent>
            <DialogActions>
               <Button variant={"contained"} onClick={handleSubmit(onSave)}>Update</Button>
            </DialogActions>
         </>}
      </Dialog>
   </FormProvider>
}