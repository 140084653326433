import * as React from "react";
import {Button, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {DataObject} from "@mui/icons-material";
import {EnumSeasonAutoRulesDataType} from "../../../Queries";

type SeasionExpressionTypeProps = {
   onSelect: (type: EnumSeasonAutoRulesDataType) => void
}

export const SeasonExpressionType = ({onSelect}: SeasionExpressionTypeProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? 'expression-type-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         variant={"outlined"}
         color={"secondary"}
      >
         Add rule
      </Button>
      <Menu
         anchorEl={anchorEl}
         id="expression-type-menu"
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {Object.values(EnumSeasonAutoRulesDataType).map(connector => (
            <MenuItem key={'connector_' + connector}
                      onClick={() => onSelect(connector)}>
               <ListItemIcon>
                  <DataObject fontSize="small"/>
               </ListItemIcon>
               {connector}
            </MenuItem>
         ))}
      </Menu>
   </>
}

