import * as React from "react";
import {FC} from "react";
import {Alert, LinearProgress} from "@mui/material";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {DateFormat} from "../../../utility/DateFormat";
import {useTradeInListQuery} from "../../../Queries";
import {Thumbnails} from "../../../components/Thumbnails";

type TradeInsListProps = {
   shopId: string
   registered: boolean
   rejected: boolean
   stocked: boolean
   onSelectTradeIn: (row: any) => void
   maxRows?: number
   hideColumns?: string[]
   hideFilter?: boolean
   imageSize?: number
}

export const getTradeInStatus = (registered?: boolean | null, carrierId?: string | null, deliveryDate?: Date | null, deliveryStatus?: string | null) => {
   if (registered) {
      return "Received"
   } else if (carrierId === 'pickup') {
      return "Awaiting pickup"
   } else if (deliveryDate) {
      return "Received"
   } else if (deliveryStatus === "WARNING") {
      return "Not shipped"
   } else if (deliveryStatus === "PROGRESSING") {
      return "Shipped"
   } else if (deliveryStatus === "WAITING") {
      return "Not shipped"
   } else if (deliveryStatus === "UNKNOWN") {
      return "Awaiting carrier"
   } else if (deliveryStatus === "DELIVERED") {
      return "Received"
   } else {
      return "Unknown status"
   }
}

export const TradeInsList: FC<TradeInsListProps> = ({
                                                       shopId,
                                                       registered,
                                                       rejected,
                                                       stocked,
                                                       onSelectTradeIn,
                                                       maxRows,
                                                       hideColumns,
                                                       hideFilter,
                                                       imageSize
                                                    }) => {
   const {data, loading} = useTradeInListQuery({
      variables: {
         shopId: shopId,
         registered: registered,
         limit: maxRows || 2000
      }
   })


   if (!data?.TradeIns || loading) {
      return <LinearProgress/>
   }

   let tradeIns = data.TradeIns;


   let columns: DataColumn<typeof tradeIns[0]>[] = [{
      label: "#",
      get: (row) => {
         return row.tradeInNr
      },
      searchable: true,
      width: '5%'
   }, {
      label: "Items",
      get: (row) => {
         return row._id
      },
      render: (id, row) => {
         let images = row.items.map(item => {
            return item.imageUrls ? item.imageUrls[0] : ''
         }).filter(image => !!image)
         return <Thumbnails imageUrls={images} size={imageSize || 80} maxImages={images.length}/>
      },
      width: '15%'
   }, {
      label: "Consumer",
      get: (row) => {
         return row.consumer?.firstName + ' ' + row.consumer?.lastName
      },
      searchable: true,
      width: '20%'
   }, {
      label: "Date",
      get: (row) => {
         return row.dateTime
      },
      render: (data) => {
         return DateFormat.toPresent(data)
      },
      searchable: true,
      width: '15%'
   }, {
      label: "Type",
      get: (row) => {
         let wholeSale: boolean = false;
         let b2c: boolean = false;
         for (const item of row.items) {
            if (item.wholeSale) {
               wholeSale = true;
            } else {
               b2c = true
            }
         }
         return wholeSale && b2c ? 'Wholesale and B2C' : wholeSale ? 'Wholesale' : 'B2C'
      },
      render: (item, row) => {
         return <div>{item}</div>
      },
      searchable: true,
      width: '10%'
   }, {
      label: 'Delivery status',
      get: (row) => {
         return getTradeInStatus(row.registered, row.shippingCarrier?.carrierId, row.label?.deliveryDate, row.label?.deliveryStatus);
      },
      render: (data, row) => {
         if (row.shippingCarrier?.carrierId === 'pickup' && !row.registered) {
            return <Alert severity={"error"}>{data}</Alert>
         } else if (row.registered) {
            return <Alert variant={"outlined"}>{data}</Alert>
         } else {
            return <>{data}</>
         }
      },
      width: '20%',
      searchable: true
   }, {
      label: 'Logistics status',
      get: (row) => {
         let stocked = row.items.filter(item => item.stockItem || item.brandItem).length
         let unstocked = row.items.filter(item => !item.rejected && !(item.stockItem || item.brandItem)).length
         let rejected = row.items.filter(item => item.rejected).length
         return {
            stocked: stocked,
            unstocked: unstocked,
            rejected
         }
      },
      render: (data, row) => {
         if (!row.registered) {
            return <>Awaiting registration</>
         }
         if (data.unstocked > 0) {
            return <Alert severity={"warning"} >
               {data.stocked > 0 && <>{data.stocked} stocked<br/></>}
               {data.unstocked} awaiting
            </Alert>
         } else if (!!data.stocked) {
            return <Alert severity={"success"}>
               {data.stocked} stocked
            </Alert>
         } else if (!!data.rejected) {
            return <Alert severity={"error"}>{data.rejected} rejected</Alert>
         }
      },
      width: '20%'
   }]

   if (hideColumns && hideColumns.length > 0) {
      columns = columns.filter(col => {
         return hideColumns.indexOf(col.label) === -1;
      })
   }

   return <DataTable
      stateId={"tradeInList"}
      idField={"_id"} columns={columns} rows={data.TradeIns} order={"desc"}
      maxRows={maxRows}
      hideFilter={hideFilter}
      onSingleSelect={onSelectTradeIn}
   />
}
