import * as React from "react";
import {useFormContext} from "react-hook-form";
import {Alert, TextField, TextFieldProps} from "@mui/material";
import {merge} from "lodash";
import makeStyles from "@mui/styles/makeStyles";

type FormInputNumberProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   label: string;
   setValue?: any;
   errorMessage?: string

   control?: any;
}

export const FormInputNumber = ({name, error, label, errorMessage, ...rest}: FormInputNumberProps) => {
   const {style, InputProps} = rest;
   const classes = useStyles();

   const methods = useFormContext();
   const {register, formState: {errors}} = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   const newProps = merge(InputProps, {
      disableUnderline: true,
      style: {backgroundColor: 'transparent'},
   });

   return (
      <div className={classes.noBorderRadius}>
         <TextField
            id={name}
            size="small"
            fullWidth
            label={label}
            variant="filled"
            margin={"none"}
            error={showError}
            type={"number"}
            inputProps={{
               ...register(name, {
                  valueAsNumber: true
               }),
               step: "1"
            }}
            InputLabelProps={{
               shrink: true,
            }}
            defaultValue={null}
            InputProps={newProps}
            style={newStyle}
            {...rest}
         />
         {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
      </div>
   );
};

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});
