import {UserAccess, useUserAccessesQuery, useUserAccessToggleMutation} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import SettingsHeader from "../SettingsHeader";
import {Button, Chip} from "@mui/material";
import PageContent from "../../../layout/PageContent";
import * as React from "react";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {BrandSettingsPagePath} from "../BrandSettingsPage";
import {UsersInvitePagePath} from "./UsersInvitePage";
import {Dialogs} from "../../../DialogProvider";
import {TitleText} from "../../../layout/Typography";

export const UsersListPagePath = (brandHouseId: string) => {
   return BrandSettingsPagePath(brandHouseId) + '/users'
}

export const UsersListPage = () => {
   const {brandHouseId} = useParams<{ brandHouseId: string }>()
   const navigate = useNavigate();
   const [disable] = useUserAccessToggleMutation()

   const {data} = useUserAccessesQuery({
      variables: {
         brandHouseId
      }
   })

   const onToggle = async (row: UserAccess, disabled: boolean) => {
      Dialogs.confirm({
         title: 'Are you sure you wish to ' + (disabled?'disable':'enable') + ' this user?',
         subtitle: 'The user will ' + (disabled?'be':'not be') + ' able to login.'
      }, async () => {
         await disable({
            variables: {
               id: row._id,
               disabled: disabled
            }
         })
      })
   }

   const columns: DataColumn<UserAccess>[] = [{
      field: 'user.name',
      label: 'Name',
      render: (_, row) => {
         const name = row.user?.name
         if (row.disabled) {
            return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
               <div>{name}</div>
               <Chip size={"small"} sx={{padding: '0'}} variant={"outlined"} color={"secondary"}
                     label={"Disabled"}/>
            </div>
         } else if (row.inviteTokenTs) {
            return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
               <div>{name}</div>
               <Chip size={"small"} sx={{padding: '0'}} variant={"outlined"} color={"secondary"}
                     label={"Invitation sent"}/>
            </div>
         } else if (row.user?.resetTokenTs && !row.user?.lastLoginAt) {
            return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
               <div>{name}</div>
               <Chip size={"small"} sx={{padding: '0'}} variant={"outlined"} color={"secondary"}
                     label={"Invitation sent"}/></div>
         } else {
            return <div>{name}</div>
         }
      },
      width: '30%'
   }, {
      field: 'user.email',
      label: 'Email',
      width: '25%',
      render: (_, row) => {
         return row.user?.email
      }
   }, {
      field: '_id',
      label: 'Role',
      render: (id, row) => {
         if (row?.brandHouseId) {
            return <div>Admin for {row?.brandHouseName}</div>
         } else if (row?.logisticsPartnerId) {
            return <div>Admin for {row?.logisticsPartnerName}</div>
         } else {
            return <div>Unknown access</div>
         }
      },
      width: '20%'
   }, {
      field: '_id',
      label: '',
      render: (id, row) => {
         if (row.disabled) {
            return <Button onClick={() => onToggle(row, false)} variant={"outlined"} size={"small"}
                           color={"secondary"}>Enable</Button>
         } else {
            return <Button onClick={() => onToggle(row, true)} variant={"outlined"} size={"small"}
                           color={"secondary"}>Disable</Button>
         }
      },
      align: "right",
      width: '10%'
   }]

   const accesses = data?.UserAccesses || [];
   const activeAccesses = accesses.filter(user => !user?.disabled);
   const disabledAccesses = accesses.filter(user => !!user?.disabled)
   return <div>
      <SettingsHeader title={"Users and permissions"}
                      subtitle={"The following users have access to your create2STAY platform"}>
         <Button variant={"contained"} onClick={() => navigate(UsersInvitePagePath(brandHouseId))}>Invite user</Button>
      </SettingsHeader>
      <PageContent>
         <TitleText type={"h2"} style={{marginBottom: '20px'}}>Active users</TitleText>
         <DataTable idField={"_id"} columns={columns} rows={activeAccesses}/>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"} style={{marginBottom: '20px'}}>Disabled users</TitleText>
         <DataTable idField={"_id"} columns={columns} rows={disabledAccesses}/>
      </PageContent>
   </div>
}
