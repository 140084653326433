import {FC} from "react";
import {Divider, Grid, LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";
import {BrandSelection} from "./BrandSelector";
import {DateUtility} from "../../utility/DateUtility";
import {StatCurrency} from "./StatCurrency";
import {getFilter, Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";
import {useStatInvoiceTableQuery} from "../../Queries";

type StatInvoiceTableProps = {
   start: Date
   end: Date
   splits: Split[]
   brand?: BrandSelection
}

export const StatInvoiceTable: FC<StatInvoiceTableProps> = ({start, end, splits, brand}) => {
   const {data} = useStatInvoiceTableQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: getFilter(splits),
         brandHouseIds: brand?.brandHouseIds,
         shopIds: brand?.shopIds
      }
   })
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   let fee = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'fee')?.value || 0;
   let returns = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'return')?.value || 0;
   let items = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'items')?.value || 0;
   let commission = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'commission')?.value || 0;
   let pickpack = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'pickpack')?.value || 0;

   let total = fee + returns + commission + pickpack;

   let currency = brand?.currency||'DKK';
   return <div>
      <TitleText type={"h2"}>Platform Revenue in period
         ({Math.round(DateUtility.dateDiffDays(end, start))} days)</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <Grid container>
         <Grid item xs={10}>
            <BodyText type={"body1"} style={{marginBottom: '10px'}}>
               Total revenue ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <StatCurrency currency={currency} value={Math.round(total)}/>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Items ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <StatCurrency currency={currency} value={Math.round(items)}/>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Pickpack ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(pickpack)}/>
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Commission ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(commission)}/>
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Return ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(returns)}/>
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Platform fee ({currency}) :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(fee)}/>
            </BodyText>
         </Grid>
      </Grid>
   </div>
}
