import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {ObjectPatchFragment, useAuditChangesQuery} from "../../../Queries";
import {Box, Grid, LinearProgress, Paper} from "@mui/material";
import React from "react";
import {SupervisedUserCircle} from "@mui/icons-material";

export const AuditPage = () => {
   const {data} = useAuditChangesQuery()
   return <Page>
      <PageHeader title={"Audit"}>
      </PageHeader>
      <PageContent>
         {!data && <LinearProgress/>}
         {data?.PatchPagination?.items?.map(item => (
            <ObjectPatch patch={item}/>
         ))}
      </PageContent>
   </Page>

}

type ObjectPathProps = {
   patch: ObjectPatchFragment
}

export const ObjectPatch = ({patch}: ObjectPathProps) => {
   return <Paper>
      <Grid container>
         <Grid>
            {patch.type} was changed by {patch.updatedBy?.ref || 'Unknown'} at {patch.date}
         </Grid>
         <Grid>
            <UserInfo userId={patch.updatedBy?.ref || ''}/>
         </Grid>
      </Grid>
   </Paper>
}

type UserInfoProps = {
   userId: any
}
export const UserInfo = ({userId}: UserInfoProps) => {
   return <Box><SupervisedUserCircle/>Test</Box>
}
