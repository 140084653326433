import * as React from "react";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";

type PageCommandProps = {}

const PageCommands: React.FC<PageCommandProps> = ({children}) => {
   const classes = useStyles();
   return <Grid container className={classes.root}>
      <Grid item xs>
         {children}
      </Grid>
   </Grid>
}


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
         marginBottom: theme.spacing(1),
         width: '100%',
         display: 'flex',
         borderBottom: '1px solid #e0e0e0',
         textAlign: 'right'
      },
      cell: {}
   }),
);

export default PageCommands