import {Button, ListItemIcon, Menu, MenuItem} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import * as React from "react";
import {EnumIntegrationConnectorRefType, useShopConnectorMenuQuery} from "../../../Queries";

type AddConnectorMenuProps = {
   refType: EnumIntegrationConnectorRefType
   refId?: string
   onSelect: (connectorId: string) => void
}

export const AddConnectorMenu = ({refId, refType, onSelect}: AddConnectorMenuProps) => {
   const {data} = useShopConnectorMenuQuery()
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? 'connector-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         variant={"outlined"}
         color={"secondary"}
      >
         Add integration
      </Button>
      <Menu
         anchorEl={anchorEl}
         id="connector-menu"
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {data?.IntegrationConnectors?.filter(connector =>
            (connector.activeFor || []).length === 0 || (connector.activeFor || []).includes(refId))
            .filter(connector => (connector.refTypes as any as EnumIntegrationConnectorRefType[])?.includes(refType))
            .map(connector =>
               <MenuItem key={'connector_' + connector._id}
                         onClick={() => onSelect(connector._id)}>
                  <ListItemIcon>
                     <LanguageIcon fontSize="small"/>
                  </ListItemIcon>
                  {connector.name}
               </MenuItem>
            )}
      </Menu>
   </>
}

