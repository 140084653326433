import * as React from "react"
import {FC} from "react"
import {Button, CircularProgress} from "@mui/material";
import {QComponentProps} from "../Model";
import DoneIcon from '@mui/icons-material/Done';
import {Warning} from "../widgets/Warning";

export type QButtonProps = QComponentProps & {
   label: string,
   type: 'save' | 'delete' | 'cancel' | string
}

export const QButton: FC<QButtonProps> = ({label, type, action, hasErrors}) => {
   const textRef = React.useRef<HTMLDivElement>(null);
   const [failure, setFailure] = React.useState<string | undefined>();
   const [enabled, setEnabled] = React.useState<boolean>(true);
   const [saved, setSaved] = React.useState<boolean | null>();

   const onAction = async () => {
      if(type==="save") {
         setEnabled(false);
      }
      let qActionResult = await action(type);

      if(type==="save") {
         if (textRef?.current) {
            textRef.current.innerText = "Saving..."
         }

         let timeout: number = 100;
         if (qActionResult && !qActionResult.success) {
            setFailure(qActionResult.message);
         } else {
            setFailure(undefined);
            timeout = 250;
         }
         setTimeout(() => {
            setEnabled(true);
            if (textRef?.current) {
               textRef.current.innerText = label
            }
            if (!failure) {
               setSaved(true);
               setTimeout(() => {
                  setSaved(false);
               }, 100);
            }
         }, timeout);
      }
   }

   return <>
      {failure && <Warning>{failure}</Warning>}
      <div>
         <Button style={{width: 125}}
                 color={"primary"}
                 variant={type === 'save' && enabled ? "contained" : "outlined"}
                 onClick={onAction}>
            {saved &&
            <DoneIcon htmlColor={"#ffffff"}/>
            }
            {!enabled && <CircularProgress style={{marginRight: 10}} size={16}/>}
            {!saved && <div ref={textRef}>{label}</div>}
         </Button>
      </div>

   </>
}