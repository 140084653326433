import {ActionType, Form} from "../../../components/quickform/Model";
import {createForm, QuickForm} from "../../../components/quickform/QuickForm";
import React from "react";
import PageContent from "../../../layout/PageContent";
import Page from "../../../layout/Page";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useShippingBrandsQuery, useShippingCarrierEditQuery, useShippingCarrierUpdateMutation} from "../../../Queries";

export const ShippingCarrierEditPath = (id: string) => {
   return "/admin/shippingcarriers/" + id
}
export const ShippingCarrierEdit = () => {
   const {shippingCarrierId} = useParams<{ shippingCarrierId: string }>()
   const {data} = useShippingCarrierEditQuery({
      variables: {
         id: shippingCarrierId
      }
   });
   const {data: brands} = useShippingBrandsQuery();
   const [update] = useShippingCarrierUpdateMutation();

   if (!data || !brands) {
      return <LinearProgress/>
   }

   const shippingCarrier = data.ShippingCarrier

   let carrierForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "Shipping Carrier",
               variant: 'h1'
            }
         }, {
            type: "TextField",
            props: {
               label: "Name",
               field: "name",
            }
         }, {
            type: "TextField",
            props: {
               label: "Description (Brands will see this)",
               field: "description",
            }
         }, {
            type: "TextField",
            props: {
               label: "Name of carrier, as it appears for end consumers",
               field: "externalName",
            }
         }, {
            type: "TextField",
            props: {
               label: "Short hand name of carrier, as it appears for end consumers",
               field: "externalCode",
            }
         }, {
            type: "TextField",
            props: {
               label: "Visibility expression (Only allow this carrier if the expression evaluates to true)",
               field: "expression"
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'space-between'
            },
            children: [{
               type: "Select",
               props: {
                  label: "Carrier ID",
                  field: "carrierId",
                  options: [{
                     value: 'shipmondo',
                     label: 'Shipmondo'
                  }, {
                     value: 'pickup',
                     label: 'Pickup'
                  }, {
                     value: 'dropoff',
                     label: 'Dropoff'
                  }]
               }
            }, {
               type: "TextField",
               props: {
                  label: "Localization identifier",
                  field: "localizationKey",
               }
            }]
         }, {
            type: "Select",
            props: {
               label: "Active For",
               field: "activeFor",
               options: (brands.BrandHouses||[]).map(brand => {
                  return {
                     value: brand._id,
                     label: brand.name
                  }
               }) || [],
               multiple: true
            }
         }, {
            type: "Heading",
            props: {
               text: "B2CEurope Integration",
               variant: 'h2'
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Enable",
               field: "b2ceurope",
            }
         }, {
            type: "Activation",
            props: {
               justify: 'space-between',
               expression: "data.carrierId=='shipmondo' && !data.b2ceurope"
            },
            children: [{
               type: "Heading",
               props: {
                  text: "Shipmondo Integration",
                  variant: 'h2'
               }
            }, {
               type: "Horizontal",
               props: {
                  justify: 'space-between',
                  spacing: 20
               },
               children: [{
                  type: "TextField",
                  props: {
                     label: "Default carrier (glsdk, pdk_rho,...)",
                     field: "shipmondo.carrier"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Carrier_code (gls, pdk,...)",
                     field: "shipmondo.carrierCode",
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Service codes",
                     field: "shipmondo.serviceCodes",
                  }
               }]
            }, {
               type: "Horizontal",
               props: {
                  justify: 'space-between',
                  spacing: 20
               },
               children: [{
                  type: "TextField",
                  props: {
                     label: "Shipping Agreement Nr",
                     field: "shipmondo.customerNumber",
                     type: "string"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "QR - X pos",
                     field: "shipmondo.qrX",
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "QR - Y pos",
                     field: "shipmondo.qrY",
                  }
               }, {
                  type: "TextField",
                  props: {
                     type: "number",
                     label: "QR - Size",
                     field: "shipmondo.qrSize",
                  }
               }, {
                  type: "TextField",
                  props: {
                     type: "number",
                     label: "Parcel - height (cm)",
                     field: "shipmondo.height",
                  }
               }, {
                  type: "TextField",
                  props: {
                     type: "number",
                     label: "Parcel - width (cm)",
                     field: "shipmondo.width",
                  }
               }, {
                  type: "TextField",
                  props: {
                     type: "number",
                     label: "Parcel - length (cm)",
                     field: "shipmondo.length",
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Parcel - content description",
                     field: "shipmondo.description",
                  }
               }]
            }, {
               type: "Horizontal",
               props: {
                  justify: 'space-between'
               },
               children: [{
                  type: "CheckBox",
                  props: {
                     label: "Use own agreement",
                     field: "shipmondo.ownAgreement",
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Test mode",
                     field: "shipmondo.testMode",
                  }
               }]
            }]
         }, {
            type: "Heading",
            props: {
               text: "Custom Carrier",
               variant: 'h2'
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Enable",
               field: "customCarrier",
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Update",
                  type: "save"
               }
            }]
         }]
      }
   })

   const onButton = async (button: ActionType, data: any) => {
      if (button === "save") {
         await update({
            variables: {
               id: shippingCarrierId,
               input: data
            }
         });
      } else if (button === "cancel") {

      }
      return {
         success: true
      }
   }
   return <Page>
      <PageContent>
         <QuickForm form={carrierForm} data={shippingCarrier} onButton={onButton}/>
      </PageContent>
   </Page>
}
