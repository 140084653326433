import {IntegrationConnector, useConnectorsPageListLazyQuery} from "../../Queries";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {FlexList} from "../../components/flexlist/FlexList";
import {FlexListColumn, FlexListRequestData} from "../../components/flexlist/FlexTypes";
import {useNavigate} from "react-router";
import {ConnectorCreatePagePath, ConnectorEditPagePath} from "./ConnectorEditPage";
import Button from "@mui/material/Button";
import {Chip} from "@mui/material";

export const ConnectorsPage = () => {
   const [requestData] = useConnectorsPageListLazyQuery();
   const navigate = useNavigate();

   const columns: FlexListColumn<IntegrationConnector>[] = [{
      id: "name",
      field: "name",
      label: "Name"
   }, {
      id: "reftypes",
      field: "refTypes",
      label: "Target",
      render: (refTypes) => {
         return refTypes.join(" / ")
      }
   }, {
      id: "installs",
      field: "autoInstallFor",
      label: "Storefront",
      render: (refTypes, row) => {
         return row?.instances?.map(instance => <Chip size={"small"} label={instance?.refName}/>)
      }
   }];

   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter?: any) => {
      const perPage = 40;
      const skip = (page - 1) * perPage;
      const {data} = await requestData({
         variables: {
            skip: skip,
            limit: perPage
         }
      });

      return {
         data: data?.IntegrationConnectors || [],
         totalCount: data?.IntegrationConnectorsCount || 0
      } as FlexListRequestData<IntegrationConnector>
   }

   const onSelect = (row: IntegrationConnector) => {
      navigate(ConnectorEditPagePath(row._id));
   }

   const onCreate = () => {
      navigate(ConnectorCreatePagePath())
   }

   return <Page>
      <PageHeader title={"Connectors"}>
         <Button variant={"contained"} onClick={onCreate}>Create connector</Button>
      </PageHeader>
      <PageContent>
         <FlexList globalId={"connectorList"} onSelectRow={onSelect} columns={columns} itemsPerPage={40} onRequestData={onRequestData}/>
      </PageContent>
   </Page>
}