import * as React from "react";
import {FC} from "react";
import {useStatInvoiceChartQuery} from "../../Queries";
import {Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";
import {Checkbox, Divider, FormControlLabel, LinearProgress} from "@mui/material";

import {Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {TitleText} from "../../layout/Typography";
import {BrandSelection} from "./BrandSelector";
import {createTrendline} from "../../utility/Trendline";
import {makeStyles} from "@mui/styles";
import useLocalStorage from "../../hooks/useLocalStorage";

type StatInvoiceChartProps = {
   start: Date
   end: Date
   splits: Split[]
   brand?: BrandSelection
}

export const StatInvoiceChart: FC<StatInvoiceChartProps> = ({start, end, splits, brand}) => {
   const {data} = useStatInvoiceChartQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         splits: splits,
         filter: "true",
         brandHouseIds: brand?.brandHouseIds,
         shopIds: brand?.shopIds
      }
   })
   const [showTrend, setShowTrend] = useLocalStorage<boolean>('trendline2', false);
   const classes = useStyles();
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   type OrderData = {
      day: string
      items: number
      commission: number
      pickpack: number
      return: number
      total: number
      trend?: number
   }

   const transformed: OrderData[] = [];
   data?.StatisticsCalculate?.splits?.forEach(split => {
      let result = data?.StatisticsCalculate?.results?.filter(res => res?.split === split);
      let itemsSum = result?.find(res => res?.kpi === 'items')?.value || 0;
      let pickpackSum = result?.find(res => res?.kpi === 'pickpack')?.value || 0;
      let commissionSum = result?.find(res => res?.kpi === 'commission')?.value || 0;
      let returnSum = result?.find(res => res?.kpi === 'return')?.value || 0;
      transformed.push({
         day: split,
         items: itemsSum,
         commission: commissionSum,
         pickpack: pickpackSum,
         return: returnSum,
         total: itemsSum + commissionSum + pickpackSum + returnSum
      })
   });


   const trend = createTrendline(transformed.map((trans, index) => ({
      day: index,
      total: trans.total
   })), 'day', 'total');

   transformed.forEach((line, i) => {
      line.trend = trend.calcY(i)
   })

   return <div>
      <div className={classes.titleContainer}>
         <TitleText type={"h2"}>Platform Revenue</TitleText>
         <div className={classes.titleCommands}>
            <FormControlLabel
               control={<Checkbox checked={showTrend} value={true} onChange={(e, val) => setShowTrend(val)}/>}
               label="Trendline"
            />
         </div>
      </div>
      <Divider style={{marginBottom: '20px'}}/>
      <ResponsiveContainer width={"100%"} height={300}>
         <ComposedChart
            width={400}
            height={400}
            data={transformed}
            margin={{top: 5, right: 20, left: 10, bottom: 5}}
            syncId={"orders"}
         >
            <XAxis dataKey="day"/>
            <YAxis/>
            <Tooltip/>
            <Legend />
            <CartesianGrid stroke="#f5f5f5"/>
            <Area type="monotone" name={"Items"} stackId={"1"} dataKey="items" stroke="#808080" fill="#FF5376"/>
            <Area type="monotone" name={"Pickpack"} stackId={"1"} dataKey="pickpack" stroke="#808080" fill="#606060"/>
            <Area type="monotone" name={"Commission"} stackId={"1"} dataKey="commission" stroke="#808080" fill="#A0A0A0"/>
            <Area type="monotone" name={"Return"} stackId={"1"} dataKey="return" stroke="#808080" fill="#404040"/>
            {showTrend && <Line
               type={"linear"}
               dataKey={"trend"}
               legendType={"none"}
               tooltipType={"none"}
               stroke={trend.slope < 0 ? "#D09090" : "#90D090"}
               strokeWidth={1}
               dot={false}
            />}
         </ComposedChart>
      </ResponsiveContainer>
   </div>
}

const useStyles = makeStyles({
   titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
   },
   titleCommands: {
      position: "relative",
      marginTop: '-21px',
      top: '8px'
   }

});