import React, {CSSProperties, forwardRef, useEffect, useLayoutEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {mergeRefs} from 'react-advanced-cropper';

interface Props {
   src?: string;
   className?: string;
   crossOrigin?: 'anonymous' | 'use-credentials' | boolean;
   brightness?: number;
   saturation?: number;
   hue?: number;
   contrast?: number;
   style?: CSSProperties;
   editable?: boolean;
}

interface Point {
   x: number;
   y: number;
}

export const AdjustableImage = forwardRef<HTMLCanvasElement, Props>(
   (
      {
         src,
         className,
         crossOrigin,
         brightness = 0,
         saturation = 0,
         hue = 0,
         contrast = 0,
         style,
         editable
      }: Props,
      ref
   ) => {
      const imageRef = useRef<HTMLImageElement>(null);
      const canvasRef = useRef<HTMLCanvasElement>(null);
      const [points, setPoints] = useState<Point[]>([]);
      const [erasedPolygons, setErasedPolygons] = useState<Point[][]>([]);

      const drawImage = () => {
         const image = imageRef.current;
         const canvas = canvasRef.current;
         if (canvas && image && image.complete) {
            const ctx = canvas.getContext('2d');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;

            if (ctx) {
               ctx.filter = [
                  `brightness(${100 + brightness * 100}%)`,
                  `contrast(${100 + contrast * 100}%)`,
                  `saturate(${100 + saturation * 100}%)`,
                  `hue-rotate(${hue * 360}deg)`,
               ].join(' ');

               ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

               if (editable) {
                  // Clear the erased polygons
                  erasedPolygons.forEach(polygon => {
                     ctx.save();
                     ctx.beginPath();
                     polygon.forEach((point, index) => {
                        if (index === 0) {
                           ctx.moveTo(point.x, point.y);
                        } else {
                           ctx.lineTo(point.x, point.y);
                        }
                     });
                     ctx.closePath();
                     ctx.clip();
                     ctx.fillStyle = '#C6C7CB'
                     ctx.fillRect(0, 0, canvas.width, canvas.height);
                     ctx.restore();
                  });

                  // Draw the current polygon
                  if (points.length > 1) {
                     ctx.strokeStyle = 'white';
                     ctx.lineWidth = 2;
                     ctx.setLineDash([5, 5]); // 5px dash with 5px gap
                     ctx.beginPath();
                     points.forEach((point, index) => {
                        if (index === 0) {
                           ctx.moveTo(point.x, point.y);
                        } else {
                           ctx.lineTo(point.x, point.y);
                        }
                     });
                     ctx.stroke();
                     ctx.setLineDash([]); // Reset to solid lines
                  }
               }
            }
         }
      };

      const translateEventToCanvas = (e: MouseEvent): { x: number; y: number } | null => {
         const canvas = canvasRef.current;
         if (!canvas) return null;
         const rect = canvas.getBoundingClientRect();
         const x = (e.clientX - rect.left) * (canvas.width / rect.width);
         const y = (e.clientY - rect.top) * (canvas.height / rect.height);
         return {x, y};
      };

      const handleUndo = (e: KeyboardEvent) => {
         if (e.ctrlKey && e.key === 'z') {
            setErasedPolygons(prev => prev.slice(0, -1));
         }
      };

      useLayoutEffect(() => {
         drawImage();
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [src, brightness, saturation, hue, contrast, points, erasedPolygons]);

      useEffect(() => {
         const handleMouseDown = (e: MouseEvent) => {
            const pos = translateEventToCanvas(e);
            if (pos) {
               setPoints([pos]);
            }
         };

         const handleMouseMove = (e: MouseEvent) => {
            if (points.length > 0) {
               const pos = translateEventToCanvas(e);
               if (pos) {
                  setPoints(prev => [...prev, pos]);
               }
            }
         };

         const handleMouseUp = () => {
            if (points.length > 0) {
               setErasedPolygons(prev => [...prev, [...points, points[0]]]); // Close the polygon by connecting to the first point
               setPoints([]);
            }
         };

         const handleWindowMouseDown = (e: MouseEvent) => handleMouseDown(e);
         const handleWindowMouseMove = (e: MouseEvent) => handleMouseMove(e);
         const handleWindowMouseUp = () => handleMouseUp();

         if (editable) {
            window.addEventListener('mousedown', handleWindowMouseDown);
            window.addEventListener('mousemove', handleWindowMouseMove);
            window.addEventListener('mouseup', handleWindowMouseUp);
            window.addEventListener('keydown', handleUndo);
            return () => {
               window.removeEventListener('mousedown', handleWindowMouseDown);
               window.removeEventListener('mousemove', handleWindowMouseMove);
               window.removeEventListener('mouseup', handleWindowMouseUp);
               window.removeEventListener('keydown', handleUndo);
            };
         }
      }, [editable, points]);

      return (
         <>
            <canvas
               key={`${src}-canvas`}
               ref={mergeRefs([ref, canvasRef])}
               className={cn('adjustable-image-element', className)}
               style={style}
            />
            {src ? (
               <img
                  key={`${src}-img`}
                  ref={imageRef}
                  style={{display: 'none'}}
                  src={src}
                  crossOrigin={crossOrigin === true ? 'anonymous' : crossOrigin || undefined}
                  onLoad={drawImage}
                  alt=""
               />
            ) : null}
         </>
      );
   }
);

AdjustableImage.displayName = 'AdjustableImage';
