import * as React from "react";
import {useState} from "react";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {Box, Tab, Tabs} from "@mui/material";
import {PageContentProps} from "./PageContent";

export type PageTab = {
   id?: string
   label: string
}

type PageHeaderProps = PageContentProps & {
   onBack?: boolean
   tab?: string
   tabs: PageTab[]
   render?: (tab: string) => React.ReactElement
   onClick?: (tab: string) => void
   renderCommands?: (tab: string) => React.ReactElement
}

const PageContentTabs = ({onBack, tab: curTab, tabs, render, onClick, commands, renderCommands, ...rest}: PageHeaderProps) => {
   const [tab, setTab] = useState<string>(curTab || tabs[0].id || tabs[0].label);
   const classes = useStyles();
   const navigate = useNavigate()

   const onHandleBack = () => {
      navigate(-1)
   }

   return <div className={classes.root}>
      <div className={classes.cell}>
         <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {onBack && window.history?.length > 0 &&
               <Button sx={{marginRight: '20px'}} color={"secondary"} variant={"outlined"} size={"small"}
                       onClick={onHandleBack}>
                  <ArrowBackIcon/>
               </Button>}
            <Tabs value={tab} onChange={(tab, newValue) => setTab(newValue)}>
               {tabs.map(tab => {
                  let id = tab.id || tab.label;
                  return <Tab key={'page_Tab_' + id} label={tab.label} value={id} onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     if(onClick && id) {
                        onClick(id)
                     }
                  }}/>
               })}
            </Tabs>
         </Box>
         <Box sx={{textAlign: 'right'}}>
            {renderCommands && renderCommands(tab)}
            {commands && commands}
         </Box>
      </div>
      {render && render(tab)}
   </div>
}


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: '100%'
      },
      cell: {
         display: 'flex',
         flexDirection: 'row',
         alignItems: 'center',
         justifyContent: 'space-between'
      }
   }),
);

export default PageContentTabs