import React, {FC} from "react";
import {Alert, IconButton} from "@mui/material";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FormInputCheckbox} from "../../wholesale/form/FormInputCheckbox";
import {AddCircle, DeleteForeverRounded} from "@mui/icons-material";
import {DateFormat} from "../../../../utility/DateFormat";
import {FormInputText} from "../../wholesale/form/FormInputText";
import {StockItemFormData} from "./StockItemEditForm";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../../atoms/CurrentUser";

export const StockItemProblemsForm: FC<{}> = () => {
   const methods = useFormContext<StockItemFormData>();
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const {control, watch} = methods;

   const {fields, append, remove} = useFieldArray({
      control,
      name: "problems",
   });
   const productStatus = watch('productStatus')
   return <div>
      {fields.map((field, index) => {
         return <div key={"problem_" + field._id}>
            <FormInputText name={`problems[${index}].description`}
                           label={DateFormat.toPresent(field.createdAt || new Date())}
                           multiline
                           errorMessage={"Description is required"}
                           InputProps={{
                              endAdornment: <><FormInputCheckbox name={`problems[${index}].solved`}
                                                                 control={control}
                                                                 label={"Solved"}/>
                                 <IconButton onClick={() => {
                                    remove(index);
                                 }}><DeleteForeverRounded/></IconButton>
                              </>
                           }}
            />
         </div>
      })}
      {((productStatus === 'sale' || (isBrandHouse && productStatus==='draft')) && (fields.filter(f => !f.solved).length) > 0) &&
      <Alert variant={"filled"} style={{marginBottom: '10px'}} severity="error">Make sure all issues are resolved, then click "Solved"</Alert>
      }
      <IconButton onClick={() => append({
         description: '',
         solved: false,
         createdAt: new Date()
      })}>
         <AddCircle/>
      </IconButton>
   </div>
}