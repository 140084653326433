import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {DatePicker} from '@mui/x-date-pickers';
import '@mui/lab';
import TextField from "@mui/material/TextField";
import {merge} from "lodash";
import dayjs from "dayjs";

export type FormInputDateProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   label: string;
   errorMessage?: string
}

export const FormInputDate = ({name, label, errorMessage, ...rest}: FormInputDateProps) => {
   const {style} = rest;

   const classes = useStyles();

   const methods = useFormContext();
   const {formState: {errors}, control} = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   return (
      <div className={classes.noBorderRadius}>
         <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({
                        field: {onChange, value},
                        fieldState: {error, invalid}
                     }) => (
               <DatePicker
                  mask={"__/__/____"}
                  label={label}
                  value={value}
                  inputFormat={"dd/MM/yyyy"}
                  onChange={(value) => {
                     if(!value) {
                        onChange(null);
                     } else {
                        onChange(dayjs(value).format("YYYY-MM-DD"))
                     }
                  }}
                  renderInput={(params) => {
                     const {InputProps} = params;

                     const newProps = merge(InputProps, {
                        disableUnderline: true,
                        style: {backgroundColor: 'transparent'}
                     });

                     return <TextField
                        helperText={invalid ? errorMessage : null}
                        id={name}
                        size="small"
                        variant="filled"
                        margin={"none"}
                        fullWidth
                        InputLabelProps={{
                           shrink: true,
                        }}
                        color="primary"
                        InputProps={newProps}
                        {...params}
                        error={invalid}
                        style={newStyle}
                     />

                  }}
               />
            )}
         />
         {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
      </div>
   );
};

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});
