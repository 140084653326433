import {Alert, Button, ButtonProps, Snackbar} from "@mui/material";
import * as React from "react";
import {FC} from "react";
import {Control} from "react-hook-form/dist/types";


type SaveButtonProps = ButtonProps & {
   saveMessage: string
   control?: Control<any>
   onSave?: () => Promise<void>
}

export const SaveButton: FC<SaveButtonProps> = ({children, onSave, saveMessage, control, ...props}) => {
   const [saving, setSaving] = React.useState<'success' | 'error' | undefined>();
   const [error, setError] = React.useState<string | undefined>();
   const handleClose = () => {
      setSaving(undefined);
   }
   const onClick = async () => {
      try {
         if (control) {
            const errors = control._formState.errors;
            if (errors && Object.keys(errors).length > 0) {
               return;
            }
         }

         if (onSave) {
            await onSave();
            setSaving('success');
         }
      } catch (e:any) {
         setSaving('error');
         setError(e.message);
      }
   }

   return <>
      <Button onClick={onClick} {...props}>
         {children}
      </Button>
      <Snackbar open={!!saving} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{
         horizontal: "center",
         vertical: "bottom"
      }}>
         <Alert onClose={handleClose} variant={"filled"} severity={saving} sx={{width: '100%'}}>
            {saving !== 'error' && saveMessage}
            {saving === 'error' && (error || 'An unknown error occured')}
         </Alert>
      </Snackbar></>
}