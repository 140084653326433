import * as React from 'react'
import {useCallback} from 'react'
import {UNSAFE_NavigationContext} from 'react-router-dom'
import {Dialogs} from "../DialogProvider";
import type {Blocker, History, Transition} from 'history'

export function useBlocker(blocker: Blocker, when = true): void {
   const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History

   React.useEffect(() => {
      if (!when) return

      const unblock = navigator.block((tx: Transition) => {
         const autoUnblockingTx = {
            ...tx,
            retry() {
               // Automatically unblock the transition so it can play all the way
               // through before retrying it. TODO: Figure out how to re-enable
               // this block if the transition is cancelled for some reason.
               unblock()
               tx.retry()
            },
         }

         blocker(autoUnblockingTx)
      })

      return unblock
   }, [navigator, blocker, when])
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message: string, when = true) {
   const blocker = useCallback(
      (tx) => {
         Dialogs.confirm({
            title: 'Unsaved changes',
            subtitle: 'If you leave this page, any unsaved changes will be lost.',
            buttonYes: 'Leave page'
         }, () => {
            tx.retry();
         })
      },
      []
   );

   useBlocker(blocker, when);
}
