import {ApolloClient, gql} from "@apollo/client";

const qlConsumers = gql`
query Consumers($shopId:String!, $pageSize: Int!, $page: Int!, $orderBy: String, $order: String, $filter: String) {
  Consumers(shopId:$shopId, pageSize: $pageSize, page: $page, orderBy:$orderBy, order: $order, filter: $filter) {
    total
    page
    pages
    data {
      _id
      firstName
      lastName
      add1
      add2
      zipCode
      city
      mail
      phone
      acceptsMarketing
    }
  }
}
`;


export interface ListConsumer {
   _id: string
   firstName: string
   lastName: string
   add1: string
   add2: string
   zipCode: string
   city: string
   mail: string
   phone: string
   acceptsMarketing: boolean
}

export interface ListQuery {
   total: number
   page: number
   pages: number
   data: ListConsumer[]
}


const queryConsumers = async (client: ApolloClient<object>, shopId: string, pageSize: number, page: number, orderBy?: string, order?: string, filter?: string):Promise<ListQuery> => {
   let newVar = await client.query({
      query: qlConsumers,
      variables: {
         shopId: shopId,
         pageSize: pageSize,
         page: page,
         orderBy: orderBy,
         order: order,
         filter: filter
      }
   });
   return newVar.data.Consumers as ListQuery
}

export default queryConsumers