import * as React from "react";
import {FC, useCallback} from "react";
import {Alert, Box, Link} from "@mui/material";
import {ConsumerPagePath} from "../../../masterdata/consumers/ConsumerPage";
import {useNavigate} from "react-router-dom";
import {
   DtcFragment,
   DtcShopFragment,
   EnumPotentialNotified,
   FilterFindManyPotentialInput,
   SortFindManyPotentialInput,
   useDtcPotentialsListPaginationLazyQuery
} from "../../../../../Queries";
import {FlexList} from "../../../../../components/flexlist/FlexList";
import {FlexFilter, FlexListColumn, FlexSort} from "../../../../../components/flexlist/FlexTypes";
import {DateFormat} from "../../../../../utility/DateFormat";
import {AlertColor} from "@mui/material/Alert/Alert";

type PotentialActionListParams = {
   shop: DtcShopFragment,
   brandRuleId: string,
   activated: boolean
};


export const DTCPotentialsList: FC<PotentialActionListParams> = ({shop, brandRuleId, activated}) => {
   const navigate = useNavigate();
   const [fetchMore] = useDtcPotentialsListPaginationLazyQuery()

   const sorts: FlexSort<SortFindManyPotentialInput>[] = [{
      id: 'NameAsc',
      label: 'Product name A-Z',
      value: SortFindManyPotentialInput.SHOPID__NAME_ASC
   }, {
      id: 'NameDesc',
      label: 'Product name Z-A',
      value: SortFindManyPotentialInput.SHOPID__NAME_DESC
   }, {
      id: 'OrderAsc',
      label: 'Order date (Oldest first)',
      value: SortFindManyPotentialInput.SHOPID__EXTERNDATE_ASC
   }, {
      id: 'OrderDesc',
      label: 'Order date (Newest first)',
      value: SortFindManyPotentialInput.SHOPID__EXTERNDATE_DESC
   }]

   const filters: FlexFilter<FilterFindManyPotentialInput>[] = [{
      id: 'sent',
      label: 'Status',
      options: [{
         label: 'Not sent',
         filter: {
            notified: EnumPotentialNotified.NOTSENT
         }
      }, {
         label: 'Sent',
         filter: {
            notified: EnumPotentialNotified.SENT
         }
      }, {
         label: 'Not allowed',
         filter: {
            notified: EnumPotentialNotified.NOTALLOWED
         }
      }]
   }]

   const columns: FlexListColumn<DtcFragment>[] = [{
      id: 'consumer',
      label: 'Consumer',
      get: (row) => {
         return row.consumer?.mail || row.consumer?.firstName
      },
      render: (item) => {
         return <Link onClick={(e) => {
            e.preventDefault()
         }} href={'#'}>{item}</Link>
      },
      width: 2
   }, {
      id: 'date',
      label: 'Order date',
      field: 'orderDate',
      render: (item, row) => {
         return row?.externDate ? new Date(row.externDate).toLocaleDateString('da-dk', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
         }) : null;
      },
      width: 1
   }, {
      id: 'product',
      label: 'Order line',
      field: 'productVariantId',
      ellipsis: true,
      render: (value, row) => {
         const rabat = (row?.sp || 0) / (row?.rrp || 1)
         const discount = Math.round((1 - rabat) * 100);
         return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px'}}
                     title={row?.name || undefined}
         >
            <Box>
               <Box>
                  {row?.name || undefined}
               </Box>
               <Box title={'RRP: ' + row?.rrp + ' ' + row?.shop?.currency}>
                  {row?.sp} {row?.shop?.currency} {discount > 0 && ' (' + Math.round((1 - rabat) * 100) + '% discount)'}
               </Box>
               <Box>
               </Box>
            </Box>
         </Box>
      },
      width: 2
   }, {
      id: 'voucher',
      label: 'Trade-in offer',
      get: (row) => {
         return row.offer?.value || 0
      },
      render: (item, row) => {
         if (row?.offer?.value) {
            return row?.offer.value + ' ' + row?.offer.currency
         } else {
            return ''
         }
      },
      width: 1
   }, {
      id: 'status',
      label: 'Status',
      width: 2,
      get: (row) => {
         let alertColor: AlertColor;
         if (row?.status?.type === "WARN") {
            alertColor = "warning"
         } else if (row?.status?.type === "INFO") {
            alertColor = "info"
         } else if (row?.status?.type === "ERROR") {
            alertColor = "error"
         } else {
            alertColor = "success"
         }
         return <Alert color={alertColor} sx={{backgroundColor: 'transparent', border: '0'}}>
            {row?.status?.message}
            {row?.status?.date && <>: {DateFormat.toPresent(row?.status?.date)}</>}
         </Alert>
      }
   }];

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: sort as SortFindManyPotentialInput || SortFindManyPotentialInput.SHOPID__EXTERNDATE_DESC,
            perPage: 20,
            search: search,
            filter: {
               shopId: shop._id,
               ...filter
            },
            brandRuleId: brandRuleId
         },
         fetchPolicy: "cache-and-network"
      });
      return {
         data: data?.PotentialsDTCPagination?.items || [],
         totalCount: data?.PotentialsDTCPagination?.count || 0
      }
   }, [brandRuleId, fetchMore, shop._id]);

   const onSingleSelect = (row: any) => {
      navigate(ConsumerPagePath(shop.brandHouseId, shop._id, row.consumer._id));
   }

   return <>
      <FlexList
         globalId={"DTCPotentialsList_" + brandRuleId}
         columns={columns}
         sorts={sorts}
         filters={filters}
         onRequestData={onRequestData}
         onSelectRow={onSingleSelect}
         itemsPerPage={20}
         options={{
            searchLabel: "Search "
         }}
      />
   </>
}
