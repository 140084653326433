import * as React from "react";

export const IDView = (props:{mongoid:string}) => {
   return <span>{IDViewText(props.mongoid)}</span>
}

export const IDViewText = (mongoid?: string) => {
   if(mongoid) {
      return '#' + mongoid.substr(mongoid.length - 6)
   } else {
      return '#???';
   }
}