import * as React from "react"
import {useEffect} from "react"
import {LinearProgress} from "@mui/material";
import {QComponentProps} from "../Model";
import {cloneDeep, get, set} from "lodash";
import {ImageInfo, ImageUpload} from "../../ImageUpload";
import {UploadServiceType} from "../QuickForm";

type QImageInputProps = QComponentProps & {
   field: string
   maxImages?: number
}

function toImageInfo(images: any, uploadService?: UploadServiceType):ImageInfo[] {
   if (Array.isArray(images)) {
      return (images as string[]).map(image => {
         return {
            id: image,
            url: uploadService?.url() + '/' + image
         }
      });
   } else if (images) {
      return [{
         id: images,
         url: uploadService?.url() + '/' + images
      }];
   } else {
      return []
   }
}

export const QImageField: React.FC<QImageInputProps> = ({field, data, update, maxImages, config}) => {
   const [images, setImages] = React.useState<ImageInfo[] | null>();

   useEffect(() => {
      let images = get(data, field, []);
      setImages(toImageInfo(images, config?.uploadService))
   }, [config?.uploadService, data, field])

   if (!images) {
      return <LinearProgress/>
   }

   const addImages = (imageIds: string[] | string) => {
      let clonedData = cloneDeep(data);
      if (maxImages === 1) {
         if (Array.isArray(imageIds)) {
            set(clonedData, field, imageIds[0]);
         } else {
            set(clonedData, field, imageIds);
         }
      } else {
         let updatedImages;
         let curIds = images.map(image=>image.id);
         if (Array.isArray(imageIds)) {
            updatedImages = [curIds, ...imageIds];
         } else {
            updatedImages = [curIds, imageIds];
         }
         set(clonedData, field, updatedImages);
      }
      update(clonedData);
   };

   const delImage = (imageId: string) => {
      let clonedData = cloneDeep(data);
      if (maxImages === 1) {
         set(clonedData, field, undefined);
      } else {
         let curIds = images.map(image=>image.id);
         let index = curIds.indexOf(imageId);
         if (index > -1) {
            curIds.splice(index, 1)
         }
         set(clonedData, field, curIds);
      }
      update(clonedData);
   };

   if (!config || !config.uploadService) {
      return <div>ERROR, uploadServiceUrl not set</div>
   }

   return <ImageUpload
      id={field}
      uploadService={config.uploadService}
      images={images}
      addImages={addImages}
      delImage={delImage}
      maxImages={maxImages}
   />
}
