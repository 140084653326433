import * as React from "react";
import {FC} from "react";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {useParams} from "react-router-dom";
import {Box, LinearProgress, TextField} from "@mui/material";
import {
   useAnalyticsPageBrandByIdQuery,
   useCustomerTrackerQuery,
   useMovesTrackerQuery,
   useSalesTrackerQuery
} from "../../Queries";
import {TrackerView} from "./TrackerView";
import {DateRange, DateRangePicker} from '@mui/x-date-pickers-pro';
import '@mui/lab';
import {DateFormat} from "../../utility/DateFormat";

export const AnalyticsPage = () => {
   const [value, setValue] = React.useState<DateRange<Date>>([new Date(2021, 0, 1), new Date()]);
   const {shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const {data} = useAnalyticsPageBrandByIdQuery({
      variables: {
         shopId: shopId
      }
   });

   if (!data?.ShopById) {
      return <LinearProgress/>
   }

   const shop = data.ShopById

   const [start, end] = value;
   let fromDate: string = ''
   let toDate: string = ''
   if (start && end) {
      fromDate = start.toISOString();
      toDate = end.toISOString()
   } else {
      return <LinearProgress/>
   }

   return <>
      <Page>
         <PageHeader title={"Analytics " + shop?.name}>
            <DateRangePicker
               value={value}
               inputFormat={"dd-MM-yyyy"}

               onChange={(newValue) => {
                  setValue(newValue);
               }}
               renderInput={(startProps, endProps) => (
                  <React.Fragment>
                     <TextField {...startProps} />
                     <Box sx={{mx: 2}}> to </Box>
                     <TextField {...endProps} />
                  </React.Fragment>
               )}
            />
         </PageHeader>
         <PageContent>
            <CustomerTracker shopId={shopId} fromDate={fromDate} toDate={toDate}/>
         </PageContent>
         <PageContent>
            <MonthTracker shopId={shopId} fromDate={fromDate} toDate={toDate}/>
         </PageContent>
         <PageContent>
            <DayTracker shopId={shopId} fromDate={fromDate} toDate={toDate}/>
         </PageContent>
         <PageContent>
            <MovesTracker shopId={shopId} fromDate={fromDate} toDate={toDate}/>
         </PageContent>
      </Page></>
}

const CustomerTracker: FC<{ shopId: string, fromDate: string, toDate: string }> = ({shopId, fromDate, toDate}) => {
   const {data, loading} = useCustomerTrackerQuery({
      variables: {
         shopId: shopId,
         fromDate,
         toDate
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   if (loading) {
      return <LinearProgress/>
   }

   let anyData: any = data.StatisticsCalculate as any;
   return <TrackerView title={"Customer Tracker"} data={anyData}/>
}

const MonthTracker: FC<{ shopId: string, fromDate: string, toDate: string }> = ({shopId, fromDate, toDate}) => {
   const {data} = useSalesTrackerQuery({
      variables: {
         shopId: shopId,
         fromDate,
         toDate,
         splits: [getMonthFilter(-3), getMonthFilter(-2), getMonthFilter(-1), getMonthFilter(0), {
            id: 'total',
            filter: 'true'
         }]
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   let anyData: any = data.StatisticsCalculate as any;
   return <TrackerView title={"Month Tracker"} data={anyData}/>
}

const DayTracker: FC<{ shopId: string, fromDate: string, toDate: string }> = ({shopId, fromDate, toDate}) => {
   const {data} = useSalesTrackerQuery({
      variables: {
         shopId: shopId,
         fromDate,
         toDate,
         splits: [
            getDayFilter(-7),
            getDayFilter(-6),
            getDayFilter(-5),
            getDayFilter(-4),
            getDayFilter(-3),
            getDayFilter(-2),
            getDayFilter(-1),
            getDayFilter(0), {
               id: 'total',
               filter: 'true'
            }]
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   let anyData: any = data.StatisticsCalculate as any;
   return <TrackerView title={"Week Tracker"} data={anyData}/>
}

const getMonthFilter = (index: number): { id: string, filter: string } => {
   let date = new Date();
   let theDate = new Date(date.getUTCFullYear(), date.getUTCMonth() + index, 1);
   let toDate = new Date(date.getUTCFullYear(), date.getUTCMonth() + index + 1, 1);

   let thisMonth = DateFormat.toMonth(theDate);
   return {
      id: thisMonth,
      filter: "dateMs >= " + theDate.getTime() + " && dateMs < " + toDate.getTime()
   }
}

const getDayFilter = (index: number): { id: string, filter: string } => {
   let date = new Date();
   let theDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getDate() + index);
   let toDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getDate() + index + 1);

   let thisMonth = DateFormat.toDay(theDate);
   return {
      id: thisMonth,
      filter: "dateMs >= " + theDate.getTime() + " && dateMs < " + toDate.getTime()
   }
}

const MovesTracker: FC<{ shopId: string, fromDate: string, toDate: string }> = ({shopId, fromDate, toDate}) => {
   const {data} = useMovesTrackerQuery({
      variables: {
         shopId: shopId,
         fromDate,
         toDate,
         splits: [
            getMonthFilter(-5),
            getMonthFilter(-4),
            getMonthFilter(-3),
            getMonthFilter(-2),
            getMonthFilter(-1),
            getMonthFilter(0), {
               id: 'total',
               filter: 'true'
            }]
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   let anyData: any = data.StatisticsCalculate as any;
   return <TrackerView title={"Moves Tracker"} data={anyData}/>
}
