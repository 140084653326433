import * as React from "react";
import {onError} from "@apollo/client/link/error";
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache} from '@apollo/client';
import {useRecoilState} from "recoil";
import {CurrentUserState} from "./atoms/CurrentUser";
import {AccessController} from "./AccessController";
import MainWindow from "./MainWindow";
import {PubSub} from "./components/PubSub";
import {ErrorHandler} from "./ErrorHandler";
import {NavigationController} from "./navigation/NavigationController";
import {setContext} from "@apollo/client/link/context";
import {Route, Routes} from "react-router-dom";
import {InviteJoinPage} from "./pages/InviteJoinPage";
import {ForgotPasswordPage} from "./pages/ForgotPasswordPage";
import {ResetPasswordPage} from "./pages/ResetPasswordPage";
import {InviteAcceptPage} from "./pages/account/InviteAcceptPage";
import {QRSessionReceiver} from "./pages/logistics/qr-app/QRSessionReceiver";
import {RetryLink} from "@apollo/client/link/retry";

export const cache = new InMemoryCache({});

// @ts-ignore
const errorLink = onError(({graphQLErrors, networkError}) => {
   if (graphQLErrors) {
      if (graphQLErrors.find((error: any) => {
         return error.message && error.message.startsWith("Not Authorised");
      })) {
         PubSub.emit('logout');
      } else {
         PubSub.emit('errors', graphQLErrors);
      }
   }
});

const cleanTypeName = new ApolloLink((operation, forward) => {
   if (operation.variables) {
      const omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
   }
   return forward(operation).map((data) => {
      return data;
   });
});

const reactAppServer = process.env.REACT_APP_APISERVER || 'https://ourimpacthub-graphql.azurewebsites.net';

const client: ApolloClient<any> = new ApolloClient({
   cache,
   link: from([
      cleanTypeName,
      errorLink,
      setContext((request, previousContext) => {
         const accessToken = sessionStorage.getItem('accessToken');
         return {
            headers: {
               authorization: accessToken || '',
               pathname: window.location.pathname || ''
            }
         }
      }),
      new RetryLink({
         delay: {
            initial: 500,
            max: 5000,
            jitter: true
         },
         attempts: {
            max: 20,
            retryIf: (error, _operation) => !!error
         }
      }),
      createHttpLink({uri: reactAppServer + '/api/graphql'}),
   ]),
   credentials: 'include',
   defaultOptions: {
      query: {
         fetchPolicy: 'no-cache',
         errorPolicy: 'none',
      },
      mutate: {
         errorPolicy: 'none',
      },
   }
});

export const ApolloApp = () => {
   return <ApolloProvider client={client}>
      <Routes>
         <Route path={"join/:token"} element={<InviteJoinPage/>}/>
         <Route path={"reset/:token"} element={<ResetPasswordPage/>}/>
         <Route path={"reset"} element={<ForgotPasswordPage/>}/>
         <Route path={"*"} element={<AccessArea/>}/>
      </Routes>
   </ApolloProvider>
}

const AccessArea = () => {
   return <AccessController>
      <ErrorHandler>
         <Routes>
            <Route path={"account/accept"} element={<InviteAcceptPage/>}/>
            <Route path={"*"} element={<MainWindowArea/>}/>
         </Routes>
      </ErrorHandler>
   </AccessController>
}

const MainWindowArea = () => {
   const [currentUser] = useRecoilState(CurrentUserState);
   return <NavigationController shopId={currentUser?.shopId || undefined}>
      <div style={{paddingLeft: 20, paddingTop: 10, paddingRight: 20}}>
         <MainWindow/>
      </div>
      {(currentUser?.qrSession && currentUser?.qrSession.state === 'receiver') &&
      <QRSessionReceiver session={currentUser?.qrSession}/>}
   </NavigationController>
}