import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {FC} from "react";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Theme} from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type VASServiceDialogProps = {
   item:string
   onSave: () => void
   onCancel: () => void
   open: boolean
}

export const VASServiceDialog:FC<VASServiceDialogProps> = ({open, item, onSave, onCancel}) => {
   const classes = useStyles();

   const onSelect = () => {
      onSave();
   }


   return <Dialog  open={open} onClose={onCancel}>
      <DialogTitle>
         <TitleText type={"h1"}>Confirming that quality is acceptable</TitleText>
         <BodyText type={"subtitle1"}>{item}</BodyText>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
      </DialogContent>
      <DialogActions>
         <Button color={"primary"} variant={"contained"} onClick={onSelect}>Continue</Button>
         <Button variant={"outlined"} onClick={onCancel}>Cancel</Button>
      </DialogActions>
   </Dialog>
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      dialogContent: {
         minWidth: '600px'
      },
   }),
);
