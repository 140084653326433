import {Button, Checkbox, LinearProgress, Paper} from "@mui/material";
import {BodyText, TitleText} from "../../../layout/Typography";
import {
   ShippingCarrier,
   ShippingCarrierDocument,
   useShippingCarrierActivateMutation,
   useShippingCarrierQuery,
   useShippingCarriersQuery
} from "../../../Queries";
import * as React from "react";
import {FC} from "react";
import {SavingUI} from "../../../SavingProvider";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {Dialogs} from "../../../DialogProvider";
import {useParams} from "react-router";
import {ParamBrandHouse} from "../../../Parameters";
import SettingsHeader from "../SettingsHeader";

export const ShippingCarriers = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const {data} = useShippingCarriersQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })

   let columns: DataColumn<ShippingCarrier>[] = [{
      field: 'name',
      label: 'Shipping carrier',
      render: (name, carrier) => {
         return <div><TitleText type={"h3"}>{name}</TitleText>
            <BodyText type={"subtitle1"}>{carrier.description}</BodyText>
         </div>
      }
   }, {
      field: '_id',
      label: 'Active',
      align: 'right',
      render: (id, data) => {
         return <BrandCarrier brandHouseId={brandHouseId} shippingCarrierId={data._id}/>
      }
   }]

   if (!data?.ShippingCarriers) {
      return <LinearProgress/>
   }

   return <div>
      <SettingsHeader title={"Shipping providers"} subtitle={"Select the shipping providers that you wish to service your customers when they trade-in their used items"}/>

      <Paper sx={{padding: '20px', marginBottom: '20px'}}>
         <DataTable rows={data?.ShippingCarriers} minLineHeight={80} hideHeader hideFilter columns={columns}
                    idField={"_id"}/>
      </Paper>
   </div>
}

type BrandCarrierProps = {
   brandHouseId: string
   shippingCarrierId: string
}

export const BrandCarrier: FC<BrandCarrierProps> = ({brandHouseId, shippingCarrierId}) => {
   const {data} = useShippingCarrierQuery({
      variables: {
         brandHouseId: brandHouseId,
         shippingCarrierId: shippingCarrierId
      }
   })
   const [activate] = useShippingCarrierActivateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const toggle = async () => {
      if (data && data?.BrandCarrier?.active) {
         Dialogs.confirm({
            title: 'Are you sure you wish to disable this carrier?',
            subtitle: 'Future consumers will not be able to select this carrier when trading in their items. This will not affect historic tradeins.'
         }, async () => {
            await SavingUI.process(async () => {
               await activate({
                  variables: {
                     brandHouseId: brandHouseId,
                     shippingCarrierId: shippingCarrierId,
                     active: false
                  },
                  refetchQueries: [{
                     query: ShippingCarrierDocument,
                     variables: {
                        brandHouseId: brandHouseId,
                        shippingCarrierId: shippingCarrierId
                     }
                  }]
               });
            }, 'Carrier disabled');
         })
      } else {
         await SavingUI.process(async () => {
            await activate({
               variables: {
                  brandHouseId: brandHouseId,
                  shippingCarrierId: shippingCarrierId,
                  active: true
               },
               refetchQueries: [{
                  query: ShippingCarrierDocument,
                  variables: {
                     brandHouseId: brandHouseId,
                     shippingCarrierId: shippingCarrierId
                  }
               }]
            });
         }, 'Carrier enabled');
      }
   }

   return <Button color="secondary" variant={"text"} onClick={toggle}>
      <Checkbox checked={data?.BrandCarrier?.active || false} color={"secondary"}/>
   </Button>
}
