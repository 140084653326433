import {useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {
   useSalesforceEditCreateMutation,
   useSalesforceEditGetQuery,
   useSalesforceEditShopQuery,
   useSalesforceEditUpdateMutation
} from "../../../Queries";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {Box, Button, Grid, LinearProgress} from "@mui/material";
import PageContent from "../../../layout/PageContent";
import {TitleText} from "../../../layout/Typography";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import * as React from "react";
import SettingsHeader from "../SettingsHeader";
import {DetailsPagePath} from "./DetailsPage";
import {useNavigate} from "react-router-dom";
import {SavingUI} from "../../../SavingProvider";

export interface SalesforceData {
   name: string
   externId: string
   clientId: string
   password: string
   tenant: string
   baseUrl: string
   webdavImageUrl?: string
   webdavXmlUrl?: string
   siteId: string
   settings: SalesforcePropertiesType
   customize: string | any
}

export interface SalesforcePropertiesType {
   catalogId: string
   taxClassId: string
   baseImageUrl: string
   inventoryId: string
   priceBookId: string
}


export const SalesforceEdit = () => {
   const {brandHouseId, shopId} = useParams<ParamBrandHouse & ParamShop>();
   const {data} = useSalesforceEditGetQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })
   const navigate = useNavigate();

   if (!data) {
      return <LinearProgress/>
   }

   return <div>
      <SettingsHeader title={shopId ? "Salesforce" : "Connect to salesforce"}>
         <Button onClick={() => navigate(DetailsPagePath(brandHouseId))} color={"secondary"}
                 variant={"contained"}>Cancel</Button>
      </SettingsHeader>
      {shopId && <SalesforceEditor shopId={shopId} brandHouseId={brandHouseId}/>}
      {!shopId && <SalesforceCreator license={data.BrandHouse!.license!} brandHouseId={brandHouseId}/>}
   </div>
}


type SalesforceEditorProps = {
   brandHouseId: string
   shopId: string
}

const SalesforceEditor = ({brandHouseId, shopId}: SalesforceEditorProps) => {
   const {data} = useSalesforceEditShopQuery({
      variables: {
         shop: shopId
      }
   })
   const [save] = useSalesforceEditUpdateMutation()
   const onSave = async (data: SalesforceData) => {
      const {externId, name, ...config} = data;
      config.customize = JSON.parse(config.customize);
      await SavingUI.process(async () => {
         await save({
            variables: {
               shopId: shopId,
               data: config
            }
         })
      }, 'Salesforce connector updated');
   }

   if (!data) {
      return <LinearProgress/>
   }

   const shop = data.ShopById!;

   const salesforceData: SalesforceData = {
      name: shop.name!,
      externId: shop.externalId!,
      clientId: shop.configuration.clientId,
      baseUrl: shop.configuration.baseUrl,
      webdavImageUrl: shop.configuration?.webdavImageUrl,
      webdavXmlUrl: shop.configuration?.webdavXmlUrl,
      password: shop.configuration.password,
      tenant: shop.configuration.tenant,
      settings: {
         baseImageUrl: shop.configuration.settings.baseImageUrl,
         taxClassId: shop.configuration.settings.taxClassId,
         inventoryId: shop.configuration.settings.inventoryId,
         catalogId: shop.configuration.settings.catalogId,
         priceBookId: shop.configuration.settings.priceBookId
      },
      siteId: shop.configuration.siteId,
      customize: JSON.stringify(shop.configuration.customize, null, 2)
   }

   return <SalesforceForm onSave={onSave} data={salesforceData} update={true}/>
}

type SalesforceCreatorProps = {
   brandHouseId: string
   license: string
}

const SalesforceCreator = ({license}: SalesforceCreatorProps) => {
   const [save] = useSalesforceEditCreateMutation()
   const onSave = async (data: SalesforceData) => {
      const {externId, name, ...config} = data;
      config.customize = JSON.parse(config.customize);
      await SavingUI.process(async () => {
         await save({
            variables: {
               name: name,
               license: license,
               externId: data.externId,
               config: config
            }
         })
      }, 'Salesforce connector created')
   }

   return <SalesforceForm onSave={onSave} update={false} data={{
      name: '',
      externId: '',
      baseUrl: '',
      webdavXmlUrl: '',
      webdavImageUrl: '',
      clientId: '',
      customize: '',
      password: '',
      tenant: '',
      settings: {
         baseImageUrl: '',
         catalogId: '',
         inventoryId: '',
         taxClassId: '',
         priceBookId: ''
      },
      siteId: ''
   }}/>
}


type SalesforceFormProps = {
   update: boolean
   data: SalesforceData
   onSave: (data: SalesforceData) => Promise<void>
}

const SalesforceForm = ({data, onSave, update}: SalesforceFormProps) => {

   const methods = useForm<SalesforceData>({
      resolver: yupResolver(yup.object().shape({
         name: yup.string().required('Please provide a name for this salesforce shop'),
         externId: yup.string().required('Please provide an extern id'),
         clientId: yup.string().required('Please provide a client-id'),
         password: yup.string().required('Please provide a password'),
         baseUrl: yup.string().required('Please provide a baseurl'),
         webdavImageUrl: yup.string().required('Please provide a webdavurl'),
         webdavXmlUrl: yup.string().required('Please provide a webdavurl'),
         siteId: yup.string().required('Please provide siteId'),
         settings: yup.object().shape({
            catalogId: yup.string().required('Please provide a categoryId'),
            taxClassId: yup.string().required('Please provide a taxClassId'),
            baseImageUrl: yup.string().required('Please provide a baseImageUrl'),
            inventoryId: yup.string().required('Please provide a inventoryId'),
            priceBookId: yup.string().required('Please provide a priceBookId')
         })
      })),
      defaultValues: data,
      reValidateMode: "onChange"
   });

   const {handleSubmit} = methods;

   const buttonCaption = update ? 'Update' : 'Create'

   return <PageContent>
      <TitleText type={"h2"}>Connect to salesforce commercecloud</TitleText>
      <Box>
         <FormProvider {...methods}>
            <FormInputText disabled={update} name={"name"} label={"Name of shop (example: create2STAY)"}/>
            <FormInputText disabled={update} name={"externId"} label={"External ID (example: shop1.myshop.com)"}/>
            <TitleText type={"h3"}>Credentials</TitleText>
            <Grid container spacing={1}>
               <Grid item xs={4}>
                  <FormInputText name={"clientId"} label={"Salesforce client-id"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText type={"password"} name={"password"} label={"Salesforce password"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"tenant"} label={"Tenant ID (Realm ID and Instance ID)"}/>
               </Grid>
            </Grid>
            <TitleText type={"h3"}>Commerce cloud store information</TitleText>
            <Grid container spacing={1}>
               <Grid item xs={8}>
                  <FormInputText name={"baseUrl"} label={"Base URL of store"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"siteId"} label={"Site ID"}/>
               </Grid>
            </Grid>
            <TitleText type={"h3"}>Webdav configuration</TitleText>
            <Grid container spacing={1}>
               <Grid item xs={6}>
                  <FormInputText name={"webdavImageUrl"} label={"Webdav Image Upload URL"}/>
               </Grid>
               <Grid item xs={6}>
                  <FormInputText name={"webdavXmlUrl"} label={"Webdav XML Upload URL"}/>
               </Grid>
            </Grid>

            <TitleText type={"h3"}>Integration settings</TitleText>
            <Grid container spacing={1}>
               <Grid item xs={4}>
                  <FormInputText name={"settings.catalogId"} label={"Catalog ID"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"settings.priceBookId"} label={"Price Book ID"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"settings.taxClassId"} label={"Tax class ID"}/>
               </Grid>
               <Grid item xs={4}>
                  <FormInputText name={"settings.inventoryId"} label={"Inventory ID"}/>
               </Grid>
               <Grid item xs={8}>
                  <FormInputText name={"settings.baseImageUrl"} label={"Base url for images"}/>
               </Grid>
            </Grid>
            <FormInputText multiline={true} rows={"5"} name={"customize"} label={"Field customizations (JSON)"}/>
            <Button variant={"contained"} onClick={handleSubmit(onSave)}>{buttonCaption}</Button>
         </FormProvider>
      </Box>
   </PageContent>
}