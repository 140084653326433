import * as React from 'react';
import {Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export type CommonProps = {
   className?:string,
   style?:any
   sx?: SxProps<Theme>
}

export type TitleProps = CommonProps & {
   type: 'h1'|'h2'|'h3'|'h4'|'h5'|'subtitle1'|'subtitle2',
   children: React.ReactNode|React.ReactNode[]|null,
   component?: string|any
}

export const TitleText = (props:TitleProps) => {
   const effectStyle = {
      display: 'block',
      ...props.style
   }
   return <Typography sx={props.sx} style={effectStyle} className={props.className} variant={props.type} component={props.component || "span"}>
      {props.children}
   </Typography>
}

export type BodyProps = CommonProps & {
   title?: string
   type: 'body1'|'body2'|'subtitle1'|'subtitle2',
   children: React.ReactNode|React.ReactNode[]|null,
}

export const BodyText = (props: BodyProps) => {
   const {title, className, type, ...rest} = props;
   const effectStyle = {
      display: 'block',
      ...props.style
   }
   return <Typography style={effectStyle} title={title} className={className} variant={type} component={"span"} {...rest}>
      {props.children}
   </Typography>
}

export type LinkProps = CommonProps & {
   icon?: any,
   onClick: (e:any) => void,
   children?: React.ReactNode|React.ReactNode[]|null,
}

export const Link = (props:LinkProps) => {
   const effectStyle = {
      cursor: 'pointer',
      textDecoration: 'underline',
      ...props.style
   }
   return <span onClick={props.onClick} style={effectStyle}>
      {!!props.icon&&<span style={{textDecoration: 'none', top: '5px', position: 'relative', fontSize: '20px'}}>{props.icon}</span>}
      {props.children}
   </span>
}