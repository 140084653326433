import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import React from "react";
import {ParcelsList} from "./ParcelsList";
import {useParams} from "react-router-dom";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {BrandsPagePath} from "../../brandhouse/ShopsPage";

export const ParcelsPagePath = (brandHouseId: string, shopId: string) => {
   return BrandsPagePath(brandHouseId) + "/" + shopId + "/parcels"
}

export const ParcelsPage = () => {
   const {brandHouseId, shopId} = useParams<ParamBrandHouse & ParamShop>();

   return <Page>
      <PageHeader title={"Parcels"}>

      </PageHeader>
      <PageContent>
         <ParcelsList brandHouseId={brandHouseId} shopId={shopId}/>
      </PageContent>
   </Page>
}
