import {Box, TextField} from "@mui/material";
import {useFieldArray, useFormContext} from "react-hook-form";
import {EnumOptionSetValueType, OptionInstanceInput} from "../../Queries";
import {SimpleSelect} from "../../components/SimpleSelect";
import * as React from "react";

export type EditorOptionSet = {
   _id: any
   title: string
   valueType: EnumOptionSetValueType
   values: {
      _id: any
      label: string
   }[]
}

type EditorOptionSetsProps = {
   optionSets: EditorOptionSet[]
}

type HasOptionInstances = {
   options: OptionInstanceInput[]
}


export const OptionSets = ({optionSets}: EditorOptionSetsProps) => {
   const methods = useFormContext<HasOptionInstances>();
   const {control} = methods;

   const {fields: options, append, remove, update} = useFieldArray({
      control,
      name: "options",
   });

   const onChange = (optionSetId: string) => (e: any) => {
      const optionValueId = e.target.value;
      const optionSet = optionSets.find(optionSet => optionSet._id === optionSetId);
      if (!optionSet) {
         return;
      }
      if (optionSet.valueType === EnumOptionSetValueType.SELECT) {
         const optionValue = optionSet.values.find(value => value._id === optionValueId);
         if (!optionValue) {
            remove(options.findIndex(option => option.optionSetId === optionSetId));
            return;
         }
         const option = options.find(option => option.optionSetId === optionSetId);
         if (option) {
            update(options.indexOf(option), {
               optionSetId: optionSet._id,
               optionValueId: optionValue._id
            })
         } else {
            append({
               optionSetId: optionSet._id,
               optionValueId: optionValue._id
            })
         }
      } else if (optionSet.valueType === EnumOptionSetValueType.TEXT) {
         const option = options.find(option => option.optionSetId === optionSetId);
         if (option) {
            update(options.indexOf(option), {
               optionSetId: optionSet._id,
               optionValue: e.target.value
            })
         } else {
            append({
               optionSetId: optionSet._id,
               optionValue: e.target.value
            })
         }
      }
   }

   return <Box sx={{width: '100%'}}>
      {optionSets.map((optionSet, index) => {
         const value = options.find(option => option.optionSetId === optionSet._id)
         if (optionSet.valueType === EnumOptionSetValueType.SELECT) {
            return <SimpleSelect key={"option_" + optionSet._id} onSelectChange={onChange(optionSet._id)}
                                 label={optionSet.title}
                                 value={value?.optionValueId} options={optionSet.values.map(value => ({
               id: value._id,
               name: value.label
            }))}/>
         } else if (optionSet.valueType === EnumOptionSetValueType.TEXT) {
            return <TextField
               key={"option_" + optionSet._id}
               size="small"
               fullWidth
               onBlur={onChange(optionSet._id)}
               label={optionSet.title}
               variant="filled"
               defaultValue={value?.optionValue || ''}
               margin={"none"}
               InputLabelProps={{
                  shrink: true,
               }}
            />
         } else {
            return <Box>Unsupported type</Box>
         }
      })}
   </Box>
}
