import {BodyText} from "../../../../layout/Typography";
import {DateFormat} from "../../../../utility/DateFormat";

type PeriodProps = {
   from?: Date | string | null,
   to?: Date | string | null
}
export const PeriodView = (props: PeriodProps) => {
   return <div>
      {(props.from || props.to) && <>
         {props.from && props.to && <><BodyText sx={{display: 'inline-block'}} type={"body2"}>
            Period between {DateFormat.toPresent(props.from)} and {DateFormat.toPresent(props.to)}</BodyText></>
         }
         {props.from && !props.to && <><BodyText type={"body2"}>From {DateFormat.toPresent(props.from)}</BodyText></>}
         {!props.from && props.to && <><BodyText type={"body2"}>Until {DateFormat.toPresent(props.to)}</BodyText></>}
      </>
      }
      {!(props.from || props.to) && <>
         Any time
      </>}
   </div>
}
