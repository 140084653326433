import {useParams} from "react-router-dom";
import SettingsHeader from "../SettingsHeader";
import {Alert, Button, LinearProgress} from "@mui/material";
import {ShopTabs} from "../ShopTabs";
import * as React from "react";
import {useNavigate} from "react-router";
import {BrandSettingConsumerInput, useConsumerSettingsQuery, useConsumerSettingsSaveMutation} from "../../../Queries";
import {SavingUI} from "../../../SavingProvider";
import {FormProvider, useForm} from "react-hook-form";
import PageContent from "../../../layout/PageContent";
import {BodyText, TitleText} from "../../../layout/Typography";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputImage} from "../../shop/wholesale/form/FormInputImage";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import Grid from "@mui/material/Grid";

const ID_CONSUMERFORM = "SettingsConsumers";

const ConsumerSettingsPagePath = (brandId: string, targetId: string) => {
   return "/brands/" + brandId + "/settings/appsettings/" + targetId
}

export const ConsumerSettingsPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (value: string) => {
      navigate(ConsumerSettingsPagePath(brandHouseId, value));
   };

   return <div>
      <SettingsHeader title={"App settings"} subtitle={""}>
         <Button type="submit" form={ID_CONSUMERFORM} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <ConsumerSettingsInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <ConsumerSettingsInner shopId={shop}/>}
      />
   </div>
}

type ConsumerSettingsInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const ConsumerSettingsInner = ({brandHouseId, shopId}: ConsumerSettingsInnerProps) => {
   const {data} = useConsumerSettingsQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   const [saveSettings] = useConsumerSettingsSaveMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: ConsumerFormData) => {
      await SavingUI.process(async () => {
         const {
            defaultProductImage,
            tradeIn2InvalidEmailExp,
            tradeInProductSearch,
            tradeInProductSearchMask,
            lockEmail,
            tradeInHideLanguageSelector,
            tradeInComments
         } = data;
         await saveSettings({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               input: {
                  consumer: {
                     lockEmail
                  },
                  defaultProductImage,
                  tradeIn2InvalidEmailExp,
                  tradeInProductSearch,
                  tradeInProductSearchMask,
                  tradeInHideLanguageSelector,
                  tradeInComments
               }
            }
         });
      }, 'Consumer settings updated')
   }
   return <ConsumerInputEditor
      container={(shopId || brandHouseId)!}
      data={{
         ...data!.BrandSettings!.consumer,
         defaultProductImage: data!.BrandSettings!.defaultProductImage,
         tradeIn2InvalidEmailExp: data!.BrandSettings!.tradeIn2InvalidEmailExp,
         tradeInProductSearch: data!.BrandSettings!.tradeInProductSearch,
         tradeInProductSearchMask: data!.BrandSettings!.tradeInProductSearchMask,
         tradeInHideLanguageSelector: data!.BrandSettings!.tradeInHideLanguageSelector,
         tradeInComments: data!.BrandSettings!.tradeInComments
      } as ConsumerFormData}
      onSave={onSave}
   />
}


type ConsumerFormData = BrandSettingConsumerInput & {
   defaultProductImage: string
   tradeIn2InvalidEmailExp: string
   tradeInProductSearch: boolean
   tradeInProductSearchMask?: string
   tradeInHideLanguageSelector: boolean
   tradeInComments: boolean
}

type ConsumerInputEditorProps = {
   container: string
   data?: ConsumerFormData
   onSave: (data: ConsumerFormData) => Promise<void>
}


const ConsumerInputEditor = ({container, data, onSave}: ConsumerInputEditorProps) => {
   const methods = useForm<ConsumerFormData>({
      defaultValues: data
   });
   const {control, handleSubmit} = methods;

   // @ts-ignore
   return <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} id={ID_CONSUMERFORM}/>
      <PageContent>
         <TitleText type={"h2"}>Product search</TitleText>
         <FormInputCheckbox name={"tradeInProductSearch"} label={"Enable search based trade-ins"}/>
         <Grid item container spacing={1} sx={{paddingLeft: '30px'}}>
            <Grid item xs={6}>
               <FormInputText name={"tradeInProductSearchMask"} label={"Search mask (Example: ####-####)"}/>
            </Grid>
         </Grid>

         <TitleText type={"h2"}>Comments</TitleText>
         <FormInputCheckbox name={"tradeInComments"} label={"Enable trade-in comments"}/>

         <TitleText type={"h2"}>Consumer profile settings</TitleText>
         <FormInputCheckbox name={"lockEmail"} label={"Disallow editing of email"}/>
         <FormInputText name={"tradeIn2InvalidEmailExp"} label={"Regular expression (for disallowing e-mails)"}/>
         <FormInputCheckbox name={"tradeInHideLanguageSelector"} label={"Hide language selector in trade-in app"}/>
         <TitleText type={"h2"}>Default product image</TitleText>
         <BodyText type={"subtitle1"}>If the masterdata does not have an original packshot image, this image can be used
            as a default</BodyText>
         {data?.defaultProductImage?.startsWith('http') && <>
            <Alert variant={"outlined"} color={"warning"} severity={"error"}>Default image is using old format</Alert>
         </>}
         <FormInputImage name={"defaultProductImage"} control={control} label={'Default image for consumers'}
                         container={container}/>
      </PageContent>
   </FormProvider>
}
