import {useItemEditorProductImageQuery} from "../../../Queries";
import {Thumbnail} from "../../../components/Thumbnail";

type BrandProductImageProps = {
   brandProductImageId: string
}

export const BrandProductImage = ({brandProductImageId}: BrandProductImageProps) => {
   const {data} = useItemEditorProductImageQuery({
      variables: {
         imageId: brandProductImageId
      }
   })
   return <Thumbnail image={data?.BrandProductImage?.imageUrl || undefined}
                     size={200}
                     hideBorder={true}/>

}
