const toISO = (date: Date | string) => {
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   let isoDate = unformattedDate.toISOString();
   let tIndex = isoDate.indexOf('T')
   return isoDate.substring(0, tIndex);
}

const toDate = (date: Date | string) => {
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString('en-dk', {
      day: '2-digit',
      month: '2-digit',
      year: "numeric"
   }).replaceAll("/", "-");
}

const toTime = (date: Date | string | null | undefined) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleTimeString(navigator.language || 'da-dk', {
      hour: '2-digit',
      minute: '2-digit'
   });
}

const toPresent = (date: Date | string | null | undefined) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString(navigator.language || 'da-dk', {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
   })
}

const toMonth = (date: Date | string | null) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString(navigator.language || 'da-dk', {
      month: 'short',
      year: 'numeric'
   })
}

const toMonthName = (date: Date | string | null) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString(navigator.language || 'da-dk', {
      month: 'short'
   })
}

const toDay = (date?: Date | string | null) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString(navigator.language || 'da-dk', {
      day: '2-digit',
      month: 'short'
   })
}

const toWeeknumber = (date?: Date | string | null) => {
   if(!date) {
      return ""
   }
   let currentdate = new Date(date);
   let oneJan = new Date(currentdate.getFullYear(),0,1);
   let numberOfDays = Math.floor((currentdate.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
   return Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
}

const toReadable = (date: Date | string | null | undefined) => {
   if (!date) {
      return "";
   }
   let unformattedDate;
   if (typeof date === 'string') {
      unformattedDate = new Date(date)
   } else {
      unformattedDate = date;
   }
   return unformattedDate.toLocaleDateString(navigator.language || 'da-dk', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
   })
}

const timeSince = (date?:Date|null) => {
   if(!date) {
      return "";
   }
   var seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
   var interval = seconds / 31536000;
   if (interval > 1) {
      return toPresent(date);
   }
   interval = seconds / 2592000;
   if (interval > 1) {
      return toPresent(date);
   }
   interval = seconds / 86400;
   if (interval > 1) {
      return toPresent(date);
   }
   interval = seconds / 3600;
   if (interval > 1) {
      if(Math.floor(interval)===1) {
         return Math.floor(interval) + " hour ago";
      } else {
         return Math.floor(interval) + " hours ago";
      }
   }
   interval = seconds / 60;
   if (interval > 1) {
      if(Math.floor(interval)===1) {
         return Math.floor(interval) + " minute ago";
      } else {
         return Math.floor(interval) + " minutes ago";
      }
   }
   return Math.floor(seconds) + " seconds ago";
}


export const DateFormat = {
   toISO,
   toPresent,
   toReadable,
   toMonth,
   toDay,
   timeSince,
   toTime,
   toDate,
   toWeeknumber,
   toMonthName
}