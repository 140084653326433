import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {TableVirtuoso} from "react-virtuoso";
import makeStyles from "@mui/styles/makeStyles";
import {FlexHeader} from "./FlexHeader";
import {atomFamily, selectorFamily, useRecoilState, useRecoilValue} from "recoil";
import {FlexTabs} from "./FlexTab";
import {buildGraphQLFilter, FlexCommand, FlexFilterValue, FlexListColumn, FlexListProps} from "./FlexTypes";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogContent,
   IconButton,
   LinearProgress,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {PubSub} from "../PubSub";

export type InternalListState = {
   search?: string
   filters?: FlexFilterValue[]
   sort?: string,
   columns?: FlexListColumn<any>[]
   columnsVisible?: string[]
}

export const flexSettingsAtom =
   atomFamily<InternalListState, string>({
      key: `InternalListState`,
      default: {
         search: '',
         filters: [],
         sort: '',
         columns: []
      }
   });

type CalculatedColumn = {
   column: FlexListColumn<any>
   width: number
}

export const flexListColumns = selectorFamily({
   key: 'InternalListWidths',
   get: (globalId: string) => ({get}) => {
      const listSettings = get(flexSettingsAtom(globalId))
      const columns = listSettings?.columns?.filter(column => {
         if (listSettings.columnsVisible) {
            return listSettings.columnsVisible.includes(column.id);
         } else {
            return true;
         }
      }) || [];

      const result: CalculatedColumn[] = [];
      let totalWidth: number = columns.reduce((a, b) => a + (b.width || 1), 0) || 0;
      if (totalWidth) {
         for (const column of columns) {
            let columnWidth = Math.round((column.width || 1) * 100 / totalWidth);
            result.push({
               column: column,
               width: columnWidth
            });
         }
      }
      return result
   }
})

type InternalState = {
   total: number
   reset: boolean
   pages: any[][] | null
   listState: InternalListState | null
}

const defaultState: InternalState = {
   total: 0,
   reset: true,
   pages: null,
   listState: null
}

type InternalData = {
   data: any[]
   total: number
}

const defaultData: InternalData = {
   data: [],
   total: 0
}


export const FlexList = <T extends object>(
   {
      globalId,
      columns,
      itemsPerPage,
      onRequestData,
      options,
      filters,
      sorts,
      tabs,
      context,
      selections,
      commands,
      onTabChange,
      onSelectRow
   }: FlexListProps<T>) => {
   type AnchorEL = {
      element: HTMLElement | null
      command: FlexCommand<any>
   }
   const [anchorEl, setAnchorEl] = React.useState<null | AnchorEL>(null);

   const state = useRef<InternalState>(defaultState);
   const [data, setData] = useState<InternalData>(() => defaultData);
   const [selected, setSelected] = useState<string[]>([]);
   const [selectAll, setSelectAll] = useState<number | undefined>();
   const [executing, setExecuting] = useState<string>('');

   const [listState, setListState] = useRecoilState(flexSettingsAtom(globalId));
   const visibleColumns = useRecoilValue(flexListColumns(globalId));
   const [loading, setLoading] = useState<boolean>(true);

   const classes = useStyles();

   const loadData = useCallback(async () => {
      if (data.total > 0 && options?.maxRows) {
         return data;
      }
      const getPageData = async (page: number) => {
         let search: string | undefined;
         let sort: string | undefined;
         let filterValues: any | undefined;

         if (state.current.listState) {
            search = state.current.listState?.search;
            sort = state.current.listState?.sort;
            filterValues = state.current.listState?.filters;
         } else {
            search = listState.search;
            sort = listState.sort;
            filterValues = listState.filters;
         }

         return await onRequestData(
            search,
            sort,
            page,
            buildGraphQLFilter(filters, filterValues)
         )
      }

      let somethingChanged: boolean = false;

      if (!state.current.pages === null || state.current.reset) {
         const pageData = await getPageData(1);
         const numberOfPages = Math.ceil(pageData.totalCount / itemsPerPage)

         state.current.pages = [...Array(numberOfPages).fill(null)];
         state.current.pages[0] = pageData?.data;
         state.current.total = pageData.totalCount;

         somethingChanged = true;
      } else {
         const nextPage = state.current.pages?.findIndex(p => !p) || -1;
         if (nextPage > -1 && state.current.pages) {
            const pageData = await getPageData(nextPage + 1);
            if (pageData) {
               state.current.pages[nextPage] = pageData?.data
               state.current.total = pageData.totalCount;
               somethingChanged = true;
            }
         }
      }

      if (somethingChanged) {
         state.current = {
            ...state.current,
            reset: false,
            listState: null
         }

         let data: any[] = [];
         for (const page of state.current.pages || []) {
            if (page) {
               data = data.concat(page);
            } else {
               break;
            }
         }
         let newData = {
            data: data,
            total: state.current.total
         };
         setData(newData);
         return newData;
      }
      // eslint-disable-next-line
   }, [data]);

   useEffect(() => {
      setLoading(true);
      state.current = {
         ...state.current,
         reset: true,
         pages: null,
         listState: listState
      };
      setSelectAll(undefined);
      setSelected([]);
      (async function () {
         await loadData();
         setLoading(false);
      })();
      // eslint-disable-next-line
   }, [listState])

   useEffect(() => {
      if (selectAll !== undefined) {
         if (data.data.length === data.total) {
            const newSelecteds = data.data.map((row) => row?.[idField]);
            setSelected(newSelecteds);
            setSelectAll(undefined);
         } else {
            const handle = setTimeout(() => {
               (async function () {
                  const newData = await loadData()
                  if (newData && (newData.data.length < newData.total)) {
                     setSelectAll(selectAll + 1)
                  } else {
                     if (newData) {
                        const newSelecteds = newData.data.map((row) => row?.[idField]);
                        setSelected(newSelecteds);
                     } else {
                        const newSelecteds = data.data.map((row) => row?.[idField]);
                        setSelected(newSelecteds);
                     }
                     setSelectAll(undefined);
                  }
               })();
            }, 100)
            return () => clearTimeout(handle);
         }
      }
      // eslint-disable-next-line
   }, [selectAll])

   useEffect(() => {
      if (listState.columns?.length === 0) {
         const firstTab = tabs?.find(tab => tab.filters.length === 0);
         setListState({
            ...listState,
            columns: columns,
            columnsVisible: firstTab?.columns
         })
      }
      // eslint-disable-next-line
   }, [columns, options])

   useEffect(() => {
      return PubSub.on('FlexList-DataChange', (updatedData: any) => {
         const id = updatedData?.[idField];
         if (!id) return;

         const findData = data.data.find((row) => row[idField] === id);
         if (findData) {
            const newData: any[] = data.data.map((row) => {
               if (row[idField] === id) {
                  return {
                     ...row,
                     ...updatedData
                  };
               }
               return row;
            });

            setData({
               data: newData,
               total: data.total
            });
         }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data])

   const onSelectAllClick = async (selectAll: boolean) => {
      if (selectAll) {
         setSelectAll(1);
      } else {
         setSelected([]);
      }
   }

   const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const onExecute = async (cmd: FlexCommand<T>) => {
      setExecuting(cmd.id);
      cmd.execute && await cmd.execute(data.data.filter(row => selected.includes(row[idField])));

      state.current.reset = true;
      await loadData();

      PubSub.emit('FlexList-SearchChanged-' + globalId, listState);

      setExecuting('');
   }

   const isSelected = (id: string) => selected.indexOf(id) !== -1;

   if (!listState.columns) {
      return <LinearProgress/>
   }

   const idField = options?.idField || '_id'

   const commandOpen = Boolean(anchorEl);

   const commandHandleClick = (cmd: FlexCommand<T>, event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl({
         command: cmd,
         element: event.currentTarget
      });
   };
   const commandHandleClose = () => {
      setAnchorEl(null);
   };

   return <div className={classes.flexListContainer}>
      <Dialog open={selectAll !== undefined} maxWidth={"xs"}>
         <DialogContent style={{textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
            <div><CircularProgress/></div>
            <div>Selecting rows, please wait...</div>
         </DialogContent>
      </Dialog>

      {!options?.hideHeader && tabs && <div className={classes.flexTab}>
         <FlexTabs globalId={globalId} tabs={tabs} onRequestData={onRequestData} filters={filters}
                   onTabChange={onTabChange}/>
      </div>}

      {!options?.hideHeader && <div className={classes.flexHeader}>
         <FlexHeader globalId={globalId} count={data.total} filters={filters} sorts={sorts}
                     searchLabel={options?.searchLabel} style={{display: selected.length > 0 ? 'none' : 'block'}}/>
         <div className={classes.flexCommand} style={{display: selected.length > 0 ? 'flex' : 'none'}}>
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
               {selected.length} selected
               <IconButton size={"small"} onClick={() => setSelected([])}><ClearIcon fontSize={"inherit"}/></IconButton>
            </Typography>
            <div className={classes.commands}>{commands?.map(cmd => {
               const onClick = async () => await onExecute(cmd);
               return <Box key={'command_' + cmd.id}>
                  <Tooltip title={cmd.title} key={'cmd_' + cmd.id}>
                     <Button
                        aria-label={cmd.title}
                        onClick={cmd.children ? (e) => commandHandleClick(cmd, e) : onClick}
                        size="small"
                        startIcon={cmd.children ? undefined : executing === cmd.id ? <CircularProgress size={18}/> :
                           <cmd.icon/>}
                        endIcon={cmd.children ? <ArrowDropDownIcon/> : undefined}
                     >
                        {cmd.title}
                     </Button>
                  </Tooltip>
               </Box>
            })}</div>
         </div>
      </div>
      }
      {anchorEl && <Menu id={"menu_" + anchorEl.command!.id} open={commandOpen} anchorEl={anchorEl.element}
                         onClose={commandHandleClose}>
         {anchorEl.command!.children!.map(childCmd => {
            return <MenuItem key={'cmd_' + childCmd.id}
                             onClick={async () => await onExecute(childCmd)}>
               <ListItemIcon>
                  {executing === childCmd.id ? <CircularProgress size={18}/> : <childCmd.icon/>}
               </ListItemIcon>
               <ListItemText>
                  {childCmd.title}
               </ListItemText>
            </MenuItem>
         })}
      </Menu>}
      <div className={classes.flexContent}>
         <TableVirtuoso
            data={loading ? [] : data.data}
            endReached={loadData}
            fixedItemHeight={options?.itemHeight}
            useWindowScroll={!options?.height}
            style={{width: '100%', height: options?.height}}
            className={classes.table}
            increaseViewportBy={options?.height || 50}
            overscan={60}
            fixedHeaderContent={() => {
               return <tr>
                  {selections && <td className={classes.headerCell}
                                     width={'40px'}
                                     key={'select_all_column'}>
                     <Checkbox
                        indeterminate={selected.length > 0 && selected.length < state?.current?.total}
                        checked={selected.length > 0 && selected.length === state?.current?.total}
                        onChange={async (e) => await onSelectAllClick(e.target.checked)}
                        inputProps={{'aria-label': 'select all data'}}
                     />
                  </td>}
                  {visibleColumns.map((column, index) => {
                     return <td
                        className={classes.headerCell}
                        width={column.width + '%'}
                        key={'header_' + column.column.id + '_' + index}>
                        {column.column.label}
                     </td>
                  })}</tr>
            }}
            itemContent={(index: number, row: any) => {
               if (!row) {
                  return <></>
               }
               let id = row[idField];
               if (!row) {
                  return <></>
               }
               const isItemSelected = isSelected(id);
               const labelId = `enhanced-table-checkbox-${index}`;
               return <>
                  {selections && <td className={classes.dataCell}
                                     width={'40px'}
                                     key={'select_all_column'}>
                     <Checkbox
                        onClick={(event) => {
                           event.preventDefault();
                           event.stopPropagation();
                           handleClick(event, id)
                        }}
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                     />
                  </td>}

                  {visibleColumns.map((visibleColumn, colindex) => {
                     const column = visibleColumn.column;
                     const value = getCellValue(column, row);
                     let innerComponent;
                     if (column.render !== undefined) {
                        innerComponent = <>{column.render(value, row, context)}</>
                     } else {
                        innerComponent = <>{value}</>
                     }
                     let title: string | undefined;
                     if (typeof value === 'string') {
                        title = String(value);
                     }

                     return <td
                        onClick={(e) => onSelectRow && onSelectRow(row, e.ctrlKey)}
                        className={classes.dataCell}
                        width={visibleColumn.width + '%'}
                        title={title}
                        key={"r_" + id + "_" + column.id + "_" + colindex}
                        style={{height: options?.itemHeight, cursor: onSelectRow ? 'pointer' : undefined}}>
                        {column.ellipsis ?
                           <div style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              marginRight: '15px'
                           }}>
                              {innerComponent}
                           </div>
                           : <>
                              {innerComponent}
                           </>
                        }
                     </td>
                  })}</>
            }}
            fixedFooterContent={() => {
               return <tr>
                  <td colSpan={visibleColumns.length + 1}>
                     {(data.total > data.data.length || loading) && <LinearProgress/>}
                  </td>
               </tr>
            }}
         >
         </TableVirtuoso>
      </div>
   </div>
}


/**
 * Responsible for getting cell value given a column and a row.
 * @param column The column
 * @param row The row
 */
const getCellValue = (column: FlexListColumn<any>, row: any) => {
   if (column.get !== undefined) {
      return column.get(row)
   } else if (column.field !== undefined) {
      return row[column.field];
   } else {
      throw new Error('Column "' + column.label + '" must have either get or field');
   }
}

const useStyles = makeStyles({
   flexListContainer: {
      display: 'flex',
      flexDirection: 'column'
   },
   flexTab: {
      marginBottom: '3px',
   },
   flexHeader: {},
   flexContent: {
      marginTop: '4px',
      "& tr:hover": {
         transition: 'all .2s ease',
         backgroundColor: '#F4F4F4'
      }
   },
   table: {
      "& table": {
         tableLayout: "fixed",
         width: '100%',
         overflow: "hidden",
         wordWrap: "break-word"
      }
   },
   commands: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
   },
   headerCell: {
      fontWeight: 600,
      backgroundColor: '#F4F4F4'
   },
   dataCell: {
      borderTop: '1px solid #e0e0e0'
   },
   title: {},
   flexCommand: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#f8f8f8',
      paddingTop: '14px',
      paddingBottom: '14px'
   }
});
