import {useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {UsersListPagePath} from "./UsersListPage";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {UserAccessesDocument, useUsersInviteSendMutation} from "../../../Queries";
import {SingleClickButton} from "../../../components/SingleClickButton";
import {Dialogs} from "../../../DialogProvider";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

export const UsersInvitePagePath = (brandHouseId: string) => {
   return UsersListPagePath(brandHouseId) + '/invite'
}

type InvitationData = {
   email: string
}

export const UsersInvitePage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse & ParamShop>()
   const navigate = useNavigate();
   const [invite] = useUsersInviteSendMutation()

   const methods = useForm<InvitationData>({
      defaultValues: {
         email: ''
      },
      resolver: yupResolver(
         yup.object().shape({
            email: yup.string().required('Please enter an email').email('Invalid email address')
         })
      ),
      mode: "onChange",
      reValidateMode: "onChange"
   });
   const {handleSubmit} = methods;
   const onSendInvite = async (inviteData: InvitationData) => {
      const {data} = await invite({
         variables: {
            email: inviteData.email,
            brandHouseId: brandHouseId
         },
         refetchQueries: [{
            query: UserAccessesDocument,
            variables: {
               brandHouseId: brandHouseId
            }
         }]
      });
      if (data?.UserInvite) {
         navigate(UsersListPagePath(brandHouseId));
      } else {
         Dialogs.info({
            title: 'Unable to invite user',
            subtitle: 'This user cannot be invited at this time'
         })
      }
   }

   return <FormProvider {...methods}>
      <Dialog open={true} onClose={() => navigate(UsersListPagePath(brandHouseId))}>
         <form id={"inviteuser"} onSubmit={handleSubmit(onSendInvite)}>
            <DialogTitle>
               <TitleText type={"h1"}>
                  Invite user
               </TitleText>
               <BodyText type={"body1"}>
                  For security reasons the user will have 12 hours to accept the invitation
               </BodyText>
            </DialogTitle>
            <DialogContent>
               <TitleText type={"h3"}>Enter the email</TitleText>
               <FormInputText name={"email"} label={"Email"}/>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => navigate(UsersListPagePath(brandHouseId))}
                       color={"secondary"}
                       variant={"outlined"}>Cancel</Button>
               <SingleClickButton type={"submit"} variant={"contained"}>
                  Send invitation
               </SingleClickButton>
            </DialogActions>
         </form>
      </Dialog>
   </FormProvider>
}
