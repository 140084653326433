import {gql, useMutation} from "@apollo/client";

const QL_REFRESH = gql`
    mutation UserRefresh($token:String!) {
      UserRefresh(token:$token) {
         token
      }
    }
`;


const useUserRefresh = () => {
   const [login] = useMutation(QL_REFRESH);

   return (token: string) => {
      return login({
         variables: {
            token: token
         }
      }).then(value => {
         return {
            token: value.data.UserRefresh.token,
         }
      })
   }
}

export default useUserRefresh;