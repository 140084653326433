import {gql, useMutation} from "@apollo/client";

export const qlStockItemCreateEmpty = gql`
    mutation ShopStockItemCreateEmpty($shopId: String!, $item: StockItemUpdateInput) {
      StockItemCreateEmpty(shopId:$shopId, stockItem: $item) {
         _id
         ean
      }
    }
`;

type StockItemUpdateInput = {
   _id: string
   title: string
   description: string
   productType: string
   vendor: string
   imageIds: string[]
   ean: string
   originalEan: string
   sku: string
   size: string
   color: string
   beforePrice: number
   sellingPrice: number
   condition: number
   active: boolean
   draft?: boolean
   ecommerceId: string
   published: boolean
   cleaning: StockItemCleaningInput
   repair: StockItemCleaningInput
   photo: StockItemCleaningInput
   stockLocation: string
   updatedAt: Date
   createdAt: Date
}

type StockItemCleaningInput = {
   required: boolean
}

const useStockItemCreateEmpty = () => {
   const [stockItemCreate] = useMutation(qlStockItemCreateEmpty);

   return (shopId: string, item: Partial<StockItemUpdateInput>) => {
      return stockItemCreate({
         variables: {
            shopId: shopId,
            item: item
         },
         update(cache, {data}) {
            cache.evict(data.StockItemCreateEmpty._id);
         }
      }).then(data => data.data.StockItemCreateEmpty?._id)
   }
}

export default useStockItemCreateEmpty;