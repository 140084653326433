import * as React from "react";
import {FC, useState} from "react";
import Page from "../../../../layout/Page";
import PageContent from "../../../../layout/PageContent";
import PageHeader from "../../../../layout/PageHeader";
import {useNavigate, useParams} from "react-router-dom";
import {DataColumn, DataCommand, DataTableAsync, QueryResult} from "../../../../components/datatable/DataTableAsync";
import {useApolloClient} from "@apollo/client";
import queryConsumers, {ListConsumer} from "./hooks/useConsumers";
import {ConsumerPagePath} from "./ConsumerPage";
import {Merge} from "@mui/icons-material";
import {Box, Dialog, DialogContent} from "@mui/material";
import {useConsumerRequestAccessMutation, useConsumersPageMergeMutation} from "../../../../Queries";
import Button from "@mui/material/Button";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputText} from "../../wholesale/form/FormInputText";
import {ParamShop} from "../../../../Parameters";

export const ConsumersPagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/consumers"
}

export const ConsumersPage: FC<any> = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const apolloClient = useApolloClient();
   const navigate = useNavigate();
   const [merge] = useConsumersPageMergeMutation()
   const [adding, setAdding] = useState<boolean>(false);

   let columns: DataColumn<ListConsumer>[] = [{
      field: 'firstName',
      label: "Name",
      render: (name, consumer) => {
         let consumerName = (consumer.firstName + ' ' + consumer.lastName).trim();
         if (!consumerName) {
            consumerName = 'No Name'
         }

         return <Box onClick={(e) => onSelectConsumer(e, consumer)}>{consumerName}</Box>
      },
      width: '15%'
   }, {
      label: "Address",
      field: 'zipCode',
      render: (data, consumer) => {
         return <div>{consumer.add1} {consumer.add2} {consumer.zipCode} {consumer.city} </div>
      }
   }, {
      field: "mail",
      label: "Email"
   }, {
      field: "phone",
      label: "Phone"
   }, {
      field: "acceptsMarketing",
      label: "Accepts marketing",
      render: (accepts) => {
         if (accepts) {
            return "Yes";
         } else {
            return "No"
         }
      }
   }]

   const requestData = async (page: number, orderBy?: string, order?: string, filter?: string) => {
      let listQuery = await queryConsumers(apolloClient, shopId, 15, page, orderBy, order, filter);
      return listQuery as QueryResult;
   }

   const onSelectConsumer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, consumer: ListConsumer) => {
      if (e.ctrlKey) {
         window.open(ConsumerPagePath(brandHouseId, shopId, consumer._id), '_blank')
      } else {
         navigate(ConsumerPagePath(brandHouseId, shopId, consumer._id));
      }
   }

   const commands: DataCommand[] = [{
      id: 'Merge',
      title: 'Merge',
      execute: async (rows) => {
         await merge({
            variables: {
               consumerIds: rows.map(row => row._id)
            }
         })
      },
      icon: Merge
   }]

   return <Page>
      <PageHeader title={"Consumers"}>
         <Button onClick={() => setAdding(true)}>Request consumer</Button>
      </PageHeader>
      {adding && <ConsumerDialog onClose={() => setAdding(false)}/>}
      <PageContent fullHeight>
         <DataTableAsync idField={"_id"} columns={columns} onRequestData={requestData}
                         commands={commands}
                         selections={true}
                         searchCaption={"Search by e-mail"}
         />
      </PageContent>
   </Page>
}

type ConsumerDialogProps = {
   onClose: () => void
}

const ConsumerDialog = ({onClose}: ConsumerDialogProps) => {
   const {shopId} = useParams<ParamShop>();

   type ConsumerDialogData = {
      email: string
   }
   const form = useForm<ConsumerDialogData>({
      defaultValues: {
         email: ""
      }
   });
   const [requestAccess] = useConsumerRequestAccessMutation();
   const {handleSubmit} = form

   const onSubmit = async (data: ConsumerDialogData) => {
      await requestAccess({
         variables: {
            shopId: shopId,
            mail: data.email
         }
      });
      onClose()
   }

   return <Dialog open={true} onClose={onClose}>
      <DialogContent>
         <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} id={"EmailAddForm"} style={{width: '100%'}}>
               <FormInputText name={"email"} label={"Email"} autoFocus/>
               <Button type={"submit"} form={"EmailAddForm"} variant={"contained"}>Save</Button>
            </form>
         </FormProvider>
      </DialogContent>
   </Dialog>
}