import * as React from 'react';
import {FC, useCallback, useEffect} from 'react';
import clsx from 'clsx';
import {lighten, Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {SearchField} from "./SearchField";
import {Button, LinearProgress, SvgIconProps} from "@mui/material";
import Grid from "@mui/material/Grid";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {debounce} from "lodash";

type Order = 'asc' | 'desc';

interface HeadCell {
   id: number;
   label: string;
   numeric: boolean;
   disablePadding?: boolean;
   width?: string;
   disableSort?: boolean
}

interface EnhancedTableProps {
   headCells: HeadCell[],
   classes: ReturnType<typeof useStyles>;
   numSelected: number;
   onRequestSort: (event: React.MouseEvent<unknown>, property: number) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order?: Order;
   orderBy?: number;
   rowCount: number;
   selections: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;

   const createSortHandler = (property: number) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            {props.selections && <TableCell padding="checkbox">
               <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{'aria-label': 'select all desserts'}}
               />
            </TableCell>}
            {props.headCells.map((headCell, idx) => (
               <TableCell
                  key={'headcol_' + headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  style={{paddingLeft: (props.selections ? 0 : (idx === 0 ? 10 : undefined))}}
                  sortDirection={orderBy === headCell.id ? order : false}
                  width={headCell.width}
               >
                  {headCell.disableSort && <div>{headCell.label}</div>}
                  {!headCell.disableSort && <TableSortLabel
                     active={orderBy === headCell.id}
                     direction={orderBy === headCell.id ? order : 'asc'}
                     onClick={createSortHandler(headCell.id)}
                  >
                     {headCell.label}
                  </TableSortLabel>}
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
      },
      highlight:
         theme.palette.mode === 'light'
            ? {
               color: theme.palette.secondary.main,
               backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
               color: theme.palette.text.primary,
               backgroundColor: theme.palette.secondary.dark,
            },
      title: {
         flex: '1 1 100%',
      },
   }),
);

interface EnhancedTableToolbarProps {
   numSelected: number;
   commands?: DataCommand[],
   onExecute: (command: DataCommand) => Promise<void>
   onSearch: (search: string) => void
   searchable: boolean
   rows?: number
   searchCaption?: string
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
   const [search, setSearch] = React.useState<string>('')
   const classes = useToolbarStyles();
   const {numSelected} = props;

   const onSearch = async (e: any) => {
      setSearch(e.target.value)
      props.onSearch(e.target.value);
   }

   const onExecute = async (cmd: DataCommand) => {
      await props.onExecute(cmd);
   }

   return (
      <Toolbar
         className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
         })}
      >
         {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
               {numSelected} selected
            </Typography>
         ) : <></>}
         {numSelected > 0 && props.commands && props.commands.map(cmd => {
            return (
               <Tooltip title={cmd.title} key={'cmd_' + cmd.id}>
                  <IconButton
                     aria-label={cmd.title}
                     onClick={async () => await onExecute(cmd)}
                     size="large">
                     <cmd.icon/>
                  </IconButton>
               </Tooltip>
            );
         })}
         {props.searchable && numSelected === 0 && <>
            <SearchField
               fullWidth={true}
               placeholder={props.searchCaption || "Filter"}
               value={search}
               onChange={onSearch}
               count={props.rows}
            />
         </>}
      </Toolbar>
   );
};

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: '100%',
      },
      paper: {
         width: '100%',
      },
      tableContainer: {},
      table: {
         minWidth: 500,
      },
      visuallyHidden: {
         border: 0,
         clip: 'rect(0 0 0 0)',
         height: 1,
         margin: -1,
         overflow: 'hidden',
         padding: 0,
         position: 'absolute',
         top: 20,
         width: 1,
      },
   }),
);

export type FilterValue = {
   field: string
   id: string
   value: string
}

export interface DataColumn<T> {
   field?: string
   label: string
   numeric?: boolean
   get?: (data: T) => any
   render?: (data: any, row: T) => any
   align?: 'left' | 'right' | 'center'
   width?: string
   filterOptions?: () => Promise<FilterValue[]>,
   nosort?: boolean
}

export interface DataCommand {
   id: string
   title: string
   icon: React.ElementType<SvgIconProps>
   execute: (rows: any[]) => Promise<void>
}

export interface QueryStats {
   page: number,
   pages: number,
   total: number,
}

export type QueryResult = QueryStats & {
   data: any[]
}

export type DataTableProps = {
   idField: string
   columns: DataColumn<any>[]
   commands?: DataCommand[]
   selections?: boolean
   minLineHeight?: number
   onRequestData: (page: number, orderBy?: string, order?: string, filter?: string) => Promise<QueryResult>
   orderBy?: number
   order?: Order,
   searchCaption?: string
   searchable?: boolean
   onSingleSelect?: (row: any) => void
   stateStorageId?: string
}

export const DataTableAsync = (props: DataTableProps) => {
   const classes = useStyles();
   const [filter, setFilter] = React.useState<string | undefined>(undefined);
   const [page, setPage] = React.useState<number>(1);
   const [order, setOrder] = React.useState<Order | undefined>(props.order);
   const [orderBy, setOrderBy] = React.useState<number | undefined>(props.orderBy);
   const [selected, setSelected] = React.useState<string[]>([]);
   const [data, setData] = React.useState<QueryResult | undefined>(undefined);

   const searchable = props.searchable !== undefined ? props.searchable : true;

   const getStateKey = (field: string) => {
      return 'dataTable.' + props.stateStorageId + '.' + field;
   }

   const loadState = () => {
      if (props.stateStorageId) {
         const page = localStorage.getItem(getStateKey('page'));
         if (page) {
            setPage(parseInt(page));
         } else {
            setPage(1);
         }
         const order = localStorage.getItem(getStateKey('order'));
         if (order) {
            setOrder(order as any as Order)
         } else {
            setOrder(undefined);
         }
         const orderBy = localStorage.getItem(getStateKey('orderBy'));
         if (orderBy) {
            setOrderBy(parseInt(orderBy));
         } else {
            setOrderBy(undefined);
         }
      }
   }
   const saveState = () => {
      if (props.stateStorageId) {
         localStorage.setItem(getStateKey('page'), String(page));
         localStorage.setItem(getStateKey('order'), String(order));
         localStorage.setItem(getStateKey('orderBy'), String(orderBy));
      }
   }

   useEffect(() => {
      if (props.stateStorageId) {
         loadState();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (orderBy) {
         let column = props.columns[orderBy];
         if (!column.field) {
            setOrderBy(undefined);
         } else {
            setOrderBy(orderBy);
         }
      }
   }, [orderBy, props.columns])

   useEffect(() => {
      (async function () {
         let orderField = orderBy !== undefined ? props.columns?.[orderBy]?.field : undefined;
         let dataQuery = await props.onRequestData(page, orderField, order, filter);
         if (dataQuery) {
            setData(dataQuery);
         }
         saveState();
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filter, order, orderBy, page, props])


   const handleSearchDebounce = (search: string) => {
      onSearch(search);
   }

   // eslint-disable-next-line
   const handleSearch = useCallback(
      debounce(handleSearchDebounce, 400),
      [searchable]);


   const handleRequestSort = (event: React.MouseEvent<unknown>, property: number) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };

   let cells: HeadCell[] = props.columns.map((col, idx) => {
      return {
         id: idx,
         label: col.label,
         disablePadding: true,
         numeric: col.numeric,
         width: col.width,
         disableSort: !props.columns[idx].field || props.columns[idx].nosort,
         filterOptions: props.columns[idx].filterOptions
      } as HeadCell
   })

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelecteds = data?.data.map((n) => n[props.idField]);
         if (newSelecteds) {
            setSelected(newSelecteds);
         }
         return;
      }
      setSelected([]);
   };

   const onExecuteCommand = async (command: DataCommand) => {
      let rows = data?.data.filter(row => {
         return selected.includes(row[props.idField])
      });
      if (rows) {
         await command.execute(rows)
      }
   }

   const onSearch = (search: string) => {
      setPage(1);
      setFilter(search);
   }

   const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }
      setSelected(newSelected);
   };

   const onChangePage = (page: number) => {
      setPage(page);
   }

   const isSelected = (name: string) => selected.indexOf(name) !== -1;
   const selectionEnabled = props.selections !== undefined ? props.selections : false;

   const onRowSelect = (row: any) => () => {
      if (props.onSingleSelect) {
         props.onSingleSelect(row);
      }
   }

   let dataTableContent = <>
      {(searchable || selectionEnabled) && <EnhancedTableToolbar
         numSelected={selected.length}
         commands={props.commands}
         onExecute={onExecuteCommand}
         onSearch={handleSearch}
         searchable={searchable}
         rows={data?.total}
         searchCaption={props.searchCaption}
      />}
      <TableContainer className={classes.tableContainer}>
         <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
         >
            <EnhancedTableHead
               selections={selectionEnabled}
               headCells={cells}
               classes={classes}
               numSelected={selected.length}
               order={order}
               orderBy={orderBy}
               onSelectAllClick={handleSelectAllClick}
               onRequestSort={handleRequestSort}
               rowCount={data?.total || 0}
            />
            <TableBody>
               {data?.data.map((row, index) => {
                  let id = row[props.idField];
                  const isItemSelected = isSelected(id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let cells = [];
                  let first = true;
                  for (const column of props.columns) {
                     let value = getCellValue(column, row);
                     let innerComponent;
                     if (column.render !== undefined) {
                        innerComponent = <>{column.render(value, row)}</>
                     } else {
                        innerComponent = <>{value}</>
                     }
                     cells.push(<TableCell key={'cell_' + row.id + '_' + column.label}
                                           style={{
                                              paddingLeft: (selectionEnabled ? 0 : (first ? 10 : undefined)),
                                              paddingRight: 10
                                           }}
                                           padding="none"
                                           align={column.align}>
                        {innerComponent}
                     </TableCell>);
                     first = false;
                  }
                  return (
                     <TableRow
                        hover
                        onClick={onRowSelect(row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={'data_' + row[props.idField]}
                        selected={isItemSelected}
                        style={{cursor: 'pointer', height: props.minLineHeight || 40}}
                     >
                        {selectionEnabled && <TableCell padding="checkbox">
                           <Checkbox
                              onClick={(event) => handleClick(event, row[props.idField])}
                              checked={isItemSelected}
                              inputProps={{'aria-labelledby': labelId}}
                           />
                        </TableCell>}
                        {cells}
                     </TableRow>
                  );
               })}
               {data?.data.length === 0 &&
               <TableRow><TableCell colSpan={props.columns.length}>No data</TableCell></TableRow>}
            </TableBody>
         </Table>
         {!data && <LinearProgress/>}
         <Pagination pages={data?.pages} page={data?.page} onChangePage={onChangePage}/>
      </TableContainer>
   </>;
   return (
      <div className={classes.root}>
         {dataTableContent}
      </div>
   );
}

type PaginationProps = { pages?: number, page?: number, onChangePage: (page: number) => void };
const Pagination: FC<PaginationProps> = ({pages, page, onChangePage}) => {
   if (!pages || !page) {
      return <></>
   }

   const onPageDown = () => {
      if (page > 1) {
         onChangePage(page - 1);
      }
   }
   const onPageUp = () => {
      onChangePage(page + 1);
   }

   const onPageFirst = () => {
      onChangePage(1);
   }

   const onPageLast = () => {
      onChangePage(pages);
   }

   return (
      <Grid container justifyContent={"flex-end"}>
         <IconButton style={{paddingLeft: 0, paddingRight: 0}}  onClick={onPageFirst} disabled={page === 1} size="medium">
            <KeyboardDoubleArrowLeftIcon/>
         </IconButton>
         <IconButton style={{paddingLeft: 0, paddingRight: 0}}  onClick={onPageDown} disabled={page === 1} size="medium">
            <KeyboardArrowLeftIcon/>
         </IconButton>
         <Button color={"secondary"} variant={"text"}>{page} of {pages}</Button>
         <IconButton style={{paddingLeft: 0, paddingRight: 0}}  onClick={onPageUp} disabled={page === pages} size="medium">
            <KeyboardArrowRightIcon/>
         </IconButton>
         <IconButton style={{paddingLeft: 0, paddingRight: 0}} onClick={onPageLast} disabled={page === pages} size="medium">
            <KeyboardDoubleArrowRightIcon/>
         </IconButton>
      </Grid>
   );
}


function getCellValue(column: DataColumn<any>, row: any) {
   if (column.get !== undefined) {
      return column.get(row)
   } else if (column.field !== undefined) {
      return row[column.field];
   } else {
      throw new Error('Column "' + column.label + '" must have either get or field');
   }
}
