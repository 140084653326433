import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {FC, forwardRef, useImperativeHandle, useRef} from "react";
import {Box, Button, Grid, LinearProgress, Paper} from "@mui/material";
import {Control, FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {useApiHook} from "../../../utility/useApiHook";
import {
   BrandAppLocalizationsQuery,
   LocalizationInput,
   useBrandAppLocalizationsQuery,
   useBrandAppLocalizationsUpdateMutation,
   useBrandAppShopsQuery,
   useShopAppLocalizationsQuery,
   useShopAppLocalizationsUpdateMutation
} from "../../../Queries";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Thumbnails} from "../../../components/Thumbnails";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import SettingsHeader from "../SettingsHeader";
import {SavingUI} from "../../../SavingProvider";
import {usePrompt} from "../../../components/useBlocker";
import {ShopTabs} from "../ShopTabs";
import UndoIcon from '@mui/icons-material/Undo';
import {LanguageSettingsPath} from "../languages/LanguageSettings";
import {LinkTo} from "../../../components/LinkTo";
import {ExportToExcel, ImportFromExcel} from "./TradeInAppTextExport";
import {Dialogs} from "../../../DialogProvider";

export type Section = {
   header: {
      title: string
      description: string
   }
   data: {
      key: string
      defaults: {
         [lang: string]: string
      }
   }[]
}

type DataValue = {
   text: string | undefined | null
   default: string | undefined | null
   inherited: boolean
};
type DataValues = {
   [id: string]: DataValue
};
export type TextFormData = {
   header: {
      title: string
      description: string
      images?: string[]
   }
   data: {
      key: string
      values: DataValues
      defaults: any
   }[]
}

const TradeInAppTextsPath = (brandId: string, shop: string) => {
   return "/brands/" + brandId + "/settings/translations/" + shop
}

export const TradeInAppTexts = () => {
   const {brandHouseId} = useParams<{ brandHouseId: string }>();
   const headers: Section[] = useApiHook('https://ourimpacthub-graphql.azurewebsites.net/locheaders2');
   if (!headers) {
      return <LinearProgress/>
   }
   return <div>
      <TradeInAppTextsInner headers={headers} brandHouseId={brandHouseId}/>
   </div>
}

type TradeInAppTextsInnerProps = {
   headers: Section[]
   brandHouseId: string
}

export const TradeInAppTextsInner = ({headers, brandHouseId}: TradeInAppTextsInnerProps) => {
   const {edit} = useParams<{ edit: string }>();
   const {data} = useBrandAppShopsQuery({
      variables: {id: brandHouseId}
   });
   const navigate = useNavigate();
   const ref = useRef<{ onExport: () => void, onImport: (data: TextFormData[]) => void }>();
   const importRef = useRef<HTMLInputElement | null>(null)

   if (!data?.Shops) {
      return <LinearProgress/>
   }

   const onStartImport = () => {
      Dialogs.confirm({
         title: 'Import translations?',
         subtitle: 'The imported translations will be imported into this view, and you will be able to review the changes before you save them.'
      }, () => {
         importRef.current?.click()
      })
   }

   const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      if (file) {
         await SavingUI.process(async () => {
            const data = await ImportFromExcel(file);
            ref.current?.onImport(data)
         }, 'Importing translations ... please wait')
      }
   }

   const handleChange = (newValue: string) => {
      navigate(TradeInAppTextsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Translations"} subtitle={
         <BodyText type={"subtitle1"}>
            Define texts for <LinkTo to={LanguageSettingsPath(brandHouseId, edit)}>languages</LinkTo> here
         </BodyText>
      }>
         <input ref={importRef}
                type="file"
                id="importInputFile"
                style={{display: 'none'}}
                onChange={onSelectFile}
         />

         <Button onClick={onStartImport}>Import</Button>
         <Button onClick={() => ref.current?.onExport()}>Export</Button>
         <Button type="submit" form={"appTextForm"} variant={"contained"}>Save</Button>
      </SettingsHeader>
      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <TradeInAppTextsEditor ref={ref} brandHouseId={brandHouseId} headers={headers}/>}
         renderShop={(shop) => <TradeInAppTextsEditorShop key={'appText_shop_' + shop} ref={ref} shopId={shop}
                                                          headers={headers}/>}
      />
   </div>
}

export type TradeInAppTextsEditorProps = {
   brandHouseId?: string
   shopId?: string
   headers: Section[]
}

export const TradeInAppTextsEditor = forwardRef(({brandHouseId, headers}: TradeInAppTextsEditorProps, ref) => {
   const {data} = useBrandAppLocalizationsQuery({
      variables: {
         id: brandHouseId,
         type: 'tradein2'
      },
      fetchPolicy: 'no-cache'
   });

   const [saveLocalizations] = useBrandAppLocalizationsUpdateMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: LocalizationInput[]) => {
      await SavingUI.process(async () => {
         await saveLocalizations({
            variables: {
               id: brandHouseId,
               input: data,
               type: 'tradein2'
            },
            refetchQueries: ['BrandLocalizations']
         });
      }, 'Default translations saved')
   }

   let brandLocales = data.BrandSettings?.locales || [];
   const locales: string[] = brandLocales
      .map(locale => locale?.locale) as string[];

   const localizationData = convertToFormData(headers, data, true);

   return <TradeInAppEditor ref={ref} localizations={localizationData} languages={locales} onSave={onSave}
                            isDefault={true}/>
})

export const TradeInAppTextsEditorShop = forwardRef(({shopId, headers}: TradeInAppTextsEditorProps, ref) => {
   const {data} = useShopAppLocalizationsQuery({
      variables: {
         id: shopId,
         type: 'tradein2'
      },
      fetchPolicy: 'no-cache'
   });

   const [saveLocalizations] = useShopAppLocalizationsUpdateMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: LocalizationInput[]) => {
      await SavingUI.process(async () => {
         await saveLocalizations({
            variables: {
               id: shopId,
               input: data,
               type: 'tradein2'
            },
            refetchQueries: ['BrandLocalizations']
         });
      }, 'Translations saved')
   }

   let brandLocales = data.BrandSettings?.locales || [];
   const locales: string[] = brandLocales
      .map(locale => locale?.locale) as string[];

   const localizationData = convertToFormData(headers, data, false);

   return <TradeInAppEditor ref={ref} localizations={localizationData} languages={locales} onSave={onSave}
                            isDefault={false}/>
})

type TradeInAppEditorProps = {
   languages: string[]
   localizations: TextFormData[]
   onSave: (data: LocalizationInput[]) => Promise<void>
   isDefault: boolean
}

export type TradeInAppFormData = {
   sections: TextFormData[]
}

const TradeInAppEditor = forwardRef(({languages, localizations, onSave, isDefault}: TradeInAppEditorProps, ref) => {
   const methods = useForm<TradeInAppFormData>({
      defaultValues: {
         sections: localizations
      }
   });
   const {handleSubmit, register, control, getValues, reset, setValue, formState: {isDirty}} = methods;

   const onSubmit = async (data: TradeInAppFormData) => {
      const updates: LocalizationInput[] = [];
      for (const section of data.sections) {
         for (const datum of section.data) {
            for (const language of languages) {
               if (isDefault && datum.values[language].text !== datum.defaults[language]) {
                  updates.push({
                     key: datum.key,
                     language: language,
                     value: datum.values[language].text
                  })
               } else if (!isDefault && datum.values[language].text !== datum.values[language].default) {
                  updates.push({
                     key: datum.key,
                     language: language,
                     value: datum.values[language].text
                  })
               }
            }
         }
      }
      await onSave(updates);
   }

   useImperativeHandle(ref, () => ({
      async onExport() {
         await ExportToExcel(languages, getValues().sections)
      },
      async onImport(data: TextFormData[]) {
         let currentValues = [...getValues().sections];
         for (const currentValue of currentValues) {
            const updated = data.find(dat => dat.header.title === currentValue.header.title);
            for (const currentData of currentValue.data) {
               const updatedData = updated?.data?.find(dat => dat.key === currentData.key);
               if (updatedData) {
                  for (const language of languages) {
                     if (currentData.values[language] && updatedData.values[language]) {
                        currentData.values[language].text = updatedData.values[language].text
                     }
                  }
               }
            }
         }
         reset({sections: []})
         setValue('sections', currentValues, {
            shouldDirty: true
         });
      }
   }))

   const {fields} = useFieldArray({
      control,
      name: "sections"
   });

   React.useEffect(() => {
      register("sections");
   }, [register]);


   usePrompt('Discard changes?', isDirty);

   return <FormProvider {...methods}>
      <form id={"appTextForm"} onSubmit={handleSubmit(onSubmit)}/>
      {fields.map((section, index) => {
         return <Paper key={'appTexts_section_' + section.header.title} sx={{padding: '20px', marginBottom: '20px'}}>
            <Box sx={{flexDirection: 'row', display: 'flex'}} justifyContent={"space-between"}>
               <div>
                  <TitleText type={"h2"}>{section.header.title}</TitleText>
                  <BodyText type={"subtitle1"}>{section.header.description}</BodyText>
               </div>
               {section.header.images && <Thumbnails imageUrls={section.header.images} size={60}/>}
            </Box>
            <div style={{marginTop: '10px'}}>
               <SectionEditor languages={languages} control={control} index={index} isDefault={isDefault}/>
            </div>
         </Paper>
      })}
   </FormProvider>
});

type SectionEditorProps = {
   languages: string[]
   control: Control<TradeInAppFormData>
   index: number
   isDefault: boolean
}

const SectionEditor: FC<SectionEditorProps> = ({languages, control, index, isDefault}) => {
   const {fields} = useFieldArray<TradeInAppFormData>({
      control,
      name: `sections.${index}.data`
   });
   const {getValues, setValue, trigger} = useFormContext();

   return <>{fields.map((field, nested) => {
      let item = field as any
      return <Grid key={'appTexts_section_editor_' + field.id} justifyContent={'flex-start'} wrap={'nowrap'}
                   style={{gap: '10px'}} container>
         {languages.map(lang => {
            const undo = <UndoIcon titleAccess={"Revert changes to default"} style={{cursor: 'pointer'}}
                                   fontSize={"small"} onClick={async () => {
               const defaultValue = getValues(`sections.${index}.data.${nested}.values.${lang}.default`);
               setValue(`sections.${index}.data.${nested}.values.${lang}.text`, defaultValue);
               setValue(`sections.${index}.data.${nested}.values.${lang}.inherited`, true);
               await trigger(`sections.${index}.data.${nested}.values.${lang}`)
            }}/>

            const inherited = getValues(`sections.${index}.data.${nested}.values.${lang}.inherited`)
            return <FormInputText name={`sections.${index}.data.${nested}.values.${lang}.text`} control={control}
                                  label={item.key + '(' + lang.toUpperCase() + ')'}
                                  InputProps={{
                                     endAdornment: <div>{!inherited && undo}</div>
                                  }}
                                  style={{
                                     backgroundColor: inherited ? '#fcf4f4' : '#ffffff'
                                  }}
            />
         })}
      </Grid>
   })}</>
}

function convertToFormData(headers: Section[], data: BrandAppLocalizationsQuery, isDefault: boolean): TextFormData[] {
   let t0 = Date.now()
   try {
      return headers
         .sort((a, b) => {
            if (a.header.title < b.header.title) {
               return -1;
            } else if (a.header.title > b.header.title) {
               return 1;
            } else {
               return 0;
            }
         })
         .map(header => {
            return {
               header: header.header,
               data: header.data.map(sect => {
                  const actualLocalizations = data.BrandLocalizations?.filter(loc => {
                     return loc?.key === sect.key
                  });
                  let values: DataValues = {};
                  actualLocalizations?.forEach(key => {
                     if (key?.language) {
                        values[key.language] = {
                           text: key.value,
                           inherited: key.inherited,
                           default: key.default
                        };
                     }
                  });
                  Object.keys(sect.defaults).forEach(def => {
                     if (!values[def]) {
                        values[def] = {
                           text: sect.defaults[def],
                           inherited: true,
                           default: sect.defaults[def]
                        }
                     } else {
                        if (isDefault) {
                           values[def].default = sect.defaults[def]
                        } // otherwise the default comes from the brand
                     }
                  });
                  return {
                     key: sect.key,
                     values: values,
                     defaults: sect.defaults
                  }
               })
            }
         });
   } finally {
      console.log(Date.now() - t0 + ' ms converting data')
   }
}