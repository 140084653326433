import {gql, useQuery} from "@apollo/client";

const qlConsumer = gql`
query IntegrationConsumerById($consumerId:MongoID!) {
  ConsumerById(_id: $consumerId) {
      _id
      firstName
      lastName
      add1
      add2
      zipCode
      city
      country
      locale
      mail
      phone
      acceptsMarketing
      accessToken
      lastLogin
      selfCreated     
      createdAt       
  }
}
`;


export interface Consumer {
   _id: string
   firstName: string
   lastName: string
   add1: string
   add2: string
   zipCode: string
   city: string
   country: string
   locale: string
   mail: string
   phone: string
   acceptsMarketing: boolean
   accessToken: string
   lastLogin: Date
   selfCreated: boolean
   createdAt: Date
}


const useConsumer = (consumerId: string):Consumer|null => {
   const {data} = useQuery(qlConsumer, {
      variables: {
         consumerId: consumerId
      }
   });

   if(!data) {
      return null;
   }

   return data.ConsumerById as Consumer
}

export default useConsumer