import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

type PageProps = {}

const Page: React.FC<PageProps> = ({children}) => {
   return <Box style={{minWidth: 600}}>
      <Grid item spacing={0} container xs={12}>
         {children}
      </Grid>
   </Box>
}

export default Page
