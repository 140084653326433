import {Button, Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import * as React from "react";

type AddLanguageProps = {
   onSelect: (language: string) => void
   hideLocales?: string[]
}

export const LanguageMenu = ({onSelect, hideLocales}: AddLanguageProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   let divider: boolean = false;

   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? 'account-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         startIcon={<AddCircle/>}
         variant={"outlined"}
         color={"secondary"}
      >
         Add language
      </Button>
      <Menu
         anchorEl={anchorEl}
         id="account-menu"
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {Locales.filter(locale => !(hideLocales||[]).find(lf => lf === locale.key)).map(locale => {
            let showDivider = false;
            if (!locale.main && !divider) {
               divider = true;
               showDivider = true;
            }

            return <>
               {showDivider && <Divider/>}
               <MenuItem key={'menu_locale_' + locale.key} onClick={() => onSelect(locale.key)}>
                  <ListItemIcon>
                     <LanguageIcon fontSize="small"/>
                  </ListItemIcon>
                  {locale.name}
               </MenuItem>
            </>
         })}
      </Menu>
   </>
}


export interface Locale {
   key: string;
   name: string;
   main?: boolean
}

export const Locales: Locale[] = [
   {
      "key": "en",
      "name": "English",
      "main": true
   },
   {
      "key": "da",
      "name": "Danish",
      "main": true
   },
   {
      "key": "de",
      "name": "German",
      "main": true
   },
   {
      "key": "no",
      "name": "Norwegian",
      "main": true
   },
   {
      "key": "sv",
      "name": "Swedish",
      "main": true
   },
   {
      "key": "sq",
      "name": "Albanian"
   },
   {
      "key": "bg",
      "name": "Bulgarian"
   },
   {
      "key": "bs",
      "name": "Bosnian"
   },
   {
      "key": "hr",
      "name": "Croatian"
   },
   {
      "key": "cs",
      "name": "Czech"
   },
   {
      "key": "nl",
      "name": "Dutch"
   },
   {
      "key": "el",
      "name": "Greek"
   },
   {
      "key": "et",
      "name": "Estonian"
   },
   {
      "key": "fi",
      "name": "Finnish"
   },
   {
      "key": "fo",
      "name": "Faroese"
   },
   {
      "key": "fr",
      "name": "French"
   },
   {
      "key": "ka",
      "name": "Georgian"
   },
   {
      "key": "hu",
      "name": "Hungarian"
   },
   {
      "key": "is",
      "name": "Icelandic"
   },
   {
      "key": "it",
      "name": "Italian"
   },
   {
      "key": "lt",
      "name": "Lithuanian"
   },
   {
      "key": "lv",
      "name": "Latvian"
   },
   {
      "key": "mk",
      "name": "Macedonian"
   },
   {
      "key": "mt",
      "name": "Maltese"
   },
   {
      "key": "pl",
      "name": "Polish"
   },
   {
      "key": "pt",
      "name": "Portuguese"
   },
   {
      "key": "ro",
      "name": "Romanian"
   },
   {
      "key": "ru",
      "name": "Russian"
   },
   {
      "key": "sl",
      "name": "Slovenian"
   },
   {
      "key": "es",
      "name": "Spanish"
   },
   {
      "key": "sr",
      "name": "Serbian"
   },
   {
      "key": "tr",
      "name": "Turkish"
   },
   {
      "key": "uk",
      "name": "Ukrainian"
   }
]