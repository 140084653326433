import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import PageHeader from "../../../layout/PageHeader";
import * as React from "react";
import {ListTradeInsList} from "../../lists/ListTradeInsList";
import {useParams} from "react-router";
import {ParamBrandHouse} from "../../../Parameters";

export const BrandTradeInsListPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId + "/tradeins"
}

export const BrandTradeInsListPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   return <Page>
      <PageHeader title="TradeIns"/>
      <PageContent>
         <ListTradeInsList globalId={"brandTradeIns"} extrafilter={{
            brandHouseId
         }}/>
      </PageContent>
   </Page>
}
