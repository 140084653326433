import * as React from "react";
import PageContent from "../../../layout/PageContent";
import {
   BrandLocaleInput,
   LanguagesFragment,
   useLanguageSettingsQuery,
   useLanguageSettingsUpdateMutation
} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {Button, ButtonGroup, Grid, LinearProgress} from "@mui/material";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {SavingUI} from "../../../SavingProvider";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {ArrowDropDown, ArrowDropUpSharp, DeleteForever} from "@mui/icons-material";
import {LanguageMenu} from "../LanguageMenu";
import {Dialogs} from "../../../DialogProvider";
import {Languages} from "./Languages";
import {FormInputLanguage} from "../../shop/wholesale/form/FormInputLanguage";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";

const FORMID_LANGUAGE = "SettingsCountries";

export const LanguageSettingsPath = (brandId: string, shopId?: string) => {
   let path = "/brands/" + brandId + "/settings/languages";
   if (shopId) {
      path += '/' + shopId
   }
   return path;
}

export const LanguageSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(LanguageSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Languages"} subtitle={"The languages used"}>
         <Button type="submit" form={FORMID_LANGUAGE} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <LanguagesInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <LanguagesInner shopId={shop}/>}
      />
   </div>
}

type CountriesPageInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const LanguagesInner = ({brandHouseId, shopId}: CountriesPageInnerProps) => {
   const {data} = useLanguageSettingsQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   const [saveLanguages] = useLanguageSettingsUpdateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: LanguagesFragment) => {
      if ((data.locales || []).filter(locale => locale?.activeItems).length === 0) {
         Dialogs.info({
            title: 'One language must be active for stockitems',
            subtitle: 'Do not remove all languages and make sure that atleast one language is active'
         });
      } else if ((data.locales || []).filter(locale => locale?.activeTradeIn).length === 0) {
         Dialogs.info({
            title: 'One language must be active for tradeins',
            subtitle: 'Do not remove all languages and make sure that atleast one language is active'
         });
      } else {
         await SavingUI.process(async () => {
            await saveLanguages({
               variables: {
                  shopId: shopId,
                  brandHouseId: brandHouseId,
                  locales: data.locales as BrandLocaleInput[],
                  masterDataLocale: data.masterDataLocale || 'da'
               }
            });
         }, 'Languages updated')
      }
   }
   return <LanguageSettingsEditor data={data!.BrandSettings as LanguagesFragment}
                                  onSave={onSave}
   />
}


type LanguageSettingsProps = {
   data?: LanguagesFragment
   onSave: (data: LanguagesFragment) => Promise<void>
}

const LanguageSettingsEditor = ({data, onSave}: LanguageSettingsProps) => {
   const methods = useForm<LanguagesFragment>({
      defaultValues: data
   });
   const {handleSubmit, control} = methods;

   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const {fields: localeFields, append: localeAppend, remove: localeRemove, move} = useFieldArray({
      control,
      name: 'locales'
   });

   // @ts-ignore
   return <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} id={FORMID_LANGUAGE}/>
      <PageContent>
         <TitleText type={"h2"}>Languages</TitleText>
         <BodyText type={"subtitle1"}>The masterdata coming from any shop, is</BodyText>
         <br/>

         <Grid container>
            <Grid key={'active_root'} container gap={1} wrap={"nowrap"} alignItems={"center"}>
               <Grid item xs={2}>
                  <TitleText type={"h2"}>Language</TitleText>
               </Grid>
               <Grid item xs={2}>
                  <TitleText type={"h2"}>TradeIn App</TitleText>
               </Grid>
               <Grid item xs={2}>
                  <TitleText type={"h2"}>Stock Items</TitleText>
               </Grid>
               <Grid item xs={8}>
               </Grid>
            </Grid>
            {localeFields?.map((localeField, i) => {
               const localeDefinition = Languages.all().find(loc => loc.key === localeField.locale)
               return <Grid key={'active_' + localeField.locale} container gap={1} wrap={"nowrap"}
                            alignItems={"center"}>
                  <Grid item xs={2}>
                     {localeDefinition?.name}
                  </Grid>
                  <Grid item xs={2}>
                     <FormInputCheckbox name={`locales[${i}].activeTradeIn`} label={"Active"}/>
                  </Grid>
                  <Grid item xs={2}>
                     <FormInputCheckbox name={`locales[${i}].activeItems`} label={"Active"}/>
                  </Grid>
                  <Grid item xs={8}>

                     <ButtonGroup orientation={"horizontal"} size={"small"}>
                        <Button
                           onClick={() => localeRemove(i)}
                           size="small"
                           sx={{ml: 2}}
                           aria-controls={'account-menu'}
                           aria-haspopup="true"
                           aria-expanded={'true'}
                           color={"secondary"}
                        >
                           <DeleteForever/>
                        </Button>
                        <Button
                           onClick={() => move(i, i - 1)}
                           size="small"
                           sx={{ml: 2, padding: 0}}
                           aria-controls={'account-menu'}
                           aria-haspopup="true"
                           aria-expanded={'true'}
                           disabled={i === 0}
                           color={"secondary"}
                        >
                           <ArrowDropUpSharp/>
                        </Button>
                        <Button
                           onClick={() => move(i, i + 1)}
                           size="small"
                           sx={{ml: 2, padding: 0}}
                           aria-controls={'account-menu'}
                           aria-haspopup="true"
                           aria-expanded={'true'}
                           disabled={i === localeFields?.length - 1}
                           color={"secondary"}
                        >
                           <ArrowDropDown/>
                        </Button>
                     </ButtonGroup>
                  </Grid>
               </Grid>
            })}
            <div style={{marginTop: '20px'}}>
               <LanguageMenu onSelect={(locale) => localeAppend({
                  locale: locale,
                  activeTradeIn: true,
                  activeItems: true
               })} hideLocales={localeFields.map(lf => lf.locale)}/>
            </div>
         </Grid>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Master data language</TitleText>
         <BodyText type={"subtitle1"}>The masterdata coming from any shop, is</BodyText>
         <br/>
         <FormInputLanguage name={"masterDataLocale"} label={"Master data language"}
                            disabled={!!data?.masterDataLocale && !isAdministrator}/>
         Careful, this setting should probably not be changed.
      </PageContent>
   </FormProvider>
}

