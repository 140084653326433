import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   IconButton,
   LinearProgress,
   Paper,
   Popper
} from "@mui/material";
import {useParams} from "react-router";
import {DataGridPro, GridColDef, GridRenderCellParams, useGridApiContext, useGridApiRef} from "@mui/x-data-grid-pro";
import {
   BtnBold,
   BtnBulletList,
   BtnClearFormatting,
   BtnItalic,
   BtnNumberedList,
   BtnRedo,
   BtnStyles,
   BtnUnderline,
   BtnUndo,
   ContentEditableEvent,
   Editor,
   EditorProvider,
   HtmlButton,
   Separator,
   Toolbar
} from "react-simple-wysiwyg";
import {ParamShop} from "../../../../Parameters";
import {Thumbnails} from "../../../../components/Thumbnails";
import {
   ProductVariantSize,
   StockItemMultiFragment,
   StockItemUpdateInput,
   useStockItemMultiQuery,
   useStockItemMultiRecalculatePriceMutation,
   useStockItemMultiSettingsQuery,
   useStockItemMultiUpdateMutation,
   ViewProductVariantFragment
} from "../../../../Queries";
import {SavingUI} from "../../../../SavingProvider";
import SearchIcon from "@mui/icons-material/Search";
import {ProductsList} from "../../components/ProductList";
import {Dialogs} from "../../../../DialogProvider";
import {StringHelper} from "../../../../utility/StringHelper";

type StockItemMultiEditProps = {
   ids: string[]
   onClose: () => void
}

export const StockItemMultiEdit = ({ids, onClose}: StockItemMultiEditProps) => {
   const {shopId} = useParams<ParamShop>()
   const [language, setLanguage] = useState<string>('da')
   const [storedData, setStoredData] = useState<StockItemContainer | undefined>(undefined)
   const updatedData = useRef<StockItemUpdated>({})
   const disabled = useRef<boolean>(false)
   const [update] = useStockItemMultiUpdateMutation()
   const [recalculate] = useStockItemMultiRecalculatePriceMutation()

   const {data: items} = useStockItemMultiQuery({
      variables: {
         StockItemIds: ids
      }
   })
   const {data: settings} = useStockItemMultiSettingsQuery({
      variables: {
         shopId
      }
   })

   const onDataChange = (data: StockItemData) => {
      updatedData.current[data._id] = data;
   }

   const onSave = async () => {
      if (disabled.current) {
         return;
      }
      await SavingUI.process(async () => {
         for (const id of Object.keys(updatedData.current)) {
            const row = updatedData.current[id];

            let stockItemInput: StockItemUpdateInput = {
               _id: row._id,
               sku: row.originalSku,
               sellingPrice: row.sellingPrice,
               beforePrice: row.beforePrice,
               originalEan: row.originalEan,
               title: row.title,
               description: row.description,
               color: row.color,
               size: row.size,
               vendor: row.vendor,
               productType: row.type,
               condition: row.condition,
               productVariantId: row.productVariantId,
               reviewed: row.reviewed ? new Date() : null
            };

            const {data: updatedPrice} = await recalculate({
               variables: {
                  shopId,
                  stockItem: stockItemInput
               }
            });

            stockItemInput.sellingPrice = updatedPrice?.StockItemsRecalculatePrice

            await update({
               variables: {
                  shopId,
                  input: stockItemInput
               },
               refetchQueries: ['StockItemPagination']
            })
         }
         onClose()
      }, 'Saving changes', 'Saving all changed stockitems')
   }

   useEffect(() => {
      if (items && settings) {
         const locales = settings.BrandSettings?.locales?.filter(locale => locale!.activeItems).map(locale => locale!.locale);
         const currencies = settings.BrandSettings?.stockItemConfig?.currencies?.filter(currency => currency!.active).map(currency => currency!.currency)

         const container: StockItemContainer = {
            currencies: currencies || [],
            locales: locales || [],
            data: []
         }

         const rows: StockItemData[] = []
         for (const brandItem of items.StockItems2 || []) {
            const currentBrandItem = brandItem! as StockItemMultiFragment

            const getStockItemData = (source: StockItemMultiFragment) => {
               const newItem: StockItemData = {
                  _id: source._id,
                  itemNr: source.itemNr!,
                  title: source.title!,
                  description: source.description!,
                  color: source.color!,
                  size: source.size!,
                  type: source.productType!,
                  vendor: source.vendor!,
                  originalEan: source.originalEan!,
                  originalSku: source.sku!,
                  beforePrice: source.beforePrice!,
                  sellingPrice: source.sellingPrice!,
                  imageUrls: source.imageUrls || [],
                  itemState: 'NONE',
                  condition: source.condition!,
                  productVariantId: source.productVariantId,
                  reviewed: !!source.reviewed
               }
               return newItem;
            }
            const newItem = getStockItemData(currentBrandItem);
            rows.push(newItem);

            rows.sort((a, b) => a.itemNr - b.itemNr)
            container.data = rows;
         }
         setLanguage(locales?.[0] || 'da')
         setStoredData(container)
      }
   }, [items, settings])


   if (!storedData || !language) {
      return <LinearProgress/>
   }

   function onEditStart() {
      disabled.current = true
   }

   function onEditStop() {
      disabled.current = false
   }

   return <Dialog open={ids.length > 0} fullScreen sx={{padding: '20px'}} onClose={onClose} disableEscapeKeyDown>
      <DialogTitle>
         <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            Editing {ids.length} stockitems
            <Box sx={{display: 'flex', gap: 5}}>
               <Button onClick={onSave} variant={"contained"}>Save</Button>
            </Box>
         </Box>
      </DialogTitle>
      <DialogContent>
         <StockItemMultiEditInner
            data={storedData.data}
            onChange={onDataChange}
            onEditStart={onEditStart}
            onEditStop={onEditStop}
            currency={settings?.Shop?.currency || 'DKK'}
         />
      </DialogContent>
   </Dialog>
}

type StockItemUpdated = {
   [id: string]: StockItemData
}

type StockItemContainer = {
   locales: string[]
   currencies: string[]
   data: StockItemData[]
}

type StockItemData = {
   _id: string
   productVariantId?: string
   itemNr: number
   title?: string
   description?: string
   color?: string
   size?: string
   type?: string
   beforePrice?: number
   sellingPrice?: number
   originalEan?: string
   originalSku?: string
   vendor?: string
   imageUrls: string[]
   itemState: string
   condition: number
   resolved?: Omit<StockItemData, 'resolved'>
   reviewed: boolean
}

type StockItemMultiEditInnerProps = {
   data: StockItemData[]
   onChange: (data: StockItemData) => void
   onEditStart: () => void
   onEditStop: () => void
   currency: string
}

const StockItemMultiEditInner = ({data, onChange, onEditStop, onEditStart, currency}: StockItemMultiEditInnerProps) => {
   const {shopId} = useParams<ParamShop>()
   const apiRef = useGridApiRef()

   type SearchContext = {
      search?: string
      stockItem: StockItemData
   }

   const [updating, setUpdating] = useState<SearchContext | undefined>(undefined);

   const grades = [{
      value: 1,
      label: 'Fair'
   }, {
      value: 2,
      label: 'Good'
   }, {
      value: 3,
      label: 'Excellent'
   }];

   const doSearch = (row: StockItemData) => {
      setUpdating({
         search: row.title,
         stockItem: row
      })
   }

   const columns: GridColDef<StockItemData>[] = [{
      field: 'itemNr',
      sortable: true,
      headerName: '#',
      width: 100,
      renderCell: ({value, row}) => {
         return <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Thumbnails imageUrls={row.imageUrls} size={50}/>
            {value}
         </Box>
      }
   }, {
      field: 'title',
      headerName: 'Name',
      editable: true,
      width: 200
   }, {
      field: 'description',
      headerName: 'Description',
      width: 200,
      editable: true,
      renderEditCell: (params) => {
         return <EditTextarea {...params}/>
      }
   }, {
      field: 'color',
      headerName: 'Color',
      editable: true,
      width: 100
   }, {
      field: 'size',
      headerName: 'Size',
      editable: true,
      width: 100
   }, {
      field: 'type',
      headerName: 'Type',
      editable: true,
      width: 100,
   }, {
      field: 'originalEan',
      headerName: 'Orig EAN',
      editable: true,
      width: 100,
   }, {
      field: 'originalSku',
      headerName: 'Orig SKU',
      editable: true,
      width: 100,
   }, {
      field: 'condition',
      headerName: 'Condition',
      editable: true,
      type: 'singleSelect',
      valueOptions: grades,
      renderCell: ({value}) => {
         return grades.find(grade => grade.value === value)?.label
      },
   }, {
      field: 'beforePrice',
      headerName: 'RRP',
      type: 'number',
      editable: true,
      width: 100,
      sortable: true,
      resizable: true
   }, {
      field: 'reviewed',
      headerName: 'Reviewed',
      type: 'boolean',
      editable: true,
      width: 100,
      sortable: true,
   }, {
      field: '_id',
      sortable: false,
      headerName: '',
      width: 100,
      renderCell: ({value, row}) => {
         return <Box sx={{display: 'flex', alignItems: 'center'}}>
            <IconButton title={"Search for master data"}
                        onClick={() => doSearch(row)}><SearchIcon/></IconButton>
         </Box>
      }
   }]

   const onCopyProduct = async (variant: ViewProductVariantFragment, size: Partial<ProductVariantSize>) => {
      if (updating) {
         const doUpdate = () => {
            updating.stockItem.title = variant.product?.name || undefined
            updating.stockItem.beforePrice = variant.rrp?.find(rrp => rrp?.currency === currency)?.value || undefined
            updating.stockItem.type = variant.product?.productType || undefined
            updating.stockItem.color = variant.color || undefined
            updating.stockItem.originalEan = variant.ean || undefined
            updating.stockItem.originalSku = variant.sku || undefined
            updating.stockItem.productVariantId = variant._id
            updating.stockItem.size = size.size || undefined
            updating.stockItem.reviewed = true
            apiRef.current.updateRows([updating.stockItem])
            onChange(updating.stockItem)
         }

         if (updating.stockItem.size && StringHelper.compare(updating.stockItem.size, size.size) !== 0) {
            Dialogs.confirm({
               title: 'Warning - Size change!',
               subtitle: 'The style you are editing, does not have the same SIZE as the one you just selected !\n' +
                  'This should be avoided, since the logistics partner registers the sizes when receiving the item\n' +
                  'Are you should you wish to CHANGE THE SIZE of this style?',
               defaultNo: true
            }, () => {
               doUpdate()
            })
         } else {
            doUpdate()
         }
      }
      setUpdating(undefined);
   }

   return <div style={{height: '100%'}}>
      <DataGridPro
         apiRef={apiRef}
         getRowId={(row) => row._id}
         columns={columns}
         rows={data}
         rowHeight={70}
         disableMultipleSelection={true}
         hideFooter={true}
         experimentalFeatures={{
            newEditingApi: true
         }}
         processRowUpdate={(cur, old) => {
            onChange(cur)
            return cur
         }}
         onProcessRowUpdateError={(err) => {

         }}
         onCellEditStart={onEditStart}
         onCellEditStop={onEditStop}
      />
      <Dialog open={updating !== undefined} onClose={() => setUpdating(undefined)} fullWidth maxWidth={"lg"}>
         <DialogTitle>Select product to copy</DialogTitle>
         <DialogContent>
            <ProductsList shopId={shopId} onVariantSelect={onCopyProduct} defaultSearch={updating?.search} inDialog/>
         </DialogContent>
         <DialogActions>
            <Button variant={"outlined"} onClick={() => setUpdating(undefined)}>Cancel</Button>
         </DialogActions>
      </Dialog>

   </div>
}


const EditTextarea = (props: GridRenderCellParams) => {
   const {id, field, value, colDef} = props;
   const [valueState, setValueState] = React.useState(value);
   const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>();

   const {current: api} = useGridApiContext()

   const minWidth = React.useMemo(() => {
      if (colDef.computedWidth) {
         return colDef.computedWidth + 400;
      }
      return 0;
   }, [colDef.computedWidth]);

   const setEditCellValue = React.useCallback(
      (event: ContentEditableEvent, newValue: string) => {
         api.setEditCellValue({id, field, value: newValue}, event);
      },
      [api, field, id]
   );

   const debouncedSetEditCellValue = React.useMemo(
      () => debounce(setEditCellValue, 60),
      [setEditCellValue]
   );

   const handleRef = (el: HTMLDivElement) => {
      setAnchorEl(el);
   };

   const handleChange = React.useCallback(
      (event: ContentEditableEvent) => {
         const newValue = event.target.value;
         setValueState(newValue);
         debouncedSetEditCellValue(event, newValue);
      },
      [debouncedSetEditCellValue]
   );

   return (
      <div>
         <div
            ref={handleRef}
            style={{
               height: 1,
               width: minWidth,
               display: "block",
               position: "absolute",
            }}
         />
         {anchorEl && (
            <Popper open anchorEl={anchorEl} placement="bottom-end" sx={{zIndex: 9999}}>
               <Paper elevation={1} sx={{p: 1, width: minWidth}}>
                  <EditorProvider>
                     <Editor tabIndex={0} style={{
                        overflow: 'auto',
                        color: 'inherit'
                     }} value={valueState} onChange={handleChange}>
                        <Toolbar>
                           <BtnUndo/>
                           <BtnRedo/>
                           <Separator/>
                           <BtnBold/>
                           <BtnItalic/>
                           <BtnUnderline/>
                           <Separator/>
                           <BtnNumberedList/>
                           <BtnBulletList/>
                           <Separator/>
                           <BtnClearFormatting/>
                           <HtmlButton/>
                           <Separator/>
                           <BtnStyles/>
                        </Toolbar>
                     </Editor>
                  </EditorProvider>
               </Paper>
            </Popper>
         )}
      </div>
   );
};
