import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {useTrackingPageQuery, useTrackingPageUpdateMutation} from "../../../Queries";
import {useParams} from "react-router-dom";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {Button} from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import {Dialogs} from "../../../DialogProvider";
import * as React from "react";
import StopIcon from "@mui/icons-material/Stop";
import {TrackingViewList} from "../../lists/TrackingViewList";

export const TrackingPage = () => {
   const {shopId} = useParams<ParamShop & ParamBrandHouse>()
   const {data} = useTrackingPageQuery({
      variables: {
         shopId: shopId
      }
   });
   const [updateTracking] = useTrackingPageUpdateMutation();

   return <Page>
      <PageHeader title={"Activity"}>
         <Button
            variant={"outlined"}
            startIcon={data?.BrandSettings?.tracking?<StopIcon/>:<PlayCircleFilledWhiteIcon/>}
            onClick={() => {
               let title: string, desc: string;
               if(!data?.BrandSettings?.tracking) {
                  title = 'Are you sure you wish to enable tracking?';
                  desc = 'Tracking will collect data about users navigating the external shop'
               } else {
                  title = 'Are you sure you wish to disable tracking?';
                  desc = 'The tracking will be disabled, it might take some time until all tracking stops'
               }
               Dialogs.confirm({
                  title: title,
                  subtitle: desc
               }, async () => {
                  await updateTracking({
                     variables: {
                        shopId: shopId,
                        tracking: !data?.BrandSettings?.tracking
                     },
                     refetchQueries: ['BrandSettings']
                  });
               })
            }}>{data?.BrandSettings?.tracking?'Deactivate':'Activate'} tracking</Button>
      </PageHeader>
      <PageContent fullHeight>
         <TrackingViewList brandColumn={false} filter={{
            shopId: shopId
         }}/>
      </PageContent>
   </Page>
}