import * as React from "react";
import {useState} from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";
import {useResetPasswordSendMutation} from "../Queries";
import {SingleClickButton} from "../components/SingleClickButton";

export const ForgotPasswordPage = () => {
   const classes = useStyles();
   const [email, setEmail] = useState<string>('');
   const [sent, setSent] = useState<boolean>(false);
   const [recover] = useResetPasswordSendMutation()

   const onSubmit = async () => {
      const {data} = await recover({
         variables: {
            email: email
         }
      });
      setSent(data?.UserRecoverPassword || false)
   }

   return <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline/>
      <div className={classes.paper}>
         {!sent && <>
            <Typography component="h1" variant="h5">
               Forgot your password?
            </Typography>
            <Typography>
               Please enter the email address associated with your create2STAY account.
            </Typography>
            <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
               <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="email"
                  onChange={(e) => {
                     setEmail(e.target.value)
                  }}
               />

               <SingleClickButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onSubmit}
                  disabled={email.length === 0 || !email.includes('@')}
               >
                  Send a password reset email
               </SingleClickButton>
            </form>
         </>}
         {sent && <>
            <Typography component="h1" variant="h5">
               We sent you an email!
            </Typography>
            <Typography>
               If its not in your inbox within a few minutes, check your spam folder or try again.
            </Typography>
         </>}
      </div>
   </Container>
}

const useStyles = makeStyles((theme: Theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px'
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   container: {
      backgroundColor: 'white',
      padding: '0px 20px 20px',
      marginTop: '20px'
   }
}));

