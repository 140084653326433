import {useVoucherUsagesQuery, VoucherAdjustment, VoucherUsage} from "../../../Queries";
import {Link, TitleText} from "../../../layout/Typography";
import {Box, LinearProgress, Step, StepLabel, Stepper} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {ParamBrandHouse} from "../../../Parameters";
import {ConsumerPagePath} from "../../shop/masterdata/consumers/ConsumerPage";
import {TradeInPagePath} from "../../shop/tradeins/TradeInPage";

type VoucherUsagesProps = {
   voucherId: string
}

type VoucherDataItem = {
   usage?: VoucherUsage
   adjustment?: VoucherAdjustment
   date: Date
}

export const VoucherUsages = ({voucherId}: VoucherUsagesProps) => {
   const [items, setItems] = useState<VoucherDataItem[]>([]);
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const {data} = useVoucherUsagesQuery({
      variables: {
         voucherId: voucherId
      }
   })
   const navigate = useNavigate();
   const voucher = data?.VoucherById

   useEffect(() => {
      const newItems: VoucherDataItem[] = [];
      for (const voucherUsage of data?.VoucherUsages || []) {
         newItems.push({
            usage: voucherUsage as VoucherUsage,
            date: voucherUsage.usageDate
         })
      }
      for (const voucherAdjustment of data?.VoucherAdjustments || []) {
         newItems.push({
            adjustment: voucherAdjustment as VoucherAdjustment,
            date: voucherAdjustment.date
         })
      }
      setItems(newItems.sort((a, b) => {
         return new Date(b.date).getTime() - new Date(a.date).getTime()
      }));

   }, [data])

   if (!data) {
      return <LinearProgress/>
   }

   return <div>
      <Box sx={{marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
         <TitleText sx={{marginTop: 0}} component={"div"} type={"h2"}>Timeline</TitleText>
      </Box>
      <Stepper orientation={"vertical"}>
         {!!voucher?.deleted && <Step>
            <StepLabel error icon={"D"}>
               <span
                  title={DateFormat.toReadable(voucher?.deleted)}>{DateFormat.timeSince(voucher.deleted)}</span>:
               Voucher deleted
            </StepLabel>
         </Step>
         }
         <div>{items.map((item, i) => {
            const elements: any[] = [];

            if (item.usage) {
               const usage = item.usage;
               elements.push(<Step active={i === 0} key={'step_' + usage?._id}>
                  <StepLabel icon={"U"}>
                  <span
                     title={DateFormat.toReadable(usage?.usageDate)}>{DateFormat.timeSince(usage?.usageDate)}</span><br/>
                     Consumer used {!voucher?.passive && <>{Math.abs(usage.voucherUsage)} {voucher?.currency}</>} on&nbsp;
                     <a target={"_blank"} rel="noreferrer" href={usage?.externUrl || '#'}>order</a>
                     {usage?.refunds?.map(refund => {
                        return <li>
                           Consumer was issued a refund {DateFormat.timeSince(refund?.refundDate)}
                        </li>
                     })}
                  </StepLabel>
               </Step>);
            } else {
               const adjustment = item.adjustment;
               const amount = Math.round(adjustment?.amount || 0);
               elements.push(<Step active={i === 0} key={'step_' + adjustment?._id}>
                  <StepLabel icon={"A"}>
                     <span title={DateFormat.toReadable(adjustment?.date)}>
                        {DateFormat.timeSince(adjustment?.date)}
                     </span>
                     <br/>
                     {amount < 0 && <>{Math.abs(amount)} {voucher?.currency} was subtracted from the voucher</>}
                     {amount >= 0 && <>{Math.abs(amount)} {voucher?.currency} was added to the voucher</>}
                     <Box>
                        Reason:
                        {adjustment?.reason}
                     </Box>
                  </StepLabel>
               </Step>);
            }
            return elements
         })}</div>
         <Step>
            <StepLabel icon={"S"}>
               <span
                  title={DateFormat.toReadable(voucher?.createdAt)}>{DateFormat.timeSince(voucher?.createdAt)}</span><br/>
               {Math.round(voucher?.value || 0)} {voucher?.currency} credit issued to <Link
               onClick={() => navigate(ConsumerPagePath(brandHouseId, voucher?.shopId, voucher?.consumerId))}>consumer</Link> for <Link
               onClick={() => navigate(TradeInPagePath(brandHouseId, voucher?.shopId, voucher?.tradeInId))}>tradein</Link>.<br/>
            </StepLabel>
         </Step>

      </Stepper>
   </div>
}
