import {Dialog, DialogContent} from "@mui/material";
import React from "react";
import 'react-advanced-cropper/dist/style.css';
import {ImageEditor} from "../../../components/imageeditor/ImageEditor";
import axios from "axios";
import {SavingUI} from "../../../SavingProvider";
import {useApolloClient} from "@apollo/client";
import {useImageEditorImageQuery} from "../../../Queries";

type BrandItemImageEditorProps = {
   brandHouseId: string
   imageId: string
   show: boolean
   onClose: () => void
   onUpdate: (newImageId: string) => Promise<void>
}

const uploadToBrandItem = async (brandHouseId: string, file: Blob, onUploadProgress?: (event: {
   loaded: number,
   total?: number
}) => string) => {
   const formData = new FormData();
   formData.append('file', file, Date.now() + '.png');

   const {data} = await axios({
      method: "POST",
      url: process.env.REACT_APP_BRANDITEMIMAGE + '/' + brandHouseId,
      data: formData,
      onUploadProgress
   });
   return data?.imageId
}


export const BrandItemImageEditor = ({show, imageId, brandHouseId, onUpdate, onClose}: BrandItemImageEditorProps) => {
   const {data} = useImageEditorImageQuery({
      variables: {
         imageId: imageId
      },
      fetchPolicy: 'network-only'
   });
   const client = useApolloClient();

   if (!data?.Image?.url) {
      return null;
   }

   const onSave = async (image: Blob) => {
      await SavingUI.process(async () => {
         const newId = await uploadToBrandItem(brandHouseId, image);
         await client.refetchQueries({
            include: ['BrandItemImage']
         });
         await onUpdate(newId);
      }, 'Done, remember to save', 'Updating image, please wait');
   }

   return <Dialog open={show} onClose={onClose} fullWidth={true} maxWidth={"md"}>
      <DialogContent sx={{padding: 0}}>
         <ImageEditor imageUrl={data.Image.url} onSave={onSave}/>
      </DialogContent>
   </Dialog>
}




