import React, {FC} from "react";
import {Alert, LinearProgress} from "@mui/material";
import useConsumerTradeIns, {ConsumerTradeIn, ConsumerTradeInItem} from "./hooks/useConsumerTradeIns";
import {DataColumn, DataTable} from "../../../../../components/DataTable";
import {DateFormat} from "../../../../../utility/DateFormat";
import {Thumbnails} from "../../../../../components/Thumbnails";
import {TradeInPagePath} from "../../../tradeins/TradeInPage";
import {useNavigate} from "react-router-dom";

type ConsumerTradeInsPanel = {
   consumerId: string
}

export const ConsumerTradeIns: FC<ConsumerTradeInsPanel> = ({consumerId}) => {
   const tradeIns = useConsumerTradeIns(consumerId);
   const navigate = useNavigate();
   if (!tradeIns) {
      return <LinearProgress/>
   }

   const onSelectItem = (item: ConsumerTradeInItem) => {
      navigate(TradeInPagePath(item.shop.brandHouseId, item.shopId, item.tradeInId))
   }

   let itemColumns: DataColumn<ConsumerTradeInItem>[] = [{
      label: "Images",
      get: (row) => {
         return row.imageUrls || []
      },
      render: (images: string[]) => {
         return <Thumbnails imageUrls={images} maxImages={3} size={60}/>
      },
      align: 'center',
      width: '20%'
   }, {
      label: "Item",
      get: (row) => {
         return row.name
      },
      render: (item) => {
         return <div>{item}</div>
      },
      width: '50%'
   }, {
      label: "Type",
      get: (row) => {
         return row.wholeSale ? 'Wholesale' : 'DTC'
      },
      render: (item, row) => {
         if (row.externUrl) {
            return <a href={row.externUrl} rel={"noreferrer"} target={"_blank"} title={'Goto external page'}>{item}</a>
         } else {
            return <div>{item}</div>
         }
      },
      width: '15%'
   }, {
      label: "Logistics status",
      get: (row) => {
         if (row.stockItem) {
            return "Stocked";
         } else if (row.rejected) {
            return "Rejected";
         } else if (row.tradeIn?.registered) {
            return "Registered";
         } else {
            return "None"
         }
      },
      render: (registered, row) => {
         if (row.stockItem) {
            return <Alert severity={"success"}>Stocked</Alert>
         } else if (row.rejected) {
            return <Alert severity={"error"}>Rejected</Alert>
         } else if (row.tradeIn.registered) {
            return <Alert severity={"info"} color={"info"}>Received</Alert>
         } else {
            return <></>
         }
      },
      width: '15%'
   }]

   let columns: DataColumn<ConsumerTradeIn>[] = [{
      label: "Date",
      get: (row) => {
         return row.dateTime
      },
      render: (data) => {
         return DateFormat.toPresent(data)
      },
      width: '15%'
   }, {
      label: "Items",
      get: (row) => {
         return row._id
      },
      render: (data, tradeIn) => {
         return <DataTable idField={"_id"} columns={itemColumns} rows={tradeIn.items}
                           hideHeader={true}
                           hideLines={true}
                           onSingleSelect={onSelectItem}
         />
      },
      width: '85%'
   }]

   return <>
      <DataTable idField={"_id"} order={"desc"} rows={tradeIns} columns={columns}/>
   </>
}