import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";
import {useDtcRedirectRulesQuery} from "../../../../Queries";
import {ParamBrandHouse, ParamShop} from "../../../../Parameters";
import {Box, LinearProgress} from "@mui/material";
import {DTCViewPath} from "../view/DTCViewPage";

export const DTCPagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/dtc";
}

export const DTCPage = () => {
   const {brandHouseId, shopId} = useParams<ParamShop & ParamBrandHouse>()
   const {data} = useDtcRedirectRulesQuery({
      variables: {
         shopId
      }
   });
   const navigate = useNavigate();

   useEffect(() => {
      if (data) {
         const firstActive = data.BrandRules?.find(rule => rule?.active);
         const first = data.BrandRules?.[0];
         navigate(DTCViewPath(brandHouseId, shopId, firstActive?._id || first?._id), {
            replace: true
         })
      }
   }, [brandHouseId, data, navigate, shopId])

   if (!data?.BrandRules) {
      return <LinearProgress/>
   }

   return <Box/>
}
