import * as React from "react"
import {FC, useEffect} from "react"
import {QComponentProps} from "../Model";
import {QEvaluate} from "../QEvaluate";
import {QPubSub} from "../QPubSub";
import {Warning} from "../widgets/Warning";

type QValidationProps = QComponentProps & {
   expression: string
   message: string
}

export const QValidation: FC<QValidationProps> = ({
                                                     expression,
                                                     message,
                                                     data,
                                                  }) => {
   const [failMessage, setFailMessage] = React.useState<string | undefined>();

   useEffect(() => {
      const handler = setTimeout(async () => {
         let hasErrors = !(await QEvaluate(expression, data));
         if (hasErrors) {
            setFailMessage(message);
         } else {
            setFailMessage(undefined);
         }
         QPubSub.emit('validate', {
            expression: expression,
            error: hasErrors
         })
      }, 300)
      return () => clearTimeout(handler);
   }, [data, expression, message])

   if(!failMessage) {
      return null;
   }

   return <>{!!failMessage && <Warning>{failMessage}</Warning>}</>
}

