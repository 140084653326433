export const QPubSub = {
   list: new Map(),
   on(eventType:string, eventAction:(args:any)=>void) {
      this.list.has(eventType) || this.list.set(eventType, []);
      if (this.list.get(eventType)) this.list.get(eventType).push(eventAction);
      return () => {
         let value = this.list.get(eventType).filter((a:any) => a!==eventAction);
         this.list.set(eventType, value);
      };
   },

   emit(eventType:string, ...args:any) {
      this.list.get(eventType) &&
      this.list.get(eventType).forEach((cb:any) => {
         cb(...args);
      });
   }
};
