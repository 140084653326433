import axios from "axios";
import {UploadServiceType} from "./components/quickform/QuickForm";

const upload = async (url: string, containerId: string, file: File, onUploadProgress?: (event: { loaded: number, total?: number }) => void) => {
   const http = axios.create({
      baseURL: url,
      headers: {
         "Content-type": "application/json"
      }
   });

   let formData = new FormData();
   formData.append("file", file);

   return http.post(containerId, formData, {
      headers: {
         "Content-Type": "multipart/form-data",
      },
      onUploadProgress
   }).then(value => value.data);
}

export const createUploadService = (url: string, container: string): UploadServiceType => {
   return {
      upload: async (file: File, onUploadProgress?: (event: { loaded: number, total?: number }) => void) => {
         return await upload(url, container, file, onUploadProgress);
      },
      url: () => {
         return url
      }
   }
}