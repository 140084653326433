import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {WholeSaleCategoryList} from "./components/WholeSaleCategoryList";
import {WholeSaleActivate} from "./components/WholeSaleActivate";
import Button from "@mui/material/Button";
import useWholeSaleCreator from "./hooks/useWholeSaleCreator";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import AddIcon from '@mui/icons-material/Add';

export const WholeSalePagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/wholesale";
}

export const WholeSalePage = () => {
   return <Page>
      <WholeSalePageView/>
   </Page>
}

export const WholeSalePageView = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const wholeSaleCreate = useWholeSaleCreator();
   const navigate = useNavigate();

   const onAddCategory = async () => {
      let newCategoryId = await wholeSaleCreate(shopId);
      navigate('/brands/' + brandHouseId + '/' + shopId + '/wholesale/' + newCategoryId);
   }

   return <>
      <PageHeader title={"Categories"}>
         <WholeSaleActivate/>,
         <Button variant={"outlined"} startIcon={<AddIcon/>} onClick={onAddCategory}>Create category</Button>
      </PageHeader>
      <PageContent fullHeight>
         <WholeSaleCategoryList/>
      </PageContent>
   </>;
}
