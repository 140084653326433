import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import {
   BrandItemMultiFragment,
   BrandItemMultiResolvedFragment,
   BrandItemStateEnum,
   LocalizationEntry,
   Pricing,
   useBrandItemMultiQuery,
   useBrandItemMultiSettingsQuery,
   useBrandItemMultiUpdateMutation
} from "../../Queries";
import {Box, Button, Dialog, DialogContent, DialogTitle, LinearProgress, Paper, Popper} from "@mui/material";
import {useParams} from "react-router";
import {ParamBrandHouse} from "../../Parameters";
import {Thumbnails} from "../../components/Thumbnails";
import {LanguageMenu} from "../settings/LanguageMenu";
import {DataGridPro, GridColDef, GridRenderCellParams, useGridApiContext} from "@mui/x-data-grid-pro";
import {
   BtnBold,
   BtnBulletList,
   BtnClearFormatting,
   BtnItalic,
   BtnNumberedList,
   BtnRedo,
   BtnStyles,
   BtnUnderline,
   BtnUndo,
   ContentEditableEvent,
   Editor,
   EditorProvider,
   HtmlButton,
   Separator,
   Toolbar
} from "react-simple-wysiwyg";
import {SavingUI} from "../../SavingProvider";

type BrandItemMultiEditProps = {
   ids: string[]
   onClose: () => void
}

export const BrandItemMultiEdit = ({ids, onClose}: BrandItemMultiEditProps) => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const [language, setLanguage] = useState<string>('da')
   const [storedData, setStoredData] = useState<BrandItemContainer | undefined>(undefined)
   const updatedData = useRef<BrandItemUpdated>({})
   const disabled = useRef<boolean>(false)
   const [update] = useBrandItemMultiUpdateMutation()

   const {data: items} = useBrandItemMultiQuery({
      variables: {
         brandItemIds: ids
      }
   })
   const {data: settings} = useBrandItemMultiSettingsQuery({
      variables: {
         brandHouseId
      }
   })

   const onDataChange = (data: BrandItemData) => {
      updatedData.current[data._id] = data;
   }

   const onSave = async () => {
      if (disabled.current) {
         return;
      }
      await SavingUI.process(async () => {
         for (const id of Object.keys(updatedData.current)) {
            const row = updatedData.current[id];
            await update({
               variables: {
                  brandHouseId: brandHouseId,
                  input: {
                     _id: row._id,
                     originalSku: row.originalSku,
                     beforePrices: row.beforePrices?.filter(loc => !!loc!.value),
                     originalEan: row.originalEan,
                     nameLocalized: row.name?.filter(loc => !!loc!.value),
                     descriptionLocalized: row.description?.filter(loc => !!loc!.value),
                     colorLocalized: row.color?.filter(loc => !!loc!.value),
                     sizeLocalized: row.size?.filter(loc => !!loc!.value),
                     itemState: row.itemState as BrandItemStateEnum,
                     vendor: row.vendor,
                     typeLocalized: row.type?.filter(loc => !!loc!.value),
                     condition: row.condition,
                     sortorder: row.sortorder,
                     test: row.test
                  }
               }
            })
         }
         onClose()
      }, 'Saving changes', 'Saving all changed stockitems')
   }

   useEffect(() => {
      if (items && settings) {
         const locales = settings.BrandSettings?.locales?.filter(locale => locale!.activeItems).map(locale => locale!.locale);
         const currencies = settings.BrandSettings?.stockItemConfig?.currencies?.filter(currency => currency!.active).map(currency => currency!.currency)

         const container: BrandItemContainer = {
            currencies: currencies || [],
            locales: locales || [],
            data: []
         }

         const rows: BrandItemData[] = []
         for (const brandItem of items.BrandItems || []) {
            const currentBrandItem = brandItem! as BrandItemMultiFragment
            const currentResolved = brandItem?.resolved as BrandItemMultiResolvedFragment

            const getLocalizations = (entries: LocalizationEntry[]) => {
               const result: LocalizationEntry[] = [];
               for (const entry of entries) {
                  result.push({
                     locale: entry.locale,
                     value: entry.value
                  })
               }
               return result;
            }

            const getBrandItemData = (source: BrandItemMultiFragment | BrandItemMultiResolvedFragment) => {
               const prices: Pricing[] = [];
               for (const beforePrice of (source.beforePrices || [])) {
                  prices.push({
                     currency: beforePrice!.currency,
                     value: beforePrice!.value
                  })
               }
               const newItem: BrandItemData = {
                  _id: source._id,
                  itemNr: source.itemNr!,
                  name: getLocalizations(source.nameLocalized as LocalizationEntry[]),
                  color: getLocalizations(source.colorLocalized as LocalizationEntry[]),
                  size: getLocalizations(source.sizeLocalized as LocalizationEntry[]),
                  type: getLocalizations(source.typeLocalized as LocalizationEntry[]),
                  vendor: source.vendor!,
                  originalEan: source.originalEan!,
                  originalSku: source.originalSku!,
                  description: getLocalizations(source.descriptionLocalized as LocalizationEntry[]),
                  beforePrices: prices,
                  imageUrls: source.imageUrls || [],
                  itemState: source.itemState || 'NONE',
                  condition: source.condition,
                  sortorder: source.sortorder || undefined,
                  test: source.test || undefined
               }
               return newItem;
            }
            const newItem = getBrandItemData(currentBrandItem);
            newItem.resolved = getBrandItemData(currentResolved);
            rows.push(newItem);

            rows.sort((a, b) => a.itemNr - b.itemNr)
            container.data = rows;
         }
         setLanguage(locales?.[0] || 'da')
         setStoredData(container)
      }
   }, [items, settings])


   if (!storedData || !language) {
      return <LinearProgress/>
   }

   function onEditStart() {
      disabled.current = true
   }

   function onEditStop() {
      disabled.current = false
   }

   return <Dialog open={ids.length > 0} fullScreen sx={{padding: '20px'}} onClose={onClose} disableEscapeKeyDown>
      <DialogTitle>
         <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            Editing {ids.length} stockitems
            <Box sx={{display: 'flex', gap: 5}}>
               <LanguageMenu allowLocales={storedData.locales} startIcon={false} label={language}
                             onSelect={(language) => setLanguage(language)}/>
               <Button onClick={onSave} variant={"contained"}>Save</Button>
            </Box>
         </Box>
      </DialogTitle>
      <DialogContent>
         <BrandItemMultiEditInner
            data={storedData.data}
            language={language}
            currencies={storedData.currencies}
            onChange={onDataChange}
            onEditStart={onEditStart}
            onEditStop={onEditStop}
         />
      </DialogContent>
   </Dialog>
}

type BrandItemUpdated = {
   [id: string]: BrandItemData
}

type BrandItemContainer = {
   locales: string[]
   currencies: string[]
   data: BrandItemData[]
}

type BrandItemData = {
   _id: string
   itemNr: number
   name: LocalizationEntry[]
   description: LocalizationEntry[]
   color: LocalizationEntry[]
   size: LocalizationEntry[]
   type: LocalizationEntry[]
   beforePrices: Pricing[]
   originalEan?: string
   originalSku?: string
   vendor?: string
   imageUrls: string[]
   itemState: string
   condition: number
   sortorder?: number
   resolved?: Omit<BrandItemData, 'resolved'>
   test?: boolean
}

type BrandItemMultiEditInnerProps = {
   language: string
   currencies: string[]
   data: BrandItemData[]
   onChange: (data: BrandItemData) => void
   onEditStart: () => void
   onEditStop: () => void
}

const BrandItemMultiEditInner = ({language, data, onChange, currencies, onEditStart, onEditStop}: BrandItemMultiEditInnerProps) => {
   const localizationGetter = (field: string) => (params: any) => {
      const entries = params.row[field] as LocalizationEntry[];
      return entries.find(bp => bp!.locale === language)?.value || ''
   }
   const localizationSetter = (field: string) => (params: any) => {
      const {row, value} = params;
      let entries = row[field] as LocalizationEntry[]
      if (!entries) {
         entries = row[field] = []
      }
      const current = entries.find(bp => bp.locale === language);
      if (!current) {
         entries.push({
            locale: language,
            value: value
         })
      } else {
         current.value = value;
      }
      return row
   }
   const localizationRenderer = (html?: boolean) => (params: GridRenderCellParams<BrandItemData, any, any>) => {
      const field = params.field;
      const value = localizationGetter(field)(params);
      if(value) {
         return value;
      }
      const entries = params.row.resolved?.[field] as LocalizationEntry[];
      const result = entries?.find(bp => bp!.locale === language)?.value || ''
      if(html) {
         return <Box dangerouslySetInnerHTML={{__html: result}}/>
      } else {
         return result
      }
   }

   const grades = [{
      value: 1,
      label: 'Fair'
   }, {
      value: 2,
      label: 'Good'
   }, {
      value: 3,
      label: 'Excellent'
   }];

   const columns: GridColDef<BrandItemData>[] = [{
      field: 'itemNr',
      sortable: true,
      headerName: '#',
      width: 100,
      renderCell: ({value, row}) => {
         return <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Thumbnails imageUrls={row.imageUrls} size={50}/>
            {value}
         </Box>
      }
   }, {
      field: 'name',
      headerName: 'Name',
      editable: true,
      width: 250,
      valueGetter: localizationGetter('name'),
      valueSetter: localizationSetter('name'),
      renderCell: localizationRenderer()
   }, {
      field: 'description',
      headerName: 'Description',
      width: 350,
      editable: true,
      renderEditCell: (params) => {
         return <EditTextarea {...params}/>
      },
      renderCell: localizationRenderer(true),
      valueGetter: localizationGetter('description'),
      valueSetter: localizationSetter('description')
   }, {
      field: 'color',
      headerName: 'Color',
      editable: true,
      width: 100,
      valueGetter: localizationGetter('color'),
      valueSetter: localizationSetter('color'),
      renderCell: localizationRenderer()
   }, {
      field: 'size',
      headerName: 'Size',
      editable: true,
      width: 100,
      valueGetter: localizationGetter('size'),
      valueSetter: localizationSetter('size'),
      renderCell: localizationRenderer()
   }, {
      field: 'type',
      headerName: 'Type',
      editable: true,
      width: 100,
      valueGetter: localizationGetter('type'),
      valueSetter: localizationSetter('type'),
      renderCell: localizationRenderer()
   }, {
      field: 'itemState',
      headerName: 'State',
      editable: true,
      type: 'singleSelect',
      valueOptions: [{
         value: 'NONE',
         label: 'None'
      }, {
         value: 'DRAFT',
         label: 'Draft'
      }, {
         value: 'REVIEW',
         label: 'Review'
      }, {
         value: 'READY',
         label: 'Ready'
      }]
   }, {
      field: 'condition',
      headerName: 'Condition',
      editable: true,
      type: 'singleSelect',
      valueOptions: grades,
      renderCell: ({value}) => {
         return grades.find(grade => grade.value === value)?.label
      },
   }, {
      field: 'originalEan',
      headerName: 'Original EAN',
      editable: true
   }, {
      field: 'originalSku',
      headerName: 'Original EAN',
      editable: true
   }, {
      field: 'sortorder',
      headerName: 'Sortorder',
      editable: true,
      type: 'number'
   }, {
      field: 'test',
      headerName: 'Test',
      editable: true,
      type: 'boolean'
   }]

   for (const currency of currencies) {
      columns.push({
         valueGetter: ({row}) => row.beforePrices.find(bp => bp.currency === currency)?.value,
         valueSetter: ({row, value}) => {
            const rrp = row.beforePrices.find(bp => bp.currency === currency);
            if (!rrp) {
               row.beforePrices.push({
                  currency: currency,
                  value: value
               })
            } else {
               rrp.value = value;
            }
            return row
         },
         renderCell: ({value, field, row}) => {
            if(value) {
               return value;
            }
            return row.resolved?.beforePrices.find(bp => bp.currency === currency)?.value
         },
         field: 'beforePrices.' + currency,
         headerName: 'RRP ' + currency,
         type: 'number',
         editable: true,
         width: 100,
         sortable: true,
         resizable: true
      })
   }

   return <div style={{height: '100%'}}>
      <DataGridPro
         getRowId={(row) => row._id}
         columns={columns}
         rows={data}
         rowHeight={70}
         disableMultipleSelection={true}
         hideFooter={true}
         experimentalFeatures={{
            newEditingApi: true
         }}
         processRowUpdate={(cur, old) => {
            onChange(cur)
            return cur
         }}
         onProcessRowUpdateError={(err) => {

         }}
         onCellEditStart={onEditStart}
         onCellEditStop={onEditStop}
      />
   </div>
}


const EditTextarea = (props: GridRenderCellParams) => {
   const {id, field, value, colDef} = props;
   const [valueState, setValueState] = React.useState(value);
   const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>();

   const {current: api} = useGridApiContext()

   const minWidth = React.useMemo(() => {
      if (colDef.computedWidth) {
         return colDef.computedWidth + 400;
      }
      return 0;
   }, [colDef.computedWidth]);

   const setEditCellValue = React.useCallback(
      (event: ContentEditableEvent, newValue: string) => {
         api.setEditCellValue({id, field, value: newValue}, event);
      },
      [api, field, id]
   );

   const debouncedSetEditCellValue = React.useMemo(
      () => debounce(setEditCellValue, 60),
      [setEditCellValue]
   );

   const handleRef = (el: HTMLDivElement) => {
      setAnchorEl(el);
   };

   const handleChange = React.useCallback(
      (event: ContentEditableEvent) => {
         const newValue = event.target.value;
         setValueState(newValue);
         debouncedSetEditCellValue(event, newValue);
      },
      [debouncedSetEditCellValue]
   );

   return (
      <div>
         <div
            ref={handleRef}
            style={{
               height: 1,
               width: minWidth,
               display: "block",
               position: "absolute",
            }}
         />
         {anchorEl && (
            <Popper open anchorEl={anchorEl} placement="bottom-end" sx={{zIndex: 9999}}>
               <Paper elevation={1} sx={{p: 1, width: minWidth}}>
                  <EditorProvider>
                     <Editor tabIndex={0} style={{
                        overflow: 'auto',
                        color: 'inherit'
                     }} value={valueState} onChange={handleChange}>
                        <Toolbar>
                           <BtnUndo/>
                           <BtnRedo/>
                           <Separator/>
                           <BtnBold/>
                           <BtnItalic/>
                           <BtnUnderline/>
                           <Separator/>
                           <BtnNumberedList/>
                           <BtnBulletList/>
                           <Separator/>
                           <BtnClearFormatting/>
                           <HtmlButton/>
                           <Separator/>
                           <BtnStyles/>
                        </Toolbar>
                     </Editor>
                  </EditorProvider>
               </Paper>
            </Popper>
         )}
      </div>
   );
};
