import {DateFormat} from "../../utility/DateFormat";
import {DateUtility} from "../../utility/DateUtility";
import dayjs from "dayjs";

export type Split = { id: string, filter: string };

export const getDayFilter = (index: number): Split => {
   let date = new Date();
   let theDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getDate() + index);
   let toDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getDate() + index + 1);

   let thisMonth = DateFormat.toDay(theDate);
   return {
      id: thisMonth,
      filter: "dateMs >= " + theDate.getTime() + " && dateMs < " + toDate.getTime()
   }
}

export const getSplits = (fromDate: Date, toDate: Date): Split[] => {
   const days = DateUtility.dateDiffDays(toDate, fromDate);
   if (days < 365) {
      return getDays(fromDate, toDate);
   } else {
      return []
   }
}

export const getDays = (fromDate: Date, toDate: Date): Split[] => {
   const days = DateUtility.dateDiffDays(toDate, fromDate) + 1;

   const result: Split[] = [];
   for (let i = 1; i <= days; i++) {
      let fDate = dayjs(fromDate).add(i - 1, 'days')
      fDate = fDate.set('hour', 0).set('minute', 0).set('second', 0);
      let tDate = dayjs(fromDate).add(i, 'days');
      tDate = tDate.set('hour', 0).set('minute', 0).set('second', 0);

      let thisMonth = DateFormat.toDay(fDate.toDate());
      result.push({
         id: thisMonth,
         filter: "dateMs >= " + fDate.toDate().getTime() + " && dateMs < " + tDate.toDate().getTime()
      })
   }
   return result;
}


export const getMonths = (fromDate: Date, toDate: Date): Split[] => {
   const months = DateUtility.dateDiffWeeks(toDate, fromDate);

   const result: Split[] = [];
   for (let i = 1; i <= months; i++) {
      const fDate = dayjs(fromDate).add(i - 1, 'week');

      const tDate = dayjs(fromDate).add(i, 'week');

      result.push({
         id: "Week " + fDate.week(),
         filter: "dateMs >= " + fDate.toDate().getTime() + " && dateMs < " + tDate.toDate().getTime()
      })
   }
   return result;
}

export const toStartISODate = (date: Date) => {
   return dayjs(date)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0).format();
}

export const toEndISODate = (date: Date) => {
   return dayjs(date)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .set('millisecond', 999).format();
}


export const getFilter = (splits: Split[]) => {
   const filter = splits.map(split => split.filter).join('||');
   if(filter) {
      return filter;
   } else {
      return 'false'
   }
}