import {BodyText} from "../../../../layout/Typography";

type AgeProps = {
   minimumAge: number | undefined | null
   maximumAge: number | undefined | null
}
export const AgeView = (props: AgeProps) => {
   let hasMinAge = props.minimumAge !== 0 && props.minimumAge;
   let hasMaxAge = props.maximumAge !== 0 && props.maximumAge;
   return <>
      {(!hasMinAge && !hasMaxAge) && <BodyText type={"body2"}>Any item age</BodyText>}
      {hasMinAge && <>
         <BodyText type={"body2"}>Activates after {props.minimumAge} days</BodyText>
      </>}
      {hasMaxAge && <>
         <BodyText type={"body2"}>Ends after {props.maximumAge} days</BodyText>
      </>}
   </>
}
