import * as React from "react";
import {OutlinedInputProps, TextField, TextFieldProps, Theme} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {makeStyles} from "@mui/styles";

type SearchFieldProps = TextFieldProps & {
   count?: number
}

export function SearchField(props: SearchFieldProps) {
   const classes = useStylesReddit();

   let endAdornment = null;
   if (props.count) {
      endAdornment = <div style={{backgroundColor: '#e0e0e0', padding: '10px'}}>{props.count}</div>
   }

   return (
      <TextField
         InputProps={{
            classes,
            endAdornment: endAdornment
         } as Partial<OutlinedInputProps>}
         {...props}
      />
   );
}

const useStylesReddit = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         overflow: 'hidden',
         borderRadius: 4,
         backgroundColor: '#fefefe',
         transition: theme.transitions.create(['border-color', 'box-shadow']),
         '&:hover': {
            backgroundColor: '#fff',
         },
         padding: 0,
         height: 42
      },
   }),
);