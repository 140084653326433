import * as React from "react";
import {FC} from "react";
import {Link, TitleText} from "../../../layout/Typography";
import {LinearProgress, Step, StepLabel, Stepper} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import {useVoucherActionsBrandQuery, useVoucherActionsDetailsQuery, useVoucherActionsQuery} from "../../../Queries";
import {useNavigate} from "react-router-dom";
import {ConsumerPagePath} from "../../shop/masterdata/consumers/ConsumerPage";
import {TradeInPagePath} from "../../shop/tradeins/TradeInPage";

type VoucherActionProps = {
   voucherId: string
   shopId: string
   brandHouseId: string
}

export const VoucherActions: FC<VoucherActionProps> = ({voucherId, shopId, brandHouseId}) => {
   const navigate = useNavigate();

   const {data} = useVoucherActionsQuery({
      variables: {
         id: voucherId
      }
   })
   const {data: brand} = useVoucherActionsBrandQuery({
      variables: {
         shopId: shopId
      }
   })

   const {data: voucher} = useVoucherActionsDetailsQuery({
      variables: {
         voucherId: voucherId
      }
   })

   if (!data || !brand || !voucher) {
      return <LinearProgress/>
   }
   let actions = (data.VoucherActions2 || []).slice();
   const currency = brand?.ShopById?.currency;

   actions.sort((a, b) => {
      return new Date(b?.date || Date.now()).getTime() - new Date(a?.date || Date.now()).getTime()
   })

   return <>
      <div style={{marginTop: '20px'}}>
         <TitleText type={"h2"}>Timeline</TitleText>
      </div>
      <Stepper orientation={"vertical"}>
         {!!voucher.VoucherById?.deleted && <Step>
            <StepLabel error icon={"D"}>
               <span
                  title={DateFormat.toReadable(voucher?.VoucherById.deleted)}>{DateFormat.timeSince(voucher?.VoucherById.deleted)}</span>:
               Voucher deleted
            </StepLabel>
         </Step>
         }
         {actions.map((action, i) => {
            const usedAmount = (action?.voucherBefore || 0) - (action?.voucherAfter || 0);
            if (action?.kind === 'USE') {
               return <Step active={i === 0} key={'step_' + action?._id}>
                  <StepLabel icon={"U"}>
                     <span title={DateFormat.toReadable(action?.date)}>{DateFormat.timeSince(action?.date)}</span><br/>
                     Consumer used {Math.round(usedAmount)} {currency} on <a target={"_blank"} rel="noreferrer"
                                                                             href={action?.externUrl || '#'}>order</a>,
                     netto payment was {Math.round(action?.pricePaid || 0)} {currency}.<br/>
                     Voucher balance {Math.round(action?.voucherAfter || 0)} {currency}
                  </StepLabel>
               </Step>
            } else if (action?.kind === 'REFUND') {
               return <Step active={i === 0} key={'step_' + action?._id}>
                  <StepLabel color={"black"} icon={"R"}>
                     <span title={DateFormat.toReadable(action?.date)}>{DateFormat.timeSince(action?.date)}</span><br/>
                     Consumer was refunded {Math.round(action?.refundValue)} {currency} from <a target={"_blank"}
                                                                                                rel="noreferrer"
                                                                                                href={action?.externUrl || '#'}>order</a>.<br/>
                     Voucher balance {Math.round(action?.voucherAfter || 0)} {currency}
                  </StepLabel>
               </Step>
            } else if (action?.kind === 'CANCEL') {
               return <Step active={i === 0} key={'step_' + action?._id}>
                  <StepLabel icon={"C"}>
                     <span title={DateFormat.toReadable(action?.date)}>{DateFormat.timeSince(action?.date)}</span><br/>
                     Consumer cancelled the <a target={"_blank"} rel="noreferrer"
                                               href={action?.externUrl || '#'}>order</a>
                     <br/>
                     Voucher balance {Math.round(action?.voucherAfter || 0)} {currency}
                  </StepLabel>
               </Step>
            } else {
               return <Step active={i === 0} key={'step_' + action?._id}>
                  <StepLabel icon={"?"}>
                     {DateFormat.toPresent(action?.date)}<br/> Unknown event
                  </StepLabel>
               </Step>
            }
         })}
         <Step>
            <StepLabel icon={"S"}>
               <span
                  title={DateFormat.toReadable(voucher?.VoucherById?.createdAt)}>{DateFormat.timeSince(voucher?.VoucherById?.createdAt)}</span><br/>
               {Math.round(voucher?.VoucherById?.value || 0)} {currency} credit issued to <Link
               onClick={() => navigate(ConsumerPagePath(brandHouseId, shopId, voucher.VoucherById?.consumerId))}>consumer</Link> for <Link
               onClick={() => navigate(TradeInPagePath(brandHouseId, shopId, voucher.VoucherById?.tradeInId))}>tradein</Link>.<br/>
            </StepLabel>
         </Step>
      </Stepper>
   </>
}