import * as React from "react";
import {FC} from "react";
import {
   useVoucherApproveMutation,
   useVoucherEnableDisableMutation,
   useVoucherMigrateMutation,
   useVoucherPanelActivateMutation,
   useVoucherPanelVoucherQuery,
   VouchersPageVouchersDocument
} from "../../../Queries";
import {BodyText, Link, TitleText} from "../../../layout/Typography";
import {Checkbox, FormControlLabel, Grid, LinearProgress} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";
import {Dialogs} from "../../../DialogProvider";
import {SavingUI} from "../../../SavingProvider";

type VoucherPanelProps = {
   voucherId: string
}

export const VoucherPanel: FC<VoucherPanelProps> = ({voucherId}) => {
   const {data} = useVoucherPanelVoucherQuery({
      variables: {
         id: voucherId
      }
   })
   const [voucherActivate] = useVoucherPanelActivateMutation()
   const [voucherApprove] = useVoucherApproveMutation();
   const [voucherMigrate] = useVoucherMigrateMutation();
   const [voucherEnableDisable] = useVoucherEnableDisableMutation();
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const onActivateToggle = (shopId: string, code: string, activate: boolean) => async () => {
      await voucherActivate({
         variables: {
            shopId: shopId,
            code: code,
            activate: activate
         },
         refetchQueries: [{
            query: VouchersPageVouchersDocument,
            variables: {
               shopId: shopId
            }
         }]
      })
   }

   const onMigrate = async () => {
      if (voucher.newVoucherUsage) {
         return;
      }
      Dialogs.confirm({
         title: 'Convert this voucher to new voucher system?',
         subtitle: 'This action cannot be undone'
      }, async () => {
         await SavingUI.process(async () => {
            await voucherMigrate({
               variables: {
                  voucherId: voucherId
               }
            })
         }, 'Conversion started')
      })
   }


   const onEnableDisable = async () => {
      await SavingUI.process(async () => {
         await voucherEnableDisable({
            variables: {
               voucherId: voucherId,
               enable: !!voucher.disabled
            }
         })
      }, voucher.disabled ? 'Enabled' : 'Disabled', 'Please wait')
   }

   const onApprove = async () => {
      await voucherApprove({
         variables: {
            voucherId: voucherId
         },
         refetchQueries: [{
            query: VouchersPageVouchersDocument,
            variables: {
               shopId: data?.VoucherById?.shopId
            }
         }]
      })
   }

   if (!data) {
      return <LinearProgress/>
   }
   const voucher = data.VoucherById!;

   return <Grid container rowGap={2}>
      <Grid item xs={6}>
         <TitleText type={"h1"}>{voucher.codeAlias || voucher.code}</TitleText>
         {(voucher.codeAlias && voucher.code !== voucher.codeAlias) && <TitleText type={"subtitle1"}>Alias for {voucher.code}</TitleText>}
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>Value</TitleText>
         <BodyText type={"body1"}>
            Original: {voucher.value} {voucher.currency}
         </BodyText>
         <BodyText type={"body1"}>
            Remaining: {Math.round(voucher.currentValue || 0)} {voucher.currency}
         </BodyText>
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Activated</TitleText>
         <Link onClick={onActivateToggle(voucher.shopId!, voucher.code!, !voucher.activated)}>
            <BodyText style={{display: 'inline'}} type={"body1"}>{voucher.activated ? "Yes" : "No"}</BodyText>
         </Link>
         &nbsp;({voucher.voucherStrategy})
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Expiration
         </TitleText>
         <BodyText type={"body1"}>{voucher.expireDate ? DateFormat.toPresent(voucher.expireDate) : 'Never'}</BodyText>
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Minimum purchase
         </TitleText>
         <BodyText
            type={"body1"}>{voucher.minimumPurchase ? (voucher.minimumPurchase + ' ' + voucher.currency) : '-'}
         </BodyText>
      </Grid>
      <Grid item xs={6}>
         <FormControlLabel
            disabled={!voucher.hasUnapprovedActions}
            control={<Checkbox checked={!voucher.hasUnapprovedActions} value={true} onChange={onApprove}/>}
            label={'Voucher approved'}
         />
         <br/>
         {isAdministrator &&
            <FormControlLabel
               disabled={!!voucher.newVoucherUsage}
               control={<Checkbox checked={!!voucher.newVoucherUsage} value={true} onChange={onMigrate}/>}
               label={'Using new vouchers'}
            />
         }<br/>

         <FormControlLabel
            control={<Checkbox checked={!voucher.disabled} value={true} onChange={onEnableDisable}/>}
            label={'Voucher enabled'}
         />

      </Grid>
   </Grid>
}