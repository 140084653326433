import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import * as React from "react";
import {StatKPITable} from "../analytics/StatKPITable";

export const LogisticsKPIPage = () => {
   return <Page>
      <PageHeader title={"KPIS"}/>
      <PageContent fullHeight style={{width: 'auto'}}>
         <StatKPITable/>
      </PageContent>
   </Page>
}