import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Tab, Tabs} from "@mui/material";
import {buildGraphQLFilter, FlexFilter, FlexListRequestData, FlexTab} from "./FlexTypes";
import {useRecoilState, useRecoilValue} from "recoil";
import {flexSettingsAtom} from "./FlexList";
import {isEqual} from "lodash";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import makeStyles from "@mui/styles/makeStyles";
import {PubSub} from "../PubSub";

type FlexTabsProps<T> = {
   globalId: string
   tabs?: FlexTab[]
   filters?: FlexFilter<T>[]
   onRequestData?: (search: string | undefined, sort: string | undefined, page: number, filter?: any) => Promise<FlexListRequestData<T>>
   onTabChange?: (tab: string) => void
}

export const FlexTabs = <T extends object>({globalId, tabs, onRequestData, filters, onTabChange}: FlexTabsProps<T>) => {
   const [listState, setListState] = useRecoilState(flexSettingsAtom(globalId));

   const methods = useForm<any>({
      defaultValues: {
         tab: ''
      }
   });
   const {reset, control, watch} = methods;

   const currentTab = watch('tab');

   useEffect(() => {
      let foundTab = tabs?.find(tab => {
         return isEqual(tab.filters, listState.filters);
      });
      reset({
         tab: foundTab?.id || ''
      });
      // eslint-disable-next-line
   }, [listState])

   return <FormProvider {...methods}>
      <Controller
         control={control}
         name={"tab"}
         defaultValue={""}
         render={({field: {onChange, value}}) => {
            return <Tabs value={value}
                         style={{marginBottom: '5px'}}
                         onChange={(e, tab) => {
                            onChange({target: {value: tab}});
                            onTabChange && onTabChange(tab);
                         }}>
               {tabs?.map(tab => {
                  return <Tab key={'flex_tab_' + tab.id} onClick={() => {
                     setListState({
                        ...listState,
                        filters: tab.filters,
                        columnsVisible: tab.columns
                     })
                  }} label={<FlexTabLabel filters={filters} globalId={globalId} onRequestData={onRequestData}
                                          tab={tab}/>} value={tab.id}/>
               })}
               {!currentTab &&
               <Tab onClick={() => {
                  // Ignore
               }} label={<StarBorderIcon/>} value={''}/>
               }
            </Tabs>
         }}/>
   </FormProvider>
}

type FlexTabViewProps = {
   globalId: string
   tab: FlexTab
   filters?: FlexFilter<any>[]
   onRequestData?: (search: string | undefined, sort: string | undefined, page: number, filter?: any) => Promise<FlexListRequestData<any>>
}

const FlexTabLabel: FC<FlexTabViewProps> = ({globalId, tab, onRequestData, filters}) => {
   const listState = useRecoilValue(flexSettingsAtom(globalId));
   const [count, setCount] = useState<number>(0);
   const classes = useStyles();

   useEffect(() => {
      let handle = PubSub.on('FlexList-SearchChanged-' + globalId, (listState) => {
         if (listState && tab.showCount && onRequestData) {
            (async function () {
               const countData = await onRequestData(
                  listState.search,
                  listState.sort,
                  1,
                  buildGraphQLFilter(filters, tab.filters)
               );
               setCount(countData?.totalCount || 0);
            })();
         }
      });
      PubSub.emit('FlexList-SearchChanged-' + globalId, listState);
      return handle;
      // eslint-disable-next-line
   }, []);

   return <div>
      {tab.label}
      {tab.showCount && <>
         {count > 0 && <span className={classes.badge}>{count}</span>}
      </>}
   </div>
}

const useStyles = makeStyles({
   badge: {
      borderRadius: '50%',
      backgroundColor: 'rgb(255, 83, 118)',
      display: 'inline-block',
      color: 'white',
      paddingLeft: '5px',
      paddingRight: '5px',
      marginLeft: '5px'
   },
   badgePlaceholder: {
      display: 'inline-block',
      paddingLeft: '5px',
      paddingRight: '5px',
      marginLeft: '5px'
   }
});
