import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {TradeInExportFragment, TradeInExportItemFragment, useExportTradeInsQuery} from "../../Queries";

type ExportLine = {
   itemNr: number
   item: TradeInExportItemFragment
   tradeIn: TradeInExportFragment
}

export const ExportToExcel = async (language: string, currency: string, data: ExportLine[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ID",
      type: String,
      value: (object: ExportLine) => object.tradeIn._id
   }, {
      column: "TRADEINNR",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.tradeInNr
   }, {
      column: "TRADEINDATE",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: ExportLine) => new Date(object.tradeIn.dateTime)
   }, {
      column: "PACKAGESTATUS",
      type: String,
      value: (object: ExportLine) => object.tradeIn.label?.deliveryStatus || 'Unknown'
   }, {
      column: "PACKAGEQR",
      type: String,
      value: (object: ExportLine) => object.tradeIn.label?.qrUrl || ''
   }, {
      column: "ITEMCOUNT",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.items?.length || 0
   }, {
      column: "REGISTERED",
      type: Boolean,
      value: (object: ExportLine) => object.tradeIn.registered || false
   }, {
      column: "CONSUMER",
      type: String,
      value: (object: ExportLine) => object.tradeIn.consumer?.name || 'Unknown'
   }, {
      column: "CONSUMERMAIL",
      type: String,
      value: (object: ExportLine) => object.tradeIn.consumer?.mail || 'Unknown@mail'
   }, {
      column: "CONSUMERPHONE",
      type: String,
      value: (object: ExportLine) => object.tradeIn.consumer?.phoneE164 || object.tradeIn.consumer?.phone || ''
   }, {
      column: "SHOP",
      type: String,
      value: (object: ExportLine) => object.tradeIn.shopId || 'Unknown shop'
   }, {
      column: "SHIPPING",
      type: String,
      value: (object: ExportLine) => object.tradeIn.shippingCarrier?.name || 'Unknown carrier'
   }, {
      column: "VOUCHERVALUE",
      type: Number,
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.value
   }, {
      column: "VOUCHERCURRENCY",
      type: String,
      value: (object: ExportLine) => object.tradeIn.vouchers?.[0]?.currency || '???'
   }, {
      column: "ITEMID",
      type: String,
      value: (object: ExportLine) => object.item._id
   }, {
      column: "ITEMIMAGE",
      type: String,
      value: (object: ExportLine) => object.item.imageUrls?.[0]
   }, {
      column: "ITEMTYPE",
      type: String,
      value: (object: ExportLine) => object.item.wholeSale ? 'Wholesale' : 'Retail'
   }, {
      column: "ITEMSKU",
      type: String,
      value: (object: ExportLine) => object.item?.brandItem?.resolved?.originalSku || object.item.stockItem?.resolved?.sku
   }, {
      column: "ITEMNAME",
      type: String,
      value: (object: ExportLine) => object.item?.brandItem?.resolved?.nameLocalized?.[0]?.value || object.item.stockItem?.resolved?.title || object.item.wholeSale?.name
   }, {
      column: "ITEMVALUE",
      type: Number,
      value: (object: ExportLine) => object.item.wholeSale?.value || object.item.value || 0
   }, {
      column: "ITEMSIZE",
      type: String,
      value: (object: ExportLine) => object.item?.brandItem?.resolved?.sizeLocalized?.[0]?.value || object.item.stockItem?.resolved?.size
   }, {
      column: "ITEMCONDITION",
      type: Number,
      value: (object: ExportLine) => object.item.condition
   }, {
      column: "ITEMSOLD",
      type: Boolean,
      value: (object: ExportLine) => object.item.stockItem?.isSold || object.item.brandItem?.isSold || false
   }, {
      column: "ITEMSELLPRICE",
      type: Number,
      value: (object: ExportLine) => object.item.brandItem?.resolved?.orderline?.discountedPrice || object.item.stockItem?.orderline?.discountedPrice
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'items-' + Date.now() + '.xlsx'
   })
}


type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const TradeInExport = ({language, currency, ids}: BrandItemExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportTradeInsQuery({
      variables: {
         tradeIns: ids
      }
   });

   useEffect(() => {
      if (data?.TradeIns) {
         setOpen(true);
         (async function () {
            const exportLines: ExportLine[] = []
            const tradeIns = ((data.TradeIns || []) as TradeInExportFragment[])
            for (const tradeIn of tradeIns) {
               const items = ((tradeIn.items || []) as TradeInExportItemFragment[])
               let itemNr: number = 1
               for (const item of items) {
                  exportLines.push({
                     item,
                     tradeIn,
                     itemNr: itemNr++
                  })
               }
            }
            await ExportToExcel(language, currency, exportLines);
            setOpen(false);
         })();
      }
   }, [currency, data, ids, language])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}
