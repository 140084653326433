import * as React from "react";
import {CSSProperties} from "react";
import {Box, Paper} from "@mui/material";
import PageCommands from "./PageCommands";

export type PageContentProps = {
   fullHeight?: boolean
   style?: CSSProperties | undefined
   children?: React.ReactNode | React.ReactNode[];
   commands?: React.ReactNode | React.ReactNode[];
   noPadding?: boolean
   noBorder?: boolean
}

const PageContent = ({fullHeight, style, children, commands, noPadding, noBorder}: PageContentProps) => {
   let minHeight = 'initial';
   if (fullHeight) {
      minHeight = 'calc(100vh - 140px)'
   }
   return <Paper variant={"outlined"} sx={{
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      minHeight: minHeight,
      marginBottom: '10px !important',
      border: noBorder ? 'none' : undefined,
   }} >
      {commands && <PageCommands>{commands}</PageCommands>}
      <Box sx={{
         marginTop: '-2px',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'flex-start',
         padding: noPadding ? '0' : '15px 25px 15px 25px',
         ...style
      }}>
         {children}
      </Box>
   </Paper>
}

export default PageContent