import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {atom, useRecoilState} from "recoil";
import {Alert, Checkbox, DialogContent, FormControlLabel, Snackbar} from "@mui/material";
import {PubSub} from "./components/PubSub";
import Typography from "@mui/material/Typography";

type DialogInfo = {
   title: string
   subtitle: string
   defaultNo?: boolean
   acceptTitle?: string
   open: boolean
   onConfirm?: (accept: boolean) => void
   type: 'info' | 'confirm' | 'saved'
   buttonYes: string
   buttonNo: string
}

let defaultValues: DialogInfo = {
   title: '',
   subtitle: '',
   open: false,
   type: 'confirm',
   buttonYes: 'Yes',
   buttonNo: 'No',
   onConfirm: () => {
   }
};
const DialogState = atom<DialogInfo>({
   key: 'DialogProvider',
   default: defaultValues
})

type DialogProviderProps = {
   children: React.ReactNode | React.ReactNode[];
}

export type ConfirmOptions = {
   title: string,
   subtitle?: string
   defaultNo?: boolean
   buttonYes?: string
   buttonNo?: string
   acceptTitle?: string
}

const confirm = (options: ConfirmOptions, onConfirm: (accept: boolean) => void) => {
   PubSub.emit('confirm', {
      ...defaultValues,
      ...options,
      onConfirm: onConfirm
   });
}
const info = (options: ConfirmOptions, onOK?: () => void) => {
   PubSub.emit('info', {
      ...defaultValues,
      ...options,
      onConfirm: onOK
   });
}
const saved = (options: ConfirmOptions) => {
   PubSub.emit('saved', {
      title: options.title
   });
}

export const Dialogs = {
   confirm,
   info,
   saved
}

export const DialogProvider = (props: DialogProviderProps) => {
   const [info, setInfo] = useRecoilState<DialogInfo>(DialogState);

   const onClose = () => {
      setInfo({
         ...info,
         open: false
      })
   }

   useEffect(() => {
      PubSub.on('confirm', (args: any) => {
         setInfo({
            ...args,
            open: true,
            type: 'confirm'
         })
      })

      PubSub.on('info', (args: any) => {
         setInfo({
            ...args,
            open: true,
            type: 'info'
         })
      })

      PubSub.on('saved', (args?: any) => {
         setInfo({
            ...info,
            open: true,
            type: 'saved'
         })
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   if (info?.type === 'saved') {
      return <>
         <Snackbar open={info?.open} autoHideDuration={3000} onClose={onClose} anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
         }}>
            <Alert onClose={onClose} variant={"filled"} severity={'info'} sx={{width: '100%'}}>
               {info?.title}
            </Alert>
         </Snackbar>
         {props.children}
      </>
   } else {
      return <>
         {info.open && <MyDialog title={info.title}
                   subtitle={info.subtitle}
                   open={info.open}
                   defaultNo={info.defaultNo}
                   onConfirm={info.onConfirm || ((accept: boolean) => {
                   })}
                   onClose={onClose}
                   type={info.type}
                   buttonYes={info.buttonYes}
                   buttonNo={info.buttonNo}
                   acceptTitle={info.acceptTitle}
         />}
         {props.children}
      </>
   }
}

type DialogProps = {
   title: string
   subtitle: string
   acceptTitle?: string
   open: boolean;
   defaultNo?: boolean
   type: 'info' | 'confirm';
   onConfirm: (accept: boolean) => void;
   onClose: () => void
   buttonYes: string
   buttonNo: string
}

const MyDialog = (props: DialogProps) => {
   const [accept, setAccept] = useState<boolean>(true);
   return (
      <Dialog
         open={props.open}
         onClose={props.onClose}
         aria-labelledby="confirm-dialog"
      >
         <DialogTitle id="confirm-dialog">{props.title}</DialogTitle>
         <DialogContent>
            {props.subtitle.split('\n').map(line =>
               <Typography key={line} variant={"body1"} style={{marginBottom: '10px'}}>{line}</Typography>)
            }
         </DialogContent>
         <DialogActions sx={{justifyContent: 'space-between', padding: '25px'}}>
            <div>
               {props.acceptTitle &&
               <FormControlLabel
                  control={<Checkbox checked={accept} value={true} onChange={(e, val) => {
                     setAccept(val);
                  }}/>}
                  label={props.acceptTitle}
               />}
            </div>
            <div>
               <Button
                  variant={props.defaultNo ? "outlined" : "contained"}
                  onClick={() => {
                     props.onClose();
                     props.onConfirm && props.onConfirm(accept);
                  }}
                  color="primary"
               >
                  {props.type === 'info' && <>Ok</>}
                  {props.type !== 'info' && <>{props.buttonYes}</>}
               </Button>
               {props.type === 'confirm' && <Button
                  variant={props.defaultNo ? "contained" : "outlined"}
                  onClick={props.onClose}
                  color="primary"
               >
                  {props.buttonNo}
               </Button>}
            </div>
         </DialogActions>
      </Dialog>
   );
};
