import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import PageHeader from "../../../layout/PageHeader";
import * as React from "react";
import {ListTradeInsList} from "../../lists/ListTradeInsList";

export const LogisticsTradeInsListPage = () => {
   return <Page>
      <PageHeader title="TradeIns"/>
      <PageContent>
         <ListTradeInsList globalId={"logisticsTradeIns"} extrafilter={{
            _operators: {
               brandHouseId: {
                  ne: '601c19a2c6dac20032263343'
               }
            }
         }}/>
      </PageContent>
   </Page>
}

