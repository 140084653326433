import * as React from "react";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {OrdersList} from "../shop/components/OrdersList";
import {OrderFragment} from "../../Queries";
import {OrderPagePath} from "../shop/orders/OrderPage";
import {useParams} from "react-router";
import {ParamBrandHouse} from "../../Parameters";

export const BrandOrdersPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId + "/orders"
}

export const BrandOrdersPage: FC<any> = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const navigate = useNavigate();

   const onSelectRow = (row: OrderFragment) => {
      navigate(OrderPagePath(row.shop?.brandHouseId, row.shopId, row._id));
   }

   return <Page>
      <PageHeader title={"Orders"}/>
      <PageContent fullHeight>
         <OrdersList
            listId={"brandOrders"}
            brandHouseId={brandHouseId}
            onSelectOrder={onSelectRow}/>
      </PageContent>
   </Page>
}