import * as React from "react";
import {SyntheticEvent} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, Autocomplete, TextField, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {merge} from "lodash";

export type FormInputProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   control?: any;
   label: string;
   options: string[]
   errorMessage?: string
   onValueChange?: (e: SyntheticEvent<any>) => void
}

export const FormInputFree = ({name, options, label, onValueChange, placeholder, ...rest}: FormInputProps) => {
   const {style} = rest;
   const methods = useFormContext();
   const classes = useStyles();

   const {control, formState: {errors}} = methods;
   const showError = !!errors[name];

   return <div>
      <Controller
         name={name}
         control={control}
         defaultValue={null}
         render={({
                     field: {ref, ...field},
                     fieldState: {error, invalid}
                  }) => {
            return (
               <Autocomplete
                  {...field}
                  freeSolo
                  handleHomeEndKeys
                  options={options}
                  placeholder={placeholder}
                  renderInput={(params) => {
                     const {InputProps, ...rest2} = params;

                     const newProps = merge(InputProps, {
                        disableUnderline: true,
                        style: {backgroundColor: 'transparent'}
                     });

                     return <div className={classes.noBorderRadius}>
                        <TextField
                           InputProps={{
                              ...newProps
                           }}
                           {...rest2}
                           size={"small"}
                           label={label}
                           variant="filled"
                           margin={"none"}
                           fullWidth
                           placeholder={placeholder}
                           style={merge(style, {
                              border: '1px solid #d0d0d0',
                              marginBottom: '8px'
                           })}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           {...rest}
                        />
                        {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
                     </div>
                  }}
                  size={"small"}
                  onChange={(e, value) => {
                     field.onChange(value)
                     onValueChange && onValueChange(e)
                  }}
                  onInputChange={(_, data) => {
                     if (data) field.onChange(data);
                  }}
               />
            );
         }}
      />
      {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
   </div>
}


const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});