import {Box, Button, Card, CardActionArea, CardContent, CircularProgress, Grid, Typography} from "@mui/material";
import React from "react";
import {useGetOptionSetsQuery} from "../../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../../../Parameters";
import SettingsHeader from "../../SettingsHeader";
import {OptionSetCreatePath, OptionSetEditPath} from "./OptionSetEditorPage";

export const OptionSetSettingsPagePath = (brandId: string) => {
   return `/brands/${brandId}/settings/optionset`;
}

export const OptionSetSettingsPage: React.FC = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data, loading} = useGetOptionSetsQuery({
      variables: {
         refId: brandHouseId,
      },
      fetchPolicy: "network-only",
   });
   const navigate = useNavigate();

   if (loading) {
      return (
         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress/>
         </Box>
      );
   }

   const handleEditOption = (optionId: string) => {
      navigate(OptionSetEditPath(brandHouseId, optionId));
   };

   return (
      <Box>
         <SettingsHeader title="Option Sets" subtitle="Customizable data">
            <Button
               type="button"
               variant="contained"
               onClick={() => {
                  navigate(OptionSetCreatePath(brandHouseId));
               }}
            >
               Create new
            </Button>
         </SettingsHeader>

         <Grid container spacing={2}>
            {data?.Options?.map((option) => (
               <Grid item xs={12} sm={6} md={4} key={option.key}>
                  <Card>
                     <CardActionArea onClick={() => handleEditOption(option._id)}>
                        <CardContent>
                           <Typography variant="h5" component="div">
                              {option.title}
                           </Typography>
                           <Typography color={option.active ? "primary" : "error"}>
                              {option.active ? "Active" : "Inactive"}
                           </Typography>
                           <Typography color="textSecondary">Target: {option.target}</Typography>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
            ))}
         </Grid>
      </Box>
   );
};
