import * as React from "react";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {OrdersList} from "../shop/components/OrdersList";
import {OrderFragment} from "../../Queries";
import {OrderPagePath} from "../shop/orders/OrderPage";

export const LogisticsOrdersPagePath = (logisticsPartnerId: string) => {
   return "/logisticspartners/" + logisticsPartnerId + "/orders"
}

export const LogisticsOrdersPage: FC<any> = () => {
   const navigate = useNavigate();

   const onSelectRow = (row: OrderFragment) => {
      navigate(OrderPagePath(row.shop?.brandHouseId, row.shopId, row._id));
   }

   return <Page>
      <PageHeader title={"Orders"}/>
      <PageContent fullHeight>
         <OrdersList
            listId={"logisticsOrders"}
            onSelectOrder={onSelectRow}/>
      </PageContent>
   </Page>
}