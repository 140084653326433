import {gql, useMutation} from "@apollo/client";

const qlTradeInUpdate = gql`
  mutation TradeInUpdate($id:MongoID!, $input: TradeInUpdateInput) {
    TradeInUpdate(tradeInId: $id, input: $input) {
      _id
    }
  }
`

export type TradeInUpdateResult = {
   _id: string
}

export type TradeInUpdate = {
   registered: boolean
}

const useTradeInUpdate = () => {
   const [tradeInUpdate] = useMutation(qlTradeInUpdate);

   return async (id: string, shopId: string, item: TradeInUpdate): Promise<TradeInUpdateResult|undefined> => {
      let result = await tradeInUpdate({
         variables: {
            id: id,
            shopId: shopId,
            input: item
         },
         update(cache, {data}) {
            cache.evict(data.TradeInUpdate._id);
         }
      });
      return result?.data?.TradeInUpdate as TradeInUpdateResult
   }
}

export default useTradeInUpdate;