import * as React from "react";
import {Button, LinearProgress} from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopIcon from "@mui/icons-material/Stop";
import {useParams} from "react-router-dom";
import {Dialogs} from "../../../../DialogProvider";
import {useWholeSaleActivateMutation, useWholeSaleActiveQuery} from "../../../../Queries";

export const WholeSaleActivate = (props: {}) => {
    const {shopId} = useParams<{shopId: string}>();
    const {data} = useWholeSaleActiveQuery({variables:{shopId}})
    const [toggle] = useWholeSaleActivateMutation();

    const onActivateWholeSale = async (activate: boolean) => {
        await toggle({
            variables: {
                shopId,
                active: activate
            },
            refetchQueries: ['ShopById']
        });
    }

    if(!data) {
        return <LinearProgress/>
    }

    const wholeSaleActive = data.ShopById?.wholeSaleActive || false

    return <>
        {!wholeSaleActive &&
        <Button
            variant={"outlined"}
            startIcon={<PlayCircleFilledWhiteIcon/>}
            onClick={() => {
                Dialogs.confirm({
                    title: "Are you sure you wish to activate self-creation?",
                    subtitle: "Consumers will be able to trade-in their items based on categories."
                }, async () => {
                    await onActivateWholeSale(true);
                })
            }}>Activate</Button>
        }
        {wholeSaleActive &&
        <Button
            variant={"outlined"}
            startIcon={<StopIcon/>}
            onClick={() => {
                Dialogs.confirm({
                    title: "Are you sure you wish to de-activate self-creation?",
                    subtitle: "Consumers will no-longer be able to trade-in their items based on categories."
                }, async () => {
                    await onActivateWholeSale(false);
                })
            }}>Deactivate</Button>
        }
    </>;
}