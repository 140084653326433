import * as React from "react";
import {FC} from "react";
import {useStatOrdersChartQuery} from "../../Queries";
import {Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";
import {Checkbox, Divider, FormControlLabel, LinearProgress} from "@mui/material";

import {Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {TitleText} from "../../layout/Typography";
import {BrandSelection} from "./BrandSelector";
import {createTrendline} from "../../utility/Trendline";
import {makeStyles} from "@mui/styles";
import useLocalStorage from "../../hooks/useLocalStorage";

type StatOrdersChartProps = {
   start: Date
   end: Date
   splits: Split[]
   brand?: BrandSelection
}

export const StatOrderChart: FC<StatOrdersChartProps> = ({start, end, splits, brand}) => {
   const {data} = useStatOrdersChartQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         splits: splits,
         shopIds: brand?.shopIds,
         brandIds: brand?.brandHouseIds
      }
   })
   const [showTrend, setShowTrend] = useLocalStorage<boolean>('trendline', false);
   const classes = useStyles();
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   type OrderData = {
      day: string
      total: number
      c2s: number
      trendTotal?: number
      trendC2s?: number
   }

   const transformed: OrderData[] = [];
   data?.StatisticsCalculate?.splits?.forEach(split => {
      let result = data?.StatisticsCalculate?.results?.filter(res => res?.split === split);
      transformed.push({
         day: split,
         total: result?.find(res => res?.kpi === 'orderTotal')?.value || 0,
         c2s: result?.find(res => res?.kpi === 'orderC2s')?.value || 0,
      })
   });

   const trend = createTrendline(transformed.map((trans, index) => ({
      day: index,
      total: trans.total
   })), 'day', 'total');

   const trendC2s = createTrendline(transformed.map((trans, index) => ({
      day: index,
      total: trans.c2s
   })), 'day', 'total');

   transformed.forEach((line, i) => {
      line.trendTotal = trend.calcY(i)
      line.trendC2s = trendC2s.calcY(i)
   })

   return <div>
      <div className={classes.titleContainer}>
         <TitleText type={"h2"}>Orders</TitleText>
         <div className={classes.titleCommands}>
            <FormControlLabel
               control={<Checkbox checked={showTrend} value={true} onChange={(e, val) => setShowTrend(val)}/>}
               label="Trendline"
            />
         </div>
      </div>
      <Divider style={{marginBottom: '20px'}}/>
      <ResponsiveContainer width={"100%"} height={180}>
         <ComposedChart
            width={400}
            height={400}
            data={transformed}
            margin={{top: 5, right: 20, left: 10, bottom: 5}}
            syncId={"orders"}
         >
            <XAxis dataKey="day"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <CartesianGrid stroke="#f5f5f5"/>
            <Area type="monotone" name={"All orders"} dataKey="total" stroke="#808080" fill="#C0C0C0"/>
            {showTrend && <Line
               type={"linear"}
               dataKey={"trendTotal"}
               legendType={"none"}
               tooltipType={"none"}
               stroke={trend.slope < 0 ? "#D09090" : "#90D090"}
               strokeWidth={1}
               dot={false}
            />}
         </ComposedChart>
      </ResponsiveContainer>

      <ResponsiveContainer width={"100%"} height={150}>
         <ComposedChart
            width={400}
            height={400}
            data={transformed}
            margin={{top: 5, right: 20, left: 10, bottom: 5}}
            syncId={"orders"}
         >
            <XAxis dataKey="day"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <CartesianGrid stroke="#f5f5f5"/>
            <Area name={'create2STAY orders'} type="monotone" dataKey="c2s" stroke="#808080" fill="#FF5376"/>
            {showTrend && <Line
               type={"linear"}
               dataKey={"trendC2s"}
               legendType={"none"}
               tooltipType={"none"}
               stroke={trendC2s.slope < 0 ? "#D09090" : "#90D090"}
               strokeWidth={1}
               dot={false}
            />}

         </ComposedChart>
      </ResponsiveContainer>
   </div>
}

const useStyles = makeStyles({
   titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
   },
   titleCommands: {
      position: "relative",
      marginTop: '-21px',
      top: '8px'
   }

});