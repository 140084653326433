import {gql, useMutation} from "@apollo/client";

const qlBrandHouseCreate = gql`
    mutation BrandHouseCreate($name: String!) {
      BrandHouseCreate(name:$name, logisticsPartnerId: "601c19a0c6dac20032263341") {
        _id
        name
      }
    }
`

export type BrandHouseCreate = {
   _id: string
   name: string
}

const useBrandHouseCreate = () => {
   const [brandHouseCreate] = useMutation(qlBrandHouseCreate);

   return async (name: string): Promise<BrandHouseCreate | undefined> => {
      let result = await brandHouseCreate({
         variables: {
            name: name
         },
         update(cache, {data}) {
            cache.evict(data.BrandHouseCreate._id);
         }
      });
      return result?.data?.BrandHouseCreate as BrandHouseCreate
   }
}

export default useBrandHouseCreate;