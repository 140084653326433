import {Dialog, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useBrandItemProductImagesQuery} from "../../../Queries";

type BrandItemImportDialogProps = {
   brandProductSizeId: string
   onSelect: (data: OriginalImageData) => void
   onHide: () => void
}

export const BrandItemImportDialog = ({brandProductSizeId, onHide, onSelect}: BrandItemImportDialogProps) => {
   return <Dialog open={true} onClose={onHide} fullWidth={true} maxWidth={"lg"}>
      <OriginalImages
         brandProductSizeId={brandProductSizeId}
         onSelect={onSelect}
      />
   </Dialog>
}

type OriginalImageData = {
   imageId: string
   imageUrl: string
}

type OriginalImagesProps = {
   brandProductSizeId?: string
   onSelect: (data: OriginalImageData) => void
}

const OriginalImages = ({brandProductSizeId, onSelect}: OriginalImagesProps) => {
   const classes = useStyles();
   const {data} = useBrandItemProductImagesQuery({
      variables: {
         brandProductSizeId: brandProductSizeId
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   const brandSize = data.BrandProductSize
   const brandVariant = brandSize?.brandVariant;
   const brandProduct = brandVariant?.brandProduct;

   return <div className={classes.container}>
      {brandVariant?.images?.length === 0 && <div>No images found</div>}
      {brandVariant?.images?.map(img => {
         const name = brandProduct?.nameLocalized?.find(name => name?.value)?.value || 'Unknown name'
         const color = brandVariant?.colorLocalized?.find(col => col?.value)?.value || 'Unknown color'
         const size = brandSize?.sizeLocalized?.find(size => size?.value)?.value || 'Unknown size'
         return <div
            key={'import_image' + img!._id}
            onClick={() => onSelect({
               imageId: img!._id,
               imageUrl: img!.imageUrl!
            })}
            className={classes.image}
            style={{
               backgroundImage: 'url(' + img!.imageUrl + ')',
               backgroundRepeat: "no-repeat",
               backgroundSize: "contain",
               backgroundPosition: "center",
               height: '380px', width: '380px',
               cursor: 'pointer'
            }}
         >
            <div className={classes.imageText}>
               <div style={{marginBottom: '10px'}}>
                  Name: {name}
               </div>
               <div>
                  Color: {color}
               </div>
               <div>
                  Size: {size}
               </div>
            </div>
         </div>
      })
      }
   </div>

}

const useStyles = makeStyles({
   container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px'
   },
   image: {
      position: 'relative',
      color: 'transparent',
      "&:hover *": {
         color: 'white',
         display: 'flex',
         flexDirection: 'column'
      }
   },
   imageText: {
      fontSize: '11px',
      position: 'absolute',
      bottom: 0,
      left: '20px',
      right: '20px',
      display: 'none',
      backgroundColor: '#000000',
      opacity: '0.5',
      padding: '5px 10px 5px 10px',
      minHeight: '40px'
   }
})