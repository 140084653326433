import * as React from "react";
import {FC, useState} from "react";
import {useParams} from "react-router-dom";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {
   useVoucherPageAdjustmentMutation, useVoucherPageSendReceivedMailMutation,
   useVoucherPageSynchronizeMutation,
   useVoucherPageVoucherQuery, VoucherPageVoucherDocument, VoucherUsagesDocument
} from "../../../Queries";
import {Box, Button, Dialog, DialogContent, LinearProgress} from "@mui/material";
import PageGroup from "../../../layout/PageGroup";
import {VoucherPanel} from "./VoucherPanel";
import {VoucherActions} from "./VoucherActions";
import {SavingUI} from "../../../SavingProvider";
import {VoucherUsages} from "./VoucherUsages";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";
import {FormProvider, useForm} from "react-hook-form";
import {TitleText} from "../../../layout/Typography";
import {ConsumerPanel} from "../../shop/components/ConsumerPanel";
import {FormInputPrice} from "../../shop/wholesale/form/FormInputPrice";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";

export const VoucherPagePath = (brandHouseId: string, shopId: string, voucherId: string) => {
   return "/brands/" + brandHouseId + "/rewards/" + voucherId;
}

export const VoucherPage: FC<any> = () => {
   const {voucherId, shopId, brandHouseId} = useParams<{ brandHouseId: string, shopId: string, voucherId: string }>();
   const [adjust, setAdjust] = useState<boolean>(false)
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const {data} = useVoucherPageVoucherQuery({
      variables: {
         id: voucherId
      }
   });
   const [syncVoucher] = useVoucherPageSynchronizeMutation();
   const [sendReceivedMail] = useVoucherPageSendReceivedMailMutation()

   if (!data?.VoucherById) {
      return <LinearProgress/>
   }
   const voucher = data!.VoucherById

   const onSendReceived = async () => {
      await SavingUI.process(async () => {
         await sendReceivedMail({
            variables: {
               shopId: shopId,
               options: {
                  received: {
                     voucherId: voucherId
                  }
               }
            }
         });
      }, 'Sent received mail')
   }

   const onSync = async () => {
      await SavingUI.process(async () => {
         await syncVoucher({
            variables: {
               shopId: shopId,
               code: data!.VoucherById!.code!
            }
         });
      }, 'Synchronized')
   }

   const onAdjust = () => {
      setAdjust(true)
   }

   return <Page>
      <PageHeader title="Reward details" onBack={true}>
         <Button onClick={onSendReceived}>Send received mail</Button>
      </PageHeader>
      <PageGroup widths={['75%', '25%']}>
         <PageContent fullHeight commands={<>
            {(!voucher.passive && voucher?.newVoucherUsage) && <Button onClick={onAdjust}>Adjust voucher</Button>}
            {(!voucher.passive && isAdministrator)  && <Button onClick={onSync}>Synchronize</Button>}
         </>}>
            <VoucherPanel voucherId={voucherId}/>
            <div style={{marginTop: '20px'}}>
               {!voucher?.newVoucherUsage &&
                  <VoucherActions voucherId={voucherId} shopId={shopId} brandHouseId={brandHouseId}/>
               }
               {voucher?.newVoucherUsage &&
                  <VoucherUsages voucherId={voucherId}/>
               }
            </div>
         </PageContent>
         <PageContent fullHeight>
            <ConsumerPanel consumerId={voucher.consumerId}/>
         </PageContent>
      </PageGroup>
      <Dialog open={adjust} maxWidth={"md"} onClose={() => setAdjust(false)} fullWidth>
         <DialogContent>
            <AdjustForm onHide={() => setAdjust(false)}/>
         </DialogContent>
      </Dialog>
   </Page>
}

type AdjustFormData = {
   voucherId: string
   shopId: string
   amount: number
   reason: string
}

type AdjustFormProps = {
   onHide: () => void
}

const AdjustForm = ({onHide}: AdjustFormProps) => {
   const {voucherId, shopId} = useParams<{ shopId: string, voucherId: string }>();
   const form = useForm<AdjustFormData>({
      defaultValues: {
         amount: 0,
         reason: ''
      }
   });
   const {handleSubmit} = form;
   const [adjust] = useVoucherPageAdjustmentMutation()

   const onSave = async (data: AdjustFormData) => {
      if(!data.amount || !data.reason) {
         return;
      }
      await SavingUI.process(async () => {
         await adjust({
            variables: {
               adjustment: {
                  amount: data.amount,
                  shopId: shopId,
                  voucherId: voucherId,
                  reason: data.reason,
                  date: new Date()
               }
            },
            refetchQueries: [{
               query: VoucherUsagesDocument,
               variables: {
                  voucherId: voucherId
               }
            }, {
               query: VoucherPageVoucherDocument,
               variables: {
                  id: voucherId
               }
            }]
         })
      }, 'Voucher updated', 'Please wait')
      onHide()
   }

   return <FormProvider {...form}>
      <Box>
         <TitleText type={"h1"}>Adjust the voucher value</TitleText>
         <TitleText type={"subtitle1"}>You can add or subtract an amount of the voucher here. If you need to correct the
            adjustment afterwards, do this by making another adjustment</TitleText>

         <FormInputPrice name={"amount"} label={"Amount"} allowNegative={true}/>
         <FormInputText name={"reason"} label={"Reason for adjustment"}/>

         <Box sx={{display: 'flex', gap: '10px'}}>
            <Button variant={"contained"} onClick={handleSubmit(onSave)}>Adjust</Button>
            <Button variant={"outlined"} onClick={onHide}>Cancel</Button>
         </Box>
      </Box>
   </FormProvider>
}