import {useRecoilValue} from "recoil";
import {CurrentUserState} from "../../../atoms/CurrentUser";
import {FC, useEffect, useRef, useState} from "react";
import {
   BarcodeFormat,
   BrowserCodeReader,
   ChecksumException,
   DecodeHintType,
   FormatException,
   MultiFormatReader,
   NotFoundException
} from "@zxing/library";
import Button from "@mui/material/Button";
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import {Alert, Slide, Snackbar} from "@mui/material";
import {useQrSessionUpdateMutation} from "../../../Queries";
import {isLocation, LocationInfo} from "./LocationInfo";

export const QRSession = () => {
   const scanState = useRef<string>('')
   const currentUser = useRecoilValue(CurrentUserState);
   const [code, setCode] = useState<string>("");
   const [snack, setSnack] = useState<boolean>(false);
   const [updateSession] = useQrSessionUpdateMutation();
   const [location, setLocation] = useState<string>('');

   const onScan = (result: string) => {
      if (scanState.current !== '') {
         return;
      }
      if (currentUser?.qrSession && currentUser?.qrSession.state === 'sender') {
         if (result !== code) {
            if (isLocation(result)) {
               setLocation(result);
            } else {
               setSnack(true);
               (async function () {
                  await updateSession({
                     variables: {
                        id: currentUser!.qrSession!.id,
                        code: result
                     }
                  })
                  setTimeout(() => {
                     setSnack(false);
                  }, 300)
               })();
            }
         }
      }
      setCode(result);
   }

   useEffect(() => {
      scanState.current = location
   }, [location])

   return <div>
      {location && <LocationInfo location={location} onHide={() => {
         setLocation('')
      }
      }/>}
      <Snackbar autoHideDuration={2000} open={snack} transitionDuration={400} TransitionComponent={Slide}>
         <Alert variant={"filled"} color={"success"}>{code}</Alert>
      </Snackbar>
      <QRScanner onScan={onScan}/>
   </div>
}

type QRScannerProps = {
   onScan: (result: string) => void
}
const QRScanner: FC<QRScannerProps> = ({onScan}) => {
   const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
   const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");

   const format = new MultiFormatReader();
   const hints = new Map();
   hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_128, BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE])
   format.setHints(hints)

   const codeReader = new BrowserCodeReader(format, 1000);
   //const codeReader = new BrowserQRCodeReader(2000);

   useEffect(() => {
      codeReader
         .listVideoInputDevices()
         .then(videoInputDevices => {
            setupDevices(videoInputDevices);
         })
         .catch(err => {
            console.error(err);
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function setupDevices(videoInputDevices: MediaDeviceInfo[]) {
      // selects first device
      setSelectedDeviceId(videoInputDevices[videoInputDevices.length - 1].deviceId);
      setDevices(videoInputDevices);
   }

   function decodeContinuously(selectedDeviceId: string | null) {
      codeReader.decodeFromVideoDevice(
         selectedDeviceId,
         "video",
         (result, err) => {
            if (result) {
               onScan(result.getText());
            }

            if (err) {
               // As long as this error belongs into one of the following categories
               // the code reader is going to continue as excepted. Any other error
               // will stop the decoding loop.
               //
               // Excepted Exceptions:
               //
               //  - NotFoundException
               //  - ChecksumException
               //  - FormatException

               if (err instanceof NotFoundException) {
                  console.log("No QR code found.");
               }

               if (err instanceof ChecksumException) {
                  console.log("A code was found, but it's read value was not valid.");
               }

               if (err instanceof FormatException) {
                  console.log("A code was found, but it was in a invalid format.");
               }
            }
         }
      );
   }

   useEffect(() => {
      decodeContinuously(selectedDeviceId);
      console.log(`Started decode from camera with id ${selectedDeviceId}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedDeviceId]);

   const onSwitchCamera = () => {
      let otherDevice = devices.find(device => {
         return device.deviceId !== selectedDeviceId
      });
      if (otherDevice) {
         setSelectedDeviceId(otherDevice.deviceId);
      }
   }

   return <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
      <video id="video" width="100%" height="100%" style={{objectFit: 'cover'}}/>
      {devices.length > 1 &&
      <Button onClick={onSwitchCamera} variant={"outlined"} startIcon={<FlipCameraIosIcon/>}>Switch</Button>}
   </div>
}
