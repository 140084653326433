import {Box, CircularProgress, TextField} from "@mui/material";
import {TitleText} from "../../layout/Typography";
import React, {useCallback, useEffect, useState} from "react";
import {useTradeInItemIdGetQuery, useTradeInItemIdUpdateMutation} from "../../Queries";
import {debounce} from "lodash";

type TradeInItemIdProps = {
   tradeInItemId: string
}

export const TradeInItemId = ({tradeInItemId}: TradeInItemIdProps) => {
   const {data, loading: loading2} = useTradeInItemIdGetQuery({
      variables: {
         tradeInItemId
      }
   });
   const [save, {loading}] = useTradeInItemIdUpdateMutation()
   const [value, setValue] = useState<string>('')

   const handleChangeDebounce = async (value: string) => {
      await save({
         variables: {
            tradeInItemId: tradeInItemId,
            input: {
               customData: value
            }
         }
      })
   }

   // eslint-disable-next-line
   const handleChange = useCallback(debounce(handleChangeDebounce, 400), []);

   const onChange = (event: any) => {
      setValue(event.target.value);
      handleChange(event.target.value)
   }

   useEffect(() => {
      if (!value && data) {
         setValue(data.TradeInItemById?.customData || '');
      }
   }, [data, value])

   return <Box sx={{marginTop: '10px'}}>
      <TitleText type={"subtitle1"}>Item ID</TitleText>
      <TextField
         onFocus={event => {
            event.target.select();
         }}
         value={value} onChange={onChange} size={"small"} placeholder={"Enter ID"} variant={"outlined"}
         InputProps={{
            endAdornment: (loading2 || loading) ? <CircularProgress size={"14px"}/> : <Box sx={{width: '14px'}}/>
         }}
      />
   </Box>
}