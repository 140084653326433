import {FC} from "react";
import {Divider, Grid, LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";
import {useStatOrderTableQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {DateUtility} from "../../utility/DateUtility";
import {StatCurrency} from "./StatCurrency";
import {getFilter, Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";

type StatOrderTableProps = {
   start: Date
   end: Date
   splits: Split[]
   brand?: BrandSelection
}

export const StatOrderTable: FC<StatOrderTableProps> = ({start, end, splits, brand}) => {
   const {data} = useStatOrderTableQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: getFilter(splits),
         brandHouseIds: brand?.brandHouseIds,
         shopIds: brand?.shopIds
      }
   })
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   let totalOrders = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderTotals')?.value || 0;
   let c2sOrders = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderC2S')?.value || 0;

   let orderSplit = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderSplit')?.value || 0;
   let orderItemsC2S = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderItemsC2S')?.value || 0;
   let orderItemsC2SValue = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderItemsC2SValue')?.value || 0;
   let orderItemsSplitValue = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderItemsSplitValue')?.value || 0;
   let orderItemsC2SReturned = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'orderItemsC2SReturned')?.value || 0;

   let prefix = 'C2S';

   let currency = brand?.currency||'DKK';
   return <div>
      <TitleText type={"h2"}>Order statistics in period
         ({Math.round(DateUtility.dateDiffDays(end, start))} days)</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <Grid container>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Total orders :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}> {totalOrders}</BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               {prefix} orders:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}> {c2sOrders}</BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               {prefix} order lines:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               {orderItemsC2S}
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               {prefix} order lines ({currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(orderItemsC2SValue)}/>
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Total order lines ({currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(orderItemsSplitValue)}/>
            </BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               {prefix} returned items:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>{orderItemsC2SReturned}</BodyText>
         </Grid>

         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Split orders:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText
               type={"body1"}>{c2sOrders > 0 ? Math.round((orderSplit * 100 / c2sOrders) * 10) / 10 : '-'}%{}</BodyText>
         </Grid>

         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Percentage of sale:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText
               type={"body1"}>{totalOrders > 0 ? Math.round((c2sOrders * 100 / totalOrders) * 10) / 10 : '-'}%</BodyText>
         </Grid>


      </Grid>
   </div>
}
