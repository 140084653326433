import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ShopTabs} from "../../ShopTabs";
import SettingsHeader from "../../SettingsHeader";
import {ObjectHistory} from "../../../components/ObjectHistory";
import {useHistorySettingsBrandQuery} from "../../../../Queries";

export const BrandOptionsHistoryPagePath = (brandId: string, shopId?: string) => {
   return "/brands/" + brandId + "/settings/history" + (shopId ? '/' + shopId : '');
}

export const BrandOptionsHistoryPage = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();
   const {data} = useHistorySettingsBrandQuery({
      variables: {
         refId: shopId || brandHouseId
      }
   })

   const handleChange = (newValue: string) => {
      navigate(BrandOptionsHistoryPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Settings Audit"}
                      subtitle={"Shows all changes to settings"}>
      </SettingsHeader>
      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <ObjectHistory refId={data?.BrandSettings?._id}/>}
         renderShop={() => <ObjectHistory refId={data?.BrandSettings?._id}/>}
      />
   </div>
}
