import * as React from "react"
import Page from "../../../../layout/Page";
import PageContent from "../../../../layout/PageContent";
import {useParams} from "react-router-dom";
import {Button, Checkbox, Chip, Grid, LinearProgress} from "@mui/material";
import {DataColumn, DataTable} from "../../../../components/DataTable";
import {Thumbnails} from "../../../../components/Thumbnails";
import {
   ProductVariant,
   useBrandProductImportMutation,
   useProductDetailDisableMutation,
   useProductDetailVariantsQuery
} from "../../../../Queries";
import {BodyText, TitleText} from "../../../../layout/Typography";
import {DateFormat} from "../../../../utility/DateFormat";
import PageHeader from "../../../../layout/PageHeader";
import {SimpleSelect} from "../../../../components/SimpleSelect";
import {SavingUI} from "../../../../SavingProvider";
import {ParamBrandHouse} from "../../../../Parameters";

export const ProductDetailPagePath = (brandHouseId: string, shopId: string, productId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/products/" + productId
}

export const ProductDetailPage = () => {
   const {brandHouseId, productId} = useParams<ParamBrandHouse & { shopId: string, productId: string }>();
   const {data} = useProductDetailVariantsQuery({
      variables: {
         productId: productId
      }
   });
   const [productDisable] = useProductDetailDisableMutation();
   const [exportToBrand] = useBrandProductImportMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const productDetail = data.ProductById;

   let columns: DataColumn<ProductVariant>[] = [{
      label: "Images",
      get: (row) => {
         return row.imageUrls;
      },
      render: (images) => {
         return <Thumbnails size={40} imageUrls={images} maxImages={2}/>
      },
      width: '20%'
   }, {
      label: 'Origin',
      field: 'countryOrigin',
      width: '10%'
   }, {
      label: 'TaxCode',
      field: 'commodityCode',
      width: '10%'
   }, {
      label: "SKU",
      field: "sku",
      width: '20%'
   }, {
      label: "EAN",
      field: "ean",
      width: '10%'
   }, {
      label: "Color",
      field: "color",
      width: '10%'
   }, {
      label: "Size",
      field: "size",
      width: '10%'
   }, {
      label: "RRP",
      get: (row) => {
         return row.rrp;
      },
      render: (rrp) => {
         return <div><SimpleSelect hideborder aria-readonly={true} label={"RRP"} value={"DKK"}
                                   options={rrp.map((rrp: any) => {
                                      return {
                                         id: rrp.currency,
                                         name: rrp.value + ' ' + rrp.currency
                                      }
                                   })}/></div>
      },
      width: '10%'
   }]

   const onDisableToggle = async () => {
      await productDisable({
         variables: {
            productId: productId,
            disable: !productDetail?.disabled
         }
      });
   }

   const onExport = async () => {
      await SavingUI.process(async () => {
         await exportToBrand({
            variables: {
               brandHouseID: brandHouseId,
               productID: productId
            }
         })
      }, 'Export started', 'Please wait')
   }

   return <Page>
      <PageHeader title={productDetail?.name || 'Unavngivet produkt'} onBack={true}>
         <Button variant={"outlined"} onClick={onExport}>Export to brand</Button>
      </PageHeader>
      <PageContent>
         <Grid container rowGap={2}>
            <Grid item xs={6}>
               <BodyText type={"subtitle1"}>
                  <div dangerouslySetInnerHTML={{__html: productDetail?.description || ''}}/>
               </BodyText>
            </Grid>
            <Grid item xs={6} container justifyContent={"space-between"}>
               <div>
                  <TitleText type={"h2"}>Created</TitleText>
                  <BodyText type={"body1"}>
                     {DateFormat.toPresent(productDetail?.createdAt)}
                  </BodyText>
               </div>
               <div>
                  <TitleText type={"h2"}>Updated</TitleText>
                  <BodyText type={"body1"}>
                     {DateFormat.toPresent(productDetail?.updatedAt)}
                  </BodyText>
               </div>
               <div>
                  <TitleText type={"h2"}>Style</TitleText>
                  <BodyText type={"body1"}>
                     {productDetail?.style}
                  </BodyText>
               </div>
            </Grid>
            <Grid item xs={6}>
               <TitleText type={"h2"}>Tags & Collections</TitleText>
               {productDetail?.tags?.map(tag => <Chip label={tag}/>)}
               {productDetail?.collections?.map(collection => <Chip variant={"outlined"} label={collection}/>)}
            </Grid>
            <Grid item xs={6} container justifyContent={"space-between"}>
               <div>
                  <TitleText type={"h2"}>
                     Disabled (TradeIn not possible)
                  </TitleText>
                  <Checkbox checked={!!productDetail?.disabled} onClick={onDisableToggle}/>
               </div>
               <div>
                  <TitleText type={"h2"}>
                     Preowned
                  </TitleText>
                  <BodyText type={"body1"}>{productDetail?.isPreOwned ? 'Yes' : 'No'}</BodyText>
               </div>
               <div>
                  <TitleText type={"h2"}>
                     Potential
                  </TitleText>
                  <BodyText
                     type={"body1"}>{productDetail?.isPotential ? 'Yes' : 'No'}
                  </BodyText>
               </div>
            </Grid>
         </Grid>
      </PageContent>
      <PageContent>
         {productDetail?.productVariants &&
            <DataTable columns={columns} idField={"_id"} rows={productDetail.productVariants}/>}
      </PageContent>
   </Page>
}
