import {useCircularPointsPanelQuery} from "../../../Queries";
import {BodyText} from "../../../layout/Typography";
import React from "react";

type CircularPointsPanelProps = {
   consumerId: string
}

export const CircularPointsPanel = ({consumerId}: CircularPointsPanelProps) => {
   const {data} = useCircularPointsPanelQuery({
      variables: {
         consumerId: consumerId
      }
   });

   return <>
      <BodyText type={"body1"}>
         {data?.CircularAccountById?.total} points ({data?.CircularAccountById?.pending} pending)
      </BodyText>
   </>
}
