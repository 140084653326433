import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import * as React from "react";
import {TrackingViewList} from "../lists/TrackingViewList";

export const LogisticsTrackingPage = () => {
   return <Page>
      <PageHeader title={"Activity"}/>
      <PageContent fullHeight>
         <TrackingViewList brandColumn={true} filter={{}}/>
      </PageContent>
   </Page>
}