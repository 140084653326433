import {Box, Button} from "@mui/material";
import {DetailsPagePath} from "./DetailsPage";
import {useShopListShopsQuery} from "../../../Queries";
import {useParams} from "react-router-dom";
import SettingsHeader from "../SettingsHeader";
import PageContent from "../../../layout/PageContent";
import * as React from "react";

export const ShopListPath = (brandHouseId: string, shopId: string) => {
   return DetailsPagePath(brandHouseId) + "/shops/" + shopId
}

export const ShopList = () => {
   const {shopId} = useParams<{ shopId: string }>()
   const {data} = useShopListShopsQuery({
      variables: {
         parentShopId: shopId
      }
   })
   return <Box>
      <SettingsHeader title={"Shops"}>
         <Button>Create shop</Button>
      </SettingsHeader>
      <PageContent>
         <Box>
            {data?.ShopMany?.map(shop => <Box>{shop.name}</Box>)}
         </Box>
      </PageContent>

   </Box>
}