import axios from "axios";
import React, {useEffect} from "react";

type ApiOptions = {
   method: 'get' | 'post'
   headers: any
}

export const useApiHook = (url: string, options?: Partial<ApiOptions>) => {
   const [data, setData] = React.useState<any | undefined>();
   useEffect(() => {
      axios({
         method: options?.method || 'get',
         url: url,
         headers: {
            ...options?.headers
         }
      }).then(value => {
         setData(value.data);
      })
   }, [url, options])
   return data;
}