/*!
 * Barcoder v2.0.0
 */

const validationRegex = /^\d{8,14}$/;

/**
 * Converts strings to intergers
 *
 * @param  numString
 * @return integer
 * @api private
 */
const toInt = (numString: string) => {
   return parseInt(numString, 10);
};

/**
 * Tests if a number is odd
 *
 * @param   num
 * @return {Boolean}
 * @api private
 */
var isOdd = (num: number) => {
   return (num % 2) === 1;
}

/**
 * Calculates a checksum (Modulo 10)
 * GTIN implementation factor 3
 *
 * @param gtin
 * @return integer
 * @api private
 */
const calculateChecksum = (gtin: string) => {
   gtin = parseInt(gtin, 10).toString();
   var chunks = gtin.split('').map(toInt).reverse();
   var checksum = 0;

   // Remove first chuck (checksum)
   chunks.shift();

   // sum numbers and multiply accordingly
   chunks.forEach(function (number, index) {
      checksum += isOdd(index) ? number : number * 3;
   });
   // calc checksum
   checksum %= 10;
   checksum = (checksum === 0) ? 0 : (10 - checksum);
   return checksum;
};

/**
 * Barcoder class
 */
export const Barcoder = {
   validate: (barcode: string) => {
      if (validationRegex.exec(parseInt(barcode).toString()) === null) {
         return false;
      }
      const checksum = parseInt(barcode.substring(barcode.length - 1), 10);
      const calcChecksum = calculateChecksum(barcode);
      return (checksum === calcChecksum);
   }
};
