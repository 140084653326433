import * as React from "react";
import {FC} from "react";
import {
   EnumBrandSettingStockItemConfigAutoDataOperator,
   EnumBrandSettingStockItemConfigAutoDataSearch,
   EnumBrandSettingStockItemConfigAutoDataTarget,
   EnumMetaFieldInstanceType,
   SettingsStockItemFragment,
   useAutoTagBrandQuery,
   useAutoTagSettingsQuery,
   useAutoTagSettingsUpdateMutation,
   useAutoTagShopQuery
} from "../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Button, Grid, IconButton, LinearProgress} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {SavingUI} from "../../../SavingProvider";
import {Dirty, DirtyDetector, ReactHookDirtyDetector} from "../../../components/DirtyDetector";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {BodyText, TitleText} from "../../../layout/Typography";
import {FormInputFree} from "../../shop/wholesale/form/FormInputFree";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {AddCircle, DeleteForever} from "@mui/icons-material";
import PageContent from "../../../layout/PageContent";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";

const FORMID_AUTODATA = "autodata";

const AutoDataSettingsPath = (brandId: string, shopId: string) => {
   return "/brands/" + brandId + "/settings/autodata/" + shopId
}

export const AutoDataSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(AutoDataSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Auto data"}>
         <Button variant={"contained"} type={"submit"} form={FORMID_AUTODATA}>
            Save
         </Button>
      </SettingsHeader>
      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <AutoDataSettingsBrand brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <AutoDataSettingsShop shopId={shop}/>}
      />
   </div>
}

type AutoDataSettingsProps = {
   shopId?: string
   brandHouseId?: string
}

const AutoDataSettingsShop = ({shopId}: AutoDataSettingsProps) => {
   const {data: shopStatic} = useAutoTagShopQuery({
      variables: {
         shopId: shopId
      }
   });

   const {data: settings} = useAutoTagSettingsQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useAutoTagSettingsUpdateMutation();

   const onSave = async (data: SettingsStockItemFragment) => {
      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               shopId: shopId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Stock settings updated')
   }

   if (!shopStatic || !settings) {
      return <LinearProgress/>
   }

   return <>
      <AutoDataEditor
         tags={shopStatic.ShopById!.productTags || []}
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
         hideExtra={true}
      />
      <DirtyDetector/>
   </>
}

const AutoDataSettingsBrand = ({brandHouseId}: AutoDataSettingsProps) => {
   const {data: shopStatic} = useAutoTagBrandQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   });

   const {data: settings} = useAutoTagSettingsQuery({
      variables: {
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useAutoTagSettingsUpdateMutation();

   const onSave = async (data: SettingsStockItemFragment) => {
      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               brandHouseId: brandHouseId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Stock settings updated')
   }

   if (!shopStatic || !settings) {
      return <LinearProgress/>
   }

   return <>
      <AutoDataEditor
         tags={shopStatic.BrandHouse!.productTags || []}
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}


type AutoDataEditorProps = {
   tags: string[]
   data: SettingsStockItemFragment
   onSave: (data: SettingsStockItemFragment) => Promise<void>
   hideExtra?: boolean
}

const AutoDataEditor: FC<AutoDataEditorProps> = ({
                                                    tags,
                                                    data,
                                                    onSave,
                                                    hideExtra
                                                 }) => {
   const methods = useForm<SettingsStockItemFragment>({
      defaultValues: data,
      reValidateMode: "onChange"
   });

   const {handleSubmit, control} = methods;

   const {fields: autoDataFields, append: dataAppend, remove: dataRemove} = useFieldArray({
      control,
      name: 'autoData'
   })

   const {fields: extraMetaFields, append: extraAppend, remove: extraRemove} = useFieldArray({
      control,
      name: 'extraMeta'
   })

   const {fields: extraTagFields, append: extraTagAppend, remove: extraTagRemove} = useFieldArray({
      control,
      name: 'extraTags'
   })

   return <div>
      <form onSubmit={handleSubmit(onSave)} id={FORMID_AUTODATA}/>
      <FormProvider {...methods}>
         <ReactHookDirtyDetector/>
         <PageContent>
            <BodyText type={"subtitle1"}>
               Use this section to configure data from the original products
               that should be automatically added to the secondhand stockitems, either as tags or metafields.
            </BodyText>
            <br/>
            <TitleText type={"h2"}>Tags</TitleText>
            <div style={{marginTop: '20px'}}>
               {autoDataFields.map((field, i) => {
                  if (field.target !== EnumBrandSettingStockItemConfigAutoDataTarget.tag) {
                     return null
                  }
                  return <Grid key={'autotag_' + field.id} container gap={1} justifyContent={'flex-start'}
                               wrap={'nowrap'}>
                     <Grid item xs={4}>
                        <FormInputFree name={`autoData.${i}.tag`} options={tags} label={"Tag"}/>
                     </Grid>
                     <Grid item xs={2}>
                        <FormInputSelect required name={`autoData.${i}.search`} label={"Search"}
                                         options={
                                            Object.values(EnumBrandSettingStockItemConfigAutoDataSearch).map(key => {
                                               return {
                                                  id: key,
                                                  name: key
                                               }
                                            })
                                         }/>
                     </Grid>
                     <Grid item xs={2}>
                        <FormInputSelect required name={`autoData.${i}.operator`} label={"Operator"}
                                         options={
                                            Object.values(EnumBrandSettingStockItemConfigAutoDataOperator).map(key => {
                                               return {
                                                  id: key,
                                                  name: key
                                               }
                                            })
                                         }/>
                     </Grid>
                     <Grid item xs={1}>
                        <IconButton onClick={() => dataRemove(i)}>
                           <DeleteForever/>
                        </IconButton>
                     </Grid>
                  </Grid>
               })}
               <Grid container style={{marginBottom: '20px'}}>
                  <Grid item xs={12}>
                     <IconButton onClick={() => dataAppend({
                        target: EnumBrandSettingStockItemConfigAutoDataTarget.tag,
                        tag: '',
                        search: EnumBrandSettingStockItemConfigAutoDataSearch.both,
                        operator: EnumBrandSettingStockItemConfigAutoDataOperator.include
                     })}><AddCircle/></IconButton>
                  </Grid>
               </Grid>
            </div>
            <TitleText type={"h2"}>Metafields</TitleText>
            <div style={{marginTop: '20px'}}>
               {autoDataFields.map((field, i) => {
                  if (field.target !== EnumBrandSettingStockItemConfigAutoDataTarget.meta) {
                     return null
                  }
                  return <Grid key={'autometa_' + field.id} container gap={1} justifyContent={'flex-start'}
                               wrap={'nowrap'}>
                     <Grid item xs={4}>
                        <FormInputText name={`autoData.${i}.tag`} label={"Meta field key"}/>
                     </Grid>
                     <Grid item xs={2}>
                        <FormInputSelect required name={`autoData.${i}.search`} label={"Search"}
                                         options={
                                            Object.values(EnumBrandSettingStockItemConfigAutoDataSearch).map(key => {
                                               return {
                                                  id: key,
                                                  name: key
                                               }
                                            })
                                         }/>
                     </Grid>
                     <Grid item xs={2}>
                        <FormInputSelect required name={`autoData.${i}.operator`} label={"Operator"}
                                         options={
                                            Object.values(EnumBrandSettingStockItemConfigAutoDataOperator).map(key => {
                                               return {
                                                  id: key,
                                                  name: key
                                               }
                                            })
                                         }/>
                     </Grid>
                     <Grid item xs={1}>
                        <IconButton onClick={() => dataRemove(i)}>
                           <DeleteForever/>
                        </IconButton>
                     </Grid>
                  </Grid>
               })}
               <Grid container style={{marginBottom: '20px'}}>
                  <Grid item xs={12}>
                     <IconButton onClick={() => dataAppend({
                        target: EnumBrandSettingStockItemConfigAutoDataTarget.meta,
                        tag: '',
                        search: EnumBrandSettingStockItemConfigAutoDataSearch.both,
                        operator: EnumBrandSettingStockItemConfigAutoDataOperator.include
                     })}><AddCircle/></IconButton>
                  </Grid>
               </Grid>
            </div>

            {!hideExtra && <>
               <TitleText type={"h2"}>Extra metafields</TitleText>
               <div style={{marginTop: '20px'}}>
                  {extraMetaFields.map((field, i) => {
                     return <Grid key={'extrameta_' + field.id} container gap={1} justifyContent={'flex-start'}
                                  wrap={'nowrap'}>
                        <Grid item xs={2}>
                           <FormInputText name={`extraMeta.${i}.namespace`} label={"Namespace"}/>
                        </Grid>
                        <Grid item xs={2}>
                           <FormInputText name={`extraMeta.${i}.key`} label={"Key"}/>
                        </Grid>
                        <Grid item xs={2}>
                           <FormInputSelect required name={`extraMeta.${i}.type`} label={"Type"}
                                            options={
                                               Object.values(EnumMetaFieldInstanceType).map(key => {
                                                  return {
                                                     id: key,
                                                     name: key
                                                  }
                                               })
                                            }/>
                        </Grid>
                        <Grid item xs={5}>
                           <FormInputText multiline={true} rows={2} name={`extraMeta.${i}.value`} label={"Value (Scripting available)"}/>
                        </Grid>

                        <Grid item xs={1}>
                           <IconButton onClick={() => extraRemove(i)}>
                              <DeleteForever/>
                           </IconButton>
                        </Grid>
                     </Grid>
                  })}
                  <Grid container style={{marginBottom: '20px'}}>
                     <Grid item xs={12}>
                        <IconButton onClick={() => extraAppend({
                           namespace: '',
                           key: '',
                           type: EnumMetaFieldInstanceType.Text,
                           value: ''
                        })}><AddCircle/></IconButton>
                     </Grid>
                  </Grid>
               </div>
               <TitleText type={"h2"}>Extra tags</TitleText>
               <div style={{marginTop: '20px'}}>
                  {extraTagFields.map((field, i) => {
                     return <Grid key={'extratags_' + field.id} container gap={1} justifyContent={'flex-start'}
                                  wrap={'nowrap'}>
                        <Grid item xs={8}>
                           <FormInputText name={`extraTags.${i}.tag`} label={"Tag"}/>
                        </Grid>
                        <Grid item xs={3}>
                           <FormInputCheckbox name={`extraTags.${i}.active`} label={"Active"}/>
                        </Grid>
                        <Grid item xs={1}>
                           <IconButton onClick={() => extraTagRemove(i)}>
                              <DeleteForever/>
                           </IconButton>
                        </Grid>
                     </Grid>
                  })}
                  <Grid container style={{marginBottom: '20px'}}>
                     <Grid item xs={12}>
                        <IconButton onClick={() => extraTagAppend({
                           tag: '',
                           active: true
                        })}><AddCircle/></IconButton>
                     </Grid>
                  </Grid>
               </div>
            </>}
         </PageContent>
      </FormProvider>
   </div>
}
