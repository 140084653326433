import {BodyText} from "../../../../layout/Typography";

type MinimumPriceProps = {
   price?: number | null
   currency?: string | null
}
export const MinimumPriceView = (props: MinimumPriceProps) => {
   if(!props.price || !props.currency) {
      return <BodyText type={"body2"}>No minimum price</BodyText>
   }
   return <>
      <BodyText type={"body2"}>Minimum price {props.price} {props.currency}</BodyText>
   </>
}
