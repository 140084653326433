import * as React from "react";
import {useEffect, useState} from "react";
import Page from "../../layout/Page";
import PageContentTabs from "../../layout/PageContentTabs";
import {
   EnumInvoiceLineLineType,
   InvoiceSettingInput,
   InvoiceTotalValue,
   useInvoiceLinesQuery,
   useInvoiceLineStockItemQuery,
   useInvoicePageDeleteMutation,
   useInvoicePageUpdateMutation,
   useInvoiceResetMutation,
   useInvoiceSettingsQuery,
   useInvoiceSettingsUpdateMutation,
   useInvoicesQuery
} from "../../Queries";
import {useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../Parameters";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FormInputText} from "../shop/wholesale/form/FormInputText";
import {TitleText} from "../../layout/Typography";
import {
   Box,
   Button,
   ButtonGroup,
   Drawer,
   Grid,
   LinearProgress,
   Menu,
   MenuItem,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow
} from "@mui/material";
import {SaveButton} from "../../components/SaveButton";
import makeStyles from "@mui/styles/makeStyles";
import {Dialogs} from "../../DialogProvider";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin, CurrentUserIsShop} from "../../atoms/CurrentUser";
import {Thumbnails} from "../../components/Thumbnails";
import {CheckBoxOutlineBlankTwoTone, CheckBoxTwoTone} from "@mui/icons-material";
import {SavingUI} from "../../SavingProvider";
import PageContent from "../../layout/PageContent";
import {FormInputPrice} from "../shop/wholesale/form/FormInputPrice";
import {FormInputJSON} from "../shop/wholesale/form/FormInputJSON";
import {ObjectHistory} from "../components/ObjectHistory";

export const InvoicesPagePath = (brandId: string) => {
   return "/brands/" + brandId + "/invoices"
}

export const InvoicesPage = () => {
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   let tabs = [
      {label: 'Invoices'}
   ];
   if (isAdministrator) {
      tabs.push({label: 'Settings'});
   }

   return <Page>
      <PageContentTabs
         tabs={tabs}
         render={(tab: string) => {
            if (tab === 'Invoices') {
               return <PageContent>
                  <Invoices/>
               </PageContent>
            } else {
               return <Settings/>
            }
         }}
      />
   </Page>
}

type InvoiceLineSelection = {
   invoiceId: string
   line: InvoiceTotalValue | undefined | null
}

const Invoices = () => {
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const [line, setLine] = useState<InvoiceLineSelection | null>();

   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data} = useInvoicesQuery({
      variables: {
         brandHouseId
      }
   })
   const [regenerate] = useInvoiceResetMutation()
   const [update] = useInvoicePageUpdateMutation()
   const [deleteInvoice] = useInvoicePageDeleteMutation()
   const classes = useStyles();

   const onRegenerate = (invoiceId: string) => {
      Dialogs.confirm({
         title: 'Regenerate invoice ' + invoiceId + '?',
         subtitle: 'Please be patient, it can take a while - and then reload browser'
      }, async () => {
         await SavingUI.process(async () => {
            await regenerate({
               variables: {
                  brandHouseId,
                  invoiceId
               }
            });
         }, 'Invoice will be regnerated')
      });
   }

   const onDelete = (invoiceId: string) => {
      Dialogs.confirm({
         title: 'Delete all invoices after ' + invoiceId + ' ?',
         subtitle: 'Please be patient, it can take a while - and then reload browser'
      }, async () => {
         await SavingUI.process(async () => {
            await deleteInvoice({
               variables: {
                  brandHouseId,
                  invoiceId
               },
               refetchQueries: ['Invoices']
            })
         }, 'Invoice deleted')
      });
   }

   const onUpdatePaid = (invoiceId: string, paid: boolean) => {
      Dialogs.confirm({
         title: 'Mark invoice ' + invoiceId + ' as ' + (paid ? 'paid' : 'unpaid') + '?'
      }, async () => {
         await update({
            variables: {
               brandHouseId,
               invoiceId,
               paid: paid
            }
         });
      });
   }
   const onUpdateLock = (invoiceId: string, locked: boolean) => {
      Dialogs.confirm({
         title: 'Mark invoice ' + invoiceId + ' as ' + (locked ? 'locked' : 'unlocked') + '?'
      }, async () => {
         await update({
            variables: {
               brandHouseId,
               invoiceId,
               locked: locked
            }
         });
      });
   }

   let cutOffDate = new Date("2022-08-01T12:00:00.000");
   let invoices = data?.Invoices?.filter(invoice => {
      if (isBrandHouse) {
         return new Date(invoice!.invoiceDate!) >= cutOffDate;
      } else {
         return true;
      }
   }) || [];

   return <div className={classes.page}>
      <div className={classes.invoicePanel}>
         {invoices?.map(invoice => {
            return <div style={{marginBottom: '30px'}}>
               {!isBrandHouse &&
                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                     <TitleText type={"h2"}>{invoice?.invoiceId}</TitleText>
                     <ButtonGroup size={"small"} color={"secondary"}>
                        <Button onClick={() => onUpdateLock(invoice!.invoiceId, !invoice!.locked)}>
                           Locked {invoice!.locked ? <CheckBoxTwoTone/> : <CheckBoxOutlineBlankTwoTone/>}
                        </Button>
                        <Button onClick={() => onUpdatePaid(invoice!.invoiceId, !invoice!.paid)}>
                           Paid {invoice!.paid ? <CheckBoxTwoTone/> : <CheckBoxOutlineBlankTwoTone/>}
                        </Button>
                        <Button onClick={() => onRegenerate(invoice!.invoiceId)}>
                           Regenerate
                        </Button>
                        <Button disabled={!!invoice?.locked} onClick={() => onDelete(invoice!.invoiceId)}>
                           Delete
                        </Button>
                     </ButtonGroup>
                  </Box>}
               <div style={{maxWidth: '500px'}}>
                  {invoice?.total?.sections?.map(section => {
                     return <Grid container key={'row_' + invoice?.invoiceId + '_' + section?.title}>
                        <Grid item xs={12} className={classes.invoiceTitle}>
                           {section?.title}
                        </Grid>
                        {section?.lines?.map(line => {
                           return <Grid container className={classes.invoiceLine}>
                              <Grid item xs={6} style={{textAlign: 'left'}}>
                                 <div onClick={() => setLine({
                                    line: line,
                                    invoiceId: invoice._id
                                 })} style={{cursor: 'pointer'}}>{line?.title}</div>
                              </Grid>
                              <Grid item xs={3} style={{textAlign: 'right'}}>
                                 {line?.count}
                              </Grid>
                              <Grid item xs={3} style={{textAlign: 'right'}}>
                                 {line?.sum} {invoice?.currency}
                              </Grid>
                           </Grid>
                        })}
                        <Grid container className={classes.invoiceLine} style={{marginBottom: '10px'}}>
                           <Grid item xs={6} style={{textAlign: 'left'}}>
                              Subtotal
                           </Grid>
                           <Grid item xs={3} style={{textAlign: 'right'}}>
                              -
                           </Grid>
                           <Grid item xs={3} style={{textAlign: 'right'}}>
                              {section?.total} {invoice?.currency}
                           </Grid>
                        </Grid>
                     </Grid>
                  })}
                  <div>
                     <Grid container className={classes.totalLine} style={{marginBottom: '10px'}}>
                        <Grid item xs={6} style={{textAlign: 'left'}}>
                           Total
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                           -
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                           {invoice?.total?.total} {invoice?.currency}
                        </Grid>
                     </Grid>
                  </div>
               </div>
            </div>
         })}
      </div>
      <Drawer variant={"temporary"} open={!!line} anchor={"right"} onClose={() => setLine(null)}
              BackdropProps={{invisible: true}}>
         <div className={classes.lineDetails}>
            {line && <InvoiceLineView invoiceId={line.invoiceId} lineType={line.line!.lineType!}/>}
         </div>
      </Drawer>
   </div>
}

const Settings = () => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data} = useInvoiceSettingsQuery({
      variables: {
         brandHouseId
      }
   });
   const [saveSettings] = useInvoiceSettingsUpdateMutation()

   const methods = useForm<InvoiceSettingInput>({
      defaultValues: {
         brandHouseId: brandHouseId,
         fee: 0,
         currency: 'DKK',
         costPerItem: 0,
         shops: []
      }
   });

   const {control, reset, handleSubmit} = methods;

   const {fields, append} = useFieldArray({
      control,
      name: "shops"
   });

   useEffect(() => {
      if (data && data.InvoiceSetting) {
         reset({
            ...data.InvoiceSetting
         });
      }
   }, [data, reset]);

   const shopsToAdd = data?.BrandHouse?.shops?.filter(shp => {
      return !fields?.find(shop => String(shop!.shopId) === String(shp?._id));
   }) || [];

   const onSubmit = async (data: InvoiceSettingInput) => {
      await saveSettings({
         variables: {
            settings: data
         }
      })
   }

   const removeBorders = {
      sx: {borderBottom: 'none'}
   }

   return <div>
      <PageContent>
         <FormProvider {...methods}>
            <TitleText type={"h2"}>Invoice settings</TitleText>

            <Grid container spacing={"10px"}>
               <Grid item xs={6}>
                  <FormInputPrice name={"fee"} label={"Monthly fee (Optional)"}/>
                  <FormInputPrice name={"minPlatformCost"} label={"Minimum platform cost (Optional)"}/>
                  <FormInputPrice name={"costPerItem"} label={"Cost per item (Invoice currency)"}/>
                  <FormInputText name={"currency"}
                                 label={"Invoice currency (Costs will be converted to this currency)"}/>
               </Grid>
               <Grid item xs={6}>
                  <FormInputJSON name={"customData"} placeholder={"E-Conomic JSON"} height={"214px"}/>
               </Grid>
            </Grid>


            <Table size={"small"}>
               <TableHead>
                  <TableRow>
                     <TableCell {...removeBorders}></TableCell>
                     <TableCell {...removeBorders}>Stock Item</TableCell>
                     <TableCell {...removeBorders} colSpan={4}>Order</TableCell>
                     <TableCell {...removeBorders} colSpan={1}>TradeIns</TableCell>
                     <TableCell {...removeBorders}>Extra</TableCell>
                  </TableRow>

                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell>Cost per item</TableCell>
                     <TableCell>Pick pack fee</TableCell>
                     <TableCell>Commission %</TableCell>
                     <TableCell>Shipping cost</TableCell>
                     <TableCell>Shipping cost</TableCell>
                     <TableCell>Shipping return</TableCell>
                     <TableCell>Value added</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {fields?.map((shop, index) => {
                     const shp = data?.BrandHouse?.shops?.find(shp => shp?._id === shop.shopId);
                     return <TableRow>
                        <TableCell sx={{
                           whiteSpace: 'nowrap'
                        }}>{shp?.name}</TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.costPerItem`}
                                           label={'(' + shp?.currency + ')'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.pickPackFee`}
                                           label={'(' + shp?.currency + ')'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.saleCommisionPct`} label={'0-100 %'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.shippingOrder`}
                                           label={'(' + shp?.currency + ')'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.shippingReturn`}
                                           label={'(' + shp?.currency + ')'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputPrice name={`shops.${index}.shippingTradeIn`}
                                           label={'(' + shp?.currency + ')'}/>
                        </TableCell>
                        <TableCell>
                           <FormInputText name={`shops.${index}.comment`} label={'Value added services'} multiline
                                          rows={3}/>
                        </TableCell>
                     </TableRow>
                  })}
               </TableBody>
            </Table>
            {fields?.length === 0 && <div>No invoices yet</div>}

            {shopsToAdd.length > 0 && <>
               <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
               >
                  Add shop
               </Button>
               <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               > {shopsToAdd.map(brand => {
                  return <MenuItem key={'add_' + brand?._id} onClick={() => {
                     append({
                        shopId: brand?._id,
                        costPerItem: 0,
                        pickPackFee: 0,
                        saleCommisionPct: 0,
                        shippingTradeIn: 0,
                        shippingOrder: 0,
                        shippingReturn: 0,
                        comment: ''
                     })
                  }}>{brand?.name}</MenuItem>
               })}
               </Menu>
            </>}
            <SaveButton saveMessage={"OK"} onClick={handleSubmit(onSubmit)}>Save</SaveButton>
         </FormProvider>
      </PageContent>
      <ObjectHistory refId={data?.InvoiceSetting?._id}/>
   </div>
}

const useStyles = makeStyles({
   page: {
      position: "relative",
      display: 'flex'
   },
   commands: {
      position: 'absolute',
      right: 0
   },
   invoiceTitle: {
      borderBottom: '1px solid #c0c0c0',
      fontWeight: 500
   },
   invoiceLine: {
      borderBottom: '1px solid #c0c0c0'
   },
   lineDetails: {
      marginLeft: '20px',
      padding: '20px'
   },
   invoicePanel: {},
   totalLine: {
      borderBottom: '2px solid #808080'
   }
});

type InvoiceLineViewProps = {
   invoiceId: string
   lineType: EnumInvoiceLineLineType
}

const InvoiceLineView = ({invoiceId, lineType}: InvoiceLineViewProps) => {
   const {data} = useInvoiceLinesQuery({
      variables: {
         invoiceId: invoiceId,
         lineType: lineType
      }
   });

   return <div>
      {lineType === EnumInvoiceLineLineType.item &&
         <TitleText type={"h2"}>
            Stockitems registered
         </TitleText>
      }
      {data?.InvoiceLines?.map(line => {
         if (line.lineType === EnumInvoiceLineLineType.item) {
            return <InvoiceLineStockItem stockItemId={line.lineId}/>
         }
         return <div key={'line_' + line?._id}>
            {line.cost}
         </div>
      })}
   </div>
}

const InvoiceLineStockItem = ({stockItemId}: { stockItemId: string }) => {
   const {data} = useInvoiceLineStockItemQuery({
      variables: {stockItemId}
   });
   if (!data) {
      return <LinearProgress/>
   }
   return <Grid container style={{minWidth: '500px'}}>
      <Grid item xs={2}>
         {data.StockItemById?.itemNr}
         {data.BrandItem?.itemNr}
      </Grid>
      <Grid item xs={8}>
         {data.StockItemById?.title}
         {data.BrandItem?.nameLocalized?.[0]?.value}
      </Grid>
      <Grid item xs={2}>
         <Thumbnails imageUrls={data.StockItemById?.imageUrls || data.BrandItem?.imageUrls || []} size={30}
                     maxImages={1}/>
      </Grid>
   </Grid>
}
