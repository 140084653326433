import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import React from "react";
import {SeasonsList} from "./SeasonsList";
import {useNavigate, useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../../Parameters";
import {useSeasionsPageCreateMutation} from "../../../Queries";
import Button from "@mui/material/Button";
import {SeasonsEditPagePath} from "./SeasonsEditPage";

export const SeasonsPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId + "/seasons"
}

export const SeasonsPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const [createSeason] = useSeasionsPageCreateMutation()
   const navigate = useNavigate();

   const onCreateSeason = async () => {
      const {data} = await createSeason({
         variables: {
            brandHouseId
         }
      })
      if (data?.SeasonCreate?.record?._id) {
         navigate(SeasonsEditPagePath(brandHouseId, data?.SeasonCreate.record._id))
      }
   }

   return <Page>
      <PageHeader title={"Seasons"}>
         <Button onClick={onCreateSeason}>Create season</Button>
      </PageHeader>
      <PageContent>
         <SeasonsList brandHouseId={brandHouseId}/>
      </PageContent>
   </Page>
}