import * as React from "react"
import {FC} from "react"
import {QComponentChildrenProps} from "../Model";
import {Grid} from "@mui/material";

export type QHorizontalProps = QComponentChildrenProps & {
   justify?: any
}

export const QHorizontal:FC<QHorizontalProps> = ({justify, children}) => {
   return <Grid justifyContent={justify||'flex-start'} wrap={"nowrap"} style={{gap: '10px'}} container>
      {React.Children.map(children, (child) => {
         return child
      })}
   </Grid>
}