import {gql, useMutation} from "@apollo/client";

export const qlStartSynchronize = gql`
    mutation StartSynchronize($shopId: String!, $service: String!) {
      StartSynchronize(shopId:$shopId, service:$service) {
         status
      }
    }
`;

export type ServiceEnum = 'orders' | 'products' | 'hooks' | 'stockitems';
const useSynchronization = () => {
   const [startSynchronize] = useMutation(qlStartSynchronize);

   return (shopId: string, service: ServiceEnum) => {
      return startSynchronize({
         variables: {
            shopId: shopId,
            service: service
         },
         fetchPolicy: "no-cache"
      }).then(data => data.data.StartSynchronize?.status)
   }
}

export default useSynchronization;