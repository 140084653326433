import {Button, Menu, MenuItem} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import * as React from "react";

type FormInputAddProps = {
   id: string
   label: string
   onSelect: (value: string) => void
   values?: string[]
   hideValues?: string[]
}

export const FormInputAdd = ({id, label, onSelect, values, hideValues}: FormInputAddProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const messagesToHide = hideValues || []

   const relevantMessages = (values || []).filter(message => !(messagesToHide || []).find(lf => lf === message));
   if (relevantMessages.length === 0) {
      return <></>
   }
   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? id + '-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         startIcon={<AddCircle/>}
         variant={"outlined"}
         color={"secondary"}
      >
         {label}
      </Button>
      <Menu
         anchorEl={anchorEl}
         id={id + "-menu"}
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {relevantMessages.map(message => {
            return <MenuItem key={id + 'menu_message_' + message} onClick={() => onSelect(message)}>
               {message}
            </MenuItem>
         })}
      </Menu>
   </>
}
