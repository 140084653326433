import * as React from "react";
import {useEffect, useState} from "react";
import {
   BrandSetting,
   ItemSourceType,
   ProductVariantSize,
   Season,
   StockItemFragment,
   StockItemUpdateInput,
   useStockItemFormRecalculatePriceMutation,
   ViewProductVariantFragment
} from "../../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {FormInputText} from "../../wholesale/form/FormInputText";
import makeStyles from "@mui/styles/makeStyles";
import {FormInputSelect} from "../../wholesale/form/FormInputSelect";
import {BodyText, Link, TitleText} from "../../../../layout/Typography";
import {Cached, Edit} from "@mui/icons-material";
import PageGroup from "../../../../layout/PageGroup";
import {StockItemProblemsForm} from "./StockItemProblems";
import PageContent from "../../../../layout/PageContent";
import {DateFormat} from "../../../../utility/DateFormat";
import InfoIcon from '@mui/icons-material/Info';
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../../atoms/CurrentUser";
import SearchIcon from '@mui/icons-material/Search';
import {PubSub} from "../../../../components/PubSub";
import Barcode from "../../../../components/Barcode";
import {Dialogs} from "../../../../DialogProvider";
import {FormInputHtml} from "../../wholesale/form/FormInputHtml";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {FormInputFree} from "../../wholesale/form/FormInputFree";
import {SimpleSelect} from "../../../../components/SimpleSelect";
import {FormInputPrice} from "../../wholesale/form/FormInputPrice";
import {LocalizationEditor} from "../../components/LocalizationEditor";
import {Dirty} from "../../../../components/DirtyDetector";
import {FormInputCheckbox} from "../../wholesale/form/FormInputCheckbox";
import {FormInputSelectMultiple} from "../../wholesale/form/FormInputSelectMultiple";

export type ProductStatus = 'none' | 'draft' | 'review' | 'sale';

export type StockItemFormData = StockItemFragment & {
   productStatus: ProductStatus
   missingB: boolean
}

type StockItemEditFormProps = {
   settings: BrandSetting
   data: StockItemFormData
   onSubmit: (data: StockItemFormData) => Promise<void>
   onNavigate: (to: 'order' | 'consumer' | 'tradein', id: any) => void
   onSearch: (search?: string) => void
   currency: string
   tags: string[]
   productTypes: string[]
   primaryLocale: string
   locales: string[]
   seasons: Season[]
}

const resolver = async (values: StockItemFormData) => {
   const errors: any = {}
   if (!values.condition) {
      errors.condition = {
         type: 'validation',
         message: 'Condition is required'
      }
   }
   if (values.productStatus === 'sale') {
      const required = {type: 'validation', message: 'This field is required'};
      if (!values.sku) errors.sku = required;
      if (!values.originalEan) errors.originalEan = required;
      if (!values.title) errors.title = required;
      if (!values.size) errors.size = required;
      if (!values.sellingPrice || values.sellingPrice === 0) errors.sellingPrice = required;
      if (!values.beforePrice || values.beforePrice === 0) errors.beforePrice = required;

      if (values.sellingPrice && values.beforePrice) {
         if (values.sellingPrice > values.beforePrice) {
            errors.sellingPrice = {
               type: 'sellprice-error',
               message: 'Price can\'t be higher than the compare at price'
            }
         }
      }
   }
   return {
      values,
      errors: errors
   }
};

export const StockItemEditForm = ({
                                     data,
                                     onSubmit,
                                     settings,
                                     onNavigate,
                                     currency,
                                     onSearch,
                                     tags,
                                     productTypes,
                                     primaryLocale,
                                     locales,
                                     seasons
                                  }: StockItemEditFormProps) => {
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const [showTranslations, setShowTranslations] = useState<boolean>(false);
   const [recalculatePrice, {loading}] = useStockItemFormRecalculatePriceMutation()

   const methods = useForm<StockItemFormData & { newTag?: string }>({
      defaultValues: {
         ...data,
         newTag: ''
      },
      resolver: resolver,
      mode: 'onSubmit',
      reValidateMode: 'onSubmit'
   })
   const {watch, getValues, setValue, control, handleSubmit, trigger, reset} = methods;

   const prodStatus = watch('productStatus');

   //@ts-ignore
   const {fields, append, remove} = useFieldArray({
      control,
      name: 'itemTags'
   })

   useEffect(() => {
      (async function () {
         await trigger();
         if (getValues('beforePrice')) {
            if (!getValues('sellingPrice')) {
               recalculate();
            }
         }
      }());
      // eslint-disable-next-line
   }, [data.title, data.description, data.color, getValues, isBrandHouse, setValue, trigger])

   // Callback version of watch.  It's your responsibility to unsubscribe when done.
   React.useEffect(() => {
      const subscription = watch((value, {name, type}) => {
         Dirty.flag(type === 'change')
      });
      return () => subscription.unsubscribe();
   }, [watch]);

   useEffect(() => {
      return PubSub.on('UpdateProduct', ({variant, size}: {
         variant: ViewProductVariantFragment,
         size: Partial<ProductVariantSize>
      }) => {
         let currentSize = getValues('size');
         if (currentSize && currentSize !== size.size) {
            console.log(variant, size);
            Dialogs.confirm({
               title: 'Warning - Size change!',
               subtitle: 'The style you are editing, does not have the same SIZE as the one you just selected !\n' +
                  'This should be avoided, since the logistics partner registers the sizes when receiving the item\n' +
                  'Are you should you wish to CHANGE THE SIZE of this style?',
               defaultNo: true
            }, () => {
               reset({
                  ...data,
                  title: variant.product?.name,
                  description: variant.product?.description || '',
                  size: size.size,
                  color: variant.color,
                  sku: size.sku,
                  originalEan: size.ean,
                  beforePrice: variant.rrp?.find(rrp => rrp?.currency === currency)?.value,
                  productType: variant.product?.productType
               });
               recalculate()
            })
         } else {
            reset({
               ...data,
               title: variant.product?.name || '',
               description: variant.product?.description || '',
               size: size.size,
               color: variant.color,
               sku: size.sku,
               originalEan: size.ean,
               beforePrice: variant.rrp?.find(rrp => rrp?.currency === currency)?.value,
               productType: variant.product?.productType
            });
            recalculate()
         }
      });
      // eslint-disable-next-line
   }, [])

   const classes = useStyles();
   let html = settings?.stockItemConfig?.descriptionSuffix || undefined;

   const recalculate = () => {
      recalculatePrice({
         variables: {
            shopId: settings?.shopId,
            stockItem: getStockItemInput(getValues())
         }
      }).then(({data}) => {
         if (data) {
            setValue('sellingPrice', data?.StockItemsRecalculatePrice, {
               shouldDirty: true
            });
         }
      })
   }

   const doNavigate = (to: 'order' | 'consumer' | 'tradein', id?: string) => (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (id) {
         onNavigate(to, id);
      }
   }

   const doSearch = (search?: string | null) => {
      onSearch(search || undefined)
   }

   let productStatus = [{
      id: 'none',
      name: 'Sold'
   }, {
      id: 'draft',
      name: 'Draft'
   }, {
      id: 'review',
      name: 'Review'
   }, {
      id: 'sale',
      name: 'For sale'
   }];

   return <FormProvider {...methods}>
      <form id={"stockItemForm"} onSubmit={handleSubmit(onSubmit)}>
         <PageGroup widths={['65%', '35%']}>
            <PageContent>
               <Grid container columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                     <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <TitleText type={"h1"}>Stock Item #{data.itemNr}</TitleText>
                        <BodyText type={"subtitle1"}>{data.itemSku}</BodyText>
                     </Box>
                     <Link icon={<Edit fontSize={"small"}/>}
                           onClick={() => setShowTranslations(true)}>Translations</Link>
                  </Grid>

                  <Grid item xs={12} style={{marginTop: '5px'}}>
                     <FormInputText name={"title"} control={control} label={"Name"}
                                    InputProps={{
                                       startAdornment: <div className={classes.titleAdornment}>
                                          {settings?.stockItemConfig?.titlePrefix}
                                       </div>,
                                       endAdornment: <IconButton title={"Search for master data"}
                                                                 onClick={() => doSearch(getValues('title'))}><SearchIcon/></IconButton>
                                    }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormInputHtml name={"description"} label={"Description"} height={"150px"}>
                        {html && <div
                           style={{
                              display: 'block',
                              flexWrap: 'wrap',
                              paddingTop: '0px',
                              width: '100%',
                              color: '#a0a0a0',
                              marginBottom: '20px'
                           }}
                           dangerouslySetInnerHTML={{__html: html}}/>}
                     </FormInputHtml>
                  </Grid>
                  <Grid item xs={6}>
                     <FormInputText name={"size"} control={control} label={"Size"}/>
                     <FormInputText name={"color"} control={control} label={"Color"}/>
                     <FormInputSelect name={"sourceType"} label={"Source of item"}
                                      options={Object.values(ItemSourceType).map(key => ({
                                         id: key,
                                         name: key
                                      }))}/>
                  </Grid>
                  <Grid item xs={6}>
                     <FormInputFree name={"productType"} label={"Product type"} options={productTypes}
                                    onValueChange={recalculate}
                     />
                     <div className={classes.tagsContainer}>
                        <FormInputFree label={"Add tag"} name={"newTag"} options={tags}
                                       onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                             e.preventDefault();
                                          }
                                       }}
                                       onKeyUp={(e) => {
                                          if (e.key === 'Enter') {
                                             let newTag = getValues('newTag');
                                             if (!fields.find(field => field.tag === newTag)) {
                                                append({
                                                   tag: newTag,
                                                   auto: false
                                                });
                                                recalculate()
                                             }
                                             setValue('newTag', '');
                                          }
                                       }}
                        />
                        {fields.map((field, i) => {
                           if (field.auto) {
                              return <Chip key={'tag_' + field?.tag} className={classes.chip} size={"small"}
                                           label={field?.tag}/>
                           } else {
                              return <Chip key={'tag_' + field?.tag} className={classes.chip} size={"small"}
                                           label={field?.tag}
                                           variant={"outlined"} onDelete={() => {
                                 remove(i);
                                 recalculate()
                              }}/>
                           }
                        })}
                     </div>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: '20px', marginBottom: '10px'}}>
                     <TitleText type={"h2"}>Price and condition</TitleText>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputSelect required={true} options={[{
                        id: 1,
                        name: "Fair (1)"
                     }, {
                        id: 2,
                        name: "Good (2)"
                     }, {
                        id: 3,
                        name: "Excellent (3)"
                     }]} name={"condition"} control={control} label={"Condition"} onSelectChange={() => recalculate()}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputPrice name={"sellingPrice"} label={"Selling price (" + currency + ")"}
                                     InputProps={{
                                        endAdornment: <div onClick={recalculate} style={{
                                           cursor: 'pointer',
                                           padding: '9px 5px 9px 5px',
                                           backgroundColor: '#f0f0f0',
                                           marginRight: '-12px'
                                        }}>{loading ? <HourglassEmptyIcon/> : <Cached/>}</div>
                                     }}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputPrice name={"beforePrice"} label={"Compare at price (" + currency + ")"}/>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: '20px', marginBottom: '10px'}}>
                     <TitleText type={"h2"}>Master data</TitleText>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputText name={"sku"} control={control} label={"Original SKU"}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputText name={"originalEan"} control={control} label={"Original EAN"}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputText name={"externId"} control={control} disabled label={"Extern ID"}/>
                  </Grid>
               </Grid>
            </PageContent>
            <PageGroup direction={"column"}>
               <PageContent>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                     <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                        <TitleText type={"h2"}>Status</TitleText>
                     </Grid>
                     <Grid item xs={12}>
                        <SimpleSelect
                           required
                           options={productStatus}
                           label={"Product status"} value={prodStatus} onSelectChange={async (e) => {
                           setValue('productStatus', e.target.value as ProductStatus, {
                              shouldValidate: false,
                              shouldTouch: false,
                              shouldDirty: false
                           });
                           await trigger();
                           setValue('productStatus', e.target.value as ProductStatus, {
                              shouldValidate: true,
                              shouldTouch: true,
                              shouldDirty: true
                           });
                        }}/>
                     </Grid>
                     <Grid item xs={6}>
                        <FormInputText disabled={isBrandHouse} name={"stockLocation"} control={control}
                                       label={"Location"}/>
                        {!isBrandHouse &&
                           <FormInputCheckbox name={"missingB"} label={"Item missing"}
                                              sx={{paddingTop: 0, paddingBottom: 0}}/>
                        }
                     </Grid>
                     <Grid item xs={6}>
                        <FormInputText name={"ean"} control={control} disabled label={"Warehouse EAN"}/>
                     </Grid>

                     {!isBrandHouse && <Grid item xs={12}>
                        <Barcode includeCheck={false} scale={2} id={1} height={5} bcid={'ean13'} text={data.ean || ''}/>
                     </Grid>}

                     {!isBrandHouse && <Grid item xs={12}>
                        <FormInputText name={"note"} control={control} label={"Warehouse note"}/>
                     </Grid>}

                     {!isBrandHouse && <Grid item xs={12}>
                        <FormInputText name={"nfcTag"} control={control} label={"NFC Tag"}/>
                     </Grid>}

                     <Grid item xs={12}>
                        <FormInputSelectMultiple name={"seasonIds"} label={"Seasons"} options={seasons.map(season => {
                           return {
                              id: season._id!,
                              label: season.name!
                           }
                        })}/>
                     </Grid>
                  </Grid>
               </PageContent>
               {!isBrandHouse && <PageContent>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                     <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                        <TitleText type={"h2"}>Statistics</TitleText>
                     </Grid>
                     {!data.tradeInItemId && <>
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           Source <div>Created {DateFormat.toPresent(data.createdAt)}</div>
                        </Grid>
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           Registration
                           <div>{data.itemAge} days</div>
                        </Grid>
                     </>}
                     {data.tradeInItemId && <>
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           {/* eslint-disable-next-line */}
                           Source <a href={"#"} onClick={doNavigate('tradein', data.tradeInItem?.tradeInId)}>TradeIn</a>
                        </Grid>
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           {/* eslint-disable-next-line */}
                           Previous owner <a href={"#"}
                                             onClick={doNavigate('consumer', data.tradeInItem?.tradeIn?.consumerId)}>{data.tradeInItem?.tradeIn?.consumer?.name}</a>
                        </Grid>
                        {data.tradeInItem &&
                           <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                              <div title={'Estimated voucher value'}>Voucher <InfoIcon
                                 style={{color: 'blue', fontSize: '14px'}}/></div>
                              <div>{Math.round(data.tradeInItem?.value || 0)} {data.shop?.currency}</div>
                           </Grid>}
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           Item age <div>{data.itemAge} days</div>
                        </Grid>
                     </>}
                     {data.orderlines &&
                        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                           Orders <div>{data.orderlines?.map(order => {
                           return <div key={'stock_order_' + order?.order?._id}>
                              {/* eslint-disable-next-line */}
                              <a href={"#"}
                                 onClick={doNavigate('order', order?.order?._id)}>{order?.order?.orderName}</a> - {DateFormat.toPresent(order?.order?.orderDate)}
                           </div>
                        })} </div>
                        </Grid>}
                  </Grid>
               </PageContent>}
               {!isBrandHouse && <PageContent>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                     <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                        <TitleText type={"h2"}>Issues</TitleText>
                     </Grid>
                     <Grid item xs={12} style={{marginTop: '10px'}}>
                        <StockItemProblemsForm/>
                     </Grid>
                  </Grid>
               </PageContent>}
            </PageGroup>
         </PageGroup>

         <Dialog open={showTranslations} fullWidth maxWidth={"lg"} onClose={() => setShowTranslations(false)}>
            <DialogTitle>Localization editor</DialogTitle>
            <DialogContent>
               <LocalizationEditor primaryLocale={primaryLocale} locales={locales} fields={[{
                  title: 'Title',
                  field: 'title',
                  multiline: false
               }, {
                  title: 'Description',
                  field: 'description',
                  multiline: true
               }, {
                  title: 'Color',
                  field: 'color',
                  multiline: false
               }]}/>
            </DialogContent>
            <DialogActions>
               <Button variant={"contained"} onClick={() => setShowTranslations(false)}>Update</Button>
            </DialogActions>
         </Dialog>
      </form>
   </FormProvider>
}

const useStyles = makeStyles({
   titleAdornment: {
      backgroundColor: '#f0f0f0',
      padding: '21px 7px 4px 14px',
      marginLeft: '-12px',
      marginRight: '7px',
      minWidth: '100px'
   },
   tagsContainer: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '5px'
   },
   tagsLabel: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '14px'
   },
   chip: {
      marginRight: '2px'
   }
});

type StockItemInputProps = StockItemFragment & {
   productStatus: ProductStatus
   missingB: boolean
}


export const getStockItemInput = (data: StockItemInputProps) => {
   let missingDate: Date | null = null;

   if (data.missingB) {
      if (!data.effectiveMissing) {
         missingDate = new Date();
      } else {
         missingDate = data.missing || null
      }
   } else {
      missingDate = null
   }

   return {
      _id: data._id,
      title: data.title,
      titleLocalized: data.titleLocalized,
      description: data.description,
      descriptionLocalized: data.descriptionLocalized,

      color: data.color,
      colorLocalized: data.colorLocalized,

      size: data.size,

      sku: data.sku,
      ean: data.ean,
      originalEan: data.originalEan,

      condition: data.condition,
      sellingPrice: data.sellingPrice,
      beforePrice: data.beforePrice,

      productType: data.productType,
      vendor: data.vendor,

      stockLocation: data.stockLocation,
      checked: data.checked,
      missing: missingDate,

      imageIds: data.imageIds,

      itemTags: data.itemTags,

      problems: data.problems?.filter(prob => !!prob?.description),

      draft: data.productStatus === 'draft',
      published: data.productStatus === 'sale',
      review: data.productStatus === 'review',

      note: data.note,

      nfcTag: data.nfcTag,
      seasonIds: data.seasonIds
   } as StockItemUpdateInput;
}
