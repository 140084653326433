import {useMessageDetailPageGetQuery, useUpdateMessageMutation} from "../../Queries";
import {Box, Button, LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import * as React from "react";


type MessageDetailPanelProps = {
   messageId: string
}

export const MessageDetailPanel = ({messageId}: MessageDetailPanelProps) => {
   const {data} = useMessageDetailPageGetQuery({
      variables: {
         messageId: messageId
      },
      pollInterval: 5000,
      fetchPolicy: "no-cache"
   });
   const [update] = useUpdateMessageMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const msg = data.IntegrationMessageById;
   if (!msg) {
      return <Box>No details found</Box>
   }

   const resend = async () => {
      await update({
         variables: {
            messageId: messageId,
            record: {
               sent: null,
               acknowledged: null,
               failed: null,
               result: null,
               resultTs: null
            }
         }
      })
   }

   const owner = msg.refShop?.name || msg.refLogisticsPartner?.name || msg.refBrandHouse?.name || 'Unknown'
   const instance = msg.instance?.name || 'Unknown integration'

   return <Box>
      <Box>
         <TitleText type={"h2"}>
            {owner} sent message {msg.message as string} to {instance} connector
         </TitleText>
         <BodyText type={"subtitle2"}>{msg._id}</BodyText>
         <Button variant={"text"} onClick={resend}>Resend</Button>
      </Box>

      <Box sx={{marginTop: '10px'}}>
         <BodyText type={"body1"}>
            Request payload
         </BodyText>
      </Box>
      <AceEditor
         height={"300px"}
         mode="json"
         readOnly={true}
         theme="tomorrow"
         value={JSON.stringify(msg.payload, null, 2)}
         name="jsonEditor"
         editorProps={{$blockScrolling: true}}
         placeholder={"Message data"}
         width={"100%"}
      />


      <Box sx={{marginTop: '10px'}}>
         <BodyText type={"body1"}>
            Response details:
         </BodyText>
      </Box>
      <AceEditor
         height={"300px"}
         mode="json"
         readOnly={true}
         theme="tomorrow"
         value={JSON.stringify(msg.result, null, 2)}
         name="jsonEditor"
         editorProps={{$blockScrolling: true}}
         placeholder={"Message data"}
         width={"100%"}
      />
   </Box>
}