/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react"
import {FC, useEffect} from "react"
import {ActionType, Form, QActionResult, QElement} from "./Model";
import {QRegistry} from "./QRegistry";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {LinearProgress} from "@mui/material";
import {QPubSub} from "./QPubSub";
import {cloneDeep} from "lodash";

export interface UploadServiceType {
   upload: (file: File, onUploadProgress?: (event: { loaded: number, total?: number }) => void) => Promise<any>
   url: () => string
}

export type QuickFormConfig = {
   uploadService?: UploadServiceType
}

export type QuickFormProps = {
   form: Form
   data: any,
   onButton: (type: ActionType, data: any) => Promise<QActionResult>,
   config?: QuickFormConfig
}

type QuickFormState = {
   [expression: string]: boolean
}

let nextId = new Date().getTime();
let formMap = new Map<string, Form>();

export const createForm = (form: Form): Form => {
   let key = JSON.stringify(form, undefined, '');
   let existingForm = formMap.get(key);
   if (existingForm) {
      return existingForm;
   } else {
      const recursive = (element: QElement): void => {
         if (!element.id) {
            element.id = '#autoid_' + (nextId++);
         }
         if (element.children) {
            for (const child of element.children) {
               recursive(child);
            }
         }
      }
      let clonedForm = cloneDeep(form);
      form.id = 'form_' + (nextId++);
      recursive(clonedForm.layout);
      formMap.set(key, clonedForm);
      return clonedForm;
   }
}

type FormStateType = {
   [formId: string]: QuickFormState
}
const formState: FormStateType = {}

export const QuickForm: FC<QuickFormProps> = ({form, data, config, onButton}) => {
   const [formData, setFormData] = React.useState<any | undefined>();
   const [hasErrors, setHasErrors] = React.useState<boolean>(false);
   const classes = useStyles();

   useEffect(() => {
      if (data) {
         setFormData(cloneDeep(data));
      }
   }, [data])

   useEffect(() => {
      let removeHandle = QPubSub.on('validate', (data: any) => {
         let states = formState[form.id || 'unknown'] = formState[form.id || 'unknown'] || [];
         states[data.expression] = data.error;
         setHasErrors(Object.keys(states).filter(key => states[key]).length > 0);
      });
      return () => removeHandle();
   }, [])

   if (!formData) {
      return <LinearProgress/>
   }

   const onUpdate = (data: any) => {
      setFormData(data);
   }

   /**
    * Determines whether we have one or more input elements that are required, but unfulfilled
    */
   const hasMissingData = () => {
      const recursiveHasMissing = (element: QElement) => {
         if (element.props?.required) {
            let qRegistryElement = QRegistry[element.type];
            if (qRegistryElement.valid) {
               if (!qRegistryElement.valid(element, formData)) {
                  return true;
               }
            }
         }
         if (element.children) {
            for (const child of element.children) {
               if (recursiveHasMissing(child)) {
                  return true;
               }
            }
         }
         return false;
      }
      return recursiveHasMissing(form.layout);
   }

   const onAction = async (type: 'save' | 'delete') => {
      if (type === "save") {
         if (hasMissingData()) {
            return {
               success: false,
               message: 'Please fill in the missing data'
            }
         } else if (hasErrors) {
            return {
               success: false,
               message: 'Please correct the errors'
            }
         } else {
            return await onButton(type, formData);
         }
      } else {
         return await onButton(type, formData);
      }
   }

   const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
   }

   return <form className={classes.root} noValidate onSubmit={onSubmit}>
      <RenderElement
         element={form.layout}
         data={formData}
         onUpdate={onUpdate}
         config={config}
         onAction={onAction}
         hasErrors={hasErrors}
      />
   </form>
}

type RenderElementProps = {
   element: QElement
   data: any
   config?: QuickFormConfig
   onUpdate: (data: any) => void
   onAction: (type: 'save' | 'delete') => Promise<QActionResult>
   hasErrors: boolean
}


const RenderElement: FC<RenderElementProps> = ({element, data, config, onUpdate, onAction, hasErrors}) => {
   let childComponents = element.children;
   let currentComponent = QRegistry[element.type];
   if (!currentComponent) {
      return <div>Unsupported component : {element.type}</div>
   }
   return <currentComponent.component
      hasErrors={hasErrors}
      data={data} action={onAction} update={onUpdate} config={config} {...element.props}
   >
      {childComponents && childComponents.map(childComponent => {
         return <RenderElement key={childComponent.id} config={config} onUpdate={onUpdate} onAction={onAction}
                               element={childComponent}
                               hasErrors={hasErrors}
                               data={data}
         />
      })}
   </currentComponent.component>
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         marginBottom: 15,
         userSelect: 'none'
      },
      margin: {
         margin: theme.spacing(1),
      },
   }),
);
