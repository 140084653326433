import * as React from "react";
import {FC, useEffect} from "react";
import {useRecoilState} from "recoil";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {CurrentUserState, getFromStorage, saveToStorage, UserInfo} from "./atoms/CurrentUser";
import {PubSub} from "./components/PubSub";
import useUserRefresh from "./hooks/useUserRefresh";
import {Theme} from "@mui/material";
import {useUserLoginMutation} from "./Queries";
import {useNavigate} from "react-router";

type UserAccessControllerProps = {}

export const AccessController: FC<UserAccessControllerProps> = ({children}) => {
   const [curUser, setCurUser] = useRecoilState(CurrentUserState);
   const [reload, setReload] = React.useState<boolean>(false);
   const [error, setError] = React.useState<string | null>(null);
   const [expired] = React.useState<boolean>(false);
   const [userLogin] = useUserLoginMutation();
   const userRefresh = useUserRefresh();

   useEffect(() => {
      PubSub.on('logout', () => {
         if (curUser !== null) {
            if (curUser.brandhouse) {
               saveToStorage(null);
               setReload(true);
            } else {
               setCurUser(null);
               window.location.assign("/");
            }
         } else {
            window.location.assign("/");
         }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (curUser === null) {
         let userFromStorage = getFromStorage();
         if (userFromStorage) {
            (async function () {
               if (!userFromStorage.brandhouse) {
                  let refreshedToken;
                  try {
                     refreshedToken = await userRefresh(userFromStorage.token);
                     if (refreshedToken && refreshedToken.token) {
                        let updatedUser = {
                           ...userFromStorage,
                           token: refreshedToken.token
                        };
                        setCurUser(updatedUser);
                     } else {
                        setCurUser(null);
                     }
                  } catch (e) {
                     setCurUser(null);
                  }
               } else {
                  setCurUser(userFromStorage);
               }
            })();
         }
      }
   }, [curUser, setCurUser, userRefresh])

   useEffect(() => {
      saveToStorage(curUser);
   }, [curUser])

   const onLogin = async (username: string, password: string) => {
      try {
         let {data} = await userLogin({
            variables: {
               email: username,
               password
            }
         });
         let newUser = {
            token: data!.UserLogin!.token,
            brandhouse: false,
            userType: data!.UserLogin!.userType,
            userEmail: username,
            simplifiedMode: data!.UserLogin!.simplified || false
         } as UserInfo;
         setCurUser(newUser);
         saveToStorage(newUser);
      } catch (err) {
         if (err instanceof Error) {
            if(err.toString() === 'Error: Network Error') {
               setError("Network error, are you connected?")
            } else {
               setError("Invalid credentials, please correct and try again");
            }
         } else {
            setError(String(err))
         }
      }
   }

   if (reload) {
      return <div>You have been logged out. Please reload your browser</div>
   }

   if (curUser === null) {
      return <AuthorizeDialog onLogin={onLogin} error={error || undefined} expired={expired}/>
   }

   return <>
      {children}
   </>
}

type AuthorizeDialogProps = {
   onLogin: (username: string, password: string) => void;
   error?: string;
   expired: boolean;
}

const AuthorizeDialog = (props: AuthorizeDialogProps) => {
   const classes = useStyles();
   const [username, setUsername] = React.useState<string>('');
   const [password, setPassword] = React.useState<string>('');
   const navigate = useNavigate();

   const onSubmit = () => {
      props.onLogin(username, password);
      return false;
   }

   const onForgot = (e:any) => {
      e.preventDefault();
      e.stopPropagation();
      navigate('/reset')
   }

   return <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline/>
      <div className={classes.paper}>
         <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
         </Avatar>
         <Typography component="h1" variant="h5">
            Sign in
         </Typography>
         <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               id="email"
               error={!!props.error && true}
               label="Email Address"
               name="email"
               autoComplete="email"
               autoFocus
               onChange={(e) => {
                  setUsername(e.target.value)
               }}
            />
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               name="password"
               label="Password"
               type="password"
               id="password"
               error={!!props.error && true}
               helperText={props.error ? props.error : undefined}
               autoComplete="current-password"
               onChange={(e) => {
                  setPassword(e.target.value)
               }}
            />
            <FormControlLabel
               control={<Checkbox value="remember" color="primary"/>}
               label="Remember me"
            />
            <Button
               type="submit"
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
               onClick={onSubmit}
            >
               Sign In
            </Button>
            <Grid container>
               <Grid item xs>
                  <Link href={"#"} onClick={onForgot} variant="body2">
                     Forgot password?
                  </Link>
               </Grid>
            </Grid>
         </form>
      </div>
   </Container>
}

const useStyles = makeStyles((theme: Theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px'
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   container: {
      backgroundColor: 'white',
      padding: '0px 20px 20px',
      marginTop: '20px'
   }
}));
