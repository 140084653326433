/* eslint-disable react-hooks/exhaustive-deps */
import {KpiCalculationsQuery, useKpiBrandsQuery, useKpiCalculationsQuery} from "../../Queries";
import {LinearProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {TitleText} from "../../layout/Typography";
import {Info} from "@mui/icons-material";

type KPIColumn = {
   fromMs: number
   toMs: number
   title: string
}


export const StatKPITable = () => {
   const [columns, setColumns] = useState<KPIColumn[]>([])
   const {data} = useKpiBrandsQuery();

   const systemStartDate = dayjs.utc('2021-06-01T00:00:00.000');

   useEffect(() => {
      const toDate = dayjs();

      let current = systemStartDate.clone();

      const newColumns: KPIColumn[] = [];
      while (current < toDate) {
         const next = current.add(1, "month");
         newColumns.push({
            fromMs: current.valueOf(),
            toMs: next.valueOf(),
            title: current.format('MMM-YY')
         })
         current = next;
      }
      setColumns(newColumns.reverse());
      //@ts-ignore
   }, [])

   if (!data) {
      return <LinearProgress/>
   }

   return <Table sx={{width: '100%'}}>
      <TableHead>
         <TableRow>
            <TableCell/>
            {columns.map(column => {
               return <TableCell sx={{
                  textAlign: 'right',
                  padding: '15px 0 15px 0',
                  whiteSpace: 'nowrap',
                  transformOrigin: '50% 50%',
                  transform: 'rotate(-90deg)'
               }}>{column.title}</TableCell>
            })}
         </TableRow>
      </TableHead>
      <TableBody>
         <StatRow columns={columns} fromDate={systemStartDate.toDate()} endDate={new Date()} name={"All live shops"}
                  shopIds={[]}/>
         {data?.BrandHouses?.map(bh => {
            let fromDate: Date | undefined;
            let endDate: Date | undefined;
            for (const shop of bh.shops || []) {
               if (shop?.liveDate && !fromDate) {
                  fromDate = shop.liveDate;
               }
               if (shop?.closeDate && !endDate) {
                  endDate = shop.closeDate
               }

               if (shop?.liveDate && fromDate) {
                  if (shop.liveDate < fromDate) {
                     fromDate = shop.liveDate;
                  }
               }
               if (shop?.closeDate && endDate) {
                  if (shop.closeDate > endDate) {
                     endDate = shop.closeDate;
                  }
               }
            }
            if (!endDate) {
               endDate = new Date();
            }
            if (!fromDate) {
               return undefined
            }

            const liveShopIds = bh.shops?.filter(shop => shop?.live).map(shop => shop!._id) || []
            if (liveShopIds.length === 0) {
               return undefined
            }

            return <StatRow columns={columns} fromDate={new Date(fromDate)} endDate={new Date(endDate)} name={bh.name!}
                            shopIds={liveShopIds}/>
         })}
      </TableBody>
   </Table>
}

type StatRowProps = {
   columns: KPIColumn[]
   fromDate: Date
   endDate: Date
   shopIds: string[]
   name: string
}

type KPIScoreKPI = {
   score: number
   total: number
   current: number
   decimals: number
};

type KPIScore = {
   col: KPIColumn
   scores?: KPIScoreKPI
};
type KPIResult = {
   title: string
   maxValue?: number
   minValue?: number
   info?: string
   goal?: number
   kpis: KPIScore[]
}

function calculateKpi(columns: KPIColumn[], data: KpiCalculationsQuery, totalKpi: string, currentKpi: string, title: string, percentage: boolean, goal?: number, info?: string) {
   const kpiScores: KPIScore[] = [];
   for (const col of columns) {
      const kpiScore: KPIScore = {
         col
      }

      const totalOrder = data?.StatisticsCalculate?.results?.find(res => res!.kpi === totalKpi && res!.split === col.title)
      const splitOrder = data?.StatisticsCalculate?.results?.find(res => res!.kpi === currentKpi && res!.split === col.title)

      let orderPercent: number | undefined = undefined;
      if (splitOrder?.value && totalOrder?.value) {
         orderPercent = (splitOrder.value * 10 * (percentage ? 100 : 1)) / totalOrder.value
      }
      if (orderPercent) {
         kpiScore.scores = {
            total: totalOrder!.value,
            current: splitOrder!.value,
            score: Math.round(orderPercent!) / 10,
            decimals: 1
         }
      }
      kpiScores.push(kpiScore)
   }

   const allScores = kpiScores.filter(kpi => kpi.scores).map(kpi => kpi.scores!.score!);
   const max = Math.max(...allScores)
   const min = Math.min(...allScores)

   const result: KPIResult = {
      kpis: kpiScores,
      maxValue: max,
      minValue: min,
      info: info,
      goal,
      title: title
   }
   return result;
}

function calculateSimple(columns: KPIColumn[], data: KpiCalculationsQuery, kpi: string, title: string, percentage: boolean, goal?: number, info?: string) {
   const kpiScores: KPIScore[] = [];
   for (const col of columns) {
      const kpiScore: KPIScore = {
         col
      }

      const kpiCell = data?.StatisticsCalculate?.results?.find(res => res!.kpi === kpi && res!.split === col.title)

      if (kpiCell?.value) {
         kpiScore.scores = {
            total: kpiCell.value,
            current: kpiCell.value,
            score: kpiCell.value,
            decimals: 0
         }
      }
      kpiScores.push(kpiScore)
   }

   const allScores = kpiScores.filter(kpi => kpi.scores).map(kpi => kpi.scores!.score!);
   const max = Math.max(...allScores)
   const min = Math.min(...allScores)

   const result: KPIResult = {
      kpis: kpiScores,
      maxValue: max,
      minValue: min,
      info: info,
      goal,
      title: title
   }
   return result;
}


export const StatRow = ({columns, fromDate, endDate, name, shopIds}: StatRowProps) => {
   const [cached, setCached] = useState<KPIResult[]>([])
   const {data} = useKpiCalculationsQuery({
      variables: {
         from: fromDate.toISOString(),
         to: endDate.toISOString(),
         shopIds: shopIds,
         filter: "true",
         splits: columns.map(col => {
            return {
               id: col.title,
               filter: "dateMs >= " + col.fromMs + " && dateMs < " + col.toMs
            }
         })
      }
   })

   useEffect(() => {
      if (data) {
         const newResults: KPIResult[] = []
         newResults.push(calculateKpi(columns, data, 'orderTotal', 'orderC2s', 'Order %', true, 2.0));
         newResults.push(calculateKpi(columns, data, 'orderC2s', 'orderSplit', 'Splitorder %', true));
         newResults.push(calculateKpi(columns, data, 'orderC2s', 'orderItemsC2SCount', 'Basketsize', false, undefined, 'Number of secondhand items in an order'));
         newResults.push(calculateSimple(columns, data, 'orderC2s', 'Orders', false, undefined, 'Secondhand orders'));
         newResults.push(calculateSimple(columns, data, 'orderItemsC2SCount', 'Orderlines', false, undefined, 'Secondhand orderlines'));
         newResults.push(calculateSimple(columns, data, 'orderItemsC2SReturned', 'Orderlines returned', false, undefined, 'Secondhand orderlines returned'));
         newResults.push(calculateKpi(columns, data, 'orderItemsC2SCount', 'orderItemsC2SReturned', 'Orderlines returned %', true, undefined, 'Percentage of returned secondhand items'));
         newResults.push(calculateSimple(columns, data, 'tradeInTotal', 'Tradeins', false, undefined, 'Number of received tradeins'));
         newResults.push(calculateSimple(columns, data, 'tradeInItemsReceived', 'Tradein items', false, undefined, 'Number of received tradein items'));
         newResults.push(calculateSimple(columns, data, 'stockitemsForSale', 'Items for sale', false, undefined, 'Approximate number of items for sale in period'));
         newResults.push(calculateSimple(columns, data, 'voucherRegisteredActive', 'Vouchers', false, undefined, 'Number of vouchers where the tradein was received'));
         newResults.push(calculateSimple(columns, data, 'voucherRegisteredUsed', 'Vouchers Used', false, undefined, 'Number of vouchers used, where the tradein was received'));
         newResults.push(calculateKpi(columns, data, 'voucherRegisteredActive', 'voucherRegisteredUsed', 'Voucher usage %', true, undefined, 'How many vouchers are used'));
         setCached(newResults)
      }
   }, [columns, data]);

   if (cached.length === 0) {
      return <LinearProgress/>
   }

   return <><TableRow>
      <TableCell colSpan={columns.length + 1}><TitleText type={"h2"}>{name}</TitleText></TableCell>
   </TableRow>
      {cached.map(cache => {
         let title: string = '';
         if (cache.goal || cache.info) {
            if (cache.info) {
               title = cache.info
            } else {
               title = 'The goal is > ' + cache.goal
            }
         }
         return <TableRow
         >
            <TableCell sx={{whiteSpace: 'nowrap', padding: '0px 20px 0px 50px', borderRight: '1px solid #e0e0e0'}}>
               {cache.title}
               {title && <span title={title}>
                  <Info sx={{marginTop: '2px', marginLeft: '10px', fontSize: '14px', color: "#d0d0d0"}}/>
               </span>}
            </TableCell>
            {cache.kpis.map((current, i) => {
               //const range = ((cache.maxValue || 0) - (cache.minValue || 0)) || 1
               //const color = calculateColor(kpi.scores.orderPercent, previous?.scores.orderPercent, range)

               let color: string | undefined;
               if (current?.scores && cache.goal) {
                  if (current.scores!.score > cache.goal) {
                     color = '#C6EFCE'
                  } else if (current.scores.score === cache.goal) {
                     color = '#FFEB9C'
                  } else {
                     color = '#FFC7CE'
                  }
               }
               return <TableCell sx={{
                  borderRight: '1px solid #e0e0e0',
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  padding: '0 10px 0 0',
                  backgroundColor: color
               }}>
                  {current.scores?.score?.toFixed((current.scores?.decimals !== undefined ? current.scores?.decimals : 1))}
               </TableCell>
            })
            }</TableRow>
      })}
   </>
}
/*

type RGB = {
   r: number
   g: number
   b: number
}

const gradient = {
   negative: {
      r: 64,
      g: 192,
      b: 64
   } as RGB,
   positive: {
      r: 64,
      g: 255,
      b: 64
   } as RGB,
   neutral: {
      r: 255,
      g: 255,
      b: 255
   } as RGB
}


const calculateColor = (current: number | undefined, previous: number | undefined, range: number): string | undefined => {
   if (!current) {
      return undefined;
   }
   if (!previous) {
      return rgbToHex(gradient.neutral);
   } else if (previous > current) {
      // Negative interpolation
      const percentage = (previous - current) / range
      const color = colorInterpolate(gradient.negative, gradient.positive, percentage);
      return rgbToHex(color);
   } else {
      // Positive interpolation
      const percentage = (current - previous) / range
      const color = colorInterpolate(gradient.negative, gradient.positive, percentage);
      return rgbToHex(color);
   }
}

function colorInterpolate(rgbA: RGB, rgbB: RGB, intval: number) {
   return {
      r: Math.round(rgbA.r + intval * (rgbB.r - rgbA.r)),
      g: Math.round(rgbA.g + intval * (rgbB.g - rgbA.g)),
      b: Math.round(rgbA.b + intval * (rgbB.b - rgbA.b)),
   } as RGB
}

function valueToHex(c: number) {
   let s = c.toString(16);
   if (s.length !== 2) {
      return "0" + s
   } else {
      return s
   }
}

function rgbToHex(rgb: RGB) {
   let s = valueToHex(rgb.r) + valueToHex(rgb.g) + valueToHex(rgb.b);
   console.log('RGB', rgb, s)
   return s;
}
*/
