import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'
import {TextFormData} from "./TradeInAppTexts";

const NAME_AREA = 'Area';
const NAME_LOCALIZATION_KEY = 'TextKey';

export const ExportToExcel = async (languages: string[], data: TextFormData[]) => {
   const objects = data.map(dat => {
      return dat.data.map(d => {
         const object: any = {
            area: dat.header.title,
            key: d.key
         };
         for (const language of languages) {
            object[language] = d.values[language].text
         }
         return object
      });
   }).flat();

   // @ts-ignore
   const schema = [{
      column: NAME_AREA,
      type: String,
      value: (object: any) => object.area
   }, {
      column: NAME_LOCALIZATION_KEY,
      type: String,
      value: (object: any) => object.key
   }];

   for (const language of languages) {
      schema.push({
         column: language,
         type: String,
         value: (object: any) => object[language]
      })
   }

   await writeXlsxFile(objects, {
      schema,
      fileName: 'translations-' + Date.now() + '.xlsx'
   })
}


export const ImportFromExcel = async (file: File): Promise<TextFormData[]> => {
   const rows = await readXlsxFile(file);

   const languages: string[] = [];
   const result: TextFormData[] = [];

   for (let row = 0; row < rows.length; row++) {
      const cells = rows[row];
      if (row === 0) {
         //
         // Header row
         //
         for (let i = 0; i < cells.length; i++) {
            if (i === 0) {
               if (cells[i] !== NAME_AREA) throw new Error('File is missing area column')
            } else if (i === 1) {
               if (cells[i] !== NAME_LOCALIZATION_KEY) throw new Error('File is missing localization key column')
            } else {
               let cellAsText = cells[i] as string;
               if (cellAsText) {
                  languages.push(cellAsText);
               }
            }
         }
      } else {
         //
         // Data cells
         //

         // Find header
         let formData = result.find(res => res.header.title===cells[0])
         if(!formData) {
            formData = {
               header: {
                  title: cells[0] as string,
                  description: ''
               },
               data: []
            }
            result.push(formData);
         }

         // Find key
         let dataKey = formData.data.find(dat => dat.key===cells[1] as string);
         if(!dataKey) {
            dataKey = {
               key: cells[1] as string,
               values: {},
               defaults: null
            }
            formData.data.push(dataKey);
         }

         // Add languages
         for (let i = 0; i < languages.length; i++) {
            const language = languages[i];
            const translation = cells[2 + i] as string
            dataKey.values[language] = {
               text: translation,
               default: null,
               inherited: false
            }
         }
      }
   }
   return result;
}