import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, TextField, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {merge} from "lodash";

export type FormInputTextProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   label: string;
   errorMessage?: string

   /**
    * @deprecated now using formcontext
    */
   control?: any
}

export const FormInputText = ({name, label, error, errorMessage, ...rest}: FormInputTextProps) => {
   const {style, InputProps} = rest;
   const classes = useStyles();

   const methods = useFormContext();
   const {control, formState: {errors}} = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   const newProps = merge(InputProps, {
      disableUnderline: true,
      style: {backgroundColor: 'transparent'}
   });

   return (
      <div className={classes.noBorderRadius}>
         <Controller
            name={name}
            control={control}
            render={({
                        field: {onChange, value},
                        fieldState: {error}
                     }) => (
               <TextField
                  id={name}
                  size="small"
                  fullWidth
                  label={label}
                  variant="filled"
                  value={value || undefined}
                  error={showError}
                  onChange={onChange}
                  margin={"none"}
                  InputLabelProps={{
                     shrink: true,
                  }}
                  InputProps={newProps}
                  style={newStyle}
                  {...rest}
               />
            )}/>
         {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
      </div>
   );
};

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});
