import {SeasonsPagePath} from "./SeasonsPage";
import {useParams} from "react-router-dom";
import {
   EnumSeasonAutoRulesDataType,
   EnumSeasonAutoRulesOperation,
   EnumSeasonPeriodRepeat,
   SeasonAutoRulesInput,
   SeasonsEditFragment,
   SeasonsListDocument,
   useSeasonsEditQuery,
   useSeasonsEditUpdateMutation
} from "../../../Queries";
import Page from "../../../layout/Page";
import PageContent from "../../../layout/PageContent";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Box, Grid, IconButton, LinearProgress} from "@mui/material";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import Button from "@mui/material/Button";
import {SavingUI} from "../../../SavingProvider";
import React, {useEffect} from "react";
import {FormInputDate} from "../../shop/wholesale/form/FormInputDate";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import PageContentTabs, {PageTab} from "../../../layout/PageContentTabs";
import {BrandItemsList} from "../BrandItemsList";
import {ParamBrandHouse} from "../../../Parameters";
import FormInputAutocomplete2 from "../../shop/wholesale/form/FormInputAutocomplete2";
import {DeleteForever} from "@mui/icons-material";
import {SeasonExpressionType} from "./SeasonExpressionType";

export const SeasonsEditPagePath = (brandHouseId: string, seasonId: string) => {
   return SeasonsPagePath(brandHouseId) + '/' + seasonId
}

export const SeasonsEditPage = () => {
   const {brandHouseId, seasonId} = useParams<ParamBrandHouse & { seasonId: string }>();
   const {data} = useSeasonsEditQuery({
      variables: {
         id: seasonId
      }
   })
   const [save] = useSeasonsEditUpdateMutation()
   const form = useForm<SeasonsEditFragment>();
   const {control, handleSubmit} = form;

   useEffect(() => {
      if (data) {
         const {autoRules, ...rest} = data.Season as SeasonsEditFragment
         form.reset({
            autoRules: autoRules||[],
            ...rest
         })
      }
   }, [data, form])

   const {fields, append, remove} = useFieldArray<SeasonsEditFragment>({
      control,
      name: 'autoRules'
   })

   if (!data) {
      return <LinearProgress/>
   }
   const onSave = async (data: SeasonsEditFragment) => {
      await SavingUI.process(async () => {
         const {_id, __typename, ...item} = data;

         await save({
            variables: {
               id: seasonId,
               input: item
            },
            refetchQueries: [{
               query: SeasonsListDocument
            }]
         })
      }, 'Saved season')
   }

   const tabs: PageTab[] = [{
      label: "Season"
   }, {
      label: "Items"
   }]

   const renderContent = (tab: string) => {
      if (tab === "Season") {
         return <PageContent>
            <FormProvider {...form}>
               <FormInputText name={"name"} label={"Name"}/>
               <FormInputDate name={"period.from"} label={"Active from"}/>
               <FormInputDate name={"period.to"} label={"Active to"}/>
               <FormInputSelect name={"period.repeat"} label={"Repeat"}
                                options={Object.values(EnumSeasonPeriodRepeat).map(value => {
                                   return {
                                      id: value,
                                      name: value
                                   }
                                })} required/>
               <FormInputCheckbox name={"active"} label={"Active"}/>

               {fields.map((field, index) => {
                  return <Grid spacing={"4px"} container key={field.id}>
                     <Grid item xs={2}>
                        <FormInputSelect disabled={true} name={`autoRules.${index}.dataType`} label={"Product"}
                                         options={Object.values(EnumSeasonAutoRulesDataType).map(valueType => ({
                                            id: valueType,
                                            name: valueType
                                         }))}/>
                     </Grid>
                     <Grid item xs={2}>
                        <FormInputSelect name={`autoRules.${index}.operation`} label={"Must"}
                                         options={Object.values(EnumSeasonAutoRulesOperation).map(valueType => ({
                                            id: valueType,
                                            name: valueType
                                         }))}/>
                     </Grid>
                     <Grid item xs={5}>
                        <FormInputAutocomplete2
                           fullWidth
                           options={[]}
                           name={`autoRules[${index}].values`}
                           label={"Any of:"}
                        />
                     </Grid>
                     <Grid item xs={1}>
                        <IconButton onClick={() => remove(index)}>
                           <DeleteForever/>
                        </IconButton>
                     </Grid>
                  </Grid>
               })}
               <SeasonExpressionType onSelect={(type) => {
                  append({
                     dataType: type as EnumSeasonAutoRulesDataType,
                     values: [],
                     operation: EnumSeasonAutoRulesOperation.CONTAINS
                  } as SeasonAutoRulesInput)
               }}/>

            </FormProvider>
         </PageContent>
      } else if (tab === "Items") {
         return <PageContent>
            <BrandItemsList headerOptions={{hide: true}}
                            brandHouseId={brandHouseId}
                            listId={"seasonItems"}
                            commands={[]}
                            filter={{
                               _operators: {
                                  seasonIds: {
                                     in: seasonId
                                  }
                               }
                            }}
            />
         </PageContent>
      } else {
         return <Box>Unknown tab</Box>
      }
   }

   return <Page>
      <PageContentTabs onBack tabs={tabs} render={renderContent} commands={
         <><Button variant={"contained"} onClick={handleSubmit(onSave)}>Save</Button></>
      }/>
   </Page>

}
