import {Button, LinearProgress} from "@mui/material";
import {DataColumn, DataTable} from "../../components/DataTable";
import * as React from "react";
import {Link, useParams} from "react-router-dom"
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import Page from "../../layout/Page";
import {Shop, useBrandHouseShopsQuery, useBrandsPageBrandHouseQuery} from "../../Queries";
import {Dialogs} from "../../DialogProvider";
import {useRecoilState, useRecoilValue} from "recoil";
import {CurrentUserIsAdmin, CurrentUserState, UserInfo} from "../../atoms/CurrentUser";
import {PubSub} from "../../components/PubSub";

export const BrandsPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId
}

export const ShopsPage = () => {
   const [curUser, setCurUser] = useRecoilState(CurrentUserState);
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const {brandHouseId} = useParams<{ brandHouseId: string }>();
   const {data: brands} = useBrandHouseShopsQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })
   const {data} = useBrandsPageBrandHouseQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   });

   if (!brands?.ShopMany || !data?.BrandHouse) {
      return <LinearProgress/>
   }

   const shops = brands?.ShopMany

   let columns: DataColumn<Shop>[] = [{
      field: 'name',
      label: 'Name',
      searchable: true,
      render: (data, row) => {
         return <Link to={"/brands/" + brandHouseId + '/' + row._id}>
            {data}
         </Link>
      }
   }, {
      field: 'externalId',
      label: 'External',
      searchable: true
   }, {
      field: 'currency',
      label: 'Currency',
   }, {
      field: 'live',
      label: 'Live',
      get: (row) => {
         return row.live ? 'Yes' : 'No'
      }
   }, {
      field: 'wholeSaleActive',
      label: 'WholeSale',
      get: (row) => {
         return row.wholeSaleActive ? 'Active' : '-'
      }
   }]

   return <Page>
      <PageHeader title={"Shops"}>
         {isAdministrator && <Button
            variant={"contained"}
            onClick={() => {
               setCurUser({
                  ...curUser,
                  brandMode: true,
               } as UserInfo)
               PubSub.emit('NavigationReload', brandHouseId);
               Dialogs.info({
                  title: "Showing " + data?.BrandHouse?.name,
                  subtitle: "All other brands are hidden, until you log out!"
               })
            }}
         >Login</Button>}
      </PageHeader>
      <PageContent fullHeight>
         <DataTable stateId={"brands"}
                    idField={"_id"}
                    selections={false}
                    columns={columns}
                    rows={shops}
                    minLineHeight={40}
                    commands={[]}/>
      </PageContent>
   </Page>
}