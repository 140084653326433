import * as React from "react";
import {FC, useCallback, useEffect} from "react";
import {DateRange, DateRangePicker} from '@mui/x-date-pickers-pro';
import '@mui/lab';
import {Box, Divider, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {debounce} from "lodash";
import dayjs from "dayjs";

const ONE_MINUTE = 1000 * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7

type PeriodSelectorProps = {
   defaultPeriod: any
   minDate?: Date
   onChange: (value: DateRange<Date>) => void;
}

export type PeriodType = '1W' | '1M' | '3M' | '6M' | 'YTD' | 'CUSTOM';

export const getDateRange = (period: PeriodType): DateRange<Date> => {
   // MaxDate is yesterday
   const maxDate = new Date();
   maxDate.setUTCDate(maxDate.getUTCDate() - 1)
   maxDate.setUTCHours(12, 0, 0, 0);

   if (period === '1W') {
      const from = new Date(maxDate);
      from.setTime(from.getTime() - ONE_WEEK)
      return [from, maxDate];
   } else if (period === '1M') {
      const from = new Date(maxDate);
      from.setMonth(from.getMonth() - 1)
      return [from, maxDate];
   } else if (period === '3M') {
      const from = new Date(maxDate);
      from.setMonth(from.getMonth() - 3)
      return [from, maxDate];
   } else if (period === '6M') {
      const from = new Date(maxDate);
      from.setMonth(from.getMonth() - 6)
      return [from, maxDate];
   } else if (period === 'YTD') {
      const from = new Date(maxDate);
      from.setUTCFullYear(from.getUTCFullYear(), 0, 1)
      return [from, maxDate];
   } else {
      throw new Error('Unsupported');
   }
}


export const PeriodSelector: FC<PeriodSelectorProps> = ({minDate, onChange, defaultPeriod}) => {
   const [period, setPeriod] = React.useState<string | undefined>()
   const [value, setValue] = React.useState<DateRange<Date>>(getDateRange('1W'));

   useEffect(() => {
      if (defaultPeriod) {
         onInternalChange(defaultPeriod);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [defaultPeriod])

   const onInternalChange = (period: PeriodType, newValue?: DateRange<Date>) => {
      setPeriod(period)
      if (period === 'CUSTOM' && newValue) {
         const [start, end] = newValue
         const systemStart = dayjs.utc('2021-01-01T00:00:00.000');

         const startUTC = dayjs.utc(start);
         const endUTC = dayjs.utc(end);
         if (startUTC.isValid() && startUTC.isAfter(systemStart)
            && endUTC.isValid() && endUTC.isBefore(dayjs())
            && startUTC.isBefore(endUTC)) {
            setValue(newValue);
            onChange(newValue);
         }
      } else {
         const dateRange = getDateRange(period);
         setValue(dateRange);
         onChange(dateRange);
      }
   }

   // eslint-disable-next-line
   const handleSearch = useCallback(
      debounce(onInternalChange, 400),
      []);

   let curMinDate: Date | undefined;
   if (minDate) {
      curMinDate = new Date(minDate);
      curMinDate.setUTCHours(12, 0, 0, 0);
   }

   let curMaxDate = new Date();

   return <Box sx={{display: 'flex'}}>
      <ToggleButtonGroup value={period}>
         <ToggleButton value={"1W"} onClick={() => {
            onInternalChange('1W')
         }}>1W</ToggleButton>
         <ToggleButton value={"1M"} onClick={() => {
            onInternalChange('1M')
         }}>1M</ToggleButton>
         <ToggleButton value={"3M"} onClick={() => {
            onInternalChange('3M')
         }}>3M</ToggleButton>
         <ToggleButton value={"6M"} onClick={() => {
            onInternalChange('6M')
         }}>6M</ToggleButton>
         <ToggleButton value={"YTD"} onClick={() => {
            onInternalChange('YTD')
         }}>YTD</ToggleButton>
      </ToggleButtonGroup>
      <Divider orientation={"vertical"} style={{marginLeft: '10px', marginRight: '10px'}}/>
      <DateRangePicker
         minDate={curMinDate}
         maxDate={curMaxDate}
         value={value}
         inputFormat={"dd-MM-yyyy"}
         onChange={(newValue) => {
            handleSearch('CUSTOM', newValue);
         }}
         renderInput={(startProps, endProps) => (
            <React.Fragment>
               <TextField {...startProps} />
               <Box sx={{mx: 2}}> - </Box>
               <TextField {...endProps} />
            </React.Fragment>
         )}
      />
   </Box>
}
