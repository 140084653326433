import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {MailFragment, useExportMailsQuery} from "../../../Queries";
import {BrickMailDefinition} from "./BrickMailType";

type ExportLine = {
   mailType: string
   language: string
   subject: string
   senderName: string
   senderMail: string
   bricks: string[]
}

export const ExportToExcel = async (data: ExportLine[]) => {
   // Unique list of mailType-language combinations
   const uniqueColumns = new Set<string>();
   for (const line of data) {
      uniqueColumns.add(line.mailType + '-' + line.language);
   }
   const columns = Array.from(uniqueColumns);

   const uniqueRows = new Set<string>();
   for (const line of data) {
      uniqueRows.add("subject")
      uniqueRows.add("senderName")
      uniqueRows.add("senderMail")
      for(let brickIndex = 1; brickIndex < line.bricks.length; brickIndex++) {
         uniqueRows.add("brick" + brickIndex)
      }
   }
   const rows = Array.from(uniqueRows);

   type DataCell = {
      column: string
      row: string
      value: string
   }
   const cells: DataCell[] = []
   for (const datum of data) {
      cells.push({
         column: datum.mailType + '-' + datum.language,
         row: 'subject',
         value: datum.subject
      })
      cells.push({
         column: datum.mailType + '-' + datum.language,
         row: 'senderName',
         value: datum.senderName
      })
      cells.push({
         column: datum.mailType + '-' + datum.language,
         row: 'senderMail',
         value: datum.senderMail
      })

      let brickIndex = 1;
      for (const brick of datum.bricks) {
         cells.push({
            column: datum.mailType + '-' + datum.language,
            row: "brick" + brickIndex,
            value: getTextContentOnly(brick)
         })
         brickIndex++;
      }
   }

   const schema: any = [{
      column: "KEY",
      type: String,
      value: (rowId: string) => rowId
   }]
   for (const column of columns) {
      schema.push({
         column: column,
         type: String,
         value: (rowId: string) => (
            cells.find(cell => cell.row === rowId && cell.column === column)?.value || ''
         )
      })
   }
   await writeXlsxFile(rows, {
      schema,
      fileName: 'items-' + Date.now() + '.xlsx'
   })
}

type MailTextExportProps = {
   shopId: string
}

export const MailTextExport = ({shopId}: MailTextExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportMailsQuery({
      variables: {
         shopId: shopId
      }
   });

   useEffect(() => {
      if (data?.BrickMails) {
         setOpen(true);
         (async function () {
            const exportLines: ExportLine[] = []
            const brickMails = ((data.BrickMails || []) as MailFragment[])
            for (const brickMail of brickMails) {
               if (!brickMail.active) {
                  continue
               }
               for (const mail of brickMail.mails || []) {
                  const mailDef = mail!.mail as BrickMailDefinition
                  if (!mailDef) {
                     continue;
                  }
                  exportLines.push({
                     subject: mailDef.subject,
                     senderMail: mailDef.from?.mail || '',
                     senderName: mailDef.from?.name || '',
                     language: mail!.language!,
                     mailType: brickMail!.type!,
                     bricks: mailDef.bricks.map(brick => {
                        return ((brick as any).value)
                     }).filter(val => !!val) || []
                  })
               }
            }
            await ExportToExcel(exportLines);
            setOpen(false);
         })();
      }
   }, [data])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}

const getTextContentOnly = (html: string) => {
   const doc = new DOMParser().parseFromString(html, 'text/html');
   const walker = document.createTreeWalker(
      doc.body,
      NodeFilter.SHOW_TEXT,
      null
   );
   const texts = [];
   let node = walker.nextNode()
   while (node) {
      texts.push(node.textContent);
      node = walker.nextNode()
   }
   return texts.join(' ').trim().replace(/\s\s+/g, ' ')
}
