import {FormProvider, useForm} from "react-hook-form"
import {FormInputText} from "../wholesale/form/FormInputText";
import Button from "@mui/material/Button";

export type Address = {
   company?: string | null
   name?: string | null

   add1?: string | null
   add2?: string | null
   zipCode?: string | null
   city?: string | null

   country?: string | null
   countryCode?: string | null

   mail?: string | null
   phone?: string | null
}

type AddressEditorProps = {
   address: Address
   onSave: (data: Address) => Promise<void>
}

export const AddressEditor = ({address, onSave}: AddressEditorProps) => {
   const form = useForm<Address>({
      defaultValues: address
   })
   const {handleSubmit} = form;

   const requiredText = "Field is required"

   return <FormProvider {...form}>
      <form>
         <FormInputText name="name" label={"Name"}
                        errorMessage={requiredText}/>
         <FormInputText name="company" label={"Company"}
                        errorMessage={requiredText}/>
         <FormInputText name="add1" label={"Address 1"}
                        errorMessage={requiredText}/>
         <FormInputText name="add2" label={"Address 2"}
                        errorMessage={requiredText}/>
         <FormInputText name="zipCode" label={"Zipcode"}
                        errorMessage={requiredText}/>
         <FormInputText name="city" label={"City"}
                        errorMessage={requiredText}/>

         <FormInputText name="countryCode" label={"Country Code (2 character ISO code)"}
                        errorMessage={requiredText}/>

         <FormInputText name="country" label={"Country name"}
                        errorMessage={requiredText}/>

         <FormInputText name="mail" label={"Email"}
                        errorMessage={requiredText}/>
         <FormInputText name="phone" label={"Mobiltelefon"}
                        errorMessage={requiredText}/>
      </form>
      <Button type={"submit"} onClick={handleSubmit(onSave)}>Save</Button>
   </FormProvider>
}