import * as React from "react";
import {FC, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Dialogs} from "../../../DialogProvider";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {WholeSalePagePath} from "./WholeSalePage";
import {
   UpdateOneWholeSaleCategoryInput,
   useWholeSaleCategoryEditBrandQuery,
   useWholeSaleEditCategoryDeleteMutation,
   useWholeSaleEditCategoryGetQuery,
   useWholeSaleEditCategoryUpdateMutation,
   useWholeSaleLocalesQuery,
   useWholeSaleStatisticsQuery,
   WholeSaleCategoriesDocument,
   WholeSaleCategoryFragment,
   WholeSaleEditCategoryExpressionFragment
} from "../../../Queries";
import {Control, FormProvider, useFieldArray, useForm, useFormContext, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';
import {FormInputText} from "./form/FormInputText";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton,
   ListItemIcon,
   Menu,
   MenuItem,
   Paper,
   Tab,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Tabs
} from "@mui/material";
import {FormInputImage} from "./form/FormInputImage";
import {BodyText, Link, TitleText} from "../../../layout/Typography";
import {FormInputSelect, SelectOption} from "./form/FormInputSelect";
import {FormInputCheckbox} from "./form/FormInputCheckbox";
import {Add, AddCircle, AllInbox, DeleteForever, Label, Language, TurnedIn, Visibility} from "@mui/icons-material";
import {FormInputNumber} from "./form/FormInputNumber";
import {FormInputAutocomplete} from "./form/FormInputAutocomplete";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {Thumbnails} from "../../../components/Thumbnails";
import {SaveButton} from "../../../components/SaveButton";
import {LocalizationEditor} from "../components/LocalizationEditor";
import {Dirty, DirtyDetector} from "../../../components/DirtyDetector";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";

export const WholeSaleEditPath = (brandHouseId: string, shopId: string, categoryId: string) => {
   return '/brands/' + brandHouseId + '/' + shopId + '/wholesale/' + categoryId
}

const voucherStrategyOptions: SelectOption[] = [{
   id: "INSTANT",
   name: "Instant"
}, {
   id: "HANDIN",
   name: "Hand-in"
}, {
   id: "RECEIVED",
   name: "Received"
}]

export const WholeSaleEditPage = (props: {}) => {
   const {
      brandHouseId,
      shopId,
      categoryId
   } = useParams<{ brandHouseId: string, shopId: string, categoryId: string }>();

   const mavigate = useNavigate();

   const {data} = useWholeSaleEditCategoryGetQuery({
      variables: {
         categoryId: categoryId
      }
   });

   const {data: shopLocales} = useWholeSaleLocalesQuery({
      variables: {
         shopId: shopId
      }
   })

   const [categoryUpdate] = useWholeSaleEditCategoryUpdateMutation()
   const [categoryDelete] = useWholeSaleEditCategoryDeleteMutation();

   if (!data || !shopLocales) {
      return <></>
   }

   const category = data.WholeSaleCategory as WholeSaleCategoryFragment;

   const onSave = async (data: UpdateOneWholeSaleCategoryInput) => {
      if (data.iconId === '') {
         data.iconId = null;
      }
      if (data.advanced) {
         if (data.rules?.length === 0) {
            data.advanced = false;
         }
      }
      await categoryUpdate({
         variables: {
            categoryId: categoryId,
            record: data
         }
      })
   }

   const onDelete = () => {
      Dialogs.confirm({
         title: "Are you sure you wish to delete this category?",
         subtitle: "If this category has been active and used, then your users could experience problems when trying to complete their category tradeins."
      }, async () => {
         await categoryDelete({
            variables: {
               shopId: shopId,
               categoryId: categoryId
            },
            refetchQueries: [{
               query: WholeSaleCategoriesDocument,
               variables: {
                  shopId: shopId
               }
            }]
         });
         mavigate(WholeSalePagePath(brandHouseId, shopId));
      })
   }

   return <Page>
      <PageHeader title={"Category editor"} onBack={true}>
         <Button color={"secondary"} onClick={onDelete} variant={"text"}>Delete</Button>
      </PageHeader>
      <PageContent fullHeight>
         <WholeSaleEditForm shopId={shopId} data={category} onSave={onSave}
                            primaryLocale={shopLocales!.ShopById!.tradeInLocalePrimary!}
                            locales={shopLocales!.ShopById!.tradeInLocales || []}/>
      </PageContent>
   </Page>
}

type WholeSaleEditFormProps = {
   shopId: string
   primaryLocale: string
   locales: string[]
   data: WholeSaleCategoryFragment
   onSave: (data: WholeSaleCategoryFragment) => Promise<void>
};
const WholeSaleEditForm: FC<WholeSaleEditFormProps> = ({shopId, data, onSave, primaryLocale, locales}) => {
   const [showNameTranslations, setShowNameTranslations] = useState<boolean>(false);
   const [showSectionTranslations, setShowSectionTranslations] = useState<boolean>(false);
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const methods = useForm<WholeSaleCategoryFragment>({
      resolver: yupResolver(
         yup.object({
            name: yup.string().required(),
            description: yup.string(),
            voucherStrategy: yup.string().required(),
            rules: yup.array().nullable().of(
               yup.object({
                  name: yup.string().required(),
                  expressions: yup.array().of(
                     yup.object({
                        operator: yup.string().required()
                     })
                  ),
                  value: yup.number().required()
               })
            )
         }).required()),
      defaultValues: {
         ...data
      },
      mode: 'onSubmit',
      reValidateMode: "onChange"
   });
   const {watch, handleSubmit, setValue, control, formState} = methods;
   const advanced = useWatch<WholeSaleCategoryFragment>({
      control,
      name: "advanced"
   })

   const onSubmit = async (data: WholeSaleCategoryFragment) => {
      const {_id, ...rest} = data;
      await onSave(rest as any)
      Dirty.reset();
   }

   // Callback version of watch.  It's your responsibility to unsubscribe when done.
   React.useEffect(() => {
      const subscription = watch((value, {name, type}) => {
         Dirty.flag(type === 'change')
         console.log(value, name, type)
      });
      return () => subscription.unsubscribe();
   }, [watch]);

   return <FormProvider {...methods}>
      <DirtyDetector/>
      <Grid container>
         <Grid item xs={6}>
            <FormInputText name="name" control={control} label={"Name"} errorMessage={"Name is required"}
                           InputProps={{
                              endAdornment: <Link icon={<Language fontSize={"small"}/>}
                                                  onClick={() => setShowNameTranslations(true)}/>
                           }}
            />
            <FormInputText name="section" control={control} label={"Section (optional)"}
                           InputProps={{
                              endAdornment: <Link icon={<Language fontSize={"small"}/>}
                                                  onClick={() => setShowSectionTranslations(true)}/>
                           }}
            />

            <FormInputSelect name={"voucherStrategy"} control={control} label={"Reward strategy"}
                             options={voucherStrategyOptions}/>
            {isAdministrator && <FormInputNumber name={"sortOrder"} label={"Sortorder"}/>}
         </Grid>
         <Grid item xs={6} justifyContent={"center"} container>
            <FormInputImage
               name={"iconId"} control={control} label={"Icon"} errorMessage={"Must be supplied"}
               container={shopId}/>
         </Grid>
         <Grid item xs={12}>
            <Tabs value={advanced ? 2 : 1}>
               <Tab value={1} label={"Simple"} onClick={() => setValue('advanced', false)}/>
               <Tab value={2} label={"Advanced"} onClick={() => setValue('advanced', true)}/>
            </Tabs>
            <Paper variant={"outlined"} style={{padding: '20px'}}>
               {!advanced && <SimpleEditor control={control}/>}
               {advanced && <AdvancedEditor control={control}/>}
            </Paper>
         </Grid>
         <Grid item xs={12} style={{marginTop: '20px'}}>
            <SaveButton saveMessage={"Category saved"} onSave={handleSubmit(onSubmit)}
                        variant={"contained"}>
               Save
            </SaveButton>
         </Grid>
      </Grid>
      <div style={{display: 'none'}}>{formState.errors && JSON.stringify(formState.errors, null, 2)}</div>
      <Dialog open={showNameTranslations} fullWidth maxWidth={"lg"} onClose={() => setShowNameTranslations(false)}>
         <DialogTitle>Localization editor</DialogTitle>
         <DialogContent>
            <LocalizationEditor primaryLocale={primaryLocale} locales={locales} fields={[{
               title: 'Name',
               field: 'name',
               multiline: false
            }]}/>
         </DialogContent>
         <DialogActions>
            <Button variant={"contained"} onClick={() => setShowNameTranslations(false)}>Update</Button>
         </DialogActions>
      </Dialog>
      <Dialog open={showSectionTranslations} fullWidth maxWidth={"lg"} onClose={() => setShowSectionTranslations(false)}>
         <DialogTitle>Localization editor</DialogTitle>
         <DialogContent>
            <LocalizationEditor primaryLocale={primaryLocale} locales={locales} fields={[{
               title: 'Section',
               field: 'section',
               multiline: false
            }]}/>
         </DialogContent>
         <DialogActions>
            <Button variant={"contained"} onClick={() => setShowSectionTranslations(false)}>Update</Button>
         </DialogActions>
      </Dialog>
   </FormProvider>
}

const SimpleEditor: FC<{ control: Control<WholeSaleCategoryFragment> }> = ({control}) => {
   return <div>
      <BodyText type={"body1"}>
         The consumer gets a fixed amount for their trade-in in this category
      </BodyText>
      <FormInputNumber name="value" control={control} label={"Voucher value"}/>
   </div>
}

const AdvancedEditor: FC<{ control: Control<WholeSaleCategoryFragment> }> = ({control}) => {
   const {fields, append, remove} = useFieldArray({
      control,
      name: "rules"
   });

   const onDelete = (index: number) => () => {
      Dialogs.confirm({
         title: "Are you sure you wish to delete this rule?",
         subtitle: ""
      }, async () => {
         remove(index);
      })
   }

   return <div>
      <BodyText type={"body1"}>
         Consumers selecting this will be asked to search and find the exact product that they wish to return.
      </BodyText>

      <Table aria-label="collapsible table">
         <TableHead>
            <TableRow>
               <TableCell/>
               <TableCell>Rule name</TableCell>
               <TableCell style={{width: '50%'}}>Expressions</TableCell>
               <TableCell>Voucher Value (%)</TableCell>
               <TableCell>Active</TableCell>
               <TableCell/>
            </TableRow>
         </TableHead>
         <TableBody>
            {fields.map((item, index) => {
               return <RuleEditor key={item.id} control={control} index={index} onDelete={onDelete(index)}/>
            })}
         </TableBody>
      </Table>
      <IconButton color={"primary"} onClick={() => append({
         name: 'Rule ' + (fields.length + 1),
         active: true
      })}>
         <AddCircle/>
      </IconButton>
   </div>
}

type RuleEditorProps = { control: Control<WholeSaleCategoryFragment>, index: number, onDelete: () => void };

const RuleEditor: FC<RuleEditorProps> = ({control, index, onDelete}) => {
   const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
   const {watch} = useFormContext();
   const expressions = watch(`rules.${index}.expressions`);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const open = Boolean(anchorEl);

   return <>
      <TableRow style={{verticalAlign: 'top'}}>
         <TableCell valign={"top"} style={{paddingTop: '35px'}}>{index + 1}</TableCell>
         <TableCell valign={"top"}>
            <FormInputText name={`rules[${index}].name`} control={control} label={"Name"}
                           errorMessage={"Name is required"}/>
         </TableCell>
         <TableCell valign={"top"}>
            <ExpressionEditor control={control} nested={index}/>
         </TableCell>
         <TableCell valign={"top"}>
            <FormInputNumber name={`rules[${index}].value`} control={control} label={"Value (%)"}
                             errorMessage={"Value is required"}/>
         </TableCell>
         <TableCell valign={"top"}>
            <FormInputCheckbox name={`rules[${index}].active`} control={control} label={""}/>
         </TableCell>
         <TableCell>
            <Box display={"flex"}>
               <IconButton
                  aria-controls={open ? 'expr-stat-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
               >
                  <Visibility/>
               </IconButton>
               <IconButton onClick={onDelete}>
                  <DeleteForever/>
               </IconButton>

               <Menu open={open}
                     id={"expr-stat-menu"}
                     onClose={handleClose}
                     onClick={handleClose}
                     anchorEl={anchorEl}
                     transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                     anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                     style={{minWidth: '500px'}}
               >
                  <Statistics expressions={expressions}/>
               </Menu>
            </Box>
         </TableCell>
      </TableRow>
   </>;
}

const Statistics: FC<{ expressions: any }> = ({expressions}) => {
   const {shopId} = useParams<{ shopId: string }>();
   const {data} = useWholeSaleStatisticsQuery({
      variables: {
         shopId: shopId,
         rule: {
            _id: 'id',
            name: 'Statistics',
            value: 100,
            active: true,
            expressions: expressions
         }
      }
   })

   type SampleData = {
      name?: string
      images: string[]
      sku?: string
   }

   const sampleData: SampleData[] = [];

   for (const product of data?.WholeSaleRuleStatistics?.sample || []) {
      let images = new Set<string>();
      let sku: string | undefined;
      for (const variant of product?.variants || []) {
         if (variant && variant.images) {
            for (const image of variant.images) {
               if (image) {
                  images.add(image)
               }
            }
            sku = sku || variant.sku || undefined
         }
      }
      sampleData.push({
         name: product!.name || undefined,
         images: Array.from(images),
         sku: sku
      })
   }

   let matchCount = data?.WholeSaleRuleStatistics?.count || 0;
   let columns: DataColumn<SampleData>[] = [{
      field: 'name',
      label: 'Samples'
   }, {
      field: 'images',
      label: 'Image',
      render: (images) => {
         return <Thumbnails maxImages={1} size={30} imageUrls={images}/>
      }
   }]

   return <div style={{padding: '20px'}}>
      <TitleText type={"h1"}>{matchCount} products matches the rule</TitleText><br/>
      <DataTable idField={"_id"} rows={sampleData} columns={columns}/>
      {matchCount > 10 && <BodyText type={"subtitle1"}>Not showing all data...</BodyText>}
   </div>
}

const ExpressionEditor: FC<{ control: Control<WholeSaleCategoryFragment>, nested: number }> = ({control, nested}) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const {fields, append, remove} = useFieldArray<WholeSaleCategoryFragment>({
      control,
      name: `rules.${nested}.expressions`
   });
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);

   return <div>
      {fields.map((field, index) => {
         let item: WholeSaleEditCategoryExpressionFragment = field as any
         return <div key={field.id} style={{display: 'flex', gap: '5px'}}>
            <div style={{width: '180px'}}>
               <FormInputSelect name={`rules[${nested}].expressions[${index}].operator`} control={control}
                                label={"Op"} options={[{
                  id: 'INCLUDING',
                  name: "Include"
               }, {
                  id: 'EXCLUDING',
                  name: "Exclude"
               }]}/>
            </div>
            {item.expressionType === 'SKU' &&
            <SKUField control={control} nested={nested} index={index}/>
            }
            {item.expressionType === 'TAG' &&
            <TAGField control={control} nested={nested} index={index}/>
            }
            {item.expressionType === 'COL' &&
            <COLField control={control} nested={nested} index={index}/>
            }
            <IconButton onClick={() => remove(index)}>
               <DeleteForever/>
            </IconButton>
         </div>
      })}
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
         <IconButton onClick={handleClick}
                     size="small"
                     sx={{ml: 2}}
                     aria-controls={open ? 'expr-add-menu' : undefined}
                     aria-haspopup="true"
                     aria-expanded={open ? 'true' : undefined}
         >
            <Add/>
         </IconButton>
      </Box>
      <Menu open={open}
            id={"expr-add-menu"}
            onClose={handleClose}
            onClick={handleClose}
            anchorEl={anchorEl}
            PaperProps={{
               elevation: 0,
               sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                  },
                  '&:before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: 'background.paper',
                     transform: 'translateY(-50%) rotate(45deg)',
                     zIndex: 0,
                  },
               },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
         <MenuItem onClick={() => append({
            operator: 'INCLUDING',
            expressionType: 'SKU'
         })}>
            <ListItemIcon>
               <Label/>
            </ListItemIcon>
            SKU
         </MenuItem>
         <MenuItem onClick={() => append({
            operator: 'INCLUDING',
            expressionType: 'TAG'
         })}>
            <ListItemIcon>
               <TurnedIn/>
            </ListItemIcon>
            Tag
         </MenuItem>
         <MenuItem onClick={() => append({
            operator: 'INCLUDING',
            expressionType: 'COL'
         })}>
            <ListItemIcon>
               <AllInbox/>
            </ListItemIcon>
            Collection
         </MenuItem>
      </Menu>
   </div>
}

const SKUField: FC<{ control: Control<WholeSaleCategoryFragment>, nested: number, index: number }> = ({
                                                                                                         control,
                                                                                                         nested,
                                                                                                         index
                                                                                                      }) => {
   return <FormInputText name={`rules[${nested}].expressions[${index}].sku`} control={control}
                         label={"SKU (Or part of)"}
                         errorMessage={"SKU or part of the SKU is required"}/>

}

const TAGField: FC<{ control: Control<WholeSaleCategoryFragment>, nested: number, index: number }> = ({
                                                                                                         control,
                                                                                                         nested,
                                                                                                         index
                                                                                                      }) => {
   const {shopId} = useParams<{ shopId: string }>();
   const {data} = useWholeSaleCategoryEditBrandQuery({
      variables: {
         shopId: shopId
      }
   })
   if (!data) return <></>
   const options = (data.ShopById?.productTags || []).map(opt => ({id: opt, name: opt}));

   return <FormInputAutocomplete name={`rules[${nested}].expressions[${index}].tag`} control={control}
                                 options={options}
                                 label={"TAG"}
                                 errorMessage={"TAG is required"}/>

}


const COLField: FC<{ control: Control<WholeSaleCategoryFragment>, nested: number, index: number }> = ({
                                                                                                         control,
                                                                                                         nested,
                                                                                                         index
                                                                                                      }) => {
   const {shopId} = useParams<{ shopId: string }>();
   const {data} = useWholeSaleCategoryEditBrandQuery({
      variables: {
         shopId: shopId
      }
   })
   if (!data) return <></>
   const options = (data.ShopById?.productCollections || []).map(opt => ({id: opt, name: opt}));

   return <FormInputAutocomplete name={`rules[${nested}].expressions[${index}].col`} control={control}
                                 options={options}
                                 label={"Collection"}
                                 errorMessage={"Collection is required"}/>

}