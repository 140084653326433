import PageContent from "../../layout/PageContent";
import {useBrandItemOrdersQuery} from "../../Queries";
import {useNavigate} from "react-router";
import {DataColumn, DataTable} from "../../components/DataTable";
import * as React from "react";
import {useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";
import {DateFormat} from "../../utility/DateFormat";
import {OrderPagePath} from "../shop/orders/OrderPage";

type BrandItemOrder = {
   orderId: string
   shopId: string
   name: string
   date: Date
   returned: boolean
   consumer?: string
   shop: string
}

type BrandItemOrdersProps = {
   brandHouseId: string
   brandItemId: string
}

export const BrandItemOrders = ({brandHouseId, brandItemId}: BrandItemOrdersProps) => {
   const navigate = useNavigate();

   const [rows, setRows] = useState<BrandItemOrder[]>([])
   const {data} = useBrandItemOrdersQuery({
      variables: {
         brandItemId: brandItemId
      }
   })

   const onNavigateOrder = (order: BrandItemOrder) => (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(OrderPagePath(brandHouseId, order.shopId, order.orderId))
   }

   useEffect(() => {
      if (data) {
         const result: BrandItemOrder[] = [];
         for (const shop of data.BrandItem?.shops || []) {
            for (const orderLine of shop?.stockItem?.orderlines || []) {
               result.push({
                  name: orderLine!.order!.orderName!,
                  date: orderLine!.order!.orderDate,
                  consumer: orderLine!.order!.consumer?.name || undefined,
                  returned: orderLine?.returned || false,
                  shop: shop!.shop!.name!,
                  shopId: shop!.shop!._id!,
                  orderId: orderLine!.order!._id!
               })
            }
         }
         result.sort((a, b) => {
            return new Date(a!.date!).getTime() - new Date(b!.date!).getTime()
         })
         setRows(result);
      }
   }, [data])

   if (!data) {
      return <LinearProgress/>
   }

   const columns: DataColumn<BrandItemOrder>[] = [{
      field: 'date',
      label: 'Date',
      render: (date) => {
         return DateFormat.toPresent(date)
      }
   }, {
      field: 'name',
      label: 'Order',
      render: (data, row) => {
         // eslint-disable-next-line jsx-a11y/anchor-is-valid
         return <div><a href={"#"} onClick={onNavigateOrder(row!)}>{data}</a></div>
      }
   }, {
      field: 'consumer',
      label: 'Consumer'
   }, {
      field: 'shop',
      label: 'Store'
   }]


   return <PageContent>
      <DataTable idField={"_id"} columns={columns} rows={rows}/>
   </PageContent>
}