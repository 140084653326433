import React from "react";
import {FormInputText} from "../shop/wholesale/form/FormInputText";
import Grid from "@mui/material/Grid";
import {PageAccordion} from "../../layout/PageAccordion";

type AddressEditorProps = {
   addressField: string
}

export const AddressEditor = ({addressField}: AddressEditorProps) => {
   return <PageAccordion title={"Address"} stateId={"address"}>
      <Grid xs={12} container>
         <FormInputText name={addressField + ".name"} required label={"Name / Company"}/>
         <FormInputText name={addressField + ".attention"} label={"Attention"}/>
         <FormInputText name={addressField + ".add1"} required label={"Address"}/>
         <FormInputText name={addressField + ".add2"} label={"Address 2"}/>
         <Grid xs={12} container columnGap={"8px"} wrap={"nowrap"}>
            <Grid xs={4}>
               <FormInputText name={addressField + ".zipCode"} required label={"Zipcode"}/>
            </Grid>
            <Grid xs={8}>
               <FormInputText name={addressField + ".city"} required label={"City"}/>
            </Grid>
         </Grid>
         <FormInputText name={addressField + ".countryCode"} required label={"Country code (ISO)"}/>
         <Grid xs={12} container columnGap={"8px"} wrap={"nowrap"}>
            <Grid xs={4}>
               <FormInputText name={addressField + ".mail"} required label={"Mail"}/>
            </Grid>
            <Grid xs={8}>
               <FormInputText name={addressField + ".phone"} required label={"Phone"}/>
            </Grid>
         </Grid>
      </Grid>
   </PageAccordion>
}