import {FC} from "react";
import {Divider, LinearProgress} from "@mui/material";
import {TitleText} from "../../layout/Typography";
import {useStatDevelopmentTableQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";
import {StatisticsTable} from "./StatisticsTable";
import {StatCurrency} from "./StatCurrency";

type StatDevelopmentTableProps = {
   start: Date
   end: Date
   brand?: BrandSelection
   splits: Split[]
}

export const StatDevelopmentTable: FC<StatDevelopmentTableProps> = ({start, end, brand, splits}) => {
   const {data} = useStatDevelopmentTableQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: "true",
         brandHouseIds: brand?.brandHouseIds,
         shopIds: brand?.shopIds,
         splits: splits
      }
   })
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   const onTitleRender = (kpi: string) => {
      if (kpi === 'orderTotal') {
         return 'Order total'
      } else if (kpi === 'orderC2s') {
         return 'C2S orders'
      } else if (kpi === 'orderItemsC2S') {
         return 'C2S orderlines'
      } else if (kpi === 'orderItemsC2SValue') {
         return 'C2S orderlines (' + brand?.currency + ')'
      } else if (kpi === 'orderItemsC2SReturned') {
         return 'C2S returned items'
      } else {
         return kpi
      }
   }

   const onValueRender = (kpi: string, value: any) => {
      if (kpi === 'orderItemsC2SValue') {
         return <StatCurrency currency={brand?.currency || 'DKK'} value={Math.round(value)}/>
      } else {
         return <span>{Math.round(value)}</span>
      }
   }


   let statisticsCalculate2: any = {...data.StatisticsCalculate}
   if (!brand) {
      statisticsCalculate2.kpis = statisticsCalculate2.kpis.filter((kpi: string) => kpi !== 'orderItemsC2SValue')
   }
   return <div>
      <TitleText type={"h2"}>Development per day</TitleText>
      <Divider style={{marginBottom: '20px'}}/>

      <StatisticsTable titleRender={onTitleRender} valueRender={onValueRender} data={statisticsCalculate2}/>
   </div>
}
