import * as React from "react";
import Page from "../../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import PageContent from "../../../layout/PageContent";
import PageHeader from "../../../layout/PageHeader";
import Button from "@mui/material/Button";
import useStockItemCreateEmpty from "./hooks/useStockItemCreateEmpty";
import {StockItemEditPagePath} from "./edit/StockItemEditPage";
import {Dialogs} from "../../../DialogProvider";
import {ProductsList} from "../components/ProductList";
import {FileDownload, GetApp, PictureAsPdf, Publish} from "@mui/icons-material";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../atoms/CurrentUser";
import {
   FilterFindManyStockItemInput,
   FlexStockItemFragment, ProductVariantSize,
   StockItemUpdateInput,
   useReprocessAllImagesMutation,
   useReprocessStockItemImagesMutation,
   useStockItemImageSynchronizeMutation,
   useStockItemProductInfoLazyQuery,
   useStockItemsPageBrandQuery,
   useStockItemsPagePublishMutation,
   useStockItemsRecalculatePricesMutation,
   ViewProductVariantFragment
} from "../../../Queries";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {FlexCommand} from "../../../components/flexlist/FlexTypes";
import {StockItemsList} from "../components/StockItemsList";
import EditIcon from "@mui/icons-material/Edit";
import {StockItemMultiEdit} from "./edit/StockItemMultiEdit";
import {StockItemExport} from "./StockItemExport";

export const StockItemsPagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/stock";
}

export const StockItemsPage = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const [creating, setCreating] = React.useState<boolean>(false);
   const navigate = useNavigate();
   const [editing, setEditing] = React.useState<string[]>([]);
   const [exportIds, setExportIds] = React.useState<string[]>([])

   const createStockItem = useStockItemCreateEmpty();
   const [reprocessAllImages] = useReprocessAllImagesMutation()
   const [reprocessItemImages] = useReprocessStockItemImagesMutation()
   const [synchronizeImage] = useStockItemImageSynchronizeMutation()
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);

   const [productDetails] = useStockItemProductInfoLazyQuery();

   const {data: brand} = useStockItemsPageBrandQuery({
      variables: {
         shopId: shopId
      }
   });

   const [recalculatePrices] = useStockItemsRecalculatePricesMutation()
   const [publishItem] = useStockItemsPagePublishMutation();

   const onSelectStockItem = (stockItem: FlexStockItemFragment, newWindow?: boolean) => {
      if (newWindow) {
         window.open(StockItemEditPagePath(brandHouseId, shopId, stockItem._id), '_blank', '')
      } else {
         navigate(StockItemEditPagePath(brandHouseId, shopId, stockItem._id))
      }
   }

   const onCreateStockItem = async (variant: ViewProductVariantFragment, size: Partial<ProductVariantSize>) => {
      const productInfo = await productDetails({
         variables: {
            productId: variant.productId
         }
      });
      if (productInfo) {
         const newStockItemId = await createStockItem(shopId, {
            title: variant.product?.name || undefined,
            color: variant.color || undefined,
            beforePrice: variant.rrp?.find(value => value?.currency === brand?.ShopById?.currency)?.value || undefined,
            originalEan: variant.ean || undefined,
            sku: variant.sku || undefined,
            condition: 3,
            description: productInfo?.data?.ProductById?.description || undefined,
            draft: false,
            published: false
         });
         navigate(StockItemEditPagePath(brandHouseId, shopId, newStockItemId))
      }
   }

   const onCreateEmpty = async () => {
      const newStockItemId = await createStockItem(shopId, {
         condition: 3,
         draft: true
      });
      navigate(StockItemEditPagePath(brandHouseId, shopId, newStockItemId))
   }

   const onCreating = () => {
      setCreating(true);
   }
   const onCancel = () => {
      setCreating(false);
   }


   const onRecalculatePrices = async () => {
      Dialogs.confirm({
         title: 'Recalculate all prices?',
         subtitle: 'This will update all sales prices on all stockitems, based on their Compare At Price (RRP) and Condition.\n' +
            'All published items will be updated at the external shop.'
      }, async () => {
         await recalculatePrices({
            variables: {
               shopId: shopId
            },
            refetchQueries: ['StockItemPagination']
         })
      })
   }

   const onAutoRetouch = async () => {
      Dialogs.confirm({
         title: 'Retouch ALL Images?',
         subtitle: 'Please notice that this is an expensive operation, so use with care!'
      }, async () => {
         await reprocessAllImages({
            variables: {
               shopId: shopId
            }
         })
      })
   }

   if (!brand?.ShopById) {
      return <LinearProgress/>
   }

   const commands: FlexCommand<FilterFindManyStockItemInput>[] = [{
      id: "retouch",
      title: "Retouch Images",
      execute: async rows => {
         for (const row of rows) {
            await reprocessItemImages({
               variables: {
                  stockItemId: row._id,
                  shopId: row.shopId
               }
            })
         }
      },
      icon: PictureAsPdf
   }, {
      id: "status",
      title: "Change status",
      icon: Publish,
      children: [{
         id: "Publish",
         title: "To Sale",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: shopId,
                     stockItem: {
                        _id: row._id,
                        published: true,
                        draft: false,
                        review: false
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: Publish
      }, {
         id: "Review",
         title: "To Review",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: shopId,
                     stockItem: {
                        _id: row._id,
                        published: false,
                        draft: false,
                        review: true
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: Publish
      }, {
         id: "Draft",
         title: "To Draft",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: shopId,
                     stockItem: {
                        _id: row._id,
                        published: false,
                        draft: true,
                        review: false
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: GetApp
      }]
   }, {
      id: "ImageSynchronize",
      title: "Synchronize Images",
      execute: async rows => {
         for (const row of rows) {
            await synchronizeImage({
               variables: {
                  stockItem: row._id
               }
            })
         }
      },
      icon: AutorenewIcon
   }, {
      id: 'BulkEdit',
      title: 'Bulk edit',
      execute: async rows => {
         setEditing(rows.map(row => row._id))
      },
      icon: EditIcon
   }, {
      id: 'export',
      title: 'Export to excel',
      icon: FileDownload,
      execute: async rows => {
         setExportIds(rows.map(row => row._id) || [])
      }
   }]

   const onCloseEditor = () => {
      setEditing([])
   }

   return <Page>
      <PageHeader title="Stock items">
         {!isBrandHouse && <>
            <Button color={"secondary"} onClick={onRecalculatePrices} variant={"text"}>Recalculate salesprices</Button>
            <Button color={"secondary"} onClick={onAutoRetouch} variant={"text"}>Retouch All</Button>
            {!brand?.ShopById?.brandHouse?.settings?.useBrandItems &&
               <Button color={"secondary"} onClick={onCreating} variant={"text"}>Create StockItem</Button>}
         </>}
      </PageHeader>

      <PageContent fullHeight>
         {editing.length === 0 &&
            <StockItemsList
               onSelectItem={onSelectStockItem}
               listId={"shopStockItemList"}
               shopId={shopId}
               commands={commands}
               currency={brand?.ShopById?.currency || '???'}
               columnOptions={{
                  id: true
               }}
            />
         }
         {editing.length > 0 && <LinearProgress/>}
      </PageContent>
      <Dialog open={creating} onClose={onCancel} fullWidth maxWidth={"lg"}>
         <DialogTitle>Select product to create stock item for, or select Proceed to create an empty
            product</DialogTitle>
         <DialogContent>
            <ProductsList shopId={shopId} onVariantSelect={onCreateStockItem} inDialog hidepreowned/>
         </DialogContent>
         <DialogActions>
            <Button variant={"contained"} color={"primary"} onClick={onCreateEmpty}>Create empty</Button>
            <Button variant={"outlined"} onClick={onCancel}>Cancel</Button>
         </DialogActions>
      </Dialog>
      {editing.length > 0 && <StockItemMultiEdit ids={editing} onClose={onCloseEditor}/>}
      {exportIds.length > 0 && <StockItemExport language={"da"} currency={"DKK"} ids={exportIds}/>}
   </Page>
}