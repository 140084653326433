import {
   InventoryPageLocationsDocument,
   useInventoryPageItemsUpdateLazyQuery,
   useInventoryPageLocationsQuery,
   useInventoryPageResetMutation
} from "../../../Queries";
import {Button, LinearProgress} from "@mui/material";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import {PageAccordion} from "../../../layout/PageAccordion";
import {FC, useEffect, useState} from "react";
import {InventoryItem} from "./InventoryItem";
import {CheckCircle, Circle} from "@mui/icons-material";
import {PubSub} from "../../../components/PubSub";
import {ParamLogisticsPartner} from "../../../Parameters";
import {useParams} from "react-router";
import {Dialogs} from "../../../DialogProvider";
import {SavingUI} from "../../../SavingProvider";

export const InventoryPage = () => {
   const {logisticsPartnerId} = useParams<ParamLogisticsPartner>()
   const {data} = useInventoryPageLocationsQuery()
   const [reset] = useInventoryPageResetMutation();
   if (!data) {
      return <LinearProgress/>
   }
   const onReset = async () => {
      Dialogs.confirm({
         title: 'WARNING! Reset inventory status?',
         subtitle: 'THIS IS DANGEROUS! All items that has been marked as missing will be made available again in all external shops. You probably NEVER want to do this!'
      },async () => {
         await SavingUI.process(async () => {
            await reset({
               variables: {
                  lpId: logisticsPartnerId
               },
               refetchQueries: [{
                  query: InventoryPageLocationsDocument
               }]
            });
         }, 'Inventory was successfully reset!')
      })
   }
   return <Page>
      <PageHeader title={"Inventory"}>
         <Button onClick={onReset} variant={"contained"}>
            Restart Counting
         </Button>
      </PageHeader>
      {data?.WarehouseLocations?.map(loc => {
         return <PageAccordion
            title={<LocationTitle ids={loc?.items?.map(item => item?._id) || []} count={loc?.count || 0}
                                  title={loc?._id || ''} checked={loc?.checked || false}/>}
            stateId={'warehouse_' + loc?._id} lazyload={true}>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
               {loc?.items?.map(item => {
                  return <InventoryItem key={'inventory_item_' + item?._id}
                                        locationId={loc?._id || ''}
                                        stockItemId={item?._id}/>
               })}
            </div>
         </PageAccordion>
      })}
   </Page>
}


type LocationTitleProps = {
   title: string
   checked: boolean
   count: number
   ids: string[]
}

const LocationTitle: FC<LocationTitleProps> = ({title, count, checked, ids}) => {
   const [isChecked, setIsChecked] = useState<boolean | undefined>();

   const [refetch] = useInventoryPageItemsUpdateLazyQuery();

   useEffect(() => {
      return PubSub.on('InventoryItemUpdate-' + title, () => {
         refetch({
            variables: {
               query: {
                  OR: ids.map(id => {
                     return {
                        _id: id
                     }
                  })
               }
            },
            fetchPolicy: 'no-cache'
         }).then(value => {
            let stockItems = value?.data?.StockItems || [];
            for (const stockItem of stockItems) {
               if (!stockItem?.effectiveChecked) {
                  setIsChecked(false);
                  return;
               }
            }
            setIsChecked(true);
         })
      })
      // eslint-disable-next-line
   }, []);

   let effectiveChecked: boolean;
   if (isChecked === undefined) {
      effectiveChecked = checked;
   } else {
      effectiveChecked = isChecked;
   }

   return <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <div style={{width: '200px'}}>
         {title || 'None'}
      </div>
      <div style={{width: '100px'}}>
         {count} items
      </div>
      <div style={{width: '100%', textAlign: 'right'}}>
         {effectiveChecked && <CheckCircle color={"success"}/>}
         {!effectiveChecked && <Circle color={"warning"}/>}
      </div>
   </div>
}
