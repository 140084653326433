import {gql, useMutation} from "@apollo/client";

const qlTradeInDelete = gql`
   mutation TradeInDelete($shopId:String!, $tradeInId:String!) {
     TradeInDelete(shopId: $shopId, tradeInId: $tradeInId) {
       _id
     }
   }
`

const useTradeInDelete = () => {
   const [tradeInDelete] = useMutation(qlTradeInDelete);

   return async (shopId: string, tradeInId: string) => {
      let result = await tradeInDelete({
         variables: {
            shopId: shopId,
            tradeInId: tradeInId
         },
         update(cache, {data}) {
            cache.evict(data.TradeInDelete._id);
         }
      });
      return result?.data?.TradeInDelete
   }
}

export default useTradeInDelete;