import * as React from "react";
import {FC} from "react";
import {
   BrandSetting,
   BrandSettingInput,
   CircularPayoutRatio,
   CircularPayoutType,
   useCircularPageQuery,
   useCircularPageUpdateMutation
} from "../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Button, Grid, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {AddCircle, DeleteForever, Label} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {SavingUI} from "../../../SavingProvider";
import SettingsHeader from "../SettingsHeader";
import PageContent from "../../../layout/PageContent";
import {FormInputDate} from "../../shop/wholesale/form/FormInputDate";
import {FormInputPercent} from "../../shop/wholesale/form/FormInputPercent";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {ShopTabs} from "../ShopTabs";

const ID_CIRCULAR_FORM = "circularssettingsform";

const CircularPagePath = (brandId: string, edit: string) => {
   return "/brands/" + brandId + "/settings/ecircular/" + edit
}

export const CircularPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(CircularPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Circular points"}>
         <Button type="submit" form={ID_CIRCULAR_FORM} variant={"contained"}>Save</Button>
      </SettingsHeader>
      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <CircularEditorContainer brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <CircularEditorContainer shopId={shop}/>}
      />
   </div>
}

type CircularEditorContainerProps = {
   brandHouseId?: string
   shopId?: string
}

const CircularEditorContainer = ({brandHouseId, shopId}: CircularEditorContainerProps) => {
   const {data} = useCircularPageQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveCircularSettings] = useCircularPageUpdateMutation();

   const onSaveCircular = async (data: BrandSettingInput) => {
      await SavingUI.process(async () => {
         await saveCircularSettings({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               input: data.circular
            },
            refetchQueries: ['BrandSettings']
         });
      }, 'Circular settings updated')
   }

   if (!data?.BrandSettings) {
      return <LinearProgress/>
   }

   return <PageContent>
      <CircularEditor conversions={data?.CircularPayoutMany || []}
                    data={data?.BrandSettings as BrandSetting || {
                       circularPoints: {}
                    }}
                    onSave={onSaveCircular}/>
   </PageContent>
}

type CircularEditorProps = {
   data: BrandSetting
   conversions: CircularPayoutType[]
   onSave: (data: BrandSettingInput) => Promise<void>
}

const CircularEditor: FC<CircularEditorProps> = ({data, conversions, onSave}) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

   const methods = useForm<BrandSetting>({
      defaultValues: data
   });
   const {handleSubmit, control} = methods;

   const {fields: payoutFields, append: payoutAppend, remove: payoutRemove} = useFieldArray({
      control,
      name: "circular.payoutTypes"
   });

   const onSubmit = async (data: BrandSettingInput) => {
      await onSave(data as any)
   }

   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   let availableConversions = conversions?.filter(conversion => {
      return !payoutFields.find(field => String(field.payoutId) === String(conversion._id))
   });

   return <div>
      <form onSubmit={handleSubmit(onSubmit)} id={ID_CIRCULAR_FORM}/>
      <FormProvider {...methods}>
         <TitleText type={"h1"}>Circular Points</TitleText>
         <BodyText type={"subtitle1"}>
            Activating circular points will enable a point account for the consumer, that will receive "Points" whenever
            the consumer makes a tradein.
            Whenever the consumer feels like buying something, they can use the payout function - meaning they can
            convert the points into different valuables.
         </BodyText>
         <FormInputDate name={"circular.activationDate"} label={"Activate circular points date"}/>
         <br/>
         <TitleText type={"h3"}>Payout methods</TitleText>
         <BodyText type={"subtitle1"}>
            Each payout method will enable the consumer to convert their points into another form of currency. The
            payout ratio denotes how of the circular points
            will be converted into the other currency.
         </BodyText>
         {payoutFields.map((payout, index) => {
            const activeConversion = conversions.find(conv => conv._id===payout.payoutId)
            return <Grid container>
               <Grid item xs={12}>
                  <FormInputPercent name={`circular.payoutTypes[${index}].ratio`}
                                    label={activeConversion?.name + " payout ratio (0-100) %"}
                                    InputProps={{
                                       endAdornment: <>
                                          <FormInputCheckbox name={`circular.payoutTypes[${index}].active`}
                                                             label={"Active"}/>
                                          <IconButton onClick={() => payoutRemove(index)}>
                                             <DeleteForever/>
                                          </IconButton>
                                       </>
                                    }}
                  />
               </Grid>
            </Grid>
         })}
         {availableConversions?.length > 0 && <>
            <IconButton onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'expr-add-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
            >
               <AddCircle/>
            </IconButton>

            <Menu open={open}
                  id={"reward-add-menu"}
                  onClose={handleClose}
                  onClick={handleClose}
                  anchorEl={anchorEl}
                  PaperProps={{
                     elevation: 0,
                     sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                           width: 32,
                           height: 32,
                           ml: -0.5,
                           mr: 1,
                        },
                        '&:before': {
                           content: '""',
                           display: 'block',
                           position: 'absolute',
                           top: 0,
                           right: 14,
                           width: 10,
                           height: 10,
                           bgcolor: 'background.paper',
                           transform: 'translateY(-50%) rotate(45deg)',
                           zIndex: 0,
                        },
                     },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
               {availableConversions?.map(conversion => {
                  return <MenuItem onClick={() => payoutAppend({
                     ratio: conversion.defaultRatio,
                     active: true,
                     payoutId: conversion!._id!
                  } as CircularPayoutRatio)}>
                     <ListItemIcon>
                        <Label/>
                     </ListItemIcon>
                     {conversion.name}
                  </MenuItem>
               })}
            </Menu>
         </>}
      </FormProvider>
   </div>
}
