import {Box, Button, ButtonProps, CircularProgress} from "@mui/material";
import React, {FC, useState} from "react";

export const SingleClickButton: FC<ButtonProps> = ({children, ...props}) => {
   const {onClick, ...rest} = props;
   const [disabled, setDisabled] = useState<boolean>(false);

   const _onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setDisabled(true);
      if (onClick) {
         setTimeout(() => {
            onClick && onClick(e);
         }, 200);
      }
      setTimeout(() => {
         setDisabled(false);
      }, 1000);
   }

   return <Button {...rest} onClick={onClick ? _onClick : undefined} disabled={disabled}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
         {disabled && <CircularProgress size={16}/>}
         {children}
      </Box>
   </Button>
}