import * as React from "react"
import {FC} from "react"
import {QComponentChildrenProps} from "../Model";
import {Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

export type QVerticalProps = QComponentChildrenProps & {

}

export const QVertical:FC<QVerticalProps> = ({children}) => {
   const classes = useVerticalStyles();
   return <Grid container style={{gap: '10px'}}>{React.Children.map(children, child => {
      return <Grid className={classes.grid} item xs={12}>{child}</Grid>
   })}</Grid>
}

const useVerticalStyles = makeStyles({
   grid: {
      "&:empty": {
         display:'none'
      }
   }
});