import {gql, useMutation} from "@apollo/client";

export const qlStockItemCreateEmpty = gql`
    mutation TradeInsStockItemCreate($shopId: String!, $tradeInItemId: String!) {
      StockItemCreate(shopId:$shopId, tradeInItemId: $tradeInItemId) {
         _id
         tradeInItemId
      }
    }
`;

const useStockItemCreate = () => {
   const [stockItemCreate] = useMutation(qlStockItemCreateEmpty);

   return (shopId: string, tradeInItemId: string) => {
      return stockItemCreate({
         variables: {
            shopId: shopId,
            tradeInItemId: tradeInItemId
         },
         update(cache, {data}) {
            cache.evict(data.StockItemCreate._id);
            cache.evict(data.StockItemCreate.tradeInItemId);
         }
      }).then(data => data.data.StockItemCreate?._id)
   }
}

export default useStockItemCreate;