import * as React from "react";
import PageContent from "../../../layout/PageContent";
import {AnalyticsFragment, useAnalyticsGetQuery, useAnalyticsUpdateMutation} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {Button, LinearProgress} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {SavingUI} from "../../../SavingProvider";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";

const ID_ANALYTICSFORM = "AnalyticsForm";

const AnalyticsPagePath = (brandId: string, targetId: string) => {
   return "/brands/" + brandId + "/settings/gatags/" + targetId
}

export const AnalyticsPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(AnalyticsPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Analytics"} subtitle={""}>
         <Button type="submit" form={ID_ANALYTICSFORM} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         hideDefault={true}
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <AnalyticsPageInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <AnalyticsPageInner shopId={shop}/>}
      />
   </div>
}

type AnalyticsPageInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const AnalyticsPageInner = ({brandHouseId, shopId}: AnalyticsPageInnerProps) => {
   const {data} = useAnalyticsGetQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   const [saveAnalytics] = useAnalyticsUpdateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: AnalyticsFragment) => {
      await SavingUI.process(async () => {
         await saveAnalytics({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               input: data.tradeIn2Analytics!
            }
         });
      }, 'Analytics settings updated')
   }
   return <AnalyticsEditor data={data!.BrandSettings as AnalyticsFragment}
                           onSave={onSave}
   />
}


type AnalyticsEditorProps = {
   data?: AnalyticsFragment
   onSave: (data: AnalyticsFragment) => Promise<void>
}


const AnalyticsEditor = ({data, onSave}: AnalyticsEditorProps) => {
   const methods = useForm<AnalyticsFragment>({
      defaultValues: data
   });
   const {handleSubmit} = methods;

   // @ts-ignore
   return <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} id={ID_ANALYTICSFORM}/>
      <PageContent>
         <TitleText type={"h2"}>Google Analytics</TitleText>
         <BodyText type={"subtitle1"}>Configure the google analytics propertyId's for the tradein app</BodyText>
         <br/>
         <FormInputText name={"tradeIn2Analytics.ga4Property"} label={"Google Analytics 4 (GA4)"}/>
         <FormInputText name={"tradeIn2Analytics.uaProperty"} label={"Universal Analytics (UA - Deprecated!)"}/>
      </PageContent>
   </FormProvider>
}
