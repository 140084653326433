import React, {useEffect} from "react";
import {PubSub} from "./PubSub";
import {usePrompt} from "./useBlocker";
import {useFormContext} from "react-hook-form";

export const DirtyDetector = () => {
   const [dirty, setDirty] = React.useState<boolean>(false);

   useEffect(() => {
      return PubSub.on('DirtyDetector', (newDirt: boolean) => {
         if(newDirt) {
            setDirty(true);
         }
      })
   }, []);

   useEffect(() => {
      return PubSub.on('DirtyDetector_Reset', () => {
         setDirty(false);
      })
   }, []);

   usePrompt('Discard changes?', dirty);

   return <div/>
}

export const useReactHookDirtyDetection = () => {
   const methods = useFormContext();
   const {watch} = methods;

   // Callback version of watch.  It's your responsibility to unsubscribe when done.
   React.useEffect(() => {
      const subscription = watch((value, { name, type }) => {
         Dirty.flag(type==='change')
      });
      return () => subscription.unsubscribe();
   }, [watch]);

}

export const ReactHookDirtyDetector = () => {
   useReactHookDirtyDetection();
   return <div/>
}

const reset = () => {
   PubSub.emit('DirtyDetector_Reset')
}

const flag = (dirt: boolean) => {
   PubSub.emit('DirtyDetector', dirt);
}

export const Dirty = {
   reset,
   flag
}
