import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {useExportVouchersQuery, Voucher} from "../../../Queries";

export const ExportToExcel = async (data: Voucher[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ID",
      type: String,
      value: (object: Voucher) => String(object._id)
   }, {
      column: "CURRENTVALUE",
      type: Number,
      value: (object: Voucher) => object.currentValue
   }, {
      column: "ORIGINALVALUE",
      type: Number,
      value: (object: Voucher) => object.value
   }, {
      column: "CURRENCY",
      type: String,
      value: (object: Voucher) => object.currency
   }, {
      column: "CODE",
      type: String,
      value: (object: Voucher) => object.code
   }, {
      column: "NAME",
      type: String,
      value: (object: Voucher) => object.consumer?.name
   }, {
      column: "MAIL",
      type: String,
      value: (object: Voucher) => object.consumer?.mail
   }, {
      column: "RECEIVEDTS",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: Voucher) => object.tradeInReceivedTs ? new Date(object.tradeInReceivedTs) : undefined
   }, {
      column: "ACTIVE",
      type: Boolean,
      value: (object: Voucher) => object.activated
   }, {
      column: "ACTIVATION",
      type: String,
      value: (object: Voucher) => object.voucherStrategy
   }, {
      column: "MINIMUM",
      type: Number,
      value: (object: Voucher) => object.minimumPurchase
   }, {
      column: "PASSIVE",
      type: Boolean,
      value: (object: Voucher) => object.passive
   }, {
      column: "DISABLEDTS",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: Voucher) => object.disabled ? new Date(object.disabled) : undefined
   }, {
      column: "DELETEDTS",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: Voucher) => object.deleted ? new Date(object.deleted) : undefined
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'items-' + Date.now() + '.xlsx'
   })
}

type VoucherExportProps = {
   ids: string[]
}

export const VouchersExport = ({ids}: VoucherExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportVouchersQuery({
      variables: {
         voucherIds: ids
      }
   });

   useEffect(() => {
      if (data?.VoucherByIds) {
         setOpen(true);
         (async function () {
            const items = (data.VoucherByIds || []) as Voucher[]
            await ExportToExcel(items);
            setOpen(false);
         })();
      }
   }, [data, ids])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}