import {Box, Dialog, DialogContent, IconButton, Paper, Slide, SvgIconProps} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router";
import {TitleText} from "../../layout/Typography";
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {ParamBrandHouse} from "../../Parameters";
import {useRecoilValue} from "recoil";
import {PreviousPathAtom} from "../../Hierarchy";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {DetailsPage} from "./details/DetailsPage";
import CloseIcon from '@mui/icons-material/Close';
import {TradeInAppTexts} from "./tradein/TradeInAppTexts";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {ShippingCarriers} from "./tradein/ShippingCarriers";
import {ContactPersonPage} from "./contactperson/ContactPersonPage";
import {AppLayout} from "./tradein/AppLayout";
import {CountriesPage} from "./tradein/CountriesPage";
import {VouchersPage} from "./rewards/VouchersPage";
import {CircularPage} from "./rewards/CircularPage";
import {
   Analytics,
   AppSettingsAlt,
   AttachMoney,
   Bookmark,
   CardGiftcard,
   CurrencyExchange, HistoryEdu,
   Language,
   LocalOffer,
   Mail,
   People,
   Person,
   Public,
   Save,
   ShoppingBasket,
   Style,
   VerticalSplit
} from "@mui/icons-material";
import {KlaviyoPage} from "./marketing/KlaviyoPage";
import {MailPage} from "./marketing/MailPage";
import {MailEditPage} from "./marketing/MailEditPage";
import {BasicItemSettings} from "./items/BasicItemSettings";
import {LanguageSettings} from "./languages/LanguageSettings";
import {SellingPriceSettings} from "./items/SellingPriceSettings";
import {AutoDataSettings} from "./items/AutoDataSettings";
import {LocaleItemSettings} from "./items/LocaleItemSettings";
import {TransitionProps} from "@mui/material/transitions";
import {useBrandSettingsPageHouseQuery} from "../../Queries";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {ShopSettings} from "./details/ShopSettings";
import {ShopCreate, ShopCreateStep2} from "./details/ShopCreate";
import {UsersListPage} from "./contactperson/UsersListPage";
import {UsersInvitePage} from "./contactperson/UsersInvitePage";
import {ConsumerSettingsPage} from "./tradein/ConsumerSettingsPage";
import {MasterDataSettingsPage} from "./advanced/masterdata/MasterDataSettingsPage";
import {ShopIntegrations} from "./details/ShopIntegrations";
import {ShopIntegrationSettings} from "./details/ShopIntegrationSettings";
import {BrandIntegrationSettings} from "./details/BrandIntegrationSettings";
import {OrderSettings} from "./orders/OrderSettings";
import {StyledTreeItem} from "../../layout/TreeViewItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {TreeView} from "@mui/lab";
import {AnalyticsPage} from "./tradein/AnalyticsPage";
import { ShopList } from "./details/ShopList";
import {OptionSetSettingsPage} from "./advanced/optionset/OptionSetSettingsPage";
import {OptionSetEditorPage} from "./advanced/optionset/OptionSetEditorPage";
import { BrandOptionsHistoryPage } from "./advanced/history/BrandOptionsHistoryPage";

type SettingItem = {
   title: string
   icon: React.ElementType<SvgIconProps>
   path: string
   element: React.ReactNode | null
   routes?: {
      path: string
      element: React.ReactNode
   }[]
}

type Item = {
   caption: string
   items: SettingItem[]
}

const Settings: Item[] = [{
   caption: 'General',
   items: [{
      title: 'Brand and shops',
      icon: AccountBalanceIcon,
      path: 'brand',
      element: <DetailsPage/>,
      routes: [{
         path: 'brand/:shopId',
         element: <ShopSettings/>
      }, {
         path: 'brand/integrations/:instanceId',
         element: <BrandIntegrationSettings/>
      }, {
         path: 'brand/create',
         element: <ShopCreate/>
      }, {
         path: 'brand/create/:type',
         element: <ShopCreateStep2/>
      }, {
         path: 'brand/:shopId/integrations',
         element: <ShopIntegrations/>
      }, {
         path: 'brand/:shopId/integrations/:instanceId',
         element: <ShopIntegrationSettings/>
      }, {
         path: 'brand/shops/:shopId',
         element: <ShopList/>
      }]
   }, {
      title: 'Contact details',
      icon: ReceiptIcon,
      path: 'contact',
      element: <ContactPersonPage/>
   }, {
      title: 'Users and permissions',
      icon: Person,
      path: 'users',
      element: <UsersListPage/>,
      routes: [{
         path: 'users/invite',
         element: <UsersInvitePage/>
      }]
   }, {
      title: 'Languages',
      icon: Language,
      path: 'languages',
      element: <LanguageSettings/>,
      routes: [{
         path: 'languages/:shopId',
         element: <LanguageSettings/>
      }]
   }]
}, {
   caption: 'Stock Items',
   items: [{
      title: 'General settings',
      icon: Style,
      path: 'basic',
      element: <BasicItemSettings/>,
      routes: [{
         path: 'basic/:shopId',
         element: <BasicItemSettings/>
      }]
   }, {
      title: 'Currencies',
      icon: Language,
      path: 'itemcurrencies',
      element: <LocaleItemSettings/>,
      routes: [{
         path: 'itemcurrencies/:shopId',
         element: <LocaleItemSettings/>
      }]
   }, {
      title: 'Selling prices',
      icon: AttachMoney,
      path: 'sellingprices',
      element: <SellingPriceSettings/>,
      routes: [{
         path: 'sellingprices/:shopId',
         element: <SellingPriceSettings/>
      }]
   }, {
      title: 'Auto data',
      icon: LocalOffer,
      path: 'autodata',
      element: <AutoDataSettings/>,
      routes: [{
         path: 'autodata/:shopId',
         element: <AutoDataSettings/>
      }]
   }, {
      title: 'Orders',
      icon: ShoppingBasket,
      path: 'orderset',
      element: <OrderSettings/>,
      routes: [{
         path: 'orderset/:shopId',
         element: <OrderSettings/>
      }]
   }]
}, {
   caption: 'TradeIn',
   items: [{
      title: 'Shipping carriers',
      icon: LocalShippingIcon,
      path: 'carriers',
      element: <ShippingCarriers/>
   }, {
      title: 'Countries',
      icon: Public,
      path: 'countries',
      element: <CountriesPage/>,
      routes: [{
         path: 'countries/:edit',
         element: <CountriesPage/>
      }]
   }, {
      title: 'App texts',
      icon: VerticalSplit,
      path: 'translations',
      element: <TradeInAppTexts/>,
      routes: [{
         path: 'translations/:edit',
         element: <TradeInAppTexts/>
      }]
   }, {
      title: 'App layout',
      icon: AppSettingsAlt,
      path: 'layout',
      element: <AppLayout/>,
      routes: [{
         path: 'layout/:edit',
         element: <AppLayout/>
      }]
   }, {
      title: 'App settings',
      icon: People,
      path: 'appsettings',
      element: <ConsumerSettingsPage/>,
      routes: [{
         path: 'appsettings/:edit',
         element: <ConsumerSettingsPage/>
      }]
   }, {
      title: 'Analytics',
      icon: Analytics,
      path: 'gatags',
      element: <AnalyticsPage/>,
      routes: [{
         path: 'gatags/:edit',
         element: <AnalyticsPage/>
      }]
   }]
}, {
   caption: 'Rewards',
   items: [{
      title: 'Vouchers',
      icon: CardGiftcard,
      path: 'evouchers',
      element: <VouchersPage/>,
      routes: [{
         path: 'evouchers/:edit',
         element: <VouchersPage/>
      }]
   }, {
      title: 'Circular point',
      icon: CurrencyExchange,
      path: 'ecircular',
      element: <CircularPage/>,
      routes: [{
         path: 'ecircular/:edit',
         element: <CircularPage/>
      }]
   }]
}, {
   caption: 'Communication',
   items: [{
      title: 'Klaviyo',
      icon: Bookmark,
      path: 'klaviyo',
      element: <KlaviyoPage/>,
      routes: [{
         path: 'klaviyo/:edit',
         element: <KlaviyoPage/>
      }]
   }, {
      title: 'Mails',
      icon: Mail,
      path: 'mails',
      element: <MailPage/>,
      routes: [{
         path: 'mails/:shopId',
         element: <MailPage/>
      }, {
         path: 'mails/:shopId/:mailType',
         element: <MailEditPage/>
      }, {
         path: 'mails/:shopId/:mailType/:language',
         element: <MailEditPage/>
      }]
   }]
}, {
   caption: 'Advanced',
   items: [{
      title: 'Masterdata',
      icon: Save,
      path: 'masterdata',
      element: <MasterDataSettingsPage/>
   },{
      title: 'Option Set',
      icon: Save,
      path: 'optionset',
      element: <OptionSetSettingsPage/>,
      routes: [{
         path: 'optionset/create',
         element: <OptionSetEditorPage/>
      }, {
         path: 'optionset/:optionSetId/edit',
         element: <OptionSetEditorPage/>
      }]
   }, {
      title: 'History',
      icon: HistoryEdu,
      path: 'history',
      element: <BrandOptionsHistoryPage/>,
      routes: [{
         path: 'history/:shopId',
         element: <BrandOptionsHistoryPage/>
      }]
   }]
}]

export const BrandSettingsPagePath = (brandId: string) => {
   return "/brands/" + brandId + "/settings"
}

export const BrandSettingsPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data} = useBrandSettingsPageHouseQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })
   const [expands, setExpands] = useState<string[]>(['0', '1', '2'])
   const previousPath = useRecoilValue(PreviousPathAtom);
   const navigate = useNavigate();
   const location = useLocation();

   const basePath = BrandSettingsPagePath(brandHouseId);

   const onHide = () => {
      if (previousPath) {
         navigate(previousPath);
      } else {
         navigate("/brands/" + brandHouseId)
      }
   }

   const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
      if (nodeId.includes('_')) return;
      if (expands.includes(nodeId)) {
         setExpands(expands.filter(expand => expand !== nodeId))
      } else {
         setExpands([...expands, nodeId])
      }
   };

   const activeSettings: SettingItem[] = useMemo(() => {
      const resultSettings: SettingItem[] = [];
      for (const setting of Settings) {
         resultSettings.push(...setting.items);
      }
      return resultSettings;
   }, []);

   useEffect(() => {
      if (location.pathname === basePath) {
         navigate(activeSettings[0].path)
      }
   }, [activeSettings, basePath, location.pathname, navigate])

   const selected = useMemo(() => {
      for (let i = 0; i < Settings.length; i++) {
         const setting = Settings[i];

         for (let j = 0; j < setting.items.length; j++) {
            const item = setting.items[j];
            const targetPath = basePath + '/' + item.path;

            const selected = location.pathname.includes(targetPath);
            if (selected) {
               let selectedId = i + '_' + j;
               return {
                  selected: selectedId,
                  expanded: [String(i), selectedId]
               }
            }
         }
      }
      return {
         selected: '0',
         expanded: ['0', '0_0']
      };
   }, [basePath, location.pathname])

   return <Dialog open={true} onClose={onHide} fullWidth={true} fullScreen sx={{
      padding: '26px 0 0 0'
   }} TransitionComponent={Transition}>
      <DialogTitle sx={{
         borderBottom: '1px solid #c0c0c0',
         padding: '8px 24px'
      }}>
         <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            alignItems: 'center',
            justifyContent: 'space-between'
         }}>
            <Box sx={{
               display: 'flex',
               flexDirection: 'row',
               gap: '20px'
            }}>
               <SettingsIcon sx={{color: '#606060'}}/>
               <TitleText type={"h1"}>
                  Settings for {data?.BrandHouse?.name}
               </TitleText>
            </Box>
            <IconButton onClick={onHide}>
               <CloseIcon/>
            </IconButton>
         </Box>
      </DialogTitle>
      <DialogContent sx={{backgroundColor: '#f0f0f0'}}>
         <Box sx={{display: 'flex', flexDirection: 'row', paddingTop: '30px', alignItems: 'flex-start'}}>
            <Paper sx={{width: '300px', padding: '20px 0 20px 0'}}>
               <TreeView sx={{
                  flexGrow: 1,
                  maxWidth: 400
               }}
                         selected={selected.selected}
                         onNodeSelect={handleSelect}
                         defaultExpandIcon={<ArrowRightIcon/>}
                         defaultCollapseIcon={<ArrowDropDownIcon/>}
                         expanded={expands.length===0 ? selected.expanded : expands}
               >
                  {Settings.map((header, index) => {
                     return <StyledTreeItem
                        key={'caption_' + index} sx={{marginLeft: '0', marginTop: index > 0 ? '10px' : '0px'}}
                        nodeId={String(index)}
                        labelText={header.caption}
                        dense={true}
                     >
                        {header.items.map((item, itemIndex) => {
                           const targetPath = basePath + '/' + item.path;
                           return <StyledTreeItem
                              key={'setting_' + item.path}
                              nodeId={index + '_' + itemIndex}
                              labelText={item.title}
                              labelIcon={item.icon}
                              onClick={() => navigate(targetPath)}>
                           </StyledTreeItem>
                        })}
                     </StyledTreeItem>
                  })}
               </TreeView>
            </Paper>
            <Box sx={{width: '100%', padding: '0 0 0 30px'}}>
               <Routes>
                  {activeSettings.map(setting => {
                     return <Route
                        key={'path_' + setting.path}
                        path={setting.path}
                        element={setting.element}/>
                  })}
                  {activeSettings.map(setting => {
                     if (!setting.routes) return null;
                     return setting.routes.map(route => <Route
                        key={'path_' + route.path}
                        path={route.path}
                        element={route.element}>
                     </Route>)
                  })}
               </Routes>
            </Box>
         </Box>
      </DialogContent>
   </Dialog>
}

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});
