import * as React from "react";
import {FC} from "react";
import {Box, Chip, Divider, Grid, LinearProgress, Table, TableCell, TableRow} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";
import {useStatTrackingTableItemQuery, useStatTrackingTableQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {DateUtility} from "../../utility/DateUtility";
import {getFilter, Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";
import {Thumbnails} from "../../components/Thumbnails";

type StatTrackingTableProps = {
   start: Date
   end: Date
   splits: Split[]
   brand?: BrandSelection
}

export const StatTrackingTable: FC<StatTrackingTableProps> = ({start, end, splits, brand}) => {
   const {data} = useStatTrackingTableQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: getFilter(splits),
         brandHouseIds: brand?.brandHouseIds,
         shopIds: brand?.shopIds
      },
      fetchPolicy: "no-cache"
   })
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   let totalViews = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'trackingTotals')?.value || 0;

   return <div>
      <TitleText type={"h2"}>Product views in period
         ({Math.round(DateUtility.dateDiffDays(end, start))} days)</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <Grid container>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Total views :
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}> {totalViews}</BodyText>
         </Grid>
      </Grid>
      <BodyText style={{marginTop: '20px'}} type={"subtitle1"}>Top 10 products viewed </BodyText>
      <Table padding={"none"}>
         {data.StatisticsFrequency?.lines?.map(line => {
            if (!line?._id) {
               return null;
            }
            return <TableRow>
               <TableCell>
                  <StockItemLine key={'statTrackLine_' + line._id} stockItemId={line._id}/>
               </TableCell>
               <TableCell>
                  {line.count}
               </TableCell>
            </TableRow>
         })}
      </Table>
   </div>
}

const StockItemLine = ({stockItemId}: { stockItemId: string }) => {
   const {data} = useStatTrackingTableItemQuery({
      variables: {
         stockItemId: stockItemId
      }
   })

   let size: string = data?.StockItemById?.resolved?.size || ''
   if (size === 'N/A') {
      size = ''
   }

   return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Thumbnails imageUrls={data?.StockItemById?.resolved?.imageUrls || []} size={20} maxImages={1}/>
      {data?.StockItemById?.resolved?.title} ({size.trim()})
      {data?.StockItemById?.isSold && <Chip size={"small"} sx={{padding: '5px 5px'}} label={"Sold"}/>}
   </Box>
}