import * as React from "react";
import {FC} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {TitleText} from "../../layout/Typography";

export type TrackerData = {
   splits: string[]
   kpis: string[]
   results: TrackerEntry[]
}

export type TrackerEntry = {
   kpi: string
   split: string
   value: number
}

export type TrackerViewProps = {
   title: string
   data: TrackerData
}

export const TrackerView: FC<TrackerViewProps> = ({title, data}) => {
   return <div>
      <Table>
         <TableHead>
            <TableRow>
               <TableCell><TitleText type={"h2"}>{title}</TitleText></TableCell>
               {data?.splits?.map(split => {
                  return <TableCell key={'split_' + split}>{split}</TableCell>
               })}
            </TableRow>
         </TableHead>
         <TableBody>
            {data.kpis?.map(kpi => {
               return <TableRow key={'row_' + kpi}>
                  <TableCell>{kpi}</TableCell>
                  {data.splits?.map(split => {
                     const value = data.results?.find(res => {
                        return res?.kpi && res?.split && res?.kpi === kpi && res?.split === split
                     })?.value;
                     return <TableCell key={'cell_' + kpi + '_' + split}>{value && Math.round(value)}</TableCell>
                  })}
               </TableRow>
            })}
         </TableBody>
      </Table>
   </div>
}