import {LinearProgress} from "@mui/material";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {DataColumn, DataTable} from "../../../components/DataTable";
import {useNavigate} from "react-router-dom";
import {NfcFragment, useNfcTagsQuery} from "../../../Queries";
import {StockItemEditPagePath} from "../../shop/stockitems/edit/StockItemEditPage";
import {BrandItemPagePath} from "../../brand/BrandItemPage";


export const NFCPage = () => {
   const {data} = useNfcTagsQuery();

   const navigate = useNavigate();
   if (!data?.NFCMany) {
      return <LinearProgress/>
   }

   const onSelect = (row: NfcFragment) => {
      if(row.stockItem) {
         navigate(StockItemEditPagePath(
            row.stockItem!.shop!.brandHouseId,
            row.stockItem!.shop!._id,
            row.stockItem!._id
         ));
      } else if(row.brandItem) {
         navigate(BrandItemPagePath(
            row.brandItem!.brandHouse!._id,
            row.brandItem!._id,
            false
         ));
      }
   }

   let columns: DataColumn<NfcFragment>[] = [{
      field: 'identifier',
      label: 'Identifier',
      searchable: true
   }, {
      label: 'Item',
      get: (data) => {
         return data.stockItem?._id
      },
      render: (stockItemId, row) => {
         if (row.stockItem) {
            return '#' + row.stockItem?.itemNr + ' - ' + row.stockItem?.shop?.name
         } else if(row.brandItem) {
            return '#' + row.brandItem?.itemNr + ' - ' + row.brandItem?.brandHouse?.name
         } else {
            return ''
         }
      }
   }]

   return <Page>
      <PageHeader title={"NFC TAGS"}/>
      <PageContent>
         <DataTable stateId={"NfcTags"} idField={"_id"} rows={data.NFCMany} columns={columns}
                    selections
                    onSingleSelect={onSelect}
         />
      </PageContent>
   </Page>
}