import {ShopIntegrationsPagePath} from "./ShopIntegrations";
import * as React from "react";
import {useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {EnumIntegrationConnectorRefType} from "../../../Queries";
import {RefIntegrationSettings} from "./RefIntegrationSettings";

export const ShopIntegrationSettingsPath = (brandHouseId: string, shopId: string, instanceId: string) => {
   return ShopIntegrationsPagePath(brandHouseId, shopId) + '/' + instanceId
}

export const ShopIntegrationSettings = () => {
   const {shopId, instanceId} = useParams<ParamBrandHouse & ParamShop & { instanceId: string }>()
   return <RefIntegrationSettings refId={shopId} refType={EnumIntegrationConnectorRefType.SHOP}
                                  instanceId={instanceId}/>
}
