import {FC} from "react";
import {Divider, Grid, LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";
import {useStatTradeInTableQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {DateUtility} from "../../utility/DateUtility";
import {StatCurrency} from "./StatCurrency";
import {getFilter, Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";

type StatTradeInTableProps = {
   start: Date
   end: Date
   brand?: BrandSelection
   splits: Split[]
}

export const StatTradeInTable: FC<StatTradeInTableProps> = ({start, end, splits, brand}) => {
   const {data} = useStatTradeInTableQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: getFilter(splits),
         shopIds: brand?.shopIds,
         brandHouseIds: brand?.brandHouseIds
      }
   })
   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   let tradeInItems = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'tradeInItems')?.value || 0;

   let voucherIssuedCount = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherIssuedCount')?.value || 0;
   let voucherIssuedValue = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherIssuedValue')?.value || 0;
   let voucherUsedCount = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherUsedCount')?.value || 0;
   let voucherUsedValue = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherUsedValue')?.value || 0;

   let voucherPaymentNetto = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherPaymentNetto')?.value || 0;
   //let voucherPaymentTotal = data.StatisticsCalculate?.results?.find(result => result?.kpi === 'voucherPaymentTotal')?.value || 0;

   let currency = brand?.currency||'DKK';
   return <div>
      <TitleText type={"h2"}>TradeIn statistics in period
         ({Math.round(DateUtility.dateDiffDays(end, start))} days)</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <Grid container>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Items:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}> {tradeInItems}</BodyText>
         </Grid>

         <Grid item xs={10} style={{marginTop: '10px'}}>
            <BodyText type={"body1"}>
               Vouchers issued:
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container style={{marginTop: '10px'}}>
            <BodyText type={"body1"}> {voucherIssuedCount}</BodyText>
         </Grid>
         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Vouchers issued ({currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={voucherIssuedValue}/>
            </BodyText>
         </Grid>

         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Vouchers used
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}> {voucherUsedCount}</BodyText>
         </Grid>

         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Vouchers used ({brand?.currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={voucherUsedValue}/>
            </BodyText>
         </Grid>

{/*
         <Grid item xs={10} style={{marginTop: '10px'}}>
            <BodyText type={"body1"}>
               Total purchase amount ({currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container style={{marginTop: '10px'}}>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(voucherPaymentTotal)}/>
            </BodyText>
         </Grid>
*/}

         <Grid item xs={10}>
            <BodyText type={"body1"}>
               Net additional payment ({currency})
            </BodyText>
         </Grid>
         <Grid item xs={2} justifyContent={"flex-end"} container>
            <BodyText type={"body1"}>
               <StatCurrency currency={currency} value={Math.round(voucherPaymentNetto)}/>
            </BodyText>
         </Grid>
      </Grid>
   </div>
}
