import {compile} from "expression-eval";
import {isNumber, isString} from "lodash";

const QFunctionFactory = {
   length: (value: any) => {
      let trimmed;
      if (!isString(value)) {
         trimmed = String(value);
      } else {
         trimmed = value;
      }
      trimmed = trimmed.trim()
      return trimmed.length;
   },
   isValidEmail: (value: string) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(String(value).toLowerCase());
   },
   isValidText: (value: string) => {
      return isString(value);
   },
   isValidNumber: (value: string) => {
      return isNumber(value);
   }
}

type CompiledExpression = (context: object) => any;
const compiledExpressions = new Map<string, CompiledExpression>();

export const QEvaluate = (expression: string, data: any, context?: any) => {
   let compiled: CompiledExpression | undefined;

   if (compiledExpressions.has(expression)) {
      compiled = compiledExpressions.get(expression);
   } else {
      compiled = compile(expression);
      compiledExpressions.set(expression, compiled);
   }

   if (!compiled) {
      throw new Error('Unable to compile expression : ' + expression);
   }

   let evaluationContext = {
      data: data,
      ...QFunctionFactory,
      ...context
   };
   return compiled(evaluationContext);
}