import * as React from "react";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {BodyText, TitleText} from "../../layout/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {CircularProgress, Dialog, DialogActions, DialogContent, Icon, LinearProgress} from "@mui/material";
import PageGroup from "../../layout/PageGroup";
import {OrdersList} from "../shop/components/OrdersList";
import Button from "@mui/material/Button";
import {OrdersPagePath} from "../shop/orders/OrdersPage";
import {TradeInsPagePath} from "../shop/tradeins/TradeInsPage";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import {Dialogs} from "../../DialogProvider";
import CheckIcon from '@mui/icons-material/Check';
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../atoms/CurrentUser";
import {DeleteOutline, Launch} from "@mui/icons-material";
import {TradeInsList} from "../shop/components/TradeInList";
import {BrandsPagePath} from "../brandhouse/ShopsPage";
import {useBrandPageBrandByIdQuery, useShopDeleteMutation} from "../../Queries";
import {DateFormat} from "../../utility/DateFormat";

export const BrandPage = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const [launching, setLaunching] = React.useState<'no' | 'progress' | 'completed'>('no');
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);
   const navigate = useNavigate();

   const {data} = useBrandPageBrandByIdQuery({
      variables: {
         shopId: shopId
      }
   })

   const [brandDelete] = useShopDeleteMutation();

   if (!data?.ShopById) {
      return <LinearProgress/>
   }

   const shop = data.ShopById;

   const onOrdersInsMore = () => {
      navigate(OrdersPagePath(brandHouseId, shopId));
   }
   const onTradeInsMore = () => {
      navigate(TradeInsPagePath(brandHouseId, shopId));
   }

   const onStop = () => {

   }

   const onLaunch = async () => {
      Dialogs.confirm({
         title: "Go live?",
         subtitle: "Please confirm that you wish to go-live with " + shop?.name + ". All test data will be deleted, and consumer data will be reset.\n" +
            "Please notice that all brandrules will be deactivated during the launch, and must be activated manually afterwards."
      }, () => {
         setLaunching('progress');
         setTimeout(async () => {
            //await launch(shopId);
            setLaunching('completed')
         }, 3000);
      })
   }

   const onDelete = async () => {
      Dialogs.confirm({
         title: "Delete shop?",
         subtitle: "Please confirm that you wish to delete  " + shop?.name + ". All data will be deleted, it is not possible to undo this operation !!"
      }, async () => {
         navigate(BrandsPagePath(brandHouseId))
         await brandDelete({
            variables: {
               shopId: shopId
            },
            refetchQueries: ["BrandHousesBrandMany"]
         });
      })
   }

   const onLaunchHide = () => {
      setLaunching('no')
   }

   const onLinkTradeIn = () => {
      if(shop.tradeInBaseUrl) {
         window.open(shop.tradeInBaseUrl, '_blank');
      }
   }
   const onLinkTradeShop = () => {
      if(shop.shopCollectionUrl) {
         window.open(shop.shopCollectionUrl, '_blank');
      }
   }

   return <>
      <Dialog open={launching !== 'no'} fullWidth={true} maxWidth={"sm"} onClick={onLaunchHide}>
         <DialogContent style={{display: 'flex', justifyContent: 'center', height: '160px', paddingTop: '100px'}}>
            {launching === 'progress' &&
            <TitleText type={"h1"}>Launching, please wait...</TitleText>
            }
            {launching === 'completed' &&
            <TitleText type={"h1"}>Success! {shop?.name} is now live!</TitleText>
            }
         </DialogContent>
         {launching === 'progress' && <>
            <DialogContent style={{display: 'flex', justifyContent: 'center', height: '200px'}}>
               <CircularProgress color={"secondary"} size={120}/>
            </DialogContent>
         </>}
         {launching === 'completed' && <>
            <DialogContent style={{display: 'flex', justifyContent: 'center', height: '150px'}}>
               <Icon color={"primary"} style={{fontSize: '100px'}}><CheckIcon style={{fontSize: '100px'}}/></Icon>
            </DialogContent></>}
         {launching === 'completed' && <DialogActions> <Button color={"secondary"} onClick={onLaunchHide}>Hide</Button>
         </DialogActions>}
      </Dialog>
      <Page>
         <PageHeader title={"Welcome " + shop?.name}>
            {isBrandHouse && <>
               {shop?.live ?
                  <Button color={"secondary"} variant={"text"} onClick={onStop}>Status: Live</Button>
                  :
                  <Button color={"secondary"} variant={"text"} onClick={onStop}>Status: Testing</Button>
               }
            </>
            }
            {!isBrandHouse && <>
               {shop?.live ?
                  <Button color={"secondary"} variant={"text"} onClick={onStop}>Status: Live</Button>
                  :
                  <Button color={"primary"} variant={"contained"} onClick={onLaunch}
                          startIcon={<PlayCircleFilledWhiteIcon/>}>Go Live</Button>
               }
            </>}
            {!isBrandHouse && <>
               <Button color={"secondary"} variant={"contained"} onClick={onDelete}
                       style={{marginLeft: '10px'}}
                       startIcon={<DeleteOutline/>}>Delete</Button>
            </>}
         </PageHeader>
         <PageGroup>
            <PageContent>
               <TitleText type={"subtitle1"}>{DateFormat.toDate(new Date())}</TitleText>
               <BodyText type={"body1"}>
                  Here you can access the most data relevant to your shop.<br/>
                  Please use the navigation area in right side, to see relevant data for your resale platform.<br/>
                  We hope you will enjoy your stay, and please let us know if we can do anything to improve your
                  experience<br/>
                  <br/>
                  Thank you for your time<br/>
                  The create2STAY Team
                  <br/>
               </BodyText>
            </PageContent>
            <PageContent>
               <TitleText type={"h2"}>Links</TitleText>
               <div>
                  <Button color={"secondary"} variant={"text"} startIcon={<Launch/>} onClick={onLinkTradeIn}
                          disabled={!shop.tradeInBaseUrl}>TradeIn Front</Button>
               </div>
               <div>
                  <Button color={"secondary"} variant={"text"} startIcon={<Launch/>} onClick={onLinkTradeShop}
                          disabled={!shop.shopCollectionUrl}>Shop Page</Button>
               </div>
            </PageContent>
         </PageGroup>
         <PageGroup>
            <PageContent>
               <TitleText type={"h2"}>Recent tradeins</TitleText>
               <div style={{marginTop: 20}}>
                  <TradeInsList
                     shopId={shopId} registered={false} rejected={true}
                     hideFilter={true}
                     hideColumns={['Consumer', 'Type', 'Logistics status']}
                     maxRows={5}
                     imageSize={70}
                     stocked={false} onSelectTradeIn={() => {
                  }}/>
                  <Button onClick={onTradeInsMore} variant={"text"} color={"primary"}>See more</Button>
               </div>
            </PageContent>
            <PageContent>
               <TitleText type={"h2"}>Recent orders</TitleText>
               <div style={{marginTop: 20}}>
                  <OrdersList
                     listId={"brandPageOrders"}
                     hideHeader={true}
                     shopId={shopId}
                     maxRows={5}
                     columns={['items','orderDate','orderUrl']}
                  />
               </div>
               <Button onClick={onOrdersInsMore} variant={"text"} color={"primary"}>See more</Button>
            </PageContent>
         </PageGroup>
      </Page></>
}
