import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {
   FlexCommand,
   FlexFilter,
   FlexListColumn,
   FlexListRequestData,
   FlexTab
} from "../../components/flexlist/FlexTypes";
import {
   EnumShippingLabelDeliveryStatus,
   FilterFindManyTradeInInput,
   ListsTradeInFragment,
   SortFindManyTradeInInput,
   useListsTradeInsListLazyQuery
} from "../../Queries";
import {Thumbnails} from "../../components/Thumbnails";
import {BodyText} from "../../layout/Typography";
import {FlexList} from "../../components/flexlist/FlexList";
import {DateFormat} from "../../utility/DateFormat";
import {BrandTradeInPagePath} from "../brand/tradeins/BrandTradeInPage";
import {FileDownload} from "@mui/icons-material";
import {TradeInExport} from "./TradeInExport";
import {Alert, Chip} from "@mui/material";

type ListsTradeInsListPageProps = {
   globalId: string
   extrafilter?: Partial<FilterFindManyTradeInInput>
   extracolumns?: FlexListColumn<ListsTradeInFragment>[]
   extracolumnsprepend?: boolean
}

export const ListTradeInsList = ({
                                    globalId,
                                    extrafilter,
                                    extracolumns,
                                    extracolumnsprepend
                                 }: ListsTradeInsListPageProps) => {
   const [fetchMore] = useListsTradeInsListLazyQuery();
   const [exportIds, setExportIds] = React.useState<string[]>([])

   const navigate = useNavigate();

   const commands: FlexCommand<FilterFindManyTradeInInput>[] = [{
      id: 'export',
      title: 'Export to excel',
      icon: FileDownload,
      execute: async (rows) => {
         setExportIds(rows.map(row => row._id) || [])
      }
   }]

   let columns: FlexListColumn<ListsTradeInFragment>[] = [{
      id: 'count',
      label: '#',
      get: (row) => {
         return row.tradeInNr
      },
      width: 4
   }, {
      id: 'items',
      label: 'Items',
      field: '_id',
      render: (data, row) => {
         const imageUrls: string[] = [];
         row?.items?.forEach(item => {
            if (item?.imageUrls?.length) {
               imageUrls.push(...item?.imageUrls)
            }
         })
         return <Thumbnails legend={String(row?.items?.length || 0)} maxImages={3} imageUrls={imageUrls} size={60}/>
      },
      width: 11
   }, {
      id: 'name',
      label: 'Navn, Mail',
      get: (row) => {
         return row.consumer?.name + ' ' + row.consumer?.mail
      },
      render: (data, row) => {
         return <div style={{overflow: 'hidden', maxWidth: '180px', whiteSpace: 'nowrap'}}>
            <BodyText type={"body2"} title={row?.consumer?.name || ''}>{row?.consumer?.name}</BodyText>
            <BodyText type={"subtitle2"} title={row?.consumer?.mail || ''}>{row?.consumer?.mail}</BodyText>
         </div>
      },
      width: 23
   }, {
      id: 'shopId',
      label: 'Store',
      get: (row) => {
         return row.shop?.name
      },
      width: 12
   }, {
      id: 'date',
      label: 'Date',
      field: 'dateTime',
      render: (date, row) => {
         return DateFormat.toPresent(date)
      },
      width: 10
   }, {
      id: 'reward',
      label: 'Reward',
      get: (row) => {
         return row._id
      },
      render: (id, row) => {
         const first = row?.vouchers?.[0]
         if (first) {
            const total = row?.vouchers?.reduce((a, b) => a + (b?.value || 0), 0);
            return <div key={'tradein_voucher_' + first?.code}>
               <div style={{marginBottom: '1px'}}>
                  {total + ' ' + first?.currency}
               </div>
            </div>
         } else {
            return <div>None</div>
         }
      },
      width: 10
   }, {
      id: 'registered',
      label: 'Status',
      field: 'registered',
      get: (row) => {
         return !!row.registered
      },
      render: (registered, row) => {
         if (!registered) {
            const deliveryStatus = row?.label?.deliveryStatus;
            if (deliveryStatus === EnumShippingLabelDeliveryStatus.DELIVERED) {
               return <Chip size={"small"} color={"primary"} label={"Delivered"} sx={{opacity: 0.7}}/>
            } else if (deliveryStatus === EnumShippingLabelDeliveryStatus.PROGRESSING) {
               return <Chip size={"small"} color={"warning"} label={"In transit"}/>
            } else if (deliveryStatus === EnumShippingLabelDeliveryStatus.WAITING) {
               return <Chip size={"small"} color={"secondary"} label={"Not sent"} sx={{opacity: 0.7}}/>
            } else if (deliveryStatus === EnumShippingLabelDeliveryStatus.UNKNOWN) {
               return <Chip size={"small"} color={"secondary"} label={"No info"} sx={{opacity: 0.3}}/>
            } else if (deliveryStatus === EnumShippingLabelDeliveryStatus.WARNING) {
               return <Chip size={"small"} color={"secondary"} label={"No info"} sx={{opacity: 0.5}}/>
            } else {
               return <div>None</div>
            }
         }
         return <Chip size={"small"} color={"success"} label={"Received"}/>
      },
      width: 15
   }, {
      id: 'logistics',
      label: 'Logistics',
      get: (row) => {
         let stocked = row.items.filter(item => item.stockItem || item.brandItem).length
         let unstocked = row.items.filter(item => !item.rejected && !(item.stockItem || item.brandItem)).length
         let rejected = row.items.filter(item => item.rejected).length
         return {
            stocked: stocked,
            unstocked: unstocked,
            rejected
         }
      },
      render: (data, row) => {
         if (!row?.registered) {
            return <>Awaiting registration</>
         }
         if (data.unstocked > 0) {
            return <Alert sx={{padding: '0 5px 0 5px'}} severity={"warning"}>
               {data.stocked > 0 && <>{data.stocked} stocked<br/></>}
               {data.unstocked} in process
            </Alert>
         } else if (!!data.stocked) {
            return <Alert sx={{padding: '0 5px 0 5px'}} severity={"success"}>
               {data.stocked} stocked
            </Alert>
         } else if (!!data.rejected) {
            return <Alert sx={{padding: '0 5px 0 5px'}}  severity={"error"}>{data.rejected} rejected</Alert>
         }
      },
      width: 15
   }]

   const filters: FlexFilter<FilterFindManyTradeInInput>[] = [{
      id: 'typen',
      label: 'Type',
      options: [{
         label: 'Registered',
         filter: {
            registered: true
         }
      }]
   }]

   const tabsSpec: FlexTab[] = [{
      id: 'All',
      label: 'All',
      filters: []
   }, {
      id: 'Registered',
      label: 'Registered',
      filters: [{
         filterId: 'typen',
         values: ['Registered']
      }]
   }]

   const handleSelect = (row: ListsTradeInFragment) => {
      navigate(BrandTradeInPagePath(row.brandHouseId, row._id));
   }

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort || SortFindManyTradeInInput._ID_DESC) as SortFindManyTradeInInput,
            perPage: 20,
            search: search,
            filter: {
               ...filter,
               ...extrafilter
            } as FilterFindManyTradeInInput
         }
      });
      return {
         data: data?.TradeInPagination?.items || [],
         totalCount: data?.TradeInPagination?.count || 0
      } as FlexListRequestData<ListsTradeInFragment>
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fetchMore]);

   return <>
      <FlexList globalId={globalId}
                columns={columns}
                filters={filters}
                tabs={tabsSpec}
                itemsPerPage={20}
                options={{
                   itemHeight: 80
                }}
                commands={commands}
                selections={true}
                onSelectRow={handleSelect}
                onRequestData={onRequestData}
      />
      {exportIds.length > 0 && <TradeInExport language={"da"} currency={"DKK"} ids={exportIds}/>}
   </>
}
