import * as React from "react"
import {FC} from "react"
import {Checkbox, FormControlLabel} from "@mui/material";
import {QComponentProps} from "../Model";
import {cloneDeep, get, set} from "lodash";
import makeStyles from '@mui/styles/makeStyles';

type QCheckBoxProps = QComponentProps & {
   field: string
   label: string
   required?: boolean
}

export const QCheckBox: FC<QCheckBoxProps> = ({field, data, label, update}) => {
   const classes = useStyles();

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.checked;
      let clonedData = cloneDeep(data);
      set(clonedData, field, value || false);
      update(clonedData);
   };

   return <div className={classes.container}>
      <FormControlLabel control={
         <Checkbox
            id={'check-' + field}
            checked={get(data, field, false)}
            onChange={handleChange}
            name={field}
            color="primary"
         />
      } label={label}/></div>
}


const useStyles = makeStyles({
   container: {
      width: '100%',
      padding: '0px 0px'
   },
});

