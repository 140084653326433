import {TreeItem, TreeItemProps} from "@mui/lab";
import * as React from "react";
import {SvgIconProps, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type StyledTreeItemProps = TreeItemProps & {
   bgColor?: string;
   color?: string;
   labelIcon?: React.ElementType<SvgIconProps>;
   labelInfo?: string;
   labelText: string;
   action?: JSX.Element
   dense?: boolean
};

export const StyledTreeItem = (props: StyledTreeItemProps) => {
   const classes = useTreeItemStyles({
      dense: props.dense
   });
   const {labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other} = props;

   return (
      <TreeItem
         label={
            <div className={classes.labelRoot}>
               {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon}/>}
               <Typography variant="body2" className={classes.labelText}>
                  {labelText}
               </Typography>
               <Typography variant="caption" color="inherit">
                  {labelInfo}
               </Typography>
               {props.action && props.action}
            </div>
         }
         classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
         }}
         {...other}
      />
   );
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         color: theme.palette.text.secondary,
      },
      content: {
         color: theme.palette.text.secondary,
         borderTopRightRadius: theme.spacing(2),
         borderBottomRightRadius: theme.spacing(2),
         paddingRight: theme.spacing(1),
         fontWeight: theme.typography.fontWeightMedium,
         "&.Mui-focused": {
            backgroundColor: 'inherit'
         }
      },
      group: {
         '& $content': {
            paddingLeft: theme.spacing(2),
         },
         marginLeft: (props:any) => (props.dense?'0':undefined)
      },
      expanded: {
         marginLeft: '0',
      },
      selected: {
      },
      label: {
         fontWeight: 'inherit',
      },
      labelRoot: {
         display: 'flex',
         alignItems: 'center',
         padding: theme.spacing(0.5, 0),
      },
      labelIcon: {
         marginRight: theme.spacing(1),
      },
      labelText: {
         fontWeight: 'inherit',
         flexGrow: 1,
      },
   }),
);
