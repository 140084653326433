import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Box, Button, Container, LinearProgress, Paper, Theme} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
   InviteAcceptPageCurrentUserDocument,
   useInviteAcceptInviteMutation,
   useInviteAcceptPageCurrentUserQuery,
   UserAccess
} from "../../Queries";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {SingleClickButton} from "../../components/SingleClickButton";

export const InviteAcceptPagePath = () => {
   return "/account/accept"
}

type AcceptData = {
   invitations: UserAccess[]
   total: UserAccess[]
}

export const InviteAcceptPage = () => {
   const {data} = useInviteAcceptPageCurrentUserQuery()
   const [access, setAccess] = useState<AcceptData | undefined>()
   const classes = useStyles();
   const [accept] = useInviteAcceptInviteMutation()

   const navigate = useNavigate();

   useEffect(() => {
      if (data) {
         const validAccesses = (data?.CurrentUser?.access || []).filter(access => !access!.disabled) as UserAccess[];
         setAccess({
            total: validAccesses,
            invitations: validAccesses.filter(access => !!access?.inviteTokenTs)
         })
      }
   }, [data])

   useEffect(() => {
      if (access) {
         if (access.total.length > 0 && access.invitations.length === 0) {
            navigate('/brands')
         }
      }
   }, [access, navigate])

   if (!access) {
      return <LinearProgress/>
   }
   const onAccept = async (invite: UserAccess) => {
      await accept({
         variables: {
            token: invite.inviteToken!
         },
         refetchQueries: [{
            query: InviteAcceptPageCurrentUserDocument
         }]
      });
   }

   return <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline/>
      <div className={classes.paper}>
         <Typography component="h1" variant="h5" style={{marginBottom: '20px'}}>
            Welcome {data?.CurrentUser?.name}
         </Typography>
         {access.total.length === 0 && <Typography>
            Your account currently has no access to any data
         </Typography>
         }
         {access.invitations.length > 0 &&
            <>
               <Typography>
                  The following invitations are pending your acceptance:
               </Typography>

               <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '30px'}}>
                  {access.invitations.map(invitation => {
                     return <Paper variant={"outlined"} sx={{
                        alignItems: 'center',
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                     }}>
                        <div>
                           {invitation?.brandHouseName || invitation?.logisticsPartnerName}
                        </div>
                        <div>
                           <SingleClickButton onClick={() => onAccept(invitation)} variant={"contained"} size={"small"}
                                              color={"secondary"}>Accept</SingleClickButton>
                           <Button disabled={true} variant={"outlined"} size={"small"}
                                   color={"secondary"}>Reject</Button>
                        </div>
                     </Paper>
                  })}
               </Box>
            </>
         }
      </div>
   </Container>
}


const useStyles = makeStyles((theme: Theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px'
   },
   container: {
      backgroundColor: 'white',
      padding: '0px 20px 20px',
      marginTop: '20px'
   }
}));