import readXlsxFile from 'read-excel-file'
import {isEqual} from "lodash";
import {Box, LinearProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {
   BrandItemInputInput,
   BrandItemStateEnum,
   BrandProductSize,
   useBrandItemImportSearchLazyQuery,
   useBrandItemUpdateMutation
} from "../../Queries";
import {useParams} from "react-router-dom";
import {ParamBrandHouse, ParamBrandItem} from "../../Parameters";
import {styled} from "@mui/styles";

export type ImportRowType = {
   identifier: string
   styleName: string
   styleId: string
   color: string
   size: string
   ean: string
   NFC: string
   condition?: number
}

export type ImportType = {
   name: string
   search: string
   rows: ImportRowType[]
}

export const ImportItemsFromExcel = async (file: File): Promise<ImportType> => {
   const rows = await readXlsxFile(file);

   const result: ImportRowType[] = [];

   for (let row = 2; row < rows.length; row++) {
      const cells = rows[row];
      if (row === 2) {
         //
         // Header row
         //
         const columns = cells.filter(cell => !!cell).map(cell => cell as string)
         if (!isEqual(columns, ['Identifier', 'Style', 'StyleId', 'EAN', 'Color', 'Size', 'NFC', 'Condition'])) {
            throw new Error('Does not match')
         }
      } else {
         //
         // Data cells
         //

         result.push({
            identifier: String(cells[1]),
            color: String(cells[5]),
            size: String(cells[6]),
            styleName: String(cells[2]),
            styleId: String(cells[3]),
            ean: String(cells[4]),
            NFC: String(cells[7]),
            condition: parseInt(String(cells[8]))
         })
      }
   }
   return {
      rows: result,
      name: String(rows[0][1]),
      search: String(rows[1][2])
   } as ImportType;
}

type ImportItemsProps = {
   languages: string[]
   searchField: string
   rows: ImportRowType[]
   onComplete: () => Promise<void>
}

export const ImportItems = ({languages, searchField, rows, onComplete}: ImportItemsProps) => {
   const {brandHouseId} = useParams<ParamBrandHouse & ParamBrandItem>()

   const [value, setValue] = useState<number>(0);
   const [search] = useBrandItemImportSearchLazyQuery()
   const [updateItem] = useBrandItemUpdateMutation()

   useEffect(() => {
      (async function () {
         if (value >= rows.length) {
            return;
         }
         const row = rows[value];
         if (!row) {
            onComplete();
            return;
         }

         let searchFor: string = ''
         if (searchField) {
            const field = Object.keys(row).find(key => key.toLowerCase() === searchField.toLowerCase());
            if (field) {
               if ((row as any)[field]) {
                  searchFor = (row as any)[field];
               }
            }
         }
         if (!searchFor) {
            searchFor = row.styleId
         }
         const {data} = await search({
            variables: {
               search: searchFor,
               brandHouseId
            }
         })

         if (data?.BrandProductsPagination) {
            let matchVariantSize: BrandProductSize | undefined = undefined;

            const products = data.BrandProductsPagination?.items || [];
            for (const item of products) {
               const variants = item.variants || [];

               for (const variant of variants) {
                  for (const size of variant?.sizes||[]) {
                     if(size && size.ean===searchFor) {
                        matchVariantSize = size as BrandProductSize
                        break;
                     }
                  }
                  if(matchVariantSize) break;
               }

               for (const variant of variants) {
                  let matchColor: boolean = false;
                  for (const colorLocalizedElement of variant?.colorLocalized || []) {
                     if (colorLocalizedElement?.value === row.color) {
                        matchColor = true;
                     }
                  }
                  let matchSize: boolean = false;
                  for (const size of variant?.sizes||[]) {
                     for (const sizeElement of size?.sizeLocalized || []) {
                        if (sizeElement?.value === row.size) {
                           matchSize = true;
                        }
                     }
                  }

                  if (matchColor && matchSize) {
                     matchVariantSize = variant as BrandProductSize;
                     break;
                  }
               }
            }

            let item: BrandItemInputInput = {
               itemState: BrandItemStateEnum.NONE,
               condition: row.condition || 2,
               externalId: row.identifier,
               nfcTag: row.NFC
            }

            if (matchVariantSize) {
               item.brandProductSizeId = matchVariantSize._id
               item.nameLocalized = undefined
               item.colorLocalized = undefined
               item.sizeLocalized = undefined
               item.originalSku = undefined
               item.note = row.identifier
            } else {
               item.note = row.identifier + ' (No masterdata match)'
               item.nameLocalized = languages.map(language => {
                  return {
                     locale: language,
                     value: row.styleName
                  }
               })
               item.originalSku = row.styleId
               item.colorLocalized = languages.map(language => {
                  return {
                     locale: language,
                     value: row.color
                  }
               })
               item.sizeLocalized = languages.map(language => {
                  return {
                     locale: language,
                     value: row.size
                  }
               })
            }

            await updateItem({
               variables: {
                  brandHouseId,
                  input: item
               }
            });
         } else {
            console.log('Not receiving data from search')
         }
      })().then(() => {
         // Trigger the next row
         setTimeout(() => {
            if (value >= rows.length) {
               (async function () {
                  await onComplete()
               }());
            } else {
               setValue(value + 1)
            }
         }, 50)
      });
   }, [value, rows, search, onComplete, updateItem, brandHouseId, languages, searchField])

   let completion = Math.round((value * 100) / rows.length);
   return <Box>
      <BorderLinearProgress variant={"determinate"} value={completion}/>
   </Box>
}

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
   height: 10,
   borderRadius: 5
}));
