import {
   EnumIntegrationConnectorRefType, EnumIntegrationConnectorRefTypes,
   RefIntegrationConfigDocument,
   useRefIntegrationConfigQuery,
   useRefIntegrationInstallConnectorMutation,
   useRefIntegrationUninstallMutation
} from "../../../Queries";
import {Box, Button, LinearProgress} from "@mui/material";
import {AddConnectorMenu} from "./AddConnectorMenu";
import * as React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {MissingImageIcon} from "../../../components/Icons";
import {PageCards} from "../../../layout/PageCards";
import {Dialogs} from "../../../DialogProvider";
import {SystemMessages} from "./RefSystemMessages";

type RefIntegrationsProps = {
   refId: string
   refType: EnumIntegrationConnectorRefType
   onNavigateTo: (connectorId: string) => void
   onBack?: boolean
   children?: React.ReactNode | React.ReactNode[] | null
   title?: string
   deleteText?: string
}

export const RefIntegrations = ({title, refId, refType, onNavigateTo, onBack, children, deleteText}: RefIntegrationsProps) => {
   const {data} = useRefIntegrationConfigQuery({
      variables: {
         refId,
         refType: [refType as any as EnumIntegrationConnectorRefTypes]
      }
   })
   const [installConnector] = useRefIntegrationInstallConnectorMutation()
   const [uninstall] = useRefIntegrationUninstallMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const instances = data.IntegrationInstances || []

   const onInstall = async (connectorId: string) => {
      await installConnector({
         variables: {
            refType,
            refId,
            connectorId
         },
         refetchQueries: [{
            query: RefIntegrationConfigDocument,
            variables: {
               refId,
               refType
            }
         }]
      })
   }

   const onDelete = (id: string) => async (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      const instance = instances.find(instance => String(instance._id) === id);
      const connector = data.IntegrationConnectors?.find(connector => connector._id === instance?.connectorId)

      if (connector) {
         Dialogs.confirm({
            title: 'Confirm uninstallation of ' + (instance?.name || connector.name),
            subtitle: 'This cannot be undone'
         }, async () => {
            await uninstall({
               variables: {
                  refId: refId,
                  refType: EnumIntegrationConnectorRefType.LOGISTICSPARTNER,
                  instanceId: id
               },
               refetchQueries: [{
                  query: RefIntegrationConfigDocument,
                  variables: {
                     refId,
                     refType
                  }
               }]
            })
         })
      }
   }

   const onSettings = (instanceId: string) => () => {
      onNavigateTo(instanceId)
   }

   return <PageCards
      onBack={onBack}
      title={title || "Integrations"}
      emptyMessage={"No integrations installed"}
      commands={<Box sx={{display: 'flex', columnGap: '10px'}}>
         {children}
         <AddConnectorMenu refId={refId} onSelect={onInstall} refType={refType}/>
      </Box>}
      cards={instances.map(instance => {
         const connector = data.IntegrationConnectors?.find(connector => connector._id === instance.connectorId)
         return {
            icon: connector?.icon || <MissingImageIcon/>,
            name: instance.name || connector?.name || 'Unnamed',
            description: 'Messages ' + (instance.configs?.filter(config => config?.enabled && !SystemMessages.includes(config.message as any)) || []).length,
            commands: <Button sx={{color: 'rgb(142, 31, 11)'}} onClick={onDelete(instance._id)}>
               <DeleteForeverIcon/>
               Uninstall
            </Button>,
            onClick: onSettings(instance._id)
         }
      })}/>
}
