import * as React from "react";
import Page from "../../layout/Page";
import {InvoicesList} from "../shop/components/InvoicesList";
import PageContent from "../../layout/PageContent";

export const LogisticsInvoicesPage = () => {
   return <Page>
      <PageContent>
         <InvoicesList/>
      </PageContent>
   </Page>
}

