export const MS_ONEHOUR = 1000 * 60 * 60;
export const MS_ONEDAY = MS_ONEHOUR * 24;
export const MS_ONEWEEK = MS_ONEDAY * 7;

/**
 * Will return the difference in days. If the now/current date is before the otherdate then the result will be negative days.
 * @param now The current date
 * @param otherDate The other date to compare with
 */
const dateDiffDays = (now: Date | string, otherDate: Date | string) => {
   let newNow = new Date(now);
   let newOther = new Date(otherDate);
   let ageDifferenceMs = newNow.getTime() - newOther.getTime();
   return ageDifferenceMs / MS_ONEDAY
}

/**
 * Will return the difference in weeks. If the now/current date is before the otherdate then the result will be negative weeks.
 * @param now The current date
 * @param otherDate The other date to compare with
 */
const dateDiffWeeks = (now: Date | string, otherDate: Date | string) => {
   let newNow = new Date(now);
   let newOther = new Date(otherDate);
   let ageDifferenceMs = newNow.getTime() - newOther.getTime();
   return ageDifferenceMs / MS_ONEWEEK
}

/**
 * Will return the difference in months. If the now/current date is before the otherdate then the result will be negative months.
 * @param now The current date
 * @param otherDate The other date to compare with
 */
const dateDiffMonths = (now: Date | string, otherDate: Date | string) => {
   let newNow = new Date(now);
   let newOther = new Date(otherDate);

   let months;
   months = (newNow.getFullYear() - newOther.getFullYear()) * 12;
   months -= newOther.getMonth();
   months += newNow.getMonth();
   return months <= 0 ? 0 : months;
}

export const DateUtility = {
   dateDiffDays,
   dateDiffWeeks,
   dateDiffMonths
}