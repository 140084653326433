import {atom, selector} from "recoil";

export type QrSessionInfo = {
   id: string,
   state: 'sender' | 'receiver'
};

export type UserType = 'administrator' | 'user' | 'brandhouse';

export type UserInfo = {
   token: string,
   brandhouse: boolean
   brandHouseId?: string
   shopId?: string
   hideFrame?: boolean
   qrSession?: QrSessionInfo
   userType: UserType
   userEmail?: string
   brandMode?: boolean
   simplifiedMode?: boolean
}

export const LoggingOut = atom<boolean>({
   key: 'loggingout',
   default: false
})

export const CurrentUserState = atom<UserInfo | null>({
   key: 'currentuser',
   default: null
})

export const CurrentUserIsShop = selector({
   key: 'currentuserShopLogin',
   get: ({get}) => {
      return !!get(CurrentUserState)?.shopId
   }
})

export const CurrentUserIsAdmin = selector({
   key: 'currentuserAdminLogin',
   get: ({get}) => {
      let newVar = get(CurrentUserState);
      return (newVar?.userType) === 'administrator' && !newVar.brandMode
   }
})

export const UserSimplified = selector({
   key: 'currentusersimplified',
   get: ({get}) => {
      let newVar = get(CurrentUserState);
      return newVar?.simplifiedMode || false
   }
})


export const getFromStorage = (): UserInfo | null => {
   let isBrandHouse = sessionStorage.getItem("brandHouse") === "true";
   let accessToken = sessionStorage.getItem("accessToken");
   let brandHouseId = sessionStorage.getItem("brandHouseId");
   let shopId = sessionStorage.getItem("shopId");
   let qrSessionId = sessionStorage.getItem("qrSessionId");
   let qrSessionState = sessionStorage.getItem("qrSessionState");

   let hideFrameStr = sessionStorage.getItem("hideFrame")
   let hideFrame = hideFrameStr ? Boolean(hideFrameStr) : undefined;

   let userTypeStr = sessionStorage.getItem("userType")
   let simplifiedStr = sessionStorage.getItem("simplified")
   let userEmail = sessionStorage.getItem("userEmail")

   let qrSession = undefined;
   if (qrSessionId && qrSessionState) {
      qrSession = {
         id: qrSessionId,
         state: qrSessionState
      } as QrSessionInfo
   }

   if (accessToken) {
      return {
         token: accessToken,
         brandhouse: isBrandHouse,
         brandHouseId: brandHouseId || undefined,
         shopId: shopId || undefined,
         qrSession: qrSession,
         hideFrame: hideFrame,
         userType: userTypeStr as UserType,
         userEmail: userEmail || undefined,
         simplifiedMode: simplifiedStr ? Boolean(simplifiedStr) : false
      }
   } else {
      return null;
   }
}

export const saveToStorage = (info: UserInfo | null) => {
   if (info) {
      if (info.brandhouse) {
         sessionStorage.setItem("brandHouse", String(info.brandhouse));
         sessionStorage.setItem("brandHouseId", info.brandHouseId!);
         sessionStorage.setItem("shopId", info.shopId!);
      }
      sessionStorage.setItem("accessToken", info.token);
      if (info.qrSession) {
         sessionStorage.setItem("qrSessionId", info.qrSession.id);
         sessionStorage.setItem("qrSessionState", info.qrSession.state);
      }
      if (info.hideFrame) {
         sessionStorage.setItem("hideFrame", String(info.hideFrame));
      }
      sessionStorage.setItem("userType", String(info.userType));
      if (info.simplifiedMode) {
         sessionStorage.setItem("simplified", "true");
      }
      if (info.userEmail) {
         sessionStorage.setItem("userEmail", String(info.userEmail));
      }
   } else {
      sessionStorage.removeItem("brandHouse");
      sessionStorage.removeItem("brandHouseId");
      sessionStorage.removeItem("shopId");
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("qrSessionId");
      sessionStorage.removeItem("qrSessionState");
      sessionStorage.removeItem("hideFrame");
      sessionStorage.removeItem("userType");
      sessionStorage.removeItem("simplified");
      sessionStorage.removeItem("userEmail");
   }
}