import {gql, useMutation} from "@apollo/client";

const QL_WHOLESALE_CREATE = gql`
mutation WholeSaleCategoryCreate($record:CreateOneWholeSaleCategoryInput!) {
  WholeSaleCategoryCreate(record: $record) {
    recordId    
  }
}
`

const useWholeSaleCreator = () => {
   const [wholeSaleCreate] = useMutation(QL_WHOLESALE_CREATE);

   return (shopId: string) => {
      return wholeSaleCreate({
         variables: {
            record: {
               shopId: shopId,
               name: "New category",
               value: 100,
               voucherStrategy: 'INSTANT'
            }
         },
         update(cache, {data}) {
            cache.evict(data.WholeSaleCategoryCreate.recordId);
         }
      }).then(data => data.data.WholeSaleCategoryCreate?.recordId)
   }
}

export default useWholeSaleCreator;