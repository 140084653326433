import * as React from "react";
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {AppBar, IconButton, Theme, Toolbar, useMediaQuery} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {CurrentUserState, LoggingOut} from "../atoms/CurrentUser";

const useStyles = makeStyles((theme: Theme) => ({
   root: {
      boxShadow: 'none',
      borderRadius: 0
   },
   flexGrow: {
      flexGrow: 1
   },
   signOutButton: {
      marginLeft: theme.spacing(1)
   },
   title: {
      color: 'white',
      textDecoration: 'none',
   }
}));

type TopbarProps = {
   className?: string,
   onSidebarOpen: () => void,
   anchor: "left" | "right"
}

const TopBar = (props: TopbarProps) => {
   const {className, onSidebarOpen, ...rest} = props;
   const currentUser = useRecoilValue(CurrentUserState);
   const setLoggingOut = useSetRecoilState(LoggingOut);
   const isDesktop = useMediaQuery('(min-width:1200px)');

   const classes = useStyles();

   const onLogout = () => {
      setLoggingOut(true);
   }

   return (
      <AppBar
         {...rest}
         className={clsx(classes.root, className)}
         sx={{
            borderRadius: '0px !important',
            border: '1px solid transparent'
         }}
      >
         <Toolbar variant={"dense"}>
            {props.anchor === "left" &&
            <IconButton color="inherit" onClick={onSidebarOpen} size="large">
               <MenuIcon/>
            </IconButton>
            }
            <RouterLink to="/" style={{textDecoration: 'none'}}>
               <div className={classes.title}>create2STAY</div>
            </RouterLink>
            <div className={classes.flexGrow}/>
            Logged in as {currentUser?.userEmail || 'Unknown'}
            {(!currentUser?.shopId || props.anchor === "left") &&
               isDesktop && <IconButton
                  className={classes.signOutButton}
                  color="inherit"
                  onClick={onLogout}
                  title={"Logout"}
                  size="large">
                  <InputIcon/>
               </IconButton>}
            {props.anchor === "right" &&
            <IconButton color="inherit" onClick={onSidebarOpen} size="large">
               <MenuIcon/>
            </IconButton>
            }
         </Toolbar>
      </AppBar>
   );
};

export default TopBar;
