import * as React from "react";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {OrdersList} from "../components/OrdersList";
import {OrderPagePath} from "./OrderPage";
import {OrderFragment} from "../../../Queries";

export const OrdersPagePath = (brandHouseId: string, shopId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/orders"
}

export const OrdersPage: FC<any> = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const onSelectOrder = (row: OrderFragment) => {
      navigate(OrderPagePath(brandHouseId, shopId, row._id))
   }

   return <Page>
      <PageHeader title={"Orders"}/>
      <PageContent fullHeight>
         <OrdersList
            listId={"ordersPage" + brandHouseId}
            shopId={shopId}
            onSelectOrder={onSelectOrder}/>
      </PageContent>
   </Page>
}