import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {BodyText, TitleText} from "../../layout/Typography";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

type PageHeaderProps = {
   title: string | JSX.Element
   subtitle?: string | JSX.Element
   onBack?: boolean
}

const SettingsHeader: React.FC<PageHeaderProps> = ({title, subtitle, children, onBack}) => {
   const containerRef = React.useRef<HTMLDivElement | null>(null);
   const classes = useStyles();
   const navigate = useNavigate();

   const onHandleBack = () => {
      navigate(-1)
   }

   return <div ref={containerRef} className={classes.fixed} style={{top: 0}}>
      <div className={classes.root}>
         <div className={classes.cell}>
            {onBack && window.history?.length > 0 &&
            <Button color={"secondary"} variant={"outlined"} size={"small"} onClick={onHandleBack}
                    sx={{marginRight: '20px'}}>
               <ArrowBackIcon/>
            </Button>}
            <Box>
               <TitleText type={"h1"}
                          style={{display: 'inline-block', position: 'relative', top: '4px'}}>
                  {title}
               </TitleText>
               {(subtitle && typeof subtitle === 'string') && <BodyText type={"subtitle1"}>
                  {subtitle}
               </BodyText>}
               {(subtitle && typeof subtitle !== 'string') && <>{subtitle}</>}
            </Box>
         </div>
         <Box sx={{display: 'flex', gap: '5px'}}>
            {children}
         </Box>
      </div>
   </div>
}


const useStyles = makeStyles({
   fixed: {
      marginLeft: '-10px',
      marginRight: '-10px',
      marginBottom: '15px',
      paddingTop: '0px',
      paddingBottom: '10px',
      position: 'sticky',
      zIndex: '1100',
      backgroundColor: '#f0f0f0',
      paddingLeft: '14px',
      paddingRight: '14px'
   },
   root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 0,
   },
   cell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start'
   }
})

export default SettingsHeader