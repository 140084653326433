import {FC} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {BodyText} from "./Typography";
import {makeStyles} from "@mui/styles";
import useLocalStorage from "../hooks/useLocalStorage";

type PageAccordionProps = {
   title: JSX.Element | string
   stateId: string
   lazyload?: boolean
   initialOpen?: boolean
}

let nextId: number = 1;

export const PageAccordion: FC<PageAccordionProps> = ({title, stateId, lazyload, initialOpen, children}) => {
   const [open, setOpen] = useLocalStorage<boolean>(stateId||'c2s-accordion', initialOpen||false);
   const classes = useStyles();

   const onToggleExpand = (e: React.SyntheticEvent, isExpanded: boolean) => {
      setOpen(isExpanded);
   }

   let myId = nextId++;
   let lazyLoadObject = lazyload?{mountOnEnter: true, unmountOnExit: true}:undefined;
   return <Accordion TransitionProps={lazyLoadObject} expanded={open} onChange={onToggleExpand} className={classes.accordion} variant={"outlined"} disableGutters  >
      <AccordionSummary
         expandIcon={<ExpandMoreIcon/>}
         aria-controls={"pageaccordion-content_" + myId}
         id={"pageaccordion_" + myId}
         style={{paddingLeft: '25px', paddingRight: '25px', backgroundColor: '#f0f0f0'}}
      >
         {typeof title === 'string' ? <BodyText type={"body1"}>{title}</BodyText> : title}
      </AccordionSummary>
      <AccordionDetails style={{paddingLeft: '25px', paddingRight: '25px', marginTop: '10px', marginBottom: '10px'}}>
         {children}
      </AccordionDetails>
   </Accordion>
}

const useStyles = makeStyles({
   accordion: {
      width: '100%',
      marginBottom: '10px',
      '&:before': {
         display: 'none',
      },
   }
})