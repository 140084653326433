import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Checkbox, Divider, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {merge} from "lodash";

type InputOption = {
   id: string
   label: string
   subtext?: string
}

type FormInputSelectMultipleProps = Omit<CheckboxProps, 'name' | 'label'> & {
   name: string
   label: string
   options: InputOption[]
   onChange?: () => void
   icon?: React.ReactNode
}

let nextId: number = 100;

export const FormInputSelectMultiple: React.FC<FormInputSelectMultipleProps> = ({
                                                                                   name,
                                                                                   label,
                                                                                   options,
                                                                                   onChange: onParentChange,
                                                                                   icon,
                                                                                   ...rest
                                                                                }) => {
   const {style} = rest;

   const methods = useFormContext();
   const {control} = methods;

   const labelId = 'multiselect_' + (nextId++);
   const inputId = 'multiselectinput_' + (nextId++);

   const newStyle = merge(style, {
      marginBottom: '8px',
   });

   const selectStyle = {
      backgroundColor: 'transparent',
      border: '1px solid #d0d0d0'
   };

   return (
      <FormControl component={"fieldset"} fullWidth variant={"filled"} margin={"none"} style={newStyle} size={"small"}>
         <InputLabel id={labelId}>
            <div style={{display: 'flex', alignItems: 'middle', gap: '10px'}}>
               {icon && icon} {label}
            </div>
         </InputLabel>
         <Controller
            control={control}
            name={name}
            render={({field: {onChange, value}, fieldState: {error}}) => {
               return <Select
                  disableUnderline
                  labelId={labelId}
                  id={inputId}
                  multiple
                  style={selectStyle}
                  value={value || []}
                  onChange={(e) => {
                     if (e.target.value?.includes('_reset')) {
                        onChange({
                           ...e,
                           target: {
                              value: []
                           }
                        });
                     } else {
                        console.log(e);
                        onChange(e);
                     }
                     onParentChange && onParentChange();
                  }}
                  renderValue={(selected) => {
                     let result = '';
                     for (const selectedElement of selected) {
                        const option = options.find(option => option.id===selectedElement);
                        if(option) {
                           if(result.length>0) {
                              result += ','
                           }
                           result += option.label;
                        }
                     }
                     if(!result) {
                        return <>None</>
                     } else {
                        return result
                     }
                  }}>
                  {options.map(option => {
                     return <MenuItem key={'opt_' + option.id} value={option.id}>
                        <Checkbox checked={(value || []).indexOf(option.id) > -1}/>
                        <ListItemText primary={option.label} secondary={option.subtext}/>
                     </MenuItem>
                  })}
                  <Divider/>
                  <MenuItem value={'_reset'} disabled={(value||[]).length===0}>
                     <ListItemText>
                        Clear
                     </ListItemText>
                  </MenuItem>
               </Select>
            }}
         />
      </FormControl>
   );
};
