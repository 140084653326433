import bwipjs from "bwip-js";
import {useEffect} from "react";

interface BarcodeProps {
   id: number;
   text: string;
   bcid: "code128" | "code39" | 'ean13';
   includeCheck: boolean;
   height: number;
   scale: number;
}

export default function Barcode({
                                   id,
                                   text,
                                   bcid,
                                   includeCheck,
                                   height,
                                   scale
                                }: BarcodeProps) {
   let htmlId = `barcodecanvas-${id}`;
   let error = "";
   useEffect(() => {
      try {
         bwipjs.toCanvas(htmlId, {
            bcid: bcid,
            text: text,
            scale: scale,
            height: height,
            includetext: false,
            includecheck: includeCheck
         });
      } catch (e) {
         console.log(e);
      }
   }, [id, text, bcid, htmlId, includeCheck, height, scale]);

   return (
      <div>
         <canvas id={htmlId} style={{width: '100%'}}/>
         <p>{error}</p>
      </div>
   );
}
