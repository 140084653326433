import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {FC} from "react";

export const Warning:FC<any> = ({children}) => {
   const classes = useStyles();

   return <div className={classes.container} role="alert">
      <div className={classes.icon}>
         <svg className={classes.svg} focusable="false" viewBox="0 0 24 24"
              aria-hidden="true">
            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/>
         </svg>
      </div>
      <div className={classes.message}>{children}</div>
   </div>
}


const useStyles = makeStyles({
   container: {
      color: 'rgb(102, 60, 0)',
      backgroundColor: 'rgb(255, 244, 229)',
      display: 'flex',
      padding: '6px 16px',
      borderRadius: '4px'
   },
   icon: {
      color: '#ff9800',
      display: 'flex',
      opacity: 0.9,
      padding: '7px 0',
      fontSize: '22px',
      marginRight: '12px'
   },
   svg: {
      fill: 'currentcolor',
      width: '1em',
      height: '1em',
      display: 'inline-block',
      flexShrink: 0,
      userSelect: 'none'
   },
   message: {
      padding: '8px 0'
   }
});
