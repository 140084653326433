import {useNavigate, useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {DetailsPagePath} from "./DetailsPage";
import * as React from "react";
import {
   EnumBrandSettingLegacyIntegrationDisabledMessages,
   EnumIntegrationConnectorRefType,
   useShopIntegrationConfigQuery,
   useShopIntegrationUpdateLegacyMessagesMutation,
   useShopIntegrationUpdateLegacyMutation
} from "../../../Queries";
import {ShopIntegrationSettingsPath} from "./ShopIntegrationSettings";
import {RefIntegrations} from "./RefIntegrations";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {SelectedMessages} from "./SelectedMessages";

export const ShopIntegrationsPagePath = (brandHouseId: string, shopId: string) => {
   return DetailsPagePath(brandHouseId) + '/' + shopId + '/integrations'
}

export const ShopIntegrations = () => {
   const {brandHouseId, shopId} = useParams<ParamBrandHouse & ParamShop>()
   const navigate = useNavigate();
   const [updateLegacy] = useShopIntegrationUpdateLegacyMutation()
   const [updateLegacyMessages] = useShopIntegrationUpdateLegacyMessagesMutation()
   const {data} = useShopIntegrationConfigQuery({
      variables: {
         shopId: shopId
      }
   })

   const onNavigateTo = (connectorId: string) => {
      navigate(ShopIntegrationSettingsPath(brandHouseId, shopId, connectorId))
   }

   const onChangeLegacy = async (enabled: boolean) => {
      await updateLegacy({
         variables: {
            shopId,
            disabled: !enabled
         }
      })
   }

   const disabledMessages = (data?.BrandSettings?.legacyIntegrationDisabledMessages || []) as EnumBrandSettingLegacyIntegrationDisabledMessages[]

   const onToggleMessage = async (value: EnumBrandSettingLegacyIntegrationDisabledMessages, enabled: boolean) => {
      if (disabledMessages.includes(value)) {
         await updateLegacyMessages({
            variables: {
               shopId,
               messages: disabledMessages.filter(msg => msg !== value) as EnumBrandSettingLegacyIntegrationDisabledMessages[]
            }
         })
      } else {
         await updateLegacyMessages({
            variables: {
               shopId,
               messages: [...disabledMessages, value as EnumBrandSettingLegacyIntegrationDisabledMessages]
            }
         })
      }
   }

   return <RefIntegrations onBack onNavigateTo={onNavigateTo} refId={shopId}
                           title={"Shop integrations"}
                           refType={EnumIntegrationConnectorRefType.SHOP}>
      <Box sx={{border: '1px solid #e0e0e0', padding: '0 10px 0 10px'}}>
         <FormControlLabel
            control={<Checkbox checked={!(data?.BrandSettings?.legacyIntegrationDisabled)} value={true}
                               onChange={(e, val) => {
                                  onChangeLegacy(val);
                               }}/>}
            label={'Shopify legacy integration'}
         />
         <SelectedMessages disabled={!!(data?.BrandSettings?.legacyIntegrationDisabled)} id={"message"} values={Object.values(EnumBrandSettingLegacyIntegrationDisabledMessages)}
                           selected={disabledMessages} label={""} onSelect={onToggleMessage}/>
      </Box>
   </RefIntegrations>
}

