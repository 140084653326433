import * as React from "react";
import {Button, Grid, LinearProgress, Paper} from "@mui/material";
import SettingsHeader from "../../SettingsHeader";
import {
   BrandSettingMasterDataConsolidateInput,
   EnumBrandSettingMasterDataConsolidateColorStrategy,
   EnumBrandSettingMasterDataConsolidateField,
   EnumIntegrationConnectorRefType,
   MasterDataFragment,
   useMasterDataConsolidationStartMutation,
   useMasterDataRequestProductsMutation,
   useMasterDataSettingsQuery,
   useMasterDataSettingsSaveMutation
} from "../../../../Queries";
import {useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../../../Parameters";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputCheckbox} from "../../../shop/wholesale/form/FormInputCheckbox";
import {FormInputSelect, SelectOption} from "../../../shop/wholesale/form/FormInputSelect";
import {SavingUI} from "../../../../SavingProvider";
import {Dialogs} from "../../../../DialogProvider";
import {FormInputNumber} from "../../../shop/wholesale/form/FormInputNumber";
import {FormInputText} from "../../../shop/wholesale/form/FormInputText";

const ID_MASTERDATA = 'masterDataSettingsForm'

export const MasterDataSettingsPagePath = (brandId: string, shopId?: string) => {
   return "/brands/" + brandId + "/settings/masterdata";
}

export const MasterDataSettingsPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const {data} = useMasterDataSettingsQuery({
      variables: {
         brandHouseId
      }
   });
   const [update] = useMasterDataSettingsSaveMutation()
   const [start] = useMasterDataConsolidationStartMutation()
   const [requestProducts] = useMasterDataRequestProductsMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSubmit = async (data: MasterDataFragment) => {
      await SavingUI.process(async () => {
         await update({
            variables: {
               brandHouseId: brandHouseId,
               input: data.masterDataConsolidate as BrandSettingMasterDataConsolidateInput,
               removeBg: data.masterDataRemoveBg || false,
               removeBgVersion: data.masterDataRemoveBgVersion || 0,
               useBrandItems: data.useBrandItems || false,
               masterDataUseProductImages: data.masterDataUseProductImages || false
            }
         })
      }, 'Settings updated')
   }

   const onStartConsolidation = async () => {
      await SavingUI.process(async () => {
         await start({
            variables: {
               brandHouseId: brandHouseId
            }
         })
      }, 'Consolidation started')
   }

   const onRequestMasterData = async () => {
      await SavingUI.process(async () => {
         const {data} = await requestProducts({
            variables: {
               refId: brandHouseId,
               refType: EnumIntegrationConnectorRefType.BRANDHOUSE,
               args: [
                  brandHouseId,
                  true
               ]
            }
         })

         if (!data?.IntegrationMessageExecute?.length) {
            Dialogs.info({
               title: 'No connectors found for message'
            })
         } else {
            Dialogs.info({
               title: 'Message sent',
            })
         }
      }, 'Consolidation started')
   }

   return <div>
      <SettingsHeader title={"Master data"} subtitle={"Settings for master data"}>
         <Button type="button" variant={"outlined"} onClick={onRequestMasterData}>Request masterdata</Button>
         <Button type="button" variant={"outlined"} onClick={onStartConsolidation}>Start consolidation</Button>
         <Button type="submit" form={ID_MASTERDATA} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <Paper sx={{padding: '20px', marginBottom: '20px'}}>
         <MasterDataForm data={{
            masterDataConsolidate: {
               enableLanguageDetect: data?.BrandSettings?.masterDataConsolidate?.enableLanguageDetect || false,
               enabled: data?.BrandSettings?.masterDataConsolidate?.enabled || false,
               field: data?.BrandSettings?.masterDataConsolidate?.field || EnumBrandSettingMasterDataConsolidateField.EAN,
               colorStrategy: data?.BrandSettings?.masterDataConsolidate?.colorStrategy,
               colorIdentifier: data?.BrandSettings?.masterDataConsolidate?.colorIdentifier || '',
               secondaryColorStrategy: data?.BrandSettings?.masterDataConsolidate?.secondaryColorStrategy,
               secondaryColorIdentifier: data?.BrandSettings?.masterDataConsolidate?.secondaryColorIdentifier || '',
               tertiaryColorStrategy: data?.BrandSettings?.masterDataConsolidate?.tertiaryColorStrategy,
               tertiaryColorIdentifier: data?.BrandSettings?.masterDataConsolidate?.tertiaryColorIdentifier || '',
            },
            masterDataRemoveBg: data?.BrandSettings?.masterDataRemoveBg || false,
            masterDataRemoveBgVersion: data?.BrandSettings?.masterDataRemoveBgVersion || 0,
            masterDataUseProductImages: data?.BrandSettings?.masterDataUseProductImages || false,
            useBrandItems: data?.BrandSettings?.useBrandItems || false
         }} onSubmit={onSubmit}/>
      </Paper>
   </div>
}

type MasterDataFormProps = {
   data: MasterDataFragment
   onSubmit: (data: MasterDataFragment) => Promise<void>
}

const MasterDataForm = ({data, onSubmit}: MasterDataFormProps) => {
   const methods = useForm<MasterDataFragment>({
      defaultValues: data
   });

   const {handleSubmit} = methods

   const options: SelectOption[] = Object.keys(EnumBrandSettingMasterDataConsolidateField).map(key => {
      return {
         id: key.toUpperCase(),
         name: key
      }
   })

   const colorStrategies: SelectOption[] = Object.values(EnumBrandSettingMasterDataConsolidateColorStrategy).map(key => {
      return {
         id: key.toUpperCase(),
         name: key
      }
   })

   return <div>

      <FormProvider {...methods}>
         <form id={ID_MASTERDATA} onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
               <Grid item xs={3}>
                  <FormInputCheckbox name={"masterDataConsolidate.enabled"}
                                     label={"Enable automatic consolidation of masterdata"}/>
               </Grid>
               <Grid item xs={9}>
                  <FormInputSelect name={"masterDataConsolidate.field"} label={"Select field to consolidate for"}
                                   options={options}/>
                  <Grid item container xs={12} spacing={"8px"}>
                     <Grid item xs={8}>
                        <FormInputSelect name={"masterDataConsolidate.colorStrategy"}
                                         label={"Primary colorstrategy to use"}
                                         options={colorStrategies}/>
                     </Grid>
                     <Grid item xs={4}>
                        <FormInputText name={"masterDataConsolidate.colorIdentifier"}
                                       label={"Primary prefix/identifier/key"}/>
                     </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={"8px"}>
                     <Grid item xs={8}>
                        <FormInputSelect name={"masterDataConsolidate.secondaryColorStrategy"}
                                         label={"Sescondary colorstrategy to use"}
                                         options={colorStrategies}/>
                     </Grid>
                     <Grid item xs={4}>
                        <FormInputText name={"masterDataConsolidate.secondaryColorIdentifier"}
                                       label={"Secondary prefix/identifier/key"}/>
                     </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={"8px"}>
                     <Grid item xs={8}>
                        <FormInputSelect name={"masterDataConsolidate.tertiaryColorStrategy"}
                                         label={"Tertiary colorstrategy to use"}
                                         options={colorStrategies}/>
                     </Grid>
                     <Grid item xs={4}>
                        <FormInputText name={"masterDataConsolidate.tertiaryColorIdentifier"}
                                       label={"Tertiary prefix/identifier/key"}/>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={3}>
               </Grid>
               <Grid item xs={9}>
                  <FormInputCheckbox name={"masterDataConsolidate.enableLanguageDetect"} label={"Detect language"}/>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={12}>
                  <FormInputCheckbox name={"useBrandItems"} label={"Use stockitems on brand"}/>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item container xs={12}>
                  <Grid item xs={6}>
                     <FormInputCheckbox name={"masterDataRemoveBg"}
                                        label={"Run REMOVEBG on masterdata images used for stockitems"}/>
                  </Grid>
                  <Grid item xs={6}>
                     <FormInputNumber name={"masterDataRemoveBgVersion"}
                                      label={"REMOVEBG Version (temp)"}/>
                  </Grid>
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={12}>
                  <FormInputCheckbox name={"masterDataUseProductImages"} label={"Use product images on variants"}/>
               </Grid>
            </Grid>
         </form>
      </FormProvider>
   </div>
}
