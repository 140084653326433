import * as React from "react"
import {FC} from "react"
import {QComponentProps} from "../Model";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {cloneDeep, get, set} from "lodash";
import makeStyles from '@mui/styles/makeStyles';

type QAccordionProps = QComponentProps & {
   field: string
   label: string
}

export const QAccordion: FC<QAccordionProps> = ({field, data, label, update, children}) => {
   const classes = useStyles();
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.checked;
      let clonedData = cloneDeep(data);
      set(clonedData, field, value || false);
      update(clonedData);
   };

   return <Accordion variant={"outlined"} expanded={get(data, field, false)}>
      <AccordionSummary
         expandIcon={<ExpandMoreIcon/>}
         aria-label="Expand"
         aria-controls="additional-actions3-content"
         id="additional-actions3-header"
      >
         <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox
               id={'check-' + field}
               checked={get(data, field, false)}
               onChange={handleChange}
               name={field}
               color="secondary"
            />
            }
            label={label}
         />
      </AccordionSummary>
      <AccordionDetails>
         <Grid container style={{paddingLeft: '30px', gap: '10px'}}>{React.Children.map(children, child => {
            return <Grid className={classes.grid} item xs={12}>{child}</Grid>
         })}</Grid>
      </AccordionDetails>
   </Accordion>
}

const useStyles = makeStyles({
   grid: {
      "&:empty": {
         display:'none'
      }
   }
});