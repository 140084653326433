import {Alert, Autocomplete, Box, TextField, TextFieldProps} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import {merge} from "lodash";
import * as React from "react";

type FormInputAutocomplete2Props = Omit<TextFieldProps, 'name'> & {
   name: string
   rules?: any
   labelMapper?: (option: any) => string
   options: any[]
   label?: string
}

export default function FormInputAutocomplete2({
                                                  name,
                                                  rules,
                                                  options,
                                                  label,
                                                  labelMapper,
                                                  style,
                                                  fullWidth,
                                                  placeholder
                                               }: FormInputAutocomplete2Props) {
   const {control, formState: {errors}} = useFormContext();
   const showError = !!errors[name]

   return (
      <Controller
         name={name}
         control={control}
         rules={rules}
         defaultValue={null}
         render={({
                     field: {ref, onChange, value, ...field},
                     fieldState: {error}
                  }) => {
            return (
               <Autocomplete
                  {...field}
                  value={value}
                  freeSolo
                  multiple
                  fullWidth={fullWidth}
                  handleHomeEndKeys
                  options={options}
                  getOptionLabel={labelMapper}
                  renderInput={({size, InputProps, InputLabelProps, ...rest}) => {
                     const newStyle = merge(style, {
                        border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
                        marginBottom: '8px'
                     });

                     const newProps = merge(InputProps, {
                        disableUnderline: true,
                        style: {backgroundColor: 'transparent'}
                     });

                     return <Box>
                        <TextField
                           size={"small"}
                           label={label}
                           variant="filled"
                           margin={"none"}
                           style={newStyle}
                           placeholder={placeholder}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           InputProps={newProps}
                           {...rest}
                        />
                        {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
                     </Box>
                  }}
                  onChange={(e, item) => {
                     onChange(item)
                  }}
               />
            );
         }}
      />
   );
}
