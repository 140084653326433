import {
   LocationInfoStockItemsDocument,
   LocationItemFragment,
   StockItemResolved,
   useLocationInfoCheck2Mutation,
   useLocationInfoCheckMutation,
   useLocationInfoStockItemsQuery
} from "../../../Queries";
import {Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {BodyText, TitleText} from "../../../layout/Typography";
import React from "react";
import {CheckCircleRounded, RadioButtonUnchecked, Unpublished} from "@mui/icons-material";
import {Thumbnails} from "../../../components/Thumbnails";

export const isLocation = (code: string) => {
   return /[A-Z]-[0-9]-[0-9]/.test(code)
}

type LocationInfoProps = {
   location: string
   onHide: () => void
}

export const LocationInfo = ({location, onHide}: LocationInfoProps) => {
   const {data} = useLocationInfoStockItemsQuery({
      variables: {
         location: location
      }
   })
   const [check] = useLocationInfoCheckMutation();
   const [check2] = useLocationInfoCheck2Mutation();

   const items = data?.StockItemMany?.map(item => item.resolved) || []
   const activeItems = items.filter(item => !item?.isSold)
   const soldItems = items.filter(item => !!item?.isSold)

   const bitems = (data?.BrandItemMany || [])
      .map(bitem => bitem!.resolved)
      .map(bitem => {
      return {
         _id: bitem?._id,
         color: (bitem?.colorLocalized?.find(loc => !!loc?.value)?.value || 'Unknown'),
         size: (bitem?.sizeLocalized?.find(loc => !!loc?.value)?.value || 'Unknown'),
         title: (bitem?.nameLocalized?.find(loc => !!loc?.value)?.value || 'Unknown'),
         isSold: bitem?.isSold || false,
         effectiveChecked: bitem?.effectiveChecked || false,
         effectiveMissing: bitem?.effectiveMissing || false,
         imageUrls: bitem?.imageUrls || [],
         shop: {
            name: bitem?.brandHouse?.name
         },
         itemNr: bitem?.itemNr
      } as LocationItemFragment
   });
   const bactiveItems = bitems.filter(item => !item.isSold)
   const bsoldItems = bitems.filter(item => !!item.isSold)

   const onCheck = async (stockItemId: string, checked: boolean, missing: boolean) => {
      if (bitems.find(bitem => bitem._id === stockItemId)) {
         // Then its branditem
         await check2({
            variables: {
               brandItemId: stockItemId,
               checked: checked,
               missing: missing
            },
            refetchQueries: [{
               query: LocationInfoStockItemsDocument,
               variables: {
                  location: location
               }
            }]
         });
      } else {
         await check({
            variables: {
               stockItemId: stockItemId,
               checked: checked,
               missing: missing
            },
            refetchQueries: [{
               query: LocationInfoStockItemsDocument,
               variables: {
                  location: location
               }
            }]
         });
      }
   }

   return <Dialog open={true} fullWidth={true} fullScreen={true} PaperProps={{
      style: {
         backgroundColor: '#f0f0f0'
      }
   }}>
      <DialogTitle>
         <Box sx={{
            padding: '5px',
            backgroundColor: '#e0e0e0',
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
         }}>
            <TitleText type={"h1"}>{location}</TitleText>
            <div>{(items?.length || 0) + (bitems?.length || 0)} items</div>
            <Button variant={"contained"} onClick={onHide}>Back</Button>
         </Box>
      </DialogTitle>
      <DialogContent>
         <RenderItems items={activeItems as StockItemResolved[]} onCheck={onCheck}/>
         <RenderItems items={bactiveItems as StockItemResolved[]} onCheck={onCheck}/>

         <Box sx={{marginTop: '150px'}}>
            <BodyText type={"subtitle1"}>Sold items at location</BodyText>

            <RenderItems items={soldItems as StockItemResolved[]} onCheck={onCheck}/>
            <RenderItems items={bsoldItems as StockItemResolved[]} onCheck={onCheck}/>
         </Box>

         {data?.StockItemMany?.length === 0 && <>No items here</>}
      </DialogContent>
   </Dialog>
}

type RenderItemsProps = {
   items: StockItemResolved[]
   onCheck: (stockItemId: string, checked: boolean, missing: boolean) => Promise<void>
}

const RenderItems = ({items, onCheck}: RenderItemsProps) => {
   return <div>
      {items?.map(item => {
         return <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
            padding: '5px',
            backgroundColor: item?.effectiveChecked ? '#f0f8f0' : item.effectiveMissing ? '#f8f0f0' : '#ffffff'
         }} variant={"outlined"}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
               <TitleText type={"h2"}>{item.itemNr + ' ' + (item.title || 'MISSING TITLE')}</TitleText>
               <BodyText type={"body1"}>{item.shop?.name || 'ukendt2'}</BodyText>
            </Box>

            <Box sx={{display: 'flex'}}>
               <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  {item?.effectiveChecked &&
                     <IconButton onClick={() => onCheck(item._id, false, true)}>
                        <CheckCircleRounded fontSize={"large"} color={"success"}/>
                     </IconButton>
                  }

                  {!item?.effectiveChecked &&
                     <IconButton onClick={() => onCheck(item._id, true, false)}>
                        <RadioButtonUnchecked fontSize={"large"}
                                              color={item.effectiveMissing ? "disabled" : "success"}/>
                     </IconButton>
                  }

                  {item?.effectiveMissing &&
                     <IconButton onClick={() => onCheck(item._id, true, false)}>
                        <Unpublished fontSize={"large"} color={"error"}/>
                     </IconButton>
                  }
                  {!item?.effectiveMissing &&
                     <IconButton onClick={() => onCheck(item._id, false, true)}>
                        <RadioButtonUnchecked fontSize={"large"} color={item.effectiveChecked ? "disabled" : "error"}/>
                     </IconButton>
                  }

               </Box>
               <Box sx={{display: 'flex', flexDirection: 'row', flex: '0 0 50%'}}>
                  <CardContent>
                     <BodyText type={"body1"}>Size: {item.size}</BodyText>
                     <BodyText type={"body1"}>Color: {item.color}</BodyText>
                  </CardContent>
               </Box>
               <Thumbnails imageUrls={item.imageUrls || []} maxImages={1} size={100}/>
            </Box>
         </Card>
      })}
   </div>
}