import React, {FC} from "react";
import {
   InventoryItemStockItemDocument,
   useInventoryItemCheckMutation,
   useInventoryItemStockItemQuery
} from "../../../Queries";
import {Button, Grid, IconButton, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Thumbnails} from "../../../components/Thumbnails";
import clsx from "clsx";
import {CheckCircleRounded} from "@mui/icons-material";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Condition} from "../../shop/components/Condition";
import {StockItemEditPagePath} from "../../shop/stockitems/edit/StockItemEditPage";
import {useNavigate} from "react-router-dom";
import {PubSub} from "../../../components/PubSub";

type InventoryItemProps = {
   locationId: string
   stockItemId: string
}

export const InventoryItem: FC<InventoryItemProps> = ({locationId, stockItemId}) => {
   const {data} = useInventoryItemStockItemQuery({
      variables: {
         stockItemId: stockItemId
      }
   });
   const [checkItem] = useInventoryItemCheckMutation();
   const classes = useStyles();
   const navigate = useNavigate();

   if (!data) {
      return <LinearProgress/>
   }
   const item = data?.StockItemById;

   const onCheck = async () => {
      await checkItem({
         variables: {
            stockItemId: stockItemId,
            checked: !item?.effectiveChecked
         },
         refetchQueries: [{
            query: InventoryItemStockItemDocument,
            variables: {
               stockItemId: stockItemId
            }
         }]
      });
      PubSub.emit('InventoryItemUpdate-' + locationId, {});
   }

   const onViewInStock = () => {
      navigate(StockItemEditPagePath(item?.shop?.brandHouseId, item?.shopId, item?._id));
   }

   return <div className={classes.row}>
      <div className={classes.startCell}>
         <Thumbnails imageUrls={item?.imageUrls || []} size={100} maxImages={1}/>
      </div>
      <div className={clsx(classes.cell, classes.content)}>
         <div className={classes.content1}>
            <div onClick={onViewInStock} style={{textDecoration: 'underline', cursor: 'pointer', color: 'blue'}}>
               <TitleText type={"h2"}>{item?.title || 'MISSING TITLE'}</TitleText>
            </div>
            <Grid container style={{width: '200px'}}>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>Size</BodyText>
               </Grid>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>{item?.size}</BodyText>
               </Grid>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>Color</BodyText>
               </Grid>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>{item?.color}</BodyText>
               </Grid>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>SKU</BodyText>
               </Grid>
               <Grid item xs={6}>
                  <BodyText type={"body1"}>{item?.sku}</BodyText>
               </Grid>
            </Grid>
         </div>
         <div className={classes.content2}>
            {item?.shop?.name}<br/>
            <Condition conditions={[{
               condition: 1,
               name: 'Fair / Signs of use',
               description: 'Fair'
            }, {
               condition: 2,
               name: 'Good / Some signs of use',
               description: 'Good'
            }, {
               condition: 3,
               name: 'Excellent / As new',
               description: 'As new'
            }]} current={item?.condition || 0}/>
         </div>
      </div>
      <div className={classes.commands}>
         {item?.effectiveChecked &&
         <IconButton onClick={onCheck}>
            <CheckCircleRounded fontSize={"large"} color={"success"}/>
         </IconButton>
         }
         {!item?.effectiveChecked &&
         <Button onClick={onCheck} variant={"outlined"}>
            OKAY!
         </Button>
         }
      </div>
   </div>
}


const useStyles = makeStyles({
   row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '15px'
   },
   startCell: {
      width: '150px'
   },
   cell: {},
   commands: {
      width: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'end'
   },
   content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
      gap: '20px'
   },
   content1: {
      width: '50%'
   },
   content2: {}
});
