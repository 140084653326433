import * as React from "react";
import PageContent from "../../../layout/PageContent";
import {Currency, OrderSettingsFragment, useOrderSettingsQuery, useOrderSettingsUpdateMutation} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {Button, LinearProgress} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {TitleText} from "../../../layout/Typography";
import {SavingUI} from "../../../SavingProvider";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputPrice} from "../../shop/wholesale/form/FormInputPrice";

const FORMID_ORDERS = "SettingsOrders";

export const OrderSettingsPath = (brandId: string, shopId?: string) => {
   let path = "/brands/" + brandId + "/settings/orderset";
   if (shopId) {
      path += '/' + shopId
   }
   return path;
}

export const OrderSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(OrderSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Orders"} subtitle={"Settings for orders"}>
         <Button type="submit" form={FORMID_ORDERS} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <OrdersInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <OrdersInner shopId={shop}/>}
      />
   </div>
}

type OrdersInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const OrdersInner = ({brandHouseId, shopId}: OrdersInnerProps) => {
   const {data} = useOrderSettingsQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   const [saveOrders] = useOrderSettingsUpdateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: OrderSettingsFragment) => {
      if (!data.refundAdjustment?.oncePerOrder) {
         data.refundAdjustment!.oncePerOrder = false
      }
      await SavingUI.process(async () => {
         await saveOrders({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               input: data
            }
         });
      }, 'Order setttings updated')
   }

   const currencies = (data.BrandSettings?.stockItemConfig?.currencies || [])
      .filter(active => !!active!.active) as Currency[]
   const editData = (data!.BrandSettings?.order || {}) as OrderSettingsFragment
   for (const currency of currencies) {
      const adjustCur = editData.refundAdjustment?.adjust?.find(adj => adj?.currency === currency.currency);
      if (!adjustCur) {
         if (!editData.refundAdjustment) {
            editData.refundAdjustment = {
               oncePerOrder: true,
               reason: '',
               adjust: []
            }
         }
         if (!editData.refundAdjustment!.adjust) {
            editData.refundAdjustment!.adjust = []
         }
         editData.refundAdjustment!.adjust!.push({
            currency: currency.currency,
            value: 0
         })
      }
   }

   return <OrderSettingsEditor
      data={editData} onSave={onSave}/>
}


type OrderSettingsEditorProps = {
   data?: OrderSettingsFragment
   onSave: (data: OrderSettingsFragment) => Promise<void>
}

const OrderSettingsEditor = ({data, onSave}: OrderSettingsEditorProps) => {
   const methods = useForm<OrderSettingsFragment>({
      defaultValues: data
   });
   const {handleSubmit} = methods;

   return <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} id={FORMID_ORDERS}/>
      <PageContent>
         <TitleText type={"h2"}>Refund amount deduction</TitleText>
         {data?.refundAdjustment?.adjust?.map((adjust, i) => {
            return <FormInputPrice name={`refundAdjustment.adjust.${i}.value`}
                                   label={"Amount (" + adjust!.currency + ")"}/>
         })}
         <FormInputText name={"refundAdjustment.reason"} label={"Reason for adjustment"}/>
         <FormInputCheckbox name={"refundAdjustment.oncePerOrder"} label={"Only adjust once per order"}/>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Delivery note</TitleText>
         <FormInputCheckbox name={"deliveryNote.hideReturnSection"} label={"Hide return section"}/>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>VAT</TitleText>
         <FormInputText name={"gbEori"} label={"GB-EORI Number"}/>
      </PageContent>
   </FormProvider>
}