import {gql, useQuery} from "@apollo/client";

const QL_BRANDHOUSES = gql`
  query MainBrandHouses {
    BrandHouses {
      _id
      name
      license
      apiKey
      apiSecret
    }
  }
`;

export interface BrandHouse {
   _id: string
   name: string
   license: string
   apiKey: string
   apiSecret: string
}

const useBrandHouses = (): BrandHouse[] | null => {
   const {data} = useQuery(QL_BRANDHOUSES);

   if (!data) {
      return null;
   }

   return data.BrandHouses as BrandHouse[]
}

export default useBrandHouses