import {Button, Menu, MenuItem} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import * as React from "react";
import {Currencies} from "../shop/wholesale/form/FormInputCurrency";

type CurrencyMenuProps = {
   label?: string
   startIcon?: React.ReactNode | boolean
   onSelect: (currency: string) => void
   hideCurrencies?: string[]
   allowCurrencies?: string[]
}

export const CurrencyMenu = ({onSelect, hideCurrencies, allowCurrencies, label, startIcon}: CurrencyMenuProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   let buttonStartIcon: React.ReactNode | undefined = undefined;
   if (startIcon === false) {
      buttonStartIcon = undefined
   } else if (startIcon) {
      buttonStartIcon = startIcon
   } else {
      buttonStartIcon = <AddCircle/>
   }


   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? 'currency-menu2' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         startIcon={buttonStartIcon}
         variant={"outlined"}
         color={"secondary"}
      >
         {label || 'Add currency'}
      </Button>
      <Menu
         anchorEl={anchorEl}
         id="currency-menu2"
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {Currencies
            .filter(currency => ((allowCurrencies || []).length === 0 || allowCurrencies?.includes(currency.id)))
            .filter(currency => currency.id !== 'POINTS')
            .filter(locale => !(hideCurrencies || []).find(lf => lf === locale.id))
            .map(currency => {
               return <MenuItem key={'menu_locale_' + currency.id} onClick={() => onSelect(currency.id)}>
                  {currency.name}
               </MenuItem>
            })}
      </Menu>
   </>
}

