import {FormInputSelect} from "./FormInputSelect";
import * as React from "react";

type FormInputCurrencyProps = {
   name: string
   label: string
   disabled?: boolean
}

export const Currencies = [{
   id: "EUR",
   name: "Euro € (EUR)"
}, {
   id: "DKK",
   name: "Danish Krone (DKK)"
}, {
   id: "NOK",
   name: "Norwegian Krone (NOK)"
}, {
   id: "SEK",
   name: "Swedish Krone (SEK)"
}, {
   id: "RON",
   name: "Romanian Leu (RON)"
}, {
   id: "CZK",
   name: "Czech Koruna (CZK)"
}, {
   id: "SIT",
   name: "Slovenian Tolar (SIT)"
}, {
   id: "PLN",
   name: "Polish Zloty (PLN)"
}, {
   id: "GBP",
   name: "British Pounds (GBP)"
}, {
   id: "POINT",
   name: "Circular Points (POINT)"
}, {
   id: "POINTS",
   name: "(Do not use)"
}];

export const FormInputCurrency = ({name, label, disabled}: FormInputCurrencyProps) => {
   return <FormInputSelect disabled={disabled} name={name} label={label} options={Currencies}/>
}