import * as React from "react";
import {LinearProgress} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {WholeSaleCategory} from "../hooks/WholeSaleTypes";
import {DataColumn, DataTable} from "../../../../components/DataTable";
import {Thumbnail} from "../../../../components/Thumbnail";
import {WholeSaleEditPath} from "../WholeSaleEditPage";
import {useWholeSaleCategoriesQuery} from "../../../../Queries";

export const WholeSaleCategoryList = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();

   const {data} = useWholeSaleCategoriesQuery({
      variables: {
         shopId
      }
   })

   if (!data) {
      return <LinearProgress/>
   }
   const columns: DataColumn<WholeSaleCategory>[] = [{
      label: 'Name',
      field: 'name',
      render: (name, row) => {
         return <Link to={WholeSaleEditPath(brandHouseId, shopId, row._id)}>{name}</Link>
      }
   }, {
      label: 'Section',
      field: 'section',
      render: (name, row) => {
         return <Link to={WholeSaleEditPath(brandHouseId, shopId, row._id)}>{name}</Link>
      }
   }, {
      label: 'Icon',
      get: (row) => {
         return row.iconId
      },
      render: (item) => {
         return <Thumbnail size={24} image={process.env.REACT_APP_FILESERVICEURL + "/" + item}/>
      }
   }, {
      label: 'Voucher Value',
      field: 'value'
   }, {
      label: 'Receive',
      field: 'voucherStrategy'
   }]

   return <DataTable
      idField={"_id"}
      commands={[]}
      selections={false}
      rows={data.WholeSaleCategories||[]} columns={columns}/>
}