import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {TextField, TextFieldProps} from '@mui/material';

import {NumericFormat} from 'react-number-format';
import {merge} from "lodash";
import makeStyles from "@mui/styles/makeStyles";

const PriceFormat = React.forwardRef<any, any>(({inputRef, onChange, ...props}, ref) => {
   return (
      <NumericFormat
         {...props}
         getInputRef={ref}
         onValueChange={({floatValue}) => onChange(floatValue)}
         thousandSeparator={false}
         allowedDecimalSeparators={['.', ',']}
         decimalScale={2}
         valueIsNumericString
         isAllowed={({value, floatValue}) => (value === '' || (floatValue || 0) >= 0)}
      />
   );
});


const PriceFormatAll = React.forwardRef<any, any>(({inputRef, onChange, ...props}, ref) => {
   return (
      <NumericFormat
         {...props}
         getInputRef={ref}
         onValueChange={({floatValue}) => onChange(floatValue)}
         thousandSeparator={false}
         allowedDecimalSeparators={['.', ',']}
         decimalScale={2}
         valueIsNumericString
         isAllowed={({value, floatValue}) => true}
      />
   );
});

export type FormInputPriceProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   label: string;
   errorMessage?: string;
   allowNegative?: boolean
}


export const FormInputPrice = (
   {
      label,
      name,
      allowNegative,
      ...rest
   }: FormInputPriceProps) => {

   const {style, InputProps, ...fieldRest} = rest;
   const classes = useStyles();

   const methods = useFormContext();
   const {control, formState: {errors}} = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      border: showError ? '1px solid #f00000' : '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   const newProps = merge(InputProps, {
      disableUnderline: true,
      style: {backgroundColor: 'transparent'},
   });

   return (
      <div className={classes.noBorderRadius}>
         <Controller
            control={control}
            name={name}
            render={({field: {onChange, value}, formState: {errors}}) => (
               <TextField
                  id={name}
                  InputProps={{
                     ...newProps,
                     inputComponent: allowNegative ? PriceFormatAll : PriceFormat,
                  }}
                  size="small"
                  fullWidth
                  variant="filled"
                  margin={"none"}

                  name={name}
                  label={label}
                  value={value ?? ''}
                  onChange={(value: any) => {
                     onChange(isNaN(value) ? null : value);
                  }}
                  error={!!errors[name]}

                  InputLabelProps={{
                     shrink: true,
                  }}
                  style={newStyle}
                  {...fieldRest}
               />
            )}
         />
      </div>
   );
}

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});
