import {
   Box,
   Button,
   Card,
   CardContent,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   LinearProgress,
   Stack,
   Tab,
   Tabs
} from "@mui/material";
import {BodyText, TitleText} from "../../layout/Typography";
import {BrandProductsList} from "./BrandProductList";
import * as React from "react";
import {useState} from "react";
import {ProductPaginationFragment, useBrandItemDialogImageQuery, useBrandItemDialogVariantsQuery} from "../../Queries";
import {Thumbnails} from "../../components/Thumbnails";
import {uniq} from "lodash";
import {Thumbnail} from "../../components/Thumbnail";
import {BrandEditorImage} from "./brandItem/BrandItemEditor";

export type BrandItemDialogLine = {
   field: string
   value: string
}

type BrandItemDialogProps = {
   search?: string
   onCancel: () => void
   brandHouseId: string
   onCreateStockItem: (brandProductSizeId: string) => void
   onCreateEmpty?: () => void
   description?: BrandItemDialogLine[]
   images?: BrandEditorImage[]
}

export const BrandItemDialog = ({
                                   search,
                                   onCancel,
                                   brandHouseId,
                                   onCreateStockItem,
                                   onCreateEmpty,
                                   description,
                                   images
                                }: BrandItemDialogProps) => {
   const [brandProductId, setBrandProductId] = useState<string | undefined>(undefined)
   const [step, setStep] = useState<'step1' | 'step2'>('step1')

   const onSelectProduct = (row: ProductPaginationFragment) => {
      setBrandProductId(row._id);
      setStep('step2')
   }

   const onSelectSize = (brandProductSizeId: string) => {
      onCreateStockItem(brandProductSizeId)
   }

   return <Dialog open={true} onClose={onCancel} fullWidth maxWidth={"lg"}>
      <DialogTitle>
         <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Tabs value={step} sx={{marginBottom: '10px'}}>
               <Tab value={"step1"} label={"1. Find product"} onClick={() => setStep('step1')}/>
               <Tab value={"step2"} label={"2. Color & size "}/>
            </Tabs>
            {(description || []).length > 0 && <Box sx={{backgroundColor: '#e0e0e0', padding: '0 10px 0 10px'}}>
               {(description || []).map(desc => {
                  return <BodyText type={"body1"}>{desc.field}: {desc.value}</BodyText>
               })}
            </Box>}
         </Box>
      </DialogTitle>
      <DialogContent sx={{height: '650px'}}>
         <Stack direction={"row"} spacing={2} sx={{height: '100%'}}>
            <Box sx={{width: '100%', height: '600px'}}>
               {step === 'step1' &&
                  <BrandProductsList defaultSearch={search} brandHouseId={brandHouseId} onProductSelect={onSelectProduct}
                                     inDialog/>
               }
               {step === 'step2' && brandProductId &&
                  <BrandProductColorList brandProductId={brandProductId} onSelectSize={onSelectSize}/>
               }
            </Box>
            <Box>
               {(images || []).filter(img => !!img._id).map(image => {
                  return <BrandItemImage imageId={image._id!}/>
               })}
            </Box>
         </Stack>
      </DialogContent>
      <DialogActions>
         {onCreateEmpty &&
            <Button variant={"contained"} color={"primary"} onClick={onCreateEmpty}>Create empty</Button>}
         <Button variant={"outlined"} onClick={onCancel}>Cancel</Button>
      </DialogActions>
   </Dialog>
}

type BrandProductColorListProps = {
   brandProductId: string,
   onSelectSize: (brandProductSizeId: string) => void
}

const BrandProductColorList = ({brandProductId, onSelectSize}: BrandProductColorListProps) => {
   const {data} = useBrandItemDialogVariantsQuery({
      variables: {
         brandProductId
      }
   })

   if (!data) {
      return <LinearProgress/>
   }
   return <div>
      {data.BrandProductVariants.map(variant => {
         const colorIds = variant.colorLocalized?.map(color => color!.value) || [];
         const color = uniq(colorIds).join(" / ")

         return <Card sx={{
            flex: '1',
            flexDirection: 'row',
            flexGrow: '1 1 auto',
            display: 'flex',
            padding: '5px',
            margin: '5px'
         }}>
            <CardContent sx={{textAlign: 'center'}}>
               <Thumbnails size={100} imageUrls={variant.imageUrls || []} maxImages={1}/>
            </CardContent>
            <CardContent sx={{height: '140px', overflow: 'hidden'}}>
               <Box sx={{marginBottom: '10px'}}>
                  <TitleText type={"h3"}>{color} {variant.color ? `(${variant.color})` : ''}</TitleText>
               </Box>
               <Box sx={{display: 'flex', gap: '5px', flexDirection: 'row', flexWrap: 'wrap'}}>
                  {variant.sizes?.map(size => {
                     return <Box onClick={() => onSelectSize(size!._id)} sx={{
                        border: '1px solid #808080',
                        borderRadius: '4px',
                        padding: '4px',
                        backgroundColor: '#ffffff',
                        cursor: 'pointer'
                     }}>{uniq(size?.sizeLocalized.map(size => size?.value)).join(" / ")}</Box>
                  })}
               </Box>
            </CardContent>
         </Card>
      })
      }</div>
}


type BrandItemImageProps = {
   imageId: string
}

export const BrandItemImage = ({imageId}: BrandItemImageProps) => {
   const {data} = useBrandItemDialogImageQuery({
      variables: {
         imageId: imageId
      }
   })
   if(data?.BrandItemImage?.imageUrl || data?.BrandItemImage?.uploadUrl!) {
      return <Thumbnail size={200} image={data?.BrandItemImage?.imageUrl || data?.BrandItemImage?.uploadUrl!}/>
   } else {
      return null
   }
}