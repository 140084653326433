import {FC} from "react";
import {Dialog, LinearProgress} from "@mui/material";
import {
   StockItemImagesDocument,
   useImportDialogCreateImageMutation,
   useImportDialogProductQuery,
   useImportDialogStockItemQuery
} from "../../../Queries";
import {makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";

type ImportDialogProps = {
   stockItemId: string
   onHide: () => void
}

export const ImportDialog: FC<ImportDialogProps> = ({stockItemId, onHide}) => {
   const {shopId} = useParams<{shopId: string}>()
   const {data} = useImportDialogStockItemQuery({
      variables: {
         stockItemId: stockItemId
      }
   })
   const [createImage] = useImportDialogCreateImageMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSelect = async (imageUrl: string) => {
      onHide();
      await createImage({
         variables: {
            shopId:shopId,
            stockItemId: stockItemId,
            imageUrl: imageUrl
         },
         refetchQueries: [
            StockItemImagesDocument,
            'StockItemImages'
         ]
      })
   }

   return <Dialog open={true} onClose={onHide} fullWidth={true} maxWidth={"lg"}>
      <OriginalImages ean={data.StockItem?.originalEan || '--undefined--'}
                      sku={data.StockItem?.sku || '--undefined--'}
                      onSelect={onSelect}
      />
   </Dialog>
}

type OriginalImagesProps = {
   sku?: string | null
   ean?: string | null
   onSelect: (url: string) => Promise<void>
}

const OriginalImages: FC<OriginalImagesProps> = ({sku, ean, onSelect}) => {
   const classes = useStyles();
   const {shopId} = useParams<{shopId: string}>();

   const {data} = useImportDialogProductQuery({
      variables: {
         shopId: shopId,
         sku: sku,
         ean: ean
      }
   });
   if (!data) {
      return <LinearProgress/>
   }
   return <div className={classes.container}>
      {data.ProductVariantMany?.length===0 && <div>No images found, update the EAN or SKU on the stockitem and click save, and retry</div>}
      {data.ProductVariantMany?.map(vari => {
            return vari.imageUrls?.map(imgurl => {
               return <div
                  onClick={()=> onSelect(imgurl)}
                  className={classes.image}
                  style={{
                     backgroundImage: 'url(' + imgurl + ')',
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "contain",
                     backgroundPosition: "center",
                     height: '380px', width: '380px',
                     cursor: 'pointer'
                  }}
               >
                  <div className={classes.imageText}>
                     <div style={{marginBottom: '10px'}}>
                        Name: {vari.product?.name}
                     </div>
                     <div>
                        Color: {vari.color}
                     </div>
                     <div>
                        Size: {vari.size}
                     </div>
                  </div>
               </div>
            })
         }
      )}
   </div>

}

const useStyles = makeStyles({
   container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px'
   },
   image: {
      position: 'relative',
      color: 'transparent',
      "&:hover *": {
         color: 'white',
         display: 'flex',
         flexDirection: 'column'
      }
   },
   imageText: {
      fontSize: '11px',
      position: 'absolute',
      bottom: 0,
      left: '20px',
      right: '20px',
      display: 'none',
      backgroundColor: '#000000',
      opacity: '0.5',
      padding: '5px 10px 5px 10px',
      minHeight: '40px'
   }
})