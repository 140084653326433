import * as React from "react";
import {FC} from "react";
import {Card, CardActions, Dialog, DialogActions} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useTheme} from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from "@mui/material/useMediaQuery";
import CardContent from "@mui/material/CardContent";
import {UploadImage} from "./UploadImage";

export interface UploadServiceType {
   upload: (file: File, onUploadProgress?: (event: { loaded: number, total?: number }) => void) => Promise<any>
   url: () => string
}

export type ImageInfo = {
   id: string
   url: string
   otherUrl?: string
}

export type ImageUploadButton = {
   iconUrl: string
   style?: any
   execute: (imageId: string) => void
}

type ImageUploadProps = {
   id: string
   uploadService: UploadServiceType
   images?: ImageInfo[]
   maxImages?: number
   addImages: (imageIds: string[] | string) => void;
   delImage: (imageId: string) => void;
   children?: (imageId: string) => React.ReactElement;
   size?: number
   onImport?: () => void
   onRender?: (image: ImageInfo) => React.ReactElement
}

export const ImageUpload: FC<ImageUploadProps> = ({
                                                     id,
                                                     uploadService,
                                                     images,
                                                     maxImages,
                                                     addImages,
                                                     delImage,
                                                     size,
                                                     children,
                                                     onImport,
                                                     onRender
                                                  }) => {
   const [previewImage, setPreviewImage] = React.useState<ImageInfo | null>(null);
   const classes = useImageStyles();
   const [showOther, setShowOther] = React.useState<string>('');

   const onShowOther = (image: ImageInfo) => () => {
      if (image.id === showOther) {
         setShowOther('')
      } else {
         setShowOther(image.id);
      }
   }

   return <div className={classes.container}>
      {images?.map(value => {
         return <Card key={value.id} className={classes.root} variant={"outlined"}>
            <CardContent className={classes.topCard}>
               <div className={classes.topCardLink} onClick={onShowOther(value)}>{
                  value.otherUrl && 'view original'
               }</div>
            </CardContent>
            <CardContent>
               <div style={{
                  backgroundImage: 'url("' + (value.otherUrl && showOther === value.id ? value.otherUrl : value.url) + '")',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  height: size ? size + 'px' : undefined
               }}
                    onClick={() => setPreviewImage(value)}
                    className={classes.media}
                    title="Product image"
               >
                  {onRender ? onRender(value) : <>&nbsp;</>}
               </div>
            </CardContent>
            <CardActions>
               <Grid container>
                  <Grid item xs={12} container justifyContent={"space-between"}>
                     {children && <>{children(value.id)}</>}
                     <Button className={"tradein-blackbutton"} variant={"outlined"} size="small" color="secondary"
                             onClick={() => {
                                setPreviewImage(value);
                             }}>
                        <VisibilityIcon/>
                     </Button>
                     <Button className={"tradein-blackbutton"} variant={"outlined"} size="small" color="secondary"
                             onClick={() => {
                                delImage(value.id);
                             }}>
                        <DeleteForeverIcon/>
                     </Button>
                  </Grid>
               </Grid>
            </CardActions>
         </Card>
      })}
      {!!(maxImages && maxImages > (images?.length || 0)) &&
      <UploadImage id={id} addImages={addImages} uploadService={uploadService}>
         {onImport &&
         <Button variant={"outlined"} onClick={onImport} style={{marginLeft: '10px'}}>Import</Button>
         }
      </UploadImage>
      }
      {previewImage != null && <PhotoPreview
         open={true}
         onClose={() => setPreviewImage(null)}
         image={showOther && previewImage.otherUrl && showOther === previewImage.id ? previewImage.otherUrl : previewImage.url}/>}
   </div>
}

const useImageStyles = makeStyles({
   container: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-7px',
      marginRight: '-7px'
   },
   uploadContainer: {},
   root: {
      width: 245,
      marginBottom: '15px',
      marginLeft: '7px',
      marginRight: '7px',
      position: 'relative',
      borderColor: '#f0f0f0'
   },
   media: {
      height: '200px',
   },
   button: {
      "&:hover": {
         backgroundColor: '#F0F0F0'
      },
      "&:disabled": {
         color: 'white',
         backgroundColor: '#c0c0c0'
      }
   },
   topCard: {
      height: '10px',
      padding: '0',
      textAlign: 'center'
   },
   topCardLink: {
      color: '#a0a0a0',
      cursor: 'pointer',
      userSelect: 'none'
   }
});


type PhotoPreviewProps = {
   open: boolean,
   onClose: () => void,
   image: string
}

export const PhotoPreview = (props: PhotoPreviewProps) => {
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

   return <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="InventoryEdit-PhotoPreview"
   >
      <img src={props.image} style={{width: '100%', height: '90%', objectFit: 'contain'}} alt={"Preview"}/>
      {fullScreen && <DialogActions>
         <Button variant={"outlined"} onClick={props.onClose} color="primary">
            Close preview
         </Button>
      </DialogActions>}
   </Dialog>
}