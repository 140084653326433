import * as React from "react";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {
   FlexStockItemFragment,
   StockItemUpdateInput,
   useLogisticsProductsPageBrandLazyQuery,
   useReprocessStockItemImagesMutation,
   useStockItemImageSynchronizeMutation,
   useStockItemsPagePublishMutation
} from "../../Queries";
import {StockItemsList} from "../shop/components/StockItemsList";
import {StockItemEditPagePath} from "../shop/stockitems/edit/StockItemEditPage";
import {FlexCommand} from "../../components/flexlist/FlexTypes";
import {GetApp, PictureAsPdf, Publish} from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const LogisticsStockItemsPath = (logisticsPartnerId: string) => {
   return "/logisticspartners/" + logisticsPartnerId + "/stockitems"
}

export const LogisticsStockItemsPage: FC<any> = () => {
   const navigate = useNavigate();
   const [reprocessItemImages] = useReprocessStockItemImagesMutation()
   const [synchronizeImage] = useStockItemImageSynchronizeMutation()
   const [publishItem] = useStockItemsPagePublishMutation();

   const [getBrandHouse] = useLogisticsProductsPageBrandLazyQuery()

   const onSelectRow = async (row: FlexStockItemFragment, newWindow?: boolean) => {
      const {data} = await getBrandHouse({
         variables: {
            shopId: row.shopId
         }
      })
      if(data) {
         if(newWindow) {
            window.open(StockItemEditPagePath(data?.ShopById?.brandHouseId, row.shopId, row._id), '_blank', '')
         } else {
            navigate(StockItemEditPagePath(data?.ShopById?.brandHouseId, row.shopId, row._id))
         }
      }
   }

   let commands: FlexCommand<FlexStockItemFragment>[] = [{
      id: "retouch",
      title: "Retouch Images",
      execute: async rows => {
         for (const row of rows) {
            await reprocessItemImages({
               variables: {
                  stockItemId: row._id,
                  shopId: row.shopId
               }
            })
         }
      },
      icon: PictureAsPdf
   }, {
      id: "status",
      title: "Change status",
      icon: Publish,
      children: [{
         id: "Publish",
         title: "To Sale",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: row.shopId,
                     stockItem: {
                        _id: row._id,
                        published: true,
                        draft: false,
                        review: false
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: Publish
      }, {
         id: "Review",
         title: "To Review",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: row.shopId,
                     stockItem: {
                        _id: row._id,
                        published: false,
                        draft: false,
                        review: true
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: Publish
      }, {
         id: "Draft",
         title: "To Draft",
         execute: async rows => {
            for (const row of rows) {
               await publishItem({
                  variables: {
                     shopId: row.shopId,
                     stockItem: {
                        _id: row._id,
                        published: false,
                        draft: true,
                        review: false
                     } as StockItemUpdateInput
                  },
                  refetchQueries: ['StockItemPagination']
               })
            }
         },
         icon: GetApp
      }]
   }, {
      id: "ImageSynchronize",
      title: "Synchronize Images",
      execute: async rows => {
         for (const row of rows) {
            await synchronizeImage({
               variables: {
                  stockItem: row._id
               }
            })
         }
      },
      icon: AutorenewIcon
   }]

   return <Page>
      <PageHeader title={"Products"}/>
      <PageContent fullHeight>
         <StockItemsList onSelectItem={onSelectRow} listId={"logisticsStockItemList"}
                         columnOptions={{
                            brand: true,
                            locationTs: true
                         }}
                         commands={commands}
                         additionalFilter={{
                            brandItemId: null
                         }}
         />
      </PageContent>
   </Page>
}