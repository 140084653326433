import * as React from "react";
import {useFormContext} from "react-hook-form";
import {Alert, Autocomplete, Box, TextField, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {merge} from "lodash";

export interface Option {
   id: string
   icon?: string
   name: string
}

export type FormInputProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   control?: any;
   label: string;
   options: Option[]
   errorMessage?: string
}

export const FormInputAutocomplete = ({name, options, label, ...rest}: FormInputProps) => {
   const {style} = rest;

   const classes = useStyles();

   const {formState: {errors}, watch, setValue} = useFormContext();
   const value = watch(name);
   const selected = options.find(opt => opt.id === value) || null;

   const showError = !!errors[name]

   return <Autocomplete
      onChange={(e, option) => {
         if (Array.isArray(option)) {
            setValue(name, option.map(opt => opt.id));
         } else {
            setValue(name, option?.id);
         }
      }}
      options={options}
      fullWidth
      autoHighlight
      value={selected}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
         return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
            {option.icon && <img
               loading="lazy"
               width="20"
               src={option.icon}
               alt={option.name}
            />}
            {option.name}
         </Box>
      }}
      size={"small"}
      renderInput={(params) => {
         const {InputProps, size, ...rest} = params;

         const newProps = merge(InputProps, {
            disableUnderline: true,
            style: {backgroundColor: 'transparent'},
         });

         return <div className={classes.noBorderRadius}>
            <TextField
               size={"small"}
               label={label}
               variant="filled"
               margin={"none"}
               title={value}
               InputProps={newProps}
               style={merge(style, {
                  border: '1px solid #d0d0d0',
                  marginBottom: '8px'
               })}
               {...rest}
            />
            {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
         </div>
      }}
   />
}


const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});

