import {Checkbox, IconButton, Menu, MenuItem} from "@mui/material";
import * as React from "react";
import {EnumBrandSettingLegacyIntegrationDisabledMessages} from "../../../Queries";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type SelectedMessagesProps = {
   id: string
   label: string
   onSelect: (value: EnumBrandSettingLegacyIntegrationDisabledMessages, selected: boolean) => void
   values?: string[]
   selected?: string[]
   disabled?: boolean
}

export const SelectedMessages = ({id, label, onSelect, values, selected, disabled}: SelectedMessagesProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const relevantMessages = (values || [])
   if (relevantMessages.length === 0) {
      return <></>
   }
   return <>
      <IconButton
         disabled={disabled}
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? id + '-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         color={"secondary"}
      >
         <ArrowDropDownIcon/>
      </IconButton>
      <Menu
         anchorEl={anchorEl}
         id={id + "-menu"}
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {relevantMessages.map(message => {
            const isSelected = !((selected || []).indexOf(message) > -1)
            return <MenuItem key={id + 'menu_message_' + message}
                             onClick={() => onSelect(message as EnumBrandSettingLegacyIntegrationDisabledMessages, isSelected)}>
               <Checkbox checked={isSelected}/>
               {message}
            </MenuItem>
         })}
      </Menu>
   </>
}
