import * as React from "react";
import Grid from "@mui/material/Grid";
import {GridDirection} from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles";
import {useMediaQuery} from "@mui/material";

type PageGroupProps = {
   widths?: string[]
   direction?: GridDirection
}

const PageGroup: React.FC<PageGroupProps> = ({children, widths, direction}) => {
   const isDesktop = useMediaQuery('(min-width:800px)');
   const classes = useStyles();
   const childCount = React.Children.count(children)
   return <Grid container className={classes.container} direction={isDesktop ? direction : 'column'} alignItems={"stretch"}>
      {children && React.Children.map(children, (child, index) => {
         if (React.isValidElement(child)) {
            const width = widths && widths[index] ? widths[index] : (direction === 'row' ? (100 / childCount) : 100) + '%';
            return <div style={{width: width}} key={"group_" + index}>{child}</div>
         }
         return null;
      })}
   </Grid>
}

export default PageGroup


const useStyles = makeStyles({
   container: {
      marginBottom: '10px',
      gap: '10px',
      flexWrap: "nowrap",
      height: '100%'
   }
});

