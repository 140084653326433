import * as React from "react"
import {FC, useEffect} from "react"
import {
   Checkbox,
   FormControl,
   InputLabel,
   LinearProgress,
   ListItemText,
   MenuItem,
   MenuProps,
   OutlinedInput,
   Select, SelectChangeEvent
} from "@mui/material";
import {QComponentInputProps} from "../Model";
import axios from "axios";
import {cloneDeep, get, isArray, set} from "lodash";
import Box from "@mui/material/Box";

type QSelectRequest = {
   url: string,
   headers?: any,
   method: 'get' | 'post'
}

type QSelectTransform = (data: any) => QSelectOption[]

type QSelectOption = {
   value: any
   label: string
   group?: string
}

type QSelectFieldProps = QComponentInputProps & {
   label: string
   options?: QSelectOption[]
   request?: QSelectRequest
   transform?: QSelectTransform
   multiple?: boolean
}

type QInternOption = {
   value?: any
   label: string
   istitle?: boolean
}

export const QSelectInput: FC<QSelectFieldProps> = ({
                                                       field,
                                                       data,
                                                       label,
                                                       required,
                                                       update,
                                                       request,
                                                       options,
                                                       transform,
                                                       multiple
                                                    }) => {
   const [values, setValues] = React.useState<QInternOption[] | null>(null)

   useEffect(() => {
      const updateValues = (options: QSelectOption[]) => {
         let sortedOptions;
         if(options.filter(opt => opt.group).length>0) {
            sortedOptions = options.sort((a, b) => {
               let gSort = 0;
               let lSort = 0;
               if (a.group && b.group) {
                  if (a.group < b.group) {
                     gSort = -1;
                  } else if (a.group > b.group) {
                     gSort = 1;
                  } else {
                     gSort = 0;
                  }
               }
               if (a.label && b.label) {
                  if (a.label < b.label) {
                     lSort = -1;
                  } else if (a.label > b.label) {
                     lSort = 1;
                  } else {
                     lSort = 0;
                  }
               }
               if (gSort === 0) {
                  return lSort;
               } else {
                  return gSort;
               }
            });
         } else {
            sortedOptions = options;
         }
         let newValues:QInternOption[] = [];
         let curGroup:string|undefined;
         for (const sortedOption of sortedOptions) {
            if(sortedOption.group && (!curGroup || curGroup!==sortedOption.group)) {
               curGroup = sortedOption.group;
               newValues.push({
                  istitle: true,
                  label: curGroup
               });
            }
            newValues.push({
               value: sortedOption.value,
               label: sortedOption.label,
               istitle: false
            });
         }
         setValues(newValues);
      }
      if (request && transform) {
         const http = axios.create();
         if (request.method === 'get') {
            http.get(request.url, {
               headers: request.headers
            }).then(value => {
               updateValues(transform(value.data));
            });
         } else if (request.method === 'post') {
            http.post(request.url, undefined, {
               headers: request.headers
            }).then(value => {
               updateValues(transform(value.data));
            });
         }
      } else if (options) {
         updateValues(options)
      }
   }, [options, request, transform])

   if (!values || !options) {
      return <LinearProgress/>
   }


   const handleChange = (event: SelectChangeEvent<any>) => {
      let clonedData = cloneDeep(data);
      if (multiple) {
         set(clonedData, field, event.target.value);
      } else {
         let selectedOption = values.find(value => String(value.value) === String(event.target.value));
         set(clonedData, field, selectedOption?.value || undefined);
      }
      update(clonedData);
   };

   let selected: string | string[];
   if (multiple) {
      let value = get(data, field);
      if (isArray(value)) {
         selected = value
      } else if (value) {
         selected = [value];
      } else {
         selected = []
      }
   } else {
      selected = get(data, field, '');
   }
   let htmlFor = "select_" + field;
   return <FormControl variant="outlined" style={{width: '100%'}}
                       required={required || false}>
      <InputLabel style={{backgroundColor: 'white'}} htmlFor={htmlFor}>{label}</InputLabel>
      <Select
         id={htmlFor}
         labelId={htmlFor}
         value={selected}
         onChange={handleChange}
         multiple={multiple}
         error={required && !get(data, field, undefined)}
         fullWidth={true}
         input={<OutlinedInput label="Tag"/>}
         renderValue={(selected: any) => (
            <Box>
               {multiple && selected.map((value: string) => (
                  <div key={'qselect_' + value}>{options?.find(option => option.value === value)?.label || 'Undefined'}</div>
               ))}
               {!multiple && <div>{options?.find(option => option.value === selected)?.label || 'Undefined'}</div>}
            </Box>
         )}
         MenuProps={menuProps}
      >
         <option aria-label="None" value=""/>
         {values.map(value => {
            return <MenuItem key={value.value} value={value.value} disabled={value.istitle}>
               {!value.istitle && multiple && <Checkbox checked={selected.indexOf(value.value) > -1}/>}
               <ListItemText primary={value.label}/>
            </MenuItem>
         })}
      </Select>
   </FormControl>
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
   },
   transformOrigin: {
      vertical: "top",
      horizontal: "left"
   }
};

