import {gql, useQuery} from "@apollo/client";

const qlConsumerTradeIns = gql`
   query TradeInMany($consumerId: MongoID!) {
     TradeInMany(filter: {consumerId:$consumerId}) {
       _id
       registered
       registeredTs
       notified
       dateTime
       shippingType
         
       items {
         _id
         tradeInId
         shopId
         shop {
           brandHouseId
         }

         name
         sku
         imageUrls
         size
         color
         externUrl
         wholeSale {
           categoryId
           name
           value
         }
         stockItem {
           _id
         }
         rejected
         tradeIn {
            registered
         }
       }
     }
   }
`;

export interface ConsumerTradeInItem {
   _id: string
   tradeInId: string
   shopId: string
   shop: {
      brandHouseId: string
   }
   name: string
   sku: string
   imageUrls: string[]
   size: string
   color: string
   externUrl: string
   wholeSale?: {
      categoryId: string
      name: string
      value: number
   }
   stockItem?: {
      _id: string
   }
   rejected: boolean
   tradeIn: {
      registered?: boolean
   }
}

export interface ConsumerTradeIn {
   _id: string
   shopId: string
   registered?: boolean
   registeredTs?: string
   notified?: boolean
   dateTime: string
   shippingType: string

   items: ConsumerTradeInItem[]
}

const useConsumerTradeIns = (consumerId: string):ConsumerTradeIn[]|null => {
   const {data} = useQuery(qlConsumerTradeIns, {
      variables: {
         consumerId: consumerId
      }
   });

   if(!data) {
      return null;
   }

   return data.TradeInMany as ConsumerTradeIn[]
}

export default useConsumerTradeIns