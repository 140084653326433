import * as React from "react"
import {FC, useEffect} from "react"
import {TextField, Theme} from "@mui/material";
import {QComponentInputProps} from "../Model";
import {cloneDeep, get, set} from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {compile} from "expression-eval";
import {Cached} from "@mui/icons-material";

type QTextFieldProps = QComponentInputProps & {
   label: string
   type?: string
   multiline?: boolean
   readonly?: boolean
   startAdornment?: string
   expression?: string
   appendage?: string
}

export const QTextInput: FC<QTextFieldProps> = ({
                                                   field,
                                                   data,
                                                   label,
                                                   type,
                                                   required,
                                                   update,
                                                   multiline,
                                                   focus,
                                                   readonly,
                                                   startAdornment,
                                                   expression,
                                                   appendage
                                                }) => {
   const [value, setValue] = React.useState<string | "">("");
   const textRef = React.useRef<HTMLInputElement>();
   const classes = useStyles();

   useEffect(() => {
      setValue(get(data, field, undefined) || '');
   }, [data, field])

   const recalculate = () => {
      if (expression) {
         let fn = compile(expression);
         let curValue = fn(data);
         if(typeof curValue === 'number') {
            curValue = Math.round(curValue);
         }

         let clonedData = cloneDeep(data);
         if (type !== 'number' || curValue === '') {
            set(clonedData, field, curValue || null)
         } else {
            set(clonedData, field, Number(curValue));
         }
         update(clonedData);
      }
   }

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let eventValue: string | null = event.target.value;
      if (eventValue) {
         setValue(eventValue);
      } else {
         setValue("");
      }

      let handler = setTimeout(() => {
         const curValue = textRef.current?.value || '';
         let clonedData = cloneDeep(data);
         if (type !== 'number' || curValue === '') {
            set(clonedData, field, curValue || null)
         } else {
            set(clonedData, field, Number(curValue));
         }
         update(clonedData);
      }, 50);
      return () => clearTimeout(handler);
   };

   let hasError = (required && (!value || value === '')) || false;


   let endAdornment: JSX.Element | undefined;
   let endAdornmentClassname: string | undefined;
   if (appendage) {
      endAdornment = <div style={{paddingTop: '10px', width: '100%', color: '#a0a0a0'}}
                          dangerouslySetInnerHTML={{__html: appendage}}/>
      endAdornmentClassname = classes.appendage
   } else if (expression) {
      endAdornment = <div onClick={recalculate} style={{
         cursor: 'pointer',
         padding: '12px',
         backgroundColor: '#f0f0f0',
         marginRight: '-12px'
      }}><Cached/></div>
   }
   return <TextField
      inputRef={textRef}
      label={label}
      id={field}
      value={value}
      fullWidth={true}
      onChange={handleChange}
      variant="filled"
      type={type}
      disabled={readonly || false}
      multiline={multiline || false}
      required={required || false}
      error={hasError}
      InputProps={{
         disableUnderline: true, style: {backgroundColor: 'transparent'},
         startAdornment: startAdornment ? <div className={classes.startAdornment}>{startAdornment}</div> : undefined,
         endAdornment: endAdornment,
         className: endAdornmentClassname
      }}
      style={{border: '1px solid #d0d0d0'}}
   />
}

const useStyles = makeStyles((theme: Theme) => createStyles({
   startAdornment: {
      marginLeft: "-12px",
      paddingTop: "26px",
      paddingBottom: "10px",
      paddingRight: "12px",
      paddingLeft: "12px",
      marginRight: '8px',
      color: theme.palette.secondary.main,
      backgroundColor: '#f0f0f0',
      whiteSpace: 'nowrap'
   },
   appendage: {
      flexWrap: 'wrap'
   }
}));
