import {Button, Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import * as React from "react";
import {Languages} from "./languages/Languages";

type LanguageMenuProps = {
   label?: string
   startIcon?: React.ReactNode | boolean
   onSelect: (language: string) => void
   hideLocales?: string[]
   allowLocales?: string[]
}

export const LanguageMenu = ({onSelect, hideLocales, allowLocales, label, startIcon}: LanguageMenuProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   let divider: boolean = false;

   let buttonStartIcon: React.ReactNode | undefined = undefined;
   if (startIcon === false) {
      buttonStartIcon = undefined
   } else if (startIcon) {
      buttonStartIcon = startIcon
   } else {
      buttonStartIcon = <AddCircle/>
   }

   return <>
      <Button
         style={{marginLeft: 0}}
         onClick={handleClick}
         size="small"
         sx={{ml: 2}}
         aria-controls={open ? 'account-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         startIcon={buttonStartIcon}
         variant={"outlined"}
         color={"secondary"}
      >
         {label || 'Add language'}
      </Button>
      <Menu
         anchorEl={anchorEl}
         id="account-menu"
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
            style: {
               maxHeight: '400px'
            }
         }}
      >
         {Languages.all()
            .filter(locale => ((allowLocales || []).length === 0 || allowLocales?.includes(locale.key)))
            .filter(locale => !(hideLocales || []).find(lf => lf === locale.key)).map(locale => {
               let showDivider = false;
               if (!locale.main && !divider) {
                  divider = true;
                  showDivider = true;
               }

               const elements: any[] = [];
               if (showDivider) {
                  elements.push(<Divider/>)
               }
               elements.push(<MenuItem key={'menu_locale_' + locale.key} onClick={() => onSelect(locale.key)}>
                     <ListItemIcon>
                        <LanguageIcon fontSize="small"/>
                     </ListItemIcon>
                     {locale.name}
                  </MenuItem>
               )
               return elements
            })}
      </Menu>
   </>
}

