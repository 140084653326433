import {useParams} from "react-router";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import PageContent from "../../../layout/PageContent";
import {BodyText, TitleText} from "../../../layout/Typography";
import SettingsHeader from "../SettingsHeader";
import {Box, Button, Card, CardActionArea, CardContent, Grid, LinearProgress} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {
   DetailsPageBrandByIdDocument,
   EnumShopFrontendType,
   NavigationDataDocument,
   ShopTabsShopsDocument,
   useShopCreateGetQuery,
   useShopCreateMutation
} from "../../../Queries";
import {FrontendIcon} from "./FrontendIcon";
import {DetailsPagePath} from "./DetailsPage";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";
import {OpenInNew} from "@mui/icons-material";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {SelectLanguage} from "../../../components/SelectLanguage";
import {SavingUI} from "../../../SavingProvider";
import {PubSub} from "../../../components/PubSub";
import {SalesforceEdit} from "./SalesforceEdit";

export const ShopCreatePath = (brandHouseId: string) => {
   return DetailsPagePath(brandHouseId) + '/create'
}

type ShopCreateType = {
   type: EnumShopFrontendType
   name: string
   description: string
   disabled?: boolean
}
const ShopTypes: ShopCreateType[] = [{
   type: EnumShopFrontendType.shopify,
   name: 'Shopify',
   description: 'The market leading robust ecommerce platform is fully integrated into the create2STAY platform'
}, {
   type: EnumShopFrontendType.custom,
   name: 'Custom shop',
   description: 'If you operate an ecommerce platform that is not directly supported, you can add a custom integrations. See the API documentation for additional information.'
}, {
   type: EnumShopFrontendType.salesforce,
   name: 'Salesforce',
   description: 'Salesforce Commerce Cloud is partly integrated into the create2STAY platform.',
   disabled: true
}, {
   type: EnumShopFrontendType.magento,
   name: 'Magento',
   description: 'Adobe Commerce or Magento is partly integrated into the create2STAY platform.',
   disabled: true
}, {
   type: EnumShopFrontendType.woocommerce,
   name: 'Woocommerce',
   description: 'Fully supported at create2STAY platform with plugins and API integration',
   disabled: true
}]

export const ShopCreate = () => {
   const {brandHouseId} = useParams<ParamBrandHouse & ParamShop>()
   const navigate = useNavigate();

   return <div>
      <SettingsHeader title={"Add shop to brand"}>
         <Button onClick={() => navigate(DetailsPagePath(brandHouseId))} color={"secondary"}
                 variant={"contained"}>Cancel</Button>
      </SettingsHeader>
      <PageContent>
         <TitleText type={"h3"}>Select shop type</TitleText>
         <Grid container sx={{margin: '0 -5px'}}>
            {ShopTypes.map(type => {
               return <Grid xs={6} lg={4} item key={'shopType_' + type.type}>
                  <Card sx={{flex: '1', flexGrow: '1 1 auto', display: 'flex', padding: '5px', margin: '5px'}}>
                     <CardActionArea onClick={() => navigate(ShopCreateStep2Path(brandHouseId, type.type))}>
                        <CardContent sx={{textAlign: 'center'}}>
                           <FrontendIcon size={"80px"} type={type.type}/>
                        </CardContent>
                        <CardContent sx={{textAlign: 'center', height: '140px', overflow: 'hidden'}}>
                           <TitleText type={"h3"}>{type.name}</TitleText>
                           <BodyText type={"subtitle1"}>{type.description}</BodyText>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
            })}
         </Grid>
      </PageContent>
   </div>
}

const ShopCreateStep2Path = (brandHouseId: string, type: EnumShopFrontendType) => {
   return ShopCreatePath(brandHouseId) + '/' + type
}

export const ShopCreateStep2 = () => {
   const {type} = useParams<{ type: string }>();

   if (type === EnumShopFrontendType.shopify) {
      return <ShopifyCreate/>
   } else if (type === EnumShopFrontendType.custom) {
      return <CustomCreate/>
   } else if (type === EnumShopFrontendType.salesforce) {
      return <SalesforceEdit/>
   } else {
      return <div>Other</div>
   }
}

type ShopifyCreateData = {
   domain: string
}

type ShopifyApp = {
   title: string
   url: string
   test: boolean
}

const ShopifyApps: ShopifyApp[] = [{
   title: 'Production',
   url: 'https://ourimpacthub-app.azurewebsites.net/',
   test: false
}, {
   title: 'Development',
   url: 'https://ourimpacthub.eu.ngrok.io/',
   test: true
}, {
   title: 'Test Site',
   url: 'https://c2s-test-shopify.azurewebsites.net/',
   test: true
}]

const ShopifyCreate = () => {
   const textRef = React.useRef<HTMLInputElement>();
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const navigate = useNavigate();
   const [link, setLink] = useState<string | undefined>();
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   const methods = useForm<ShopifyCreateData>({
      resolver: yupResolver(yup.object().shape({
         domain: yup.string()
            .matches(/.*\.myshopify\.com$/, 'Should end with myshopify.com')
      })),
      defaultValues: {
         domain: 'shop1.myshopify.com'
      },
      reValidateMode: "onChange"
   });

   const {handleSubmit} = methods;

   useEffect(() => {
      if (textRef) {
         textRef.current?.focus();
         textRef.current?.select()
      }
   }, [])

   const validApps = ShopifyApps.filter(app => {
      return !(app.test && !isAdministrator);
   });

   const onGenerate = (data: ShopifyCreateData) => {

      if (validApps.length === 1) {
         const app = validApps[0];
         const url = app.url + 'install?shop=' + link
         window.open(url, '_blank');
      } else {
         setLink(data.domain);
      }
   }

   return <div>
      <SettingsHeader title={"Shopify registration"}>
         <Button onClick={() => navigate(ShopCreatePath(brandHouseId))} color={"secondary"}
                 variant={"contained"}>Back</Button>
      </SettingsHeader>
      <PageContent>
         {link && <Grid container><Grid item xs={12}>
            <TitleText type={"h2"}>Select environment (administrator only)</TitleText>
         </Grid>{ShopifyApps.map(app => {
            if (app.test && !isAdministrator) {
               return null;
            }
            const url = app.url + 'install?shop=' + link
            return <Grid item xs={3} sx={{padding: '10px'}}>
               <Card key={'app_' + app.url}>
                  <CardActionArea onClick={() => window.open(url, '_blank')}>
                     <CardContent sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <OpenInNew/>
                        <TitleText type={"h3"}>{app.title}</TitleText>
                     </CardContent>
                  </CardActionArea>
               </Card>
            </Grid>
         })}</Grid>}
         {!link && <>
            <FormProvider {...methods}>
               <TitleText type={"h2"}>Shopify store registration</TitleText>
               <BodyText type={"body1"}>
                  Choose the store that will install this app
               </BodyText>
               <FormInputText style={{marginTop: '20px'}} inputRef={textRef} name={"domain"} InputProps={{
                  startAdornment: <Box sx={{color: '#a0a0a0', marginRight: '10px', marginTop: '18px'}}>https://</Box>,
               }} label={"Enter merchants shopify domain (example: shop1.myshopify.com)"}/>
            </FormProvider>
            <Box sx={{marginTop: '20px'}}>
               <Button variant={"contained"} onClick={handleSubmit(onGenerate)}>Generate install link</Button>
            </Box>
         </>}
      </PageContent>
   </div>
}

type CustomData = {
   name: string
   externalId: string
   currency: string
   primaryLocale: string
   mainCountryCode: string
   tradeInBaseUrl: string
}

const CustomCreate = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();
   const [shopCreate] = useShopCreateMutation()
   const {data} = useShopCreateGetQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   })
   const navigate = useNavigate();

   const methods = useForm<CustomData>({
      resolver: yupResolver(yup.object().shape({
         name: yup.string().required('Please provide a name'),
         externalId: yup.string().required('Domain of external shop is required'),
         currency: yup.string().required('Currency is required'),
         primaryLocale: yup.string().min(2, 'Language is required'),
         mainCountryCOde: yup.string().min(2, 'Please select a country')
      })),
      defaultValues: {
         name: '',
         externalId: '',
         currency: 'DKK',
         primaryLocale: 'da',
         mainCountryCode: 'DK',
         tradeInBaseUrl: '/'
      },
      reValidateMode: "onChange"
   });

   const {handleSubmit} = methods;

   const onSave = async (shop: CustomData) => {
      await SavingUI.process(async () => {
         await shopCreate({
            variables: {
               name: shop.name,
               frontendType: EnumShopFrontendType.custom,
               currency: shop.currency,
               mainCountryCode: shop.mainCountryCode,
               shop: shop.externalId,
               locale: shop.primaryLocale,
               license: data!.BrandHouse!.license!,
               tradeInBaseUrl: shop.tradeInBaseUrl || '/'
            },
            refetchQueries: [{
               query: DetailsPageBrandByIdDocument,
               variables: {
                  id: brandHouseId
               }
            }, {
               query: NavigationDataDocument
            }, {
               query: ShopTabsShopsDocument,
               variables: {
                  id: brandHouseId
               }
            }]
         });
         PubSub.emit('NavigationReload');
         navigate(DetailsPagePath(brandHouseId));
      }, 'Shop created')
   }

   if (!data) {
      return <LinearProgress/>
   }

   return <div>
      <SettingsHeader title={"Custom shop registration"}>
         <Button onClick={() => navigate(ShopCreatePath(brandHouseId))} color={"secondary"}
                 variant={"contained"}>Back</Button>
      </SettingsHeader>
      <PageContent>
         <TitleText type={"h3"}>Please fill out the following</TitleText>
         <Box>
            <FormProvider {...methods}>
               <FormInputText name={"name"} label={"Name of shop"}/>
               <FormInputText name={"externalId"} label={"Primary domain (example: myshop.com)"}/>
               <FormInputSelect name={"currency"} label={"Currency"} options={[{
                  id: "EUR",
                  name: "Euro €"
               }, {
                  id: "DKK",
                  name: "Danish Krone (DKK)"
               }]}/>
               <SelectLanguage name={"primaryLocale"} label={"Main language"}/>
               <FormInputText name={"tradeInBaseUrl"} label={"TradeIn App Url"}/>
               <Button variant={"contained"} onClick={handleSubmit(onSave)}>Create</Button>
            </FormProvider>
         </Box>
      </PageContent>
   </div>
}
