import * as React from "react";
import {useEffect} from "react";
import {PubSub} from "./components/PubSub";
import {Dialog, DialogActions, DialogTitle, TextareaAutosize} from "@mui/material";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";

type ErrorHandlerProps = {}

export const ErrorHandler: React.FunctionComponent<ErrorHandlerProps> = (props) => {
   const [error, setError] = React.useState<string|null>(null);
   const classes = useStyles();

   useEffect(() => {
      PubSub.on('errors', (args) => {
         setError(JSON.stringify(args, null, '\t'));
      });
   }, [])

   const handleClose= () => {
      setError(null);
   }

   return <div>
      {error && <Dialog fullWidth={true} maxWidth={"md"} open={!!error} onClose={handleClose}>
         <DialogTitle id="error-dialog-title">Unexpected error occured</DialogTitle>
         <TextareaAutosize className={classes.editor} value={error}/>
         <DialogActions>
            <Button onClick={handleClose} color="primary">
               Hide dialog
            </Button>
         </DialogActions>
      </Dialog>}
      {props.children}
   </div>
}

const useStyles = makeStyles({
   editor: {
      width: '100%',
      backgroundColor: '#f0f0f0'
   }
});