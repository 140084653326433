import {FC} from "react";
import {Divider, LinearProgress} from "@mui/material";

import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {TitleText} from "../../layout/Typography";
import {useStatTradeInStatusQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";

type StatTradeInStatusProps = {
   start: Date
   end: Date
   brand?: BrandSelection
   splits: Split[]
   onlyTotals?: boolean
}

export const StatTradeInChart: FC<StatTradeInStatusProps> = ({start, end, splits, brand, onlyTotals}) => {
   const {data} = useStatTradeInStatusQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         filter: "1",
         shopIds: brand?.shopIds,
         brandHouseIds: brand?.brandHouseIds,
         splits: splits,
      }
   })

   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   type OrderData = {
      day: string
      total: number
      registered: number
      stocked: number
   }

   const transformed: OrderData[] = [];
   data?.StatisticsCalculate?.splits?.forEach(split => {
      let result = data?.StatisticsCalculate?.results?.filter(res => res?.split === split);
      transformed.push({
         day: split,
         total: result?.find(res => res?.kpi === 'tradeInItems')?.value || 0,
         registered: result?.find(res => res?.kpi === 'tradeInItemsReceived')?.value || 0,
         stocked: result?.find(res => res?.kpi === 'tradeInItemsStocked')?.value || 0,
      })
   });

   return <div>
      <TitleText type={"h2"}>TradeIns</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <ResponsiveContainer width={"100%"} height={300}>
         <AreaChart
            width={400}
            height={400}
            data={transformed}
            margin={{top: 5, right: 20, left: 10, bottom: 5}}
            syncId={"orders"}
         >
            <XAxis dataKey="day"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <CartesianGrid stroke="#f5f5f5"/>
            <Area type="monotone" dataKey="total" stroke="#808080" fill="#C0C0C0"/>
            {!onlyTotals && <Area type="monotone" dataKey="registered" stroke="#808080" fill="#A0A0A0"/>}
            {!onlyTotals && <Area type="monotone" dataKey="stocked" stroke="#808080" fill="#FF5376"/>}
         </AreaChart>
      </ResponsiveContainer>
   </div>
}
