import PageContent from "../../../layout/PageContent";
import {
   BrickMailFragment,
   BrickMailGetDocument,
   BrickMailMailsInput,
   BrickMailValidation,
   BrickMailValidationResultEnum,
   EnumBrickMailMailsMailType,
   useBrickMailGetQuery,
   useBrickMailTestMutation,
   useBrickMailUpdateMutation
} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {
   Alert,
   Badge,
   Box,
   Button,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   FormControlLabel,
   Grid,
   LinearProgress,
   Paper,
   Snackbar,
   Tab,
   Tabs,
   TextField,
   ToggleButton,
   ToggleButtonGroup
} from "@mui/material";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {
   Brick,
   BrickBox,
   BrickButton,
   BrickImage,
   BrickMailDefinition,
   BrickMailInstance,
   BrickProducts,
   BrickText
} from "./BrickMailType";
import {ParamBrandHouse} from "../../../Parameters";
import {SavingUI} from "../../../SavingProvider";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputColor} from "../../shop/wholesale/form/FormInputColor";
import {FormInputHtml} from "../../shop/wholesale/form/FormInputHtml";
import SettingsHeader from "../SettingsHeader";
import {SelectOption} from "../../../components/SimpleSelect";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

import {
   BoxBrickHtml,
   ButtonBrickHtml,
   ImageBrickHtml,
   ProductsBrickHtml,
   StartHtml,
   TextBrickHtml
} from "./SendGridData";
import ReactDOM from "react-dom";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import {StringHelper} from "../../../utility/StringHelper";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {usePrompt} from "../../../components/useBlocker";
import {ObjectHistory} from "../../components/ObjectHistory";

const alignments: SelectOption[] = [{
   id: "left",
   name: "Left"
}, {
   id: "center",
   name: "Center"
}, {
   id: "right",
   name: "Right"
}]

export const MailEditPagePath = (brandHouseId: string, shopId: string, mailType: string, language?: string) => {
   let path = "/brands/" + brandHouseId + "/settings/mails/" + shopId + "/" + mailType;
   if (language) {
      path += '/' + language
   }
   return path
}

type FormDataMail = BrickMailFragment & {
   mailType?: MailEditorEnum
}

type FormData = {
   mails: FormDataMail[];
   shopId: string;
   type: string;
   editor: string
}

enum MailEditorEnum {
   Brick = 'BRICK',
   Html = 'HTML',
   Json = 'JSON'
}

export const MailEditPage = () => {
   const {brandHouseId, language} = useParams<ParamBrandHouse & { language: string }>();
   const [open, setOpen] = React.useState<boolean>(false);
   const [sent, setSent] = React.useState<string>('');
   const [receiver, setReceiver] = React.useState<string | undefined>(localStorage.getItem('c2s-testmail') || undefined);
   const [consumer, setConsumer] = React.useState<string | undefined>(localStorage.getItem('c2s-testconsumer') || undefined);
   const [activations, setActivations] = React.useState<boolean>(localStorage.getItem('c2s-activations') === 'true');

   const [saveMessages, setSaveMessages] = React.useState<BrickMailValidation[]>([])
   const {shopId, mailType} = useParams<{ shopId: string, mailType: string }>();
   const navigate = useNavigate();

   const {data} = useBrickMailGetQuery({
      variables: {
         shopId: shopId,
         type: mailType
      }
   });

   const [save] = useBrickMailUpdateMutation();
   const [testMail] = useBrickMailTestMutation();

   const form = useForm<FormData>();
   const {setValue, handleSubmit, watch, formState: {isDirty}} = form;

   useEffect(() => {
      if (data) {
         const brickMail = data.BrickMail;
         const mails: BrickMailFragment[] = []
         let mainEditorType: EnumBrickMailMailsMailType = EnumBrickMailMailsMailType.BRICK
         for (const mail of brickMail?.mails || []) {
            let mailJson: string | undefined = undefined;
            if (mail?.mail) {
               mailJson = JSON.stringify(mail.mail, null, 2)
            } else {
               mailJson = undefined;
            }
            let editorType = mail!.mailType || EnumBrickMailMailsMailType.BRICK;
            mails.push({
               mail: mailJson,
               htmlMail: mail!.htmlMail,
               language: mail!.language,
               mailType: editorType
            })
            if (mainEditorType !== editorType) {
               mainEditorType = editorType
            }
         }
         const formData: FormData = {
            mails: mails as FormDataMail[],
            shopId: shopId,
            type: brickMail!.type!,
            editor: mainEditorType
         };
         form.reset(formData);
      }
   }, [data, form, shopId])

   usePrompt('Unsaved changes', isDirty);

   if (data === undefined) {
      return <LinearProgress/>
   }

   const allLanguages = data?.ShopById?.tradeInLocales || []
   const selectedLanguage = language || allLanguages[0] || undefined;
   const mailData = data?.BrickMail?.mails

   const onChange = (event: React.SyntheticEvent, newValue: string) => {
      navigate(MailEditPagePath(brandHouseId, shopId, mailType, newValue), {
         replace: true
      });
   }
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setOpen((previousOpen) => !previousOpen);
   };

   const onTest = async () => {
      if (receiver) {
         localStorage.setItem('c2s-testmail', receiver);
         localStorage.setItem('c2s-activations', String(activations));
         if (consumer) {
            localStorage.setItem('c2s-testconsumer', consumer);
         } else {
            localStorage.removeItem('c2s-testconsumer')
         }
         const {data} = await testMail({
            variables: {
               shopId: shopId,
               type: mailType,
               email: receiver,
               language: selectedLanguage!,
               activations: activations,
               testMail: consumer
            }
         });
         setSent(data?.BrickMailTest || 'Sent, maybe...');
         setOpen(false);
      }
   }

   const onSubmit = async (data: FormData) => {
      await SavingUI.process(async () => {
         setSaveMessages([]);

         const saveResult = await save({
            variables: {
               shopId: shopId,
               type: mailType,
               input: data.mails.map(mail => ({
                  mail: mail.mail ? JSON.parse(mail.mail) : undefined,
                  htmlMail: mail.htmlMail,
                  language: mail.language!,
                  mailType: data.editor === MailEditorEnum.Json ? EnumBrickMailMailsMailType.BRICK : data.editor
               } as BrickMailMailsInput))
            },
            refetchQueries: [{
               query: BrickMailGetDocument,
               variables: {
                  shopId: shopId,
                  type: mailType
               }
            }]
         });
         const errors = saveResult.data?.BrickMailUpdate?.filter(upd => upd?.result !== BrickMailValidationResultEnum.SUCCESS) || [];
         setSaveMessages(errors as BrickMailValidation[])
         return false;
      }, 'Done')
   }

   const editors: SelectOption[] = [{
      name: "Brick",
      id: EnumBrickMailMailsMailType.BRICK
   }, {
      name: "Advanced",
      id: MailEditorEnum.Json
   }];

   return <FormProvider {...form}>
      <SettingsHeader title={data?.BrickMailTypeByID?.title || mailType} onBack>
         <Button variant={"contained"} color={"secondary"} onClick={handleClick}>Send test mail</Button>
         <Dialog open={open} onClose={() => setOpen(false)}>
            <Paper style={{padding: '20px'}}>
               <div>
                  <TitleText type={"h3"}>Send test mail to:</TitleText>
                  <TextField
                     style={{width: '400px'}}
                     value={receiver}
                     color={"secondary"}
                     onInput={(e: any) => setReceiver(e.target.value)} focused variant={"outlined"}
                     placeholder={"Receivers email"}
                  />
               </div>
               <div style={{"marginTop": "20px"}}>
                  <TitleText type={"h3"}>Use data from consumer mail:</TitleText>
                  <TextField
                     style={{width: '400px'}}
                     value={consumer}
                     color={"secondary"}
                     onInput={(e: any) => setConsumer(e.target.value)} focused variant={"outlined"}
                     placeholder={"Consumer email"}
                  />
               </div>
               <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                  <FormControlLabel
                     control={<Checkbox checked={activations} onChange={() => setActivations(!activations)}
                                        name="activations"/>}
                     label="Outline activations"/>
                  <Button onClick={onTest} style={{height: '100%'}} variant={"contained"}>Send</Button>
               </div>
            </Paper>
         </Dialog>
         <Snackbar
            anchorOrigin={{
               vertical: "top",
               horizontal: "right"
            }}
            autoHideDuration={2000} open={!!sent} message={sent} onClose={() => setSent('')}/>
         <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>Save</Button>
      </SettingsHeader>
      {(saveMessages.filter(msg => msg.locale === language).length > 0) && <PageContent>
         {saveMessages!.filter(err => err.locale === language).map(err => {
            return <div key={'error_' + err.locale}>
               <Alert onClose={() => setSaveMessages(saveMessages.filter(msg => msg.locale !== language))}
                      severity={"error"}>{JSON.stringify(err.message, null, 2)}</Alert>
            </div>
         })}
      </PageContent>}
      <PageContent style={{position: 'relative'}}>
         <Tabs value={selectedLanguage} onChange={onChange}>
            {allLanguages.map(language => {
               const messages = saveMessages.filter(msg => msg.locale === language)
               return <Tab key={'tab_' + language}
                           label={
                              <Badge anchorOrigin={{
                                 vertical: 'top',
                                 horizontal: 'right',
                              }}
                                     color={"primary"}
                                     badgeContent={messages.length || undefined}
                              >{language}
                              </Badge>
                           }
                           value={language}
               />
            })}
         </Tabs>
         {mailData?.map((mail, index) => {
            const mailType = watch(`editor`)
            const renderEditor = () => {
               if (mailType === EnumBrickMailMailsMailType.HTML) {
                  return <HTMLEditor mailIndex={index} key={'html_' + mail?.language}/>
               } else if (mailType === EnumBrickMailMailsMailType.BRICK) {
                  return <MailEditor index={index} key={'brick_' + mail?.language}/>
               } else if (mailType as any === MailEditorEnum.Json) {
                  return <JSONEditor mailIndex={index} key={'json_' + mail?.language}/>
               } else {
                  return <Box>Unknown editor {String(mail?.mailType)}</Box>
               }
            }
            if (selectedLanguage !== mail?.language) {
               return null;
            }
            const onChange = (event: React.SyntheticEvent, newValue: string) => {
               setValue(`editor`, newValue, {
                  shouldDirty: false
               });
            }
            return <>
               <Box sx={{
                  marginBottom: '10px',
                  borderBottom: '1px solid #e0e0e0',
                  textAlign: 'right',
                  position: 'absolute',
                  right: '25px',
                  marginTop: '5px'
               }}>
                  <Box>
                     <ToggleButtonGroup value={mailType} onChange={onChange} exclusive={true} size={"small"}>
                        {editors.map(editor => (
                           <ToggleButton key={'mailType_' + editor.id}
                                         value={editor.id}>{editor.name}</ToggleButton>
                        ))}
                     </ToggleButtonGroup>
                  </Box>
               </Box>
               <Box
                  sx={{borderTop: '1px solid #e0e0e0', paddingTop: '10px', display: 'flex', justifyContent: 'center'}}>
                  {renderEditor()}
               </Box>
            </>
         })
         }
      </PageContent>
      <ObjectHistory refId={data.BrickMail?._id}/>
   </FormProvider>
}


const MailEditor: FC<{ index: number }> = ({index}) => {
   const [brick, setBrick] = useState<number | undefined>();
   const [header, setHeader] = useState<boolean>(false);
   const [data, setData] = useState<BrickMailDefinition | undefined>();

   const form = useFormContext<FormData>();
   const {setValue, getValues} = form;

   const classes = useMailEditorStyles();

   useEffect(() => {
      const json = getValues(`mails.${index}.mail`);
      setData(json ? JSON.parse(json) : '');
   }, [getValues, index])

   const formSubmit = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setValue(`mails.${index}.mail`, JSON.stringify(data), {
         shouldDirty: true
      })
   }

   useEffect(() => {
      const mailRoot = document.getElementById('mail-root');
      if (mailRoot && data) {
         ReactDOM.render(<>{data.bricks.map((field: any, i) => {
            let html: string;
            if (field.image) {
               html = ImageBrickHtml(field as BrickImage);
            } else if (field.text) {
               html = TextBrickHtml(field as BrickText, data);
            } else if (field.button) {
               html = ButtonBrickHtml(field as BrickButton, data)
            } else if (field.products) {
               html = ProductsBrickHtml(field as BrickProducts, data, mailInstance)
            } else if (field.box) {
               html = BoxBrickHtml(field as BrickBox, data)
            } else {
               html = `<i>Unsupported</i>`
            }
            return <div key={'brick_' + i} onClick={onClick(i)} className={classes.brick}
                        dangerouslySetInnerHTML={{__html: html}}/>
         })}</>, mailRoot);
      }
      // eslint-disable-next-line
   }, [data])

   const mailInstance: BrickMailInstance = {
      products: {
         data: [{
            image: 'https://storageaccountourimbea2.blob.core.windows.net/brandstatic/App_Icon3.png',
            title: 'create2STAY - Tree',
            voucher: '100 EUR',
            orderDate: '2020-02-02'
         }, {
            image: 'https://storageaccountourimbea2.blob.core.windows.net/brandstatic/App_Icon3.png',
            title: 'create2STAY - The Bag',
            voucher: '150 EUR',
            orderDate: '2020-02-02'
         }],
         boughtPrefix: 'Bought',
         voucherPrefix: 'Credit'
      },
      variables: {
         customerName: 'John',
         voucher: '9999-AAAA',
         labelCode: '99LABELCODE',
         loginLink: 'https://create2stay.com/',
         registrationLink: 'https://create2stay.com/',
         shippingLink: 'https://create2stay.com/',
         shop: 'Shop',
         voucherValue: '100 EUR',
         voucherLink: '#'
      }
   }

   if (data === undefined) {
      return <LinearProgress/>
   }

   const onChange = (updated: Brick) => {
      if (brick !== undefined) {
         const newData: BrickMailDefinition = {
            ...data
         }
         newData.bricks[brick] = updated;
         setData(newData);

         setValue(`mails.${index}.mail`, JSON.stringify(newData, null, 2), {
            shouldDirty: true
         });
      }
      setBrick(undefined)
   }

   const onHeaderChange = (updated: BrickMailDefinition) => {
      if (header) {
         const bricks = data.bricks;
         const newData = {
            ...data,
            ...updated,
            bricks: bricks
         }
         setData(newData);

         setValue(`mails.${index}.mail`, JSON.stringify(newData, null, 2), {
            shouldDirty: true
         });
      }
      setHeader(false)
   }

   const onHeaderClick = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setHeader(true);
   }

   const onClick = (i: number) => (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setBrick(i);
   }

   const onDelete = (i: number) => () => {
      data.bricks.splice(i, 1);
      setBrick(undefined);
      const newData = {
         ...data
      }
      setData(newData);
      setValue(`mails.${index}.mail`, JSON.stringify(newData, null, 2), {
         shouldDirty: true
      });
   }

   return <Paper variant={"outlined"} style={{maxWidth: '602px'}}>
      {brick !== undefined && <Dialog open={true} onClose={() => setBrick(undefined)}
                                      fullWidth={true} maxWidth={"md"}>
         <DialogContent>
            <BrickEditor brick={data.bricks[brick]} onDelete={onDelete(brick)} onChange={onChange}
                         onCancel={() => setBrick(undefined)}/>
         </DialogContent>
      </Dialog>}

      {header && <Dialog open={true} onClose={() => setHeader(false)} fullWidth={true} maxWidth={"md"}>
         <DialogContent>
            <HeaderEditor header={data} onChange={onHeaderChange} onCancel={() => setHeader(false)}/>
         </DialogContent>
      </Dialog>}

      <form onSubmit={formSubmit} id={"superform"}/>
      <div className={classes.container} onClick={onHeaderClick}>
         <div className={classes.header}>
            <TitleText type={"h3"}>
               {data?.subject}
            </TitleText>
         </div>
         <div className={classes.from}>
            <div className={classes.item}>
               <Avatar {...StringHelper.stringAvatar(data?.from?.name || 'create2STAY')}/>
            </div>
            <div className={classes.item}>
               <BodyText type={"body2"}>From: {data?.from?.name}  &lt;{data?.from?.mail}&gt;</BodyText>
               <BodyText type={"body2"}>To: consumer@email.here</BodyText>
            </div>
         </div>
      </div>

      <div onClick={onHeaderClick} dangerouslySetInnerHTML={{__html: StartHtml(data)}}/>
   </Paper>

}

const useMailEditorStyles = makeStyles({
   brick: {
      borderLeft: '4px solid transparent',
      cursor: 'pointer',
      "&:hover": {
         borderLeft: '4px solid #808080',
      }
   },
   editorDialog: {
      minHeight: '400px'
   },
   container: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: '#f0f0f0',
      padding: '10px'
   },
   header: {
      padding: '10px'
   },
   from: {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px'
   },
   item: {
      marginRight: '10px'
   }
});


type BrickEditorProps = {
   brick: Brick
   onChange: (brick: Brick) => void
   onCancel: () => void
   onDelete: () => void
};

const BrickEditor: FC<BrickEditorProps> = ({brick, onChange, onCancel, onDelete, ...props}) => {
   const brickType = brick as any
   const methods = useForm<Brick>();
   const classes = useBrickEditorStyles();

   const {handleSubmit} = methods;
   const formSubmit = handleSubmit((data: Brick) => {
      onChange(data);
   })

   return <div className={classes.brickEditor}>
      <FormProvider {...methods}>
         <form onSubmit={formSubmit} id={"myform"} style={{width: '100%'}}>
            {brickType.image && <></>}
            {brickType.text && <BrickTextEditor brick={brick as BrickText} onChange={onChange} {...props}/>}
            {brickType.button && <BrickButtonEditor brick={brick as BrickButton} onChange={onChange} {...props}/>}
            {brickType.products && <BrickProductsEditor brick={brick as BrickProducts} onChange={onChange} {...props}/>}
            {brickType.box && <BrickBoxEditor brick={brick as BrickBox} onChange={onChange} {...props}/>}
         </form>
      </FormProvider>
      <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
         <Button variant={"outlined"} onClick={onDelete}>
            Delete
         </Button>

         <div>
            <Button onClick={formSubmit} variant={"contained"}>
               Update
            </Button>
            <Button onClick={onCancel}>
               Hide
            </Button>
         </div>
      </DialogActions>
   </div>
}

const useBrickEditorStyles = makeStyles({
   brickEditor: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      minHeight: '400px'
   }
});


type HeaderEditorProps = {
   header: BrickMailDefinition
   onChange: (header: BrickMailDefinition) => void
   onCancel: () => void
};

const HeaderEditor: FC<HeaderEditorProps> = ({header, onChange, onCancel, ...props}) => {
   const methods = useForm<BrickMailDefinition>({
      defaultValues: header
   });
   const classes = useHeaderEditorStyles();
   const {control} = methods;

   const {handleSubmit} = methods;
   const formSubmit = handleSubmit((data: BrickMailDefinition) => {
      onChange(data);
   })

   return <div className={classes.headerEditor}>
      <FormProvider {...methods}>
         <form onSubmit={formSubmit} id={"myform"} style={{width: '100%'}}>
            <div>
               <Grid container spacing={"10px"}>
                  <Grid item xs={12}>
                     <TitleText type={"h2"}>Mail subject</TitleText>
                     <FormInputText name={"subject"} control={control} label={"Subject"}/>
                  </Grid>
                  <Grid item xs={12}>
                     <TitleText type={"h2"}>Mail sender</TitleText>
                  </Grid>
                  <Grid item xs={6}>
                     <FormInputText name={"from.name"} control={control} label={"From name"}/>
                  </Grid>
                  <Grid item xs={6}>
                     <FormInputText name={"from.mail"} control={control} label={"From email"}/>
                  </Grid>
                  <Grid item xs={12}>
                     <TitleText type={"h2"}>Body</TitleText>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputColor name={"body.frameColor"} control={control} label={"Frame color"}
                                     defaultColor={"#C0C0C0"}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputText name={"body.framePadding"} control={control} label={"Frame padding"}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputCheckbox name={"body.frame"} control={control} label={"Frame around content?"}/>
                  </Grid>
                  <Grid item xs={4}>
                     <FormInputColor name={"body.backgroundColor"} control={control} label={"Background color"}
                                     defaultColor={"#FFFFFF"}/>
                  </Grid>

               </Grid>
            </div>
         </form>
      </FormProvider>
      <DialogActions>
         <Button onClick={formSubmit} variant={"contained"}>
            Update
         </Button>
         <Button onClick={onCancel}>
            Hide
         </Button>
      </DialogActions>
   </div>
}

const useHeaderEditorStyles = makeStyles({
   headerEditor: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      minHeight: '400px'
   }
});


const BrickTextEditor: FC<{ brick: BrickText, onChange: (brick: BrickText) => void }> = ({brick, onChange}) => {
   const [tab, setTab] = useState<'text' | 'options'>('text')
   const formContext = useFormContext<Brick>();
   const {reset, control} = formContext;

   useEffect(() => {
      reset(brick)
   }, [brick, reset])

   return <div>
      <Tabs value={tab}>
         <Tab value={'text'} label={"Text"} onClick={() => setTab("text")}/>
         <Tab value={'options'} label={"Options"} onClick={() => setTab("options")}/>
      </Tabs>
      {tab === 'text' && <FormInputHtml height={'250px'} name={"value"} label={"Text"}/>}
      {tab === 'options' && <Grid container spacing={"10px"}>
         <Grid item xs={6}>
            <FormInputText name={"fontSize"} control={control} label={"Font size"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"fontFamily"} control={control} label={"Font family"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputColor name={"color"} control={control} label={"Text color"} defaultColor={"#000000"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputColor name={"backgroundColor"} control={control} label={"Background color"}
                            defaultColor={"#ffffff"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"margin"} control={control} label={"Margin"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"align"} control={control} label={"Align (left, center, right)"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputCheckbox name={"bold"} control={control} label={"Bold"}/>
            <FormInputCheckbox name={"disablePadding"} control={control} label={"Disable padding"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"activation"} control={control} label={"Activation expression"}/>
         </Grid>
      </Grid>}
   </div>
}


const BrickButtonEditor: FC<{ brick: BrickButton, onChange: (brick: BrickButton) => void }> = ({brick, onChange}) => {
   const formContext = useFormContext<Brick>();
   const {reset, control} = formContext;

   useEffect(() => {
      reset(brick)
   }, [brick, reset])


   return <div>
      <Grid container spacing={"10px"}>
         <Grid item xs={6}>
            <FormInputText name={"value"} control={control} label={"Button text"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"url"} control={control} label={"URL for button"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"color"} control={control} label={"Button text color"} defaultColor={"#FFFFFF"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"borderColor"} control={control} label={"Button border color"}
                            defaultColor={"#993300"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"backgroundColor"} control={control} label={"Button background color"}
                            defaultColor={"#993300"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"margin"} control={control} label={"Margin"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={"fontFamily"} control={control} label={"Font"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputSelect name={"align"} control={control} label={"Align"} options={alignments}/>
         </Grid>
         <Grid item xs={12}>
            <FormInputText name={"activation"} control={control} label={"Activation expression"}/>
         </Grid>
      </Grid>
   </div>
}


const BrickBoxEditor: FC<{ brick: BrickBox, onChange: (brick: BrickBox) => void }> = ({brick, onChange}) => {
   const formContext = useFormContext<Brick>();
   const {reset, control} = formContext;

   useEffect(() => {
      reset(brick)
   }, [brick, reset])

   return <div>
      <Grid container spacing={"10px"}>
         <Grid item xs={12}>
            <FormInputText name={"value"} label={"Button text"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"color"} control={control} label={"Button text color"} defaultColor={"#FFFFFF"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"backgroundColor"} control={control} label={"Button background color"}
                            defaultColor={"#993300"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputColor name={"borderColor"} control={control} label={"Button border color"}
                            defaultColor={"#993300"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputText name={"margin"} control={control} label={"Margin"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputText name={"fontSize"} control={control} label={"Font size"}/>
         </Grid>
         <Grid item xs={4}>
            <FormInputText name={"fontFamily"} control={control} label={"Font"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputSelect name={"align"} control={control} label={"Align"} options={alignments}/>
         </Grid>
         <Grid item xs={12}>
            <FormInputText name={"activation"} control={control} label={"Activation expression"}/>
         </Grid>
      </Grid>
   </div>
}


const BrickProductsEditor: FC<{ brick: BrickProducts, onChange: (brick: BrickProducts) => void }> = ({
                                                                                                        brick,
                                                                                                        onChange
                                                                                                     }) => {
   const formContext = useFormContext<Brick>();
   const {reset, control} = formContext;

   useEffect(() => {
      reset(brick)
   }, [brick, reset])

   return <div>
      <Grid container spacing={"10px"}>
         <Grid item xs={12}>
            <FormInputText name={"title"} control={control} label={"Title text"}/>
         </Grid>
         <Grid item xs={12}>
            <FormInputText name={"activation"} control={control} label={"Activation expression"}/>
         </Grid>
      </Grid>
   </div>
}

const JSONEditor: FC<{ mailIndex: number }> = ({mailIndex}) => {
   const form = useFormContext<FormData>();
   const {control} = form;

   return <div style={{width: '100%'}}>
      <Controller
         control={control}
         name={`mails.${mailIndex}.mail`}
         defaultValue={""}
         render={({field: {onChange, value}}) => {
            return <AceEditor
               height={"500px"}
               mode="json"
               theme="tomorrow"
               value={value}
               onChange={onChange}
               name="jsonEditor"
               editorProps={{$blockScrolling: true}}
               placeholder={"Brick JSON"}
               width={"100%"}
            />
         }}
      />
   </div>
}

type HTMLEditorProps = {
   mailIndex: number
}

const HTMLEditor = ({mailIndex}: HTMLEditorProps) => {
   const form = useFormContext<FormData>();
   const {control} = form;

   return <Box sx={{width: '100%'}}>
      <Grid container spacing={1}>
         <Grid item xs={6}>
            <FormInputText name={`mails.${mailIndex}.htmlMail.from.name`} label={"Name of sender"}/>
         </Grid>
         <Grid item xs={6}>
            <FormInputText name={`mails.${mailIndex}.htmlMail.from.mail`} label={"Mail of sender"}/>
         </Grid>
      </Grid>
      <FormInputText name={`mails.${mailIndex}.htmlMail.subject`} label={"Subject"}/>
      <Controller
         control={control}
         name={`mails.${mailIndex}.htmlMail.html`}
         defaultValue={""}
         render={({field: {onChange, value}}) => {
            return <AceEditor
               height={"400px"}
               mode="handlebars"
               theme="tomorrow"
               value={value || ''}
               onChange={onChange}
               name="htmlEditor"
               editorProps={{$blockScrolling: true}}
               placeholder={"Mail content"}
               width={"100%"}
            />
         }}
      />
   </Box>
}
