import * as React from "react";
import {
   BrandRuleExpressionsInput,
   DtcEditBrandRuleFragment,
   EnumBrandRuleExpressionsOperation,
   EnumBrandRuleExpressionsValueType,
   useProductEditorBrandByIdQuery
} from "../../../../../Queries";
import {useFieldArray, useFormContext} from "react-hook-form";
import FormInputAutocomplete2 from "../../../wholesale/form/FormInputAutocomplete2";
import {Box, Grid, IconButton, LinearProgress} from "@mui/material";
import {MenuExpressionTypes} from "./MenuExpressionType";
import {FormInputSelect} from "../../../wholesale/form/FormInputSelect";
import {DeleteForever} from "@mui/icons-material";
import {FormInputText} from "../../../wholesale/form/FormInputText";

export const ProductEditor = (props: { shopId: string }) => {
   const {data} = useProductEditorBrandByIdQuery({
      variables: {
         shopId: props.shopId
      }
   });

   const form = useFormContext<DtcEditBrandRuleFragment>();
   const {getValues} = form;

   const {fields, append, remove} = useFieldArray<DtcEditBrandRuleFragment>({
      name: 'expressions'
   })

   if (!data?.ShopById) {
      return <LinearProgress/>
   }

   const options = {
      TAG: (data?.ShopById?.productTags || []),
      COLLECTION: (data?.ShopById?.productCollections || []),
      VENDOR: (data?.ShopById?.productVendors || []),
      TYPE: (data?.ShopById?.productTypes || []),
      METAFIELD: []
   }

   return <Box sx={{width: '100%'}}>
      {fields.map((field, index) => {
         const expr = getValues(`expressions.${index}.valueType`)

         return <Grid spacing={"4px"} container key={field.id}>
            <Grid item xs={2}>
               <FormInputSelect disabled={true} name={`expressions[${index}].valueType`} label={"Product"}
                                options={Object.values(EnumBrandRuleExpressionsValueType).map(valueType => ({
                                   id: valueType,
                                   name: valueType
                                }))}/>
            </Grid>
            <Grid item xs={1}>
               <FormInputText name={`expressions[${index}].key`} label={"Key (metafield)"}/>
            </Grid>
            <Grid item xs={2}>
               <FormInputSelect name={`expressions[${index}].operation`} label={"Must"}
                                options={Object.values(EnumBrandRuleExpressionsOperation).map(valueType => ({
                                   id: valueType,
                                   name: valueType
                                }))}/>
            </Grid>
            <Grid item xs={5}>
               <FormInputAutocomplete2
                  fullWidth
                  options={options[expr]}
                  name={`expressions[${index}].value`}
                  label={"Any of:"}
               />
            </Grid>
            <Grid item xs={1}>
               <IconButton onClick={() => remove(index)}>
                  <DeleteForever/>
               </IconButton>
            </Grid>
         </Grid>
      })}
      <MenuExpressionTypes onSelect={(type) => {
         append({
            value: [],
            operation: EnumBrandRuleExpressionsOperation.INCLUDE,
            valueType: type
         } as BrandRuleExpressionsInput);
      }}/>
   </Box>
}
