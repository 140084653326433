import PageContent from "../../../layout/PageContent";
import {useParams} from "react-router-dom";
import {Box, Button, LinearProgress} from "@mui/material";
import {TitleText} from "../../../layout/Typography";
import * as React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {SavingUI} from "../../../SavingProvider";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {
   ContactPersonFragment,
   useContactPersonSettingsQuery,
   useContactPersonSettingsUpdateMutation
} from "../../../Queries";
import SettingsHeader from "../SettingsHeader";

export const ContactPersonPage = () => {
   const {brandHouseId} = useParams<{ brandHouseId: string }>()

   const {data} = useContactPersonSettingsQuery({
      variables: {
         brandHouseId: brandHouseId
      }
   });

   const [updateBrand] = useContactPersonSettingsUpdateMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSubmit = async (data: ContactPersonFragment) => {
      await SavingUI.process(async () => {
         await updateBrand({
            variables: {
               brandHouseId: brandHouseId,
               input: data
            }
         })
      }, 'Brand updated')
   }

   return <div>
      <SettingsHeader title={"Contact Person"} subtitle={"The primary contact person for the brand"}>
         <Button type="submit" form={"brandEditForm"} variant={"contained"}>Save</Button>
      </SettingsHeader>
      <PageContent>
         <ContactPersonForm data={data?.BrandSettings as ContactPersonFragment} onSave={onSubmit}/>
      </PageContent>
   </div>
}

type BrandFormProps = {
   data: ContactPersonFragment
   onSave: (data: ContactPersonFragment) => Promise<void>
}

const ContactPersonForm = ({data, onSave}: BrandFormProps) => {
   const methods = useForm<ContactPersonFragment>({
      defaultValues: data
   });

   const {handleSubmit} = methods;

   const onSubmit = async (data: ContactPersonFragment) => {
      await onSave(data);
   }

   return <div>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
         <FormProvider {...methods}>
            <form id={"brandEditForm"} onSubmit={handleSubmit(onSubmit)}>
               <div>
                  <TitleText type={"subtitle1"}>Contact person</TitleText>
                  <FormInputText name={"contactPerson"} required label={"Full name"}/>
                  <TitleText type={"subtitle1"}>Contact person email</TitleText>
                  <FormInputText name={"contactPersonEmail"} required label={"Email"}/>
               </div>
            </form>
         </FormProvider>
      </Box>
   </div>
}