import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Popover, TextField, TextFieldProps} from "@mui/material";
import {HexColorInput, HexColorPicker} from "react-colorful";
import {merge} from "lodash";
import makeStyles from "@mui/styles/makeStyles";

export type FormInputColorProps = Omit<TextFieldProps, 'name'> & {
   name: string;
   control?: any;
   label: string;
   defaultColor?: string
   errorMessage?: string
}

export const FormInputColor = ({name, label, errorMessage, defaultColor, ...rest}: FormInputColorProps) => {
   const {style, InputProps} = rest;
   const classes = useStyles();

   const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
   const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover-' + name : undefined;

   const newStyle = merge(style, {
      border: '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   const {control} = useFormContext();

   return (
      <Controller
         name={name}
         control={control}
         render={({
                     field: {onChange, value},
                     fieldState: {error}
                  }) => {

            const newProps = merge(InputProps, {
               disableUnderline: true,
               style: {backgroundColor: 'transparent'},
               endAdornment: <div aria-describedby={id}
                                  onClick={handleClick}
                                  style={{
                                     cursor: 'pointer',
                                     border: '1px solid black',
                                     width: 40,
                                     height: 40,
                                     backgroundColor: value || defaultColor,
                                     borderRadius: '4px',
                                     marginRight: '-8px'
                                  }}
               />
            });

            const onChangeHandler = (newColor?: string) => {
               onChange(newColor);
            }

            return <div className={classes.noBorderRadius}>
               <TextField
                  id={name}
                  helperText={error ? errorMessage : null}
                  size="small"
                  error={!!error}
                  onChange={onChange}
                  value={(value === undefined || value === null) ? '' : value}
                  fullWidth
                  label={label}
                  variant="filled"
                  margin={"none"}
                  InputProps={newProps}
                  style={newStyle}
                  InputLabelProps={{
                     shrink: true,
                  }}
                  {...rest}
               />
               <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
               }}>
                  <HexColorPicker
                     color={value || defaultColor || ''}
                     onChange={onChangeHandler}
                     title={label}
                  />
                  <HexColorInput color={value || ''} onChange={onChangeHandler}
                                 style={{
                                    padding: '4px',
                                    width: '100%'
                                 }}
                  />
               </Popover>
            </div>
         }}
      />)
}


const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});

