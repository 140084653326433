import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GlobalStyles} from "@mui/material";
import {LicenseInfo} from "@mui/x-license-pro";

const globalStyle = {
   ".rsw-editor": {
      borderRadius: 0
   },
   ".rsw-toolbar": {
      backgroundColor: '#f0f0f0',
      padding: '0px 12px 0 12px'
   },
   ".rsw-ce": {
      padding: "12px"
   }
}

LicenseInfo.setLicenseKey('4207561d3aa76d6f7e5480e0dd307994T1JERVI6MzM0MzAsRVhQSVJZPTE2Njk4MzcyMjEwMDAsS0VZVkVSU0lPTj0x');

ReactDOM.render(
   <React.StrictMode>
      <GlobalStyles styles={globalStyle}/>
      <App/>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
