import {QActivation} from "./components/QActivation";
import {QHeading} from "./components/QHeading";
import * as React from "react";
import {QTextInput} from "./components/QTextInput";
import {QImageField} from "./components/QImageField";
import {QButton} from "./components/QButton";
import {QSelectInput} from "./components/QSelectInput";
import {QVertical} from "./components/QVertical";
import {QHorizontal} from "./components/QHorizontal";
import {QCheckBox} from "./components/QCheckBox";
import {QValidation} from "./components/QValidation";
import {QElement} from "./Model";
import {get} from "lodash";
import {QAccordion} from "./components/QAccordion";

type QRegistryData = {
   [id: string]: {
      component: React.FC<any>
      valid?: (element: QElement, data: any) => boolean
   }
}

const QGenericRequiredValidator = (element: QElement, data: any): boolean => {
   const {required, field} = element.props;
   if (required) {
      let newVar = get(data, field, undefined);
      if (!newVar) {
         return false;
      }
   }
   return true;
}

export const QRegistry: QRegistryData = {
   "TextField": {
      component: QTextInput,
      valid: QGenericRequiredValidator
   },
   "Activation": {
      component: QActivation
   },
   "ImageField": {
      component: QImageField
   },
   "Heading": {
      component: QHeading
   },
   "Vertical": {
      component: QVertical
   },
   "Horizontal": {
      component: QHorizontal
   },
   "Button": {
      component: QButton
   },
   "Select": {
      component: QSelectInput,
      valid: QGenericRequiredValidator
   },
   "CheckBox": {
      component: QCheckBox
   },
   "Validation": {
      component: QValidation
   },
   "Accordion": {
      component: QAccordion
   }
}
