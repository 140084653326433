import {
   Box,
   Button,
   Card,
   CardContent,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   LinearProgress,
   Tab,
   Tabs
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {ProductsList2} from "../../components/ProductList2";
import {
   AddSizeFragment,
   ProductListProductFragment,
   usePotentialAddDialogConsumerQuery,
   usePotentialCreateMutation,
   useProductItemDialogVariantsQuery
} from "../../../../Queries";
import {Thumbnails} from "../../../../components/Thumbnails";
import {TitleText} from "../../../../layout/Typography";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputNumber} from "../../wholesale/form/FormInputNumber";
import {FormInputText} from "../../wholesale/form/FormInputText";
import {FormInputDate} from "../../wholesale/form/FormInputDate";

type PotentialAddDialogProps = {
   onCancel: () => void
   shopId: string
   onCreateTradeIn: (details: CreateDetails) => void
   consumerId: string
}

export type CreateDetails = {
   productId: string
   variantSizeId: string
   consumerId: string
   price: number
   date: string
}

export const PotentialAddDialog = ({
                                      onCancel,
                                      shopId,
                                      consumerId,
                                      onCreateTradeIn,
                                   }: PotentialAddDialogProps) => {
   const {data} = usePotentialAddDialogConsumerQuery({
      variables: {consumerId}
   })
   const [productId, setProductId] = useState<string | undefined>(undefined)
   const [size, setSize] = useState<AddSizeFragment | undefined>(undefined)
   const [step, setStep] = useState<'step1' | 'step2' | 'step3'>('step1')
   const [createPotential] = usePotentialCreateMutation()

   const onSelectProduct = (row: ProductListProductFragment) => {
      setProductId(row._id);
      setStep('step2')
   }

   const onSelectVariant = (size?: AddSizeFragment) => {
      setSize(size);
      if (size) {
         setStep('step3')
      }
   }

   const onComplete = async (details: CompletionDetails) => {
      const externalId = "MANUAL_" + new Date().getTime() / 1000
      const consumer = data?.ConsumerById;
      if (consumer) {
         await createPotential({
            variables: {
               shopId: shopId,
               input: {
                  consumer: {
                     externalId: consumer.personId,
                     id: consumer.id!,
                     name: consumer.name,
                     add1: consumer.add1,
                     add2: consumer.add2,
                     zipCode: consumer.zipCode,
                     country: consumer.country,
                     countryCode: consumer.countryCode,
                     mail: consumer.mail,
                     acceptsMarketing: consumer.acceptsMarketing || false
                  },
                  currency: consumer.currency || consumer.shop?.currency,
                  dateTime: new Date(details.date),
                  externId: externalId,
                  items: [{
                     id: externalId,
                     name: size?.productVariant?.product?.name || 'Noname',
                     price: details.price,
                     cancelled: false,
                     color: size?.productVariant?.color,
                     size: size?.size,
                     description: '',
                     ean: size?.ean,
                     sku: size?.sku,
                     externalId: size?.externalId
                  }]
               },
               consumerId: consumer._id
            }
         })
         onCreateTradeIn({
            variantSizeId: size?._id,
            productId: productId!,
            price: details.price,
            date: details.date,
            consumerId: consumerId
         })
      }
   }

   return <Dialog open={true} onClose={onCancel} fullWidth maxWidth={"lg"}>
      <DialogTitle>
         <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Tabs value={step} sx={{marginBottom: '10px'}}>
               <Tab value={"step1"} label={"1. Find product"} onClick={() => setStep('step1')}/>
               <Tab value={"step2"} label={"2. Color & size "} onClick={() => setStep('step2')}/>
               <Tab value={"step3"} label={"3. Confirm"}/>
            </Tabs>
         </Box>
      </DialogTitle>
      <DialogContent sx={{height: '650px'}}>
         {step === 'step1' &&
            <ProductsList2 shopId={shopId} onProductSelect={onSelectProduct} inDialog/>
         }
         {step === 'step2' && productId &&
            <ProductColorList productId={productId} onSelectVariant={onSelectVariant}/>
         }
         {step === 'step3' && productId && size &&
            <Completion consumer={data?.ConsumerById as any} size={size} onComplete={onComplete}/>
         }
      </DialogContent>
      <DialogActions>
         <Button variant={"outlined"} onClick={onCancel}>Cancel</Button>
      </DialogActions>
   </Dialog>
}

type CompletionProps = {
   consumer: {
      _id: string,
      name: string
      shop: {
         currency: string
      }
   },
   size: AddSizeFragment,
   onComplete: (data: CompletionDetails) => Promise<void>
}

type CompletionDetails = {
   date: string
   price: number
   name: string
}

const Completion = ({consumer, size, onComplete}: CompletionProps) => {
   const form = useForm<CompletionDetails>({
      defaultValues: {
         date: new Date('2022-12-24').toISOString(),
         price: size?.productVariant?.rrp?.find(rrp => rrp?.currency === consumer.shop?.currency)?.value || 0,
         name: consumer.name
      }
   });
   const {handleSubmit} = form;

   return <FormProvider {...form}>
      <Box>
         <TitleText type={"h1"}>Confirm creation of trade-in item</TitleText>
         <FormInputText name={"name"} disabled={true} label={"Consumer"}/>
         <FormInputNumber name={"price"} label={"Buying price (" + consumer.shop.currency + ")"}/>
         <FormInputDate name={"date"} label={"Date of purchase"}/>
         <Button variant={"contained"} onClick={handleSubmit(onComplete)}>Create</Button>
      </Box>
   </FormProvider>
}

type ProductColorListProps = {
   productId: string,
   onSelectVariant: (variant?: AddSizeFragment) => void
}

const ProductColorList = ({productId, onSelectVariant}: ProductColorListProps) => {
   const {data} = useProductItemDialogVariantsQuery({
      variables: {
         productId
      }
   })

   if (!data) {
      return <LinearProgress/>
   }

   return <div>{
      data?.ProductVariantMany?.map(variant => {
         return <Card sx={{
            flex: '1',
            flexDirection: 'row',
            flexGrow: '1 1 auto',
            display: 'flex',
            padding: '5px',
            margin: '5px'
         }}>
            <CardContent sx={{textAlign: 'center'}}>
               <Thumbnails size={100} imageUrls={variant?.imageUrls || []} maxImages={1}/>
            </CardContent>
            <CardContent sx={{height: '140px', overflow: 'hidden'}}>
               <Box sx={{marginBottom: '10px'}}>
                  <TitleText type={"h3"}>{variant.color}</TitleText>
               </Box>
               <Box sx={{display: 'flex', gap: '5px', flexDirection: 'row', flexWrap: 'wrap'}}>
                  {variant?.sizes?.map(size => {
                     return <Box onClick={() => onSelectVariant(size as AddSizeFragment)} sx={{
                        border: '1px solid #808080',
                        borderRadius: '4px',
                        padding: '4px',
                        backgroundColor: '#ffffff',
                        cursor: 'pointer'
                     }}>{size?.size}</Box>
                  })}
               </Box>
            </CardContent>
         </Card>
      })
   }</div>
}

export const groupBy2 = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
   list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
   }, {} as Record<K, T[]>);
