import * as React from "react";
import {Thumbnails} from "../../../components/Thumbnails";
import {Alert, Box, Chip} from "@mui/material";
import {Barcoder} from "../../../utility/BarcodeValidate";
import {FlexStockItemFragment} from "../../../Queries";
import {styled} from "@mui/styles";
import {FlexListColumn} from "../../../components/flexlist/FlexTypes";
import dayjs from "dayjs";
import {DateFormat} from "../../../utility/DateFormat";

export enum ShowingType {
   SALE = 'forSale',
   REVIEW = 'needReview',
   DRAFT = 'draft',
   SOLD = 'sold',
   ALL = 'all'
}

type ItemColumn = Omit<FlexListColumn<FlexStockItemFragment>, 'width'>

const ColID: ItemColumn = {
   id: 'id',
   label: 'Id',
   field: 'itemNr'
};

const ColBrand: ItemColumn = {
   id: 'brand',
   label: 'Shop',
   field: 'shopId',
   render: (name, row) => {
      return <div>{row?.shop?.externalName}</div>
   },
   ellipsis: true
};

const ColLocationTS: ItemColumn = {
   id: 'stockLocationTs',
   label: 'Layaway',
   field: 'stockLocationTs',
   render: (time) => {
      if (time) {
         return <>{dayjs(time, {}).fromNow()}</>
      } else {
         return "-"
      }
   }
};

const ColImage: ItemColumn = {
   id: 'image',
   label: 'Image',
   get: (stockItem) => {
      return stockItem.imageUrls || []
   },
   render: (images: string[]) => {
      return <Thumbnails maxImages={3} size={50} imageUrls={images}/>
   }
};

const ColSellingPrice: ItemColumn = {
   id: 'sellPrice',
   field: 'sellingPrice',
   label: 'Price',
   render: (price, stockItem, context) => {
      if (price) {
         return price + ' ' + context.currency
      } else {
         return null
      }
   }
}

const ColBeforePrice: ItemColumn = {
   id: 'beforePrice',
   field: 'beforePrice',
   label: 'RRP',
   render: (price, stockItem, context) => {
      if (price) {
         return price + ' ' + context.currency
      } else {
         return null
      }
   }
}

const ColStatus: ItemColumn = {
   id: 'status',
   label: 'Status',
   get: (stockItem) => {
      if (stockItem.itemNrBroken) {
         return "itemnrbroken"
      } else if (stockItem.effectiveMissing) {
         return "missing"
      } else if (stockItem.isSold) {
         return "sold"
      } else if (stockItem.hasProblems) {
         return "problems"
      } else if (stockItem.review) {
         return "review"
      } else if (stockItem.draft) {
         return "draft"
      } else if (stockItem.published) {
         return "published";
      } else {
         return "";
      }
   },
   align: 'center',
   render: (status, stockItem) => {
      if(status === 'itemnrbroken') {
         if(stockItem?.isSold) {
            return <AlertLabel severity={"warning"}>BROKEN: SOLD</AlertLabel>
         } else if (stockItem?.effectiveMissing) {
            return <AlertLabel severity={"error"}>BROKEN: MISSING</AlertLabel>
         } else if (stockItem?.hasProblems) {
            return <AlertLabel severity={"error"}>BROKEN: PROBLEMS</AlertLabel>
         } else if (stockItem?.review) {
            return <AlertLabel severity={"error"}>BROKEN: REVIEW</AlertLabel>
         } else if (stockItem?.draft) {
            return <AlertLabel severity={"error"}>BROKEN: DRAFT</AlertLabel>
         } else if (stockItem?.published) {
            return <AlertLabel severity={"error"}>BROKEN: FOR SALE</AlertLabel>
         } else {
            return <AlertLabel severity={"error"}>BROKEN</AlertLabel>
         }
      } else if (status === "sold") {
         return <AlertLabel severity={"success"}>Sold</AlertLabel>
      } else if (status === "missing") {
         return <AlertLabel severity={"error"}>Missing</AlertLabel>
      } else if (status === "problems") {
         return <AlertLabel severity={"warning"}>Blocked</AlertLabel>
      } else if (status === "draft") {
         return <AlertLabel severity={"warning"}>Draft</AlertLabel>
      } else if (status === "review") {
         return <AlertLabel severity={"info"}>Review</AlertLabel>
      } else if (status === "published") {
         if (!!stockItem?.stockLocation) {
            if (stockItem?.isInSeason) {
               return <AlertLabel severity={"info"}>For sale</AlertLabel>
            } else {
               return <AlertLabel severity={"warning"}>Out of season</AlertLabel>
            }
         } else {
            return <AlertLabel severity={"warning"}>No location</AlertLabel>
         }
      } else {
         return <></>
      }
   },
}

const ColStockLocation: ItemColumn = {
   id: 'stockLocation',
   field: 'stockLocation',
   label: 'Location'
}

const ColCreatedAt: ItemColumn = {
   id: 'createdAt',
   field: 'createdAt',
   label: 'Created',
   render: (date) => {
      return <Box>
         {DateFormat.toDate(date!)}
      </Box>
   }
}

const ColOriginalEan: ItemColumn = {
   id: 'originalEan',
   label: 'EAN',
   field: 'originalEan',
   render: (ean: string) => {
      if (ean) {
         let valid = Barcoder.validate(ean);
         return <div style={{color: !valid ? 'RED' : 'inherit'}} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
         }}>{ean}</div>
      } else {
         return null
      }
   }
}

const ColReviewField = (field: string, title: string): ItemColumn => ({
   id: field,
   label: title,
   field: field,
   render: (value, row) => {
      if (value) {
         return value
      } else {
         return <Chip label={"Missing"}/>
      }
   }
})

const ColTitle: ItemColumn = {
   id: 'title',
   label: 'Product',
   field: 'title',
   render: (value, row) => {
      return <Box>
         {value}
      </Box>
   }
}


const ColDecoratorField = (decorator: ItemColumn): ItemColumn => ({
   id: decorator.id,
   label: decorator.label,
   field: decorator.field,
   render: (value, row, context) => {
      if (decorator.render) {
         const myVal = decorator.render(value, row, context)
         if (myVal) return myVal
      }
      if (value) {
         return value
      } else {
         return <Chip label={"Missing"}/>
      }
   }
})

export type StockItemColumnsProps = {
   id?: boolean
   brand?: boolean
   locationTs?: boolean
}

export const getStockItemColumns = ({
                                       id,
                                       brand,
                                       locationTs,
                                    }: StockItemColumnsProps): FlexListColumn<FlexStockItemFragment>[] => {
   let columns: FlexListColumn<FlexStockItemFragment>[] = [];

   if (id) {
      columns.push({...ColID, width: 5});
   }
   if (brand) {
      columns.push({...ColBrand, width: 10})
   }
   columns.push(...[
      {...ColImage, width: 10},
      {...ColTitle, width: 25},
      {...ColReviewField('sku', 'SKU'), width: 15},
      {...ColDecoratorField(ColSellingPrice), width: 10},
      {...ColDecoratorField(ColBeforePrice), width: 10},
      {...ColDecoratorField(ColOriginalEan), width: 15},
      {...ColReviewField('size', 'Size'), width: 10},
      {...ColReviewField('color', 'Color'), width: 10},
      {...ColStockLocation, width: 10},
      {...ColCreatedAt, width: 10}
   ]);

   if (locationTs) {
      columns.push({...ColLocationTS, width: 15})
   }
   columns.push(...[
      {...ColStatus, width: 10}
   ]);
   return columns
}

const AlertLabel = styled(Alert)(({theme}) => ({
   padding: '0'
}));
