import * as React from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {merge} from "lodash";
import {FormControlProps} from "@mui/material/FormControl/FormControl";

let nextId = 0;

export type SelectOption = {
   id: any
   name: string
}

export interface SimpleSelectProps extends FormControlProps {
   label: string;
   value: string;
   options: SelectOption[]
   onSelectChange?: (e: SelectChangeEvent<any>) => void
   hideborder?: boolean
}


export const SimpleSelect: React.FC<SimpleSelectProps> = ({
                                                             label,
                                                             value,
                                                             options,
                                                             required,
                                                             onSelectChange,
                                                             hideborder,
                                                             ...rest
                                                          }) => {
   let labelid = "simple-select-" + (++nextId);

   const {style} = rest;

   const newStyle = merge(style, {
      marginBottom: '8px',
   });

   const selectStyle = {
      backgroundColor: 'transparent',
      border: !hideborder ? '1px solid #d0d0d0' : '0'
   };

   return (
      <FormControl fullWidth variant={"filled"} margin={"none"} size={"small"} style={newStyle} {...rest}>
         <InputLabel id={labelid}>
            <div style={{display: 'flex', alignItems: 'middle', gap: '10px'}}>
               {label}
            </div>
         </InputLabel>
         <Select
            disableUnderline
            style={selectStyle}
            labelId={labelid}
            onChange={(e) => {
               if (onSelectChange) {
                  onSelectChange(e)
               }
            }}
            value={value || ''}
            label={label}
            renderValue={(selected) => {
               return <div>{options?.find(option => option.id === selected)?.name}</div>
            }}
         >
            {(required === undefined || !required) && <MenuItem value={''}>- None -</MenuItem>}
            {options?.map((option) => {
               return <MenuItem value={option.id} dense={true} key={'select_' + option.id}>
                  {option.name}
               </MenuItem>
            })}
         </Select>
      </FormControl>
   );
};
