import {useParams} from "react-router-dom";
import Page from "../../../../layout/Page";
import PageContent from "../../../../layout/PageContent";
import * as React from "react";
import {FC} from "react";
import {Box, Grid, LinearProgress} from "@mui/material";
import {
   LocalizationInput,
   useTradeInAppLocalizationsQuery,
   useTradeInAppLocalizationsUpdateMutation
} from "../../../../Queries";
import {useApiHook} from "../../../../utility/useApiHook";
import {Control, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FormInputText} from "../../wholesale/form/FormInputText";
import {BodyText, TitleText} from "../../../../layout/Typography";
import {SaveButton} from "../../../../components/SaveButton";
import PageHeader from "../../../../layout/PageHeader";
import {Thumbnails} from "../../../../components/Thumbnails";

export type Section = {
   header: {
      title: string
      description: string
   }
   data: {
      key: string
      defaults: any
   }[]
}

export type FormData = {
   header: {
      title: string
      description: string
      images?: string[]
   }
   data: {
      key: string
      values: any
      defaults: any
   }[]
}

export type TradeInAppTextsEditorProps = {
   shopId: string
   version: 'tradein' | 'tradein2'
   headers: Section[]
}

export const TradeInAppTextsV1 = () => {
   const {shopId} = useParams<{ shopId: string }>();
   const headers: Section[] = useApiHook('https://ourimpacthub-graphql.azurewebsites.net/locheaders');
   if(!headers) {
      return <LinearProgress/>
   }

   return <TradeInAppTextsEditor shopId={shopId}
                                 headers={headers}
                                 version={"tradein"}/>
}

export const TradeInAppTextsV2 = () => {
   const {shopId} = useParams<{ shopId: string }>();
   const headers: Section[] = useApiHook('https://ourimpacthub-graphql.azurewebsites.net/locheaders2');

   if(!headers) {
      return <LinearProgress/>
   }

   return <TradeInAppTextsEditor shopId={shopId}
                                 headers={headers}
                                 version={"tradein2"}/>
}


export const TradeInAppTextsEditor: FC<TradeInAppTextsEditorProps> = ({shopId, headers, version}) => {
   const {data} = useTradeInAppLocalizationsQuery({
      variables: {
         shopId: shopId,
         type: version
      },
      fetchPolicy: 'no-cache'
   });

   const [saveLocalizations] = useTradeInAppLocalizationsUpdateMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: LocalizationInput[]) => {
      await saveLocalizations({
         variables: {
            shopId: shopId,
            input: data,
            type: version
         }
      });
   }

   const locales: string[] = data.Shop?.locales as string[];

   const localizationData: FormData[] = headers
      .sort((a,b) => {
         if(a.header.title < b.header.title) {
            return -1;
         } else if(a.header.title > b.header.title) {
            return 1;
         } else {
            return 0;
         }
      })
      .map(header => {
      return {
         header: header.header,
         data: header.data.map(sect => {
            const keyData = data.BrandLocalizations?.filter(loc => {
               return loc?.key === sect.key
            });
            let values: any = {};
            keyData?.forEach(key => {
               if (key?.language) {
                  values[key.language] = key.value;
               }
            });
            const newValues = {...sect.defaults, ...values}
            return {
               key: sect.key,
               values: newValues,
               defaults: sect.defaults
            }
         })
      }
   });

   return <Page>
      <TradeInAppEditor localizations={localizationData} languages={locales} onSave={onSave}/>
   </Page>

}

type TradeInAppEditorProps = {
   languages: string[]
   localizations: FormData[]
   onSave: (data: LocalizationInput[]) => Promise<void>
}

type TradeInAppFormData = {
   sections: FormData[]
}

const TradeInAppEditor: FC<TradeInAppEditorProps> = ({languages, localizations, onSave}) => {
   const methods = useForm<TradeInAppFormData>({
      defaultValues: {
         sections: localizations
      }
   });
   const {handleSubmit, control} = methods;

   const onSubmit = async (data: TradeInAppFormData) => {
      const updates: LocalizationInput[] = [];
      for (const section of data.sections) {
         for (const datum of section.data) {
            for (const language of languages) {
               if (datum.values[language] !== datum.defaults[language]) {
                  updates.push({
                     key: datum.key,
                     language: language,
                     value: datum.values[language]
                  })
               }
            }
         }
      }
      await onSave(updates);
   }

   const {fields} = useFieldArray({
      control,
      name: "sections"
   });

   return <FormProvider {...methods}>
      <PageHeader title={"Translations "}>
         <SaveButton variant={"contained"} onSave={handleSubmit(onSubmit)} saveMessage={"Localizations saved"}>
            Save
         </SaveButton>
      </PageHeader>
      {fields.map((section, index) => {
         return <PageContent>
            <Box sx={{flexDirection: 'row', display: 'flex'}} justifyContent={"space-between"}>
               <div>
                  <TitleText type={"h2"}>{section.header.title}</TitleText>
                  <BodyText type={"subtitle1"}>{section.header.description}</BodyText>
               </div>
               {section.header.images && <Thumbnails imageUrls={section.header.images} size={60}/>}
            </Box>
            <div style={{marginTop: '10px'}}>
               <SectionEditor languages={languages} control={control} index={index}/>
            </div>
         </PageContent>
      })}
   </FormProvider>
}

type SectionEditorProps = {
   languages: string[]
   control: Control<TradeInAppFormData>
   index: number
}

const SectionEditor: FC<SectionEditorProps> = ({languages, control, index}) => {
   const {fields} = useFieldArray<TradeInAppFormData>({
      control,
      name: `sections.${index}.data`
   });

   return <>{fields.map((field, nested) => {
      let item = field as any
      return <Grid justifyContent={'flex-start'} wrap={'nowrap'} style={{gap: '10px'}} container>
         {languages.map(lang => {
            return <FormInputText name={`sections.${index}.data.${nested}.values.${lang}`} control={control}
                                  label={item.key + '(' + lang.toUpperCase() + ')'}/>
         })}
      </Grid>
   })}</>
}