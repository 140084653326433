import * as React from "react";
import {FC, useCallback, useEffect} from "react";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import {Box} from "@mui/material";
import PageContent from "../../layout/PageContent";
import {BodyText} from "../../layout/Typography";
import {useRecoilValue} from "recoil";
import {CurrentUserState} from "../../atoms/CurrentUser";
import {useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../Parameters";
import {isInsideIframe} from "../../MainWindow";

export const DashboardPage: FC<any> = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>();

   const currentUser = useRecoilValue(CurrentUserState);
   const openDashboard = useCallback(() => {
      const isInsideIFrame = isInsideIframe();
      if(isInsideIFrame) {
         window.open('https://pitwall.create2stay.com/dashboard?tab=overview&token=' + currentUser?.token + '&brandId=' + brandHouseId, '_blank');
      } else {
         window.location.href = 'https://pitwall.create2stay.com/dashboard?tab=overview&token=' + currentUser?.token + '&brandId=' + brandHouseId;
      }
   }, [brandHouseId, currentUser?.token])

   useEffect(() => {
      openDashboard();
   }, [openDashboard]);

   return <Page>
      <PageHeader title={"Dashboard"}>

      </PageHeader>
      <PageContent>
         <Box>
            <BodyText type={"body1"}>
               Click <a href={"#/"} onClick={openDashboard} target={"_blank"} rel={"noreferrer"}>here</a> if it doesn't
               open ...
            </BodyText>
         </Box>
      </PageContent>
   </Page>
}
