import * as React from "react";
import PageContent from "../../../layout/PageContent";
import {
   BrandSettingCountriesInput,
   TradeInCountriesFragment,
   useTradeInCountriesQuery,
   useTradeInCountriesUpdateMutation
} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Divider, Grid, LinearProgress} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {SavingUI} from "../../../SavingProvider";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputPercent} from "../../shop/wholesale/form/FormInputPercent";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {FormInputCurrency} from "../../shop/wholesale/form/FormInputCurrency";

const ID_TRADEINFORM = "SettingsCountries";

const CountriesPagePath = (brandId: string, targetId: string) => {
   return "/brands/" + brandId + "/settings/countries/" + targetId
}

export const CountriesPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(CountriesPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Countries"} subtitle={""}>
         <Button type="submit" form={ID_TRADEINFORM} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <CountriesPageInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <CountriesPageInner shopId={shop}/>}
      />
   </div>
}

type CountriesPageInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const CountriesPageInner = ({brandHouseId, shopId}: CountriesPageInnerProps) => {
   const {data} = useTradeInCountriesQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   const [saveCountries] = useTradeInCountriesUpdateMutation()

   if (!data) {
      return <LinearProgress/>
   }

   const onSave = async (data: TradeInCountriesFragment) => {
      await SavingUI.process(async () => {
         await saveCountries({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               countries: data.countries as BrandSettingCountriesInput
            }
         });
      }, 'TradeIn settings updated')
   }
   return <TradeInOperationEditor data={data!.BrandSettings as TradeInCountriesFragment}
                                  onSave={onSave}
   />
}


type TradeInOperationEditorProps = {
   data?: TradeInCountriesFragment
   onSave: (data: TradeInCountriesFragment) => Promise<void>
}


const TradeInOperationEditor = ({data, onSave}: TradeInOperationEditorProps) => {
   const methods = useForm<TradeInCountriesFragment>({
      defaultValues: data
   });
   const {handleSubmit} = methods;

   // @ts-ignore
   return <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)} id={ID_TRADEINFORM}/>
      <PageContent>
         <TitleText type={"h2"}>Countries of operation</TitleText>
         <BodyText type={"subtitle1"}>Consumers in these countries are eligible for trade-back offers.</BodyText>
         <br/>
         <Grid container spacing={2}>
            <Grid item xs={3}>
               <TitleText type={"h3"} sx={{fontWeight:'bold'}}>
                  Country
               </TitleText>
            </Grid>
            <Grid item xs={3}>
               <TitleText type={"h3"} sx={{fontWeight:'bold'}}>
                  Active / inactive
               </TitleText>
            </Grid>
            <Grid item xs={3}>
               <TitleText type={"h3"} sx={{fontWeight:'bold'}}>
                  Currency
               </TitleText>
            </Grid>
            <Grid item xs={3}>
               <TitleText type={"h3"} sx={{fontWeight:'bold'}}>
                  VAT
               </TitleText>
            </Grid>
         </Grid>
         <Divider style={{marginBottom: '10px'}}/>
         {data?.countries && Object.keys(data.countries).sort().map(country => {
            if (country === '__typename') return null;

            // @ts-ignore
            const regionNames = new Intl.DisplayNames(
               ['en'], {type: 'region'}
            );

            return <Grid container spacing={2} key={'countries.country_' + country} sx={{borderBottom: '1px solid #e0e0e0', marginBottom: '10px'}}>
               <Grid item xs={3} alignItems={"center"} container>
                  <BodyText type={"body1"}>
                     {regionNames.of(country)}
                  </BodyText>
               </Grid>
               <Grid item xs={3} alignItems={"center"} container>
                  <FormInputCheckbox name={'countries.' + country + ".enabled"} label={"Active"}/>
               </Grid>
               <Grid item xs={3} alignItems={"center"} container>
                  <FormInputCurrency name={'countries.' + country + ".currency"} label={"Currency"}/>
               </Grid>
               <Grid item xs={3} alignItems={"center"} container>
                  <FormInputPercent name={'countries.' + country + ".vat"} label={"VAT (Default is 25%)"}/>
               </Grid>
            </Grid>
         })}
      </PageContent>
   </FormProvider>
}
