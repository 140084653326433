import * as React from "react";
import {TextField, TextFieldProps} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {merge} from "lodash";

export type FormInputReadonlyProps = Omit<TextFieldProps, 'name' | 'disabled'> & {
   label: string;
   value: string
}

export const FormInputReadonly = ({label, value, error, ...rest}: FormInputReadonlyProps) => {
   const {style, InputProps} = rest;
   const classes = useStyles();

   const newStyle = merge(style, {
      border: '1px solid #d0d0d0',
      marginBottom: '8px'
   });

   const newProps = merge(InputProps, {
      disableUnderline: true,
      style: {backgroundColor: 'transparent'}
   });

   return (
      <div className={classes.noBorderRadius}>
         <TextField
            id={'readonly_' + Date.now()}
            size="small"
            fullWidth
            label={label}
            variant="filled"
            value={value}
            margin={"none"}
            disabled
            InputLabelProps={{
               shrink: true,
            }}
            InputProps={newProps}
            style={newStyle}
            {...rest}
         />
      </div>
   );
};

const useStyles = makeStyles({
   noBorderRadius: {
      "& fieldset": {
         borderRadius: 0,
      },
      width: '100%'
   }
});
