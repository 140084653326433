import * as React from "react";
import {useEffect} from "react";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import {NavigationDataAtom, NavigationItem, NavigationLeaf} from "./NavigationController";
import {useLocation} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {useNavigate} from "react-router";

type NavigationState = {
   leaf?: NavigationLeaf
   selected: NavigationItem,
   selectedPath: NavigationItem[]
}

export const NavigationStateAtom = atom<NavigationState | null>({
   key: 'navigationState',
   default: null
})

export const NavigationStateController = (props: React.PropsWithChildren<any>) => {
   const navigationData = useRecoilValue(NavigationDataAtom);
   const [navigationState, setNavigationState] = useRecoilState(NavigationStateAtom);
   const location = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      if (navigationData) {
         let navigationState = getNavigationState(location.pathname, navigationData.items);
         if (!navigationState) {
            navigationState = getNavigationState(navigationData.items?.[0]?.path, navigationData.items);
         }
         setNavigationState(navigationState);
      }
   }, [navigationData, location, setNavigationState])

   useEffect(() => {
      if(navigationData && navigationData.items.length===0) {
         navigate("/account/accept")
      }
   }, [navigate, navigationData])

   if (!navigationData || !navigationState) {
      return <LinearProgress/>
   }

   return <>{props.children}</>
}


const getNavigationState = (path: string, items: NavigationItem[]): NavigationState | null => {
   let stack = [] as NavigationItem[];
   const recursive = (target: NavigationItem | NavigationItem[]): NavigationItem | null => {
      if (Array.isArray(target)) {
         for (const child of target) {
            stack.push(child);
            let match = recursive(child);
            if (match) {
               return match;
            }
            stack.pop();
         }
         return null;
      } else {
         if (path === target.path) {
            return target;
         } else if (target.pathMatch) {
            if (path.match(target.path + '.*')) {
               return target;
            }
         }
         return recursive([...(target.children || []), ...(target.topbar || [])]);
      }
   }
   let selected = recursive(items);
   if (selected) {
      let parent = stack?.[Math.max(0, stack.length - 2)]
      if (parent?.topbar?.includes(selected)) {
         return {
            leaf: selected as NavigationLeaf,
            selected: parent,
            selectedPath: stack.slice(0, -1)
         }
      } else {
         return {
            selected: selected,
            selectedPath: stack
         }
      }
   } else {
      return null;
   }
}


export const getNavigationItem = (id: string, state: NavigationState | null, items: NavigationItem[] | null): NavigationItem | null => {
   let iterations: number = 0;

   const recursive = (target: NavigationItem | NavigationItem[]): NavigationItem | null => {
      iterations++;
      if (Array.isArray(target)) {
         for (const child of target) {
            let match = recursive(child);
            if (match) {
               return match;
            }
         }
         return null;
      } else {
         if (id === target.id) {
            return target;
         }
         return recursive([...(target.children || []), ...(target.topbar || [])]);
      }
   }
   try {
      for (const path of state?.selectedPath || []) {
         if (path.id === id) {
            return path;
         } else if (path.children) {
            for (const child of path.children) {
               if (child.id === id) {
                  return child;
               }
            }
         }
      }
      return recursive(items || []);
   } finally {
      console.debug('Iterations: ', iterations)
   }
}
