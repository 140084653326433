import {FormInputSelect} from "./FormInputSelect";
import * as React from "react";
import {Languages} from "../../../settings/languages/Languages";

type FormInputLanguageProps = {
   name: string
   label: string
   disabled?: boolean
}


export const FormInputLanguage = ({name, label, disabled}: FormInputLanguageProps) => {
   return <FormInputSelect disabled={disabled} name={name} label={label} options={Languages.all().map(language => {
      return {
         id: language.key,
         name: language.name
      }
   })}/>
}