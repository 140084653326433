import React from "react";
import PageContent from "../../layout/PageContent";
import {IntegrationInstance, useInstallsPageGetLazyQuery} from "../../Queries";
import {FlexList} from "../../components/flexlist/FlexList";
import {FlexListColumn, FlexListRequestData} from "../../components/flexlist/FlexTypes";
import {useNavigate} from "react-router";
import {ShopIntegrationSettingsPath} from "../settings/details/ShopIntegrationSettings";
import {BrandIntegrationSettingsPath} from "../settings/details/BrandIntegrationSettings";
import {LogisticsIntegrationPagePath} from "../logistics/LogisticsIntegrationPage";

type InstallsPageProps = {
   connectorId: string
}

export const InstallsPage = ({connectorId}: InstallsPageProps) => {
   const navigate = useNavigate();

   const [requestData] = useInstallsPageGetLazyQuery({
      variables: {
         connectorId
      }
   })

   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter?: any) => {
      const {data} = await requestData({
         variables: {
            connectorId: connectorId
         }
      });

      const allData = data?.IntegrationInstances || [];
      return {
         data: allData,
         totalCount: allData.length
      } as FlexListRequestData<IntegrationInstance>
   }

   const columns: FlexListColumn<IntegrationInstance>[] = [{
      id: "name",
      field: 'refId',
      label: "Name",
      render: (data, row) => {
         return row?.refShop?.name || row?.refBrandHouse?.name || row?.refLogisticsPartner?.name || 'Unknown'
      }
   }, {
      id: "reftype",
      field: "refType",
      label: "Target",
      render: (data, row) => {
         return row?.refType
      }
   }];

   const onSelect = (row: IntegrationInstance) => {
      if (row.refShop?.brandHouseId) {
         navigate(ShopIntegrationSettingsPath(row.refShop?.brandHouseId, row.refShop?._id, row._id))
      } else if (row.refLogisticsPartner?._id) {
         navigate(LogisticsIntegrationPagePath(row.refLogisticsPartner?._id, row._id))
      } else {
         navigate(BrandIntegrationSettingsPath(row.refBrandHouse?._id, row._id))
      }
   }

   return <PageContent>
      <FlexList globalId={"connectorInstalls"} onSelectRow={onSelect} columns={columns} itemsPerPage={40}
                onRequestData={onRequestData}/>
   </PageContent>
}