import * as React from "react";
import {FC} from "react";
import {
   SettingsStockItemFragment,
   useLocaleItemBrandHouseSettingsQuery,
   useLocaleItemBrandHouseSettingsUpdateMutation,
   useLocaleItemSettingsQuery,
   useLocaleItemSettingsUpdateMutation
} from "../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {BodyText} from "../../../layout/Typography";
import {Button, ButtonGroup, Grid, LinearProgress} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {SavingUI} from "../../../SavingProvider";
import {Dirty, DirtyDetector, ReactHookDirtyDetector} from "../../../components/DirtyDetector";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import PageContent from "../../../layout/PageContent";
import {DeleteForever} from "@mui/icons-material";
import {Currencies} from "../../shop/wholesale/form/FormInputCurrency";
import {CurrencyMenu} from "../CurrencyMenu";

const FORMID_ITEMLOCALES = "itemlocalesform";

const LocaleItemSettingsPath = (brandId: string, shopId: string) => {
   return "/brands/" + brandId + "/settings/itemcurrencies/" + shopId
}

export const LocaleItemSettings = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(LocaleItemSettingsPath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Stock item currencies"}>
         <Button variant={"contained"} type={"submit"} form={FORMID_ITEMLOCALES}>
            Save
         </Button>
      </SettingsHeader>
      <ShopTabs
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <LocaleItemSettingsBrandHouse brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <LocaleItemSettingsInner shopId={shop}/>}
      />
   </div>
}

type LocaleItemSettingsInnerProps = {
   shopId: string
}

const LocaleItemSettingsInner = ({shopId}: LocaleItemSettingsInnerProps) => {
   const {data: settings} = useLocaleItemSettingsQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useLocaleItemSettingsUpdateMutation();

   const onSave = async (data: SettingsStockItemFragment) => {

      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               shopId: shopId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Languages updated')
   }

   if (!settings) {
      return <LinearProgress/>
   }

   return <>
      <StockSettingsEditor
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}


type LocaleItemSettingsInnerBrandHouseProps = {
   brandHouseId: string
}

const LocaleItemSettingsBrandHouse = ({brandHouseId}: LocaleItemSettingsInnerBrandHouseProps) => {
   const {data: settings} = useLocaleItemBrandHouseSettingsQuery({
      variables: {
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveSettings] = useLocaleItemBrandHouseSettingsUpdateMutation();

   const onSave = async (data: SettingsStockItemFragment) => {
      await SavingUI.process(async () => {
         await saveSettings({
            variables: {
               brandHouseId: brandHouseId,
               stockConfig: data
            }
         });
         Dirty.reset()
      }, 'Languages updated')
   }

   if (!settings) {
      return <LinearProgress/>
   }

   return <>
      <StockSettingsEditor
         data={settings.BrandSettings!.stockItemConfig!}
         onSave={onSave}
      />
      <DirtyDetector/>
   </>
}


type StockSettingsEditorProps = {
   data: SettingsStockItemFragment
   onSave: (data: SettingsStockItemFragment) => Promise<void>
}

const StockSettingsEditor: FC<StockSettingsEditorProps> = ({
                                                              data,
                                                              onSave
                                                           }) => {
   const methods = useForm<SettingsStockItemFragment>({
      defaultValues: data,
      reValidateMode: "onChange"
   });

   const {handleSubmit, control} = methods;

   const {fields: currencyFields, append: currencyAppend, remove: currencyRemove} = useFieldArray({
      control,
      name: 'currencies'
   })

   return <div>
      <form onSubmit={handleSubmit(onSave)} id={FORMID_ITEMLOCALES}/>
      <FormProvider {...methods}>
         <ReactHookDirtyDetector/>

         <PageContent>
            <BodyText type={"subtitle1"}>
               Currencies that stockitems can be sold with.
            </BodyText>
            <br/>

            {currencyFields?.map((currencyField, i) => {
               const currencyDefinition = Currencies.find(cur => cur.id === currencyField.currency)
               return <Grid key={'active_' + currencyField.currency} container gap={1} wrap={"nowrap"}>
                  <Grid item xs={2}>
                     {currencyDefinition?.name}
                  </Grid>
                  <Grid item xs={2} justifyContent={"flex-start"}>
                     <FormInputCheckbox style={{padding: 0}}
                                        name={`currencies[${i}].active`}
                                        label={"Active"}/>
                  </Grid>
                  <Grid item xs={8} justifyContent={"flex-start"}>
                     <ButtonGroup orientation={"horizontal"} size={"small"}>
                        <Button
                           onClick={() => currencyRemove(i)}
                           size="small"
                           sx={{ml: 2}}
                           aria-controls={'account-menu'}
                           aria-haspopup="true"
                           aria-expanded={'true'}
                           color={"secondary"}
                        >
                           <DeleteForever/>
                        </Button>
                     </ButtonGroup>
                  </Grid>
               </Grid>
            })}
            <div style={{marginTop: '20px'}}>
               <CurrencyMenu onSelect={(currency) => currencyAppend({
                  currency: currency,
                  active: true
               })} hideCurrencies={currencyFields.map(lf => lf.currency)}/>
            </div>
         </PageContent>
      </FormProvider>
   </div>
}


