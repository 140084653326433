import * as React from "react";
import {DetailedHTMLProps, FC, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputText} from "../../pages/shop/wholesale/form/FormInputText";
import {FormInputSelectMultiple} from "../../pages/shop/wholesale/form/FormInputSelectMultiple";
import {FormInputSelect} from "../../pages/shop/wholesale/form/FormInputSelect";
import {FilterAlt, Sort} from "@mui/icons-material";
import {Button, Drawer, Grid, Stack, useMediaQuery} from "@mui/material";
import {useRecoilState} from "recoil";
import {flexSettingsAtom} from "./FlexList";
import {FlexFilter, FlexFilterValue, FlexSort} from "./FlexTypes";
import makeStyles from "@mui/styles/makeStyles";
import {TitleText} from "../../layout/Typography";
import {PubSub} from "../PubSub";

type FlexHeaderProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
   globalId: string
   count: number | undefined
   filters?: FlexFilter<any>[]
   sorts?: FlexSort<any>[]
   searchLabel?: string
}

export type FlexHeaderData = {
   search: string
   filterOr: {
      [id: string]: string[]
   }
   filterAnd: {
      [id: string]: string[]
   }
   sort?: string
   tab?: string
}

export const FlexHeader: FC<FlexHeaderProps> = ({
                                                   globalId,
                                                   count,
                                                   filters,
                                                   sorts,
                                                   searchLabel,
                                                   ...props
                                                }) => {
   const [listState, setListState] = useRecoilState(flexSettingsAtom(globalId));
   const [initialized, setInitialized] = useState<boolean>(false);
   const [filterPanel, setFilterPanel] = useState<boolean>(false);
   const classes = useStyles();
   const isDesktopLG = useMediaQuery('(min-width:1250px)');
   const isDesktopMD = useMediaQuery('(min-width:800px)');

   const methods = useForm<FlexHeaderData>({
      defaultValues: {
         search: '',
         sort: '',
         filterAnd: {},
         filterOr: {}
      }
   });

   const {watch, getValues, reset} = methods;

   const search = watch('search');
   const debouncedSearch = useDebounce(search, 1000);

   useEffect(() => {
      const effectiveFilterOr: any = {}
      const effectiveFilterAnd: any = {}
      for (const filter of listState?.filters || []) {
         if (filter.requireAll) {
            effectiveFilterAnd[filter.filterId] = filter.values
         } else {
            effectiveFilterOr[filter.filterId] = filter.values
         }
      }
      reset({
         search: listState.search,
         filterAnd: effectiveFilterAnd,
         filterOr: effectiveFilterOr,
         sort: sorts?.find(sort => sort.value === listState.sort)?.id,
      })
      setInitialized(true);
      // eslint-disable-next-line
   }, [listState])

   useEffect(() => {
      if (!initialized) {
         return;
      }
      if (listState.search === debouncedSearch) {
         return;
      }
      let newListState = {
         ...listState,
         search: debouncedSearch
      };
      setListState(newListState)
      PubSub.emit('FlexList-SearchChanged-' + globalId, newListState);
      // eslint-disable-next-line
   }, [debouncedSearch]);

   const updateHeaders = () => {
      const sortvalue = getValues('sort');
      const sort = sorts?.find(sort => sort.id === sortvalue)?.value;

      const filterAnd = getValues('filterAnd');
      const filterOr = getValues('filterOr');
      const resultFilters: FlexFilterValue[] = [];

      for (const key of Object.keys(filterAnd)) {
         let selected = filterAnd[key];
         if (selected?.length > 0) {
            resultFilters.push({
               filterId: key,
               values: selected,
               requireAll: true
            })
         }
      }
      for (const key of Object.keys(filterOr)) {
         let selected = filterOr[key];
         if (selected?.length > 0) {
            resultFilters.push({
               filterId: key,
               values: selected,
               requireAll: false
            })
         }
      }

      setListState({
         ...listState,
         sort: sort,
         filters: resultFilters
      })
   }

   let maxFiltersOnLine = isDesktopLG ? 2 : isDesktopMD ? 1 : 0;
   let sortSize = isDesktopLG ? 20 : isDesktopMD ? 25 : 50;
   let filterSize = isDesktopLG ? 20 : isDesktopMD ? 30 : 10
   let filterPanelWidth: number = 100 - (Math.min(maxFiltersOnLine, (filters || []).length) * filterSize) - ((sorts || []).length > 0 ? sortSize : 0);

   return <div {...props}>
      <FormProvider {...methods}>
         <Drawer variant={"persistent"} open={filterPanel} anchor={"right"} onClose={() => setFilterPanel(false)}
                 BackdropProps={{invisible: true}}>
            <Stack direction={"column"} spacing={1} className={classes.filterDrawer}>
               <TitleText type={"h2"}>More filters</TitleText>
               {filters?.map(filter => {
                  return <Grid item xs key={'flex_filter_' + filter.id}>
                     <FormInputSelectMultiple icon={<FilterAlt/>} name={'filterOr.' + filter.id} label={filter.label}
                                              onChange={() => updateHeaders()}
                                              options={
                                                 filter?.options?.map(option => {
                                                    return {
                                                       id: option.id || option.label,
                                                       label: option.label,
                                                       subtext: option.subtext
                                                    }
                                                 }) || []
                                              }/>
                  </Grid>
               })}
               <Button variant={"contained"} color={"primary"} onClick={() => setFilterPanel(false)}>
                  Done
               </Button>
            </Stack>
         </Drawer>
         <Stack direction={"row"} spacing={1}>
            <div style={{width: filterPanelWidth + '%'}}>
               <FormInputText name={"search"} label={searchLabel || "Search"}
                              InputProps={{
                                 endAdornment: <div
                                    style={{
                                       backgroundColor: '#e0e0e0',
                                       marginRight: '-12px',
                                       padding: '12px'
                                    }}>{count}</div>
                              }}
                              onFocus={event => {
                                 event.target.select();
                              }}
               />
            </div>
            <div style={{width: (100 - filterPanelWidth) + '%'}}>
               <Stack direction={"row"} spacing={1}>
                  {filters?.slice(0, maxFiltersOnLine).map(filter => {
                     return <Grid item xs key={'flex_filter_' + filter.id}>
                        <FormInputSelectMultiple icon={<FilterAlt/>} name={'filterOr.' + filter.id} label={filter.label}
                                                 onChange={() => updateHeaders()}
                                                 options={
                                                    filter?.options?.map(option => {
                                                       return {
                                                          id: option.id || option.label,
                                                          label: option.label,
                                                          subtext: option.subtext
                                                       }
                                                    }) || []
                                                 }/>
                     </Grid>
                  })}
                  {(filters || []).length > maxFiltersOnLine && <div className={classes.button}>
                     <Button color={"secondary"} size={"small"} variant={"outlined"} style={{borderColor: '#d0d0d0'}}
                             onClick={() => setFilterPanel(true)}>
                        Filters
                     </Button>
                  </div>}

                  {(sorts || []).length > 0 && <Grid item xs>
                     <FormInputSelect icon={<Sort/>}
                                      name={"sort"}
                                      label={"Sort"}
                                      onSelectChange={() => updateHeaders()}
                                      options={sorts!.map(sort => {
                                         return {
                                            id: sort.id,
                                            name: sort.label
                                         }
                                      })}/>
                  </Grid>}
               </Stack>
            </div>
         </Stack>
      </FormProvider>
   </div>
}

function useDebounce<TValue>(value: TValue, delay: number = 300) {
   const [debouncedValue, setDebouncedValue] = useState(value)

   useEffect(() => {
      const handler = setTimeout(() => {
         setDebouncedValue(value)
      }, delay)

      return () => {
         clearTimeout(handler)
      }
   }, [value, delay])

   return debouncedValue
}

const useStyles = makeStyles({
   filterDrawer: {
      minWidth: '500px',
      padding: '20px'
   },
   button: {
      marginBottom: '8px !important',
      "& button": {
         height: '100%'
      }
   }
});
