import Page from "../../../layout/Page"
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../../layout/Typography";
import {DateFormat} from "../../../utility/DateFormat";
import Grid from "@mui/material/Grid";
import React from "react";
import Button from "@mui/material/Button";
import useSynchronization from "./hooks/useSynchronization";
import {useIntegrationBrandByIdQuery} from "../../../Queries";


export const MasterDataPage = () => {
   const {shopId} = useParams<{ shopId: string }>();
   const [products, setProducts] = React.useState<boolean>(true);
   const [orders, setOrders] = React.useState<boolean>(true);
   const [hooks, setHooks] = React.useState<boolean>(true);
   const [stockItems, setStockItems] = React.useState<boolean>(true);
   const synchronize = useSynchronization();

   const {data} = useIntegrationBrandByIdQuery({
      variables: {
         shopId
      }
   })
   if (!data?.ShopById) {
      return <LinearProgress/>
   }

   const brand = data.ShopById;

   const onSynchronizeProducts = async () => {
      setProducts(false);
      await synchronize(shopId, 'products');
   }

   const onSynchronizeOrders = async () => {
      setOrders(false);
      await synchronize(shopId, 'orders');
   }

   const onSynchronizeHooks = async () => {
      setHooks(false);
      await synchronize(shopId, 'hooks');
   }

   const onSynchronizeStockItems = async () => {
      setStockItems(false);
      await synchronize(shopId, 'stockitems');
   }

   return <Page>
      <PageHeader title={"Integrations"}/>

      <Grid container spacing={2}>
         <Grid item xs={8}>
            <PageContent>
               <TitleText type={"h2"}>Connected to</TitleText>
               <BodyText type={"body1"}>
                  {brand.externalId}
               </BodyText>
            </PageContent>
         </Grid>
         <Grid item xs={4}>
            <PageContent>
               <TitleText type={"h1"}>Products</TitleText>
               <BodyText type={"body1"}>
                  {DateFormat.toReadable(brand.synchronizeProducts)}
               </BodyText>
               <Button disabled={!products} onClick={onSynchronizeProducts} variant={"outlined"}>Update now</Button>
               <div style={{height: '30px'}}/>

               <TitleText type={"h1"}>Orders</TitleText>
               <BodyText type={"body1"}>
                  {DateFormat.toReadable(brand.synchronizeOrders)}
               </BodyText>
               <Button disabled={!orders} onClick={onSynchronizeOrders} variant={"outlined"}>Update now</Button>
               <div style={{height: '30px'}}/>

               <TitleText type={"h1"}>External configurations</TitleText>
               <BodyText type={"body1"}>
                  {DateFormat.toReadable(brand.synchronizeHooks)}
               </BodyText>
               <Button disabled={!hooks} onClick={onSynchronizeHooks} variant={"outlined"}>Update now</Button>

               <TitleText type={"h1"}>StockItems </TitleText>
               <Button disabled={!stockItems} onClick={onSynchronizeStockItems} variant={"outlined"}>Update now</Button>

            </PageContent>
         </Grid>
      </Grid>
   </Page>
}
