import * as React from "react"
import {FC, useEffect} from "react"
import {QComponentChildrenProps} from "../Model";
import {QEvaluate} from "../QEvaluate";
import {Collapse, Grid} from "@mui/material";

export type QActivationProps = QComponentChildrenProps & {
   expression: string
}

export const QActivation: FC<QActivationProps> = ({children, expression, data}) => {
   const [active, setActive] = React.useState<boolean>(false);

   useEffect(() => {
      const handler = setTimeout(() => {
         let isActive = QEvaluate(expression, data);
         setActive(!!isActive);
      }, 300)
      return () => clearTimeout(handler);
   }, [data, expression])

   return <Collapse in={active}>
      <Grid container style={{gap: '10px'}}>{React.Children.map(children, child => {
         return <Grid item xs={12}>{child}</Grid>
      })}</Grid>
   </Collapse>
}