import {FC} from "react";
import {Divider, LinearProgress} from "@mui/material";

import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {TitleText} from "../../layout/Typography";
import {useStatWarehouseChartQuery} from "../../Queries";
import {BrandSelection} from "./BrandSelector";
import {Split, toEndISODate, toStartISODate} from "./AnalyticsUtility";

type StatTradeInStatusProps = {
   start: Date
   end: Date
   brand?: BrandSelection
   splits: Split[]
   onlyTotals?: boolean
}

export const StatWarehouseChart: FC<StatTradeInStatusProps> = ({start, end, splits, brand, onlyTotals}) => {
   const {data} = useStatWarehouseChartQuery({
      variables: {
         from: toStartISODate(start),
         to: toEndISODate(end),
         shopIds: brand?.shopIds,
         brandHouseIds: brand?.brandHouseIds,
         splits: splits
      }
   })

   if (!data?.StatisticsCalculate) {
      return <LinearProgress/>
   }

   type OrderData = {
      day: string
      forSale: number
   }

   const transformed: OrderData[] = [];
   data?.StatisticsCalculate?.splits?.forEach(split => {
      let result = data?.StatisticsCalculate?.results?.filter(res => res?.split === split);
      transformed.push({
         day: split,
         forSale: result?.find(res => res?.kpi === 'stockitemsForSale')?.value || 0
      })
   });

   return <div>
      <TitleText type={"h2"}>Warehouse</TitleText>
      <Divider style={{marginBottom: '20px'}}/>
      <ResponsiveContainer width={"100%"} height={300}>
         <AreaChart
            width={400}
            height={400}
            data={transformed}
            margin={{top: 5, right: 20, left: 10, bottom: 5}}
         >
            <XAxis dataKey="day"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <CartesianGrid stroke="#f5f5f5"/>
            <Area type="monotone" dataKey="forSale" stroke="#808080" fill="#C0C0C0"/>
         </AreaChart>
      </ResponsiveContainer>
   </div>
}
