import {useStatCurrencyConvertQuery} from "../../Queries";

type StatCurrencyProps = {
   currency: string
   value: number
}

export const StatCurrency = ({currency, value}: StatCurrencyProps) => {
   const {data} = useStatCurrencyConvertQuery({
      variables: {
         inputCurrency: 'DKK', // Internal currency is DKK
         outputCurrency: currency,
         inputValue: value
      },
      fetchPolicy: 'no-cache'
   });
   return <span>{data?.ConvertCurrency?.value}</span>
}
