import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import {ActionType, Form} from "../../../components/quickform/Model";
import {LogisticsForm} from "../../components/LogisticsForm";
import useBrandApi from "./hooks/useBrandApi";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import PageGroup from "../../../layout/PageGroup";
import useBrandApiUpdate from "./hooks/useBrandApiUpdate";
import {createForm} from "../../../components/quickform/QuickForm";
import PageContent from "../../../layout/PageContent";

export const IntegrationsSettingsPage = () => {
   const {shopId} = useParams<{ shopId: string }>()
   const data = useBrandApi(shopId);
   const saveApi = useBrandApiUpdate();

   if (!data) {
      return <LinearProgress/>
   }

   let api = data.BrandApiGet;

   const sendgridForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "Sendgrid",
               variant: 'h2'
            }
         }, {
            type: "Horizontal",
            children: [{
               type: "TextField",
               props: {
                  label: "Sender Name",
                  field: "sendgrid.from",
                  required: true
               }
            }, {
               type: "TextField",
               props: {
                  label: "Sender Email",
                  field: "sendgrid.fromEmail",
                  required: true
               }
            }]
         }, {
            type: "TextField",
            props: {
               label: "Access token",
               field: "sendgrid.token",
               required: true
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Update",
                  type: "save"
               }
            }]
         }]
      }
   });

   let externalshopForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "External shop - Azure ServiceBus",
               variant: 'h2'
            }
         }, {
            type: "TextField",
            props: {
               label: "Service Connection string",
               field: "externShop.serviceBus"
            }
         }, {
            type: "TextField",
            props: {
               label: "Topic",
               field: "externShop.topic"
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Update",
                  type: "save"
               }
            }]
         }]
      }
   });

   let autoRetouchForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "AutoRetouch",
               variant: 'h2'
            }
         }, {
            type: "Select",
            props: {
               label: "Select the workflow to use with autoretouch",
               field: "autoretouch.workflowId",
               options: data.AutoRetouchWorkflows.map(wf => {
                  return {
                     value: wf.id,
                     label: wf.name
                  }
               })
            }
         }, {
            type: "Heading",
            props: {
               text: "StockItem integration API",
               subtext: "",
               variant: "h2",
            }
         }, {
            type: "Select",
            props: {
               label: "Automatic rotate images received via API",
               field: "autoretouch.autoRotate",
               options: [{
                  value: null,
                  label: "None"
               }, {
                  value: -90,
                  label: "Left 90 degrees"
               }, {
                  value: 90,
                  label: "Right 90 degrees"
               }, {
                  value: 180,
                  label: "Flip 180 degrees"
               }]
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Automatic retouch images received via API",
               field: "autoretouch.autoProcess"
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Automatic publish products received via API",
               field: "autoPublish"
            }
         }, {
            type: "Horizontal",
            children: [{
               type: "Vertical",
               children: [{
                  type: "Heading",
                  props: {
                     text: "Traede Integration",
                     variant: "h2",
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Enabled",
                     field: "traede.enabled"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Access token",
                     field: "traede.accessToken"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Item number prefix",
                     field: "traede.itemNumberPrefix"
                  }
               }]
            }, {
               type: "Vertical",
               children: [{
                  type: "Heading",
                  props: {
                     text: "SPY Integration",
                     variant: "h2",
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Enabled",
                     field: "spy.enabled"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Capture URL",
                     field: "spy.captureUrl"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Refund URL",
                     field: "spy.refundUrl"
                  }
               }]
            }, {
               type: "Vertical",
               children: [{
                  type: "Heading",
                  props: {
                     text: "Shopify Integration",
                     variant: "h2",
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Enabled",
                     field: "shopify.enabled"
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Capture",
                     field: "shopify.capture"
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Refund",
                     field: "shopify.refund"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Shopify Location ID for stockitems",
                     field: "shopify.shopifyLocationId"
                  }
               }]
            }, {
               type: "Vertical",
               children: [{
                  type: "CheckBox",
                  props: {
                     label: "Color enabled",
                     field: "shopify.options.color"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Color label",
                     field: "shopify.options.colorName"
                  }
               }, {
                  type: "CheckBox",
                  props: {
                     label: "Size enabled",
                     field: "shopify.options.size"
                  }
               }, {
                  type: "TextField",
                  props: {
                     label: "Size label",
                     field: "shopify.options.sizeName"
                  }
               }]
            }]
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Update",
                  type: "save"
               }
            }]
         }]
      }
   });

   const onButton = async (type: ActionType, data: any) => {
      await saveApi(shopId, data)
      return {
         success: true
      }
   }

   return <Page>
      <PageHeader title={"Integrations"}/>
      <PageGroup>
         <PageContent>
            <LogisticsForm form={sendgridForm} data={api} onButton={onButton} shopId={shopId}/>
         </PageContent>
         <PageContent>
            <LogisticsForm form={externalshopForm} data={api} onButton={onButton} shopId={shopId}/>
         </PageContent>
      </PageGroup>
      <PageContent>
         <LogisticsForm form={autoRetouchForm} data={api} onButton={onButton} shopId={shopId}/>
      </PageContent>
   </Page>
}