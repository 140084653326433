import * as React from "react";
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {Drawer, Theme} from '@mui/material';
import {ReactNode} from "react";

const useStyles = makeStyles((theme:Theme) => ({
    drawer: {
        width: 320,
        [theme.breakpoints.up('lg')]: {
            marginTop: 50,
            height: 'calc(100% - 50px)'
        }
    },
    drawerTop: {
        width: 320,
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100% - 50px)'
        }
    },
    root: {
        // backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}));

type SidebarProps = {
    className?: string;
    onClose: () => void;
    open: boolean;
    variant: "permanent" | "persistent" | "temporary";
    children: ReactNode,
    anchor: "left"|"right",
    topbarHidden?: boolean
}

const Sidebar = (props:SidebarProps) => {
    const {open, variant, onClose, className, topbarHidden, ...rest} = props;

    const classes = useStyles();

    return (
        <Drawer
            anchor={props.anchor}
            classes={{paper: topbarHidden? classes.drawerTop:classes.drawer}}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
            </div>
        </Drawer>
    );
};

export default Sidebar;
