import {gql, useMutation} from "@apollo/client";

const qlBrandRuleDeletion = gql`
    mutation BrandRuleDelete($shopId: MongoID!, $ruleId: MongoID!) {
      BrandRuleDelete(shopId:$shopId, ruleId: $ruleId) {
         _id
      }
    }

`

type BrandRuleDeleteResult = { _id: string };

const useBrandRuleDelete = () => {
   const [brandRuleDelete] = useMutation(qlBrandRuleDeletion);

   return async (shopId: string, ruleId: string): Promise<BrandRuleDeleteResult | undefined> => {
      let result = await brandRuleDelete({
         variables: {
            shopId: shopId,
            ruleId: ruleId
         },
         update(cache, {data}) {
            cache.evict(data.BrandRuleDelete._id);
         }
      });
      return result?.data?.BrandRuleDelete as BrandRuleDeleteResult
   }
}

export default useBrandRuleDelete;