import * as React from "react";
import {FC} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

export type StatisticsData = {
   splits: string[]
   kpis: string[]
   results: StatisticsEntry[]
}

export type StatisticsEntry = {
   kpi: string
   split: string
   value: number
}

export type StatisticsTableProps = {
   titleRender: (kpi: string) => string
   valueRender: (kpi: string, value: any) => JSX.Element
   data: StatisticsData
}

export const StatisticsTable: FC<StatisticsTableProps> = ({titleRender, valueRender, data}) => {
   return <div>
      <Table>
         <TableHead>
            <TableRow>
               <TableCell/>
               {data?.kpis?.map(kpi => {
                  return <TableCell key={'col_kpi_' + kpi}>{titleRender(kpi)}</TableCell>
               })}
            </TableRow>
         </TableHead>
         <TableBody>
            {data.splits?.map(split => {
               return <TableRow key={'row_split_' + split}>
                  <TableCell>{split}</TableCell>
                  {data.kpis?.map(kpi => {
                     const value = data.results?.find(res => {
                        return res?.kpi && res?.split && res?.kpi === kpi && res?.split === split
                     })?.value;
                     return <TableCell key={'vcell_' + kpi + '_' + split}>
                        {value && valueRender(kpi, value)}
                     </TableCell>
                  })}
               </TableRow>
            })}
         </TableBody>
      </Table>
   </div>
}