import PageContent from "../../../layout/PageContent";
import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {MailEditPagePath} from "./MailEditPage";
import {
   BrandSettingPlannedMailsTradeInInput,
   BrandSettingPlannedMailsVoucherInput,
   BrickMailGetTypesDocument,
   useBrickMailActivateMutation,
   useBrickMailGetTypesQuery,
   useReminderMailSettingsQuery,
   useReminderMailTradeInUpdateMutation,
   useReminderMailVoucherUpdateMutation
} from "../../../Queries";
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Dialogs} from "../../../DialogProvider";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";
import {MailTextExport} from "./MailTextExport";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputSelect} from "../../shop/wholesale/form/FormInputSelect";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {SavingUI} from "../../../SavingProvider";

const MailPagePath = (brandId: string, shopId: string) => {
   return "/brands/" + brandId + "/settings/mails/" + shopId
}

export const MailPage = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(MailPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Mails"}/>
      <ShopTabs
         hideDefault={true}
         activeId={shopId}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <div/>}
         renderShop={(shop) => <MailPageInner brandHouseId={brandHouseId} shopId={shop}/>}
      />
   </div>
}

type MailPageInnerProps = {
   brandHouseId: string
   shopId: string
}

const MailPageInner = ({shopId, brandHouseId}: MailPageInnerProps) => {
   const navigate = useNavigate();
   const [exporting, setExporting] = useState<boolean>(false);

   const [tradeInReminder, setTradeInReminder] = useState<boolean>(false);
   const [voucherReminder, setVoucherReminder] = useState<boolean>(false);

   const [saveVoucher] = useReminderMailVoucherUpdateMutation()
   const [saveTradeIn] = useReminderMailTradeInUpdateMutation()

   const {data} = useBrickMailGetTypesQuery({
      variables: {
         shopId: shopId
      }
   })

   const {data: reminderSettings} = useReminderMailSettingsQuery({
      variables: {
         shopId
      }
   })

   const [activate] = useBrickMailActivateMutation()

   const onSelect = (mailType: string) => () => {
      navigate(MailEditPagePath(brandHouseId, shopId, mailType))
   }

   const onToggle = (mailType: string, active: boolean) => async () => {
      if (!active) {
         Dialogs.confirm({
            title: 'Are you sure you wish to deactivate this mail?',
            subtitle: 'Please notice that noone can receive a mails of this type, if it is deactivated here'
         }, async () => {
            await activate({
               variables: {
                  shopId: shopId,
                  mailType: mailType,
                  active: active
               },
               refetchQueries: [{
                  query: BrickMailGetTypesDocument,
                  variables: {
                     shopId: shopId
                  }
               }]
            })
         });
      } else {
         Dialogs.confirm({
            title: 'Are you sure you wish to activate this mail?',
            subtitle: 'Customers will, from time of activation be able to receive mails of this type'
         }, async () => {
            await activate({
               variables: {
                  shopId: shopId,
                  mailType: mailType,
                  active: active
               },
               refetchQueries: [{
                  query: BrickMailGetTypesDocument,
                  variables: {
                     shopId: shopId
                  }
               }]
            })
         });
      }
   }

   const onSaveVoucher = async (data: BrandSettingPlannedMailsVoucherInput) => {
      await SavingUI.process(async () => {
         await saveVoucher({
            variables: {
               shopId: shopId,
               voucher: data
            }
         })
         setVoucherReminder(false);
      }, 'Saved voucher reminder settings', 'Saving')
   }
   const onSaveTradeIn = async (data: BrandSettingPlannedMailsTradeInInput) => {
      await SavingUI.process(async () => {
         await saveTradeIn({
            variables: {
               shopId: shopId,
               voucher: data
            }
         })
         setTradeInReminder(false);
      }, 'Saved trade-in reminder settings', 'Saving')
   }

   if (!data) {
      return <LinearProgress/>
   }

   return <Box sx={{position: 'relative'}}>
      <Box sx={{position: 'absolute', right: '5px', top: '-52px'}}>
         <Button onClick={() => setExporting(true)}>Export translations</Button>
      </Box>
      {data.BrickMailTypes?.map(mailType => {
         return <PageContent key={'mail_' + mailType?.type}>
            <div style={{display: 'flex'}}>
               <div onClick={onSelect(mailType!.type)} style={{cursor: "pointer", width: '100%'}}>
                  <TitleText type={"h2"}>{mailType!.title}</TitleText>
                  {mailType!.description}
                  {mailType?.type === 'reminder' && <Box sx={{marginLeft: '-8px'}}>
                     <Button onClick={(e) => {
                        e.stopPropagation();
                        setTradeInReminder(true)
                     }} variant={"text"} size={"small"}>Settings</Button></Box>
                  }
                  {mailType?.type === 'remindervoucher' && <Box sx={{marginLeft: '-8px'}}>
                     <Button onClick={(e) => {
                        e.stopPropagation();
                        setVoucherReminder(true)
                     }} variant={"text"} size={"small"}>Settings</Button></Box>
                  }
               </div>
               <div>
                  <Checkbox onClick={onToggle(mailType!.type, !mailType!.active)} checked={mailType!.active}/>
               </div>
            </div>
         </PageContent>
      })}
      {tradeInReminder && <DialogReminderSettings title={"Trade-in"}
                                                  data={reminderSettings?.BrandSettings?.plannedMailsTradeIn || {
                                                     startAfter: 0,
                                                     repeatCount: 0,
                                                     repeatDelay: 0
                                                  }} onClose={() => setTradeInReminder(false)} onSave={onSaveTradeIn}/>}
      {voucherReminder && <DialogReminderSettings title={"Voucher"}
                                                  data={reminderSettings?.BrandSettings?.plannedMailsVoucher || {
                                                     startAfter: 0,
                                                     repeatCount: 0,
                                                     repeatDelay: 0
                                                  }} onClose={() => setVoucherReminder(false)} onSave={onSaveVoucher}/>}
      {exporting && <MailTextExport shopId={shopId}/>}
   </Box>
}

type DialogReminderSettingsProps = {
   title: string
   data: BrandSettingPlannedMailsVoucherInput
   onSave: (data: BrandSettingPlannedMailsVoucherInput) => Promise<void>
   onClose: () => void
}

export const DialogReminderSettings = ({title, data, onSave, onClose}: DialogReminderSettingsProps) => {
   const methods = useForm<BrandSettingPlannedMailsVoucherInput>({
      defaultValues: data
   });

   const {handleSubmit} = methods;

   const onSubmit = async (data: BrandSettingPlannedMailsVoucherInput) => {
      await onSave(data);
   }

   // Using formik, create a form and editor
   return <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle>
         <TitleText type={"h2"}>Reminder settings for {title}</TitleText>
         <BodyText type={"subtitle1"}>Notice, will only apply for new or updated {title.toLowerCase()}s</BodyText>
      </DialogTitle>
      <DialogContent>
         <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <FormProvider {...methods}>
               <form id={"reminderForm"} onSubmit={handleSubmit(onSubmit)}>
                  <div>
                     <FormInputNumber name={"startAfter"} required label={"Start reminding after (days)"}/>
                     <FormInputSelect name={"repeatCount"} required label={"Number of reminders"}
                                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(r => ({
                                         id: r,
                                         name: r + ' reminders' + (r === 2 ? ' (Recommended)' : '')
                                      }))}/>
                     <FormInputSelect required name={"repeatDelay"} label={"Days between each reminder (after delay)"}
                                      options={[1, 2, 3, 5, 7, 10, 14, 21, 31].map(r => ({
                                         id: r,
                                         name: r + ' days' + (r === 7 ? ' (Recommended)' : '')
                                      }))}/>
                  </div>
               </form>
            </FormProvider>
         </Box>
      </DialogContent>
      <DialogActions>
         <Button type={"submit"} form={"reminderForm"}>Save</Button>
      </DialogActions>
   </Dialog>
}