import {FormInputSelect} from "../pages/shop/wholesale/form/FormInputSelect";
import * as React from "react";
import {Locales} from "./AddLanguage";

type SelectLanguageProps = {
   name: string
   label: string
}

export const SelectLanguage = ({name, label}: SelectLanguageProps) => {
   return <FormInputSelect name={name} label={label} options={Locales.map(locale => {
      return {
         id: locale.key,
         name: locale.name
      }
   })}/>

}