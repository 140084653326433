import PageContent from "../../../layout/PageContent";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Chip, IconButton, LinearProgress} from "@mui/material";
import {TitleText} from "../../../layout/Typography";
import * as React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {SavingUI} from "../../../SavingProvider";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {
   BrandHouseShopFragment,
   EnumIntegrationConnectorRefType,
   UpdateByIdBrandHouseInput,
   useDetailsPageBrandByIdQuery,
   useDetailsPageBrandUpdateMutation
} from "../../../Queries";
import SettingsHeader from "../SettingsHeader"
import {FrontendIcon} from "./FrontendIcon";
import {ShopCreatePath} from "./ShopCreate";
import {ShopSettingsPath} from "./ShopSettings";
import {ShopIntegrationsPagePath} from "./ShopIntegrations";
import {RefIntegrations} from "./RefIntegrations";
import {BrandIntegrationSettingsPath} from "./BrandIntegrationSettings";
import {PageCard, PageCards} from "../../../layout/PageCards";
import {AddressEditor} from "../AddressEditor";
import {Folder} from "@mui/icons-material";
import {ShopListPath} from "./ShopList";

export const DetailsPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId + "/settings/brand"
}

export const DetailsPage = () => {
   const {brandHouseId} = useParams<{ brandHouseId: string }>()
   const [updateBrand] = useDetailsPageBrandUpdateMutation();
   const navigate = useNavigate();

   const {data: brandData} = useDetailsPageBrandByIdQuery({
      variables: {
         id: brandHouseId
      }
   });

   if (!brandData) {
      return <LinearProgress/>
   }

   const onSubmit = async (data: UpdateByIdBrandHouseInput) => {
      await SavingUI.process(async () => {
         await updateBrand({
            variables: {
               id: brandHouseId,
               input: {
                  name: data.name,
                  address: data.address
               }
            }
         })
      }, 'Brand updated')
   }

   if (!brandData?.BrandHouse) {
      return <LinearProgress/>
   }

   const onGoToShop = (shop: BrandHouseShopFragment) => {
      navigate(ShopSettingsPath(brandHouseId, shop._id))
   }

   const onGotoConnector = (connectorId: string) => {
      navigate(BrandIntegrationSettingsPath(brandHouseId, connectorId))
   }

   return <div>
      <SettingsHeader title={"Brand and shops"}/>
      <PageContent>
         <Box>
            <BrandForm data={brandData?.BrandHouse as UpdateByIdBrandHouseInput} onSave={onSubmit}/>
            <Button sx={{marginTop: '10px'}} type="submit" form={"brandEditForm"} variant={"contained"}>Update</Button>
         </Box>
      </PageContent>
      <Box sx={{marginTop: '40px'}}>
         <PageCards cards={(brandData?.BrandHouse?.shops || []).map(shop => ({
               id: shop!._id,
               icon: <FrontendIcon type={shop!.frontendType}/>,
               name: shop!.name || 'Unknown shop',
               description: shop!.externalId,
               onClick: () => onGoToShop(shop as BrandHouseShopFragment),
               commands: <Box sx={{display: 'flex', columnGap: '15px', alignItems: 'center'}}>
                  <IconButton onClick={(e: any) => {
                     e.preventDefault();
                     e.stopPropagation();
                     navigate(ShopListPath(brandHouseId, shop!._id))
                  }}><Folder/></IconButton>
                  {shop!.live ?
                     <Chip color={"success"} label={"Live"} size={"small"} sx={{minWidth: '80px'}}/> :
                     <Chip color={"secondary"} label={"Offline"} size={"small"} sx={{minWidth: '80px'}}/>}
                  <Button onClick={(e: any) => {
                     e.preventDefault();
                     e.stopPropagation();
                     navigate(ShopIntegrationsPagePath(brandHouseId, shop!._id))
                  }} color={"secondary"} variant={"outlined"}>Integrations</Button>
               </Box>,
            } as PageCard
         ))}
                    title={"Shops"}
                    commands={<Button onClick={() => navigate(ShopCreatePath(brandHouseId))}
                                      variant={"outlined"}
                                      color={"secondary"}
                                      size={"small"}
                                      sx={{ml: 2}}
                    >
                       Add shop
                    </Button>
                    }
         />
      </Box>
      <Box sx={{marginTop: '40px'}}>
         <RefIntegrations onNavigateTo={onGotoConnector} refId={brandHouseId}
                          title={"Brand integrations"}
                          refType={EnumIntegrationConnectorRefType.BRANDHOUSE}/>
      </Box>
   </div>
}

type BrandFormProps = {
   data: UpdateByIdBrandHouseInput
   onSave: (data: UpdateByIdBrandHouseInput) => Promise<void>
}

const BrandForm = ({data, onSave}: BrandFormProps) => {
   const methods = useForm<UpdateByIdBrandHouseInput>({
      defaultValues: data
   });

   const {handleSubmit} = methods;

   const onSubmit = async (data: UpdateByIdBrandHouseInput) => {
      await onSave(data);
   }

   return <div>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
         <form id={"brandEditForm"} onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
               <div>
                  <TitleText type={"subtitle1"}>The name of the brand</TitleText>
                  <FormInputText name={"name"} required label={"Name of brand"}/>
                  <TitleText type={"subtitle1"}>License key</TitleText>
                  <FormInputText disabled name={"license"} required label={"Key"}/>
                  <AddressEditor addressField={"address"}/>
               </div>
            </FormProvider>
         </form>
      </Box>
   </div>
}
