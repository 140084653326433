import {BodyText, TitleText} from "../../../../../layout/Typography";
import {Box, CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

export const StatCard = (props: { title: string, value?: string | number, polling: boolean, className?: string }) => {
   const classes = useStyles();
   return <Box className={classes.statCol}>
         <BodyText type="body1">
            {props.title}
         </BodyText>
         {props.polling && <CircularProgress/>}
         <TitleText type="h1">
            {props.polling ? '' : props.value ? props.value : '0'}
         </TitleText>
   </Box>
}

const useStyles = makeStyles({
   statCol: {
      display: 'table-cell',
      padding: '10px 20px 10px 20px',
      margin: 'auto',
      width: '100%',
      height: '100%'
   }
});
