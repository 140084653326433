import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {useParams} from "react-router-dom";
import {ParamBrandHouse} from "../../Parameters";
import * as React from "react";
import {TrackingViewList} from "../lists/TrackingViewList";

export const BrandTrackingPage = () => {
   const {brandHouseId} = useParams<ParamBrandHouse>()

   return <Page>
      <PageHeader title={"Activity"}/>
      <PageContent fullHeight>
         <TrackingViewList brandColumn={false} filter={{
            brandHouseId: brandHouseId
         }}/>
      </PageContent>
   </Page>
}