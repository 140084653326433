import {Alert, AppBar, LinearProgress, Tab, Tabs} from "@mui/material";
import * as React from "react";
import {useShopTabsShopsQuery} from "../../Queries";

type ShopTabsProps = {
   brandHouseId: string
   activeId: string
   hideDefault?: boolean
   onChange: (shopId: string) => void
   renderDefault: () => React.ReactElement
   renderShop: (shopId: string) => React.ReactElement
}

export const ShopTabs = ({brandHouseId, activeId, onChange, renderDefault, renderShop, hideDefault}: ShopTabsProps) => {
   const {data} = useShopTabsShopsQuery({
      variables: {
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })
   if (!data) {
      return <LinearProgress/>
   }

   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      onChange(newValue);
   };

   let currentId: string;
   if (hideDefault && !activeId && data.ShopMany?.length > 0) {
      currentId = String(data.ShopMany[0]._id)
   } else {
      currentId = activeId || brandHouseId
   }

   return <div>
      {!(hideDefault && data.ShopMany?.length===0) && <AppBar position={"relative"} color={"default"} sx={{marginBottom: '10px'}} variant={"outlined"}>
         <Tabs value={currentId} onChange={handleChange} variant={"scrollable"} style={{width: 'calc(100vw - 360px)'}}>
            {!hideDefault && <Tab value={brandHouseId} label={"Default"}/>}
            {data?.ShopMany?.map(shop => {
               return <Tab value={shop._id} label={shop.name || 'Shop'}/>
            })}
         </Tabs>
      </AppBar>}
      {(hideDefault && data.ShopMany?.length===0) && <>
         <Alert variant={"outlined"} color={"warning"}>No shops defined</Alert>
      </>}
      {(currentId === brandHouseId) && renderDefault()}
      {(currentId !== brandHouseId) && renderShop(currentId)}
   </div>
}