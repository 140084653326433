import * as React from "react";
import {FC} from "react";
import {QuickForm, QuickFormConfig, QuickFormProps} from "../../components/quickform/QuickForm";
import {createUploadService} from "../../UploadService";

type LogisticsFormProps = QuickFormProps & {
   shopId: string
}

export const LogisticsForm: FC<LogisticsFormProps> = ({shopId, form, data, onButton}) => {
   let config: QuickFormConfig = {
      uploadService: createUploadService(process.env.REACT_APP_FILESERVICEURL!, shopId)
   }
   return <QuickForm form={form} data={data} onButton={onButton} config={config}/>
}