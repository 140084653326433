import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {merge} from "lodash";
import {FormControlProps} from "@mui/material/FormControl/FormControl";

let nextId = 0;

export type SelectOption = {
   id: any
   name: string
}

export interface FormInputSelectProps extends FormControlProps {
   name: string;
   control?: any;
   label: string;
   setValue?: any;
   errorMessage?: string;
   options: SelectOption[]
   required?: boolean
   onSelectChange?: (e: SelectChangeEvent<any>) => void
   icon?: React.ReactNode
}


export const FormInputSelect: React.FC<FormInputSelectProps> = ({
                                                                   name,
                                                                   label,
                                                                   options,
                                                                   errorMessage,
                                                                   required,
                                                                   onSelectChange,
                                                                   icon,
                                                                   ...rest
                                                                }) => {
   let labelid = "simple-select-" + (++nextId);

   const {style} = rest;

   const methods = useFormContext();
   const {control, formState: {errors}} = methods;
   const showError = !!errors[name];

   const newStyle = merge(style, {
      marginBottom: '8px',
   });

   const selectStyle = {
      backgroundColor: 'transparent',
      border: '1px solid #d0d0d0'
   };

   return (
      <FormControl fullWidth variant={"filled"} margin={"none"} size={"small"} style={newStyle} {...rest}>
         <InputLabel id={labelid}>
            <div style={{display: 'flex', alignItems: 'middle', gap: '10px'}}>
               {icon && icon} {label}
            </div>
         </InputLabel>
         <Controller
            render={({field: {onChange, value}, fieldState: {error}}) => {
               return <><Select
                  disableUnderline
                  style={selectStyle}
                  labelId={labelid}
                  onChange={(e) => {
                     onChange(e);
                     if (onSelectChange) {
                        onSelectChange(e)
                     }
                  }}
                  value={value}
                  label={required ? label : (label||'')}
                  renderValue={(selected) => {
                     return <div>{options?.find(option => option.id===selected)?.name}</div>
                  }}
               >
                  {(required === undefined || !required) && <MenuItem value={''}>- None -</MenuItem>}
                  {options?.map((option) => {
                     return <MenuItem value={option.id} dense={true} key={'select_' + option.id}>
                        {option.name}
                     </MenuItem>
                  })}
               </Select>
                  {error && <div style={{fontSize: '12px'}}>{errorMessage}</div>}
               </>
            }}
            control={control}
            name={name}
         />
         {showError && <Alert variant="filled" severity="error">{errors[name]?.message}</Alert>}
      </FormControl>
   );
};
