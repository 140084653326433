import * as React from "react";
import {FC} from "react";
import {BrandSetting, BrandSettingInput, useVouchersPageQuery, useVouchersPageUpdateMutation} from "../../../Queries";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {BodyText, TitleText} from "../../../layout/Typography";
import {
   Button,
   Grid,
   IconButton,
   LinearProgress,
   Paper,
   Tab,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Tabs
} from "@mui/material";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import {AddCircle, DeleteForever} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import {SavingUI} from "../../../SavingProvider";
import SettingsHeader from "../SettingsHeader";
import PageContent from "../../../layout/PageContent";
import {ShopTabs} from "../ShopTabs";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";

const ID_VOUCHERS_FORM = "voucherssettingsform";

const VouchersPagePath = (brandId: string, edit: string) => {
   return "/brands/" + brandId + "/settings/evouchers/" + edit
}

export const VouchersPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(VouchersPagePath(brandHouseId, newValue));
   }

   return <div>
      <SettingsHeader title={"Rewards"}>
         <Button type="submit" form={ID_VOUCHERS_FORM} variant={"contained"}>Save</Button>
      </SettingsHeader>
      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <VouchersPageInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <VouchersPageInner shopId={shop}/>}
      />
   </div>
}

type VouchersPageInnerProps = {
   brandHouseId?: string
   shopId?: string
}

const VouchersPageInner = ({brandHouseId, shopId}: VouchersPageInnerProps) => {
   const {data} = useVouchersPageQuery({
      variables: {
         shopId: shopId,
         brandHouseId: brandHouseId
      },
      fetchPolicy: "no-cache"
   })

   const [saveVoucherSettings] = useVouchersPageUpdateMutation();

   const onSaveVoucher = async (data: BrandSettingInput) => {
      await SavingUI.process(async () => {
         await saveVoucherSettings({
            variables: {
               shopId: shopId,
               brandHouseId: brandHouseId,
               input: data.voucher
            },
            refetchQueries: ['BrandSettings']
         });
      }, 'Rewards updated')
   }

   if (!data?.BrandSettings) {
      return <LinearProgress/>
   }

   return <PageContent>
      <RewardEditor data={data?.BrandSettings as BrandSetting || {
         voucher: {
            minimumPurchaseExt: [],
            minimumPurchase: undefined,
            expireDays: undefined,
            prefix: undefined,
            refundApproval: true
         }
      }} onSave={onSaveVoucher}/>
   </PageContent>
}

type VoucherProps = {
   data: BrandSetting
   onSave: (data: BrandSettingInput) => Promise<void>
}

type VoucherMinimumType = 'none' | 'simple' | 'ranges'

const RewardEditor: FC<VoucherProps> = ({data, onSave}) => {
   const classes = useRewardStyles();

   const [minType, setMinType] = React.useState<VoucherMinimumType>(
      data.voucher?.minimumPurchase ? 'simple' : (data.voucher?.minimumPurchaseExt || []).length > 0 ? 'ranges' : 'none'
   );

   const methods = useForm<BrandSetting>({
      defaultValues: data
   });

   const {handleSubmit, control} = methods;

   const {fields, append, remove} = useFieldArray({
      control,
      name: "voucher.minimumPurchaseExt"
   });

   const onSubmit = async (data: BrandSettingInput) => {
      if (minType === 'none') {
         data.voucher!.minimumPurchaseExt = [];
         data.voucher!.minimumPurchase = null;
      } else if (minType === 'simple') {
         data.voucher!.minimumPurchaseExt = [];
      } else if (minType === 'ranges') {
         data.voucher!.minimumPurchase = null;
      }
      await onSave(data as any)
   }

   return <>
      <form onSubmit={handleSubmit(onSubmit)} id={ID_VOUCHERS_FORM}/>
      <FormProvider {...methods}>
         <Grid container>
            <Grid item xs={12}>
               <div className={classes.section}>
                  <TitleText type={"h2"}>Vouchers</TitleText>
                  <BodyText type={"subtitle1"}>Here you can control how vouchers are issued, how they can be used,
                     and
                     for
                     how long</BodyText>
               </div>
               <div className={classes.section}>
                  <TitleText type={"h3"}>Expiration</TitleText>
                  <BodyText type={"subtitle1"}>Vouchers generated will have a fixed expiration in days, measured from
                     the
                     day the voucher is issued</BodyText>
                  <FormInputNumber name="voucher.expireDays" control={control} label={"Expires after (days)"}
                                   errorMessage={"Name is required"}/>
               </div>
               <div className={classes.section}>
                  <TitleText type={"h3"}>Prefix</TitleText>
                  <BodyText type={"subtitle1"}>Vouchers will be created with a fixed prefix, to make them
                     distinguishable
                     from other vouchers</BodyText>
                  <FormInputText name="voucher.prefix" control={control} label={"Prefix"}/>
               </div>
               <div className={classes.section}>
                  <TitleText type={"h3"}>Voucher maximum value</TitleText>
                  <BodyText type={"subtitle1"}>If set, then no trade-in can give a reward that exceeds this amount</BodyText>
                  <FormInputNumber name="voucher.maximumValue" label={"Maximum voucher value"}/>
               </div>
               <div className={classes.section}>
                  <TitleText type={"h3"}>Voucher usage system</TitleText>
                  <BodyText type={"subtitle1"}>Vouchers will be reimbursed gradually as the orders with are refunded</BodyText>
                  <FormInputCheckbox name="voucher.newVoucherUsage" label={"Voucher usage system"}/>
               </div>
            </Grid>
            <Grid item xs={12}>
               <div className={classes.section}>
                  <TitleText type={"h3"}>Minimum Purchase</TitleText>
                  <BodyText type={"subtitle1"}>The issued vouchers can limited so they can be used on select orders
                     only.
                     There is 2 strategies for limiting the use, "Modifier" that automatically calculates a minimum
                     order
                     in order to use the voucher, and the "Range" that allows you to control when the voucher is
                     useable
                     based on voucher ranges.</BodyText>
                  <Tabs value={minType}>
                     <Tab value={'none'} label={"None"} onClick={() => setMinType("none")}/>
                     <Tab value={'simple'} label={"Modifier"} onClick={() => setMinType('simple')}/>
                     <Tab value={'ranges'} label={"Ranges"} onClick={() => setMinType('ranges')}/>
                  </Tabs>
                  <Paper variant={"outlined"} style={{padding: '20px'}}>
                     {minType === 'none' && <>No limitation on voucher use</>}
                     {minType === 'simple' && <>
                        <BodyText type={"subtitle1"}>Vouchers generated with a minimum purchase modifier will have an
                           additional requirement on the voucher. If the modifier is set to 50% and the voucher is
                           for
                           100
                           DKK, then the order that uses the voucher must have a subtotal of 150 DKK or
                           more.</BodyText>
                        <FormInputNumber name="voucher.minimumPurchase" control={control}
                                         label={"Minimum Purchase Modifier (%)"}
                                         errorMessage={"Required"}/>
                     </>
                     }
                     {minType === 'ranges' && <>
                        <BodyText type={"subtitle1"}>Here you can define a number of voucher value and minimum
                           purchase
                           rules.</BodyText>
                        <Table aria-label="collapsible table" size={"small"}>
                           <TableHead>
                              <TableRow>
                                 <TableCell/>
                                 <TableCell>Voucher Value</TableCell>
                                 <TableCell>Minimum Purchase</TableCell>
                                 <TableCell/>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {fields?.map((item, index) => {
                                 return <TableRow key={'voucher_' + item.id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell><FormInputNumber
                                       name={`voucher.minimumPurchaseExt[${index}].valueMax`}
                                       control={control}
                                       label={'Maximum value'}/></TableCell>
                                    <TableCell><FormInputNumber
                                       name={`voucher.minimumPurchaseExt[${index}].minimumPurchase`}
                                       control={control}
                                       label={'Minimum Purchase'}/></TableCell>
                                    <TableCell>
                                       <IconButton color={"secondary"} onClick={() => {
                                          remove(index)
                                       }}>
                                          <DeleteForever/>
                                       </IconButton>
                                    </TableCell>
                                 </TableRow>
                              })}
                           </TableBody>
                        </Table>
                        <IconButton color={"primary"} onClick={() => {
                           append({
                              minimumPurchase: 0,
                              valueMax: 0
                           })
                        }}>
                           <AddCircle/>
                        </IconButton>
                     </>}
                  </Paper>
               </div>
            </Grid>
         </Grid>
      </FormProvider>
   </>

}

const useRewardStyles = makeStyles((theme: Theme) =>
   createStyles({
      section: {
         marginTop: '20px',
      },
   }),
);