import {useParams} from "react-router";
import * as React from "react";
import {ParamLogisticsPartner} from "../../Parameters";
import {RefIntegrations} from "../settings/details/RefIntegrations";
import {EnumIntegrationConnectorRefType} from "../../Queries";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {LogisticsIntegrationPagePath} from "./LogisticsIntegrationPage";

export const LogisticsIntegrationsPagePath = (logisticsPartnerId: string) => {
   return '/logisticspartners/' + logisticsPartnerId + '/integrations'
}
export const LogisticsIntegrationsPage = () => {
   const {logisticsPartnerId} = useParams<ParamLogisticsPartner>()
   const navigate = useNavigate()

   const onNavigateTo = (connectorId: string) => {
      navigate(LogisticsIntegrationPagePath(logisticsPartnerId, connectorId))
   }

   return <Box sx={{marginTop: '10px'}}>
      <RefIntegrations onNavigateTo={onNavigateTo} refId={logisticsPartnerId}
                           refType={EnumIntegrationConnectorRefType.LOGISTICSPARTNER}/>

   </Box>
}
