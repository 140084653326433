import useBrandHouses, {BrandHouse} from "./hooks/useBrandHouses";
import {Button, Dialog, DialogContent, LinearProgress} from "@mui/material";
import {DataColumn, DataTable} from "../../components/DataTable";
import * as React from "react";
import {Link} from "react-router-dom"
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import Page from "../../layout/Page";
import useBrandHouseCreate from "./hooks/useBrandHouseCreate";
import {ActionType, Form} from "../../components/quickform/Model";
import {createForm, QuickForm} from "../../components/quickform/QuickForm";
import {PubSub} from "../../components/PubSub";
import {SavingUI} from "../../SavingProvider";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../atoms/CurrentUser";

export const BrandHousePage = () => {
   const brands = useBrandHouses();
   const [creating, setCreating] = React.useState<boolean>(false);
   const createBrandHouse = useBrandHouseCreate();
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   if (!brands) {
      return <LinearProgress/>
   }

   let columns: DataColumn<BrandHouse>[] = [{
      field: 'name',
      label: 'Name',
      searchable: true,
      render: (data, row) => {
         return <Link to={"/brands/" + row._id}>
            {data}
         </Link>
      }
   }, {
      field: 'license',
      label: 'License',
      searchable: true
   }, {
      field: 'apiKey',
      label: 'ApiKey',
   }, {
      field: 'apiSecret',
      label: 'ApiSecret',
   }]

   let nameForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "Create Brand"
            }
         }, {
            type: "TextField",
            props: {
               label: "Name of Brand",
               field: "name",
               required: true,
               focus: true
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Save",
                  type: "save"
               }
            }, {
               type: "Button",

               props: {
                  label: "Cancel",
                  type: "cancel"
               }
            }]
         }]
      }
   });

   const onCreateBrand = () => {
      setCreating(true);
   }

   const onCancel = () => {
      setCreating(false);
   }

   const onButton = async (button: ActionType, data: any) => {
      return await SavingUI.process(async () => {
         if (button === "save") {
            setCreating(false);
            await createBrandHouse(data.name);
            PubSub.emit('NavigationReload')
         }
         return {
            success: true
         }
      }, 'Brand created')
   }

   return <Page>
      <PageHeader title={"Brands"}>
         {isAdministrator && <Button variant={"outlined"} onClick={onCreateBrand}>Create brand</Button>}
      </PageHeader>
      <PageContent fullHeight>
         <DataTable stateId={"brands"} idField={"_id"} selections={false} columns={columns} rows={brands} commands={[]}/>
      </PageContent>
      <Dialog open={creating} onClose={onCancel}>
         <DialogContent>
            <QuickForm onButton={onButton} form={nameForm} data={{
               name: ''
            }}/>
         </DialogContent>
      </Dialog>
   </Page>
}