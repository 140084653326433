import {
   ButtonFragment,
   PanelFragment,
   ReturnItemFragment,
   TradeInLayoutFragment,
   TradeInLayoutInput,
   TypographyFragment,
   UploadPhotoFragment,
   useShopAppLayoutQuery,
   useShopAppLayoutUpdateMutation
} from "../../../Queries";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid, LinearProgress} from "@mui/material";
import * as React from "react";
import {FC} from "react";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {FormInputText} from "../../shop/wholesale/form/FormInputText";
import {FormInputColor} from "../../shop/wholesale/form/FormInputColor";
import {FormInputCheckbox} from "../../shop/wholesale/form/FormInputCheckbox";
import {FormInputNumber} from "../../shop/wholesale/form/FormInputNumber";
import SettingsHeader from "../SettingsHeader";
import {TitleText} from "../../../layout/Typography";
import {SavingUI} from "../../../SavingProvider";
import PageContent from "../../../layout/PageContent";
import {ShopTabs} from "../ShopTabs";


const addItemDefaults: ButtonFragment = {
   fontWeight: "Blank use default",
   fontSize: "Blank use default",
   fontFamily: "Blank use default",
   disabledButtonColor: "#C0C0C0",
   disabledTextColor: "#808080",
   textColor: "#FFFFFF",
   buttonColor: "#000000",
   border: "Blank use default"
}

const addItemBlankDefaults: ButtonFragment = {
   ...addItemDefaults,
   textColor: "#000000",
   buttonColor: "#FFFFFF",
}

const cartContinueButton: ButtonFragment = {
   fontWeight: "Blank use default",
   fontSize: "Blank use default",
   fontFamily: "Blank use default",
   disabledButtonColor: "#C0C0C0",
   disabledTextColor: "#C0C0C0",
   textColor: "#000000",
   buttonColor: "#000000",
   border: "Blank use default"
}

const frontPanelDefault: PanelFragment = {}

const h1Defaults: TypographyFragment = {
   fontFamily: "Leave blank to use global font",
   fontSize: "24px",
   fontWeight: "blank = default, 400 = normal, 500 = bold, 600 = ekstra bold",
   textColor: "#000000"
}

const h2Defaults: TypographyFragment = {
   fontFamily: "Leave blank to use global font",
   fontSize: "20px",
   fontWeight: "blank = default, 400 = normal, 500 = bold, 600 = ekstra bold",
   textColor: "#000000"
}

const textDefaults: TypographyFragment = {
   fontFamily: "Leave blank to use global font",
   fontSize: "16px",
   fontWeight: "blank = default, 400 = normal, 500 = bold, 600 = ekstra bold",
   textColor: "#000000"
}

const subtextDefaults: TypographyFragment = {
   fontFamily: "Leave blank to use global font",
   fontSize: "14px",
   fontWeight: "blank = default, 400 = normal, 500 = bold, 600 = ekstra bold",
   textColor: "#000000"
}

const uploadPhotoDefaults: UploadPhotoFragment = {
   border: "2px dashed #FF5376",
   disabledBorder: "2px dashed #e0e0e0",
   color: '#000000',
   disabledColor: '#e0e0e0'
}

const returnItemDefaults: ReturnItemFragment = {
   fontSize: "Blank to use global fontsize",
   fontFamily: "Blank to use global font",
   fontWeight: "Blank to use global weight",
   textColor: undefined,
   backgroundColor: undefined,
   border: "Blank to use global weight",
   scale: undefined
}

const AppLayoutPath = (brandId: string, targetId: string) => {
   return "/brands/" + brandId + "/settings/layout/" + targetId
}

export const AppLayout = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (active: string) => {
      navigate(AppLayoutPath(brandHouseId, active));
   };

   return <div>
      <SettingsHeader title={"Layout"}>
         <Button type="submit" form={"applayoutForm"} variant={"contained"}>Save</Button>
      </SettingsHeader>

      <ShopTabs
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={(value) => handleChange(value)}
         renderDefault={() => <AppLayoutInner brandHouseId={brandHouseId}/>}
         renderShop={(shop) => <AppLayoutInner shopId={shop}/>}
      />
   </div>
}

type AppLayoutInnerProps = {
   brandHouseId?: string
   shopId?: string
}

export const AppLayoutInner = ({brandHouseId, shopId}: AppLayoutInnerProps) => {
   const {data} = useShopAppLayoutQuery({
      variables: {
         brandHouseId: brandHouseId,
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })

   const [save] = useShopAppLayoutUpdateMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const onSubmit = async (data: TradeInLayoutInput) => {
      await SavingUI.process(async () => {
         await save({
            variables: {
               brandHouseId: brandHouseId,
               shopId: shopId,
               api: {
                  tradeIn2: data
               }
            },
            fetchPolicy: "no-cache"
         })
      }, 'Saved layout')
   }

   const layoutData = data.BrandSettings?.tradeIn2 as TradeInLayoutFragment;

   return <div>
      <AppLayoutEditor data={layoutData} onSave={onSubmit}/>
   </div>
}

type AppLayoutEditorProps = {
   data: TradeInLayoutFragment
   onSave: (data: TradeInLayoutFragment) => Promise<void>
}

const AppLayoutEditor: FC<AppLayoutEditorProps> = ({data, onSave}) => {
   const methods = useForm<TradeInLayoutFragment>({
      resolver: (values, context) => {
         const errors: any = {}
         return {
            values,
            errors: errors
         }
      },
      defaultValues: data
   });
   const {handleSubmit, control} = methods;

   const onSubmit = async (data: TradeInLayoutFragment) => {
      await onSave(data);
   }
   return <FormProvider {...methods}>
      <form id={"applayoutForm"} onSubmit={handleSubmit(onSubmit)}/>
      <PageContent>
         <TitleText type={"h2"}>Global settings</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <TitleText type={"h3"}>Fonts and styles</TitleText>
               <FormInputText name={"fontFamily"} control={control} label={"Main font (Arial)"}/>
               <FormInputColor name={"widgets.actionItemBorderColor"} control={control}
                               defaultColor={'#FF5376'}
                               label={"Icon colors (#FF5376)"}/>
               <FormInputCheckbox name={"disableIcons"} control={control} label={"Disable the use of most icons"}/>
               <FormInputColor name={"widgets.accessBoxBorder"} control={control}
                               defaultColor={'#E0E0E0'}
                               label={"Box borders (#E0E0E0)"}/>

            </Grid>
            <Grid item xs={6}>
               <ButtonEditor buttonProperty={"mainPanel.mainButton"} title={"Default buttons"}
                             defaults={addItemDefaults}/>
            </Grid>
         </Grid>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Typography</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <TypographyEditor title={"Main title typography"} property={"h1"} defaults={h1Defaults}/>
            </Grid>
            <Grid item xs={6}>
               <TypographyEditor title={"Sub title typography"} property={"h2"} defaults={h2Defaults}/>
            </Grid>
            <Grid item xs={6}>
               <TypographyEditor title={"Main text typography"} property={"text"} defaults={textDefaults}/>
            </Grid>
            <Grid item xs={6}>
               <TypographyEditor title={"Sub text typography"} property={"subtext"} defaults={subtextDefaults}/>
            </Grid>
         </Grid>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Colors</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <TitleText type={"h3"}>Default colors</TitleText>
               <FormInputColor name={"mainPanel.textColor"} control={control}
                               defaultColor={'#000000'}
                               label={"Default app text color (#000000)"}/>
               <FormInputColor name={"mainPanel.backgroundColor"} control={control}
                               defaultColor={'#ffffff'}
                               label={"Default app background color (#FFFFFF)"}/>
               <FormInputColor name={"widgets.actionItemBorderColor"} control={control}
                               defaultColor={'#FF5376'}
                               label={"Default action icon colors (#FF5376)"}/>
            </Grid>
            <Grid item xs={6}>
               <TitleText type={"h3"}>Frontpage - right side box colors</TitleText>
               <FormInputColor name={"contrastPanel.backgroundColor"} control={control}
                               defaultColor={'#063B91'}
                               label={"App contrast background color (#063B91)"}/>
               <FormInputColor name={"contrastPanel.textColor"} control={control}
                               defaultColor={'#FFFFFF'}
                               label={"App contrast text color (#FFFFFF)"}/>
            </Grid>
         </Grid>
      </PageContent>
      <PageContent>
         <TitleText type={"h2"}>Main tradeins page</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <ButtonEditor buttonProperty={"widgets.addItemButton"} title={"Add items button"}
                             defaults={addItemDefaults}/>
               <ButtonEditor buttonProperty={"widgets.addItemButtonBlank"} title={"Add items button (no tradeins)"}
                             defaults={addItemBlankDefaults}/>
               <FormInputCheckbox name={"widgets.addItemIconHide"} control={control}
                                  label={"Disable the red-icon on add-item button"}/>
               <FormInputColor name={"widgets.addItemIconColor"} control={control}
                               defaultColor={'#FF5376'}
                               label={"The red-icons color (#FF5376)"}/>
               <FormInputText name={"widgets.stepperBorderWidth"} control={control}
                              label={"Border width for steppers (3px)"}/>
            </Grid>
            <Grid item xs={6}>
               <ReturnItemEditor title={"Trade-in item"} property={"widgets.returnItem"}
                                 defaults={returnItemDefaults}/>
            </Grid>
            <Grid item xs={6}>
            </Grid>
         </Grid>
      </PageContent>

      <PageContent>
         <TitleText type={"h2"}>Wholesale add-items dialog</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <TitleText type={"h3"}>Category selection</TitleText>
               <FormInputColor name={"widgets.selectionColor"} control={control}
                               defaultColor={'#9DF7E5'}
                               label={"Selection Background Color (#9DF7E5)"}/>
               <FormInputColor name={"widgets.selectionTextColor"} control={control}
                               defaultColor={'#000000'}
                               label={"Selection Text Color (#000000)"}/>
               <FormInputColor name={"widgets.categoryBorderColor"} control={control}
                               defaultColor={'#FF5376'}
                               label={"Category Border Color (#FF5376)"}/>
               <FormInputText name={"widgets.categoryRadius"} control={control}
                              label={"Category Border Radius (0 = Square, 50% = Circle)"}/>
               <FormInputNumber name={"widgets.categoryIconSize"} control={control}
                                label={"Category Icon size (Default 55)"}/>
               <FormInputNumber name={"widgets.categoryIconPadding"} control={control}
                                label={"Category Padding (Default 10)"}/>
            </Grid>
            <Grid item xs={6}>
               <UploadPhotoEditor title={"Upload photo box"} property={"widgets.uploadPhoto"}
                                  defaults={uploadPhotoDefaults}/>
            </Grid>
         </Grid>

      </PageContent>

      <PageContent>
         <TitleText type={"h2"}>Bottom cart, selected tradeins</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={6}>
               <TitleText type={"h3"}>Cart colors</TitleText>
               <FormInputColor name={"widgets.cartBackgroundColor"} control={control}
                               defaultColor={'#F4E4BA'}
                               label={"Cart Background Color (#F4E4BA)"}/>
               <FormInputColor name={"widgets.cartTextColor"} control={control}
                               defaultColor={'#000000'}
                               label={"Cart Text Color (#000000)"}/>
            </Grid>
            <Grid item xs={6}>
               <ButtonEditor buttonProperty={"widgets.continueButton"} title={"Continue button"}
                             defaults={cartContinueButton}/>
            </Grid>
         </Grid>
      </PageContent>

      <PageContent>
         <TitleText type={"h2"}>Panels</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={12}>
               <PanelEditor title={"Frontpage panel"} property={"frontPanel"} defaults={frontPanelDefault}/>
            </Grid>
         </Grid>
      </PageContent>

      <PageContent>
         <TitleText type={"h2"}>Advanced</TitleText>
         <Grid container spacing={5}>
            <Grid item xs={12}>
               <FormInputText name={"additionalCss"} control={control}
                              multiline
                              label={"Additional Css (Will be included inside <style></style>)"}/>
            </Grid>
         </Grid>
      </PageContent>
   </FormProvider>

}

const PanelEditor: FC<{ title: string, property: string, defaults: PanelFragment }> = (
   {
      title,
      property,
      defaults
   }) => {
   const {control} = useFormContext(); // retrieve all hook methods

   return <div>
      <TitleText type={"h2"}>{title}</TitleText>
      <TitleText type={"h3"}>Colors</TitleText>
      <FormInputColor name={property + ".textColor"} control={control}
                      defaultColor={'#000000'}
                      label={"Text color (#000000)"}/>
      <FormInputColor name={property + ".backgroundColor"} control={control}
                      defaultColor={'#ffffff'}
                      label={"Background color (#FFFFFF)"}/>
      <ButtonEditor buttonProperty={property + ".mainButton"} title={"Default buttons"}
                    defaults={addItemDefaults}/>
      <ButtonEditor buttonProperty={property + ".secondaryButton"} title={"Secondary buttons"}
                    defaults={addItemDefaults}/>
   </div>
}

const TypographyEditor: FC<{ title: string, property: string, defaults: TypographyFragment }> = (
   {
      title,
      property,
      defaults
   }) => {
   const {control} = useFormContext(); // retrieve all hook methods

   return <div>
      <TitleText type={"h3"}>{title}</TitleText>
      <FormInputText name={property + ".fontFamily"} control={control}
                     label={"Font family (" + defaults.fontFamily + ")"}/>
      <FormInputText name={property + ".fontSize"} control={control} label={"Font size (" + defaults.fontSize + ")"}/>
      <FormInputText name={property + ".fontWeight"} control={control}
                     label={"Boldness (" + defaults.fontWeight + ")"}/>
      <FormInputColor name={property + ".textColor"} control={control}
                      defaultColor={defaults.textColor!}
                      label={`Text Color (${defaults.textColor}})`}/>
      <FormInputCheckbox name={property + ".uppercase"} control={control} label={"Uppercase"}/>
   </div>
}

const ButtonEditor: FC<{ title: string, buttonProperty: string, defaults: ButtonFragment }> = (
   {
      title,
      buttonProperty,
      defaults
   }) => {
   const {control} = useFormContext(); // retrieve all hook methods

   return <div>
      <TitleText type={"h3"}>{title}</TitleText>
      <FormInputColor name={buttonProperty + ".textColor"} control={control}
                      defaultColor={defaults.textColor!}
                      label={`Text Color (${defaults.textColor})`}/>
      <FormInputColor name={buttonProperty + ".buttonColor"} control={control}
                      defaultColor={defaults.buttonColor!}
                      label={`Button Color (${defaults.buttonColor})`}/>
      <FormInputColor name={buttonProperty + ".disabledTextColor"} control={control}
                      defaultColor={defaults.disabledTextColor!}
                      label={`Disabled Text Color (${defaults.disabledTextColor})`}/>
      <FormInputColor name={buttonProperty + ".disabledButtonColor"} control={control}
                      defaultColor={defaults.disabledButtonColor!}
                      label={`Disabled Button Color (${defaults.disabledButtonColor})`}/>
      <FormInputColor name={buttonProperty + ".hoverTextColor"} control={control}
                      defaultColor={defaults.hoverTextColor!}
                      label={`Hover Text Color (${defaults.hoverTextColor})`}/>
      <FormInputColor name={buttonProperty + ".hoverButtonColor"} control={control}
                      defaultColor={defaults.hoverButtonColor!}
                      label={`Hover Button Color (${defaults.hoverButtonColor})`}/>
      <FormInputText name={buttonProperty + ".fontFamily"} control={control}
                     label={"Font family (" + defaults.fontFamily + ")"}/>
      <FormInputText name={buttonProperty + ".fontSize"} control={control}
                     label={"Font size (" + defaults.fontSize + ")"}/>
      <FormInputText name={buttonProperty + ".fontWeight"} control={control}
                     label={"Font weight (" + defaults.fontWeight + ")"}/>
      <FormInputText name={buttonProperty + ".border"} control={control}
                     label={"Border (" + defaults.border + ")"}/>
   </div>
}


const UploadPhotoEditor: FC<{ title: string, property: string, defaults: UploadPhotoFragment }> = (
   {
      title,
      property,
      defaults
   }) => {
   const {control} = useFormContext(); // retrieve all hook methods

   return <div>
      <TitleText type={"h3"}>{title}</TitleText>
      <FormInputColor name={property + ".color"} control={control}
                      defaultColor={'#FF5376'}
                      label={`Photo icon text color (${defaults.color}})`}/>
      <FormInputColor name={property + ".disabledColor"} control={control}
                      defaultColor={defaults.disabledColor!}
                      label={`Disabled color (${defaults.disabledColor}})`}/>
      <FormInputText name={property + ".border"} control={control}
                     label={"Border (" + defaults.border + ")"}/>
      <FormInputText name={property + ".disabledBorder"} control={control}
                     label={"Disabled border (" + defaults.disabledBorder + ")"}/>
   </div>
}


const ReturnItemEditor: FC<{ title: string, property: string, defaults: ReturnItemFragment }> = (
   {
      title,
      property,
      defaults
   }) => {
   const {control} = useFormContext(); // retrieve all hook methods

   return <div>
      <TitleText type={"h3"}>{title}</TitleText>
      <FormInputColor name={property + ".textColor"} control={control}
                      defaultColor={defaults.textColor!}
                      label={`Text Color (${defaults.textColor}})`}/>
      <FormInputColor name={property + ".backgroundColor"} control={control}
                      defaultColor={defaults.backgroundColor!}
                      label={`Button Color (${defaults.backgroundColor}})`}/>
      <FormInputText name={property + ".fontFamily"} control={control}
                     label={"Font family (" + defaults.fontFamily + ")"}/>
      <FormInputText name={property + ".fontSize"} control={control}
                     label={"Font size (" + defaults.fontSize + ")"}/>
      <FormInputText name={property + ".fontWeight"} control={control}
                     label={"Font weight (" + defaults.fontWeight + ")"}/>
      <FormInputText name={property + ".border"} control={control}
                     label={"Border (" + defaults.border + ")"}/>
      <FormInputNumber name={property + ".scale"} control={control}
                       label={"Scale (" + defaults.scale + ")"}/>
   </div>
}
