import Page from "../../../../layout/Page";
import PageHeader from "../../../../layout/PageHeader";
import PageContent from "../../../../layout/PageContent";
import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback} from "react";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {StockItemsPagePath} from "../StockItemsPage";
import {Dialogs} from "../../../../DialogProvider";
import useStockItemDelete from "../hooks/useStockItemDelete";
import {StockItemImages} from "../StockItemImages";
import {ShoppingBag} from "@mui/icons-material";
import {useRecoilValue} from "recoil";
import {CurrentUserIsShop} from "../../../../atoms/CurrentUser";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress} from "@mui/material";
import {ProductsList} from "../../components/ProductList";
import {PubSub} from "../../../../components/PubSub";
import {
   InventoryPageLocationsDocument,
   ProductVariantSize,
   Season,
   StockItemsListManyDocument,
   useStockItemFormBrandSettingQuery,
   useStockItemFormStockItemByIdQuery,
   useStockItemFormStockItemUpdateMutation,
   ViewProductVariantFragment
} from "../../../../Queries";
import {TitleText} from "../../../../layout/Typography";
import {getStockItemInput, StockItemEditForm, StockItemFormData} from "./StockItemEditForm";
import {SaveButton} from "../../../../components/SaveButton";
import {ConsumerPagePath} from "../../masterdata/consumers/ConsumerPage";
import {TradeInPagePath} from "../../tradeins/TradeInPage";
import {OrderPagePath} from "../../orders/OrderPage";
import {SavingUI} from "../../../../SavingProvider";
import {Dirty, DirtyDetector} from "../../../../components/DirtyDetector";
import {BrandItemPagePath} from "../../../brand/BrandItemPage";

export const StockItemEditPagePath = (brandHouseId: string, shopId: string, StockItemId: string) => {
   return "/brands/" + brandHouseId + "/" + shopId + "/stock/" + StockItemId + '/edit';
}

export const StockItemEditPage = () => {
   const {
      brandHouseId,
      shopId,
      stockItemId
   } = useParams<{ brandHouseId: string, shopId: string, stockItemId: string }>();

   const [updating, setUpdating] = React.useState<string | undefined>();

   const deleteStockItem = useStockItemDelete();
   const {data: stockItem} = useStockItemFormStockItemByIdQuery({
      variables: {
         stockItemId: stockItemId
      },
      fetchPolicy: "no-cache"
   });
   const {data: settings} = useStockItemFormBrandSettingQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   });
   const [saveStockItem] = useStockItemFormStockItemUpdateMutation()

   const navigate = useNavigate();
   const isBrandHouse = useRecoilValue(CurrentUserIsShop);

   const onNavigate = useCallback((to: 'order' | 'consumer' | 'tradein', id: any) => {
      if (to === 'consumer') {
         navigate(ConsumerPagePath(brandHouseId, shopId, id));
      } else if (to === 'tradein') {
         navigate(TradeInPagePath(brandHouseId, shopId, id));
      } else if (to === 'order') {
         navigate(OrderPagePath(brandHouseId, shopId, id));
      } else {
         alert('Unknown target')
      }
   }, [brandHouseId, shopId, navigate])

   const onSubmit = useCallback(async (data: StockItemFormData) => {
      await SavingUI.process(async () => {
         let refetchQueries: any[] = [{
            query: StockItemsListManyDocument,
            variables: {
               shopId: shopId
            }
         }, 'StockItemPagination'];
         if (!isBrandHouse) {
            refetchQueries.push({
               query: InventoryPageLocationsDocument
            });
         }
         await saveStockItem({
            variables: {
               shopId: shopId,
               stockItem: getStockItemInput(data)
            },
            refetchQueries: refetchQueries
         });
         Dirty.reset();
      }, 'Stock item was saved');
   }, [shopId, isBrandHouse, saveStockItem])

   if (!stockItem?.StockItemById || !settings?.BrandSettings) {
      return <LinearProgress/>
   }

   const tags = stockItem?.StockItemById?.shop?.productTags || []
   const types = stockItem?.StockItemById?.shop?.productTypes || []

   const edit: StockItemFormData = {
      ...stockItem.StockItemById!,
      missingB: stockItem.StockItemById?.effectiveMissing,
      productStatus:
         (stockItem.StockItemById!.itemStatus === 'DRAFT') ? 'draft' :
            (stockItem.StockItemById!.itemStatus === 'SALE') ? 'sale' :
               (stockItem.StockItemById!.itemStatus === 'REVIEW') ? 'review' : 'none'
   }

   const onViewProduct = () => {
      if (edit) {
         if (isBrandHouse) {
            window.location.assign(edit.externUrl!);
         } else {
            window.open(edit.externUrl!, '_blank');
         }
      }
   }
   const onViewStockItem = () => {
      if (edit) {
         navigate(BrandItemPagePath(brandHouseId, edit.resolved?.brandItemId, false));
      }
   }

   const onDelete = async () => {
      Dialogs.confirm({
         title: "Are you sure you wish to delete this stockitem?",
         subtitle: "You will not be able to undo this action, and the potentially associated trade-in item will be available to processing again."
      }, async () => {
         await deleteStockItem(shopId, stockItemId);
         navigate(StockItemsPagePath(brandHouseId, shopId));
      })
   }

   const onCancel = () => {
      setUpdating(undefined);
   }

   const onCopyProduct = async (variant: ViewProductVariantFragment, size: Partial<ProductVariantSize>) => {
      PubSub.emit('UpdateProduct', {
         variant: variant,
         size: size
      });
      setUpdating(undefined);
   }

   if (edit.brandItemId) {
      return <Page>
         <PageHeader title={edit.resolved?.title || 'Stock Item'} onBack={true}/>
         <PageContent>
            <Grid item container>
               <Button disabled={!edit.externUrl} color={"secondary"} onClick={onViewProduct} startIcon={<ShoppingBag/>}
                       variant={"text"}>
                  View in shop
               </Button>
               <Button disabled={!edit.externUrl} color={"secondary"} onClick={onViewStockItem}
                       startIcon={<ShoppingBag/>}
                       variant={"text"}>
                  View stock item
               </Button>
            </Grid>
         </PageContent>
      </Page>
   }

   return <Page>
      <PageHeader title={'Edit'} onBack={true}>
         <SaveButton saveMessage={"Saved"} variant={"contained"} type={"submit"} form={"stockItemForm"}>
            Save
         </SaveButton>
      </PageHeader>
      <StockItemEditForm data={edit}
                         onSubmit={onSubmit}
                         settings={settings.BrandSettings}
                         onNavigate={onNavigate}
                         currency={edit.shop?.currency || 'DKK'}
                         onSearch={(search) => setUpdating(search || '')}
                         tags={tags}
                         productTypes={types}
                         primaryLocale={edit.shop?.locales?.[0] || 'da'}
                         locales={(settings.BrandSettings?.locales?.filter(locale => locale!.activeItems)
                            .map(locale => locale!.locale) as string[]) || []}
                         seasons={(settings?.ShopById?.seasons || []) as Season[]}
      />
      <DirtyDetector/>
      <PageContent style={{marginTop: '20px'}}>
         <TitleText type={"h2"}>Images</TitleText>
         <StockItemImages shopId={shopId} stockItemId={stockItemId}/>
      </PageContent>
      <Dialog open={updating !== undefined} onClose={onCancel} fullWidth maxWidth={"lg"}>
         <DialogTitle>Select product to copy</DialogTitle>
         <DialogContent>
            <ProductsList hidepreowned={true} shopId={shopId} onVariantSelect={onCopyProduct} defaultSearch={updating} inDialog/>
         </DialogContent>
         <DialogActions>
            <Button variant={"outlined"} onClick={onCancel}>Cancel</Button>
         </DialogActions>
      </Dialog>
      <PageContent>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button color={"secondary"} onClick={onViewProduct} startIcon={<ShoppingBag/>} variant={"text"}>
               View in shop
            </Button>
            <Button color={"secondary"} onClick={onDelete} startIcon={<DeleteForeverIcon/>} variant={"text"}>
               Delete
            </Button>
         </div>
      </PageContent>
   </Page>
}
