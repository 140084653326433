import {gql, useMutation} from "@apollo/client";

export const qlStockItemDelete = gql`
    mutation StockItemDelete($shopId: String!, $stockItemId: String!) {
      StockItemDelete(shopId:$shopId, stockItemId: $stockItemId) {
         _id
         tradeInItemId
      }
    }
`;

const useStockItemDelete = () => {
   const [stockItemCreate] = useMutation(qlStockItemDelete);

   return (shopId: string, stockItemId: string) => {
      return stockItemCreate({
         variables: {
            shopId: shopId,
            stockItemId: stockItemId
         },
         update(cache, {data}) {
            cache.evict(data.StockItemDelete._id);
            if(data.StockItemDelete.tradeInItemId) {
               cache.evict(data.StockItemDelete.tradeInItemId);
            }
         }
      }).then(data => data.data.StockItemDelete?._id)
   }
}

export default useStockItemDelete;