import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {
   BtnBold,
   BtnBulletList,
   BtnClearFormatting,
   BtnItalic,
   BtnNumberedList,
   BtnRedo,
   BtnStyles,
   BtnUnderline,
   BtnUndo,
   Editor,
   EditorProps,
   EditorProvider,
   HtmlButton,
   Separator,
   Toolbar
} from 'react-simple-wysiwyg';

export type FormInputHtmlProps = Omit<EditorProps, 'name' | 'onChange'> & {
   name: string;
   label: string;
   errorMessage?: string
   height?: string;
   toolbarButton?: JSX.Element | JSX.Element[]
}

export const FormInputHtml = ({
                                 name,
                                 height,
                                 placeholder,
                                 label,
                                 toolbarButton,
                                 children,
                                 disabled,
                                 ...rest
                              }: FormInputHtmlProps) => {
   const methods = useFormContext();
   const {control} = methods;

   const strippedPlaceholder = React.useMemo(() => getTextContentOnly(placeholder), [placeholder]);
   const [editorKey, setEditorKey] = React.useState(0);

   React.useEffect(() => {
      setEditorKey(prevKey => prevKey + 1);
   }, [name, placeholder]);

   return (
      <div title={strippedPlaceholder}>
         <Controller
            name={name}
            control={control}
            render={({
                        field: {onChange, value}
                     }) => {
               return <EditorProvider>
                  <Editor key={editorKey} disabled={disabled} tabIndex={0} style={{
                     height: height,
                     overflow: 'auto',
                     color: disabled ? '#e0e0e0' : 'inherit'
                  }} value={value} onChange={onChange} placeholder={strippedPlaceholder} {...rest}>
                     <Toolbar>
                        <BtnUndo/>
                        <BtnRedo/>
                        <Separator/>
                        <BtnBold/>
                        <BtnItalic/>
                        <BtnUnderline/>
                        <Separator/>
                        <BtnNumberedList/>
                        <BtnBulletList/>
                        <Separator/>
                        <BtnClearFormatting/>
                        <HtmlButton/>
                        <Separator/>
                        <BtnStyles/>
                        {toolbarButton || null}
                     </Toolbar>
                  </Editor>
                  {children}
               </EditorProvider>
            }}
         />
      </div>
   );
};

const getTextContentOnly = (html?: string) => {
   if (!html) {
      return html
   }
   const doc = new DOMParser().parseFromString(html, 'text/html');
   const walker = document.createTreeWalker(
      doc.body,
      NodeFilter.SHOW_TEXT,
      null
   );
   const texts = [];
   let node;
   do {
      if (node) {
         texts.push(node.nodeValue);
      }
      node = walker.nextNode();
   } while (!!node)

   return texts.join(' ');
}
