import * as React from "react";
import {Grid, Hidden} from "@mui/material";
import {BodyText, TitleText} from "./Typography";

export type SettingsGroupProps = {
   title: String,
   description: String,
   children: React.ReactNode | React.ReactNode[] | null;

}
export const SettingsGroup = (props: SettingsGroupProps) => {
   return (
      <Grid container item xs={12}
            style={{
               borderTop: '1px solid #e0e0e0',
               paddingTop: '30px',
               marginBottom: '30px'
            }}>
         <Hidden xlDown={true}>
            <Grid item xs={12} lg={4}>
               <div style={{marginRight: 40}}>
                  <TitleText style={{marginBottom: '10px'}} type={"h3"}>{props.title}</TitleText>
                  <BodyText type={"subtitle1"}>{props.description}</BodyText>
               </div>
            </Grid>
         </Hidden>
         <Grid item xs={12} lg={8}>
            <Hidden lgUp={true}>
               <div style={{marginBottom: 30}}>
                  <TitleText style={{marginBottom: '10px'}} type={"h3"}>{props.title}</TitleText>
                  <BodyText type={"subtitle1"}>{props.description}</BodyText>
               </div>
            </Hidden>
            <Hidden lgDown={true}>
               <TitleText style={{marginBottom: '10px'}} type={"h3"}>{props.title}</TitleText>
               <BodyText type={"subtitle1"}>{props.description}</BodyText>
               <br/>
            </Hidden>
            <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'center'}}>
               {props.children}
            </div>
         </Grid>
      </Grid>
   );
}