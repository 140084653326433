import * as React from "react";
import {FC, useCallback} from "react";
import {DateFormat} from "../../../utility/DateFormat";
import {
   EnumPotentialNotified,
   Potential,
   SortFindManyPotentialInput,
   usePotentialsListPaginationLazyQuery
} from "../../../Queries";
import {FlexList} from "../../../components/flexlist/FlexList";
import {FlexListColumn} from "../../../components/flexlist/FlexTypes";

type PotentialsListProps = {
   shopId: string
   consumerId?: string
   hideTradeIns?: boolean
   onPotentialSelect: (potential: Potential) => void
   stateKey?: string
   searchable?: boolean
}

export const PotentialsList: FC<PotentialsListProps> = ({
                                                           shopId,
                                                           consumerId,
                                                           hideTradeIns,
                                                           searchable,
                                                           onPotentialSelect,
                                                           stateKey
                                                        }) => {
   const [fetchMore] = usePotentialsListPaginationLazyQuery()

   let columns: FlexListColumn<any>[] = [{
      id: 'date',
      label: 'Date',
      field: 'externDate',
      render: (dateStr) => {
         return DateFormat.toPresent(new Date(dateStr))
      },
   }, {
      id: 'consumer',
      label: 'Consumer',
      get: (pot) => {
         if (pot.consumer) {
            return pot.consumer.firstName + ' ' + pot.consumer.lastName
         } else {
            return 'Unknown';
         }
      }
   }, {
      id: 'name',
      field: 'name',
      label: 'Name'
   }, {
      id: 'sp',
      label: 'Salesprice',
      field: 'sp'
   }, {
      id: 'rrp',
      label: 'RRP',
      field: 'rrp'
   }, {
      id: 'offer',
      label: 'Offer',
      field: '_id',
      render: (offer, row) => {
         if(row?.offer?.value) {
            return row?.offer?.value + ' ' + row?.offer?.currency
         } else {
            return ""
         }
      }
   }, {
      id: 'notification',
      label: 'Marketing mail',
      field: 'notified',
      render: (notified, row) => {
         if(notified === EnumPotentialNotified.NOTSENT) {
            if(row.offer.notifyDate) {
               return DateFormat.toPresent(row.offer.notifyDate)
            } else {
               return ""
            }
         } else if(notified === EnumPotentialNotified.SENT) {
            return "Sent " + DateFormat.timeSince(row.notifiedTs)
         } else {
            return "Not sending"
         }
      }
   }]


   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyPotentialInput) || SortFindManyPotentialInput._ID_DESC,
            perPage: 20,
            search: search,
            filter: {
               consumerId: consumerId,
               shopId: shopId,
               ...filter
            }
         }
      });

      return {
         data: data?.PotentialsPagination?.items || [],
         totalCount: data?.PotentialsPagination?.count || 0
      }
   }, [fetchMore, consumerId, shopId]);


   return <FlexList
      globalId={"potentialsListComponent"}
      columns={columns}
      onRequestData={onRequestData}
      onSelectRow={row => onPotentialSelect(row as Potential)}
      itemsPerPage={20}
   />
}
