import {Card, CardActions, LinearProgress} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import {FileDrop} from "react-file-drop";
import Button from "@mui/material/Button";
import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {DragEvent as ReactDragEvent, FC} from "react";
import withStyles from "@mui/styles/withStyles";
import {Theme} from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import {UploadServiceType} from "./ImageUpload";

type UploadImageProps = {
   id: string
   addImages: (imageIds: string[] | string) => void;
   uploadService: UploadServiceType
   size?: number
   children?: React.ReactElement
}

export const UploadImage:FC<UploadImageProps> = ({id, addImages, uploadService, size, children}) => {
   const [progress, setProgress] = React.useState<number | null>(null);
   const buttonRef = React.useRef<HTMLButtonElement | null>(null);
   const classes = useImageStyles();

   const onDrop = async (files: FileList | null, event: ReactDragEvent<HTMLDivElement>) => {
      await upload(files);
   }

   const upload = async (files: FileList | null) => {
      if (!files || files.length===0) {
         return;
      }
      let length = files?.length;
      let images: string[] = [];
      for (let i = 0; i < (length || 0); i++) {
         let file = files.item(i);
         setProgress(1);
         let axiosResponse = await uploadService.upload(file!, event1 => {
            setProgress(Math.min(100, Math.round(((event1.loaded * 100) / (event1.total||1)))));
            if (event1.loaded === event1.total) {
               setTimeout(() => {
                  setProgress(null);
               }, 700);
            }
         });
         if (axiosResponse && axiosResponse.success) {
            images.push(axiosResponse.imageId);
         } else {
            console.log(axiosResponse);
         }
      }
      addImages(images);
   }

   const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      await upload(event.target?.files || null);
   }

   const onClickImage = () => {
      if (buttonRef && buttonRef.current) {
         buttonRef.current.click();
      }
   }
   const btnId = 'btn-' + id;

   return <Card className={classes.root} variant={"outlined"}>
      <CardContent>
         <CardActionArea>
            <FileDrop onDrop={onDrop} dropEffect={"link"}>
               {!!progress && <div
                  style={{
                     position: 'absolute',
                     top: '25px',
                     left: '15px',
                     right: '15px',
                     height: '10px',
                     zIndex: 99
                  }}>
                  <BorderLinearProgress variant={"determinate"} value={progress || 0}/>
               </div>}
               <div style={{
                  backgroundImage: 'url("https://storageaccountourimbea2.blob.core.windows.net/web/placeholder-image.png")',
                  backgroundSize: 'fit',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  height: size?size + 'px':undefined
               }}
                    className={classes.media}
                    onClick={onClickImage}
                    title={"Upload"}
               >&nbsp;</div>
            </FileDrop>
         </CardActionArea>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
         <label htmlFor="btn-upload">
            <input
               id={btnId}
               name="btn-upload"
               style={{display: 'none'}}
               type="file"
               onChange={selectFile}/>
            <Button
               size={"medium"}
               ref={buttonRef}
               className={classes.button}
               variant="outlined"
               component="span"
               onClick={() => document.getElementById(btnId)?.click()}
            >
               {"Upload"}
            </Button>
         </label>
         {children}
      </CardActions>
   </Card>
}


const useImageStyles = makeStyles({
   container: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-7px',
      marginRight: '-7px'
   },
   uploadContainer: {},
   root: {
      width: 245,
      marginBottom: '15px',
      marginLeft: '7px',
      marginRight: '7px',
      position: 'relative',
      borderColor: '#f0f0f0'
   },
   media: {
      height: '200px',
   },
   button: {
      "&:hover": {
         backgroundColor: '#F0F0F0'
      },
      "&:disabled": {
         color: 'white',
         backgroundColor: '#c0c0c0'
      }
   },
   topCard: {
      height: '10px',
      padding: '0',
      textAlign: 'center'
   },
   topCardLink: {
      color: '#a0a0a0',
      cursor: 'pointer',
      userSelect: 'none'
   }
});

const BorderLinearProgress = withStyles((theme: Theme) =>
   createStyles({
      root: {
         height: 10,
         borderRadius: 5,
      },
      colorPrimary: {
         backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
      },
      bar: {
         borderRadius: 5,
         backgroundColor: '#a0a0a0',
      },
   }),
)(LinearProgress);
