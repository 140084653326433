import * as React from "react";
import {FC} from "react";
import {
   FilterFindManyProductVariantInput,
   ProductVariantSize,
   SortFindManyProductVariantInput,
   useViewProductVariantsManyLazyQuery,
   useViewProductVariantsSizesQuery,
   ViewProductVariantFragment
} from "../../../Queries";
import {Thumbnails} from "../../../components/Thumbnails";
import {FlexList} from "../../../components/flexlist/FlexList";
import {FlexFilter, FlexFilterOption, FlexListColumn, FlexSort, FlexTab} from "../../../components/flexlist/FlexTypes";
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {TitleText} from "../../../layout/Typography";
import {WarningAmber} from "@mui/icons-material";

type ProductsListProps = {
   shopId: string
   onVariantSelect: (variant: ViewProductVariantFragment, size: Partial<ProductVariantSize>) => void
   defaultSearch?: string
   inDialog?: boolean
   hidepreowned?: boolean
}

const PAGE_SIZE = 20;

export const ProductsList: FC<ProductsListProps> = ({shopId, onVariantSelect, inDialog, hidepreowned}) => {
   const [selected, setSelected] = React.useState<ViewProductVariantFragment | undefined>(undefined);
   const [fetchMore] = useViewProductVariantsManyLazyQuery();

   const {data} = useViewProductVariantsSizesQuery({
      variables: {
         shopId: shopId
      }
   });

   let columns: FlexListColumn<ViewProductVariantFragment>[] = [{
      id: 'image',
      label: "Image",
      get: (product) => {
         return product._id
      },
      render: (id, product) => {
         let imageUrls = product?.imageUrls || [];
         return <Thumbnails size={50} imageUrls={imageUrls.filter(img => !!img) as string[]} maxImages={1}/>
      },
      width: 5
   }, {
      id: 'name',
      field: '_id',
      label: "Name",
      width: 20,
      render: (data, row) => {
         if(row?.product?.isPreOwned && hidepreowned) {
            return <Box title={"Do not select"}>{row?.product?.name} <WarningAmber color={"warning"} fontSize={"small"}/></Box>
         } else {
            return row?.product?.name
         }
      }
   }, {
      id: 'sku',
      label: "SKU",
      field: 'sku',
      width: 15
   }, {
      id: 'size',
      label: "Size",
      field: '_id',
      width: 15,
      render: (data, row) => {
         return row?.sizes?.map(size => size?.size).join(', ')
      }
   }, {
      id: 'color',
      label: "Color",
      field: 'color',
      width: 10
   }, {
      id: 'ean',
      label: "EAN",
      field: 'ean',
      width: 10
   }, {
      id: 'productType',
      label: "Type",
      field: 'productType',
      width: 10
   }]

   const sortSpec: FlexSort<SortFindManyProductVariantInput>[] = [{
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyProductVariantInput._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyProductVariantInput._ID_DESC
   }]

   const sizeOptions: FlexFilterOption<FilterFindManyProductVariantInput>[] = data?.ShopById?.sizes?.map(size => ({
      label: size!,
      filter: {
         size: size
      }
   })) || []

   const colorOptions: FlexFilterOption<FilterFindManyProductVariantInput>[] = data?.ShopById?.colors?.map(color => ({
      label: color!,
      filter: {
         color: color
      }
   })) || []

   let productBatches = data?.ShopById?.productBatches?.filter(batch => batch !== 'fixedByScript');

   const batchOptions: FlexFilterOption<FilterFindManyProductVariantInput>[] = productBatches?.map(batch => ({
      label: batch!,
      filter: {
         batchSerial: batch
      }
   })) || []

   const filterSpec: FlexFilter<FilterFindManyProductVariantInput>[] = [{
      id: 'size',
      label: 'Size',
      options: sizeOptions
   }, {
      id: 'color',
      label: 'Color',
      options: colorOptions
   }, {
      id: 'batch',
      label: 'Data source',
      options: batchOptions
   }]

   const tabsSpec: FlexTab[] = [{
      id: 'all',
      label: 'All',
      filters: []
   }];

   const tabsBatch: FlexTab[] = productBatches?.map(batch => ({
      id: 't_' + batch,
      label: batch,
      filters: [{
         filterId: 'batch',
         values: [batch]
      }]
   })) || []

   if (tabsBatch.length > 3) {
      tabsSpec.push(...tabsBatch.slice(0, 3));
   } else {
      tabsSpec.push(...tabsBatch);
   }

   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const effectiveFilter: FilterFindManyProductVariantInput = {
         ...filter,
         _operators: {
            shopId: {
               ...!!shopId ? {in: [shopId]} : {ne: '610960718a538200332fa5cc'}
            },
         }
      }

      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyProductVariantInput) || SortFindManyProductVariantInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: effectiveFilter
         }
      });
      return {
         data: data?.ProductVariantsPagination?.items || [],
         totalCount: data?.ProductVariantsPagination?.count || 0
      }
   }

   const onSelectProduct = (variant: ViewProductVariantFragment) => {
      if(hidepreowned && variant.product?.isPreOwned) {
         return;
      }
      setSelected(variant);
   }

   return <Box>
      {selected && <Dialog open={true} onClose={() => setSelected(undefined)} fullWidth={true} maxWidth={"sm"}>
         <DialogTitle sx={{display: 'flex', flexDirection: 'row'}}>
            <TitleText type={"h1"}>Select size</TitleText>
         </DialogTitle>
         <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
               {selected.sizes?.map(size => {
                  return <Button key={"size_" + size?._id} variant={"outlined"} onClick={() => onVariantSelect(selected, size!)}>{size?.size}</Button>
               })}
            </Box>
         </DialogContent>
      </Dialog>}
      <FlexList
         selections={true}
         globalId={"viewProductVariantList"}
         tabs={tabsSpec}
         itemsPerPage={PAGE_SIZE}
         columns={columns}
         onRequestData={onRequestData}
         filters={filterSpec}
         sorts={sortSpec}
         options={{
            itemHeight: 50,
            height: inDialog ? 500 : undefined
         }}
         onSelectRow={onSelectProduct}
      /></Box>
}
