import {FlexCommand, FlexFilter, FlexListColumn, FlexSort, FlexTab} from "../../../components/flexlist/FlexTypes";
import {
   FilterFindManyStockItemInput,
   FlexStockItemFragment,
   SortFindManyStockItemInput,
   useStockItemListBrandsQuery,
   useStockItemsListManyLazyQuery
} from "../../../Queries";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {getStockItemColumns, ShowingType, StockItemColumnsProps} from "./StockItemsColumns";
import {FlexList} from "../../../components/flexlist/FlexList";

type StockItemsListProps = {
   listId?: string
   shopId?: string
   onSelectItem: (stockItem: FlexStockItemFragment, newWindow?: boolean) => void
   commands?: FlexCommand<FlexStockItemFragment>[]
   currency?: string
   columnOptions?: StockItemColumnsProps
   extraTabs?: FlexTab[],
   additionalFilter?: any
}

const PAGE_SIZE = 20;

export const StockItemsList = ({
                                  onSelectItem,
                                  listId,
                                  shopId,
                                  currency,
                                  commands,
                                  columnOptions,
                                  extraTabs,
                                  additionalFilter
                               }: StockItemsListProps) => {
   const {data} = useStockItemListBrandsQuery();
   const [fetchMore] = useStockItemsListManyLazyQuery({
      fetchPolicy: "no-cache"
   });

   const columns: FlexListColumn<FlexStockItemFragment>[] = useMemo(
      () => getStockItemColumns(columnOptions || {}), [columnOptions]
   )

   const sortSpec: FlexSort<SortFindManyStockItemInput>[] = [{
      id: 'TitleAsc',
      label: 'Title A-Z',
      value: SortFindManyStockItemInput.TITLE_ASC
   }, {
      id: 'TitleDesc',
      label: 'Title Z-A',
      value: SortFindManyStockItemInput.TITLE_DESC
   }, {
      id: 'LocationAsc',
      label: 'Location A-Z',
      value: SortFindManyStockItemInput.STOCKLOCATION_ASC
   }, {
      id: 'LocationDesc',
      label: 'Location Z-A',
      value: SortFindManyStockItemInput.STOCKLOCATION_DESC
   }, {
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyStockItemInput.CREATEDAT_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyStockItemInput.CREATEDAT_DESC
   }, {
      id: 'UpdatedAsc',
      label: 'Updated (oldest first)',
      value: SortFindManyStockItemInput.UPDATEDAT_ASC
   }, {
      id: 'UpdatedDesc',
      label: 'Updated (newest first)',
      value: SortFindManyStockItemInput.UPDATEDAT_DESC
   }, {
      id: 'ItemNrDesc',
      label: 'Item Number (Highest first)',
      value: SortFindManyStockItemInput.ITEMNR_DESC
   }, {
      id: 'LocationTsDesc',
      label: 'Layaway',
      value: SortFindManyStockItemInput.STOCKLOCATIONTS_DESC
   }, {
      id: 'ReviewTsDesc',
      label: 'Reviewed',
      value: SortFindManyStockItemInput.REVIEWED_DESC
   }, {
      id: 'MissingDesc',
      label: 'Missing',
      value: SortFindManyStockItemInput.MISSING_DESC
   }]

   const filterSpec: FlexFilter<FilterFindManyStockItemInput>[] = [{
      id: 'status',
      label: 'Product state',
      options: [{
         label: 'Draft',
         filter: {
            draft: true,
            review: false,
            published: false,
            isSold: false
         }
      }, {
         label: 'Review',
         filter: {
            draft: false,
            review: true,
            published: false,
            isSold: false
         }
      }, {
         label: 'For sale',
         filter: {
            draft: false,
            review: false,
            published: true,
            isSold: false,
         }
      }, {
         label: 'Sold',
         filter: {
            isSold: true
         }
      }]
   }, {
      id: 'errors',
      label: 'Data state',
      options: [{
         label: 'Has problems',
         filter: {
            hasProblems: true,
            isSold: false
         }
      }, {
         label: 'Missing fields',
         filter: {
            hasErrors: true,
            isSold: false
         }
      }, {
         label: 'No errors',
         filter: {
            hasErrors: false,
            hasProblems: false,
            isSold: false
         }
      }]
   }];

   if (columnOptions?.brand) {
      let map = (data?.ShopMany || []).map(shop => {
         return {
            label: shop?.externalName || '',
            filter: {
               shopId: shop?._id
            }
         }
      });

      filterSpec.push({
         id: 'brands',
         label: 'Brands',
         options: map
      })
   }

   const tabsSpec: FlexTab[] = [{
      id: ShowingType.SALE,
      label: 'For sale',
      filters: [{
         filterId: 'status',
         values: ['For sale']
      }],
      columns: ['brand', 'id', 'image', 'title', 'sellPrice', 'beforePrice', 'stockLocation', 'stockLocationTs', 'status']
   }, {
      id: ShowingType.REVIEW,
      label: 'Need review',
      filters: [{
         filterId: 'status',
         values: ['Review']
      }],
      showCount: true,
      columns: ['brand', 'id', 'image', 'title', 'sku', 'sellPrice', 'beforePrice', 'originalEan', 'size', 'color']
   }, {
      id: ShowingType.SOLD,
      label: 'Sold',
      filters: [{
         filterId: 'status',
         values: ['Sold']
      }],
      columns: ['brand', 'id', 'image', 'title', 'sku', 'sellPrice', 'status']
   }, {
      id: ShowingType.ALL,
      label: 'All',
      filters: [],
      columns: ['brand', 'id', 'image', 'title', 'sku', 'size', 'color', 'sellPrice', 'stockLocation', 'status', 'createdAt']
   }];
   if (extraTabs) {
      tabsSpec.push(...extraTabs);
   }

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyStockItemInput) || SortFindManyStockItemInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: {
               shopId: shopId,
               ...filter,
               ...additionalFilter
            }
         }
      });
      const resultData = data?.StockItemPagination?.items?.map(item => item!.resolved!) || []
      return {
         data: resultData,
         totalCount: data?.StockItemPagination?.count || 0
      }
   }, [fetchMore, shopId, additionalFilter]);

   return <FlexList
      selections={true}
      globalId={listId || "stockItems"}
      tabs={tabsSpec}
      itemsPerPage={PAGE_SIZE}
      columns={columns}
      onRequestData={onRequestData}
      filters={filterSpec}
      sorts={sortSpec}
      options={{
         itemHeight: 70
      }}
      context={{
         currency: currency || '',
         shopId: shopId
      }}
      commands={commands}
      onSelectRow={onSelectItem}
   />
}
