import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {lighten, Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import {SearchField} from "./datatable/SearchField";
import {Button, CircularProgress} from "@mui/material";
import useInView from 'react-cool-inview';
import {debounce} from "lodash";

interface HeadCell {
   id: number;
   label: string;
   numeric: boolean;
   align?: 'left' | 'right' | 'center'
   disablePadding?: boolean;
   width?: string;
}

interface EnhancedTableProps {
   headCells: HeadCell[],
   classes: ReturnType<typeof useStyles>;
   numSelected: number;
   onRequestSort: (event: React.MouseEvent<unknown>, property: number) => void;
   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
   order: Order;
   orderBy: number;
   rowCount: number;
   selections: boolean;
   hideHeader?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
   const createSortHandler = (property: number) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            {props.selections && <TableCell padding="checkbox">
               <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{'aria-label': 'select all desserts'}}
               />
            </TableCell>}
            {props.headCells.map((headCell, idx) => (
               <TableCell
                  key={'headcol_' + headCell.id}
                  align={headCell.numeric ? 'right' : headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  style={{
                     borderBottom: props.hideHeader ? '0px solid transparent' : undefined,
                     paddingLeft: (props.selections ? 0 : (idx === 0 ? 10 : undefined))
                  }}
                  sortDirection={orderBy === headCell.id ? order : false}
                  width={headCell.width}
               > {!props.hideHeader &&
               <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
               >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                     <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                  ) : null}
               </TableSortLabel>
               }
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         display: 'flex',
         justifyContent: 'space-between',
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1)
      },
      highlight:
         theme.palette.mode === 'light'
            ? {
               color: theme.palette.secondary.main,
               backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
               color: theme.palette.text.primary,
               backgroundColor: theme.palette.secondary.dark,
            },
      title: {},
      commands: {
         display: 'flex',
         flexDirection: 'row',
         gap: '10px'
      }
   }),
);

interface EnhancedTableToolbarProps {
   search: string
   numSelected: number;
   commands?: DataCommand[],
   onExecute: (command: DataCommand) => Promise<void>
   onSearch: (search: string) => void
   searchable: boolean
   rows: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
   const [initialized, setInitialized] = useState<boolean>(false);
   const [executing, setExecuting] = useState<string>('');
   const [search, setSearch] = React.useState<string>(props.search)
   const classes = useToolbarStyles();
   const {numSelected} = props;

   const onSearch = (e: any) => {
      let rawValue = e.target?.value || '';
      setSearch(rawValue)
      props.onSearch(rawValue.toLowerCase());
   }

   const onExecute = async (cmd: DataCommand) => {
      setExecuting(cmd.id);
      await props.onExecute(cmd);
      setExecuting('');
   }

   useEffect(() => {
      if (!initialized) {
         if (search) {
            props.onSearch(search.toLowerCase());
         }
      }
      setInitialized(true);
      // eslint-disable-next-line
   }, [initialized])

   return (
      <Toolbar
         className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
         })}
      >
         {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
               {numSelected} selected
            </Typography>
         ) : <></>}
         {numSelected > 0 && props.commands &&
         <div className={classes.commands}>{props.commands.map(cmd => {
            return (
               <Tooltip title={cmd.title} key={'cmd_' + cmd.id}>
                  <Button
                     aria-label={cmd.title}
                     onClick={async () => await onExecute(cmd)}
                     size="small"
                     startIcon={executing === cmd.id ? <CircularProgress size={18}/> : <cmd.icon/>}
                  >
                     {cmd.title}
                  </Button>
               </Tooltip>
            );
         })}</div>
         }
         {props.searchable && numSelected === 0 && <>
            <SearchField
               fullWidth={true}
               placeholder={"Filter"}
               value={search}
               onChange={onSearch}
               count={props.rows}
            />
         </>}
      </Toolbar>
   );
};

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         width: '100%',
         marginBottom: '10px'
      },
      paper: {
         width: '100%',
      },
      tableContainer: {},
      table: {
         minWidth: 400,
      },
      visuallyHidden: {
         border: 0,
         clip: 'rect(0 0 0 0)',
         height: 1,
         margin: -1,
         overflow: 'hidden',
         padding: 0,
         position: 'absolute',
         top: 20,
         width: 1,
      },
   }),
);

export interface DataColumn<T> {
   field?: string
   label: string
   numeric?: boolean
   get?: (data: T, type?: 'search' | 'data') => any
   render?: (data: any, row: T, context?: any) => any
   align?: 'left' | 'right' | 'center'
   searchable?: boolean
   width?: string
   hidden?: boolean
}

export interface DataCommand {
   id: string
   title: string
   icon: any
   execute: (rows: any[]) => Promise<void>
}

export type DataTableProps = {
   stateId?: string
   idField: string
   rows?: any[]|null
   columns: DataColumn<any>[]
   commands?: DataCommand[]
   selections?: boolean
   minLineHeight?: number
   order?: 'asc' | 'desc',
   orderBy?: number
   onSingleSelect?: (row: any) => void
   hideHeader?: boolean
   hideLines?: boolean
   maxRows?: number
   hideFilter?: boolean
   context?: any
}

export const DataTable = (props: DataTableProps) => {
   const classes = useStyles();
   const [search, setSearch] = React.useState<string>('');
   const [order, setOrder] = React.useState<Order>('asc');
   const [orderBy, setOrderBy] = React.useState<number>(props.orderBy || 0);
   const [selected, setSelected] = React.useState<string[]>([]);
   const [filtered, setFiltered] = React.useState<any[]>([]);
   const [searchable, setSearchable] = React.useState<DataColumn<any>[]>([])
   const [showCount, setShowCount] = React.useState<number>(50);

   const {observe} = useInView({
      // For better UX, we can grow the root margin so the data will be loaded earlier
      rootMargin: "150px 0px",
      // When the last item comes to the viewport
      onEnter: ({unobserve, observe}) => {
         unobserve();
         // Pause observe when loading data
         setShowCount(showCount + 50);
         observe()
      },
   });

   useEffect(() => {
      if (props.order) {
         setOrder(props.order);
      }
      setFiltered(props.rows || []);
      if (!props.hideFilter) {
         setSearchable(props.columns.filter(col => col.searchable));
      }
      // eslint-disable-next-line
   }, [props.columns, props.hideFilter, props.order, props.rows])


   useEffect(() => {
      if (searchable.length === 0) {
         return;
      }
      if (!search || search.length === 0) {
         setFiltered(props.rows || []);
         return;
      }
      let filter = props.rows?.filter(row => {
         let match = false;
         for (const column of searchable) {
            let cellValue = getCellValue(column, row, true);
            if (cellValue) {
               if (typeof cellValue === "string") {
                  cellValue = cellValue.toLowerCase();
                  match = cellValue.indexOf(search) > -1;
                  if (match) {
                     break;
                  }
               }
            }
         }
         return match;
      });
      setFiltered(filter || [])
      // eslint-disable-next-line
   }, [search, props.rows]);

   const handleRequestSort = (event: React.MouseEvent<unknown>, property: number) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };

   let cells: HeadCell[] = props.columns.filter(col => !col.hidden).map((col, idx) => {
      return {
         id: idx,
         label: col.label,
         disablePadding: true,
         numeric: col.numeric,
         align: col.align,
         width: col.width
      } as HeadCell
   })

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelecteds = filtered.map((n) => n[props.idField]);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   }

   const onExecuteCommand = async (command: DataCommand) => {
      await command.execute(filtered.filter(row => {
         return selected.includes(row[props.idField])
      }))
   }

   const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const isSelected = (name: string) => selected.indexOf(name) !== -1;
   const selectionEnabled = props.selections !== undefined ? props.selections : false;

   const onRowSelect = (row: any) => () => {
      if (props.onSingleSelect) {
         props.onSingleSelect(row);
      }
   }

   const handleSearchDebounce = (search: string) => {
      setSearch(search);
   }

   // eslint-disable-next-line
   const handleSearch = useCallback(
      debounce(handleSearchDebounce, 400),
      [searchable]);

   let dataTableContent = <>
      {(searchable.length !== 0 || selectionEnabled) && <EnhancedTableToolbar
         search={search}
         numSelected={selected.length}
         commands={props.commands}
         onExecute={onExecuteCommand}
         onSearch={handleSearch}
         searchable={searchable.length !== 0}
         rows={filtered.length}
      />}
      <TableContainer className={classes.tableContainer}>
         <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
         >
            <EnhancedTableHead
               selections={selectionEnabled}
               headCells={cells}
               classes={classes}
               numSelected={selected.length}
               order={order as Order}
               orderBy={orderBy}
               onSelectAllClick={handleSelectAllClick}
               onRequestSort={handleRequestSort}
               rowCount={filtered.length}
               hideHeader={props.hideHeader}
            />
            <TableBody>
               {stableSort(filtered, getComparator(order as Order, props.columns[orderBy]))
                  .map((row, index) => {
                     let id = row[props.idField];
                     const isItemSelected = isSelected(id);
                     const labelId = `enhanced-table-checkbox-${index}`;
                     if (index > showCount) {
                        return null;
                     }
                     if (props.maxRows && index >= props.maxRows) {
                        return null;
                     }

                     let cells = [];
                     let first = true;
                     for (const column of props.columns.filter(col => !col.hidden)) {
                        let value = getCellValue(column, row);
                        let innerComponent;
                        if (column.render !== undefined) {
                           innerComponent = <>{column.render(value, row, props.context)}</>
                        } else {
                           innerComponent = <>{value}</>
                        }
                        cells.push(<TableCell key={'cell_' + row._id + '_' + column.label}
                                              style={{
                                                 paddingLeft: (selectionEnabled ? 0 : (first ? 10 : undefined)),
                                                 borderBottom: props.hideLines ? '1px solid transparent' : undefined,
                                                 paddingRight: 10
                                              }}
                                              padding="none"
                                              align={column.numeric ? 'right' : column.align}
                        >
                           {innerComponent}
                        </TableCell>);
                        first = false;
                     }
                     return (
                        <TableRow
                           ref={index === showCount - 1 ? observe : null}
                           hover
                           role="checkbox"
                           aria-checked={isItemSelected}
                           tabIndex={-1}
                           key={'data_' + row[props.idField]}
                           selected={isItemSelected}
                           style={{cursor: 'pointer', height: props.minLineHeight || 40}}
                           onClick={onRowSelect(row)}
                        >
                           {selectionEnabled && <TableCell padding="checkbox">
                              <Checkbox
                                 onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    handleClick(event, row[props.idField])
                                 }}
                                 checked={isItemSelected}
                                 inputProps={{'aria-labelledby': labelId}}
                              />
                           </TableCell>}
                           {cells}
                        </TableRow>
                     );
                  })}
               {filtered.length === 0 &&
               <TableRow><TableCell colSpan={props.columns.length + (selectionEnabled ? 1 : 0)}>
                  No data {search && 'matching "' + search + '"'}
               </TableCell></TableRow>}
            </TableBody>
         </Table>
      </TableContainer>
   </>;

   return (
      <div className={classes.root}>
         {dataTableContent}
      </div>
   );
}

function getCellValue(column: DataColumn<any>, row: any, search?: boolean) {
   if (column.get !== undefined) {
      if (search) {
         return column.get(row, 'search')
      } else {
         return column.get(row, 'data')
      }
   } else if (column.field !== undefined) {
      return row[column.field];
   } else {
      throw new Error('Column "' + column.label + '" must have either get or field');
   }
}

function descendingComparator(a: any, b: any, orderBy: DataColumn<any>) {
   let aValue = getCellValue(orderBy, a);
   let bValue = getCellValue(orderBy, b);

   if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase();
   }
   if (typeof bValue === 'string') {
      bValue = bValue.toLowerCase();
   }

   if (bValue < aValue) {
      return -1;
   }
   if (bValue > aValue) {
      return 1;
   }
   return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order: Order, orderBy: DataColumn<any>):
   (a: any, b: any) => number {
   return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

