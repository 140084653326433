import PageContent from "./PageContent";
import CardActionArea from "@mui/material/CardActionArea";
import {Box, CardContent} from "@mui/material";
import {MissingImageIcon} from "../components/Icons";
import Card from "@mui/material/Card";
import * as React from "react";
import {BodyText, TitleText} from "./Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export type PageCard = {
   icon?: string | React.ReactNode
   name: string
   description?: string | React.ReactNode
   onClick: () => void
   commands?: React.ReactNode[] | React.ReactNode
}

type PageCardsProps = {
   title?: string
   commands?: React.ReactNode | React.ReactNode[];
   cards: PageCard[]
   onBack?: boolean
   emptyMessage?: string | React.ReactNode
}

export const PageCards = ({cards, title, commands, onBack, emptyMessage}: PageCardsProps) => {
   const navigate = useNavigate();

   const renderIcon = (card: PageCard) => {
      if (React.isValidElement(card.icon)) {
         return <>{card.icon}</>
      } else {
         return card?.icon ? <img src={card.icon as string} alt={card?.name || ''}/> :
            <MissingImageIcon/>
      }
   }

   const renderDescription = (card: PageCard) => {
      if (React.isValidElement(card.description)) {
         return <>{card.description}</>
      } else {
         return <BodyText type={"subtitle2"}>
            {card.description}
         </BodyText>
      }
   }

   const renderEmptyDescription = () => {
      if (React.isValidElement(emptyMessage)) {
         return <>{emptyMessage}</>
      } else {
         return <BodyText type={"subtitle2"}>
            {emptyMessage}
         </BodyText>
      }
   }

   const onHandleBack = () => {
      navigate(-1)
   }

   return <Box>
      {(title || commands) &&
         <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px'
         }}>
            <Box sx={{display: 'flex', alignItems: 'center', columnGap: '20px'}}>
               {onBack && window.history?.length > 0 &&
                  <Button color={"secondary"} variant={"outlined"} size={"small"} onClick={onHandleBack}>
                     <ArrowBackIcon/>
                  </Button>}
               {title &&
                  <TitleText component={"div"} type={"h1"}>{title}</TitleText>
               }
            </Box>
            <Box>
               {commands}
            </Box>
         </Box>
      }
      <PageContent noPadding noBorder>
         {cards.length === 0 && emptyMessage && <Card>
            <CardContent>
               <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {renderEmptyDescription()}
               </Box>
            </CardContent>
         </Card>}
         {cards.map((card, i) => {
            const borderRadius: any = {
               borderRadius: '0 !important'
            };
            if (i === 0) {
               borderRadius.borderTopLeftRadius = '4px !important'
               borderRadius.borderTopRightRadius = '4px !important'
            }
            if (i === (cards.length - 1)) {
               borderRadius.borderBottomLeftRadius = '4px !important'
               borderRadius.borderBottomRightRadius = '4px !important'
            } else {
               borderRadius.borderRadius = '0 !important'
            }

            return <Card
               sx={{...borderRadius, borderBottom: i !== (cards.length - 1) ? 'none' : undefined}}>
               <CardActionArea
                  onClick={card.onClick}
                  sx={{
                     display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                     padding: '8px 16px 8px 16px'
                  }}>
                  <Box sx={{display: 'flex', alignItems: 'center', columnGap: '16px'}}>
                     <Box>
                        {renderIcon(card)}
                     </Box>
                     <Box sx={{fontWeight: 'bold'}}>
                        {card?.name}
                        {renderDescription(card)}
                     </Box>
                  </Box>
                  <Box sx={{}}>
                     {card.commands}
                  </Box>
               </CardActionArea>
            </Card>
         })}
      </PageContent>
   </Box>
}