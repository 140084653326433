import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";

import AceEditor from "react-ace";
import {IAceEditorProps} from "react-ace";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

export type FormInputJSONProps = Omit<IAceEditorProps, 'name'> & {
   name: string;
}

export const FormInputJSON = ({name, ...rest}: FormInputJSONProps) => {
   const methods = useFormContext();
   const {control} = methods;

   return <div style={{width: '100%', marginBottom: '10px'}}>
      <Controller
         control={control}
         name={name}
         defaultValue={""}
         render={({field: {onChange, value}}) => {
            return <AceEditor
               height={"500px"}
               mode="json"
               style={{border: '1px solid #c0c0c0'}}
               theme="tomorrow"
               value={value}
               name="jsonEditor"
               setOptions={{
                  useWorker: false
               }}
               editorProps={{$blockScrolling: true}}
               placeholder={"JSON"}
               width={"100%"}
               onChange={onChange}
               {...rest}
            />
         }}
      />
   </div>
};
