import {FlexList} from "../../../components/flexlist/FlexList";
import {SeasonListItemFragment, SortFindManySeasonInput, useSeasonsListLazyQuery} from "../../../Queries";
import {FlexListColumn} from "../../../components/flexlist/FlexTypes";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {SeasonsEditPagePath} from "./SeasonsEditPage";

type SeasonsListProps = {
   brandHouseId: string
}

const PAGE_SIZE = 20;
export const SeasonsList = ({brandHouseId}: SeasonsListProps) => {
   const [fetchMore] = useSeasonsListLazyQuery()
   const navigate = useNavigate();

   const columns: FlexListColumn<SeasonListItemFragment>[] = [{
      id: 'id',
      field: 'name',
      label: 'Name'
   }, {
      id: 'period',
      field: 'periodDescription',
      label: 'Period'
   }, {
      id: 'active',
      field: 'active',
      label: 'Active',
      render: (data) => {
         if (data) {
            return "Yes"
         } else {
            return "No"
         }
      }
   }];

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManySeasonInput) || SortFindManySeasonInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: {
               brandHouseId,
               ...filter
            }
         }
      });
      return {
         data: data?.SeasonsPagination?.items || [],
         totalCount: data?.SeasonsPagination?.count || 0
      }
   }, [brandHouseId, fetchMore]);

   const onSelectRow = (row: SeasonListItemFragment) => {
      navigate(SeasonsEditPagePath(brandHouseId, row._id))
   }

   return <FlexList globalId={"seasonList"} onSelectRow={onSelectRow} columns={columns} itemsPerPage={20}
                    onRequestData={onRequestData}/>
}
