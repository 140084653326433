import React from "react";
import {Typography} from "@mui/material";

export type CommonProps = {
   className?: string,
   style?: any
}

export type BodyProps = CommonProps & {
   type: 'body1' | 'body2' | 'subtitle1',
   children: React.ReactNode | React.ReactNode[] | null,
}

export const SubTitle = (props: BodyProps) => {
   const effectStyle = {
      display: 'block',
      ...props.style
   }
   return <Typography style={effectStyle} className={props.className} variant={props.type} component={"span"}>
      {props.children}
   </Typography>
}
