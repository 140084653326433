import {gql, useMutation} from "@apollo/client";
import {BrandApi} from "./BrandApiType";

export const qlBrandApiUpdate = gql`
    mutation BrandApiUpdate($shopId: String!, $api: BrandApiInput!) {
      BrandApiUpdate(shopId:$shopId, api: $api) {
         _id
      }
    }
`;


const useStockItemUpdate = () => {
   const [brandApiUpdate] = useMutation(qlBrandApiUpdate);

   return (shopId: string, api: BrandApi) => {
      return brandApiUpdate({
         variables: {
            shopId: shopId,
            api: api
         },
         update(cache, {data}) {
            cache.evict(data.BrandApiUpdate._id);
         }
      }).then(data => data.data.BrandApiUpdate?._id)
   }
}

export default useStockItemUpdate;