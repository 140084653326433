import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useFieldArray, useFormContext} from "react-hook-form";
import {LocalizationEntry} from "../../../Queries";
import {FormInputHtml} from "../wholesale/form/FormInputHtml";
import {FormInputText} from "../wholesale/form/FormInputText";
import {TitleText} from "../../../layout/Typography";
import {useEffect} from "react";

export interface LocalizationField {
   title: string
   field: string
   multiline?: boolean
}

type LocalizationEditorProps = {
   primaryLocale: string
   locales: string[]
   fields: LocalizationField[]
}

export const LocalizationEditor = ({primaryLocale, locales, fields}: LocalizationEditorProps) => {
   // @ts-ignore
   const regionNames = new Intl.DisplayNames(
      ['en'], {type: 'language'}
   );

   const columnWidth = '350px'

   return <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Table>
         <TableHead>
            <TableRow>
               <TableCell valign={"top"} style={{width: '100px'}}>Field</TableCell>
               <TableCell valign={"top"}
                          style={{minWidth: '300px'}}>{regionNames.of(primaryLocale)} (Primary)</TableCell>
               {locales.filter(locale => locale !== primaryLocale).map(locale => {
                  return <TableCell key={'cell_' + locale} valign={"top"} style={{minWidth: columnWidth}}>
                     {regionNames.of(locale)}
                  </TableCell>
               })}
            </TableRow>
         </TableHead>
         <TableBody>
            {fields.map(field => {
               return <FieldEditor key={'field_' + field.field} primaryLocale={primaryLocale} locales={locales} field={field}/>
            })}
         </TableBody>
      </Table>
   </Box>
}

type FieldEditorProps = {
   primaryLocale: string
   locales: string[]
   field: LocalizationField
}

export const FieldEditor = ({primaryLocale, locales, field}: FieldEditorProps) => {
   const methods = useFormContext();
   const {control, getValues} = methods;

   // @ts-ignore
   const regionNames = new Intl.DisplayNames(
      ['en'], {type: 'language'}
   );

   const fieldArray = useFieldArray({
      control,
      name: field.field + 'Localized'
   });

   let fields = fieldArray.fields as unknown as LocalizationEntry[]
   const {append} = fieldArray;

   useEffect(() => {
      for (const locale of locales.filter(locale => locale !== primaryLocale)) {
         let find = fields.find(field => field.locale === locale);
         if (!find) {
            append({
               locale: locale,
               value: ''
            })
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const fieldValue = getValues(field.field);

   return <TableRow>
      <TableCell style={{verticalAlign: "top"}}>
         <TitleText type={"h3"}>{field.title}</TitleText>
      </TableCell>
      <TableCell style={{verticalAlign: "top"}}>
         <textarea value={fieldValue || ''} rows={field.multiline ? 10 : 3} disabled={true}
                   style={{height: 'auto', width: '100%', border: '1px solid #e0e0e0'}}/>
      </TableCell>
      {locales.filter(locale => locale !== primaryLocale).map(locale => {
         const fIndex = fields.findIndex(f => f.locale === locale);
         return <TableCell key={'loca_' + locale} style={{verticalAlign: "top"}}>
            {field.multiline &&
               <FormInputHtml height={"125px"} name={field.field + `Localized.[${fIndex}].value`}
                              label={regionNames.of(locale) + ' translation'}/>
            }
            {!field.multiline &&
               <FormInputText name={field.field + `Localized.[${fIndex}].value`} control={control}
                              label={regionNames.of(locale) + ' translation'}/>
            }
         </TableCell>
      })}
   </TableRow>
}