import {useNavigate, useParams} from "react-router-dom";
import {useKlaviyoSettingsQuery, useKlaviyoSynchronizeMutation, useKlaviyoUpdateMutation} from "../../../Queries";
import {Button, LinearProgress} from "@mui/material";
import {ActionType, Form} from "../../../components/quickform/Model";
import {createForm} from "../../../components/quickform/QuickForm";
import {Dialogs} from "../../../DialogProvider";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import {DateFormat} from "../../../utility/DateFormat";
import PageContent from "../../../layout/PageContent";
import {LogisticsForm} from "../../components/LogisticsForm";
import * as React from "react";
import SettingsHeader from "../SettingsHeader";
import {ShopTabs} from "../ShopTabs";

const KlaviyoPagePath = (brandId: string, edit: string) => {
   return "/brands/" + brandId + "/settings/klaviyo/" + edit
}


export const KlaviyoPage = () => {
   const {brandHouseId, edit} = useParams<{ brandHouseId: string, edit: string }>();
   const navigate = useNavigate();

   const handleChange = (newValue: string) => {
      navigate(KlaviyoPagePath(brandHouseId, newValue));
   };

   return <div>
      <SettingsHeader title={"Klaviyo settings"}
                      subtitle={"Enable the klaviyo configuration to handle marketing from klaviyo"}/>
      <ShopTabs
         hideDefault={true}
         activeId={edit}
         brandHouseId={brandHouseId}
         onChange={handleChange}
         renderDefault={() => <div/>}
         renderShop={(shop) => <KlaviyoPageInner shopId={shop}/>}
      />
   </div>
}

type KlaviyoPageInnerProps = {
   shopId?: string
}

const KlaviyoPageInner = ({shopId}: KlaviyoPageInnerProps) => {
   const {data} = useKlaviyoSettingsQuery({
      variables: {
         shopId: shopId
      },
      fetchPolicy: "no-cache"
   })

   const [save] = useKlaviyoUpdateMutation();

   const [synchronize] = useKlaviyoSynchronizeMutation();

   if (!data) {
      return <LinearProgress/>
   }

   const klaviyoData = data.BrandSettings;

   let klaviyoConfig: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "Heading",
            props: {
               text: "Activate",
               subtext: "Enable the klaviyo integration to allow push events and data about profiles into your klaviyo account. These data can be used when you create your marketing campaigns.",
               variant: "h2"
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Enable klaviyo integration",
               field: "klaviyoConfig.enabled"
            }
         }, {
            type: "Heading",
            props: {
               text: "Tokens",
               subtext: "Visit klaviyo.com and go to account / settings / api keys, and copy-paste both the private and public tokens below.",
               variant: "h2"
            }
         }, {
            type: "Horizontal",
            children: [{
               type: "TextField",
               props: {
                  label: "Private API Token for Klaivyo",
                  field: "klaviyoConfig.privateToken"
               }
            }, {
               type: "TextField",
               props: {
                  label: "Public API Token for Klaivyo",
                  field: "klaviyoConfig.publicToken"
               }
            }]
         }, {
            type: "Heading",
            props: {
               text: "Data push options",
               subtext: "Select the data we should push to klaviyo.",
               variant: "h2",
            }
         }, {
            type: "Horizontal",
            children: [{
               type: "CheckBox",
               props: {
                  label: "Export events",
                  field: "klaviyoConfig.exportEvents"
               }
            }, {
               type: "CheckBox",
               props: {
                  label: "Export offers",
                  field: "klaviyoConfig.exportOffers"
               }
            }]
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Save",
                  type: "save"
               }
            }]
         }]
      }
   })

   const onSaveSettings = async (type: ActionType, data: any) => {
      await save({
         variables: {
            shopId: shopId,
            api: data
         }
      })
      return {
         success: true
      }
   }

   const onSynchronize = async () => {
      Dialogs.confirm({
         title: 'Start full synchronization with Klaviyo?',
         subtitle: 'This will take a few minutes to complete'
      }, async () => {
         await synchronize({
            variables: {
               shopId: shopId
            }
         })
      })
   }

   return <Page>
      <PageHeader title={"Klaviyo"}>
         {klaviyoData?.klaviyoConfig?.updatedTs && <>
            <Button color={"secondary"} variant={"text"}>Last
               synchronize: {DateFormat.toReadable(klaviyoData?.klaviyoConfig?.updatedTs)}</Button>
         </>}
         <Button variant={"contained"} onClick={onSynchronize}>Synchronize</Button>
      </PageHeader>
      <PageContent>
         <LogisticsForm form={klaviyoConfig} data={klaviyoData} onButton={onSaveSettings} shopId={shopId!}/>
      </PageContent>
   </Page>
}