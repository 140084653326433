import {
   BrickBox,
   BrickButton,
   BrickImage,
   BrickMailDefinition,
   BrickMailInstance,
   BrickProducts,
   BrickText
} from "./BrickMailType";

export const ImageBrickHtml = (brick: BrickImage) => `<table class="module" role="module" data-type="text" border="0"
       cellpadding="0" cellspacing="0" width="100%"
       style="table-layout: fixed;"
       data-muid="580f22fc-39aa-468e-9753-3a0d0a23fd57"
       data-mc-module-version="2019-10-22">
    <tbody>
    <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;"
            height="100%" valign="top"
            role="module-content">
            <div>
                <div style="font-family: inherit; font-size: 16px; text-align: center; letter-spacing: 0.35rem">
                    <span style="color: #000000; text-decoration: none">
                        <img src="${brick.image}" title="${brick.title}" alt="${brick.title}"/>
                    </span>
                </div>
            </div>
        </td>
    </tr>
    </tbody>
</table>
`

export const TextBrickHtml = (brick: BrickText, mail: BrickMailDefinition) => `<table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%">
<tbody><tr><td style="line-height:130%;padding:${brick.margin || '0px 5px 0px 5px'}; text-align:center;">
<div style="line-height:130%;
            font-family: ${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; 
            text-align: ${brick.align || 'center'};">
    <span style="line-height:130%;background-color: ${brick.backgroundColor || 'inherit'}; color: ${brick.color || '#000000'}; ${brick.fontFamily && 'font-family: ' + brick.fontFamily}; 
    font-size: ${brick.fontSize || '14px'}; ${brick.bold && 'font-weight: bold;'}">
        ${brick.value}
        ${!brick.disablePadding ? `<table cellpadding="0" cellspacing="0" border="0" width="100">
        <tr><td width="100" height="15">&nbsp;</td></tr></table>`:``}
    </span>
</div>
</td></tr>
</tbody></table>
`

export const ButtonBrickHtml = (brick: BrickButton, mail: BrickMailDefinition) => `<!-- Button -->
<table border="0" cellpadding="0" cellspacing="0" class="module"
       data-role="module-button" data-type="button"
       role="module" style="table-layout:fixed" width="100%"
       data-muid="hthYAt191yTdg6FPWYKodF">
    <tbody>
    <tr>
        <td align="${brick.align||'center'}" class="outer-td"
            style="padding: ${brick.margin || '20px 0px 20px 0px'}; background-color:${mail.body?.textBackgroundColor || '#ffffff'}">
            <table border="0" cellpadding="0" cellspacing="0"
                   class="button-css__deep-table___2OZyb wrapper-mobile"
                   style="text-align:center">
                <tbody>
                <tr>
                    <td align="center" bgcolor="${mail.body?.textBackgroundColor || '#993300'}"
                        class="inner-td"
                        style="border-radius:6px; font-size:16px; text-align:center; background-color:inherit;">
                        ${!!brick.url && `
                            <a style="background-color:${brick.backgroundColor || '#993300'}; border:2px solid ${brick.borderColor || '#FFFFFF'}; border-color: ${brick.borderColor || '#FFFFFF'}; border-radius:0px; border-width:2px; color: ${brick.color || '#FFFFFF'}; display:inline-block; 
                            ${brick.fontFamily ? `font-family: ${brick.fontFamily}` : ``}; font-size:${brick.fontSize || 'Assistant, verdana, geneva, sans-serif'}; font-weight:normal; letter-spacing:1px; line-height:30px; padding:12px 30px 12px 30px; text-align:center; text-decoration:none; border-style:solid;"
                                           href="${brick.url}"
                                           target="_blank">
                        `}
                        ${!brick.url ? `
                            <span style="background-color:${brick.backgroundColor || '#993300'}; border:2px solid ${brick.backgroundColor || '#993300'}; border-color: ${brick.backgroundColor || '#993300'}; border-radius:0px; border-width:1px; color: ${brick.color || '#FFFFFF'}; display:inline-block; font-family: ${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:${brick.fontSize || 'Assistant, verdana, geneva, sans-serif'}; font-weight:normal; letter-spacing:1px; line-height:30px; padding:12px 30px 12px 30px; text-align:center; text-decoration:none; border-style:solid;">
                        ` : ``}
                        ${brick.value}
                        ${brick.url ? `</a>` : ``}
                        ${!brick.url ? `</span>` : ``}
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    </tbody>
</table>

<!-- Spacer -->
<table cellpadding="0" cellspacing="0" border="0" width="100">
    <tr>
        <td width="100" height="15"></td>
    </tr>
</table>
`


export const ProductsBrickHtml = (brick: BrickProducts, mail: BrickMailDefinition, instance: BrickMailInstance) => `<!-- Products -->
<table border="0" cellpadding="0" cellspacing="0" class="module"
       data-role="module-button" data-type="button"
       role="module" style="table-layout:fixed" width="100%"
       data-muid="hthYAt191yTdg6FPWYKodF">
    <tbody>
    <tr>
        <td width="20%" align="center" bgcolor="#e0e0e0" class="outer-td" style="padding:0px 10px 0px 10px;">

    ${(instance.products?.data||[]).length > 0 ? `<span style="font-weight:bold;">${brick.title}</span><br/><br/><br/>` : ``}

${instance.products?.data.map(product => `<table border="0" cellpadding="0" cellspacing="0" class="module"
      data-role="module-button" data-type="button"
      role="module" style="table-layout:fixed" width="100%"
      data-muid="hthYAt191yTdg6FPWYKodF">
      <tbody>
         <tr>
            <td width="20%" align="center" bgcolor="#e0e0e0" class="outer-td" style="padding:0px 0px 40px 0px;">
      <a href="${instance.variables.loginLink}" target="_blank"><img width="80" border="0" style="width:80px; color:#000000; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:14px; max-width:100% !important; height:auto !important;"
      alt="" data-proportionally-constrained="true" data-responsive="true" src="${product.image}"></a>
         </td>
         <td width="60%" align="left" class="outer-td" style="padding:0px 0px 40px 10px;">
      <a href="${instance.variables.loginLink}" target="_blank" style="color:#000000; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:13px; max-width:100% !important; height:auto !important;">
         ${product.title}
      </a>
      <table border="0" cellpadding="0" cellspacing="0" class="module"
      data-role="module-button" data-type="button"
      role="module" style="table-layout:fixed" width="100%"
      data-muid="hthYAt191yTdg6FPWYKodF">
      <tr><td width="40%" align="left" class="outer-td" style="padding:0px 0px 0px 0px; background-color:#ffffff;">
      <span style="color:#808080; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:10px; max-width:100% !important; height:auto !important;line-height: 12px;">
         ${instance.products?.boughtPrefix} ${product.orderDate}
      </span><br/>
      </td><td width="60%" align="right" class="outer-td" style="padding:0px 0px 0px 0px;">
      <table cellpadding="0" style="padding: 10px; background-color: #f0f0f0; color:#000000; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:12px; max-width:100% !important; height:auto !important;"><tr><td>
         <span style="background-color: #f0f0f0; color:#000000; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:11px; max-width:100% !important; height:auto !important;">
         ${instance.products?.voucherPrefix} ${product.voucher}
      </span>
      </td></tr></table>
      </td>
      </tr>
      </table>
      </td>
      </tr>
      </tbody>
      </table>
`)}
    </td>
    </tbody>
</table>
`


export const BoxBrickHtml = (brick: BrickBox, mail: BrickMailDefinition) => `<!-- Box -->
<table border="0" cellpadding="0" cellspacing="0" class="module"
       data-role="module-button" data-type="button"
       role="module" style="table-layout:fixed" width="100%"
       data-muid="hthYAt191yTdg6FPWYKodF">
    <tbody>
    <tr>
        <td align="${brick.align||'center'}" class="outer-td"
            style="padding:${brick.margin || '20px 0px 20px 0px'}; background-color:inherit">
            <table border="0" cellpadding="0" cellspacing="0"
                   class="button-css__deep-table___2OZyb wrapper-mobile"
                   style="text-align:center">
                <tbody>
                <tr>
                    <td align="center" bgcolor="${brick.backgroundColor || '#993300'}"
                        class="inner-td"
                        style="border-radius:6px; font-size:16px; text-align:center; background-color:inherit;">
                        

                            <span style="background-color: ${brick.backgroundColor || '#993300'}; border:2px solid ${brick.borderColor || '#993300'}; border-color:${brick.backgroundColor || '#993300'}; border-radius:0px; border-width:1px; color:${brick.color || '#993300'}; display:inline-block; font-family: ${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:${brick.fontSize || '16px'} !important; font-weight:normal; letter-spacing:1px; line-height:30px; padding:12px 30px 12px 30px; text-align:center; text-decoration:none; border-style:solid;">
                            ${brick.value}
                            </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    </tbody>
</table> `


export const StartHtml = (mail: BrickMailDefinition) => `<body style="background-color:${mail.body?.backgroundColor || '#ffffff'}" bgcolor="${mail.body?.backgroundColor || '#ffffff'}">
<center class="wrapper" data-link-color="#1188E6"
data-body-style="font-size:14px; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; color:#000000; background-color:${mail.body?.backgroundColor || '#ffffff'};">
<div class="webkit">
<table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="${mail.body?.backgroundColor || '#ffffff'}">
<tr>
<td valign="top" bgcolor="${mail.body?.backgroundColor || '#ffffff'}" width="100%">
<table width="100%" role="content-container" class="outer" align="center" cellpadding="0"
cellspacing="0" border="0">
<tr>
<td width="100%">
<table width="100%" cellpadding="0" cellspacing="0" border="0">
    <tr>
        <td>
            <!--[if mso]>
            <center>
                <table>
                    <tr>
                        <td width="600">
            <![endif]-->
            <table width="100%" cellpadding="0" cellspacing="0" border="0"
                   style="width:100%; max-width:600px;" align="center">
                <tr>
                    <td role="modules-container"
                        style="padding:0px 0px 0px 0px; color:#000000; text-align:left;"
                        bgcolor="${mail.title?.backgroundColor || '#F4F6F5'}" width="100%" align="left">
                        <table class="module preheader preheader-hide" role="module"
                               data-type="preheader" border="0" cellpadding="0"
                               cellspacing="0" width="100%"
                               style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
                            <tr>
                                <td role="module-content">
                                    <div style="display: none; max-height: 0px; overflow: hidden;">
                                    ${mail.title?.text}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        ${mail.title ? `
                        <table class="module" role="module" data-type="text" border="0"
                               cellpadding="0" cellspacing="0" width="100%"
                               style="table-layout: fixed;"
                               data-muid="580f22fc-39aa-468e-9753-3a0d0a23fd57"
                               data-mc-module-version="2019-10-22">
                            <tbody>
                            <tr>
                                <td style="padding:38px 0px 0px 0px; line-height:22px; text-align:inherit;"
                                    height="100%" valign="top"
                                    role="module-content">
                                    <div>
                                        <div style="font-family: inherit; font-size: 16px; text-align: center; letter-spacing: 0.35rem">
                                            ${mail.title?.url ? `
                                            <a target="_blank" href="${mail.title?.url}" style="color: #000000; text-decoration: none">                               
                                            ` : ``}
                                            ${mail.title?.logo ? `
                                                <img border="0" style="color:#000000; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:18px; max-width:100% !important; height:auto !important;"
                                                                     alt="${mail.title?.shop}"
                                                                     data-proportionally-constrained="true"
                                                                     data-responsive="true" src="${mail.title?.logo}" title="${mail.title?.text}">
                                            ` : `
                                                <span style="color: #000000; text-decoration: none">
                                                    ${mail.title?.text}
                                                </span>
                                            `}
                                            ${mail.title?.url ? `
                                            </a>` : ''}
                                        </div>
                                        <div></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        ` : ``}
                        <table border="0" cellpadding="0" cellspacing="0" align="center"
                               width="100%" role="module" data-type="columns"
                               style="padding:0px 0px 0px 0px;" bgcolor="${mail.body?.frameColor || '#C0C0C0'}"
                               data-distribution="1">
                            <tbody>
                            <tr role="module-content">
                                <td height="100%" valign="top">
                                    <table width="600"
                                           style="width:600px; border-spacing:0; border-collapse:collapse; margin:0px 0px 5px 0px;"
                                           cellpadding="0" cellspacing="0" align="left"
                                           border="0" bgcolor=""
                                           class="column column-0">
                                        <tbody>
                                        <tr>
                                            <td style="padding:${mail.body?.frame ? `${mail.body.framePadding || '15px 15px 10px 15px'}` : `0`};margin:0px;border-spacing:0;">
                                                <table class="module" role="module"
                                                       data-type="text" border="0"
                                                       cellpadding="0" cellspacing="0"
                                                       width="100%"
                                                       style="table-layout: fixed;"
                                                       data-muid="3b03c927-16c0-4abb-b355-9678f2ee3cde"
                                                       data-mc-module-version="2019-10-22">
                                                    <tbody>
                                                    <tr>
                                                        <td style="padding:${mail.body?.padding || '35px 15px 25px 15px'}; line-height:22px; text-align:inherit; background-color: ${mail.body?.backgroundColor || '#ffffff'};"
                                                            height="100%" valign="top"
                                                            bgcolor="${mail.body?.textBackgroundColor || '#ffffff'}"
                                                            role="module-content">

                                                <div id="mail-root"/>

                                        ${mail.speakbox ? `${SpeakBox(mail)}` : ``}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </td>
                </tr>
            </table>
            <!--[if mso]>
            </td>
            </tr>
            </table>
            </center>
            <![endif]-->
        </td>
    </tr>
</table>
</td>
</tr>
</table>
</div>
</center>
</body>`


export const SpeakBox = (mail: BrickMailDefinition) => `<table width="100%" border="0" cellpadding="0"
       cellspacing="0"
       align="center" bgcolor="${mail.speakbox?.backgroundColor || '#F0F0F0'}"
       style="width: 100%; background-color: ${mail.speakbox?.backgroundColor || '#F0F0F0'}">
    
    ${mail.speakbox?.box1image && mail.speakbox.box2image ? ` 
    <tr>
        <td style="padding-top: 20px;width:50%" width="50%">
            <table align="center" width="100%"
                   style="width:100%">
                <tr>
                    <td style="font-size:6px; line-height:16px; padding:0px 0px 0px 0px;"
                        valign="top" align="center">
                        <img 
                             border="0"
                             style="display:block; color: ${mail.speakbox?.color || '#000000'}; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:18px; width:40px;"
                             width="40" alt=""
                             data-proportionally-constrained="true"
                             data-responsive="true"
                             src="${mail.speakbox?.box1image}">
                    </td>
                </tr>
            </table>
        </td>
        <td style="padding-top: 20px;width:50%" width="50%">
            <table align="center" width="100%"
                   style="width:100%">
                <tr>
                    <td style="font-size:6px; line-height:16px; padding:0px 0px 0px 0px;"
                        valign="top" align="center">
                        <img 
                             border="0"
                             style="display:block; color: ${mail.speakbox?.color || '#000000'}; text-decoration:none; font-family:${mail.body?.font || 'Assistant, verdana, geneva, sans-serif'}; font-size:18px; width:40px;"
                             width="40" alt=""
                             data-proportionally-constrained="true"
                             data-responsive="true"
                             src="${mail.speakbox?.box2image}">
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    ` : ``}
    <tr>
        ${mail.speakbox?.box1 ? ` 
        <td valign="top" style="padding-bottom: 20px">
            <div style="font-family:${mail.body?.font|| 'Assistant, verdana, geneva, sans-serif' }; text-align: center">
                <span style="color: ${mail.speakbox?.color ||'#000000' }; ${mail.speakbox?.fontFamily ? `font-family: ${mail.speakbox.fontFamily}`:``}; font-size: ${mail.speakbox?.fontSize || '11px' }">
                ${mail.speakbox?.box1}
                </span>
            </div>
            <div style="font-family:${mail.body?.font|| 'Assistant, verdana, geneva, sans-serif' }; text-align: center">
                <span style="color: ${mail.speakbox?.colorDesc ||'#000000' }; ${mail.speakbox?.fontFamily ? `font-family: ${mail.speakbox.fontFamily}`:``}; font-size: ${mail.speakbox?.fontSize || '11px' }">
                ${mail.speakbox?.box1desc}
                </span>
            </div>
        </td>
        ` : ``}
        ${mail.speakbox?.box2 ? `
        <td valign="top" style="padding-bottom: 20px">
            <div style="font-family:${mail.body?.font|| 'Assistant, verdana, geneva, sans-serif' }; text-align: center">
                <span style="color: ${mail.speakbox?.color ||'#000000' }; ${mail.speakbox?.fontFamily ? `font-family: ${mail.speakbox.fontFamily}`:``}; font-size: ${mail.speakbox?.fontSize || '11px' }">
                  ${mail.speakbox?.box1}
                </span>
            </div>
            
            <div style="font-family:${mail.body?.font|| 'Assistant, verdana, geneva, sans-serif' }; text-align: center">
                <span style="color: ${mail.speakbox?.colorDesc ||'#000000' }; ${mail.speakbox?.fontFamily ? `font-family: ${mail.speakbox.fontFamily}`:``}; font-size: ${mail.speakbox?.fontSize || '11px' }">
                  ${mail.speakbox?.box1desc}
                </span>
            </div>
        </td>
        ` : ``}
    </tr>
</table>

</td>
</tr>
</tbody>
</table>
`