import {useParams} from "react-router";
import PageContent from "../../../layout/PageContent";
import {Button, LinearProgress} from "@mui/material";
import {ActionType, Form} from "../../../components/quickform/Model";
import {createForm} from "../../../components/quickform/QuickForm";
import {LogisticsForm} from "../../components/LogisticsForm";
import {
   EnumShopFrontendType,
   Shop,
   useShopResetMutation,
   useShopSettingsShopQuery,
   useShopSettingsShopUpdateMutation
} from "../../../Queries";
import {DetailsPagePath} from "./DetailsPage";
import SettingsHeader from "../SettingsHeader";
import * as React from "react";
import {ParamBrandHouse, ParamShop} from "../../../Parameters";
import {Currencies} from "../../shop/wholesale/form/FormInputCurrency";
import {SalesforceEdit} from "./SalesforceEdit";
import {Dialogs} from "../../../DialogProvider";
import {SavingUI} from "../../../SavingProvider";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";

export const ShopSettingsPath = (brandHouseId: string, shopId: string) => {
   return DetailsPagePath(brandHouseId) + '/' + shopId
}

export const ShopSettings = () => {
   const {shopId} = useParams<ParamBrandHouse & ParamShop>()
   const {data} = useShopSettingsShopQuery({
      variables: {
         shopId: shopId
      }
   })
   if (!data?.ShopById) {
      return <LinearProgress/>
   }
   const shop = data.ShopById as Shop;

   if (shop.frontendType === EnumShopFrontendType.salesforce) {
      return <SalesforceEdit/>
   } else {
      return <ShopSettingsDefault shop={shop}/>
   }
}

type ShopSettingsDefaultProps = {
   shop: Shop
}

const ShopSettingsDefault = ({shop}: ShopSettingsDefaultProps) => {
   const {shopId} = useParams<ParamBrandHouse & ParamShop>()
   const [updateShop] = useShopSettingsShopUpdateMutation();
   const [resetShop] = useShopResetMutation();
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);

   let brandForm: Form = createForm({
      layout: {
         type: "Vertical",
         children: [{
            type: "TextField",
            props: {
               label: "TradeIn Base URL",
               field: "tradeInBaseUrl",
               required: true
            }
         }, {
            type: "TextField",
            props: {
               label: "Shop Collection URL",
               field: "shopCollectionUrl",
               required: true,
            }
         }, {
            type: "Select",
            props: {
               label: "Currency",
               field: "currency",
               required: true,
               options: Currencies.map(cur => {
                  return {
                     value: cur.id,
                     label: cur.name
                  }
               })
            }
         }, {
            type: "CheckBox",
            props: {
               label: "Live (use with care)",
               field: "live"
            }
         }, {
            type: "TextField",
            props: {
               label: "Live Date",
               field: "liveDate"
            }
         }, {
            type: "TextField",
            props: {
               label: "End Date",
               field: "closeDate"
            }
         }, {
            type: "Heading",
            props: {
               text: "Contact Cutoff Date",
               subtext: "After this date contacts that are disabled, will start removing their contact disabled markings if they buy new goods."
            }
         }, {
            type: "TextField",
            props: {
               label: "Date",
               field: "disableContactResetDate"
            }
         }, {
            type: "Horizontal",
            props: {
               justify: 'flex-end'
            },
            children: [{
               type: "Button",
               props: {
                  label: "Save",
                  type: "save"
               }
            }]
         }]
      }
   });

   const onReset = async () => {
      Dialogs.confirm({
         title: 'WARNING: Delete all data?',
         subtitle: 'All data in the database for this shop will be deleted permanently'
      }, async () => {
         await SavingUI.process(async () => {
            await resetShop({
               variables: {
                  shopId: shopId
               }
            })
         }, 'Shop data reset');
      })
   }

   const onButton = async (type: ActionType, data: any) => {
      await updateShop({
         variables: {
            shopId,
            shop: {
               live: data.live || false,
               tradeInBaseUrl: data.tradeInBaseUrl,
               shopCollectionUrl: data.shopCollectionUrl,
               currency: data.currency,
               frontendType: data.frontendType,
               liveDate: data.liveDate,
               closeDate: data.closeDate
            }
         }
      });
      return {
         success: true
      }
   }

   return <div>
      <SettingsHeader title={shop.name || 'Shop'} onBack>
         {isAdministrator && <Button onClick={onReset} color={"primary"}
                                     variant={"outlined"}>Reset data</Button>}
      </SettingsHeader>
      <PageContent>
         <LogisticsForm form={brandForm} data={shop} onButton={onButton} shopId={shopId}/>
      </PageContent>
   </div>
}
