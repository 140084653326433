export interface Locale {
   key: string;
   name: string;
   main?: boolean
}

const Locales: Locale[] = [
   {
      "key": "da",
      "name": "Danish",
      "main": true
   },
   {
      "key": "de",
      "name": "German",
      "main": true
   },
   {
      "key": "sv",
      "name": "Swedish",
      "main": true
   },
   {
      "key": "en",
      "name": "English",
      "main": true
   },
   {
      "key": "no",
      "name": "Norwegian",
      "main": true
   },
   {
      "key": "sq",
      "name": "Albanian"
   },
   {
      "key": "af",
      "name": "Afrikaans"
   },
   {
      "key": "ar",
      "name": "Arabic"
   },
   {
      "key": "bg",
      "name": "Bulgarian"
   },
   {
      "key": "bs",
      "name": "Bosnian"
   },
   {
      "key": "hr",
      "name": "Croatian"
   },
   {
      "key": "cs",
      "name": "Czech"
   },
   {
      "key": "nl",
      "name": "Dutch"
   },
   {
      "key": "el",
      "name": "Greek"
   },
   {
      "key": "et",
      "name": "Estonian"
   },
   {
      "key": "fi",
      "name": "Finnish"
   },
   {
      "key": "fo",
      "name": "Faroese"
   },
   {
      "key": "fr",
      "name": "French"
   },
   {
      "key": "ka",
      "name": "Georgian"
   },
   {
      "key": "hu",
      "name": "Hungarian"
   },
   {
      "key": "is",
      "name": "Icelandic"
   },
   {
      "key": "it",
      "name": "Italian"
   },
   {
      "key": "ja",
      "name": "Japanese"
   },
   {
      "key": "lt",
      "name": "Lithuanian"
   },
   {
      "key": "lv",
      "name": "Latvian"
   },
   {
      "key": "mk",
      "name": "Macedonian"
   },
   {
      "key": "mt",
      "name": "Maltese"
   },
   {
      "key": "pl",
      "name": "Polish"
   },
   {
      "key": "pt",
      "name": "Portuguese"
   },
   {
      "key": "ro",
      "name": "Romanian"
   },
   {
      "key": "ru",
      "name": "Russian"
   },
   {
      "key": "sk",
      "name": "Slovak"
   },
   {
      "key": "sl",
      "name": "Slovenian"
   },
   {
      "key": "es",
      "name": "Spanish"
   },
   {
      "key": "sr",
      "name": "Serbian"
   },
   {
      "key": "tr",
      "name": "Turkish"
   },
   {
      "key": "uk",
      "name": "Ukrainian"
   }
]

const get = (locale: string) => {
   return Locales.find(loc => loc.key===locale)
}

const all = () => {
   return Locales;
}

export const Languages = {
   get,
   all
}