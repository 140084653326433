import * as React from "react";
import {FC} from "react";
import {
   FilterFindManyProductInput,
   FilterFindManyProductOperatorsInput,
   FilterFindManyProductVariantInput,
   ProductListProductFragment,
   SortFindManyProductInput,
   useProductListBatchesQuery,
   useProductListPaginationLazyQuery
} from "../../../Queries";
import {FlexFilter, FlexListColumn, FlexSort, FlexTab} from "../../../components/flexlist/FlexTypes";
import {Thumbnails} from "../../../components/Thumbnails";
import {FlexList} from "../../../components/flexlist/FlexList";
import {LinearProgress} from "@mui/material";

type ProductsListProps = {
   shopId: string
   onProductSelect: (product: ProductListProductFragment) => void
   defaultSearch?: string
   inDialog?: boolean
   hidePreowned?: boolean
}

const PAGE_SIZE = 20;

export const ProductsList2: FC<ProductsListProps> = ({shopId, onProductSelect, inDialog, hidePreowned}) => {
   const {data} = useProductListBatchesQuery({
      variables: {
         shopId: shopId
      }
   })
   const [fetchMore] = useProductListPaginationLazyQuery();

   if (!data) {
      return <LinearProgress/>
   }

   const columns: FlexListColumn<ProductListProductFragment>[] = [{
      id: 'image',
      label: "Image",
      get: (product) => {
         return product._id
      },
      render: (id, product) => {
         const imageUrls = product?.images || [];
         return <Thumbnails size={50} imageUrls={imageUrls.filter(img => !!img) as string[]} maxImages={3}/>
      },
      width: 5
   }, {
      id: 'name',
      field: 'name',
      label: "Name",
      render: (name: string, row) => {
         return <div>{name}</div>
      },
      width: 20
   }, {
      id: 'style',
      field: 'style',
      label: 'Style',
      width: 10
   }, {
      id: 'type',
      field: 'productType',
      label: 'Type',
      width: 10
   }, {
      id: 'vendor',
      field: 'productVendor',
      label: 'Vendor',
      width: 10
   }]

   const sortSpec: FlexSort<SortFindManyProductInput>[] = [{
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyProductInput._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyProductInput._ID_DESC
   }, {
      id: 'NameAsc',
      label: 'Name (A-Z)',
      value: SortFindManyProductInput.NAME_ASC
   }, {
      id: 'NameDesc',
      label: 'Name (Z-A)',
      value: SortFindManyProductInput.NAME_DESC
   }]

   const tabsSpec: FlexTab[] = [{
      id: 'all',
      label: 'All',
      filters: []
   }];

   const filters: FlexFilter<FilterFindManyProductVariantInput>[] = [{
      id: 'batch',
      label: 'Batch',
      options: data?.ShopById?.productBatches?.map(batch => {
         return {
            label: batch,
            filter: {
               batchSerial: batch
            }
         }
      }) || []
   }]

   data?.ShopById?.productBatches?.forEach(batch => {
      tabsSpec.push({
         id: batch,
         label: batch,
         filters: [{
            filterId: 'batch',
            values: [batch]
         }]
      })
   })

   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      let operators: FilterFindManyProductOperatorsInput = {
         shopId: {
            in: [shopId]
         }
      }
      if (hidePreowned) {
         operators.isPreOwned = {
            ne: true
         }
      }
      const effectiveFilter: FilterFindManyProductInput = {
         ...filter,
         _operators: operators
      }

      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyProductInput) || SortFindManyProductInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: effectiveFilter
         }
      });
      return {
         data: data?.ProductsPagination?.items || [],
         totalCount: data?.ProductsPagination?.count || 0
      }
   }

   const onSelectProduct = (product: ProductListProductFragment) => {
      onProductSelect(product);
   }

   return <FlexList
      selections={true}
      globalId={"productList2"}
      tabs={inDialog ? undefined : tabsSpec}
      itemsPerPage={PAGE_SIZE}
      columns={columns}
      onRequestData={onRequestData}
      sorts={sortSpec}
      filters={filters}
      options={{
         itemHeight: 50,
         height: inDialog ? 500 : undefined,
      }}
      onSelectRow={onSelectProduct}
   />
}
