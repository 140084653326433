import React, {FC, useState} from "react";
import {useBrandSelectorBrandsQuery} from "../../Queries";
import {
   Box,
   Button,
   Checkbox,
   Chip,
   Dialog,
   DialogActions,
   DialogContent,
   FormControlLabel,
   Grid,
   IconButton,
   LinearProgress
} from "@mui/material";
import {TitleText} from "../../layout/Typography";
import FilterListIcon from '@mui/icons-material/FilterList';

type BrandSelectorProps = {
   value: BrandSelection
   onChange: (value: BrandSelection) => Promise<void>
}

export interface BrandSelection {
   brandHouseIds: string[]
   shopIds: string[]
   currency: string
}

export const BrandSelector: FC<BrandSelectorProps> = ({value, onChange}) => {
   const [modify, setModify] = React.useState<boolean>(false);
   const [selection, setSelection] = useState<BrandSelection>(value)
   const {data} = useBrandSelectorBrandsQuery();

   if (!data?.BrandHouses) {
      return <LinearProgress/>
   }

   const onToggleBrand = (brandId: string, fireOnChange?: boolean) => {
      const brandHouse = data?.BrandHouses?.find(bh => bh._id === brandId);
      const shopIds = selection.shopIds.filter(shopId => !brandHouse!.shops!.find(shop => shop!._id === shopId))

      if (selection.brandHouseIds.includes(brandId)) {
         let updatedSelection = {
            ...selection,
            shopIds: shopIds,
            brandHouseIds: selection.brandHouseIds.filter(bhId => bhId !== brandId)
         };
         setSelection(updatedSelection)
         if (fireOnChange) onChange(updatedSelection);
      } else {
         let updatedSelection = {
            ...selection,
            shopIds: shopIds,
            brandHouseIds: [...selection.brandHouseIds, brandId]
         };
         setSelection(updatedSelection)
         if (fireOnChange) onChange(updatedSelection);
      }
   }

   const onToggleShop = (shopId: string, fireOnChange?: boolean) => {
      const brandHouse = data?.BrandHouses?.find(bh => {
         return bh.shops?.find(shop => shop!._id === shopId)
      });
      const brandHouseIds = selection.brandHouseIds.filter(bhId => bhId !== brandHouse!._id)

      if (selection.shopIds.includes(shopId)) {
         let updatedSelection = {
            ...selection,
            brandHouseIds,
            shopIds: selection.shopIds.filter(sId => sId !== shopId)
         };
         setSelection(updatedSelection)
         if (fireOnChange) onChange(updatedSelection);
      } else {
         let updatedSelection = {
            ...selection,
            brandHouseIds,
            shopIds: [...selection.shopIds, shopId]
         };
         setSelection(updatedSelection)
         if (fireOnChange) onChange(updatedSelection);
      }
   }

   const onApply = async () => {
      setModify(false);
      await onChange(selection)
   }
   const onModify = () => {
      setModify(true);
   }

   return <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
         <IconButton sx={{padding: '4px', margin: 0}} onClick={onModify}><FilterListIcon/></IconButton>
         <Box sx={{display: 'flex', gap: 1, flexWrap: 'wrap'}}>
            {(selection.shopIds.length === 0 && selection.brandHouseIds.length === 0) &&
               <TitleText type={"h3"}>All live shops</TitleText>}
            {selection.shopIds.map(shopId => {
               const bh = data?.BrandHouses?.find(bh => bh.shops!.find(shop => shop!._id === shopId));
               if (bh) {
                  const shop = bh!.shops!.find(shop => shop!._id === shopId);
                  return <Chip variant={"outlined"} label={bh!.name + ': ' + shop!.name} onDelete={async () => {
                     onToggleShop(shopId!, true);
                  }}/>
               } else {
                  return <></>
               }
            })}
            {selection.brandHouseIds.map(bhId => {
               const bh = data?.BrandHouses?.find(bh => bh._id === bhId);
               if (bh) {
                  return <Chip variant={"filled"} label={bh!.name} onDelete={async () => {
                     onToggleBrand(bhId!, true);
                  }}/>
               } else {
                  return <></>
               }
            })}
         </Box>
      </Box>
      <Dialog open={modify} onClose={() => {
         setModify(false);
      }}>
         <DialogContent>
            {data.BrandHouses?.map(brand => {
               const brandSelected = selection.brandHouseIds.includes(brand!._id);
               const brandIndeterminante = !!brand!.shops!.find(shop => selection.shopIds.includes(shop!._id))
               return <Grid item xs={12}>
                  <FormControlLabel control={<Checkbox onChange={() => onToggleBrand(brand!._id)}
                                                       indeterminate={brandIndeterminante}
                                                       checked={brandSelected}/>}
                                    label={brand?.name}/>
                  <Box sx={{
                     marginLeft: '35px'
                  }}>
                     {brand.shops?.map(shop => {
                        const shopSelected = !brandSelected && selection.shopIds.includes(shop?._id)
                        return <FormControlLabel
                           control={<Checkbox
                              sx={{paddingTop: 0, paddingBottom: 0, paddingRight: '5px', paddingLeft: '5px'}}
                              onChange={() => onToggleShop(shop!._id)}
                              checked={shopSelected}
                              indeterminate={brandSelected}/>}
                           label={shop?.name}/>
                     })}
                  </Box>
               </Grid>
            })}
         </DialogContent>
         <DialogActions>
            <Button onClick={onApply} variant={"contained"}>Apply selection</Button>
         </DialogActions>
      </Dialog>
   </Box>
}