import {EnumShopFrontendType} from "../../../Queries";
import {MagentoIcon, SalesForceIcon, ShopifyIcon, WoocommerceIcon} from "../../../components/Icons";
import {DashboardCustomizeTwoTone} from "@mui/icons-material";

type FrontendIconProps = {
   type: EnumShopFrontendType
   size?: string
}

export const FrontendIcon = ({type, size}: FrontendIconProps) => {
   if(type===EnumShopFrontendType.shopify) {
      return <ShopifyIcon titleAccess={"Shopify"} size={size}/>
   } else if(type===EnumShopFrontendType.magento) {
      return <MagentoIcon titleAccess={"Magento"} size={size}/>
   } else if(type===EnumShopFrontendType.salesforce) {
      return <SalesForceIcon titleAccess={"Salesforce"} size={size}/>
   } else if(type===EnumShopFrontendType.woocommerce) {
      return <WoocommerceIcon titleAccess={"Woocommerce"} size={size}/>
   } else if(type===EnumShopFrontendType.custom) {
      return <DashboardCustomizeTwoTone style={{fontSize: size || '48px'}} titleAccess={"Custom"}/>
   } else {
      throw new Error('Unknown frontend type!')
   }
}
