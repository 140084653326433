import * as React from 'react';
import {ChangeEvent} from 'react';
import {useEditorState} from "react-simple-wysiwyg";

type WildcardOption = {
   name: string
   value: string
}

const Options: WildcardOption[] = [{
   name: 'Title',
   value: 'title'
}, {
   name: 'Original description',
   value: 'originalDescription'
}, {
   name: 'Original ean',
   value: 'originalEan'
}, {
   name: 'Size',
   value: 'size'
}, {
   name: 'Color',
   value: 'color'
}, {
   name: 'RRP',
   value: 'beforePrice'
}, {
   name: 'Price',
   value: 'sellingPrice'
}, {
   name: 'Actual description',
   value: 'actualDescription'
}];

export const Wildcards = () => {
   const editorState = useEditorState();

   const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
      if(!editorState.$selection) {
         editorState.$el?.focus()
      }
      const selected = parseInt(e.target.value, 10);
      document.execCommand('insertHTML', false, '{{' + Options[selected].value + '}}');
      e.target.selectedIndex = 0;
   }

   if(editorState.htmlMode) {
      return null;
   }

   return <select onChange={onChange}>
      <option hidden>Insert wildcard</option>
      {Options.map((option, i) => {
         return <option key={'wc_' + option.value} value={i}>{option.name}</option>
      })}
   </select>
}