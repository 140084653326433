import * as React from "react";
import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";
import {
   useInviteJoinUpdateMutation,
   useInviteJoinVerifyPasswordLazyQuery,
   useInviteJoinVerifyTokenQuery
} from "../Queries";
import {useNavigate, useParams} from "react-router";
import {SingleClickButton} from "../components/SingleClickButton";
import {InviteAcceptPagePath} from "./account/InviteAcceptPage";

export const InviteJoinPage = () => {
   const {token} = useParams<{ token: string }>();
   const classes = useStyles();
   const [name, setName] = React.useState<string>('');
   const [password, setPassword] = React.useState<string>('');
   const [password2, setPassword2] = React.useState<string>('');
   const {data} = useInviteJoinVerifyTokenQuery({
      variables: {
         token: token
      }
   })
   const [verify] = useInviteJoinVerifyPasswordLazyQuery()
   const [qualityNr, setQualityNr] = React.useState<number>(0)
   const [disabled, setDisabled] = React.useState<boolean>(true)
   const [register] = useInviteJoinUpdateMutation()
   const navigate = useNavigate();

   const debounced = useDebounce(password, 400);

   useEffect(() => {
      setDisabled(true);
      (async function () {
         const {data} = await verify({
            variables: {
               password: debounced
            },
            fetchPolicy: "no-cache"
         })
         let qualityNr = data?.UserVerifyPassword?.id || 0;
         setQualityNr(qualityNr);
      })();
   }, [debounced, verify])

   useEffect(() => {
      if (qualityNr > 1 && password.length > 0 && password === password2) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
   }, [password, password2, qualityNr])

   const onSubmit = async () => {
      const {data} = await register({
         variables: {
            name: name,
            password: password,
            token: token
         }
      });
      navigate("/redirect/" + data!.UserInviteJoin!.token! + InviteAcceptPagePath());
   }

   if(data?.UserVerifyResetToken!=='valid') {
      return <Container component="main" maxWidth="xs" className={classes.container}>
         <CssBaseline/>
         <div className={classes.paper}>
            Invalid join token
         </div>
      </Container>
   }

   const passwordQuality = password.length > 0 ?
      <div style={{whiteSpace: 'nowrap', padding: '5px 10px 5px 10px', color: qualityNr < 2 ? '#C00000' : '#00C000'}}>
         {(qualityNr === 0 || qualityNr === 1) && 'Too simple'}
         {qualityNr === 2 && 'Okay'}
         {qualityNr > 2 && 'Good'}
      </div> : <></>

   return <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline/>
      <div className={classes.paper}>
         <Typography component="h1" variant="h5">
            Welcome to create2STAY!
         </Typography>
         <Typography>
            You have been invited to create a staff account with us. Please type in the following informations.
         </Typography>
         <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               name="name"
               label="Your name"
               type="name"
               id="name"
               autoComplete="name"
               onChange={(e) => {
                  setName(e.target.value)
               }}
            />
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               name="password"
               label="Password"
               type="password"
               id="password"
               autoComplete="current-password"
               onChange={(e) => {
                  setPassword(e.target.value)
               }}
               InputProps={{
                  endAdornment: passwordQuality
               }}
            />
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               name="password2"
               label="Repeat password"
               type="password"
               id="password2"
               error={password.length > 0 && password !== password2}
               helperText={password.length > 0 && password !== password2 ? 'Please type the same password' : ''}
               autoComplete="none"
               onChange={(e) => {
                  setPassword2(e.target.value);
               }}
            />

            <SingleClickButton
               type="submit"
               fullWidth
               variant="contained"
               color="primary"
               className={classes.submit}
               onClick={onSubmit}
               disabled={disabled}
            >
               Accept & Log in
            </SingleClickButton>
         </form>
      </div>
   </Container>
}

const useStyles = makeStyles((theme: Theme) => ({
   paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px'
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   container: {
      backgroundColor: 'white',
      padding: '0px 20px 20px',
      marginTop: '20px'
   }
}));


function useDebounce<TValue>(value: TValue, delay: number = 300) {
   const [debouncedValue, setDebouncedValue] = useState(value)

   useEffect(() => {
      const handler = setTimeout(() => {
         setDebouncedValue(value)
      }, delay)

      return () => {
         clearTimeout(handler)
      }
   }, [value, delay])

   return debouncedValue
}
