import * as React from "react";
import {FC, useCallback} from "react";
import Page from "../../../../layout/Page";
import PageContent from "../../../../layout/PageContent";
import PageHeader from "../../../../layout/PageHeader";
import {useNavigate, useParams} from "react-router-dom";
import {ProductDetailPagePath} from "./ProductDetailPage";
import {ProductListProductFragment} from "../../../../Queries";
import {ProductsList2} from "../../components/ProductList2";

export const ProductsPage: FC<any> = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const navigate = useNavigate();

   const onProductClick = useCallback((product: ProductListProductFragment) => {
      navigate(ProductDetailPagePath(brandHouseId, shopId, product._id));
      // eslint-disable-next-line
   }, [brandHouseId, shopId]);

   return <Page>
      <PageHeader title={"Products"}/>
      <PageContent fullHeight>
         <ProductsList2 shopId={shopId} onProductSelect={onProductClick}/>
      </PageContent>
   </Page>
}
