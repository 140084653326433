import React, {FC, useEffect} from "react";
import {CurrentUserState, QrSessionInfo, UserInfo} from "../../../atoms/CurrentUser";
import {TradeInPagePath} from "../../shop/tradeins/TradeInPage";
import {
   useQrReceiverSessionQuery,
   useQrReceiverSessionUpdateMutation,
   useQrSessionOrderLazyQuery,
   useQrSessionShippingSearchLazyQuery,
   useQrSessionTradeInLazyQuery
} from "../../../Queries";
import {Button, Snackbar} from "@mui/material";
import {OrderPagePath} from "../../shop/orders/OrderPage";
import {atom, useRecoilState} from "recoil";
import {useNavigate} from "react-router-dom";

type QRSessionReceiverProps = {
   session: QrSessionInfo
}

type QRSessionData = {
   type: string|null
   url: string|null
}

const QRSessionAtom = atom<QRSessionData>({
   key: 'QRSessionAtom',
   default: {
      type: null,
      url: null
   }
});


export const QRSessionReceiver: FC<QRSessionReceiverProps> = ({session}) => {
   const [sessionAtom, setSessionAtom] = useRecoilState(QRSessionAtom);
   const [currentUser, setCurrentUser] = useRecoilState(CurrentUserState);
   const {data, startPolling, stopPolling} = useQrReceiverSessionQuery({
      variables: {
         id: session.id
      },
      pollInterval: 4000
   });
   const [updateData] = useQrReceiverSessionUpdateMutation()
   const [search] = useQrSessionShippingSearchLazyQuery();

   const [getOrder] = useQrSessionOrderLazyQuery()
   const [getTradeIn] = useQrSessionTradeInLazyQuery()

   const navigate = useNavigate();

   useEffect(() => {
      if (data && data.QRSession?.code) {
         stopPolling();
         (async function () {
            await updateData({
               variables: {
                  id: session.id,
                  code: null
               },
               refetchQueries: ["QRSession"]
            });
            const searchResult = await search({
               variables: {
                  search: data.QRSession!.code || 'xxx'
               },
               fetchPolicy: 'no-cache'
            });
            if (searchResult.data?.ShippingSearch) {
               const search = searchResult.data?.ShippingSearch;
               if (search.type === 'ORDER' && search.identifier) {
                  const order = await getOrder({
                     variables: {
                        orderId: search.identifier!
                     }
                  })
                  const orderData = order?.data?.Order;
                  if (orderData) {
                     let url = OrderPagePath(
                        orderData.shop?.brandHouseId,
                        orderData.shop?._id,
                        orderData._id);
                     setSessionAtom({
                        url: url,
                        type: 'Order'
                     });
                     navigate(url);
                  }
               }

               if (search.type === 'TRADEIN' && search.identifier) {
                  const order = await getTradeIn({
                     variables: {
                        tradeInId: search.identifier!
                     }
                  })
                  const tradeIn = order?.data?.TradeInById;
                  if (tradeIn) {
                     let url = TradeInPagePath(
                        tradeIn.shop?.brandHouseId,
                        tradeIn.shop?._id,
                        tradeIn._id);
                     setSessionAtom({
                        url: url,
                        type: 'TradeIn'
                     })
                     navigate(url);
                  }
               }
            }
            startPolling(4000)
         })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data])

   const onStop = () => {
      stopPolling();
      let updatedUser = {
         ...currentUser,
         qrSession: undefined
      } as UserInfo
      setCurrentUser(updatedUser)
   }

   return <>
      <Snackbar open={true} anchorOrigin={{
         vertical: "bottom",
         horizontal: "right"
      }}
                action={<>
                   {sessionAtom?.url && <Button onClick={() => navigate(sessionAtom!.url!)} color={"success"} size={"small"}>Go {sessionAtom?.type}</Button>}
                   <Button onClick={onStop} size={"small"}>Stop</Button>
                </>}
                message={"Connected To APP"}
      />
   </>
}
