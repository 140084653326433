import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {StockItem, useExportStockItemsQuery} from "../../../Queries";

export const ExportToExcel = async (language: string, currency: string, data: StockItem[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ID",
      type: Number,
      value: (object: StockItem) => object.itemNr
   }, {
      column: "CONDITION",
      type: Number,
      value: (object: StockItem) => object.condition
   }, {
      column: "SOLD",
      type: Boolean,
      value: (object: StockItem) => object.isSold
   }, {
      column: "MISSING",
      type: Boolean,
      value: (object: StockItem) => object.effectiveMissing
   }, {
      column: "INVENTORY",
      type: Boolean,
      value: (object: StockItem) => object.effectiveChecked
   }, {
      column: "LOCATION",
      type: String,
      value: (object: StockItem) => object.stockLocation
   }, {
      column: "IMAGE",
      type: String,
      value: (object: StockItem) => object.imageUrls?.[0]
   }, {
      column: "PRODUCT",
      type: String,
      value: (object: StockItem) => object.effectiveTitle
   }, {
      column: "RRP",
      type: Number,
      value: (object: StockItem) => object.beforePrice
   }, {
      column: "SP",
      type: Number,
      value: (object: StockItem) => object.sellingPrice
   }, {
      column: "EAN",
      type: String,
      value: (object: StockItem) => object.ean
   }, {
      column: "COLOR",
      type: String,
      value: (object: StockItem) => object.effectiveColor
   }, {
      column: "SIZE",
      type: String,
      value: (object: StockItem) => object.size
   }, {
      column: "ORGEAN",
      type: String,
      value: (object: StockItem) => object.originalEan
   }, {
      column: "ORGSKU",
      type: String,
      value: (object: StockItem) => object.sku
   }, {
      column: "VENDOR",
      type: String,
      value: (object: StockItem) => object.vendor
   }, {
      column: "PRODUCTTYPE",
      type: String,
      value: (object: StockItem) => object.productType
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'items-' + Date.now() + '.xlsx'
   })
}


type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const StockItemExport = ({language, currency, ids}: BrandItemExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportStockItemsQuery({
      variables: {
         stockItemIds: ids
      }
   });

   useEffect(() => {
      if (data?.StockItems2) {
         setOpen(true);
         (async function () {
            const items = ((data.StockItems2 || []) as StockItem[]).map(item => item.resolved) as StockItem[]
            await ExportToExcel(language, currency, items);
            setOpen(false);
         })();
      }
   }, [currency, data, ids, language])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}